import { ReactElement, useCallback, useState } from 'react';
import { styled } from '@mui/system';
import { Popover, Typography } from '@mui/material';
import Snackbar from '@opulous/web/src/components/Snackbar';
import { CopyLinkButton, FacebookButton, LinkedInButton, TwitterButton } from './styled-components';
import { openSharePopup } from '@opulous/web/src/utils';

const ShareIconButton = styled('button')(() => ({
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
}));
const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(2),
  minWidth: theme.spacing(35),
}));
const PopoverTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2.2),
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));
const IconClose = styled('span')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2.6),
  fontSize: theme.spacing(3.6),
  cursor: 'pointer',
}));
const PopoverLinks = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  a: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(7.6),
    height: theme.spacing(7.6),
    backgroundColor: theme.palette.grey['A100'],
    fontSize: theme.spacing(4),
    borderRadius: theme.spacing(1.6),
    cursor: 'pointer',
  },
}));

const SnackbarContent = styled('div')(({ theme }) => ({
  border: `2px solid ${theme.palette.success.main}`,
  backgroundColor: theme.palette.background.paper,
  fontSize: theme.spacing(2),
  fontWeight: '500',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2.2, 3),
  maxWidth: theme.spacing(60),
}));

type Props = {
  title: string
}
export default function PopoverShare({ title }: Props): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [linkCopied, setLinkCopied] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openTwitter = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.append('url', window.location.href);
    urlParams.append('text', title);
    const intentUrl = 'https://twitter.com/intent/tweet'; 
    openSharePopup(`${intentUrl}?${urlParams.toString()}`, title);
  }, [title]);

  const openFacebook = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.append('u', window.location.href);
    const intentUrl = 'https://www.facebook.com/sharer/sharer.php';
    openSharePopup(`${intentUrl}?${urlParams.toString()}`, title);
  }, [title]);

  const openLinkedIn = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.append('url', window.location.href);
    const intentUrl = 'https://www.linkedin.com/sharing/share-offsite/';
    openSharePopup(`${intentUrl}?${urlParams.toString()}`, title);
  }, [title]);

  const copyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(window.location.href);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 4000);
  }, [title]);

  const open = Boolean(anchorEl);
  const id = open ? 'share-popover' : undefined;
  return (
    <>
      <ShareIconButton
        data-testid="popover-share__button"
        aria-describedby={id} 
        onClick={handleClick}
      >
        <svg width="18" height="21" viewBox="0 0 18 21" fill="none">
          <path
            d="M15 14.58C14.24 14.58 13.56 14.88 13.04 15.35L5.91 11.2C5.96 10.97 6 10.74 6 10.5C6 10.26 5.96 10.03 5.91 9.8L12.96 5.69C13.5 6.19 14.21 6.5 15 6.5C16.66 6.5 18 5.16 18 3.5C18 1.84 16.66 0.5 15 0.5C13.34 0.5 12 1.84 12 3.5C12 3.74 12.04 3.97 12.09 4.2L5.04 8.31C4.5 7.81 3.79 7.5 3 7.5C1.34 7.5 0 8.84 0 10.5C0 12.16 1.34 13.5 3 13.5C3.79 13.5 4.5 13.19 5.04 12.69L12.16 16.85C12.11 17.06 12.08 17.28 12.08 17.5C12.08 19.11 13.39 20.42 15 20.42C16.61 20.42 17.92 19.11 17.92 17.5C17.92 15.89 16.61 14.58 15 14.58Z"
            fill={open ? 'rgba(35, 38, 47, 1)': '#AAB0C0'}
          />
        </svg>
      </ShareIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Wrapper
          data-testid="popover-share__container"
        >
          <PopoverTitle data-testid="popover-share__container__title">Share on:</PopoverTitle>
          <IconClose data-testid="popover-share__container__close" onClick={() => handleClose()}>&times;</IconClose>
          <PopoverLinks>
            <TwitterButton data-testid="popover-share__container__twitter-button" onClick={() => openTwitter()} />
            <FacebookButton data-testid="popover-share__container__facebook-button" onClick={() => openFacebook()} />
            <LinkedInButton data-testid="popover-share__container__linkedin-button" onClick={() => openLinkedIn()} />
            <CopyLinkButton data-testid="popover-share__container__copy-link-button" onClick={() => copyToClipboard()} />
          </PopoverLinks>
        </Wrapper>
        <Snackbar
          data-testid="popover-share__container__snackbar"
          open={linkCopied}
          onClose={() => setLinkCopied(false)}
        >
          <SnackbarContent>
            <span>Link copied!</span>
          </SnackbarContent>
        </Snackbar>
      </Popover>
    </>
  );
}
