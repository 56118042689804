import React, { useEffect, useState, useContext } from 'react'
import { styled } from '@mui/system'
import Grid from '@mui/material/Grid'
import { useLocation, useParams } from 'react-router-dom'
import WalletContext from '@opulous/web/src/context/context'
import * as Sentry from '@sentry/react'

import AssetDetailInformation from '@opulous/web/src/components/Assets/Songs/AssetDetailInformation'
import SongDescription from '@opulous/web/src/components/Assets/Songs/SongDescription'
import { Asset, AssetType } from '@opulous/web/src/shared/types'
import ExtraInfoRow from '@opulous/web/src/components/Assets/Songs/ExtraInfoRow'
import Paper from '@mui/material/Paper'
import { assets as AssetsService } from '@opulous/web/src/services'
import AssetDetailErrorPage from '../Error/AssetDetailError'
import { Skeleton } from '@mui/material'
import * as myAlgoService from '@opulous/web/src/services/my-algo'

const StyledContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(5),
}))

const ExtraInfoWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4, 3.5),
  backgroundColor: theme.palette.grey['A100'],
  boxShadow: 'none',
  width: '100%',
  height: '100%',

  [theme.breakpoints.down('xl')]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}))

type LocationState = {
  transferInProgress?: boolean
}

const AssetDetailPage: React.FC = () => {
  const location = useLocation()
  const state = (location.state as LocationState) || {
    transferInProgres: false,
  }
  const {
    state: { wallet },
  } = useContext(WalletContext)
  const { assetId } = useParams()
  const [asset, setAsset] = useState<Asset>()
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [assetAmount, setAssetAmount] = useState<number>(0)

  const fetchWalletAssets = async (address: string) => {
    try {
      const accountInformation = await myAlgoService.getAccountInformation(
        address
      )
      return accountInformation?.assets || [];
    } catch (ex) {
      Sentry.captureException(ex)
      return []
    }
  }

  const fetchAssetAmount = async (id: number) => {
    try {
      const assets = await fetchWalletAssets(wallet)
      const assetInfo = assets.find((a) => a['asset-id'] === id)
      let amount = 0
      if (assetInfo) {
        amount = assetInfo.amount
      }
      setAssetAmount(amount)
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  useEffect(() => {
    const fetchAssetDetails = async () => {
      if (assetId) {
        await AssetsService.fetchAssetMetaData(assetId)
          .then((assetResponse) => {
            setAsset(assetResponse)
            setLoading(false)

            const { id } = assetResponse;
            fetchAssetAmount(id)
          })
          .catch((ex) => {
            Sentry.captureException(ex)
            setError(ex)
            setLoading(false)
          })
      }
    }

    setLoading(true)
    fetchAssetDetails()
  }, [assetId])

  const hasExtraInfo = Boolean(
    asset?.custom?.fields?.length ||
    (wallet && asset?.type === AssetType.mft)
  );

  return (
    <StyledContainer>
      {!loading && asset && (
        <Grid container spacing={4}>
          <Grid item xl={9} xs={12}>
            <AssetDetailInformation
              asset={asset as Asset}
              wallet={wallet}
              transferInProgress={state?.transferInProgress}
            />
          </Grid>

          {hasExtraInfo && (
            <Grid item xl={3} xs={12}>
              <ExtraInfoWrapper>
                {asset?.custom?.fields?.map((field) => (
                  <ExtraInfoRow label={field.label} value={field.value} />
                ))}
                {wallet && asset.type === AssetType.mft && (
                  <ExtraInfoRow label="Owned MFT's" value={assetAmount} />
                )}
              </ExtraInfoWrapper>
            </Grid>
          )}

          {asset.type === AssetType.mft && (
            <Grid item md={12}>
              <SongDescription asset={asset as Asset} />
            </Grid>
          )}
        </Grid>
      )}
      {loading && (
        <Grid container spacing={4}>
          <Grid item xl={9} xs={12}>
            <AssetDetailInformation
              asset={asset as Asset}
              transferInProgress={state?.transferInProgress}
              loading={loading}
              wallet={wallet}
            />
          </Grid>

          <Grid item xl={3} xs={12}>
            <ExtraInfoWrapper>
              <Skeleton variant="text" />
            </ExtraInfoWrapper>
          </Grid>

          <Grid item md={12}>
            <SongDescription asset={asset as Asset} loading={loading} />
          </Grid>
        </Grid>
      )}
      {!loading && error && <AssetDetailErrorPage />}
    </StyledContainer>
  )
}

export default AssetDetailPage
