import React, {
  ReactElement,
  useState,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/system';
import {
  Fade,
  Grid,
  Theme,
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import mapDataWorld from '@highcharts/map-collection/custom/world.geo.json';
import InvestmentContext from '@opulous/web/src/pages/Sales/Investment/context';
import { AssetType, InvestmentStatus } from '@opulous/web/src/shared/types';
import {
  formatCurrency,
  formatNumber,
  formatDateTime,
  formatFloat,
  calculateIndividualRewardsShare,
} from '@opulous/web/src/utils';
import InvestimentStepper from '@opulous/web/src/components/Sales/InvestmentStepper';
import {
  LinearProgressContainer,
  StyledLinearProgress,
  InvestmentButtonFloat,
  InvestmentButton,
} from '@opulous/web/src/components/Sales/styled-components';
import TabContext from '@mui/lab/TabContext';
import TabBar from '@opulous/web/src/components/TabBar';
import {
  InvestmentMark,
} from '@opulous/web/src/components/Sales/Icons';
import InvestmentStatusChip from '@opulous/web/src/components/Sales/InvestmentStatus';
import InvestmentInfoCounter from '@opulous/web/src/components/Sales/InvestmentInfoCounter';
import AudioPlayer from '@opulous/web/src/components/Sales/AudioPlayer';
import FAQ from '@opulous/web/src/components/Sales/FAQ';
import {
  StatusAndActions,
  SaleTitle,
  SaleSubtitle,
  Details,
  InfoCounter,
  InfoSummary,
  InfoFunds,
  InfoGoal,
  InfoInvestors,
  InfoRaised,
  InfoStepper,
  SectionContainer,
  SectionDate,
  ActionsContainer,
  StyledHeader,
  StyledPaper,
  StyledSection,
  StyledTabPanel,
  StyledTabWrapper,
  SidebarDetailsWrapper,
  Thumbnail,
  TitleContainer,
  VideoPlayer,
  InfoSummaryTitle,
  InfoSummaryEntry,
  DetailsContainer,
  GridSticky,
  StyledTooltip,
} from '@opulous/web/src/pages/Sales/Investment/styled-components';
import { Tabs } from '@opulous/web/src/pages/Sales/Investment/types';
import PopoverShare from '@opulous/web/src/components/Sales/PopoverShare';
import GetNotified from '@opulous/web/src/components/Sales/GetNotified';
import InvestmentDetailsComponent from '@opulous/web/src/components/Sales/InvestmentDetails';
import InvestmentPurchaseHistory from '@opulous/web/src/components/Sales/InvestmentPurchaseHistory';
import StyledUltradeWidget from '@opulous/web/src/components/Sales/StyledUltradeWidget';
import {
  RewardsCalculator,
  ArtistStats,
  RewardsAPY,
  AboutArtist,
  ExclusivePerks,
  IncludedCatalog,
  Highlights,
  PreviousReleases,
} from '@opulous/web/src/pages/Sales/Investment/Details/sections';

const ButtonInfo = styled('label')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: `calc(50% - ${theme.spacing(1.4)})`,
  right: theme.spacing(2.2),
  color: theme.palette.common.black,
  backgroundColor: theme.palette.grey[100],
  width: theme.spacing(2.8),
  height: theme.spacing(2.8),
  borderRadius: '50%',
}));

export default function InvestmentDetailsPage(): ReactElement {
  const { state: investmentState, actions: investmentActions } =
    useContext(InvestmentContext);
  const [tabSelected, setTabSelected] = useState(Tabs[0].value);
  const [availableTabs, setAvailableTabs] = useState(Tabs.map(it => it.value));
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { investment, ownedAsset } = investmentState;
  const { goToGetEarlyAccess, renderEmailConfirmationStep, subscriveInvestor } =
    investmentActions;

  const owned = {
    amount: ownedAsset?.amount || 0,
    percent: calculateIndividualRewardsShare({
      amount: ownedAsset?.amount || 0,
      targetAmount: investment?.investmentTargetAmount,
      rewardsShare: investment?.rewardsShare,
    }),
  };

  const GetNotifiedMemoized = useMemo(() => {
    if (investment?.status === InvestmentStatus.UPCOMING) {
      return (
        <GetNotified
          handleSubmit={(email: string) => subscriveInvestor(investment, email)}
        />
      );
    }
    return;
  }, [investment]);

  const ActionButtonMemoized = useMemo(() => {
    if (
      !investment ||
      [
        investment?.status !== InvestmentStatus.COMPLETED,
        !investment.soldOut,
      ].some(it => !it)
    ) {
      return;
    }

    let title = '';
    let investmentButtonInfo = '';
    let investmentButtonDisabled = false;
    let getNotified = false;
    let handleClick;
    if (investment.status === InvestmentStatus.UPCOMING) {
      title = 'Get early access';
      handleClick = goToGetEarlyAccess;
    } else if (!investment?.isAllowedToBuy) {
      investmentButtonDisabled = true;
      title = 'Purchase Blocked';
      const disabledCountriesName = (investment.disabledCountries || []).map(
        id => {
          return mapDataWorld.features.find(it => it.id === id)?.properties
            ?.name;
        },
      );
      investmentButtonInfo = `
        Unfortunately, purchases cannot be made from the following restricted locations:
        ${disabledCountriesName.join(', ')}
      `;
    } else if (investment.maxIndividualInvestmentReached) {
      investmentButtonDisabled = true;
      investmentButtonInfo =
        investment.status === InvestmentStatus.PRE_SALE
          ? `
            You've reached the individual pre-sale limit. 
            For additional MFT purchases, 
            return to this page during the live sale and complete another transaction
          `
          : `You've reached the individual sale limit`;
      title = 'Purchase limit reached';
    } else if (investment.status === InvestmentStatus.PRE_SALE) {
      if (investment.isInWhiteList) {
        title = 'Buy pre-sale now';
        handleClick = renderEmailConfirmationStep;
      } else {
        getNotified = true;
      }
    } else if (investment.status === InvestmentStatus.ACTIVE) {
      title = 'Buy now';
      handleClick = renderEmailConfirmationStep;
    }

    const InvestmentButtonTooltip = investmentButtonDisabled && (
      <StyledTooltip
        title={<React.Fragment>{investmentButtonInfo}</React.Fragment>}
        placement="top"
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => {
          return;
        }}
      >
        <ButtonInfo>i</ButtonInfo>
      </StyledTooltip>
    );

    if (isMobile) {
      if (getNotified) {
        return (
          <GetNotified
            handleSubmit={(email: string) =>
              subscriveInvestor(investment, email)
            }
            anchor={
              <InvestmentButtonFloat data-testid="investment-details-page__buy-button">
                Get notified
              </InvestmentButtonFloat>
            }
          />
        );
      }
      return (
        <InvestmentButtonFloat
          data-testid="investment-details-page__buy-button"
          disabled={investmentButtonDisabled}
          onClick={handleClick}
        >
          {title}
        </InvestmentButtonFloat>
      );
    } else {
      if (getNotified) {
        return (
          <GetNotified
            handleSubmit={(email: string) =>
              subscriveInvestor(investment, email)
            }
            anchor={
              <InvestmentButton data-testid="investment-details-page__buy-button">
                Get notified
              </InvestmentButton>
            }
          />
        );
      }
      return (
        <InvestmentButton
          data-testid="investment-details-page__buy-button"
          disabled={investmentButtonDisabled}
          onClick={handleClick}
        >
          {title}
          {InvestmentButtonTooltip}
        </InvestmentButton>
      );
    }
  }, [investment, isMobile]);

  const SalesDetailsMemoized = useMemo(() => {
    return (
      <SidebarDetailsWrapper>
        <InfoFunds>
          <InfoRaised data-testid="investment-details-page__funds-raised">
            {formatCurrency(investment?.fundsRaised || 0, '$', 0)}
          </InfoRaised>
          <LinearProgressContainer data-testid="investment-details-page__raised__progress">
            <StyledLinearProgress
              variant="determinate"
              value={investment?.fundsRaisedPercent || 0}
            />
          </LinearProgressContainer>
          <Grid container>
            <Grid item lg={6} xs={12}>
              <InfoGoal data-testid="investment-details-page__info-goal">
                <span data-testid="investment-details-page__info-goal__label">
                  Raised of
                </span>
                <span data-testid="investment-details-page__info-goal__value">
                  <strong>
                    {formatCurrency(
                      investment?.investmentTargetAmount || 0,
                      '$',
                      0,
                    )}{' '}
                    goal
                  </strong>
                </span>
              </InfoGoal>
            </Grid>
            <Grid item lg={6} xs={12}>
              <InfoInvestors data-testid="investment-details-page__info-investors">
                <span data-testid="investment-details-page__info-investors__label">
                  From
                </span>
                <span data-testid="investment-details-page__info-investors__value">
                  <strong>
                    {formatNumber(investment?.investors || 0)} buyers
                  </strong>
                </span>
              </InfoInvestors>
            </Grid>
          </Grid>
        </InfoFunds>

        {investment?.status === InvestmentStatus.COMPLETED ? (
          <>
            <InfoSummary>
              <InfoSummaryTitle data-testid="investment-details-page__info-summary__title">
                MFT Details
              </InfoSummaryTitle>
              <InfoSummaryEntry>
                <span data-testid="investment-details-page__info-summary__entry-total__label">
                  Owned / Total MFTs
                </span>
                <span data-testid="investment-details-page__info-summary__entry-total__value">
                  <strong>
                    {formatNumber(owned.amount)} /{' '}
                    {formatNumber(investment.investmentTargetAmount || 0)}
                  </strong>
                </span>
              </InfoSummaryEntry>
              <InfoSummaryEntry>
                <span data-testid="investment-details-page__info-summary__entry-individual__label">
                  Individual Rewards Share
                </span>
                <span data-testid="investment-details-page__info-summary__entry-individual__value">
                  <strong>{formatFloat(owned.percent)}%</strong>
                </span>
              </InfoSummaryEntry>
            </InfoSummary>
          </>
        ) : (
          <>
            <InfoStepper>
              {investment && (
                <InvestimentStepper
                  preSaleAt={investment.preSaleStartDate}
                  saleAt={investment.saleStartDate}
                  endAt={investment.saleEndDate}
                />
              )}
            </InfoStepper>
            <InfoCounter>
              {investment && (
                <InvestmentInfoCounter investment={investment}>
                  {!isMobile && ActionButtonMemoized}
                </InvestmentInfoCounter>
              )}
            </InfoCounter>
            {!!investment?.purchaseHistory?.length && (
              <InvestmentPurchaseHistory investment={investment} />
            )}
          </>
        )}
        {investment?.asset && <StyledUltradeWidget asset={investment?.asset} />}
      </SidebarDetailsWrapper>
    );
  }, [investment, isMobile]);

  const InvestmentDetailsMemoized = useMemo(() => {
    if (!investment) {
      return;
    }
    return <InvestmentDetailsComponent investment={investment} />;
  }, [investment]);

  const AboutTabMemoized = useMemo(() => {
    if (investment) {
      return (
        <div>
          {investment.asset?.type === AssetType.ovault ? (
            <>
              <RewardsAPY investment={investment} />
              <Highlights investment={investment} />
              <AboutArtist investment={investment} />
              <IncludedCatalog investment={investment} />
              <PreviousReleases investment={investment} />
            </>
          ) : (
            <>
              <RewardsCalculator investment={investment} />
              <ExclusivePerks investment={investment} />
              <ArtistStats investment={investment} />
              <Highlights investment={investment} />
              <AboutArtist investment={investment} />
              <PreviousReleases investment={investment} />
            </>
          )}
        </div>
      );
    }
    return <></>;
  }, [investment]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (investment) {
      let newAvailableTabs = [...availableTabs];
      if (!investment.posts?.length) {
        newAvailableTabs = newAvailableTabs.filter(it => it !== Tabs[1].value);
      }
      if (!investment.questions?.length) {
        newAvailableTabs = newAvailableTabs.filter(it => it !== Tabs[2].value);
      }
      setAvailableTabs(newAvailableTabs);
    }
  }, [investment]);

  return (
    <Fade in data-testid="investment-details-page">
      <Grid container spacing={6} style={{ position: 'relative' }}>
        <Grid item xl={8} xs={12}>
          <DetailsContainer>
            <StyledPaper>
              <StyledHeader>
                <Details>
                  <Thumbnail
                    data-testid="investment-details-page__thumbnail"
                    src={`${investment?.asset?.nftDescription?.image}`}
                  />
                  <TitleContainer>
                    <SaleTitle data-testid="investment-details-page__sale-title">
                      <div>{investment?.saleTitle}</div>
                      <InvestmentMark data-testid="investment-details-page__sale-title__mark" />
                    </SaleTitle>
                    <SaleSubtitle data-testid="investment-details-page__sale-subtitle">
                      {investment?.saleSubtitle}
                    </SaleSubtitle>
                  </TitleContainer>
                </Details>
                <StatusAndActions>
                  {investment && (
                    <>
                      <InvestmentStatusChip
                        data-testid="investment-details-page__status"
                        status={investment.status}
                        soldOut={investment.soldOut}
                      />
                      <ActionsContainer>
                        <PopoverShare
                          title={investment?.asset?.nftDescription?.name}
                        />
                        {GetNotifiedMemoized}
                      </ActionsContainer>
                    </>
                  )}
                </StatusAndActions>
              </StyledHeader>
              <VideoPlayer
                data-testid="investment-details-page__pitch-video"
                src={investment?.pitchVideo}
              ></VideoPlayer>
            </StyledPaper>
            {investment && <AudioPlayer url={investment.sampleTrack} />}
            {isMobile && SalesDetailsMemoized}
            <div>
              {isMobile && ActionButtonMemoized}
              <StyledTabWrapper isMobile={isMobile}>
                <TabContext value={tabSelected}>
                  <TabBar
                    tabs={Tabs.filter(it => availableTabs.includes(it.value))}
                    onChangTab={(newTab: string) => setTabSelected(newTab)}
                  />
                  <StyledTabPanel value={Tabs[0].value}>
                    <Fade in>{AboutTabMemoized}</Fade>
                  </StyledTabPanel>
                  {availableTabs.includes(Tabs[1].value) && (
                    <StyledTabPanel value={Tabs[1].value}>
                      <Fade in>
                        <div>
                          {investment?.posts?.map((it: any) => (
                            <StyledSection>
                              <SectionContainer>
                                <SectionDate>
                                  {formatDateTime(it.createdAt)}
                                </SectionDate>
                                <ReactMarkdown>
                                  {it.content || ''}
                                </ReactMarkdown>
                              </SectionContainer>
                            </StyledSection>
                          ))}
                        </div>
                      </Fade>
                    </StyledTabPanel>
                  )}
                  {availableTabs.includes(Tabs[2].value) && (
                    <StyledTabPanel value={Tabs[2].value}>
                      <Fade in>
                        <StyledSection>
                          <SectionContainer>
                            {investment && (
                              <FAQ questions={investment.questions} />
                            )}
                          </SectionContainer>
                        </StyledSection>
                      </Fade>
                    </StyledTabPanel>
                  )}
                  <StyledTabPanel value={Tabs[3].value}>
                    <Fade in>
                      <StyledSection>{InvestmentDetailsMemoized}</StyledSection>
                    </Fade>
                  </StyledTabPanel>
                </TabContext>
              </StyledTabWrapper>
            </div>
          </DetailsContainer>
        </Grid>
        {!isMobile && (
          <GridSticky
            onScroll={e => {
              e.stopPropagation();
            }}
            item
            xl={4}
            xs={12}
          >
            {SalesDetailsMemoized}
          </GridSticky>
        )}
      </Grid>
    </Fade>
  );
}
