import * as React from 'react';

const YourTicketsIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.334 10.417c3.416 0 6.166-1.667 6.166-3.75C16.5 4.583 13.75 3 10.334 3c-3.5 0-6.167 1.667-6.167 3.75s2.667 3.667 6.167 3.667Zm0-5.75c2.583 0 4.5 1.083 4.5 2.083 0 1-1.917 2.083-4.5 2.083-2.584-.083-4.5-1.166-4.5-2.083 0-.917 1.916-2.083 4.5-2.083Z"
    />
    <path
      fill="currentColor"
      d="M15.75 10.25c-.417-.25-.917-.083-1.167.25-.417.834-2.083 1.584-4.25 1.584-2.25 0-3.833-.75-4.333-1.584-.25-.333-.75-.5-1.167-.25-.417.25-.5.75-.25 1.167.917 1.417 3.167 2.333 5.75 2.333s4.833-.916 5.75-2.333c.167-.417.083-.917-.333-1.167Zm0 3.75c-.417-.25-.917-.083-1.167.25-.5.75-2.083 1.583-4.333 1.583-2.167 0-3.833-.75-4.333-1.583-.25-.417-.75-.5-1.167-.25-.417.25-.5.75-.25 1.167.917 1.416 3.167 2.333 5.75 2.333s4.833-.917 5.75-2.333c.25-.417.167-.917-.25-1.167Z"
    />
  </svg>
)

export default YourTicketsIcon
