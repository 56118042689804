import React, { ReactNode } from 'react';
import { styled } from '@mui/system';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';

interface ButtonProps {
  theme: Theme;
  variant?: string;
  size?: string;
  textTransform?: 'capitalize';
  padding?: string;
  background?: string;
  borderRadius?: string;
  backgroundHover?: string;
}

interface StyledLoadingButtonProps {
  children: ReactNode;
  variant?: 'contained';
  size?: 'small' | 'large';
  onClick?: () => void;
  loading?: boolean;
  dataTestId?: string;
  borderRadius?: string;
  textTransform?: 'capitalize';
  padding?: string;
  background?: string;
  fullWidth?: boolean;
  color?: 'primary';
  disabled?: boolean;
  backgroundHover?: string;
}

export const Button = styled(LoadingButton)(
  ({
    variant,
    size,
    textTransform,
    padding,
    background,
    borderRadius = '4px',
    backgroundHover,
  }: ButtonProps) => ({
    variant,
    size,
    borderRadius,
    textTransform,
    padding,
    background,

    '&:hover': {
      background: backgroundHover,
    },
  }),
);

const StyledLoadingButton: React.FC<StyledLoadingButtonProps> = ({
  children,
  variant,
  size,
  onClick,
  loading,
  dataTestId,
  textTransform,
  padding,
  background,
  borderRadius,
  fullWidth,
  color,
  disabled,
  backgroundHover,
}) => {
  const theme: Theme = useTheme();
  return (
    <Button
      color={color}
      fullWidth={fullWidth}
      theme={theme}
      data-testid={dataTestId}
      variant={variant}
      size={size}
      onClick={onClick}
      loading={loading}
      textTransform={textTransform}
      padding={padding}
      background={background}
      borderRadius={borderRadius}
      disabled={disabled}
      backgroundHover={backgroundHover}
    >
      {children}
    </Button>
  );
};

export default StyledLoadingButton;
