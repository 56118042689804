import React from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import EmailIcon from '@mui/icons-material/Email';

const StyledAlert = styled(Alert)(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  gap: '20px',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '20%',
  left: '50%',
  '-webkit-transform': 'translate(-50%, -50%)',
  transform: 'translate(-50%, -50%)',
  '& .MuiAlert-icon': {
    fontSize: '100px',
    color: theme.palette.error.main + ' !important',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 600,
  fontSize: theme.spacing(3),
  lineHeight: '28px',
  marginBottom: 20,
}));
const ErrorNotification: React.FC = () => {
  return (
    <StyledBox>
      <StyledAlert severity="error">
        <Title>An error has occured. Please contact: </Title>
        <Button
          startIcon={<EmailIcon />}
          variant="contained"
          size="large"
          color="primary"
          href="mailto:support@opulous.org"
        >
          support@opulous.org
        </Button>
      </StyledAlert>
    </StyledBox>
  );
};

export default ErrorNotification;
