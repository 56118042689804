import {
  ReactElement, useEffect, useContext
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { Grid, Typography, Button, useTheme } from '@mui/material';
import InvestmentCard from '@opulous/web/src/components/Sales/InvestmentCard';
import FilterBar from '@opulous/web/src/components/Sales/FilterBar';
import SkeletonCard from '@opulous/web/src/components/Sales/SkeletonCard';
import useMediaQuery from '@mui/material/useMediaQuery'
import { AlertError } from '@opulous/web/src/components/shared';
import BuyAndStake from '@opulous/web/src/components/Sales/BuyAndStake';
import { LIMIT_DEFAULT } from '@opulous/web/src/services/sales';
import { InvestmentStatus } from '@opulous/web/src/shared/types';
import SalesContext from './context';
import SalesProvider from './provider';

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(10),
}));
const PageHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(4.25),
  paddingBottom: theme.spacing(1.75),
  flexWrap: 'wrap',
}));
const PageTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(6),
  fontWeight: 600,
}));

const SubTitle = styled('div')(({ theme }) => ({
  'p': {
    width: theme.spacing(40),
  }
}));
const LoadMoreContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));
const LoadMore = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4.25),
  borderRadius: theme.spacing(4),
  color: theme.palette.grey[700],
  border: `2px solid ${theme.palette.grey[700]}`,
  backgroundColor: theme.palette.common.white,
  '&:hover, &:active': {
    border: `2px solid ${theme.palette.grey[700]}`,
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.common.white,
  }
}));
const NoDataLabel = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(8),
  fontSize: theme.spacing(3),
  fontWeight: 'bold',
  textAlign: 'center',
}));

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(0),
  [theme.breakpoints.down('xl')]: {
    marginTop: theme.spacing(2),
  },
}));

const StyledGridItem = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center'
}));

export function SalesPage(): ReactElement {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { state: salesContext, actions: salesActions } = useContext(SalesContext);
  const isTable = useMediaQuery(theme.breakpoints.down('xl'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const statusParam = searchParams.get('status');
    const filterAndSortSelected = salesContext.filterAndSortSelected;
    if (statusParam && Object.keys(InvestmentStatus).includes(statusParam)) {
      filterAndSortSelected.status = statusParam as InvestmentStatus;
      salesActions.changeFilterAndSortSelected({
        ...filterAndSortSelected,
      });
    }
    salesActions.loadAllInvestments(filterAndSortSelected);
  }, []);

  useEffect(() => {
    if (salesContext.filterAndSortSelected?.status) {
      setSearchParams({ status: salesContext.filterAndSortSelected?.status });
    } else {
      setSearchParams({});
    }
  }, [salesContext.filterAndSortSelected]);

  const handleLoadMore = () => {
    if (!salesContext.loading && !salesContext.loadingMore) {
      const newPagination = {
        limit: salesContext.pagination?.limit || LIMIT_DEFAULT,
        offset: (salesContext.pagination?.offset || 0) + LIMIT_DEFAULT,
      };
      salesActions.loadMoreInvestments(newPagination, salesContext.filterAndSortSelected);
    }
  };

  useEffect(() => {
    const target = document.getElementById('load-more');
    if (target && salesContext.hasMore) {
      const options = {
        root: null,
        threshold: 0.5
      };
      const callback = function(entries: any, observer: any) {
        entries.forEach((entry: any) => {
          if (entry.isIntersecting) {
            observer.unobserve(target);
            handleLoadMore();
          }
        });
      }
      const observer = new IntersectionObserver(callback, options);
      observer.observe(target);
    }
  }, [salesContext.loading, salesContext.loadingMore, salesContext.pagination]);

  return (
    <Wrapper data-testid="sales-page">
      {salesContext.showAlert?.error && (
        <AlertError
          message={
            salesContext.showAlert?.message ||
            'There was an error with the page loading'
          }
        />
      )}
      <PageHeaderWrapper>
        <PageTitle data-testid="sales-page__title">All Sales</PageTitle>
      </PageHeaderWrapper>
      <SubTitle data-testid="sales-page__subtitle">
        <p>
          Explore a variety of MFT sales and 
          find your next opportunity.
        </p>
      </SubTitle>
      <FilterBar />
      <StyledGridContainer data-testid="sales-page__investments-container" container spacing={isTable || isMobile ? 4 : 8}>
        {salesContext.loading && (<>
          <StyledGridItem item xl={4} md={6} xs={12}>
            <SkeletonCard /> 
          </StyledGridItem>
          {!isMobile && (
            <>
              <StyledGridItem item xl={4} md={6} xs={12}>
                <SkeletonCard /> 
              </StyledGridItem>
              {!isTable && (
                <StyledGridItem item xl={4} md={6} xs={12}>
                  <SkeletonCard /> 
                </StyledGridItem>
              )}
            </>
          )}
        </>)}
        {!salesContext.loading && (salesContext.allInvestments.map((it, ix) => 
          <StyledGridItem key={`investment-${ix}`} item xl={4} md={6} xs={12}>
            <InvestmentCard investment={it} />
          </StyledGridItem>
        ))}
      </StyledGridContainer>
      {!salesContext.loading && salesContext.hasMore && (
        <LoadMoreContainer>
          <LoadMore id="load-more" onClick={handleLoadMore}>{salesContext.loadingMore ? 'Loading More...' : 'Load More'}</LoadMore>
        </LoadMoreContainer>
      )}
      {!salesContext.loading && !salesContext.allInvestments.length && (
        <NoDataLabel data-testid="sales-page__no-data-label">No data to display</NoDataLabel>
      )}
      <BuyAndStake />
    </Wrapper>
  );
}

export default (): ReactElement => (
  <SalesProvider>
    <SalesPage />
  </SalesProvider>
);