import { ReactElement } from 'react';
import { styled } from '@mui/system';
import { differenceInDays, isAfter } from 'date-fns';
import { formatDate } from '@opulous/web/src/utils/index';

const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));
const LabelContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: theme.spacing(1.6),
  span: {
    flex: 1,
    textAlign: 'center',
    '&:first-child': {
      textAlign: 'left',
    },
    '&:last-child': {
      textAlign: 'right',
    },
  },
}));
const LineContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  position: 'relative',
}));
const LineStepContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  zIndex: 1,
}));
const StepVertex = styled('div')<{ active?: boolean }>(({ active, theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: '50%',
  border: active ? 'none' : `2px solid ${theme.palette.grey[100]}`,
  backgroundColor: active ? theme.palette.common.black : theme.palette.common.white,
  boxSizing: 'border-box',
}));
const LineStepsProgress = styled('div')(({ theme }) => ({
  width: '100%',
  height: theme.spacing(0.5),
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.grey[100],
  position: 'absolute',
}));
const LineStepsProgressCompleted = styled('div')<{ progress: number }>(
  ({ progress, theme }) => ({
    width: `${progress}%`,
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.common.black,
  }),
);

const DateContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: theme.spacing(1.4),
  span: {
    flex: 1,
    textAlign: 'center',
    '&:first-child': {
      textAlign: 'left',
    },
    '&:last-child': {
      textAlign: 'right',
    },
  },
}));

const STEP_NOT_STARTED = 0;
const STEP_COMPLETED = 100;
const PERCENTAGE = 100;

type Props = {
  preSaleAt?: Date;
  saleAt: Date;
  endAt: Date;
};
export default function InvestmentStepper({
  preSaleAt,
  saleAt,
  endAt,
}: Props): ReactElement {
  const now = new Date();
  const steps = [
    ...(preSaleAt ? [{
      label: 'Pre-sale',
      progress: STEP_NOT_STARTED,
      completed: false,
      target: preSaleAt,
      diff: differenceInDays(preSaleAt, now),
    }] : []),
    {
      label: 'Sale',
      progress: STEP_NOT_STARTED,
      completed: false,
      target: saleAt,
      diff: differenceInDays(saleAt, preSaleAt ?? now),
    },
    {
      label: 'End',
      progress: STEP_NOT_STARTED,
      completed: false,
      target: endAt,
      diff: differenceInDays(endAt, saleAt),
    },
  ];

  const stepperStarted = isAfter(now, steps[0].target);
  if (stepperStarted) {
    steps[0].completed = true;
    const edges = steps.slice(1, steps.length);
    edges.forEach(it => {
      if (differenceInDays(now, it.target) >= 0) {
        it.progress = STEP_COMPLETED / edges.length;
        it.completed = true;
      } else {
        const nowDiff = differenceInDays(it.target, now);
        const progress =
          nowDiff > 0 && nowDiff <= it.diff
            ? (PERCENTAGE - ((nowDiff / it.diff) * PERCENTAGE))
            : STEP_NOT_STARTED;
        it.progress =
          (progress <= STEP_COMPLETED ? progress : STEP_COMPLETED) /
          edges.length;
      }
    });
  }

  const totalProgress = steps.reduce((total, it) => total + it.progress, 0);
  return (
    <Wrapper data-testid="investiment-stepper">
      <LabelContainer data-testid="investiment-stepper__labels">
        {steps.map((it, ix) => (
          <span key={`step-${ix}`}>{it.label}</span>
        ))}
      </LabelContainer>
      <LineContainer data-testid="investiment-stepper__progress">
        <LineStepContainer data-testid="investiment-stepper__progress__vertexes">
          {steps.map((it, ix) => (
            <StepVertex key={`step-vertex-${ix}`} active={it.completed} />
          ))}
        </LineStepContainer>
        <LineStepsProgress>
          <LineStepsProgressCompleted progress={totalProgress} />
        </LineStepsProgress>
      </LineContainer>
      <DateContainer data-testid="investiment-stepper__targets">
        {steps.map((it, ix) => (
          <span key={`step-target-${ix}`}>{formatDate(it.target)}</span>
        ))}
      </DateContainer>
    </Wrapper>
  );
}
