import { ReactElement, useRef, useEffect } from 'react';
import * as HighCharts from 'highcharts';
import { styled } from '@mui/system';
import { IContentPieChartItem } from '@opulous/web/src/shared/types';

interface ContentPieChartProps {
  items: IContentPieChartItem[];
  description: string;
}

const PieChartWrapper = styled('div')(() => ({
  display: 'flex',
  marginBottom: 8,
}));

const PieChartContainer = styled('div')(() => ({
  marginBottom: 24,
}));

const Description = styled('div')(() => ({
  fontSize: 14,
  lineHeight: '21px',
  fontWeight: 300,
}));

const MOBILE_MEDIUM_WIDTH = 414;

const ContentPieChart = ({
  items,
  description = '',
}: ContentPieChartProps): ReactElement => {
  const chart = useRef(null);

  const config: HighCharts.Options = {
    chart: {
      renderTo: 'container',
      type: 'pie',
      backgroundColor: 'transparent',
      height: 150,
      width: window.innerWidth < MOBILE_MEDIUM_WIDTH ? 300 : 341,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    colors: ['#fd4b1e', '#b5367f', '#ec383b', '#f2952a', '#1d73cb', '#3db856'],
    series: [
      {
        name: '',
        colorByPoint: true,
        data:
          items?.map((item: IContentPieChartItem) => ({
            name: `${item.percent}%`,
            y: item.percent,
          })) || [],
        dataLabels: {
          distance: -20,
          style: {
            textOutline: '0',
          },
        },
      },
      {
        name: '',
        colorByPoint: true,
        data:
          items?.map((item: IContentPieChartItem) => ({
            name: item.name,
            y: item.percent,
          })) || [],
        dataLabels: {
          connectorWidth: 0,
          distance: 5,
          style: {
            textOutline: '0',
          },
          formatter: function (this, point) {
            return (
              '<div style=" color:' +
              this.point.color +
              '" class="' +
              point.className +
              '">' +
              this.point.name +
              '</div>'
            );
          },
        },
      },
    ],
    plotOptions: {
      pie: { innerSize: '30%', dataLabels: { distance: 0 }, borderWidth: 0 },
    },
  } as HighCharts.Options;

  useEffect(() => {
    if (chart.current) {
      HighCharts.chart(chart.current, config);
    }
  }, []);
  return (
    <PieChartContainer>
      <Description>{description}</Description>
      <PieChartWrapper>
        <div ref={chart} />
      </PieChartWrapper>
    </PieChartContainer>
  );
};

export default ContentPieChart;
