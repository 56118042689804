import { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import { SectionArtistItem } from '@opulous/web/src/shared/types';
import { styled } from '@mui/system';

interface SectionArtistsProps {
  items: SectionArtistItem[];
}

const Image = styled('img')(() => ({
  marginBottom: 16,
  width: '100%',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2.5),
  fontWeight: 600,
  marginBottom: theme.spacing(1.75),
}));

const Description = styled('p')(() => ({
  fontWeight: 300,
  lineHeight: '24px',
  fontSize: 16,
  marginTop: 0,
  marginBottom: 20,
}));

const SectionArtists = ({ items }: SectionArtistsProps): ReactElement => {
  return (
    <div>
      {items.map((item: SectionArtistItem) => {
        return (
          <>
            <Image src={item.image} />
            <Title>{item.name}</Title>
            {item.descriptions.map(description => (
              <Description>{description}</Description>
            ))}
          </>
        );
      })}
    </div>
  );
};

export default SectionArtists;
