import React from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from '@mui/material/Typography';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { formatWallet } from '@opulous/web/src/shared/helpers';
import PopoverMenu from '@opulous/web/src/components/Common/PopoverMenu';

const DisconnectMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  '& svg': {
    fontSize: theme.spacing(2.25),
    marginRight: theme.spacing(1),
  },
}));

const AddressMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  paddingBottom: theme.spacing(1.75),
}));

const RefreshMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  '& svg': {
    fontSize: theme.spacing(2.25),
    marginRight: theme.spacing(1),
  },
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

interface MenuProps {
  anchor: React.ReactNode;
  onRefresh: () => void;
  onDisconnect: () => void;
  senderAddress: string;
  walletAddress: string;
}

const WalletDropDownMenu: React.FC<MenuProps> = ({
  anchor,
  onRefresh,
  onDisconnect,
  senderAddress,
  walletAddress,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyAddressClick = async (address: string) => {
    await navigator.clipboard.writeText(address);

    enqueueSnackbar('Address copied!', {
      variant: 'success',
    });
  };

  return (
    <PopoverMenu anchor={anchor}>
      <AddressMenuItem
        onClick={async () => await handleCopyAddressClick(senderAddress)}
      >
        <Typography variant="overline" gutterBottom color="textSecondary">
          Sender address
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography>{formatWallet(senderAddress, 15, 3)}</Typography>
          <ContentCopy sx={{ fontSize: 16 }} />
        </Box>
      </AddressMenuItem>
      <AddressMenuItem
        onClick={async () => await handleCopyAddressClick(walletAddress)}
      >
        <Typography variant="overline" gutterBottom color="textSecondary">
          Wallet address
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography>{formatWallet(walletAddress, 15, 3)}</Typography>
          <ContentCopy sx={{ fontSize: 16 }} />
        </Box>
      </AddressMenuItem>
      <RefreshMenuItem onClick={onRefresh}>
        <RefreshIcon />
        <Typography variant="body2">Refresh Session</Typography>
      </RefreshMenuItem>
      <DisconnectMenuItem onClick={onDisconnect}>
        <PowerSettingsNewIcon />
        <Typography variant="body2">Disconnect wallet</Typography>
      </DisconnectMenuItem>
    </PopoverMenu>
  );
};

export default WalletDropDownMenu;
