import axios, { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { getAdminAuthorizationHeader } from '../admin';
import {
  IssuanceFileStatus,
  IssuanceType,
  IssuanceCurrentState,
} from 'src/shared/types';

export interface IIssuanceCsvFileUploadUrlResponse {
  data: {
    uploadUrl: string;
    fileKey: string;
  };
}

export interface IIssuanceFile {
  id: number;
  fileKey: string;
  type: IssuanceType;
  status: IssuanceFileStatus;
  displayName: string;
  uploadedBy: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IGetIssuanceFilesResponse {
  data: {
    data: IIssuanceFile[];
    count: number;
    page: number;
    pageSize: number;
  };
}

export interface IGetIssuanceFileResponse {
  data: IIssuanceFile;
}

export interface IIssuanceTransactions {
  fundRegularSmartSignatureTxn: number[];
  transferAssetsToRegularSmartSignatureTxn: number[];
  regularSmartSignatureOptInToAssetSignedTxn: number[];
}

export interface IGenerateTransactionsResponse {
  data: IIssuanceTransactions[];
}

export interface ICheckIssuanceResponse {
  data: IssuanceCurrentState;
}

export const adminUploadIssuanceCsvFile = async (
  issuanceType: IssuanceType,
  displayName: string,
  csv: string,
) => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/admin/issuances : adminUploadIssuanceCsvFile',
      message: 'Call',
      type: 'info',
    });

    const {
      data: {
        data: { uploadUrl, fileKey },
      },
    }: AxiosResponse<IIssuanceCsvFileUploadUrlResponse> = await axios({
      method: 'GET',
      url: `/api/admin/issuances/csv-upload-url/${encodeURIComponent(
        issuanceType,
      )}`,
      headers: await getAdminAuthorizationHeader(),
    });

    await axios({
      method: 'PUT',
      url: uploadUrl,
      data: csv,
    });

    await axios({
      method: 'POST',
      url: `/api/admin/issuances`,
      headers: await getAdminAuthorizationHeader(),
      data: {
        fileKey,
        type: issuanceType,
        displayName,
      },
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const adminGetIssuanceCsvFileDownloadUrl = async (fileKey: string) => {
  const {
    data: { data: downloadUrl },
  }: AxiosResponse<{ data: string }> = await axios({
    method: 'GET',
    url: `/api/admin/issuances/csv-download-url/${encodeURIComponent(fileKey)}`,
    headers: await getAdminAuthorizationHeader(),
  });

  return downloadUrl;
};

export const adminGetIssuanceFiles = async (page: number, pageSize: number) => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/admin/issuances : adminGetIssuanceFiles',
      message: 'Call',
      type: 'info',
    });

    const {
      data: { data },
    }: AxiosResponse<IGetIssuanceFilesResponse> = await axios({
      method: 'GET',
      url: `/api/admin/issuances?${new URLSearchParams({
        page: `${page}`,
        pageSize: `${pageSize}`,
      }).toString()}`,
      headers: await getAdminAuthorizationHeader(),
    });
    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const adminGetIssuanceFile = async (issuanceFileId: number) => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/admin/issuances : adminGetIssuanceFile',
      message: 'Call',
      type: 'info',
    });

    const {
      data: { data },
    }: AxiosResponse<IGetIssuanceFileResponse> = await axios({
      method: 'GET',
      url: `/api/admin/issuances/${issuanceFileId}`,
      headers: await getAdminAuthorizationHeader(),
    });
    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const adminGenerateTransactions = async (
  walletAddress: string,
  issuanceFileId: number,
) => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/admin/issuances : adminGenerateTransactions',
      message: 'Call',
      type: 'info',
    });

    const {
      data: { data: issuanceTransactions },
    }: AxiosResponse<IGenerateTransactionsResponse> = await axios({
      method: 'POST',
      url: '/api/admin/issuance/generate-transactions',
      data: {
        issuanceFileId,
        walletAddress,
      },
      headers: await getAdminAuthorizationHeader(),
    });

    return issuanceTransactions;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const adminIssue = async (
  signedTransactions: number[][],
  issuanceFileId: number,
) => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/admin/issuances : adminIssue',
      message: 'Call',
      type: 'info',
    });

    return axios({
      method: 'POST',
      url: '/api/admin/issuance/issue',
      headers: await getAdminAuthorizationHeader(),
      data: { signedTransactions, issuanceFileId },
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const adminCheckIssuance = async (issuanceFileId: number) => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/admin/issuances : adminCheckIssuance',
      message: 'Call',
      type: 'info',
    });

    const {
      data: { data },
    }: AxiosResponse<ICheckIssuanceResponse> = await axios({
      method: 'GET',
      url: `/api/admin/issuances/${issuanceFileId}/check`,
      headers: await getAdminAuthorizationHeader(),
    });
    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
