import React from 'react';
import { styled } from '@mui/system';
import { Alert } from '@mui/material';

interface IErrorMessage {
  content: string;
}

const StyledAlert = styled(Alert)(({ theme }) => ({
  color: theme.palette.error.dark,
  fontWeight: 600,
  paddingLeft: 11,
  '& a': {
    color: theme.palette.error.dark,
    fontWeight: 600,
  },
  '& .MuiAlert-icon': {
    marginRight: 9,
    fontSize: '16px',
    paddingTop: 11,
  },
}));

const ErrorMessage: React.FC<IErrorMessage> = ({ content }) => {
  return (
    <StyledAlert variant="filled" severity="error">
      {content}
    </StyledAlert>
  );
};

export default ErrorMessage;
