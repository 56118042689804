import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const Skeletons: React.FC = () => {
  return (
    <Grid item xs={12} lg={4} width="100%">
      <Skeleton variant="rectangular" width="100%">
        <Box paddingTop="65%" />
      </Skeleton>
      <Box paddingTop="6px">
        <Skeleton height={10} width="100%">
          <Typography>.</Typography>
        </Skeleton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: '20px',
        }}
      >
        <Box width="30%">
          <Skeleton height={50} width="100%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
        <Box width="30%">
          <Skeleton height={50} width="100%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Box>
    </Grid>
  );
};

export default Skeletons;
