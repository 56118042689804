import { styled } from '@mui/material';
import React from 'react'

const Container = styled('div')`
  & {
    font-size: 1rem;
    font-weight: 300;
    padding-top: 2rem;
    padding-bottom: 8rem;
  }

  & .title {
    text-align: center;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 3rem;
  }

  & li::marker {
    font-weight: bold;
  }

  & ol[type="1"] li::marker,
  & ol[type="i"] li::marker {
    font-weight: 300;
  }

  & ol[type="i"] li::marker {
    content: counter(list-item, lower-roman) ") ";
  }

  a {
    color: ${props => props.theme.palette.info.main};
    font-weight: 400;
    text-decoration: none;
  }
`;

const Terms: React.FC = () => {

  return (
    <Container>
      <p className="title">Opulous - Terms and Conditions of Service</p>
      <p>These Terms and Conditions of Service (the “<strong>Terms</strong>”)
        govern your access to and use of the website at www.opulous.org (the
        “<strong>Site</strong>”) and any other software, apps, tools, features,
        or functionalities provided on or in connection with our services
        (collectively, the “<strong>Services</strong>”) offered by Opulous
        Royalty Vault Ltd and Opulous Songs Ltd (collectively,
        “<strong>Opulous</strong>” or the “<strong>Company</strong>”) and any of
        its Affiliates. The Services provided by Opulous are independent and
        separate from any other offerings provided by Affiliates of Opulous. The
        Services include, but are not limited to, provision of a platform on
        which users may Stake, purchase or sell Digital Tokens for Rewards (the
        “<strong>Opulous Platform</strong>” or “<strong>Platform</strong>”).</p>
      <p>By signing up for an account through the Site, or using any of our
        Services, you agree that you have read, understand, and accept all of
        the terms and conditions contained in these Terms. The Terms include and
        should be read together with our Privacy Policy, which is available on
        the Site.</p>
      <ol type="A">
        <li><p><strong>Definitions.</strong></p>
          <p>The following words in these Terms are defined below:</p>
          <ol type="1">
            <li><p>“<strong>Affiliates</strong>” means any entity that is directly
              or indirectly controlled by Opulous or by any of its parent
              companies.</p></li>
            <li><p>“<strong>Digital Token</strong>” means any digital representation
              of value issued, offered and/or sold by Opulous, including but not
              limited to a MFT, OPUL and/or OVAULT.</p></li>
            <li><p>“<strong>Music Fungible Tokens</strong>” or
              “<strong>MFTs</strong>” are fungible digital assets issued by Opulous on
              a blockchain.</p></li>
            <li><p>“<strong>OPUL</strong>” means the digital token issued by Ditto
              Music Pte Ltd or an Affiliate.</p></li>
            <li><p>“<strong>OVAULT</strong>” means the MFT issued by Opulous Royalty
              Vault Ltd.</p></li>
            <li><p>“<strong>Stake</strong>”, “<strong>Staked</strong>” and
              “<strong>Staking</strong>” means immobilization of any amount of Digital
              Tokens by a User for any duration and for any purpose, which includes,
              but is not limited to, the User receiving rewards of any kind.</p></li>
            <li><p>“<strong>U.S. Person</strong>” has the meaning in Rule 902(k) of
              Regulation S under the Securities Act, including without limitation: a
              natural person resident in the United States; a partnership, limited
              liability company, or corporation organized or incorporated under the
              laws of the United States, an estate of which each executor or
              administrator is a U.S. person, a trust of which each trustee is a U.S.
              person; a non-discretionary account or similar account (other than an
              estate or trust) held by a dealer or other fiduciary for the benefit or
              account of a U.S. person, or a discretionary account or similar account
              (other than an estate or trust) held by a dealer or other fiduciary
              organized, incorporated or (if an individual) resident in the United
              States.</p></li>
            <li><p>“<strong>User</strong>”, “<strong>you</strong>”, and
              “<strong>your</strong>” means you as the user of the Services. If you
              use the Services on behalf of a company or other entity then
              “<strong>you</strong>” includes you and that entity, and you represent
              and warrant that (i) you are an authorized representative of the entity
              with the authority to bind the entity to these Terms, and (ii) you agree
              to these Terms on the entity&rsquo;s behalf.</p></li>
            <li><p>“<strong>We</strong>”, “<strong>us</strong>” and
              “<strong>our</strong>” means Opulous.</p></li>
          </ol>
        </li>
      </ol>
      <ol start={2} type="A">
        <li><p><strong>Agreement to Terms.</strong></p>
          <p>By using our Services, you agree to be bound by these Terms. If you
            do not agree to be bound by these Terms, then you may not access or use
            the Services.</p></li>
      </ol>
      <ol start={3} type="A">
        <li><p><strong>Changes to these Terms or the Services.</strong></p>
          <p>We may update, change or modify the Terms at any time in our sole
            discretion. If we make material changes to these Terms, we will use
            reasonable efforts to provide notice of such changes, such as by
            providing notice through the Service and the Opulous Platform, and
            through other communications. It is your sole responsibility to review
            the Terms from time to time to view such changes and to ensure that you
            understand the Terms that apply when you access or use the Services. If
            you continue to use the Services after we have posted the updated,
            changed or modified Terms then it means you accept and agree to the
            changes. If you do not agree to be bound by the changes, you may not use
            the Services anymore. As our Services are evolving over time we may
            change or discontinue all or any part of the Services, at any time and
            without notice, at our sole discretion and without liability to you as a
            result.</p>
        </li>
      </ol>
      <ol start={4} type="A">
        <li><p><strong>Additional Terms and Policies</strong>.</p>
          <p>Your use of the Services is subject to additional terms and policies
            as may be posted on the Opulous Platform from time to time. Those
            additional terms and conditions, which are available with the relevant
            Services, then become part of your agreement with us if you use those
            Services. In the event of a conflict between these Terms and any
            additional applicable terms we may provide for a specific Service, such
            additional terms shall control for that specific Service.</p>
        </li>
      </ol>
      <ol start={5} type="A">
        <li><p><strong>Assumption of Risk.</strong></p>
          <p>You accept and acknowledge:</p>
          <ol type="1">
            <li><p>the price of MFTs and other Digital Tokens (such as OPUL) can
              fluctuate significantly. You may lose money, including from Staking on
              the Opulous Platform.</p></li>
            <li><p>you have done your research before you decide to sell, obtain,
              transfer, Stake or otherwise interact with MFTs or any Digital Tokens,
              and are using the Services at your own risk.</p></li>
            <li><p>Opulous will not be responsible for any communication failures,
              disruptions, errors, distortions or delays you may experience when using
              the Services, however caused.</p></li>
            <li><p>Opulous will not be responsible for any risks to you from using
              the Services, which include, but are not limited to:</p>
              <ol type="i">
                <li><p>non-recoverable and/or non-reversible transactions,</p></li>
                <li><p>fraudulent and/or fraudulent transactions,</p></li>
                <li><p>unauthorized third-party access such as malicious software,
                  hacking, theft, attacks,</p></li>
                <li><p>hardware, software and/or internet connection failure,</p></li>
                <li><p>changes to the protocol or network of the underlying
                  blockchain,</p></li>
                <li><p>regulatory changes which may adversely affect the development of
                  the Services and the use of MFTs or other Digital Tokens,</p></li>
                <li><p>a lack of public interest in the Services which may also
                  negatively impact the potential utility or value of MFTs or other
                  Digital Tokens, or</p></li>
                <li><p>changes affecting, or decisions taken by, third-party platforms
                  which perform transactions with respect to any MFTs or other Digital
                  Tokens,</p></li>
              </ol></li>
            <li><p>Opulous reserves the right to hide any MFT or other Digital
              Tokens affected by any of the aforementioned issues or for other
              reasons. Under no circumstances shall the inability to view your Digital
              Tokens when using our Services serve as grounds for a claim against
              Opulous.</p></li>
            <li><p>We are not providing any financial, legal, tax, accounting or
              other professional advice. Nothing from our Services, including any
              material on the Opulous Platform, should be construed as financial,
              legal, tax, accounting or other professional advice. If you are in
              doubt, please consult an independent professional advisor from persons
              licensed and qualified in the area for which such advice would be
              appropriate.</p></li>
            <li><p>You are solely responsible for determining what, if any, taxes
              apply to your use of the Services. Opulous is not responsible for
              determining such taxes.</p></li>
          </ol>
        </li>
      </ol>
      <ol start={6} type="A">
        <li><p><strong>User Eligibility for the Services</strong></p>
          <ol type="1">
            <li><p>You must be over 18 years of age to use the Services. You must
              not attempt to access or use the Services if you are not permitted to do
              so, whether by law or under these Terms. Only Users who are permitted by
              the laws of their jurisdiction can access the Services.</p></li>
            <li><p>The Services are unavailable to Users who are a U.S. Person, or
              any person resident in Singapore, or any person or entity that is
              acting, whether directly or indirectly, (i) in contravention of any
              national, international or other money laundering regulations or
              conventions, or (ii) on behalf of terrorists, terrorist organisations or
              other high-risk entities, including those persons or entities that are
              included on any relevant lists maintained by the United Nations, North
              Atlantic Treaty Organisation, Organisation for Economic Cooperation and
              Development, Financial Action Task Force, U.S. Office of Foreign Assets
              Control, U.S. Securities &amp; Exchange Commission, U.S. Federal Bureau
              of Investigation, U.S. Central Intelligence Agency, U.S. Internal
              Revenue Service, Financial Crimes Enforcement Network (FinCEN), the
              Office of Foreign Assets Control (OFAC), Transparency International
              (www.transparency.org), or any country or organisation, all as may be
              amended from time to time, (iii) for a senior foreign political figure,
              any member of a senior foreign political figure's immediate family or
              any close associate of a senior foreign political figures, or (iv) for a
              foreign shell bank (each of (i) to (iv) a "<strong>Prohibited
                Purchaser</strong>"), in each case unless Opulous, after being
              specifically notified by you in writing that you may be a Prohibited
              Purchaser, conducts further enhanced due diligence, and performs
              appropriate verification checks on you to ensure their legitimacy and
              reliability, and determines that such investment shall be permitted. By
              using the Services, you represent and warrant that you are not any of
              the foregoing.</p></li>
            <li><p>You acknowledge that out of an abundance of caution, the Digital
              Tokens are being sold in accordance with an exemption from registration
              under the U.S. Securities Act based in part on your representations
              contained in these Terms, specifically that: (a) you are not a U.S.
              Person; (b) the offer and sale of the Digital Tokens to you was made in
              an offshore transaction (as defined in Rule 902(h) of Regulation S); (c)
              no directed selling efforts (as defined in Rule 902(c) of Regulation S)
              were made in the United States; and (d) you are not acquiring the
              Digital Tokens for the account or benefit of any U.S. Person.</p></li>
            <li><p>You agree that you shall in no event offer or sell any Digital
              Tokens purchased hereunder to or for the account or benefit of a U.S.
              Person prior to the one (1) year anniversary of the date any such
              Digital Tokens are issued to you. You acknowledge that prior to the
              expiration of this one (1) year period, any transferee of the Digital
              Tokens may be required to provide to Opulous or its agents
              certifications and other documentation relating to the non-U.S. Person
              status of any such transferee, and you agree to so inform such
              transferee prior to any such proposed transfer.</p></li>
            <li>
              <strong>Anti-Money Laundering Provisions</strong>
              <p>We reserve the right:</p>
              <ol type="i">
                <li><p>at any time, to require you to provide additional information and
                  documents at the request of any competent authority or in accordance
                  with any applicable laws and regulations in relation to anti-money
                  laundering or combating the financing of terrorism;</p></li>
                <li><p>to share submitted information and documentation to third parties
                  to verify the authenticity of the submitted information and you agree to
                  this by using our Services; and</p></li>
                <li><p>to suspend and/or terminate your account, cancel your bids,
                  withhold, suspend, restrict or terminate our provision of Services to
                  you or any transactions carried out by you (whether partially or fully
                  completed) on the Platform, if you do not promptly provide us with the
                  requested information or upon our discovery of any actual or suspected
                  fraudulent activity, money laundering, criminal activity, and/or acts
                  that are contrary to these Terms.</p></li>
              </ol>
            </li>
            <li><p>You have the sole responsibility of ensuring that you have the
              legal rights in accordance with applicable laws to engage in any and all
              transaction carried out on this Opulous Platform. To make sure all
              transactions are legally compliant, we may (but we are not obliged to)
              review the legality of the transactions carried out by you and take
              necessary actions in accordance with applicable laws and
              regulations.</p></li>
          </ol></li>
      </ol>
      <ol start={7} type="A">
        <li><p><strong>Your Licence to Use Services and Account
          Creation.</strong></p>
          <ol type="1">
            <li><p>You are granted a limited, non-exclusive, non-transferable,
              non-assignable, non-sub-licensable, personal licence to access and use
              the Services subject to your compliance with these Terms and any
              applicable laws.</p></li>
            <li><p><strong>Account Creation</strong>. To access certain features of
              the Services, you&rsquo;ll need an account (“<strong>Account</strong>”). To
              register an Account, you must first successfully complete the sign-up
              process. It&rsquo;s important that you provide us with accurate, complete and
              current account information and keep this information up to date. If you
              don&rsquo;t, we might have to suspend or terminate your account. To protect
              your Account, keep the Account details and password confidential, and
              notify us right away of any unauthorized use. You are entirely
              responsible for maintaining the security and confidentiality of your
              Account and password. YOU ARE ENTIRELY RESPONSIBLE FOR ANY AND ALL
              ACTIVITIES AND CONDUCT, WHETHER BY YOU OR ANYONE ELSE, THAT ARE
              CONDUCTED THROUGH YOUR ACCOUNT. You must notify us immediately of any
              unauthorized use of your Account or any other breach of security. We
              will not be liable for any loss that you may incur as a result of
              someone else using your password or account, either with or without your
              knowledge. You may be held liable for any losses incurred by us or
              another party due to someone else using your Account or password. You
              are only allowed to create one Account and you agree you won&rsquo;t share
              your Account with anyone. You also agree that you will not: (i) create
              another Account if we&rsquo;ve disabled one you previously established, unless
              you have our prior written consent; (ii) buy, sell, rent or lease access
              to your Account or username, unless you have our prior written consent;
              (iii) share your Account password with anyone; or (iv) log in or try to
              log in to access the Service through unauthorized third party
              applications or client.</p></li>
          </ol></li>
        <li><p><strong>Payments, Rewards, Staking and Credit Card
          Transaction.</strong></p>
          <ol type="1">
            <li><p><strong>Payments.</strong> Our Services enable Users to purchase
              Digital Tokens using USDC and/or OPUL directly from your crypto wallet
              but not all wallets may be accepted by and/or compatible with the
              Opulous Platform. <em><strong>All sales are final. No refunds or
                cancellations will be permitted</strong></em>. Additionally, our
              Services allow Users to purchase Digital Tokens using the payment
              processing services of any third party engaged by us (“<strong>Payment
                Processor</strong>”) in accordance with Section H.4 below.</p></li>
            <li><p><strong>Rewards.</strong> Our Services may enable Users who hold
              and/or Stake MFTs and/or OVAULT on the Opulous Platform earn rewards
              (“<strong>Rewards</strong>”). Any Rewards are offered on a personal and
              non-commercial basis and determined by Opulous in its sole discretion.
              For the avoidance of doubt, Rewards do not confer or include any
              ownership, intellectual property or copyright in respect of any content
              unless specifically noted. Where Staking of MFTs and/or OVAULT is
              required, the Rewards received by Users are commensurate to: (i) the
              amount of MFTs or OVAULT, as applicable, staked; and (ii) the duration
              that the MFTs or OVAULT, as applicable, have been staked for.</p>

              <p>Opulous may also develop new and different programs enabling Users to
                earn Rewards. Opulous shall have the right to amend, substitute,
                withdraw and/or revise the Rewards program in its sole discretion. Users
                are required to claim Rewards in accordance with the process outlined on
                the Opulous Platform (which may require active collection of the
                applicable Digital Token). Rewards not collected by Users after two (2)
                years from issuance date shall be forfeited with no further payment or
                benefits to the User and shall revert to Opulous.</p>
            </li>

            <li><p><strong>OPUL Staking</strong>. Our Services also enable Users to
              Stake OPUL on the Opulous Platform. The Company chooses by itself the
              necessary hardware and network requirements to provide a stable and
              efficient staking service to clients. The Company conducts payments
              based on the amount of OPUL staked by the User and how long the User
              wishes to Stake for. If a User wants to initiate the withdrawal process,
              then the User must wait until the “unbounding period” expires, as
              specified by Opulous. It&rsquo;s important to mention that User won&rsquo;t be able
              to withdraw its staked OPUL before the expiration of the applicable
              “unbounding period”.</p></li>

            <li><p><strong>Credit Card Transactions</strong>.</p>
              <ol type="i">
                <li><p>Payment Information. You may purchase a Digital Token using a
                  credit card or debit card, as required. You expressly authorize us (and
                  any payment processor) to charge your payment card for all purchases you
                  make. For credit card payments, we charge your credit card when you
                  purchase a Digital Token. We may ask you to supply additional
                  information relevant to your transaction, including your credit card
                  number, the expiration date of your credit card and your email and
                  postal addresses for billing and notification (such information,
                  “<strong>Payment Information</strong>”). You represent and warrant that
                  you have the legal right to use all payment method(s) represented by any
                  such Payment Information. You may need to provide additional information
                  to verify your identity before completing your transaction (such
                  information is included within the definition of Payment Information).
                  We reserve the right to use the Payment Information you provide us in
                  connection with any payments to provide better service to you should you
                  wish to use our service again in the future and to protect us from fraud
                  and other losses. Completion of a payment transaction is contingent
                  upon: you providing complete personal, account, transaction and any
                  other information needed, authorization of the payment by your credit or
                  debit card company, and acceptance of your payment. There are no minimum
                  or maximum purchase amounts required for a credit or debit card
                  transaction.</p></li>
                <li><p>Payment Processing. Payments facilitated by our Services shall be
                  processed by Payment Processors. You may be required to create an
                  account with such Payment Processor, and by doing so, you are agreeing
                  to comply with the Payment Processor&rsquo;s terms of service and policies.
                  You may need to provide additional information to verify your identity,
                  business and tax information and your bank information when you register
                  with the Payment Processor.</p></li>
                <li><p>Chargebacks and Payment Holdback. We reserve the right to offset
                  future payments, including any Rewards, to you (“<strong>Payment
                    Holdbacks</strong>”) in certain cases including without limitation: (i)
                  where your Account is subject to excessive chargebacks resulting in
                  additional costs or fees from the Payment Processor; (ii) where the
                  Payment Processor withholds a percentage of a payment as a reserve, for
                  example, as a result of actual or suspected fraudulent activity; or
                  (iii) in certain cases where we decide, in our sole discretion, that it
                  is prudent or necessary to reverse a transaction. We further reserve the
                  right to implement Payment Holdbacks to you if payments are deemed
                  suspicious or fraudulent, at our sole discretion. We will notify you
                  that you are subject to a Payment Holdback and we may require that you
                  provide supporting documentation or information related to the relevant
                  payment(s) and corresponding purchase(s). If you do not provide such
                  supporting evidence within the requested timeframe, we reserve the right
                  to refund the payment and transfer any available funds from your wallet
                  to your identified bank account or otherwise take any action we feel is
                  reasonably necessary to comply with our compliance standards, including
                  those imposed by any relevant financial institution, our Payment
                  Processor, or the credit card network rules, as well as any applicable
                  law.</p></li>
                <li><p>Failure to Process Payments. You are solely responsible for the
                  information you provide in connection with payments processed via the
                  Services. If you purchase a Digital Token using a credit card, you are
                  responsible for ensuring the bank issuing your credit card releases the
                  funds. You remain solely liable for the price of the Digital Token if
                  you cancel or otherwise dispute the credit card transaction after
                  purchasing the Digital Token. We will not be liable for failure to
                  complete any payment or refund from or to your Account if you provide
                  inaccurate or incomplete information regarding the payment or refund or
                  for any fraudulent behavior conducted by you on the Platform. Subject to
                  applicable law, we reserve the right to send any delinquent, past due,
                  or any other Account that is in default to collections agencies to
                  recover such past due amount. In the event that we are unable to collect
                  the funds due from you for the purchase of a Digital Token, in addition
                  to the rights specified above, we may immediately cancel the applicable
                  Digital Token.</p></li>
              </ol></li>
          </ol>
        </li>
      </ol>
      <ol start={9} type="A">
        <li><p><strong>No Entitlement to Intellectual Property or
          Copyright</strong></p>
          <p>You are not entitled to any ownership right to, or licenses in, any
            intellectual property or copyright of any music from purchasing, selling
            or Staking MFTs or other Digital Tokens, or from otherwise using the
            Services.</p>
        </li>
      </ol>
      <ol start={10} type="A">
        <li><p><strong>Fees.</strong></p>
          <ol type="1">
            <li><p>We reserve the right to impose any fees, commissions, royalties,
              and other charges (collectedly, the “<strong>Fees</strong>”) at our sole
              discretion at any time for your use of our Services.</p></li>
            <li><p>We may charge Fees for each purchase and/or sale (including
              resale) of MFTs, OVAULT or other Digital Tokens on the Opulous Platform
              or third-party exchanges.</p></li>
            <li><p>By Staking, buying, selling MFTs, OVAULT, OPUL or other Digital
              Tokens on the Opulous Platform, you agree to pay all applicable fees and
              you authorize Opulous to automatically deduct fees (including any
              transaction fees, or gas fees, as applicable) and payment processing
              fees, directly from your payment.</p></li>
            <li><p>All Fees are non-refundable except at the sole discretion of
              Opulous (for Fees within its control) or applicable third
              parties.</p></li>
          </ol></li>
        <li><p><strong>Opulous&rsquo; Right to Purchase Digital
          Assets.</strong></p>
          <p>We reserve the right to sell or purchase any quantity of digital
            assets at our sole discretion. We are not required to warn any User or
            third party when we decide to exercise this right.</p>
        </li>
      </ol>
      <ol start={12} type="A">
        <li><p><strong>No Buy-Back, Redemption or Burning</strong></p>
          <p>We do not represent, warrant or undertake that we will buy back,
            redeem, burn or provide any other similar features in relation to MFTs,
            OVAULT, OPUL or other Digital Tokens. However, we may do any of the
            foregoing at our discretion. We are not required to warn any User or
            third party when we decide to exercise this right.</p>
        </li>
      </ol>
      <ol start={13} type="A">
        <li><p><strong>Right to Block Access.</strong></p>
          <p>We reserve the right, at any time, in our sole discretion, to block
            access to the Services from certain IP addresses and unique device
            identifiers.</p>
        </li>
      </ol>
      <ol start={14} type="A">
        <li><p><strong>Wallets.</strong></p>
          <p>Opulous does not operate, maintain, control or have custody over any
            contents of your wallet(s). We accept no responsibility for, or
            liability to you, in connection with your wallet(s) and make no
            representations or warranties regarding how the Services will operate
            with any specific wallet. Any issues relating to your wallet(s) should
            be addressed to your wallet provider. We are not responsible for any
            acts or omissions by you in connection with your wallet(s) as a result
            of your wallet(s) being compromised. You will immediately notify us if
            you discover or otherwise suspect any security issues related to your
            use of the Services.</p>
        </li>
      </ol>
      <ol start={15} type="A">
        <li><p><strong>System Interruptions.</strong></p>
          <p>We may interrupt your access to the Services at our sole discretion
            for any reason, including but not limited to, updating, maintenance,
            malfunctioning equipment or repairing the Services.</p>
        </li>
      </ol>
      <ol start={16} type="A">
        <li><p><strong>Equipment and Software.</strong></p>
          <p>You must provide all equipment, connectivity, and software necessary
            to connect to the Service. You are solely responsible for any costs and
            expenses, including Internet connection or mobile fees, that you incur
            when accessing the Services.</p>
        </li>
      </ol>
      <ol start={17} type="A">
        <li><p><strong>Costs and Taxes.</strong></p>
          <p>You are solely responsible for all costs incurred by you in using the
            Services and determining, collecting, reporting and paying all
            applicable Taxes. As used herein, “<strong>Taxes</strong>” means the
            taxes, duties, levies, tariffs, and other governmental charges that you
            may be required by law to collect and remit to governmental agencies,
            and other similar municipal, state, federal and national indirect or
            other withholding and personal or corporate income taxes.</p>
        </li>
      </ol>
      <ol start={18} type="A">
        <li><p><strong>Tax Compliance.</strong></p>
          <p>We reserve the right to report any activity occurring using the
            Services to relevant tax authorities as required under applicable law.
            You are solely responsible for maintaining all relevant Tax records and
            complying with any reporting requirements you may have as related to our
            Services. You are further solely responsible for independently
            maintaining the accuracy of any record submitted to any tax authority
            including any information derived from the Services.</p>
        </li>
      </ol>
      <ol start={19} type="A">
        <li><p><strong>General Prohibitions.</strong> You agree not to do any of
          the following:</p>
          <ol type="1">
            <li><p>Use, display, mirror or frame the Services or any individual
              element within the Services, Opulous&rsquo; name, any Opulous trademark, logo
              or other proprietary information, or the layout and design of any page
              or form contained on a page, without Opulous&rsquo; express written
              consent;</p></li>
            <li><p>Circumvent or attempt to circumvent any Fees owed to
              Opulous;</p></li>
            <li><p>Attempt to hack or hack any of Opulous&rsquo; Services or any users of
              Opulous&rsquo; Services, or upload any computer viruses including worms,
              Trojan horses, corrupted files, cancelbots, or any other similar
              software or programs which may damage the operation of another&rsquo;s
              computer or property or interfere with the Services;</p></li>
            <li><p>Attempt to access or search the Services or download content from
              the Services using any engine, software, tool, agent, device or
              mechanism (including spiders, robots, crawlers, data-mining tools or the
              like) other than the software and/or search agents provided by Opulous
              or other generally available third-party web browsers;</p></li>
            <li><p>Use the Services, or any portion thereof, for any commercial
              purpose or for the benefit of any third party or in any manner not
              permitted by these Terms;</p></li>
            <li><p>Attempt to decipher, decompile, disassemble or reverse engineer
              any of the software used to provide the Services;</p></li>
            <li><p>Collect or store any personally identifiable information from the
              Services from other users of the Services without their express
              permission;</p></li>
            <li><p>Impersonate or misrepresent your affiliation with any person or
              entity;</p></li>
            <li><p>Violate any applicable law or regulation;</p></li>
            <li><p>Use the Services to conduct financial activities subject to
              registration or licensing including, but not limited to, the creation
              and trading of securities, commodities, options, debt instruments or
              other financial instruments;</p></li>
            <li><p>Use the Services by or on behalf of a competitor of Opulous for
              the purpose of interfering with the Services to obtain a competitive
              advantage;</p></li>
            <li><p>Distribute spam to other users; or</p></li>
            <li><p>Encourage or enable any other person to do any of the
              foregoing.</p></li>
          </ol></li>
        <li><p><strong>General Enforcement Rights.</strong></p>
          <ol type="1">
            <li><p><strong>Unlawful Purposes.</strong> MFTs or other Digital Tokens
              used primarily or substantially for the purpose of raising funds for
              terrorist organizations are prohibited on the Opulous Platform and will
              be removed.</p></li>
            <li><p><strong>Stolen Content.</strong> The sale of stolen MFTs or other
              Digital Tokens taken without authorization, and of otherwise illegally
              obtained MFTs or other Digital Tokens, on the Opulous Platform is
              prohibited. If you have reason to believe that an MFT or Digital Tokens
              supported by the Opulous Platform was illegally obtained, please contact
              us immediately. Transacting illegally obtained MFTs or Digital Tokens
              may result in your access to the Services being suspended.</p></li>
            <li><p><strong>Reporting Content.</strong> If you become aware of the
              creation, listing, or buying of MFTs or other Digital Tokens in
              violation of any of the terms specified in this Section, please contact
              us at <a href="mailto:support@opulous.org">support@opulous.org</a> to report it.</p></li>
            <li><p><strong>No Obligation.</strong> Opulous is not obligated to
              monitor access to or use of the Services or to review or edit any
              content. However, we have the right to do so for the purpose of
              operating the Services, to ensure compliance with these Terms and to
              comply with applicable law or other legal requirements. We reserve the
              right, but are not obligated, to remove or disable Users&rsquo; access to MFTs
              or other Digital Tokens at any time and without notice, including, but
              not limited to, if we, at our sole discretion, consider it objectionable
              or in violation of these Terms. We have the right to investigate
              violations of these Terms or conduct that affects the Services.</p></li>
          </ol></li>
        <li><p><strong>Regulatory and Compliance.</strong></p>
          <p>We may suspend or terminate your access to the Services at any time
            in connection with any transaction as required by applicable law, any
            governmental authority, or if we in our sole and reasonable discretion
            determine you are violating these Terms or the terms of any third-party
            service provider. Such suspension or termination shall not be construed
            as a breach of these Terms by Opulous. In accordance with its anti-money
            laundering, anti-terrorism, anti-fraud, and other compliance policies
            and practices, we may impose reasonable limitations and controls on the
            ability of you or any beneficiary to utilize the Services. Such
            limitations may include where good cause exists, rejecting transaction
            requests, freezing funds, or otherwise restricting you from using the
            Services. We also reserve the right to cooperate with law enforcement
            authorities and disclose any information in our possession or from our
            Services that we deem necessary to meet any applicable laws, regulations
            or legal processes.</p>
        </li>
      </ol>
      <ol start={22} type="A">
        <li><p><strong>Copyright Policy.</strong></p>
          <p>Opulous respects copyright law and expects its users to do the same.
            It is Opulous&rsquo; policy to terminate in appropriate circumstances Users
            who repeatedly infringe or are believed to be repeatedly infringing the
            rights of copyright holders.</p></li>
      </ol>
      <ol start={23} type="A">
        <li><p><strong>Opulous Intellectual Property.</strong></p>
          <p>Unless otherwise indicated in writing by us, the Service and all
            content and other materials contained therein, including, without
            limitation, the Opulous logo and all designs, text, graphics, pictures,
            information, data, software, sound files, other files and the selection
            and arrangement thereof (collectively, “<strong>Opulous&rsquo;
              Content</strong>”) are the proprietary property of Opulous, licensors or
            users, as applicable. You may not use any Opulous&rsquo; Content to link to
            the Service without our express written permission. You may not use
            framing techniques, metatags or other &lsquo;hidden text&rsquo; to enclose any
            Opulous&rsquo; Content without our express written consent.</p>
        </li>
      </ol>
      <ol type="1">
        <li><p>Opulous and its licensors exclusively own all right, title and
          interest in and to the Service, including all associated intellectual
          property rights therein. You acknowledge that the Service is protected
          by copyright, trademark, and other laws. You agree not to remove, alter
          or obscure any copyright, trademark, service mark or other proprietary
          rights notices incorporated in or accompanying the Service.</p></li>
        <li><p>The appearance and style of the Service, including the Opulous
          Platform, constitutes the trademark of Opulous and licensors, and may
          not be copied, imitated or used in whole or in part without our express
          written permission.</p></li>
        <li><p>References to any other products, services, processes or other
          information by name, trademark, manufacturer, supplier or otherwise on
          the Service does not constitute or imply endorsement, sponsorship or
          recommendation by Opulous.</p></li>
        <li><p>We may also make available through the Services content that is
          subject to intellectual property rights. We retain all rights to that
          content.</p></li>
      </ol>
      <ol start={24} type="A">
        <li><p><strong>Links to Third Party Websites or
          Content.</strong></p>
          <p>The Services may allow you to access third-party websites or other
            content. We provide access only as a convenience and are not responsible
            for the content, products or services on or available from those
            resources or links displayed on such websites. You acknowledge sole
            responsibility for, and assume all risk arising from, your use of any
            third-party resources.</p>
        </li>
      </ol>
      <ol start={25} type="A">
        <li><p><strong>Termination.</strong></p>
          <p>We may suspend or terminate your access to and use of the Services,
            at our sole discretion, at any time and without notice to you. You
            acknowledge and agree that we shall have no liability or obligation to
            you in such event and that you will not be entitled to a refund of any
            amounts that you have already paid to us. Upon any termination,
            discontinuation or cancellation of the Services or your Account, all
            sections which by their nature should survive the termination of these
            Terms shall continue in full force and effect subsequent to and
            notwithstanding any termination of these Terms by Opulous or you.
            Termination will not limit any of Opulous&rsquo; other rights or remedies at
            law or in equity.</p>
        </li>
      </ol>
      <ol start={26} type="A">
        <li><p><strong>Disclaimers.</strong></p>
          <ol type="1">
            <li><p>Your use of the Services is at your own risk. You understand and
              agree that the Services provided, including the Opulous Platform, and
              any MFTs or other Digital Tokens or assets listed therein, are provided
              on an “as is” and “as available” basis without warranties or conditions
              of any kind, either express or implied. Opulous (and its suppliers) make
              no warranty that the Services:</p>
              <ol type="i">
                <li><p>will meet your requirements;</p></li>
                <li><p>will be available on an uninterrupted, timely, secure, or
                  error-free basis; or</p></li>
                <li><p>will be accurate, reliable, complete, legal, or safe.</p></li>
              </ol>
              <p>Opulous disclaims all other warranties or conditions, express or
                implied, including, without limitation, any implied warranty of
                merchantability, fitness for a particular purpose or non-infringement,
                to the maximum extent permitted by applicable law.</p>
            </li>

            <li><p>Opulous does not represent or warrant that Opulous&rsquo; content on
              the Service is accurate, complete, reliable, current or error-free. We
              will not be liable for any loss of any kind from any action taken or
              taken in reliance on material or information, contained on the Opulous
              Platform. While Opulous attempts to make your access to and use of the
              Services and Opulous&rsquo; content safe, Opulous cannot and does not
              represent or warrant that the Services, Opulous&rsquo; content, any MFTs or
              other Digital Tokens listed therein, or our servers are free of viruses
              or other harmful components. We cannot guarantee the security of any
              data that you disclose online. You accept the inherent security risks of
              providing information and dealing online over the internet and will not
              hold us responsible for any breach of security.</p></li>
            <li><p>Opulous does not represent or warrant as to the quality, origin
              or ownership of any content on the Opulous Platform. We will not be
              liable for any errors, misrepresentations, or omissions in, of, and
              about, the content, or for the availability of the content. We will also
              not be liable for any losses, injuries, or damages from the purchase,
              inability to purchase, display, or use of content.</p></li>
            <li><p>Opulous will not be responsible or liable to you for any losses
              when we decide to sell or purchase any quantity of MFTs or other Digital
              Tokens at our sole discretion.</p></li>
            <li><p>We will not be responsible or liable to you for any loss and take
              no responsibility for, and will not be liable to you for, any use of
              MFTs or other Digital Tokens, including but not limited to any losses,
              damages or claims arising from:</p>
              <ol type="i">
                <li><p>user error such as forgotten passwords, incorrectly constructed
                  transactions, or mistyped addresses;</p></li>
                <li><p>compromised or hacked accounts;</p></li>
                <li><p>server failure or data loss;</p></li>
                <li><p>corrupted cryptocurrency wallet files;</p></li>
                <li><p>unauthorized access to applications; or</p></li>
                <li><p>any unauthorized third-party activities, including without
                  limitation the use of viruses, phishing, bruteforcing or other means of
                  attack against the Services or crypto assets.</p></li>
              </ol></li>
            <li><p>MFTs and other Digital Tokens are intangible digital assets. They
              exist only by virtue of the ownership record maintained in the
              applicable blockchain network. Any transfer of title occurs on the
              decentralized ledger within such blockchain network. We do not guarantee
              that Opulous can effect the transfer of title or right in any MFTs or
              other Digital Tokens.</p></li>
            <li><p>You bear full responsibility for verifying the identity,
              legitimacy, legality, authenticity and functionality of assets you
              purchase on the Opulous Platform. Opulous makes no claims about the
              identity, legitimacy, legality, authenticity or functionality of assets
              on the Opulous Platform.</p></li>
            <li><p>Opulous is not responsible for losses or injury due to
              vulnerability or any kind of failure, abnormal behavior of software
              (e.g., wallet, smart contract), blockchains or any other features of the
              MFTs or other Digital Tokens. Opulous is not responsible for losses or
              injury due to late reports by developers or representatives (or no
              report at all) of any issues with the blockchain supporting MFTs,
              including forks, technical node issues or any other issues having fund
              losses as a result.</p></li>
            <li><p>Nothing in these Terms shall exclude or limit liability of either
              party for fraud, violation of laws, or any other activity that cannot be
              limited or excluded by legitimate means.</p></li>
            <li><p>Some jurisdictions do not allow the exclusion of implied
              warranties in contracts with consumers, so the above exclusion may not
              apply to you.</p></li>
          </ol></li>
      </ol>
      <ol start={27 * 1} type="A">
        <li><p><strong>Force Majeure.</strong></p>
          <p>Opulous shall not be liable for any action, omission, duty or
            obligation due to any occurrence that is beyond our control,
            including:</p>
          <ol type="1">
            <li><p>any future laws or regulations;</p></li>
            <li><p>any act of God or war;</p></li>
            <li><p>terrorism;</p></li>
            <li><p>any epidemic or pandemic;</p></li>
            <li><p>hacking or attack on the Services;</p></li>
            <li><p>the unavailability, disruption or malfunction of any network or
              blockchains;</p></li>
            <li><p>the unavailability, disruption or malfunction of the Internet;
              or</p></li>
            <li><p>any unavailability, disruption or malfunction of any system,
              software, network or infrastructure necessary for the Services to
              function.</p></li>
          </ol>
        </li>
      </ol>
      <ol start={27 * 2} type="A">
        <li><p><strong>Indemnity.</strong></p>
          <p>To the fullest extent permitted by applicable law, you agree to
            indemnify, defend and hold harmless Opulous, and our respective past,
            present and future Affiliates, employees, officers, directors,
            contractors, consultants, equity holders, suppliers, vendors, service
            providers, parent companies, subsidiaries, agents, representatives,
            predecessors, successors and assigns (individually and collectively, the
            “<strong>Opulous Parties</strong>”), from and against all actual or
            alleged third-party claims, damages, awards, judgments, losses,
            liabilities, obligations, penalties, interest, fees, expenses
            (including, without limitation, attorneys&rsquo; fees and expenses) and costs
            (including, without limitation, court costs, costs of settlement and
            costs of pursuing indemnification and insurance), of every kind and
            nature whatsoever, whether known or unknown, foreseen or unforeseen,
            matured or unmatured, or suspected or unsuspected, in law or equity,
            whether in tort, contract or otherwise (collectively,
            “<strong>Claims</strong>”), including, but not limited to, damages to
            property or personal injury, that are caused by, arise out of or are
            related to:</p>

          <ol type="a">
            <li><p>your use or misuse of the Services;</p></li>
            <li><p>your violation of these Terms;</p></li>
            <li><p>your use or disposal of any MFTs or other Digital
              Tokens;</p></li>
            <li><p>your negligence or willful misconduct; or</p></li>
            <li><p>your violation of the rights of a third party or of applicable
              law.</p></li>
          </ol>
          <p>You agree to promptly notify Opulous of any Claims and cooperate with
            Opulous in defending such Claims. You further agree that Opulous shall
            have control of the defense or settlement of any third-party Claims.
            This indemnity is in addition to, and not in lieu of, any other
            indemnities set forth in a written agreement between you and
            Opulous.</p>
        </li>
      </ol>

      <ol start={27 * 3} type="A">
        <li><p><strong>Limitation</strong> <strong>of Liability.</strong></p>
          <ol type="1">
            <li><p>To the maximum extent permitted by law, neither Opulous, nor its
              service providers involved in creating, producing, or delivering the
              Services will be liable for: any incidental, special, punitive,
              exemplary or consequential damages, or damages for lost profits, lost
              revenues, lost savings, lost business opportunity, loss of data or
              goodwill, service interruption, computer damage or system failure or the
              cost of substitute services of any kind arising out of or in connection
              with these Terms or from the use of or inability to use the services,
              whether based on warranty, contract, tort (including negligence),
              product liability or any other legal theory, and whether or not Opulous
              or its service providers has been informed of, or could foresee, the
              possibility of such damage.</p></li>
            <li><p>To the maximum extent permitted by law, neither Opulous nor its
              service providers will be liable for any damages to your computer
              system, mobile device or data from the use of the Service and any
              third-party sites.</p></li>
            <li><p>To the maximum extent permitted by the law of the applicable
              jurisdiction, in no event will Opulous&rsquo; total liability arising out of
              or in connection with these terms or from the use of or inability to use
              the services exceed the amounts you have paid or are payable by you to
              Opulous for use of the Services.</p></li>
            <li><p>The exclusions and limitations of damages set forth above are
              fundamental elements of the basis of the bargain between Opulous and
              you.</p></li>
          </ol></li>

      </ol>

      <ol start={27 * 4} type="A">
        <li><p><strong>No conflict with local law</strong>.</p>
          <p>The laws of some jurisdictions may limit or not permit certain
            provisions of this agreement, such as indemnification, the exclusion of
            certain warranties or the limitation of liability. In such a case, such
            provisions will apply only to the maximum extent permitted by the laws
            of such jurisdictions. Also, you may have additional legal rights in
            your jurisdiction, and nothing in these terms will prejudice such rights
            that you may have as a consumer of the Services under such applicable
            law.</p>
        </li>
      </ol>
      <ol start={27 * 5} type="A">
        <li><p><strong>Governing Law</strong></p>
          <p>These Terms and any action related thereto will be governed by the
            laws of the British Virgin Islands, without regard to conflict of laws
            of the British Virgin Islands or any other jurisdiction.</p>
        </li>
      </ol>
      <ol start={27 * 6} type="A">
        <li><p><strong>Dispute Resolution.</strong></p>
          <ol type="1">
            <li><p><strong>Mandatory Arbitration of Disputes.</strong> You and
              Opulous each agree that any dispute, claim or controversy arising out of
              or relating to these Terms or the breach, termination, enforcement,
              interpretation or validity thereof or the use of the Services
              (collectively, “<strong>Disputes</strong>”) will be resolved solely by
              binding, individual arbitration and not in a class, representative or
              consolidated action or proceeding. You and Opulous agree that the
              Singapore International Arbitration Centre Rules 2016 (the “<strong>SIAC
                Rules</strong>”) govern the interpretation and enforcement of these
              Terms, and that you and Opulous are each waiving the right to a trial by
              jury or to participate in a class action. This arbitration provision
              shall survive termination of these Terms.</p></li>
            <li><p><strong>Exceptions.</strong> As limited exceptions to
              sub-paragraph (1) above:</p>
              <ol type="i">
                <li><p>you and Opulous both may seek to resolve a Dispute in small
                  claims court if it qualifies, provided the claims remain only in such
                  court; and</p></li>
                <li><p>you and Opulous each retain the right to seek injunctive or other
                  equitable relief from a court to prevent (or enjoin) the infringement or
                  misappropriation of our intellectual property rights.</p></li>
              </ol></li>
            <li><p><strong>Good faith resolution.</strong> You and Opulous agree to
              resolve disputes in good faith prior to either party initiating an
              arbitration, small claims court proceeding or equitable relief for
              intellectual property infringement. If the parties do not reach
              agreement to resolve the dispute within thirty (30) days after
              initiation of this dispute resolution process, either party may commence
              arbitration, file an action in small claims court, or file a claim for
              injunctive or equitable relief in a court of proper jurisdiction for
              matters relating to intellectual property infringement, if the claims
              qualify.</p></li>
            <li><p><strong>Conducting Arbitration and Arbitration Rules.</strong>
              The arbitration will be conducted by the Singapore International
              Arbitration Centre (the “<strong>SIAC</strong>”) under the SIAC Rules
              then in effect, except as modified by these Terms. The SIAC Rules are
              available at https://siac.org.sg/. A party who wishes to start
              arbitration must submit a written notice of arbitration to the SIAC and
              give notice to the other party as specified in the SIAC Rules. Any
              arbitration hearings will take place in the county where you live,
              unless we both agree to a different location, but will be conducted
              remotely to the extent permitted by the SIAC Rules. The parties agree
              that the arbitrator shall have exclusive authority to decide all issues
              relating to the interpretation, applicability, enforceability and scope
              of this arbitration agreement.</p></li>
            <li><p><strong>Arbitration Costs.</strong> Payment of all filing,
              administration and arbitrator fees will be governed by the SIAC Rules,
              and we will not seek to recover the administration and arbitrator fees
              we are responsible for paying, unless the arbitrator finds your Dispute
              frivolous. If you prevail in arbitration you may be entitled to an award
              of attorneys&rsquo; fees and expenses to the extent provided under applicable
              law.</p></li>
            <li><p><strong>Injunctive and Declaratory Relief.</strong> Except as
              provided in sub-paragraph (2) above, the arbitrator shall determine all
              issues of liability on the merits of any claim asserted by either party
              and may award declaratory or injunctive relief only in favor of the
              individual party seeking relief and only to the extent necessary to
              provide relief warranted by that party&rsquo;s individual claim.</p></li>
          </ol></li>
      </ol>
      <ol start={27 * 7} type="A">
        <li><p><strong>Class Action Waiver.</strong></p></li>
        <p>You and Opulous agree that each may bring claims against the other
          only in your or its individual capacity, and not as a plaintiff or class
          member in any purported class or representative proceeding. Further, if
          the parties&rsquo; Dispute is resolved through arbitration, the arbitrator may
          not consolidate another person&rsquo;s claims with your claims, and may not
          otherwise preside over any form of a representative or class proceeding,
          without the written permission of all parties involved.</p>
      </ol>
      <ol start={27 * 8} type="A">
        <li><p><strong>Severability.</strong></p>
          <p>If an arbitrator or court of competent jurisdiction decides that any
            part of these Terms is invalid or unenforceable, then the invalid or
            unenforceable part will be severed from the Terms while the remaining
            parts of these Terms will remain in full force and effect.</p>
        </li>
      </ol>
      <ol start={27 * 9} type="A">
        <li><p><strong>Communications from Opulous.</strong></p>
          <p>By using our Services, you consent to receiving electronic
            communications from Opulous (including email, SMS messages, or other
            types of messages). We may also send you promotional communications via
            email we think will be of interest to you. You can choose to unsubscribe
            from communications through the Service or your mobile device&rsquo;s
            operating system.</p>
        </li>
      </ol>
      <ol start={27 * 10} type="A">
        <li><p><strong>No Ownership Interest, Claim or Loan.</strong></p>
          <ol type="1">
            <li><p>You understand and agree that your purchase, sale, holding,
              receipt and use of any MFTs, other Digital Tokens or assets from the
              Services does not:</p>
              <ol type="i">
                <li><p>represent or constitute a loan or a contribution of capital to,
                  or other investment in Opulous or its Affiliates;</p></li>
                <li><p>provide you with any ownership interest, equity, security, or
                  right to or interest in the assets, rights, properties, revenues or
                  profits of, or voting rights whatsoever in Opulous or its Affiliates;
                  and</p></li>
                <li><p>create or imply or entitle Users to the benefits of any fiduciary
                  or other agency relationship between Opulous, its Affiliates, or any of
                  its directors, officers, employees, agents.</p></li>
              </ol></li>
            <li><p>You further accept that MFTs, OVAULT and OPUL shall not under any
              circumstances be deemed to be or treated as:</p>
              <ol type="i">
                <li><p>a debt or liability of any nature owed by Opulous or any of its
                  Affiliates to you or any other entity;</p></li>
                <li><p>any form of financial derivative including, but not limited to, a
                  futures contract, forward contract, option, swap or warrant;</p></li>
                <li><p>a contract for difference of any form or kind or any other
                  contract, the purpose of which is to secure a profit or avoid a loss by
                  reference to fluctuations in the value or price of an asset or an
                  index;</p></li>
                <li><p>any commercial paper or negotiable instrument;</p></li>
                <li><p>any commodity or asset that any person is obliged to redeem or
                  purchase;</p></li>
                <li><p>any note, bond, warrant or other certificate that entitles you to
                  repayment of the purchase price or part thereof, any interest, dividend
                  or any other kind of return on the purchase price or part thereof by
                  Opulous, its Affiliates or any other entity;</p></li>
                <li><p>an interest or share in any investment fund or collective
                  investment scheme;</p></li>
                <li><p>the pooling of monies with the aim of spreading investment
                  risk;</p></li>
                <li><p>entitling you to participate in any profits or gains from the
                  acquisition, holding, management or disposal of assets; and/or any
                  security, capital markets product, investment or equivalent terms in any
                  jurisdiction.</p></li>
              </ol></li>
          </ol></li>
      </ol>
      <ol start={27 * 11} type="A">
        <li><p><strong>General Terms.</strong></p>
          <ol type="1">
            <li><p><strong>Reservation of Rights.</strong> Opulous and its licensors
              exclusively own all right, title and interest in and to the Services,
              including all associated intellectual property rights. You acknowledge
              that the Services are protected by copyright, trademark, and other laws.
              You agree not to remove, alter or obscure any copyright, trademark,
              service mark or other proprietary rights notices incorporated in or
              accompanying the Services.</p></li>
            <li><p><strong>Entire Agreement.</strong> These Terms constitute the
              entire and exclusive understanding and agreement between Opulous and you
              regarding the Services, and these Terms supersede and replace all prior
              oral or written understandings or agreements between Opulous and you
              regarding the Services.</p></li>
            <li><p><strong>Assignability.</strong> You may not assign or transfer
              these Terms, by operation of law or otherwise, without Opulous&rsquo; prior
              written consent. Any attempt by you to assign or transfer these Terms,
              without such consent, will be null. Opulous may freely assign or
              transfer these Terms without restriction. Subject to the foregoing,
              these Terms will bind and inure to the benefit of the parties, their
              successors and permitted assigns.</p></li>
            <li><p><strong>No Waiver.</strong> No waiver by either party of any
              breach or default hereunder shall be deemed to be a waiver of any
              preceding or subsequent breach or default.</p></li>
            <li><p><strong>Notices.</strong> Any notices or other communications
              provided by Opulous under these Terms will be given:</p>
              <ol type="i">
                <li><p>via email; or</p></li>
                <li><p>by posting to the Opulous Platform.</p></li>
              </ol></li>
            <p>For notices made by email, the date of receipt will be deemed the
              date on which such notice is transmitted.</p>

            <li><p><strong>Waiver of Rights.</strong> Opulous&rsquo; failure to enforce
              any right or provision of these Terms will not be considered a waiver of
              such right or provision. The waiver of any such right or provision will
              be effective only if in writing and signed by a duly authorized
              representative of Opulous. Except as expressly set forth in these Terms,
              the exercise by either party of any of its remedies under these Terms
              will be without prejudice to its other remedies under these Terms or
              otherwise.</p></li>
          </ol></li>
      </ol>
      <ol start={27 * 12} type="A">
        <li><p><strong>Feedback.</strong> We welcome feedback and suggestions
          for our Services. You agree that any feedback provided to us can be used
          at our discretion in any manner, without notice and without compensation
          to you.</p></li>
      </ol>
      <ol start={27 * 13} type="A">
        <li><p><strong>Contact Information.</strong> If you have any questions
          about these Terms or the Services, please contact Opulous
          at <a href="mailto:support@opulous.org">support@opulous.org</a>.</p></li>
      </ol>
    </Container>
  )
}

export default Terms
