import { ReactElement, useEffect, useContext } from 'react';
import { styled } from '@mui/system';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import PageHeader from '@opulous/web/src/components//PageHeader';
import TabBar from '@opulous/web/src/components/TabBar';
import Box from '@mui/material/Box';
import SalesTab from '@opulous/web/src/components/Royalties/SalesTab';
import PayoutsTab from '@opulous/web/src/components//Royalties/PayoutsTab';
import AlertError from '@opulous/web/src/components/shared/AlertError';
import AlertSuccess from '@opulous/web/src/components/shared/AlertSuccess';

import { useNavigate, useSearchParams } from 'react-router-dom';
import RoyaltiesContext from './context';
import RoyaltiesProvider from './provider';
import { Tabs } from './types';

const Wrapper = styled('div')(() => ({
  position: 'relative',
}));

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: theme.spacing(3, 0),
}));

export function RoyaltiesPage(): ReactElement {
  const navigate = useNavigate();
  const { state: royaltiesContext, actions: royaltiesActions } =
    useContext(RoyaltiesContext);

  if (!royaltiesContext.walletState.hasMFTs) {
    navigate('/');
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const onChangTab = (newTab: string) => {
    setSearchParams({ tab: newTab });
    royaltiesActions.changeTabSelected(newTab);
  };

  useEffect(() => {
    const tabParam = searchParams.get('tab');
    if (tabParam) {
      royaltiesActions.changeTabSelected(tabParam);
    }
  }, []);

  useEffect(() => {
    if (royaltiesContext.tabSelected === Tabs[0].value) {
      royaltiesActions.loadSalesSummaryData(
        royaltiesContext.walletAddress,
        royaltiesContext.royaltiesSalesFilter,
        royaltiesContext.salesFilterSelected,
      );
      royaltiesActions.loadSalesFullOverview(
        royaltiesContext.walletAddress,
        royaltiesContext.salesFilterSelected,
        royaltiesContext.fullOverviewOptionSelected,
      );
    } else {
      royaltiesActions.loadPayoutData(royaltiesContext.walletState);
    }
  }, [royaltiesContext.tabSelected]);

  return (
    <Wrapper data-testid="royalties-page">
      {royaltiesContext.showAlert?.error && (
        <AlertError
          message={
            royaltiesContext.showAlert?.message ||
            'There was an error with the page loading'
          }
        />
      )}
      {royaltiesContext.showAlert?.success && (
        <AlertSuccess
          message={royaltiesContext.showAlert?.message || 'Congratulations!'}
        />
      )}
      <PageHeader title="Music Rewards" />

      <Box sx={{ paddingTop: 2 }}>
        <TabContext value={royaltiesContext.tabSelected}>
          <TabBar tabs={Tabs} onChangTab={onChangTab} />
          <StyledTabPanel value={Tabs[0].value}>
            <SalesTab />
          </StyledTabPanel>
          <StyledTabPanel value={Tabs[1].value}>
            <PayoutsTab />
          </StyledTabPanel>
        </TabContext>
      </Box>
    </Wrapper>
  );
}

export default (): ReactElement => (
  <RoyaltiesProvider>
    <RoyaltiesPage />
  </RoyaltiesProvider>
);
