import { Dispatch } from 'react';
import * as SalesService from '@opulous/web/src/services/sales';
import { FilterAndSortSelected, InvestmentsSortOptions, Pagination } from './types';
import * as Sentry from '@sentry/react';

const InvestmentsSortMap = {
  HOTTEST: 'hottest',
  MOST_FUNDED: 'mostFunded',
  CLOSING_SOON: 'saleEndDate',
  NEWEST: '-saleStartDate',
};

export type Action<T> = {
  type: T;
  payload?: any; //eslint-disable-line
};

export enum SalesActionType {
  SetLoading,
  SetLoadingMore,
  SetShowAlert,
  ChangeFilterAndSortSelected,
  LoadAllInvestments,
  LoadMoreInvestments,
}

export type SalesActions = {
  setLoading: (status: boolean) => void;
  setShowAlertError: (status: boolean, message?: string) => void;
  changeFilterAndSortSelected: (
    newFilterAndSortSelected: FilterAndSortSelected,
  ) => void;
  loadAllInvestments: (newFilterAndSortSelected: FilterAndSortSelected) => void;
  loadMoreInvestments: (newPagination: Pagination, newFilterAndSortSelected: FilterAndSortSelected) => void;
};
export function useActions(
  dispatch: Dispatch<Action<SalesActionType>>,
): SalesActions {
  const actions = {
    setLoading: (status: boolean) => {
      dispatch({ type: SalesActionType.SetLoading, payload: status });
    },
    setLoadingMore: (status: boolean) => {
      dispatch({ type: SalesActionType.SetLoadingMore, payload: status });
    },
    setShowAlertError: (status: boolean) => {
      dispatch({
        type: SalesActionType.SetShowAlert,
        payload: status ? { error: true } : undefined,
      });
    },
    changeFilterAndSortSelected: (
      newFilterAndSortSelected: FilterAndSortSelected,
    ) => {
      dispatch({
        type: SalesActionType.ChangeFilterAndSortSelected,
        payload: newFilterAndSortSelected,
      });
    },
    loadAllInvestments: (newFilterAndSortSelected: FilterAndSortSelected) => {
      (async () => {
        try {
          actions.setLoading(true);
          const preparedFilterAndSort = {
            term: newFilterAndSortSelected.term,
            status: newFilterAndSortSelected.status,
            sortBy: newFilterAndSortSelected.sortBy
              ? InvestmentsSortMap[
                  newFilterAndSortSelected.sortBy as unknown as keyof typeof InvestmentsSortOptions
                ]
              : undefined,
          };
          const result = await SalesService.fetchAllInvestments(
            preparedFilterAndSort,
          );

          dispatch({
            type: SalesActionType.LoadAllInvestments,
            payload: {
              hasMore: result.count > result.items.length,
              allInvestments: result.items,
            },
          });
        } catch (error) {
          Sentry.captureException(error);
          actions.setShowAlertError(true);
        } finally {
          actions.setLoading(false);
        }
      })();
    },
    loadMoreInvestments: (newPagination: Pagination, newFilterAndSortSelected: FilterAndSortSelected) => {
      (async () => {
        try {
          actions.setLoadingMore(true);
          const preparedFilterAndSort = {
            limit: newPagination.limit,
            offset: newPagination.offset,
            term: newFilterAndSortSelected.term,
            status: newFilterAndSortSelected.status,
            sortBy: newFilterAndSortSelected.sortBy
              ? InvestmentsSortMap[
                  newFilterAndSortSelected.sortBy as unknown as keyof typeof InvestmentsSortOptions
                ]
              : undefined,
          };
          const result = await SalesService.fetchAllInvestments(
            preparedFilterAndSort,
          );

          dispatch({
            type: SalesActionType.LoadMoreInvestments,
            payload: {
              hasMore: result.count > (result.limit + result.offset),
              newPagination,
              moreInvestments: result.items,
            },
          });
        } catch (error) {
          Sentry.captureException(error);
          actions.setShowAlertError(true);
        } finally {
          actions.setLoadingMore(false);
        }
      })();
    },
  };
  return actions;
}
