import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router'
import WalletContext from '@opulous/web/src/context/context'

interface RequireWalletProps {
  children: JSX.Element
}

export const RequireWallet: React.FC<RequireWalletProps> = ({ children }) => {
  const {
    state: { wallet, walletType },
    disconnectWallet,
  } = useContext(WalletContext)
  const location = useLocation()

  if (!wallet || !walletType?.trim()) {
    disconnectWallet()
    return <Navigate to="/login" state={{ from: location }} />
  }

  return children
}
