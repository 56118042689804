import { createContext } from 'react';
import { InvestmentActions } from './actions';
import {
  AlgoAsset,
  InvestmentAvailability,
  InvestmentDetails,
  PaymentIntent,
  PaymentMethodOptions,
} from '@opulous/web/src/shared/types';
import { PurchaseSteps } from '@opulous/web/src/pages/Sales/Investment/types';

export type InvestmentContextState = {
  loading: boolean;
  loadingAvailability: boolean;
  processingPayment: boolean;
  paymentIntentExpired?: boolean;
  openCryptoPaymentIframe?: boolean;
  showAlert?: {
    error?: boolean;
    success?: boolean;
    message?: string;
    showSupportEmailMessage?: boolean;
  };
  ownedAsset?: AlgoAsset;
  walletAddress?: string;
  investment?: InvestmentDetails;
  investmentAvailability?: InvestmentAvailability;
  paymentIntent?: PaymentIntent;
  purchaseFlow?: {
    currentStep: PurchaseSteps;
    purchaseAmount?: number;
    email?: string;
    paymentMethod?: PaymentMethodOptions;
  };
};

type InvestmentContextType = {
  state: InvestmentContextState;
  actions: InvestmentActions;
};
const InvestmentContext = createContext<InvestmentContextType>(
  {} as InvestmentContextType,
);
export default InvestmentContext;
