import axios from 'axios';
import algosdk from 'algosdk';
import BigNumber from 'bignumber.js';
import * as Sentry from '@sentry/react';
import {
  APIResponse,
  ICreateInvestment,
  Investment,
  InvestmentAvailability,
  InvestmentDetails,
  InvestmentRaw,
  InvestmentStatus,
  PaymentIntent,
  PaymentMethodOptions,
  PaymentTransactionState,
  ResponsePageable,
  WalletTypeEnum,
} from '@opulous/web/src/shared/types';
import { signTransaction } from '@opulous/web/src/helpers/AlgorandHelper';
import { PeraWalletConnect } from '@perawallet/connect';
import config from '@opulous/web/src/config';
import { getAdminAuthorizationHeader } from '../admin';

import { SignedTx } from '@randlabs/myalgo-connect';
import { sendSignedTransactions } from '@opulous/web/src/services/my-algo';

const PERCENTAGE = 100;
export const LIMIT_DEFAULT = 9;

type InvestmentFilter = {
  limit?: number;
  offset?: number;
  term?: string;
  status?: string;
  sortBy?: string;
};
export const fetchAllInvestments = async (
  filter: InvestmentFilter,
): Promise<ResponsePageable<Investment[]>> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/sales : fetchAllInvestments',
      message: 'Call',
      type: 'info',
    });
    const urlParams = new URLSearchParams();
    if (filter.term) {
      urlParams.append('search', `${filter.term}`);
    }
    if (filter.status) {
      urlParams.append('status', `${filter.status}`);
    }
    if (filter.sortBy) {
      if (filter.sortBy !== 'hottest' && filter.sortBy !== 'mostFunded') {
        urlParams.append('sort', `${filter.sortBy}`);
      }
    }

    filter.limit = filter.limit || LIMIT_DEFAULT;
    filter.offset = filter.offset || 0;
    urlParams.append('limit', `${filter.limit}`);
    urlParams.append('offset', `${filter.offset}`);

    const axiosResponse = await axios.get<APIResponse<ResponsePageable<any[]>>>(
      `/api/investments?${urlParams.toString()}`,
    );

    const responseData = axiosResponse.data.data;
    const result = {
      count: responseData.count,
      limit: responseData.limit,
      offset: responseData.offset,
      items: responseData.items.map(it => ({
        ...it,
        artistName: it.asset?.artistName,
        releaseTitle: it.asset?.releaseTitle,
        rewardsShare: new BigNumber(it.asset?.royaltyCopyrightShare || 0)
          .times(PERCENTAGE)
          .toNumber(),
        status: InvestmentStatus[
          it.status as unknown as keyof typeof InvestmentStatus
        ],
        preSaleStartDate: it.preSaleStartDate && new Date(it.preSaleStartDate),
        saleStartDate: new Date(it.saleStartDate),
        saleEndDate: new Date(it.saleEndDate),
      })),
    };

    if (filter.sortBy && filter.sortBy === 'hottest') {
      result.items = result.items.sort((a, b) => a.investors - b.investores);
    } else if (filter.sortBy && filter.sortBy === 'mostFunded') {
      result.items = result.items.sort((a, b) => a.fundsRaised - b.fundsRaised);
    }
    return result;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

type InvestmentInput = {
  investmentId: number;
  walletAddress?: string;
};
export const fetchInvestmentById = async (
  input: InvestmentInput,
): Promise<InvestmentDetails | undefined> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/sales : fetchInvestmentById',
      message: 'Call',
      type: 'info',
    });

    const urlParams = new URLSearchParams();
    if (input.walletAddress) {
      urlParams.append('walletAddress', `${input.walletAddress}`);
    }
    const axiosResponse = await axios.get<APIResponse<any>>(
      `/api/investments/${input.investmentId}/?${urlParams.toString()}`,
    );
    const investmentDetails = axiosResponse.data.data;
    return {
      ...investmentDetails,
      artistName: investmentDetails.asset?.artistName,
      releaseTitle: investmentDetails.asset?.releaseTitle,
      rewardsShare: new BigNumber(
        investmentDetails.asset?.royaltyCopyrightShare || 0,
      )
        .times(PERCENTAGE)
        .toNumber(),
      status: InvestmentStatus[
        investmentDetails.status as unknown as keyof typeof InvestmentStatus
      ],
      purchaseHistory: (investmentDetails.purchaseHistory || []).map(
        (it: any) => ({
          ...it,
          createdAt: new Date(it.createdAt),
        }),
      ),
      preSaleStartDate: investmentDetails.preSaleStartDate && new Date(investmentDetails.preSaleStartDate),
      saleStartDate: new Date(investmentDetails.saleStartDate),
      saleEndDate: new Date(investmentDetails.saleEndDate),
      mftDistributionDate: new Date(investmentDetails.mftDistributionDate),
      nextRewardDistribution: new Date(
        investmentDetails.nextRewardDistribution,
      ),
      pitchVideo: investmentDetails.pitchVideo?.url?.replace(
        'watch?v=',
        'embed/',
      ),
      sampleTrack: investmentDetails.sampleTrack?.url,
      previousReleases: (investmentDetails.previousReleases || []).map(
        (it: any) => ({
          ...it,
          releaseDate: new Date(it.releaseDate),
        }),
      ),
      posts: (investmentDetails.posts || []).map((it: any) => ({
        ...it,
        createdAt: new Date(it.createdAt),
      })),
    };
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

type InvestmentAvailabilityInput = {
  investmentId: number;
  walletAddress?: string;
};
export const fetchInvestmentAvailability = async (
  input: InvestmentAvailabilityInput,
): Promise<InvestmentAvailability> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/sales : fetchInvestmentAvailability',
      message: 'Call',
      type: 'info',
    });

    const urlParams = new URLSearchParams();
    if (input.walletAddress) {
      urlParams.append('walletAddress', `${input.walletAddress}`);
    }
    const axiosResponse = await axios.get<APIResponse<any>>(
      `/api/investments/${input.investmentId}/availability?${urlParams.toString()}`,
    );
    return {
      ...axiosResponse.data.data,
    };
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

type SubscribeInput = {
  email: string;
  crmTagId: number;
};
export const subscribeInvestor = async ({
  email,
  crmTagId,
}: SubscribeInput): Promise<void> => {
  try {
    await new Promise((resolve, reject) => {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      if (head && script) {
        const callback = `getNotifiedCallback${Date.now()}`;
        (window as any)[callback] = () => {
          head.removeChild(script);
          resolve('');
        };
        const urlParams = new URLSearchParams();
        urlParams.append('EMAIL', email);
        urlParams.append('tags', `${crmTagId}`);
        urlParams.append('c', callback);

        script.setAttribute(
          'src',
          `${config.env.CRM_SUBSCRIBE_ADDRESS}&${urlParams.toString()}`,
        );
        head.appendChild(script);
        setTimeout(() => {
          reject('');
        }, 3000);
      } else {
        resolve('');
      }
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

type ConfirmEmailInput = {
  email: string;
};
export const sendConfirmationCode = async ({
  email,
}: ConfirmEmailInput): Promise<void> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/sales : sendConfirmationCode',
      message: 'Call',
      type: 'info',
    });

    await axios.post<APIResponse<any>>(
      `/api/investments/request-confirmation`,
      {
        email,
      },
    );
    await new Promise(r => setTimeout(r, 5000));
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export async function adminGetInvestments(
  page: number,
  pageSize: number,
): Promise<{
  page: number;
  pageSize: number;
  count: number;
  data: Investment[];
}> {
  const {
    data: { data },
  } = await axios({
    method: 'GET',
    headers: await getAdminAuthorizationHeader(),
    url: `/api/admin/investments?${new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString(),
    }).toString()}`,
  });
  return {
    ...data,
    data: data.data.map((it: any) => ({
      ...it,
      artistName: it.asset?.artistName,
      releaseTitle: it.asset?.releaseTitle,
      rewardsShare: (it.asset?.royaltyCopyrightShare || 0) * PERCENTAGE,
      dealType: it.dealType,
      status:
        InvestmentStatus[it.status as unknown as keyof typeof InvestmentStatus],
      preSaleStartDate: it.preSaleStartDate && new Date(it.preSaleStartDate),
      saleStartDate: new Date(it.saleStartDate),
      saleEndDate: new Date(it.saleEndDate),
    })),
  };
}

export async function adminGetInvestment(id: number): Promise<InvestmentRaw> {
  const {
    data: { data },
  } = await axios({
    method: 'GET',
    headers: await getAdminAuthorizationHeader(),
    url: `/api/admin/investments/${encodeURIComponent(id)}`,
  });
  return data;
}

export async function adminCreateInvestment(
  rec: ICreateInvestment,
): Promise<void> {
  const {
    data: { data },
  } = await axios({
    method: 'POST',
    headers: await getAdminAuthorizationHeader(),
    url: '/api/admin/investments',
    data: rec,
  });
  return data;
}

export async function adminEditInvestment(
  id: number,
  rec: Partial<ICreateInvestment>,
): Promise<void> {
  const {
    data: { data },
  } = await axios({
    method: 'PATCH',
    headers: await getAdminAuthorizationHeader(),
    url: `/api/admin/investments/${encodeURIComponent(id)}`,
    data: rec,
  });
  return data;
}

export async function adminDeleteInvestment(id: number): Promise<void> {
  const {
    data: { data },
  } = await axios({
    method: 'DELETE',
    headers: await getAdminAuthorizationHeader(),
    url: `/api/admin/investments/${encodeURIComponent(id)}`,
  });
  return data;
}

export async function adminExportInvestmentData(id: number): Promise<string> {
  const {
    data: { data },
  } = await axios({
    method: 'GET',
    headers: await getAdminAuthorizationHeader(),
    url: `/api/admin/investments/${encodeURIComponent(id)}/report`,
  });
  return data;
}

export async function adminExportInvestmentIssuanceData(
  id: number,
): Promise<string> {
  const {
    data: { data },
  } = await axios({
    method: 'GET',
    headers: await getAdminAuthorizationHeader(),
    url: `/api/admin/investments/${encodeURIComponent(id)}/issuance-report`,
  });
  return data;
}

type VerifyConfirmationCodeInput = {
  email: string;
  code: string;
};
export const verifyConfirmationCode = async ({
  email,
  code,
}: VerifyConfirmationCodeInput): Promise<void> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/sales : verifyConfirmationCode',
      message: 'Call',
      type: 'info',
    });

    await axios.post<APIResponse<any>>(
      `/api/investments/verify-confirmation-code`,
      {
        email,
        code,
      },
    );
    await new Promise(r => setTimeout(r, 5000));
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

type CreatePaymentIntentInput = {
  investmentId: number;
  walletAddress: string;
  email: string;
  purchaseAmount: number;
  paymentMethod: PaymentMethodOptions;
};
export const createPaymentIntent = async ({
  investmentId,
  walletAddress,
  email,
  purchaseAmount,
  paymentMethod,
}: CreatePaymentIntentInput): Promise<PaymentIntent> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/sales : createPaymentIntent',
      message: 'Call',
      type: 'info',
    });

    const axiosResponse = await axios.post<APIResponse<PaymentIntent>>(
      `/api/investments/${investmentId}/create-payment-intent`,
      {
        walletAddress,
        email,
        purchaseAmount,
        paymentMethod,
      },
    );
    const response = axiosResponse.data.data;
    return {
      ...response,
      payment: {
        ...response.payment,
        expirationDate: new Date(response.payment.expirationDate),
      },
    };
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

type SignPurchasePaymentTransactionProps = {
  transaction: algosdk.Transaction;
  walletType?: WalletTypeEnum;
  connector?: PeraWalletConnect;
};
export const signPurchasePaymentTransaction = async ({
  transaction,
  walletType,
  connector,
}: SignPurchasePaymentTransactionProps): Promise<SignedTx> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/sales : signPurchasePaymentTransaction',
      message: 'Call',
      type: 'info',
    });

    const transactionSigned = await signTransaction({
      walletType,
      connector,
      transaction,
    });

    Sentry.addBreadcrumb({
      category: 'services/sales : signPurchasePaymentTransaction',
      message: 'Payment transaction signed',
      type: 'info',
      data: {
        walletType,
        transaction,
        connector,
        transactionSigned,
      },
    });

    return transactionSigned;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

type SendPurchasePaymentTransactionProps = {
  signedTxn: SignedTx;
};
export const sendPurchasePaymentTransaction = async ({
  signedTxn,
}: SendPurchasePaymentTransactionProps): Promise<void> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/sales : sendPurchasePaymentTransaction',
      message: 'Call',
      type: 'info',
    });

    await sendSignedTransactions([signedTxn]);

    Sentry.addBreadcrumb({
      category: 'services/sales : sendPurchasePaymentTransaction',
      message: 'Payment transaction sent',
      type: 'info',
      data: {
        signedTxn,
      },
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

type LoadPurchasePaymentTransactionState = {
  externalId: string;
};
export const loadPurchasePaymentTransactionState = async ({
  externalId,
}: LoadPurchasePaymentTransactionState): Promise<PaymentTransactionState> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/sales : loadPurchasePaymentTransactionState',
      message: 'Call',
      type: 'info',
    });

    const axiosResponse = await axios.get<APIResponse<PaymentTransactionState>>(
      `/api/investments/payment-transaction-state/${externalId}`,
    );
    return axiosResponse.data.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
