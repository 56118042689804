import { ReactElement } from 'react';
import { styled } from '@mui/system';
import { Paper } from '@mui/material';
import { AssetType, InvestmentDetails } from '@opulous/web/src/shared/types';
import { formatCurrency, formatDateTime } from '@opulous/web/src/utils/index';

const Wrapper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(3, 4),
}));
const Title = styled('label')(({ theme }) => ({
  fontSize: theme.spacing(2.2),
  fontWeight: 'bold',
}));
const Details = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 0),
}));
const Purchase = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.spacing(1, 0),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  '&:last-of-type': {
    borderBottom: 'none',
  },
}));
const Amount = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.spacing(1.8),
  span: {
    fontSize: theme.spacing(1.5),
  },
}));
const Value = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',
  fontSize: theme.spacing(1.8),
  strong: {
    color: theme.palette.primary.main,
  },
  span: {
    fontSize: theme.spacing(1.4),
  },
}));

type Props = {
  investment: InvestmentDetails;
};
export default function InvestmentPurchaseHistory({
  investment,
}: Props): ReactElement {
  const isAnOvaut = investment?.asset?.type === AssetType.ovault;
  return (
    <Wrapper data-testid="investment-purchase-history">
      <Title data-testid="investment-purchase-history__title">
        Launchpad History
      </Title>
      <Details data-testid="investment-purchase-history__details">
        {investment.purchaseHistory
          .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
          .map((it, ix) => (
            <Purchase
              key={`investment-purchase-history-${ix}`}
              data-testid="investment-purchase-history__details__purchase-item"
            >
              <Amount data-testid="investment-purchase-history__details__purchase-item__amount">
                <strong>{it.amount}</strong>
                <span>{isAnOvaut ? 'OVAULTs' : 'MFTS'}</span>
              </Amount>
              <Value data-testid="investment-purchase-history__details__purchase-item__value">
                <strong>{formatCurrency(it.amount, '$', 2)}</strong>
                <span>{formatDateTime(it.createdAt)}</span>
              </Value>
            </Purchase>
          ))}
      </Details>
    </Wrapper>
  );
}
