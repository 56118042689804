import React, { ReactElement, useContext, useState } from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import * as Sentry from '@sentry/react';
import CircularStackedBarChart from '@opulous/web/src/components/CircularStackedProportionalChart';
import WalletContext from '@opulous/web/src/context/context';
import { WalletTypeEnum } from '@opulous/web/src/shared/types';
import { formatNumber } from '@opulous/web/src/utils';
import MyAlgoWarningDialog from '@opulous/web/src/components/shared/MyAlgoWarningDialog';
import { DialogConnectWallet } from '@opulous/web/src/components/Common/ConnectWallet/DialogConnectWallet';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.up('lg')]: {
    height: '100%',
  },
}));

const StakeChartContainer = styled('div')(({ theme }) => ({
  width: '100%',
  flex: 1,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  position: 'relative',

  '& > .chart-container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '85%',
    minWidth: theme.spacing(20),
    maxWidth: '16rem',
    maxHeight: '16rem',

    '& > .chart-text-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',

      '& > .chart-text': {
        maxWidth: '70%',
        textAlign: 'center',
        fontSize: '.75rem',
        lineHeight: '1rem',
        color: theme.palette.grey[500],
        padding: theme.spacing(1),

        '& > .chart-badge': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: theme.spacing(2),

          '& > .chart-badge-text': {
            height: '1.3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '.65rem',
            backgroundColor: theme.palette.grey[600],
            color: theme.palette.common.white,
            padding: theme.spacing(0, 2),
            fontSize: '1rem',
          },
        },
      },
    },
  },
}));

const MaxTicketText = styled('div')(({ theme }) => ({
  fontSize: '.75rem',
  fontWeight: '500',
  padding: theme.spacing(1),
}));

export default function ActiveRaffleActions({
  winningChance,
  maxWinningChance,
  onTicketAdd,
  disabled,
  maxTicketAllowed,
}: {
  winningChance: number;
  maxWinningChance: number;
  onTicketAdd?: () => void;
  disabled?: boolean;
  maxTicketAllowed: number;
}): ReactElement {
  const theme = useTheme();
  const primaryColor = theme.palette.primary;
  const {
    state: { wallet },
    initPeraWallet,
  } = useContext(WalletContext);
  const [isWalletConnectDialogOpen, setIsWalletConnectDialogOpen] =
    useState(false);
  const [shouldShowMyAlgoWarningDialog, setShouldShowMyAlgoWarningDialog] =
    useState(false);

  const connectWallet = async (type: WalletTypeEnum) => {
    switch (type) {
      case WalletTypeEnum.myAlgoWallet:
        setShouldShowMyAlgoWarningDialog(true);
        setIsWalletConnectDialogOpen(false);
        return;
      case WalletTypeEnum.peraWallet:
        initPeraWallet();
        setIsWalletConnectDialogOpen(false);
        return;
    }
  };

  const handleConnect = async (type: WalletTypeEnum) => {
    try {
      await connectWallet(type);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <Container>
      <StakeChartContainer>
        <div className="chart-container">
          <CircularStackedBarChart
            data={[
              {
                percent: maxWinningChance,
                color: primaryColor.light,
                tooltip:
                  'The more tickets you stake, the higher your chance to win!',
              },
              {
                percent: winningChance,
                color: primaryColor.main,
              },
            ]}
          />

          <div className="chart-text-container">
            <div className="chart-text">
              <span>Your winning chance</span>
              <div className="chart-badge">
                <div className="chart-badge-text">
                  {Math.floor(winningChance * 100) / 100}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </StakeChartContainer>
      {!wallet ? (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setIsWalletConnectDialogOpen(true)}
        >
          Connect Wallet
        </Button>
      ) : (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={onTicketAdd}
          disabled={disabled}
        >
          Add tickets
        </Button>
      )}
      <Box>
        <MaxTicketText>
          Max. tickets: {formatNumber(maxTicketAllowed)}
        </MaxTicketText>
      </Box>
      <DialogConnectWallet
        onClick={() => setIsWalletConnectDialogOpen(false)}
        onClose={() => setIsWalletConnectDialogOpen(false)}
        onConnect={handleConnect}
        style={{
          opacity: isWalletConnectDialogOpen ? 1 : 0,
          pointerEvents: isWalletConnectDialogOpen ? 'all' : 'none',
        }}
      />
      <MyAlgoWarningDialog
        show={shouldShowMyAlgoWarningDialog}
        onClose={() => setShouldShowMyAlgoWarningDialog(false)}
      />
    </Container>
  );
}
