import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  CircularProgress,
  Collapse,
  ButtonBase,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ContentCopy from '@mui/icons-material/ContentCopy';
import {
  IssuanceCurrentState,
  IssuanceContractState,
  IssuanceContractStateItemStatus,
} from 'src/shared/types';
import { formatWallet } from '@opulous/web/src/shared/helpers';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(2),
    maxWidth: 680,
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: `${theme.spacing(1.75, 3)} !important`,
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(1.5)} !important`,
  },
}));

const DialogTitleText = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(2),
}));

const StyledContent = styled(DialogContent)(({ theme }) => ({
  padding: `${theme.spacing(0, 3, 3.5)} !important`,
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(0, 1.5, 2.5)} !important`,
  },
}));

const CloseIconComponent = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(1.5),
}));

function IssuanceStateTableRow({
  issuanceContractState,
}: {
  issuanceContractState: IssuanceContractState;
}) {
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleCopyAddressClick = async (address: string) => {
    await navigator.clipboard.writeText(address);

    enqueueSnackbar('Address copied!', {
      variant: 'success',
    });
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <ButtonBase
            sx={{
              font: 'inherit',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              height: '100%',
            }}
            onClick={() =>
              handleCopyAddressClick(
                issuanceContractState.issuanceMetadata.receiver,
              )
            }
          >
            {formatWallet(
              issuanceContractState.issuanceMetadata.receiver,
              15,
              3,
            )}
            <ContentCopy sx={{ fontSize: 16 }} />
          </ButtonBase>
        </TableCell>
        <TableCell
          sx={{ whiteSpace: 'nowrap' }}
        >{`${issuanceContractState.issuanceMetadata.assetName} (${issuanceContractState.issuanceMetadata.assetId})`}</TableCell>
        <TableCell align="center">
          <Box display="flex" alignItems="center" justifyContent="center">
            {
              {
                [IssuanceContractStateItemStatus.PENDING]: (
                  <CircularProgress size="1.5rem" />
                ),
                [IssuanceContractStateItemStatus.INCORRECT]: (
                  <ErrorIcon color="error" />
                ),
                [IssuanceContractStateItemStatus.CORRECT]: (
                  <CheckCircleIcon color="success" />
                ),
              }[issuanceContractState.status]
            }
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display="flex" flexDirection="column" padding={1} gap={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Contract Address</TableCell>
                    <TableCell>
                      <ButtonBase
                        sx={{
                          font: 'inherit',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          height: '100%',
                        }}
                        onClick={() =>
                          handleCopyAddressClick(
                            issuanceContractState.contractAddress,
                          )
                        }
                      >
                        {formatWallet(
                          issuanceContractState.contractAddress,
                          15,
                          3,
                        )}
                        <ContentCopy sx={{ fontSize: 16 }} />
                      </ButtonBase>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Expected Amount</TableCell>
                    <TableCell>
                      {issuanceContractState.issuanceMetadata.amount.asDecimal}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Issued Amount</TableCell>
                    <TableCell>
                      {issuanceContractState.amountIssued.asDecimal}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function IssuanceStateTable({ rows }: { rows: IssuanceCurrentState }) {
  return (
    <Box display="flex" flexDirection="column" overflow="auto">
      <TableContainer component={Box}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Receiver</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Asset (ID)</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <IssuanceStateTableRow
                key={row.contractAddress}
                issuanceContractState={row}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

type IssuanceStateModalProps = {
  onClose: () => void;
  issuanceState: IssuanceCurrentState;
};

const IssuanceStateModal: React.FC<IssuanceStateModalProps> = ({
  onClose,
  issuanceState,
}) => {
  return (
    <StyledDialog
      open={true}
      onClose={onClose}
      data-testid="issuance-state-modal"
    >
      <StyledDialogTitle>
        <DialogTitleText data-testid="issuance-state-modal__title">
          Issuance State
        </DialogTitleText>
        <IconButton
          size="small"
          onClick={() => onClose()}
          data-testid="issuance-state-modal__close-button"
        >
          <CloseIconComponent fontSize="small" />
        </IconButton>
      </StyledDialogTitle>
      <StyledContent data-testid="issuance-state-modal__content">
        <Wrapper>
          <IssuanceStateTable rows={issuanceState} />
        </Wrapper>
      </StyledContent>
    </StyledDialog>
  );
};

export default IssuanceStateModal;
