import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { styled } from '@mui/system'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import _PieChartIcon from '@mui/icons-material/PieChart'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material'
import { useTheme } from '@mui/styles'
import SvgIcon from '@mui/material/SvgIcon'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import FiberIcon from '@mui/icons-material/FiberSmartRecord'
import { formatDate } from '@opulous/web/src/utils'
import { PoolList } from '../../../shared/types'
import { ReactComponent as OpulSvg } from '../../../assets/opul.svg'
import usePopup from '../../../hooks/usePopup'
import OPULLabel from '@opulous/web/src/components/shared/OPULLabel'

const PieChartIcon = styled(_PieChartIcon)<{ dimmed?: boolean }>(({ theme, dimmed = false }) => ({
  fill: dimmed ? theme.palette.grey[400]: theme.palette.primary.main,
}));

const StyledCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(2),
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    borderRadius: theme.spacing(1.5),
  },
  minHeight: theme.spacing(8),
  background: theme.palette.background.paper,
  color: theme.palette.common.black,
  position: 'relative',
}))

export const PoolVersionTag = styled('div')<{ dimmed?: boolean }>(({ theme, dimmed = false }) => ({
  color: theme.palette.common.white,
  fontWeight: '600',
  position: 'absolute',
  top: theme.spacing(2),
  right: 0,
  height: theme.spacing(4),
  width: theme.spacing(6),
  fontSize: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(2, 0, 0, 2),
  backgroundColor: dimmed ? theme.palette.grey[400] : theme.palette.primary.main,
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: `${theme.spacing(3.5, 3)} !important`,
}))

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  lineHeight: theme.spacing(3.5),
  fontWeight: 600,
  flex: 1,
  height: theme.spacing(7),
  display: 'inline-flex',
  alignItems: 'center',
  paddingRight: theme.spacing(4), // NOTE: Offset for the version tag
}))

const CardFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 3),
  borderTop: `1px solid ${theme.palette.grey[200]}`,
}))

const Label = styled(Typography)<{ isna?: boolean }>(
  ({ isna = false, theme }) => ({
    fontSize: theme.spacing(1.75),
    lineHeight: theme.spacing(2.625),
    fontWeight: 600,
    color: isna ? 'rgba(0, 0, 5, 0.3)' : theme.palette.common.black,
  })
)

const Value = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  lineHeight: '28px',
  marginTop: theme.spacing(0.5),
  color: theme.palette.common.black,
  fontWeight: 300,
}))

const StyleTag = styled(Box)(({ theme }) => ({
  fontSize: theme.spacing(1.75),
  fontWeight: 500,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(1, 3),
  lineHeight: 1.5,
  color: theme.palette.common.white,
  background: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
}))

const StyleTagOutlined = styled(Box)(({ theme }) => ({
  fontSize: theme.spacing(1.75),
  fontWeight: 500,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(1, 1.5, 1, 3),
  lineHeight: 1.5,
  border: `1px solid ${theme.palette.grey[200]}`,
  display: 'flex',
  alignItems: 'center',
}))

const StyleIconWrapper = styled(Box)<{ isna?: boolean }>(
  ({ isna = false, theme }) => ({
    color: isna ? 'rgba(0, 0, 5, 0.3)' : theme.palette.primary.main,
    display: 'flex',
    marginRight: theme.spacing(0.5),
  })
)

const FlexWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
}))

const Details = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey['A100'],
  padding: theme.spacing(2.5, 3),
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}))

const DetailInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
  fontSize: theme.spacing(1.5),
  color: theme.palette.common.black,
  lineHeight: theme.spacing(1.5),
  fontWeight: 500,
  '&:last-child': {
    marginBottom: theme.spacing(0),
  },
}))

const DetailWrapper = styled(Box)<{ open: boolean }>(({ theme, open }) => ({
  background: theme.palette.common.white,
  height: `${open ? 'auto' : 0}`,
  overflow: `${open ? 'unset' : 'hidden'}`,
  transition: 'height ease-in 200s',
}))

const CurrencyWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(6),
  height: theme.spacing(6),
  background: theme.palette.grey['A100'],
  flexShrink: 0,
  borderRadius: '100%',
  border: `2px solid ${theme.palette.grey[100]}`,
}))

const Flex = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down(400)]: {
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
}))

const PoolSize = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '26px',
}))

const PoolSizeLeft = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const PoolIcon = styled(FiberIcon)(() => ({
  transform: 'rotateY(180deg)',
}))

interface PoolCardProps {
  pool: PoolList
}

const PoolGridCard: React.FC<PoolCardProps> = ({ pool }) => {
  const navigate = useNavigate()
  const theme: Theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const { isOpen, toggleMenu } = usePopup(ref)
  const detailButton = useRef(null)
  const [isNA, setIsNA] = useState(false)

  useEffect(() => {
    if (pool.poolSize === undefined || pool.maxPoolSize === undefined) {
      setIsNA(true)
    } else {
      setIsNA(false)
    }
  }, [pool])

  const goToDetailPage = () => {
    navigate(`/pools/${pool.id}`)
  }

  const toggleDetails = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    toggleMenu()
  }
  const isVersionExpired = pool.versionLabel !== pool.latestVersionLabel;

  return (
    <Box sx={{ position: 'relative' }}>
      <StyledCard
        theme={theme}
        onClick={goToDetailPage}
        ref={ref}
      >
        <StyledCardContent>
          <Flex mb={3}>
            <CurrencyWrapper sx={{ mr: 2 }}>
              {pool.id === 1 ? (
                <SvgIcon
                  component={OpulSvg}
                  viewBox="-9.25 -2.5 54 45"
                  sx={{ width: theme.spacing(3), height: theme.spacing(3) }}
                />
              ) : (
                <MonetizationOnOutlinedIcon sx={{ width: theme.spacing(4) }} />
              )}
            </CurrencyWrapper>
            <Title>{pool.title}</Title>
          </Flex>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FlexWrapper>
                <StyleIconWrapper theme={theme}>
                  <PieChartIcon
                    dimmed={isVersionExpired}
                    sx={{ width: theme.spacing(2) }}
                  />
                </StyleIconWrapper>
                <Label>APY</Label>
              </FlexWrapper>
              <Value>{`${pool.minAnnualPercentageYield / 100}% - ${
                pool.maxAnnualPercentageYield / 100
              }%`}</Value>
            </Grid>
          </Grid>
          <PoolSize>
            <PoolSizeLeft>
              <FlexWrapper>
                <StyleIconWrapper theme={theme} isna={isNA}>
                  <PoolIcon sx={{ width: theme.spacing(2) }} />
                </StyleIconWrapper>
                <Label isna={isNA}>TVL</Label>
              </FlexWrapper>
            </PoolSizeLeft>
          </PoolSize>
          <Value>
            <OPULLabel value={BigInt(pool.poolSize).toString()} /> OPUL
          </Value>
        </StyledCardContent>
        <CardFooter>
          <StyleTag>View Pool</StyleTag>
          <StyleTagOutlined ref={detailButton} onClick={toggleDetails}>
            <span>Details</span>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </StyleTagOutlined>
        </CardFooter>
        <DetailWrapper open={isOpen}>
          <Details>
            <DetailInfo>
              <span>Stake Period</span>
              <span>
                {`${pool.minStakePeriod / 24 / 60 / 60} - ${
                  pool.maxStakePeriod / 24 / 60 / 60
                }`}{' '}
                days
              </span>
            </DetailInfo>
            <DetailInfo>
              <span>End Date</span>
              <span>
                {formatDate(
                  new Date(pool.endTimestamp)
                )}
              </span>
            </DetailInfo>
          </Details>
        </DetailWrapper>
        {pool.versionLabel && (
          <PoolVersionTag dimmed={isVersionExpired}>{pool.versionLabel}</PoolVersionTag>
        )}
      </StyledCard>
    </Box>
  )
}

export default PoolGridCard
