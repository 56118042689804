import axios, { AxiosResponse } from 'axios'
import { getApiBaseUrl } from '../../axios-setup'
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

export enum CognitoGroup {
  ADMIN = 'admin'
}

export interface IUserInfo {
  data: {
    sub: string
    email_verified: string
    email: string
    username: string
  }
}

export interface IAmplifyConfig {
  data: {
    Auth: {
      region: string
      userPoolId: string
      userPoolWebClientId: string
    }
  }
}

export async function getAdminAccessToken(): Promise<string|null> {
  const user: CognitoUser|null = await Auth.currentUserPoolUser().catch(() => null);
  if (!user) return null;
  const session = user.getSignInUserSession();
  if (!session) return null;
  return session.getAccessToken().getJwtToken();
}

export async function getUserGroups(): Promise<string[]> {
  const user: CognitoUser|null = await Auth.currentUserPoolUser().catch(() => null);
  if (!user) return [];
  const session = user.getSignInUserSession();
  if (!session) return [];
  const payload = session.getAccessToken().decodePayload();
  return payload['cognito:groups'] || [];
}

export async function hasAdminPermission(): Promise<boolean> {
  const userGroups = await getUserGroups();
  return userGroups.includes(CognitoGroup.ADMIN);
}

export async function getAdminAccessTokenOrRedirect() {
  const token = await getAdminAccessToken();
  if (!token) {
    window.location.pathname = '/admin/login';
    throw new Error('Admin access token not found');
  }
  if (!await hasAdminPermission()) {
    window.location.pathname = '/';
    throw new Error('No admin permission');
  }
  return token;
}

export async function getAdminAuthorizationHeader() {
  return {
    Authorization: `Bearer ${await getAdminAccessTokenOrRedirect()}`,
  };
}

export const getUserInfo = async (): Promise<AxiosResponse<IUserInfo>> => {
  return axios({
    method: 'GET',
    url: `${getApiBaseUrl()}/api/admin/user-info`,
    headers: await getAdminAuthorizationHeader(),
  });
}

export const getAmplifyConfig = async (): Promise<AxiosResponse<IAmplifyConfig>> => {
  return axios({
    method: 'GET',
    url: `${getApiBaseUrl()}/api/admin/amplify-config`,
  });
}
