import { ReactElement, useEffect, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import WalletContext from '@opulous/web/src/context/context';
import { styled } from '@mui/system';
import { Grid, Skeleton } from '@mui/material';
import {
  SkeletonCounter,
  SkeletonDetails,
  SkeletonFunds,
  SkeletonAudioPlayer,
} from '@opulous/web/src/components/Sales/SkeletonDetails';
import TabContext from '@mui/lab/TabContext';
import TabBar from '@opulous/web/src/components/TabBar';
import AlertError from '@opulous/web/src/components/shared/AlertError';
import {
  DetailsContainer,
  InfoCounter,
  InfoFunds,
  InfoStepper,
  SectionContainer,
  SectionTitle,
  SidebarDetailsWrapper,
  StyledPaper,
  StyledSection,
  StyledTabPanel,
  StyledTabWrapper,
} from '@opulous/web/src/pages/Sales/Investment/styled-components';
import InvestmentDetailsPage from '@opulous/web/src/pages/Sales/Investment/Details';
import InvestmentEmailConfirmationPage from '@opulous/web/src/pages/Sales/Investment/EmailConfirmation';
import InvestmentPaymentMethodPage from '@opulous/web/src/pages/Sales/Investment/PaymentMethod';
import InvestmentPurchaseConfirmationPage from '@opulous/web/src/pages/Sales/Investment/PurchaseConfirmation';
import InvestmentCongratulationPage from '@opulous/web/src/pages/Sales/Investment/Congratulation';
import InvestmentProvider from '@opulous/web/src/pages/Sales/Investment/provider';
import InvestmentContext from '@opulous/web/src/pages/Sales/Investment/context';
import {
  PurchaseSteps,
  Tabs,
} from '@opulous/web/src/pages/Sales/Investment/types';

const Wrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
}));

export function InvestmentPage(): ReactElement {
  const { state: investmentState, actions: investmentActions } = useContext(InvestmentContext);
  const {
    state: { wallet },
  } = useContext(WalletContext);
  const { investmentId } = useParams();
  const { investment, showAlert, purchaseFlow } = investmentState;

  useEffect(() => {
    if (investmentId && !Number.isNaN(investmentId)) {
      investmentActions.loadInvestment(Number(investmentId), wallet);
    }
  }, [wallet]);

  const CurrentStep = useMemo(() => {
    if (investment) {
      switch (purchaseFlow?.currentStep) {
        case PurchaseSteps.CONFIRMATION: {
          return <InvestmentEmailConfirmationPage />;
        }
        case PurchaseSteps.PAYMENT_METHOD: {
          return <InvestmentPaymentMethodPage />;
        }
        case PurchaseSteps.PURCHASE: {
          return <InvestmentPurchaseConfirmationPage />;
        }
        case PurchaseSteps.CONGRATULATION: {
          return <InvestmentCongratulationPage />;
        }
      }
      return <InvestmentDetailsPage />;
    }
    return;
  }, [investment, purchaseFlow?.currentStep]);

  if (investmentState.loading) {
    return (
      <Wrapper data-testid="investment-page__skeleton">
        <Grid container spacing={6}>
          <Grid item xl={8} xs={12}>
          <DetailsContainer>
            <StyledPaper>
              <SkeletonDetails />
            </StyledPaper>
            <SkeletonAudioPlayer />
            <StyledTabWrapper>
              <TabContext value={Tabs[0].value}>
                <TabBar
                  tabs={Tabs}
                  onChangTab={() => {
                    return;
                  }}
                />
                <StyledTabPanel value={Tabs[0].value}>
                  <StyledSection>
                    <SectionTitle>
                      <Skeleton variant="text" width={200} height={70} />
                    </SectionTitle>
                    <SectionContainer>
                      <Skeleton variant="text" width="60%" height={40} />
                      <Skeleton variant="text" width="60%" height={40} />
                      <Skeleton variant="text" width="60%" height={40} />
                      <Skeleton variant="text" width="60%" height={40} />
                      <Skeleton variant="text" width="60%" height={40} />
                    </SectionContainer>
                  </StyledSection>
                </StyledTabPanel>
              </TabContext>
            </StyledTabWrapper>
          </DetailsContainer>
          </Grid>
          <Grid item xl={4} xs={12}>
            <SidebarDetailsWrapper>
              <InfoFunds>
                <SkeletonFunds />
              </InfoFunds>
              <InfoStepper>
                <Skeleton variant="text" width="100%" height={70} />
              </InfoStepper>
              <InfoCounter>
                <SkeletonCounter />
              </InfoCounter>
            </SidebarDetailsWrapper>
          </Grid>
        </Grid>
      </Wrapper>
    );
  }

  return (
    <Wrapper data-testid="investment-page">
      <Helmet>
        <meta charSet="utf-8" />
        {/* OPEN GRAPH META */}
        <meta
          property="og:title"
          content={investment?.asset?.nftDescription?.name}
        />
        <meta
          property="og:image"
          content={investment?.asset?.nftDescription?.image}
        />
        <meta
          property="og:description"
          content={investment?.asset?.nftDescription?.description}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      {showAlert?.error && (
        <AlertError
          onClose={() => investmentActions.setShowAlertError(false)}
          message={
            showAlert?.message || 'There was an error with the page loading'
          }
          showSupportEmailMessage={showAlert?.showSupportEmailMessage}
        />
      )}
      {CurrentStep}
    </Wrapper>
  );
}

export default (): ReactElement => (
  <InvestmentProvider>
    <InvestmentPage />
  </InvestmentProvider>
);
