import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  AnalyticsResponseData,
  APIResponse,
} from '@opulous/web/src/shared/types';

export const fetchData = async (
  walletAddress: string,
  filter: {
    view: string;
    by: string;
    days: number;
    releaseId?: number;
  },
): Promise<AnalyticsResponseData> => {
  try {
    const urlParams = new URLSearchParams();
    urlParams.append('view', filter.view);
    urlParams.append('type', filter.by);
    urlParams.append('days', `${filter.days}`);
    if (filter.releaseId) {
      urlParams.append('releaseId', `${filter.releaseId}`);
    }

    const axiosResponse = await axios.get<APIResponse<AnalyticsResponseData>>(
      `/api/analytics/${walletAddress}?${urlParams.toString()}`,
    );
    return axiosResponse.data.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
