
import { ReactElement } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Box,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/system';
import { formatNumber } from '@opulous/web/src/utils';
import { AnalyticsTrendsTypeMap } from '@opulous/web/src/shared/types';

const AnalyticsTableWrapper = styled(Paper)(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.grey[100]}`,
  marginBottom: theme.spacing(3),
}));

const TableHeader = styled(TableCell)(({ theme }) => ({
  color: theme.palette.grey[400],
  padding: theme.spacing(1.5, 3),
  fontSize: '0.875rem',
  fontWeight: 600,
}));

const BaseTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.grey[800],
  padding: theme.spacing(1.5, 3),
  fontSize: '0.875rem',
  fontWeight: 600,
}));

const TruncatedTableCell = styled(BaseTableCell)(({ theme }) => ({
  textOverflow: 'ellipsis',
  maxWidth: theme.spacing(21),
  overflow: 'hidden',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const SKELETON_ROWS = new Array(5).fill(1);

type Props = {
  loading: boolean;
  filterSelected: {
    by: keyof typeof AnalyticsTrendsTypeMap,
  }
  data: {
    color?: string;
    name: string;
    total: number;
  }[];
};
export default function AnalyticsTable({
  loading,
  filterSelected,
  data,
}: Props): ReactElement {

  return (
    <AnalyticsTableWrapper data-testid="analytics-table">
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow data-testid="analytics-table__thead">
              <TableHeader>
                {AnalyticsTrendsTypeMap[filterSelected.by]?.label || 'Name'}
              </TableHeader>
              <TableHeader align="right">
                Total
              </TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              SKELETON_ROWS.map((it, index) => (
                <TableRow data-testid="analytics-table__tbody-loading" key={`table-item-${index}`}>
                  <BaseTableCell>
                    <Skeleton variant="text" />
                  </BaseTableCell>
                  <BaseTableCell>
                    <Skeleton variant="text" />
                  </BaseTableCell>
                </TableRow>
              ))}
            {!loading &&
              data.map((row, index) => (
                <TableRow data-testid="analytics-table__tbody-row" key={`table-item-${index}`}>
                  <TruncatedTableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {row.color && (<Box
                        data-testid="analytics-table__tbody-row__color"
                        sx={{
                          width: 20,
                          height: 15,
                          background: row.color,
                          borderRadius: 0.2,
                          marginRight: 1,
                        }}
                      />)}
                      <span data-testid="analytics-table__tbody-row__name">{row.name}</span>
                    </Box>
                  </TruncatedTableCell>
                  <BaseTableCell align="right" data-testid="analytics-table__tbody-row__total">
                    {formatNumber(row.total)}
                  </BaseTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </AnalyticsTableWrapper>
  );
}
