import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PopoverMenu from '@opulous/web/src/components/Common/PopoverMenu';

const UnclaimedAssetsWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  margin: 16,
  width: 232,
}));

const NotificationButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: `${theme.spacing(0.75, 0)} !important`,
  [theme.breakpoints.down('lg')]: {
    minWidth: theme.spacing(2),
    padding: `${theme.spacing(0.75, 0)} !important`,
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const OptInButton = styled(Button)(() => ({
  width: 208,
  height: 32,
  marginTop: 16,
  padding: 0,
}));

const NotificationPopOver = styled(PopoverMenu)(() => ({
  width: 296,
  marginLeft: -16,
}));

const NotificationBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    fontSize: 5,
  },
}));

interface MenuProps {
  count: number;
}

const NotificationIcon = styled(NotificationsIcon)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

const Notifications: React.FC<MenuProps> = ({ count }) => {
  const navigate = useNavigate();
  const navigateToClaim = () => {
    navigate('/claim');
  };

  return (
    <NotificationPopOver
      anchor={
        <NotificationButton variant="text">
          <NotificationBadge badgeContent={count} color="error">
            <NotificationIcon />
          </NotificationBadge>
        </NotificationButton>
      }
    >
      <UnclaimedAssetsWrapper>
        {count === 0 && (
          <Typography variant="body1" color="textSecondary">
            You don't have any new notifications
          </Typography>
        )}{' '}
        {count > 0 && (
          <>
            <Typography variant="body1" color="textSecondary">
              You have {count} new unclaimed assets
            </Typography>
            <OptInButton onClick={navigateToClaim} color="primary">
              Opt-in to claim assets
            </OptInButton>
          </>
        )}
      </UnclaimedAssetsWrapper>
    </NotificationPopOver>
  );
};

export default Notifications;
