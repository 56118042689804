import { SortOption } from './types'

export const ValidAddressLength = 58
const PrefixLength = 3
const SuffixLength = 3

export const formatWallet = (
  wallet: string,
  prefix: number = PrefixLength,
  suffix: number = SuffixLength
): Error | string => {
  if (!wallet) {
    throw new Error('Wallet is required')
  }

  if (wallet.length !== ValidAddressLength) {
    throw new Error('Invalid wallet length provided')
  }

  return `${wallet.slice(0, prefix)}...${wallet.slice(-1 * suffix)}`
}

export function prepareSortOption(
  column: string,
  previousSortOptions?: SortOption
): SortOption {
  let nextSortOption = { direction: 'asc', column } as SortOption
  if (previousSortOptions && previousSortOptions.column === column) {
    nextSortOption = {
      direction: previousSortOptions.direction === 'asc' ? 'desc' : 'asc',
      column,
    }
  }
  return nextSortOption
}

export function createHexColor(id: number): string {
  const sin = Math.sin(id + 5) * 10000;
  return `#${Math.floor((sin - Math.floor(sin)) * 16777215).toString(16)}`
}

export function getCountryOptionsMap(): Map<string, string> {
  return new Map<string, string>([
    ['al', 'Albania'],
    ['ad', 'Andorra'],
    ['ar', 'Argentina'],
    ['am', 'Armenia'],
    ['aw', 'Aruba'],
    ['au', 'Australia'],
    ['at', 'Austria'],
    ['az', 'Azerbaijan'],
    ['bd', 'Bangladesh'],
    ['bb', 'Barbados'],
    ['by', 'Belarus'],
    ['be', 'Belgium'],
    ['bz', 'Belize'],
    ['bo', 'Bolivia'],
    ['br', 'Brazil'],
    ['bn', 'Brunei'],
    ['bg', 'Bulgaria'],
    ['ca', 'Canada'],
    ['cl', 'Chile'],
    ['cn', 'China'],
    ['co', 'Colombia'],
    ['cr', 'Costa Rica'],
    ['hr', 'Croatia'],
    ['cu', 'Cuba'],
    ['cw', 'Curacao'],
    ['cy', 'Cyprus'],
    ['cz', 'Czech Republic'],
    ['dk', 'Denmark'],
    ['do', 'Dominican Rep.'],
    ['ec', 'Ecuador'],
    ['eg', 'Egypt'],
    ['sv', 'El Salvador'],
    ['ee', 'Estonia'],
    ['fo', 'Faroe Islands'],
    ['fi', 'Finland'],
    ['fr', 'France'],
    ['ge', 'Georgia'],
    ['de', 'Germany'],
    ['gi', 'Gibraltar'],
    ['gr', 'Greece'],
    ['gl', 'Greenland'],
    ['gt', 'Guatemala'],
    ['gg', 'Guernsey'],
    ['hk', 'Hong Kong'],
    ['hu', 'Hungary'],
    ['is', 'Iceland'],
    ['in', 'India'],
    ['id', 'Indonesia'],
    ['ie', 'Ireland'],
    ['il', 'Israel'],
    ['it', 'Italy'],
    ['jm', 'Jamaica'],
    ['jp', 'Japan'],
    ['je', 'Jersey'],
    ['kz', 'Kazakhstan'],
    ['kw', 'Kuwait'],
    ['kg', 'Kyrgyzstan'],
    ['lv', 'Latvia'],
    ['li', 'Liechtenstein'],
    ['lt', 'Lithuania'],
    ['lu', 'Luxembourg'],
    ['my', 'Malaysia'],
    ['mt', 'Malta'],
    ['mu', 'Mauritius'],
    ['mx', 'Mexico'],
    ['md', 'Moldova'],
    ['mc', 'Monaco'],
    ['ma', 'Morocco'],
    ['nl', 'Netherlands'],
    ['nz', 'New Zealand'],
    ['ni', 'Nicaragua'],
    ['no', 'Norway'],
    ['pk', 'Pakistan'],
    ['py', 'Paraguay'],
    ['pe', 'Peru'],
    ['ph', 'Philippines'],
    ['pl', 'Poland'],
    ['pt', 'Portugal'],
    ['pr', 'Puerto Rico'],
    ['ro', 'Romania'],
    ['ru', 'Russia'],
    ['ws', 'Samoa'],
    ['sm', 'San Marino'],
    ['rs', 'Serbia'],
    ['sk', 'Slovakia'],
    ['si', 'Slovenia'],
    ['za', 'South Africa'],
    ['kr', 'South Korea'],
    ['es', 'Spain'],
    ['lk', 'Sri Lanka'],
    ['se', 'Sweden'],
    ['ch', 'Switzerland'],
    ['tj', 'Tajikistan'],
    ['th', 'Thailand'],
    ['tt', 'Trinidad'],
    ['tn', 'Tunisia'],
    ['tr', 'Turkey'],
    ['tm', 'Turkmenistan'],
    ['ua', 'Ukraine'],
    ['gb', 'United Kingdom'],
    ['us', 'United States'],
    ['uy', 'Uruguay'],
    ['uz', 'Uzbekistan'],
    ['ve', 'Venezuela'],
  ]);
}

export async function delay(milliseconds: number): Promise<void> {
  await new Promise(r => setTimeout(r, milliseconds));
}
