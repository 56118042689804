import { ReactElement } from 'react';

export function Pause({ onClick }: { onClick: () => void }): ReactElement {
  return (
    <svg
      data-testid="audio-player__controls__pause"
      onClick={onClick}
      style={{ backgroundColor: '#ffffff', borderRadius: '50%' }}
      width="32"
      height="32"
      viewBox="0 0 45.812 45.812"
    >
      <g>
        <g>
          <g>
            <path
              d="M39.104,6.708c-8.946-8.943-23.449-8.946-32.395,0c-8.946,8.944-8.946,23.447,0,32.394
          c8.944,8.946,23.449,8.946,32.395,0C48.047,30.156,48.047,15.653,39.104,6.708z M20.051,31.704c0,1.459-1.183,2.64-2.641,2.64
          s-2.64-1.181-2.64-2.64V14.108c0-1.457,1.182-2.64,2.64-2.64s2.641,1.183,2.641,2.64V31.704z M31.041,31.704
          c0,1.459-1.183,2.64-2.64,2.64s-2.64-1.181-2.64-2.64V14.108c0-1.457,1.183-2.64,2.64-2.64s2.64,1.183,2.64,2.64V31.704z"
              fill="#000000"
              stroke="#000000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function Play({ onClick }: { onClick: () => void }): ReactElement {
  return (
    <svg
      data-testid="audio-player__controls__play"
      onClick={onClick}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx="16" cy="16" r="16" fill="#23262F" />
      <path
        d="M20.7286 15.8304L13.306 11.1912C13.1728 11.108 13 11.2038 13 11.3608V20.6392C13 20.7962 13.1728 20.892 13.306 20.8088L20.7286 16.1696C20.854 16.0913 20.854 15.9087 20.7286 15.8304Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
