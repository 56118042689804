import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Collapse, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, CircularProgress as _CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import Papa from 'papaparse';
import * as RoyaltyService from 'src/services/royalties';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formatDateTimeUtc } from 'src/utils';

const PAGE_SIZE = 10;
const FILE_NAME_PLACEHOLDER = '<File Name>';

const CircularProgress = styled(_CircularProgress)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '80rem',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const ErrText = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: '700',
  fontSize: '.9rem',
}));

const Fieldset = styled('fieldset')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  color: theme.palette.text.primary,
  display: 'block',
  position: 'relative',
  minWidth: 0,

  '&:hover': {
    border: `1px solid ${theme.palette.common.black}`,
  },

  '&.error': {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },

  '& > legend': {
    fontSize: '.75rem',
    fontWeight: '300',
  },
}));

const FileKeyContainer = styled('div')(() => ({
  overflowWrap: 'anywhere',
}));

async function parseCsvFile(file: File): Promise<Papa.ParseResult<string[]>> {
  return new Promise(resolve => {
    Papa.parse<string[]>(file, {
      header: false,
      skipEmptyLines: true,
      complete(results) {
        return resolve(results);
      },
    });
  });
}

function validateCsvFileColumns(csv: string[][]): Error | null {
  csv; // TODO: validate csv file columns
  return null;
}

function RoyaltySalesTableRow({
  saleFile,
}: {
  saleFile: RoyaltyService.IRoyaltiesSalesFile
}) {
  const [open, setOpen] = useState(false);
  const [isFetchingDownloadUrl, setIsFetchingDownloadUrl] = useState(false);

  const handleDownload = async () => {
    setIsFetchingDownloadUrl(true);
    const downloadUrl = await RoyaltyService.adminGetRoyaltiesSalesDownloadUrl(saleFile.fileName);
    const el = document.createElement('a');
    el.setAttribute('target', '_blank');
    el.setAttribute('href', downloadUrl);
    setIsFetchingDownloadUrl(false);
    el.click();
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" >
          <Box
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            maxWidth="16rem"
            title={saleFile.displayName || saleFile.fileName}
          >
            {saleFile.displayName || saleFile.fileName}
          </Box>
        </TableCell>
        <TableCell align="right">{saleFile.status}</TableCell>
        <TableCell align="right">{formatDateTimeUtc(new Date(saleFile.createdAt))}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display="flex" flexDirection="column" padding={1} gap={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>File Key</TableCell>
                    <TableCell>
                      <FileKeyContainer>
                        {saleFile.fileName}
                      </FileKeyContainer>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Uploader ID</TableCell>
                    <TableCell>{saleFile.uploadedBy || '-'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Box
                display="flex"
                justifyContent="flex-end"
                flexWrap="wrap"
                paddingY={1}
                gap={1}
              >
                <Button
                  size="small"
                  onClick={handleDownload}
                >
                  {isFetchingDownloadUrl ? (<CircularProgress size="1.2rem" />) : 'Download'}
                </Button>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function SkeletonRoyaltySalesTable() {
  return (
    <Box display="flex" flexDirection="column" overflow="auto">
      <TableContainer component={Box}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>File</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Creation date (UTC Time)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {new Array(3).fill(3).map((_row, i) => (
              <TableRow key={`skeleton-row-${i}`}>
                <TableCell>
                  <IconButton size="small" disabled>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" >
                  <Skeleton width="14rem" />
                </TableCell>
                {new Array(2).fill(null).map((_field, j) => (
                  <TableCell key={`skeleton-field-${j}`} align="right">
                    <Box display="inline-block"><Skeleton width="10rem" /></Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function RoyaltySalesTable({
  rows,
  count,
  page,
  pageSize,
  onPageChange,
}: {
  rows: RoyaltyService.IRoyaltiesSalesFile[]
  count: number
  page: number
  pageSize: number
  onPageChange: (p: number) => void
}) {
  return (
    <Box display="flex" flexDirection="column" overflow="auto">
      <TableContainer component={Box}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>File</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Creation date (UTC Time)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <RoyaltySalesTableRow key={row.fileName} saleFile={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[pageSize]}
        count={count}
        rowsPerPage={pageSize}
        page={page - 1}
        onPageChange={(_e, p) => onPageChange(p + 1)}
      />
    </Box>
  )
}

export default function RewardPage(): React.ReactElement {
  const csvFileInputRef = useRef<HTMLInputElement | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [csvFileName, setCsvFileName] = useState(FILE_NAME_PLACEHOLDER);

  const [page, setPage] = useState(1);
  const [royaltySaleFiles, setRoyaltySaleFiles] = useState<RoyaltyService.IRoyaltiesSalesFile[]>([]);
  const [numRoyaltySaleFiles, setNumRoyaltySaleFiles] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  async function fetchRoyaltySaleFiles() {
    setIsLoading(true);
    const { data, count } = await RoyaltyService.adminGetRoyaltiesSalesFiles(page, PAGE_SIZE);
    setRoyaltySaleFiles(data);
    setNumRoyaltySaleFiles(count);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchRoyaltySaleFiles();
  }, [page]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCsvFileName(e.target.files?.[0]?.name ?? FILE_NAME_PLACEHOLDER);
  };

  const handleFileClear = () => {
    if (csvFileInputRef.current) {
      csvFileInputRef.current.value = '';
      setCsvFileName(FILE_NAME_PLACEHOLDER);
    }
  };

  const handleCsvFileUpload = async () => {
    if (!csvFileInputRef.current) return;

    const files = csvFileInputRef.current.files;
    const file = files?.[0];

    if (file) {
      const parseResult = await parseCsvFile(file);
      if (parseResult.errors.length) {
        const err = parseResult.errors[0];
        setErrorMessage(`${err.message} at row ${err.row + 1}`);
      } else {
        const validationErr = validateCsvFileColumns(parseResult.data);
        if (validationErr) {
          setErrorMessage(validationErr.message);
        } else {
          setErrorMessage('');

          setIsUploading(true);
          await RoyaltyService.adminUploadRoyaltySalesCsvFile(
            file.name,
            Papa.unparse(parseResult.data, {
              delimiter: ';',
              skipEmptyLines: true,
            }),
          )
          setIsUploading(false);

          csvFileInputRef.current.value = '';
          setCsvFileName(FILE_NAME_PLACEHOLDER);

          setPage(1);
          await fetchRoyaltySaleFiles();
        }
      }
    } else {
      setErrorMessage('Please select an royalty sales CSV file');
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={3} paddingBottom={4}>
      <Container>
        <div>
          <h1>Rewards</h1>
        </div>

        <Fieldset>
          <legend>Royalty sales CSV file upload</legend>
          <input
            style={{ display: 'none' }}
            ref={csvFileInputRef}
            type="file"
            accept="text/csv"
            onChange={handleFileChange}
          />

          <Box display="flex" flexDirection="column" gap={2} padding={3} boxSizing="border-box">
            <Box display="flex" flexDirection="column" gap={1} minWidth={0} overflow="hidden">
              <Box paddingX={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {csvFileName}
              </Box>
              <Box display="flex" gap={1} flexWrap="wrap">
                <Button size="small" onClick={() => csvFileInputRef.current?.click()}>Choose File</Button>
                <Button size="small" onClick={handleFileClear}>Clear</Button>
              </Box>
            </Box>

            <Box minHeight="2rem">
              <ErrText>{errorMessage}</ErrText>
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <Button onClick={handleCsvFileUpload}>
                {isUploading
                  ? (<CircularProgress size="1.5rem" />)
                  : 'Upload'}
              </Button>
            </Box>
          </Box>
        </Fieldset>

        {isLoading ? (
          <SkeletonRoyaltySalesTable />
        ) : (
          <RoyaltySalesTable
            pageSize={PAGE_SIZE}
            page={page}
            count={numRoyaltySaleFiles}
            rows={royaltySaleFiles}
            onPageChange={p => setPage(p)}
          />
        )}
      </Container>
    </Box>
  );
}
