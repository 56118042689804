import React, { useRef, useState } from 'react';
import { TextField, TextFieldProps, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import OPULLabel from '@opulous/web/src/components/shared/OPULLabel';

const MAX_DECIMAL_PLACES = 10;
const MAX_LEN = MAX_DECIMAL_PLACES * 3;

const useStyles = makeStyles((theme: Theme) => createStyles({
  input: {
    border: 0,
    outline: 0,

    '& input': {
      fontSize: theme.spacing(4),
      fontWeight: '300',
      paddingTop: theme.spacing(.5),
      paddingBottom: theme.spacing(.5),
    },
  },

  displayText: {
    fontSize: theme.spacing(4),
    lineHeight: theme.spacing(5.75),
    fontWeight: '300',
    padding: '16.5px 14px',
    borderRadius: theme.spacing(2),
    boxSizing: 'border-box',
    cursor: 'text',
    overflow: 'hidden',
    overflowWrap: 'anywhere',
  },

  displayContainer: {
    width: '100%',

    '& .error-text': {
      color: theme.palette.error.main,
    },

    '& .error-container': {
      borderColor: theme.palette.error.main,
    },
  },

  displayHelperText: {
    color: 'rgba(0, 0, 5, 0.5)', // MUI TextField uses the same color
    fontSize: '0.875rem',
    fontWeight: '600',
    lineHeight: '1.25rem',
    margin: '3px 14px 0 14px',
  },
}));

function OpulInput({
  className,
  style,
  onChange,
  value,
  helperText,
  error,
  displayTextStyle,
  ...props
}: Omit<TextFieldProps, 'onChange'|'value'> & {
  onChange: (n: string) => void
  value: string
  displayTextStyle?: React.CSSProperties
}): React.ReactElement {
  const classes = useStyles();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => setIsFocus(true);
  const handleBlur = () => setIsFocus(false);

  const handleChange = () => {
    const v = inputRef.current?.value || '';
    let str = '';
    let floatingPointCount = 0;
    let decimalPlaces = 0;
    for (const chr of v) {
      if (chr >= '0' && chr <= '9') {
        str += chr;
        if (floatingPointCount) {
          decimalPlaces++;
          if (decimalPlaces >= MAX_DECIMAL_PLACES) break;
        }
      } else if (chr === '.') {
        if (floatingPointCount < 1) {
          str += chr;
          floatingPointCount++;
        } else {
          break;
        }
      }

      if (str.length >= MAX_LEN) break;
    }
    onChange(str);
  };

  if (!isFocus) {
    return (
      <div
        className={`${classes.displayContainer} ${className ?? ''}`}
        style={style}
      >
        <div
          className={`${classes.displayText} ${error ? 'error-container' : ''}`}
          style={displayTextStyle}
          tabIndex={0}
          onClick={handleFocus}
          onFocus={handleFocus}
        >
          {value ? (<OPULLabel value={(parseFloat(value) * 1e10).toString()} />) : '-'}
        </div>
        {helperText && (
          <p className={`${classes.displayHelperText} ${error ? 'error-text' : ''}`}>
            {helperText}
          </p>
        )}
      </div>
    );
  }

  return (
    <TextField
      className={`${classes.input} ${className ?? ''}`}
      style={style}
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      onPaste={handleChange}
      helperText={helperText}
      error={error}
      inputRef={inputRef}
      autoFocus
      {...props}
    />
  );
}

export default OpulInput;
