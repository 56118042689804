import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/system';
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import * as Yup from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as _Link, useNavigate, useParams } from 'react-router-dom';
import { ICreatePool, PoolVersion } from 'src/shared/types';
import { validateField } from 'src/utils';
import PoolService from 'src/services/pools';
import ErrorIcon from '@mui/icons-material/Error';
import * as Sentry from '@sentry/react';
import axios from 'axios';

interface IValidationErrors {
  appId: string;
  address: string;
  title: string;
  description: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  versionLabel: string;
}

interface IDirtyLookup {
  appId: boolean;
  address: boolean;
  title: boolean;
  description: boolean;
  type: boolean;
  createdAt: boolean;
  updatedAt: boolean;
  versionLabel: boolean;
}

const ErrorMessage = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `2px solid ${theme.palette.error.main}`,
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.error.light,
  padding: theme.spacing(2),
  color: theme.palette.error.main,
  gap: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));

const Link = styled(_Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  gap: theme.spacing(1),

  '& > svg': {
    width: '1rem',
    height: '1rem',
  },
}));

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
  maxWidth: '50rem',
}));

const NULLABLE_FIELDS = new Set([
  'address',
  'versionLabel',
]);

const fieldValidators = {
  appId: Yup.string()
    .matches(/^\d+$/, 'Invalid app ID format'),
  address: Yup.string()
    .matches(/^[A-Z2-7]{58}$/, 'Invalid address format'),
  title: Yup.string(),
  description: Yup.string(),
  type: Yup.mixed().oneOf(Object.values(PoolVersion)),
  versionLabel: Yup.string(),
};

export default function PoolCreate(): React.ReactElement {
  const navigate = useNavigate();
  const { id: poolId } = useParams();
  const dirtyLookup = useRef<Partial<IDirtyLookup>>({}).current;

  const [appId, setAppId] = useState('');
  const [address, setAddress] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState<PoolVersion>(PoolVersion.v2);
  const [versionLabel, setVersionLabel] = useState('');
  const [validationErrors, setValidationErrors] = useState<Partial<IValidationErrors>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState<string>('');

  const markDirty = (name: keyof typeof dirtyLookup) => {
    dirtyLookup[name] = true;
    setValidationErrors(errs => { delete errs[name]; return { ...errs }; });
  };

  useEffect(() => {
    (async () => {
      if (!poolId || !/^\d+$/g.test(poolId)) {
        navigate('/admin/pools/create');
        return;
      }

      setIsLoading(true);
      const r = await PoolService.adminGetPool(parseInt(poolId));
      if (!r) {
        navigate('/admin/pools/create');
        return;
      }

      setAppId(r.appId.toString());
      setAddress(r.address || '');
      setTitle(r.title);
      setDescription(r.description);
      setType(r.type);
      setVersionLabel(r.versionLabel || '');
      setIsLoading(false);
    })();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!poolId) return;

    const errs: Partial<IValidationErrors> = {};

    for (const [name, value] of ([
      ['appId', appId],
      ['address', address],
      ['title', title],
      ['description', description],
      ['type', type],
      ['versionLabel', versionLabel],
    ] as [keyof typeof fieldValidators, any][])) {
      if (!dirtyLookup[name]) continue;
      if (NULLABLE_FIELDS.has(name) && !value) continue;
      const msg = await validateField(fieldValidators[name], value);
      if (msg) {
        errs[name] = msg;
      }
    }

    setValidationErrors(errs);

    if (Object.keys(errs).length) return;

    try {
      setIsSubmitting(true);
      const data: Partial<ICreatePool> = {
        ...(dirtyLookup.appId ? { appId: parseInt(appId) } : null),
        ...(dirtyLookup.address ? { address: address || null } : null),
        ...(dirtyLookup.title ? { title } : null),
        ...(dirtyLookup.description ? { description } : null),
        ...(dirtyLookup.type ? { type } : null),
        ...(dirtyLookup.versionLabel ? { versionLabel: versionLabel || null } : null),
      };

      await PoolService.adminEditPools(parseInt(poolId), data);

      navigate('/admin/pools');
      setIsSubmitting(false);
    } catch (err) {
      if (
        axios.isAxiosError(err) &&
        err.response?.status === 400 &&
        err.response?.data?.errors?.length &&
        err.response?.data?.errors?.[0]?.message
      ) {
        setSubmissionErrorMessage(err.response?.data?.errors?.[0]?.message);
      } else {
        setSubmissionErrorMessage('Unexpected error occurred, please try again later.');
      }
      Sentry.captureException(err);
      setIsSubmitting(false);
    }
  }

  if (isLoading) {
    return (
      <Box marginY={4} display="flex" flexDirection="column" gap={4} alignItems="center">
        <CircularProgress size="3rem" color="primary" />
        <div>Retrieving pool data...</div>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={3}>
      <Form onSubmit={handleSubmit}>
        <div>
          <Link to="/admin/pools">
            <ArrowBackIcon />
            <span>Back</span>
          </Link>
        </div>
        <div>
          <h1>Edit Pool</h1>
        </div>
        <TextField
          label="App ID"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.appId}
          helperText={validationErrors.appId}
          value={appId}
          onChange={e => {
            markDirty('appId');
            setAppId(e.target.value);
          }}
        />
        <TextField
          label="Address (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.address}
          helperText={validationErrors.address}
          value={address}
          onChange={e => {
            markDirty('address');
            setAddress(e.target.value);
          }}
        />
        <TextField
          label="Title"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.title}
          helperText={validationErrors.title}
          value={title}
          onChange={e => {
            markDirty('title');
            setTitle(e.target.value);
          }}
        />
        <FormControl>
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            label="Type"
            onChange={e => {
              markDirty('type');
              setType(e.target.value as PoolVersion);
            }}
          >
            <MenuItem value={PoolVersion.v1}>{PoolVersion.v1}</MenuItem>
            <MenuItem value={PoolVersion.v2}>{PoolVersion.v2}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Description"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.description}
          helperText={validationErrors.description}
          rows={8}
          multiline
          value={description}
          onChange={e => {
            markDirty('description');
            setDescription(e.target.value);
          }}
        />
        <TextField
          label="Version Label (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.versionLabel}
          helperText={validationErrors.versionLabel}
          value={versionLabel}
          onChange={e => {
            markDirty('versionLabel');
            setVersionLabel(e.target.value);
          }}
        />

        {!!submissionErrorMessage && (
          <ErrorMessage>
            <ErrorIcon />
            <Box flex="1">{submissionErrorMessage}</Box>
          </ErrorMessage>
        )}

        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (<CircularProgress size="1.5rem" color="primary" />) : 'Submit'}
        </Button>
      </Form>
    </Box>
  );
}
