import { ReactElement } from 'react';
import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {
  ISidebarItem,
  SidebarItemContentType,
  ISidebarItemContent,
  IContentLabelListItem,
  IContentIconListItem,
  IContentTimeLineItem,
  IContentPieChartItem,
} from '@opulous/web/src/shared/types';
import {
  ContentIconList,
  ContentTimeline,
  ContentLabelList,
  ContentPieChart,
} from '@opulous/web/src/components/Assets/Songs/SongDetailTab/components/SidebarItem/components';

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.grey['A100'],
  marginTop: theme.spacing(4),
}));

const WrappedCard = styled(StyledCard)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const Title = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '27px',
}));

const Description = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  marginBottom: theme.spacing(3),
}));

const SidebarItem = ({
  title,
  description,
  content,
}: ISidebarItem): ReactElement => {
  const renderSidebarContent = (contentItem: ISidebarItemContent) => {
    switch (contentItem.type) {
      case SidebarItemContentType.labels:
        return (
          <ContentLabelList
            items={contentItem.items as IContentLabelListItem[]}
          />
        );
      case SidebarItemContentType.iconList:
        return (
          <ContentIconList
            items={contentItem.items as IContentIconListItem[]}
          />
        );
      case SidebarItemContentType.timeline:
        return (
          <ContentTimeline
            items={contentItem.items as IContentTimeLineItem[]}
          />
        );
      case SidebarItemContentType.piechart:
        return (
          <ContentPieChart
            items={contentItem.items as IContentPieChartItem[]}
            description={contentItem.description}
          />
        );
      default:
        return null;
    }
  };

  return (
    <WrappedCard>
      <Title gutterBottom>{title}</Title>
      <Description>{description}</Description>
      <div>{content.map(contentItem => renderSidebarContent(contentItem))}</div>
    </WrappedCard>
  );
};

export default SidebarItem;
