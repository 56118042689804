import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import algosdk from 'algosdk';
import { styled } from '@mui/system';
import * as Sentry from '@sentry/react';
import { useFormik } from 'formik';
import {} from '@mui/material';
import ConnectWallet from '@opulous/web/src/components/Common/ConnectWallet';
import ErrorMessage from '@opulous/web/src/components/ErrorMessage';
import WalletContext from '@opulous/web/src/context/context';
import { WalletTypeEnum } from '@opulous/web/src/shared/types';
import { investor as InvestorService } from '@opulous/web/src/services';
import {
  Alert,
  Box,
  Fade,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  InputBase,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Skeleton,
} from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import { StepIconProps } from '@mui/material/StepIcon';
import countries from '@opulous/web/src/constant/countries.json';
import AssetClaimCard from '@opulous/web/src/components/Claims/AssetClaimCard';
import { Asset } from '@opulous/web/src/shared/types';
import {
  Step1Schema,
  Step2Schema,
  Step3Schema,
  Step3SchemaWithouTax,
} from '@opulous/web/src/validations/verify';
import * as myAlgoService from '@opulous/web/src/services/my-algo';
import ToolTipTextField from '@opulous/web/src/components/ToolTipTextField';
import PeraInfoModal from '@opulous/web/src/components/Wallet/PeraInfoModal';
import MyAlgoWarningDialog from 'src/components/shared/MyAlgoWarningDialog';

const StyledBox = styled(Box)(() => ({
  height: 'calc(100vh - 80px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const StyleCard = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow:
    '0px 3px 6px rgba(0, 0, 0, 0.12), 0px 12px 24px rgba(0, 0, 0, 0.1), 0px 30px 60px rgba(0, 0, 0, 0.12)',
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '24px',
  width: '410px',
  maxWidth: '410px',
}));

const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

const TwoLine = styled('div')(() => ({
  borderRadius: '24px',
  display: 'flex',
  gap: '16px',
  '>div': {
    flexBasis: '50%',
  },
}));

const Wrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 'calc(100vh - 80px)',
}));

const CardTitle = styled('div')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '28px',
  color: theme.palette.common.black,
}));

const CardBody = styled('div')(({ theme }) => ({
  fontWeight: 300,
  fontSize: '14px',
  lineHeight: '150%',
  color: theme.palette.grey[700],
}));

const StyledInput = styled(TextField)<{ error?: boolean }>(
  ({ error, theme }) => ({
    '& > div, .MuiFilledInput-root::before, .MuiFilledInput-root::after': {
      borderTopLeftRadius: '8px !important',
      borderTopRightRadius: '8px !important',
      borderBottomLeftRadius: '8px !important',
      borderBottomRightRadius: '8px !important',
      border: '0px',
      content: 'none',
    },
    input: {
      background: theme.palette.grey['A100'],
      fontSize: '16px',
      lineHeight: '150%',
      fontWeight: '400',
      borderTopLeftRadius: '8px !important',
      borderTopRightRadius: '8px !important',
      borderBottomLeftRadius: '8px !important',
      borderBottomRightRadius: '8px !important',
      border: error ? '1px solid ' + theme.palette.error.main : 'none',
    },
    '.Mui-error': {
      fontWeight: 300,
      fontSize: '14px',
    },
  }),
);

const StyledCodeInput = styled(TextField)<{ error?: boolean }>(
  ({ error, theme }) => ({
    '& > div, .MuiFilledInput-root::before, .MuiFilledInput-root::after': {
      borderTopLeftRadius: '8px !important',
      borderTopRightRadius: '8px !important',
      borderBottomLeftRadius: '8px !important',
      borderBottomRightRadius: '8px !important',
      border: '0px',
      content: 'none',
    },
    input: {
      paddingRight: '115px',
      background: theme.palette.grey['A100'],
      fontSize: '16px',
      fontWeight: '300',
      borderTopLeftRadius: '8px !important',
      borderTopRightRadius: '8px !important',
      borderBottomLeftRadius: '8px !important',
      borderBottomRightRadius: '8px !important',
      border: error ? '1px solid ' + theme.palette.error.main : 'none',
    },
    '.Mui-error': {
      fontWeight: 300,
      fontSize: '14px',
    },
    display: 'flex',
    position: 'relative',
  }),
);

const CodeWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100%',
}));

const StyledButton = styled('button')<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    backgroundColor: theme.palette.primary.main,
    borderRadius: '40px',
    color: theme.palette.common.white,
    fontSize: '16px',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    padding: '8px 40px',
    cursor: 'pointer',
    ...(disabled && {
      backgroundColor: `${theme.palette.common.black}14`,
      color: `${theme.palette.common.black}4d`,
      cursor: 'default',
    }),
  }),
);

const ResendButton = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  borderRadius: '40px',
  lineHeight: '150%',
  backgroundColor: theme.palette.common.white,
  fontSize: '16px',
  fontWeight: '600',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px 14px',
  cursor: 'pointer',
  border: `2px solid ${theme.palette.grey[200]}`,
  position: 'absolute',
  top: '12px',
  right: '15px',
  zIndex: 10,
}));

const WalletInputWrapper = styled('div')(() => ({
  position: 'relative',
  marginBottom: '60px',
  '.MuiTextField-root': {
    position: 'absolute',
    width: '100%',
  },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  color: theme.palette.error.dark,
  fontWeight: 600,
  flexShrink: 1,
  fontSize: '16px',
  lineHeight: 1,
  '& .MuiAlert-icon': {
    fontSize: '16px',
  },
  padding: '8px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  background: theme.palette.grey['A100'],
  height: '56px',
  borderRadius: '8px',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0, 1, 0, 2),
    marginBottom: '-20px',
    borderTopLeftRadius: '8px !important',
    borderTopRightRadius: '8px !important',
    borderBottomLeftRadius: '8px !important',
    borderBottomRightRadius: '8px !important',
  },
  '& .MuiInputBase-input:focus': {
    background: 'rgba(0,0,0,0)',
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiInputLabel-shrink ': {
    position: 'absolute',
    top: '17px',
  },
  '& .Mui-error': {
    border: '1px solid ' + theme.palette.error.main,
    color: theme.palette.error.main + ' !important',
  },
  '& .MuiFormHelperText-root': {
    color: theme.palette.error.main,
    fontWeight: 300,
    fontSize: '14px',
  },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  '&.Mui-error': {
    color: theme.palette.error.main,
    border: 'rgba(0,0,0,0)',
  },
}));

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    color: theme.palette.grey[200],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderStyle: 'dashed',
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState, theme }) => ({
  zIndex: 1,
  color: theme.palette.grey[400],
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[100]}`,
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px',
  ...(ownerState.active && {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    color: theme.palette.grey[900],
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  }),
}));

const Line = styled('div')(({ theme }) => ({
  width: '100%',
  height: '1px',
  backgroundColor: theme.palette.grey[100],
}));

function CustomStepLabel(props: StepIconProps) {
  const { active, completed, className, icon: Icon } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ active, completed }}
      className={className}
    >
      {completed ? <Check className="CustomStepLabel-completedIcon" /> : Icon}
    </ColorlibStepIconRoot>
  );
}

const StepperCustom = styled(Stepper)(() => ({
  margin: '0 -60px',
}));
const CurrentStep = styled('p')(({ theme }) => ({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
  color: theme.palette.common.back,
  margin: 0,
}));
const TotalStep = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const ToolTipWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
  maxWidth: 250,
  padding: '15px',
  boxShadow:
    '0px 3px 6px rgba(0, 0, 0, 0.12), 0px 12px 24px rgba(0, 0, 0, 0.1), 0px 30px 60px rgba(0, 0, 0, 0.12)',
}));

const ToolTipContent = () => (
  <ToolTipWrapper>
    <Typography>What is TAX ID?</Typography>
    <CardBody>
      A tax identification number is a unique set of numbers that identifies
      individuals, corporations, and other entities such as nonprofit
      organizations (NPOs).
    </CardBody>
  </ToolTipWrapper>
);

const steps = Array.from({ length: 3 });
enum FieldEnum {
  Tax = 'tax',
}

type ValidationError = {
  message: string;
};

const VerifyPage: React.FC = () => {
  const [step, setStep] = useState(1);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [verifyCodeError, setVerifyCodeError] = useState<string>('');
  const [peraDialogOpen, setPeraDialogState] = useState<boolean>(false);
  const [updateProfileErrors, setUpdateProfileErrors] = useState<
    (ValidationError | string)[]
  >([]);
  const [requestCodeError, setRequestCodeError] = useState<string>('');
  const [optInError, setOptInError] = useState<string>('');
  const [assetsAndTxnOptIn, setAssetsAndTxnOptIn] = useState<{
    assets: Asset[];
    transactions: algosdk.Transaction[];
  }>({ assets: [], transactions: [] });
  const [shouldShowMyAlgoWarningDialog, setShouldShowMyAlgoWarningDialog] =
    useState(false);

  const navigate = useNavigate();

  const {
    state: { wallet, walletType, connector },
    initPeraWallet,
    disconnectWallet,
  } = useContext(WalletContext);

  const formStep1 = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Step1Schema,
    onSubmit: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleRequestCode();
    },
  });

  const formStep2 = useFormik({
    initialValues: {
      verificationCode: '',
    },
    validationSchema: Step2Schema,
    onSubmit: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleVerifyCode();
    },
  });

  const formStep3 = useFormik({
    initialValues: {
      walletAddress: '',
      country: '',
      taxId: '',
    },
    validationSchema:
      missingFields.length > 0 ? Step3Schema : Step3SchemaWithouTax,
    onSubmit: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleUpdateProfile();
    },
  });

  useEffect(() => {
    if (updateProfileErrors.length > 0) {
      setUpdateProfileErrors([]);
    }
    formStep3.setFieldValue('walletAddress', wallet);
  }, [wallet]);

  const connectWallet = async (type: WalletTypeEnum) => {
    switch (type) {
      case WalletTypeEnum.myAlgoWallet:
        setShouldShowMyAlgoWarningDialog(true);
        return;
      case WalletTypeEnum.peraWallet:
        initPeraWallet();
        return;
      default:
        return;
    }
  };
  const NewWalletTimeout = 1 * 60 * 1000;

  const handleConnect = async (type: WalletTypeEnum) => {
    // setLoading(true)
    try {
      setTimeout(() => {
        setLoading(false);
        setError('');
      }, NewWalletTimeout);

      connectWallet(type);
    } catch (ex) {
      setLoading(false);
      Sentry.captureException(ex);
      setError('Something went wrong, please try again');
    }
  };
  // const lilPumpAssetId = 87755426
  const handleOptin = async () => {
    try {
      Sentry.addBreadcrumb({
        category: '<VerifyPage /> : handleOptin',
        message: 'Asking the user to sign all transactions to optIn',
        type: 'info',
        data: {
          wallet,
          walletType,
        },
      });
      if (walletType === WalletTypeEnum.peraWallet) {
        Sentry.addBreadcrumb({
          category: '<VerifyPage /> : handleOptin',
          message: 'Displaying Pera mobile dialog',
          type: 'info',
          data: {
            wallet,
            walletType,
            connector,
          },
        });
        setPeraDialogState(true);
      }
      const signedTransactions = await myAlgoService.signTransactions(
        assetsAndTxnOptIn.transactions,
        connector,
        walletType,
      );
      const {
        data: {
          result: { txId },
        },
      } = await myAlgoService.sendSignedTransactions(signedTransactions);
      await myAlgoService.waitForTransactions([txId]);

      navigate('/');
    } catch (ex) {
      Sentry.captureException(ex);
      setOptInError(
        'An unexpected error ocurred while opting in. Please try again.',
      );
    }
  };

  useEffect(() => {
    if (verifyCodeError !== '') {
      setVerifyCodeError('');
    }
  }, [formStep2.values.verificationCode]);

  useEffect(() => {
    if (requestCodeError !== '') {
      setRequestCodeError('');
    }
  }, [formStep1.values.email]);

  useEffect(() => {
    if (updateProfileErrors.length > 0) {
      setUpdateProfileErrors([]);
    }
  }, [formStep3.values.country, formStep3.values.taxId]);

  useEffect(() => {
    if (isVerified && wallet) {
      setStep(3);
      return;
    }
  }, [wallet]);

  const handleRequestCode = async () => {
    const { email } = formStep1.values;
    if (email) {
      setLoading(true);
      setRequestCodeError('');
      try {
        await InvestorService.requestCode(email);
        if (step === 1) {
          setStep(2);
        }
      } catch (errorResponse) {
        if (
          axios.isAxiosError(errorResponse) &&
          errorResponse.response?.status === 404
        ) {
          setRequestCodeError(
            'The email could not be found in our system. Please input your purchase email.',
          );
        } else {
          setRequestCodeError(
            'An error has occured while trying to request verification code.',
          );
          Sentry.captureException(errorResponse);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleVerifyCode = async () => {
    const { email } = formStep1.values;
    const { verificationCode } = formStep2.values;
    if (verificationCode) {
      setLoading(true);
      setVerifyCodeError('');
      try {
        const { data: res } = await InvestorService.verifyCode(
          email,
          verificationCode,
        );

        setIsVerified(true);
        setStep(3);
        setMissingFields(res?.data?.missingFields || []);
      } catch (err) {
        const unkownErrorText =
          'An error has occured while trying to verify code.';
        if (axios.isAxiosError(err)) {
          setVerifyCodeError(
            err.response?.data?.errors[0]?.message || unkownErrorText,
          );
        } else {
          setVerifyCodeError(unkownErrorText);
        }
        Sentry.captureException(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUpdateProfile = async () => {
    const { taxId, walletAddress, country } = formStep3.values;
    let isFormValid = (!!wallet && walletType) || false;
    if (missingFields.indexOf(FieldEnum.Tax) >= 0) {
      isFormValid = isFormValid && !!country && !!taxId;
    }
    if (isFormValid) {
      setLoading(true);
      setUpdateProfileErrors([]);
      const { email } = formStep1.values;
      const { verificationCode } = formStep2.values;
      try {
        Sentry.addBreadcrumb({
          category: '<VerifyPage /> : handleUpdateProfile',
          message: 'Updating the user profile',
          type: 'info',
          data: {
            wallet,
            walletType,
            email,
            verificationCode,
            walletAddress,
            country,
            taxId,
          },
        });
        const { data } = await InvestorService.updateProfile(
          email,
          verificationCode,
          walletAddress,
          walletType,
          country,
          taxId,
        );
        setStep(5);
        setError('');

        const assetsToOptIn = data?.data || [];
        const assetsToOptInTransactions =
          await myAlgoService.makeOptInTransactions({
            assets: assetsToOptIn.map((it: { id: number }) => it.id),
            wallet,
          });
        setAssetsAndTxnOptIn({
          assets: assetsToOptIn,
          transactions: assetsToOptInTransactions,
        });
        // check against optin
      } catch (err: unknown) {
        if (axios.isAxiosError(err)) {
          setUpdateProfileErrors(err.response?.data?.errors || []);
        } else {
          setError('An error has occured while trying to updated profile.');
        }
        Sentry.captureException(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleShowWalletLogin = () => {
    setStep(4);
  };

  const handleWalletDisconnect = () => {
    disconnectWallet();
  };

  const handleWalletBack = () => {
    setStep(3);
  };

  const areTaxFieldsVisible = missingFields.indexOf(FieldEnum.Tax) >= 0;
  const handleFormReset =
    (
      resetForm: () => void,
    ): React.MouseEventHandler<HTMLDivElement> | undefined =>
    () => {
      handleRequestCode();
      resetForm();
    };

  const handleNavigateMyAssets = () => {
    navigate('/');
  };

  return (
    <div>
      <Wrapper>
        {step === 1 && (
          <Fade in>
            <StyleCard>
              <StepperCustom
                activeStep={step - 1}
                alternativeLabel
                connector={<CustomConnector />}
              >
                {steps.map((_, index) => (
                  <Step key={index}>
                    <StepLabel StepIconComponent={CustomStepLabel}>
                      {''}
                    </StepLabel>
                  </Step>
                ))}
              </StepperCustom>
              <CurrentStep>
                Step 1<TotalStep> of 3</TotalStep>
              </CurrentStep>
              {!isLoading && (
                <>
                  <CardTitle data-testid="verify__step1__title">
                    Data verification
                  </CardTitle>
                  <CardBody data-testid="verify__step1__sub-title">
                    Please verify your email
                  </CardBody>
                  <Form onSubmit={formStep1.handleSubmit}>
                    <StyledInput
                      type="email"
                      name="email"
                      inputProps={{ 'aria-label': 'email-input' }}
                      value={formStep1.values.email}
                      onChange={formStep1.handleChange}
                      onBlur={formStep1.handleBlur}
                      error={
                        formStep1.touched.email &&
                        Boolean(formStep1.errors.email)
                      }
                      helperText={
                        formStep1.touched.email && formStep1.errors.email
                      }
                      label="Enter your email"
                      variant="filled"
                      required
                    />
                    {!!requestCodeError && (
                      <StyledAlert
                        data-testid="verify__step1__error"
                        variant="filled"
                        severity="error"
                      >
                        {requestCodeError}
                      </StyledAlert>
                    )}
                    <StyledButton
                      disabled={!formStep1.isValid || !formStep1.dirty}
                      type="submit"
                      data-testid="verify__step1__btn-submit"
                    >
                      Submit
                    </StyledButton>
                  </Form>
                </>
              )}
              {isLoading && (
                <>
                  <Skeleton variant="text"></Skeleton>
                  <Skeleton variant="text"></Skeleton>
                  <Skeleton variant="text"></Skeleton>
                </>
              )}
            </StyleCard>
          </Fade>
        )}
        {step === 2 && (
          <Fade in>
            <StyleCard>
              <StepperCustom
                activeStep={step - 1}
                alternativeLabel
                connector={<CustomConnector />}
              >
                {steps.map((_, index) => (
                  <Step key={index}>
                    <StepLabel StepIconComponent={CustomStepLabel}>
                      {''}
                    </StepLabel>
                  </Step>
                ))}
              </StepperCustom>
              <CurrentStep>
                Step 2<TotalStep> of 3</TotalStep>
              </CurrentStep>
              {!isLoading && (
                <>
                  <CardTitle>Data verification</CardTitle>
                  <CardBody data-testid="verify__step2__sub-title">
                    Please check your email and enter the provided code
                  </CardBody>
                  <StyledInput
                    disabled
                    name="email"
                    value={formStep1.values.email}
                    label="Email"
                    variant="filled"
                  />
                  <Form onSubmit={formStep2.handleSubmit}>
                    <Line />
                    <CodeWrapper>
                      <StyledCodeInput
                        name="verificationCode"
                        value={formStep2.values.verificationCode}
                        onChange={formStep2.handleChange}
                        onBlur={formStep2.handleBlur}
                        error={
                          formStep2.touched.verificationCode &&
                          Boolean(formStep2.errors.verificationCode)
                        }
                        helperText={
                          formStep2.touched.verificationCode &&
                          formStep2.errors.verificationCode
                        }
                        inputProps={{ 'aria-label': 'code-input' }}
                        label="Enter code"
                        variant="filled"
                        required
                      />
                      <ResendButton
                        onClick={handleFormReset(formStep2.resetForm)}
                      >
                        Resend
                      </ResendButton>
                    </CodeWrapper>
                    {!!verifyCodeError && (
                      <StyledAlert
                        data-testid="verify__step2__error"
                        variant="filled"
                        severity="error"
                      >
                        {verifyCodeError}
                      </StyledAlert>
                    )}
                    <StyledButton
                      disabled={!formStep2.isValid || !formStep2.dirty}
                      type="submit"
                      data-testid="verify__step2__btn-submit"
                    >
                      Validate Code
                    </StyledButton>
                  </Form>
                </>
              )}
              {isLoading && (
                <>
                  <Skeleton variant="text"></Skeleton>
                  <Skeleton variant="text"></Skeleton>
                  <Skeleton variant="text"></Skeleton>
                </>
              )}
            </StyleCard>
          </Fade>
        )}
        {step === 3 && (
          <Fade in>
            <StyleCard>
              <StepperCustom
                activeStep={step - 1}
                alternativeLabel
                connector={<CustomConnector />}
              >
                {steps.map((_, index) => (
                  <Step key={index}>
                    <StepLabel StepIconComponent={CustomStepLabel}>
                      {''}
                    </StepLabel>
                  </Step>
                ))}
              </StepperCustom>
              <CurrentStep>
                Step 3<TotalStep> of 3</TotalStep>
              </CurrentStep>
              {!isLoading && (
                <>
                  <CardTitle>Data verification</CardTitle>
                  <CardBody data-testid="verify__step3__sub-title">
                    Please check your details and connect your wallet
                  </CardBody>
                  <StyledInput
                    disabled
                    value={formStep1.values.email}
                    label="Email"
                    variant="filled"
                  />
                  <Line />
                  <Form onSubmit={formStep3.handleSubmit}>
                    {areTaxFieldsVisible && (
                      <TwoLine>
                        <StyledFormControl>
                          <StyledInputLabel
                            id="country-select"
                            className={
                              !formStep3.isValid && formStep3.dirty
                                ? 'Mui-error'
                                : ''
                            }
                          >
                            Tax Country Code
                          </StyledInputLabel>
                          <Select
                            id="country-select"
                            name="country"
                            displayEmpty
                            value={formStep3.values.country}
                            onChange={formStep3.handleChange}
                            label="Tax Country Code"
                            variant="filled"
                            onBlur={formStep3.handleBlur}
                            input={
                              <StyledInputBase
                                error={
                                  formStep3.touched.country &&
                                  Boolean(formStep3.errors.country)
                                }
                                // helperText={formStep3.touched.country && formStep3.errors.country}
                              />
                            }
                            error={
                              formStep3.touched.country &&
                              Boolean(formStep3.errors.country)
                            }
                            required
                          >
                            {countries.map((option, index) => (
                              <MenuItem
                                key={`option-${index}`}
                                value={option.code}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formStep3.touched.country &&
                            Boolean(formStep3.errors.country) && (
                              <FormHelperText>
                                Please select a Country
                              </FormHelperText>
                            )}
                        </StyledFormControl>
                        <ToolTipTextField
                          value={formStep3.values.taxId}
                          onChange={formStep3.handleChange}
                          onBlur={formStep3.handleBlur}
                          error={
                            formStep3.touched.taxId &&
                            Boolean(formStep3.errors.taxId)
                          }
                          helperText={
                            formStep3.touched.taxId && formStep3.errors.taxId
                          }
                          name="taxId"
                          label="Tax ID"
                          variant="filled"
                          required
                          popover
                          contentPopover={<ToolTipContent />}
                        />
                      </TwoLine>
                    )}
                    <WalletInputWrapper>
                      <StyledInput
                        value={formStep3.values.walletAddress}
                        onBlur={formStep3.handleBlur}
                        error={
                          formStep3.touched.walletAddress &&
                          Boolean(formStep3.errors.walletAddress)
                        }
                        helperText={
                          formStep3.touched.walletAddress &&
                          formStep3.errors.walletAddress
                        }
                        label="Wallet"
                        variant="filled"
                        required
                        name="walletAddress"
                      />
                      {!wallet && (
                        <ResendButton onClick={handleShowWalletLogin}>
                          {'Connect'}
                        </ResendButton>
                      )}
                      {!!wallet && (
                        <ResendButton onClick={handleWalletDisconnect}>
                          {'Disconnect'}
                        </ResendButton>
                      )}
                    </WalletInputWrapper>
                    {updateProfileErrors.length > 0 && (
                      <StyledAlert variant="filled" severity="error">
                        {updateProfileErrors.map(e => (
                          <p data-testid="verify__step3__error">
                            {typeof e === 'object' ? e.message : e || ''}
                          </p>
                        ))}
                      </StyledAlert>
                    )}
                    {error.length > 0 && (
                      <StyledAlert variant="filled" severity="error">
                        <p data-testid="verify__step3__error">{error}</p>
                      </StyledAlert>
                    )}
                    <StyledButton
                      disabled={!formStep3.isValid || !formStep3.dirty}
                      type="submit"
                      data-testid="verify__step3__btn-submit"
                    >
                      Complete
                    </StyledButton>
                  </Form>
                </>
              )}
              {isLoading && (
                <>
                  <Skeleton variant="text"></Skeleton>
                  <Skeleton variant="text"></Skeleton>
                  <Skeleton variant="text"></Skeleton>
                </>
              )}
            </StyleCard>
          </Fade>
        )}
        {step === 4 && (
          <Fade in>
            <StyledBox>
              <ConnectWallet
                onWalletSelect={handleConnect}
                loading={isLoading}
                errorText={error}
                onBack={handleWalletBack}
              />
              {error.length > 0 && <ErrorMessage content={error} />}
            </StyledBox>
          </Fade>
        )}
        {step === 5 && (
          <Fade in>
            <StyleCard>
              <StepperCustom
                activeStep={step - 1}
                alternativeLabel
                connector={<CustomConnector />}
              >
                {steps.map((_, index) => (
                  <Step key={index}>
                    <StepLabel StepIconComponent={CustomStepLabel}>
                      {''}
                    </StepLabel>
                  </Step>
                ))}
              </StepperCustom>
              <CurrentStep>You’ve almost made it!</CurrentStep>
              <CardTitle data-testid="verify__step5__title">
                Claim your MFT
              </CardTitle>
              {assetsAndTxnOptIn.assets.length > 0 ? (
                <>
                  <CardBody>
                    Hit the button below to complete the process and claim your
                    MFT. It may take up to 72 hours for the token to appear in
                    your wallet.
                  </CardBody>
                  {assetsAndTxnOptIn.assets.map(a => (
                    <AssetClaimCard
                      key={`asset-claim-card-${a.id}`}
                      asset={a}
                      loading={false}
                    />
                  ))}
                  <StyledButton onClick={handleOptin}>Opt me in!</StyledButton>
                </>
              ) : (
                <>
                  <CardBody>
                    Seems that you have already opted in to all available MFT's.
                    Head your assets and check if they arrived.
                  </CardBody>
                  <StyledButton onClick={handleNavigateMyAssets}>
                    Go to my assets
                  </StyledButton>
                </>
              )}
              {!!optInError && (
                <StyledAlert variant="filled" severity="error">
                  {optInError}
                </StyledAlert>
              )}
            </StyleCard>
          </Fade>
        )}
      </Wrapper>
      <PeraInfoModal
        open={peraDialogOpen}
        onClose={() => setPeraDialogState(false)}
      />
      <MyAlgoWarningDialog
        show={shouldShowMyAlgoWarningDialog}
        onClose={() => setShouldShowMyAlgoWarningDialog(false)}
      />
    </div>
  );
};

export default VerifyPage;
