import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import WalletContext from '@opulous/web/src/context/context';
import config from '@opulous/web/src/config';
import { useFeatureFlags } from '@opulous/web/src/hooks/useFeatureFlags';
import { openBuyOpulPage } from '@opulous/web/src/shared/externalLinks';
import { WalletTypeEnum } from 'src/shared/types';
import { PeraWalletConnect } from '@perawallet/connect';

import {
  getAssetsOwnedByWallet,
  makeOptInTransactions,
  sendSignedTransactions,
  signTransactions,
  waitForTransactions,
} from 'src/services/my-algo';

async function optInAndWait(
  wallet: string,
  walletType: WalletTypeEnum | undefined,
  connector: PeraWalletConnect,
  assetId: number,
) {
  const transactions = await makeOptInTransactions({
    assets: [assetId],
    wallet,
  });
  const txns = await signTransactions(transactions, connector, walletType);
  const {
    data: {
      result: { txId },
    },
  } = await sendSignedTransactions(txns);
  await waitForTransactions([txId]);
}

export default function useOpulPurchasePopup() {
  const { isActive } = useFeatureFlags();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpulOptedIn, setIsOpulOptedIn] = useState(false);
  const [isOpulOptingIn, setIsOpulOptingIn] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [hasError, setHasError] = useState(false);
  const {
    state: { wallet, walletType, connector },
  } = useContext(WalletContext);

  function openPurchasePopup() {
    isActive('indacoin')
      ? window.open(
          `https://gw.indacoin.io/?partnerId=${encodeURIComponent(
            config.env.INDACOIN_PARTNER_ID,
          )}&cur_to=OPUL&address=${encodeURIComponent(wallet)}`,
          'indacoinPurchasePopup',
          'width=400,height=800,top=100,left=100,popup=1',
        )
      : openBuyOpulPage();
  }

  const handleOpulPurchase = async () => {
    if (isLoading) return;
    if (isOpulOptedIn) {
      openPurchasePopup();
    } else {
      try {
        setIsOpulOptingIn(true);
        await optInAndWait(
          wallet,
          walletType,
          connector,
          config.env.ALGORAND_OPUL_ASSET_ID,
        );
        setIsOpulOptingIn(false);
        setIsOpulOptedIn(true);
        setIsTimeout(false);
        setHasError(false);
        openPurchasePopup();
      } catch (err: any) {
        if (
          // TODO: Parse the error with util function when #626 is merged
          axios.isAxiosError(err) &&
          err.response?.data?.type === 'TRANSACTION_TIMEOUT'
        ) {
          setIsTimeout(true);
        } else {
          Sentry.captureException(err);
          setHasError(true);
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const assets = await getAssetsOwnedByWallet(wallet);
      setIsOpulOptedIn(assets.includes(config.env.ALGORAND_OPUL_ASSET_ID));
      setIsLoading(false);
    })();
  }, []);

  return {
    isLoading,
    hasError,
    isTimeout,
    onPurchase: handleOpulPurchase,
    buttonText: isOpulOptedIn ? 'Buy OPUL' : 'Opt In & Buy OPUL',
    isOptingIn: isOpulOptingIn,
    isOptedIn: isOpulOptedIn,
  };
}
