import { ReactElement, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import StyledAlert from '@opulous/web/src/components/Common/StyledAlert'
import { useTheme } from '@mui/material'

const Close = styled('span')(() => ({
  position: 'absolute',
  right: '1rem',
  fontSize: '1.5em',
  cursor: 'pointer',
}))

type Props = {
  message: string
}
export default function AlertSuccess({ message }: Props): ReactElement | null {
  const theme = useTheme();
  const [closed, setClosed] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setClosed(true)
    }, 10000)
  }, [])

  if (closed) {
    return null
  }

  return (
    <StyledAlert
      dataTestId="alert-error"
      padding="14px 11px"
      fontSizeIcon="20px"
      colorIcon={theme.palette.success.dark}
      colorAlert={theme.palette.success.dark}
      severity="success"
      borderRadius="10px"
      backgroundColor={theme.palette.success.light}
    >
      {message}
      <Close onClick={() => setClosed(true)}>&times;</Close>
    </StyledAlert>
  )
}
