import React, { useEffect, useState, useContext, useMemo } from 'react';

import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Theme,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CloseIcon from '@mui/icons-material/Close';
import { formatNumber } from '@opulous/web/src/utils';
import { useNavigate, useParams } from 'react-router-dom';
import WalletContext from '@opulous/web/src/context/context';
import { PoolDetails, StakeData } from '@opulous/web/src/shared/types';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import ConfirmModal from '@opulous/web/src/components/Pools/ConfirmModal';
import WithdrawModal from '@opulous/web/src/components/Pools/WithdrawModal';
import TabBar from './TabBar';
import { ReactComponent as OpulSvg } from '@opulous/web/src/assets/opul.svg';
import PoolStakePanel from '@opulous/web/src/components/Pools/PoolStakePanel';
import OwnStakesPanel from '@opulous/web/src/components/Pools/OwnStakesPanel';
import { pools as PoolsService } from '@opulous/web/src/services';
import * as Sentry from '@sentry/react';
import Skeleton from '@mui/material/Skeleton';
import { ReactComponent as AllTokens } from '../../../assets/all_tokens.svg';
import PoolError from './PoolError';
import BigNumber from 'bignumber.js';
import OPULLabel from '@opulous/web/src/components/shared/OPULLabel';
import Snackbar, {
  DEFAULT_AUTO_HIDE_DURATION,
} from '@opulous/web/src/components/Snackbar';
import _CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PoolVersionTag } from 'src/components/Pools/PoolGridCard';
import { waitForTransactions } from 'src/services/my-algo';
// import useOpulPurchasePopup from 'src/hooks/useOpulPurchasePopup';
// import _WarningIcon from '@mui/icons-material/Warning';
// import _ErrorIcon from '@mui/icons-material/Error';

const PaddingContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.25, 3),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 0),
  },
}));

const CardRewards = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 84, 0, 0.08)',
  paddingLeft: '10px',
  paddingRight: '10px',
  maxWidth: 'unset',
  height: '70px',
  borderRadius: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    marginTop: '10px',
  },
}));

const StyledPanel = styled(TabPanel)(() => ({
  padding: 0,
  height: '100%',
}));

const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 0),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3, 0),
  },
}));

const Header = styled(PaddingContent)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  position: 'relative',
}));

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '&.title': {
      fontSize: '1.25rem',
    },
  },
}));

const Amount = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '120%',
  color: theme.palette.primary.main,
  fontWeight: 600,
  margin: '0px !important',
}));

const Accumulated = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '150%',
  color: theme.palette.primary.main,
  opacity: 0.7,
  minWidth: '180px',
  textAlign: 'center',
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  lineHeight: '22px',
  color: theme.palette.grey[700],
}));

const ReloadButton = styled('button')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '150%',
  color: theme.palette.common.white,
  padding: '8px 24px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '40px',
  border: 'none',
  cursor: 'pointer',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(2),
  minHeight: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    borderRadius: theme.spacing(1.5),
    boxShadow: 'unset',
    padding: theme.spacing(1, 0),
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(6),
  height: theme.spacing(6),
  background: theme.palette.grey['A100'],
  flexShrink: 0,
  borderRadius: '100%',
  border: `2px solid ${theme.palette.grey[100]}`,
  marginRight: theme.spacing(2),
}));

const Condition = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.grey.A100,
  borderRadius: theme.spacing(1),
  height: '100%',
}));

const ConditionValue = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  fontSize: '28px',
}));

const ErrorContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ErrorText = styled('span')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '150%',
  color: theme.palette.grey[700],
  marginBottom: '20px',
}));

const SnackbarContent = styled('div')(({ theme }) => ({
  border: `2px solid ${theme.palette.success.main}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1),
  fontSize: '.75rem',
  fontWeight: '500',
  maxWidth: '18rem',
}));

const CheckCircleIcon = styled(_CheckCircleIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  marginRight: theme.spacing(1),
}));

// const BuyOPULButton = styled(Button)(({ theme }) => ({
//   backgroundColor: theme.palette.common.black,
//   color: theme.palette.common.white,

//   '&:hover': {
//     backgroundColor: theme.palette.common.black,
//   },
// }));

const VerticalBar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  width: '2px',
  borderRadius: '1px',
  alignSelf: 'stretch',
}));

// const ButtonLoader = styled(CircularProgress)(({ theme }) => ({
//   color: theme.palette.common.white,
// }));

// const WarningIcon = styled(_WarningIcon)(({ theme }) => ({
//   width: '3rem',
//   height: '3rem',
//   fill: theme.palette.warning.main,
// }));
// const ErrorIcon = styled(_ErrorIcon)(({ theme }) => ({
//   width: '3rem',
//   height: '3rem',
//   fill: theme.palette.error.main,
// }));

const PoolDetailPage: React.FC = () => {
  const { poolId } = useParams();
  const navigate = useNavigate();
  const [pool, setPool] = useState<PoolDetails>();
  const [tab, setTab] = useState('stake');
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [withdrawOpen, setWithdrawOpen] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [stakeIndex, setStakeIndex] = useState<number>(0);

  const theme: Theme = useTheme();
  const [transactionStatus, setTransactionStatus] = useState<'success' | null>(
    null,
  );
  const [withdrawalStatus, setWithdrawalStatus] = useState<'success' | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stakeData, setStakeData] = useState<StakeData>({
    amount: new BigNumber(0),
    apy: 0,
    stake: 0,
    dailyProfits: '',
  });
  const {
    state: { wallet, connector, walletType },
  } = useContext(WalletContext);
  const [shouldShowTransactionDialog, setShouldShowTransactionDialog] =
    useState(false);
  // const {
  //   isLoading: isOPULPurchasePopupLoading,
  //   onPurchase: onOPULPurchase,
  //   isOptingIn: isOPULOptingIn,
  //   buttonText: buyOPULButtonText,
  //   isTimeout: isOPULPurchaseTimeout,
  //   hasError: hasOPULPurchaseError,
  // } = useOpulPurchasePopup();
  // const [shouldShowOPULPurchaseDialog, setShouldShowOPULPurchaseDialog] =
  //   useState(true);

  const activeStakeCount = useMemo(
    () => pool?.history?.filter(x => x.type === 'staked').length ?? 0,
    [pool?.history],
  );

  const fetchPoolDetails = async () => {
    setIsLoading(true);
    try {
      if (poolId) {
        const {
          data: { data },
        } = await PoolsService.getPool(parseInt(poolId), wallet);
        if (data) {
          setPool(data);
        }
        // if (data?.saleDate) {
        //   setSaleDate(new Date(data?.saleDate))
        //   setIsActiveTimer(true)
        // }
      }
    } catch (e) {
      Sentry.captureException(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPoolDetails();
  }, [poolId, wallet]);

  const handleCloseWithdrawModal = (shouldRefresh: boolean) => {
    setWithdrawOpen(false);
    if (!shouldRefresh) {
      return;
    }
    fetchPoolDetails();
  };

  const getPoolApyRangeLabel = (min: number, max: number) =>
    `${min / 100}% - ${max / 100}%`;

  const closeAllSnackbars = () => {
    setTransactionStatus(null);
    setWithdrawalStatus(null);
  };

  const handlePoolStake = async (txIds: string[]) => {
    closeAllSnackbars();

    setShouldShowTransactionDialog(true);
    await waitForTransactions(txIds);
    setShouldShowTransactionDialog(false);

    setTransactionStatus('success');
    setConfirmOpen(false);
    await fetchPoolDetails();
  };

  const handleWithdrawStake = (selectedStake: number) => {
    setWithdrawalStatus(null);
    setStakeIndex(selectedStake);
    setWithdrawOpen(true);
  };

  const handleWithdraw = async (txIds: string[]) => {
    closeAllSnackbars();

    setShouldShowTransactionDialog(true);
    await waitForTransactions(txIds);
    setShouldShowTransactionDialog(false);

    setWithdrawalStatus('success');
    await fetchPoolDetails();
  };

  // const handleOPULPurchase = () => {
  //   setShouldShowOPULPurchaseDialog(true);
  //   onOPULPurchase();
  // };

  const isVersionExpired =
    pool?.pool.versionLabel !== pool?.pool.latestVersionLabel;
  return isLoading ? (
    <Grid pt="40px" container spacing={4}>
      <Grid item xs={12} lg={8}>
        <Box
          borderRadius="10px"
          px="30px"
          boxShadow="0px 8px 24px rgba(40, 46, 48, 0.1)"
        >
          <Box display="flex" pt="30px" pb="15px">
            <Box paddingRight="15px">
              <Skeleton variant="circular">
                <Avatar />
              </Skeleton>
            </Box>
            <Skeleton width="30%">
              <Typography>.</Typography>
            </Skeleton>
          </Box>
          <Skeleton height={150} variant="text"></Skeleton>
          <Skeleton height={150} variant="text"></Skeleton>
        </Box>
        <Box
          borderRadius="15px"
          mt="30px"
          py="30px"
          px="30px"
          boxShadow="0px 8px 24px rgba(40, 46, 48, 0.1)"
        >
          <Skeleton variant="text" height={38} width="30%"></Skeleton>
          <Skeleton variant="text" height={38} width="45%"></Skeleton>
          <Skeleton variant="text" height={38} width="80%"></Skeleton>
          <Skeleton variant="text" height={70} width="50%"></Skeleton>
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box
          borderRadius="15px"
          py="30px"
          px="30px"
          boxShadow="0px 8px 24px rgba(40, 46, 48, 0.1)"
        >
          <Skeleton variant="text" height={150}></Skeleton>
          <Skeleton variant="text" height={150}></Skeleton>
          <Skeleton variant="text" height={150}></Skeleton>
          <Skeleton variant="text" height={150}></Skeleton>
        </Box>
      </Grid>
    </Grid>
  ) : error ? (
    <ErrorContainer>
      <PoolError />
      <ErrorText>An error occurred while fetching pool detail</ErrorText>
      {error && (
        <ReloadButton onClick={fetchPoolDetails}>Reload page</ReloadButton>
      )}
    </ErrorContainer>
  ) : (
    <StyledContainer>
      {pool && (
        <Grid container spacing={{ xs: 0, lg: 4 }}>
          <Grid item xs={12} md={12} xl={8}>
            <Grid container xs={12} rowSpacing={4}>
              <Grid item xs={12}>
                <StyledCard sx={{ boxShadow: 1 }}>
                  <Header>
                    <IconWrapper>
                      <SvgIcon
                        component={OpulSvg}
                        viewBox="-9.25 -2.5 54 45"
                        sx={{
                          width: theme.spacing(3),
                          height: theme.spacing(3),
                        }}
                      />
                    </IconWrapper>
                    <Box
                      display="flex"
                      alignItems="center"
                      flex="1"
                      paddingRight={4}
                      gap={2}
                    >
                      <Box flex="1">
                        <Title variant="h1">{pool.pool.title}</Title>
                      </Box>
                      {/* <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                        <BuyOPULButton
                          size="small"
                          onClick={handleOPULPurchase}
                        >
                          {isOPULPurchasePopupLoading ? (
                            <ButtonLoader size="1.5rem" />
                          ) : (
                            buyOPULButtonText
                          )}
                        </BuyOPULButton>
                      </Box> */}
                    </Box>
                    {pool.pool.versionLabel && (
                      <PoolVersionTag dimmed={isVersionExpired}>
                        {pool.pool.versionLabel}
                      </PoolVersionTag>
                    )}
                  </Header>
                  {/* <Box
                    paddingTop={2}
                    justifyContent="flex-end"
                    sx={{ display: { xs: 'flex', lg: 'none' } }}
                  >
                    <BuyOPULButton size="small" onClick={handleOPULPurchase}>
                      {isOPULPurchasePopupLoading ? (
                        <ButtonLoader size="1.5rem" />
                      ) : (
                        buyOPULButtonText
                      )}
                    </BuyOPULButton>
                  </Box> */}
                  {pool && (
                    <PaddingContent>
                      <Typography variant="h6">Conditions</Typography>
                      <Grid mt={0.5} container spacing={2}>
                        <Grid item xs={12} lg={4}>
                          <Condition>
                            <ShowChartIcon fontSize="small" color="primary" />
                            <Typography variant="h6" color="textSecondary">
                              APY
                            </Typography>
                            <ConditionValue>
                              {getPoolApyRangeLabel(
                                pool.pool.minAnnualPercentageYield,
                                pool.pool.maxAnnualPercentageYield,
                              )}
                            </ConditionValue>
                          </Condition>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                          <Condition>
                            <SvgIcon component={AllTokens} color="primary" />
                            <Typography variant="h6" color="textSecondary">
                              TVL
                            </Typography>
                            <ConditionValue>
                              <OPULLabel
                                hideUnit
                                value={pool.pool.poolSize.toString()}
                              />
                              <span> OPUL</span>
                            </ConditionValue>
                          </Condition>
                        </Grid>
                      </Grid>
                    </PaddingContent>
                  )}
                </StyledCard>
              </Grid>
              <Grid item xs={12}>
                <StyledCard sx={{ boxShadow: 1 }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={3}
                    padding={3}
                  >
                    <Title className="title" variant="h2">
                      Opulous Rewards
                    </Title>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={3}
                      sx={{ alignItems: { xs: 'center', lg: 'flex-start' } }}
                    >
                      <Box
                        display="flex"
                        gap={3}
                        alignItems="center"
                        sx={{ flexDirection: { xs: 'column', lg: 'row' } }}
                      >
                        <Text>
                          Keep your OPUL staked and collect raffle tickets!
                          Every 10 OPUL you stake earns you one daily ticket.
                          Cash-in those tickets for a chance to gain early
                          access to thrilling MFT drops, not to mention other
                          amazing prizes!
                        </Text>
                        <VerticalBar
                          sx={{ display: { xs: 'none', lg: 'block' } }}
                        />
                        <CardRewards>
                          <Amount>
                            {formatNumber(
                              parseInt(pool?.availableTickets || '', 10) || 0,
                            )}
                          </Amount>
                          <Accumulated>Accumulated tickets</Accumulated>
                        </CardRewards>
                      </Box>
                      <Button size="small" onClick={() => navigate('/raffles')}>
                        Go to Raffles
                      </Button>
                    </Box>
                  </Box>
                </StyledCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} xl={4} paddingTop={4}>
            <StyledCard sx={{ boxShadow: 1, padding: 0 }}>
              <TabContext value={tab}>
                <Header>
                  <TabBar
                    tabWidth="50%"
                    tabs={[
                      {
                        label: 'Stake',
                        value: 'stake',
                      },
                      {
                        label: 'Your stakes   ',
                        value: 'your_stake',
                        stakes: activeStakeCount,
                      },
                    ]}
                    onChangTab={setTab}
                  />
                </Header>
                <StyledPanel value="stake">
                  <PoolStakePanel
                    onStake={data => {
                      setStakeData(data);
                      setConfirmOpen(true);
                      setTransactionStatus(null);
                    }}
                    pool={pool}
                  />
                </StyledPanel>
                <StyledPanel value="your_stake">
                  <OwnStakesPanel
                    onClickWithdraw={handleWithdrawStake}
                    pool={pool}
                  />
                </StyledPanel>
              </TabContext>
            </StyledCard>
          </Grid>
        </Grid>
      )}
      {pool && (
        <ConfirmModal
          open={confirmOpen}
          data={stakeData}
          pool={pool}
          wallet={wallet}
          walletType={walletType}
          connector={connector}
          onClose={() => setConfirmOpen(false)}
          getStakeTransactions={PoolsService.getStakeTransaction}
          stakeInPool={handlePoolStake}
          activeStakeCount={activeStakeCount}
        />
      )}
      {pool && (
        <WithdrawModal
          pool={pool}
          open={withdrawOpen}
          onClose={handleCloseWithdrawModal}
          onWithdraw={handleWithdraw}
          stakeIndex={stakeIndex}
        />
      )}

      <Snackbar
        open={transactionStatus === 'success'}
        onClose={() => setTransactionStatus(null)}
        autoHideDuration={DEFAULT_AUTO_HIDE_DURATION}
        position={{ top: theme.spacing(12) }}
      >
        <SnackbarContent>
          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              color={theme.palette.success.main}
            >
              <CheckCircleIcon />
            </Box>
            <Box flex={1}>Transaction was successful.</Box>
            <Box marginLeft={1}>
              <IconButton
                size="small"
                onClick={() => setTransactionStatus(null)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </SnackbarContent>
      </Snackbar>

      <Snackbar
        open={withdrawalStatus === 'success'}
        onClose={() => setWithdrawalStatus(null)}
        autoHideDuration={DEFAULT_AUTO_HIDE_DURATION}
        position={{ top: theme.spacing(12) }}
      >
        <SnackbarContent>
          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              color={theme.palette.success.main}
            >
              <CheckCircleIcon />
            </Box>
            <Box flex={1}>
              Congratulations! You successfully withdrew your stake.
            </Box>
            <Box marginLeft={1}>
              <IconButton
                size="small"
                onClick={() => setWithdrawalStatus(null)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </SnackbarContent>
      </Snackbar>

      <Dialog maxWidth="lg" open={shouldShowTransactionDialog}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          padding={4}
          alignItems="center"
        >
          <div>Please wait till the transaction complete.</div>
          <CircularProgress size="3rem" color="primary" />
        </Box>
      </Dialog>

      {/* <Dialog
        title="OPUL Opt-In"
        onClose={() => setShouldShowOPULPurchaseDialog(false)}
        maxWidth="lg"
        open={shouldShowOPULPurchaseDialog && isOPULOptingIn}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          padding={4}
          alignItems="center"
        >
          {isOPULPurchaseTimeout ? (
            <>
              <WarningIcon />
              <span>Operation has timed out</span>
            </>
          ) : hasOPULPurchaseError ? (
            <>
              <ErrorIcon />
              <span>An unexpected error ocurred</span>
            </>
          ) : (
            <>
              <span>Please opt in to OPUL before purchase</span>
              <CircularProgress size="1.5rem" color="primary" />
            </>
          )}
        </Box>
      </Dialog> */}
    </StyledContainer>
  );
};

export default PoolDetailPage;
