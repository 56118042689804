import React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import AssetCard from '@opulous/web/src/components/Assets/AssetCard';
import {
  AssetContractMetadata,
  ContractStatus,
} from '@opulous/web/src/shared/types';

const MasonryWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, -1.5),
  paddingTop: theme.spacing(1.75),
}));

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 2, 4, 2),
  gridTemplateColumns: `repeat(auto-fit, minmax(${theme.spacing(20)}, 1fr))`,

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: `repeat(3, 1fr)`,
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: `repeat(4, 1fr)`,
  },
  [theme.breakpoints.up('xl')]: {
    gridTemplateColumns: `repeat(5, 1fr)`,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(3),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 24px) !important',
  padding: theme.spacing(0, 1.5),
  margin: '0 !important',
}));

interface AssetListProps {
  assets: AssetContractMetadata[];
  emptyMessage: string;
  loading?: boolean;
}

const AssetList: React.FC<AssetListProps> = ({
  assets,
  emptyMessage,
  loading = false,
}) => {
  return (
    <MasonryWrapper data-testid="asset-list">
      {!loading && assets.length === 0 && (
        <StyledBox data-testid="asset-list__empty-wrapper">
          <Description
            variant="body1"
            gutterBottom
            data-testid="asset-list__empty-wrapper__message"
          >
            {emptyMessage}
          </Description>
          <Button
            href="https://opulous.org/"
            target="_blank"
            color="primary"
            data-testid="asset-list__empty-wrapper__button"
          >
            Go to opulous.org
          </Button>
        </StyledBox>
      )}
      <Grid>
        {loading && (
          <Box data-testid="asset-list__skeleton-wrapper">
            <Skeleton variant="rectangular" height={200}></Skeleton>
            <Skeleton variant="text"></Skeleton>
            <Skeleton variant="text"></Skeleton>
          </Box>
        )}
        {assets.map((asset: AssetContractMetadata) => (
          <AssetCard
            key={asset.id}
            unitName={asset.unitName}
            title={asset?.custom?.title || asset.unitName}
            image={asset.image}
            subTitle={asset.custom?.artist}
            type={asset.type}
            nftCount={asset.nftCount}
            decimals={asset.decimals}
            investment={asset.lastInvestmentLinked}
            transferInProgress={asset?.contracts?.some(
              a => a.status === ContractStatus.assetOptedIn,
            )}
          />
        ))}
      </Grid>
    </MasonryWrapper>
  );
};

AssetList.defaultProps = {
  assets: [],
};

export default AssetList;
