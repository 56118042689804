import { ReactElement } from 'react';
import { styled } from '@mui/system';

interface SectionHighlightsProps {
  items: string[];
  note?: string;
}

const DescriptionList = styled('ul')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  '&ul': {
    marginBottom: 24,
    paddingLeft: theme.spacing(2.5),
  },
}));

const DescriptionListItem = styled('li')(() => ({
  fontSize: 16,
  lineHeight: '24px',
}));

const Note = styled('div')(() => ({
  fontSize: 14,
  lineHeight: '21px',
  fontWeight: 300,
  color: 'rgba(0, 0, 5, 0.48)',
}));

const SectionHighlights = ({
  items,
  note,
}: SectionHighlightsProps): ReactElement => {
  return (
    <div>
      <DescriptionList>
        {items.map(item => (
          <DescriptionListItem>{item}</DescriptionListItem>
        ))}
      </DescriptionList>
      <Note>{note || 'sdas'}</Note>
    </div>
  );
};

export default SectionHighlights;
