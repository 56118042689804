import axios, { AxiosResponse } from 'axios'
import { PoolList, PoolDetails,GetStakeTransactionResponse, ICreatePool } from '../../shared/types'
import { getApiBaseUrl } from '../../axios-setup'
import BigNumber from 'bignumber.js'
import { getAdminAuthorizationHeader } from '../admin'

type IGetPoolsResponse = {
  data: PoolList[]
}
type IGetPoolDetailsResponse = {
  data: PoolDetails
}
type IAdminGetAllPools = {
  data: PoolList[]
  count: number
  page: number
  pageSize: number
}

const getAllPools = async ( walletAddress?: string): Promise<AxiosResponse<IGetPoolsResponse>> => {
  const query = walletAddress ? `?walletAddress=${walletAddress}` : '';
  return axios.get(`${getApiBaseUrl()}/api/pools/stake${query}`)
}

const getPool = async (
  id: number,
  walletAddress?: string
): Promise<AxiosResponse<IGetPoolDetailsResponse>> => {
  const query = walletAddress ? `?walletAddress=${walletAddress}` : '';
  const url = `${getApiBaseUrl()}/api/pools/stake/${id}${query}`
  return axios.get(url)
}

const getStakeTransaction = (
  id: number,
  amount: BigNumber,
  period: number,
  walletAddress: string
): Promise<AxiosResponse<GetStakeTransactionResponse>> => {
  return axios.post(`${getApiBaseUrl()}/api/pools/stake/${id}`, {
    amount: amount.toFixed(),
    lockup: period,
    walletAddress,
  })
}

const adminGetAllPools = async (page: number, pageSize: number): Promise<IAdminGetAllPools> => {
  const { data: { data } } = await axios({
    method: 'GET',
    url: `${getApiBaseUrl()}/api/admin/pools?${new URLSearchParams({
      page: `${page}`,
      pageSize: `${pageSize}`,
    }).toString()}`,
    headers: await getAdminAuthorizationHeader(),
  });
  return data;
}

const adminGetPool = async (id: number): Promise<PoolList> => {
  const { data: { data } } = await axios({
    method: 'GET',
    url: `${getApiBaseUrl()}/api/admin/pools/${encodeURIComponent(id)}`,
    headers: await getAdminAuthorizationHeader(),
  });
  return data;
}

const adminCreatePools = async (pool: ICreatePool): Promise<void> => {
  await axios({
    method: 'POST',
    url: `${getApiBaseUrl()}/api/admin/pools`,
    headers: await getAdminAuthorizationHeader(),
    data: pool,
  });
}

const adminEditPools = async (id: number, pool: Partial<ICreatePool>): Promise<void> => {
  await axios({
    method: 'PATCH',
    url: `${getApiBaseUrl()}/api/admin/pools/${encodeURIComponent(id)}`,
    headers: await getAdminAuthorizationHeader(),
    data: pool,
  });
}

const adminDeletePool = async (id: number): Promise<void> => {
  await axios({
    method: 'DELETE',
    url: `${getApiBaseUrl()}/api/admin/pools/${encodeURIComponent(id)}`,
    headers: await getAdminAuthorizationHeader(),
  });
}

export default {
  getAllPools,
  getPool,
  getStakeTransaction,
  adminGetAllPools,
  adminGetPool,
  adminCreatePools,
  adminEditPools,
  adminDeletePool,
}
