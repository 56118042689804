import { ReactElement, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import WalletContext from '@opulous/web/src/context/context';
import { UltradeDashboard } from '@opulous/web/src/components/UltradeWidget';
import config from '@opulous/web/src/config';
import { CircularProgress } from '@mui/material';

export const CircularProgressContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default function ExchangePage(): ReactElement {
  const {
    state: { wallet, walletType, connector },
  } = useContext(WalletContext);
  const [walletAddress, setWalletAddress] = useState(wallet);

  useEffect(() => {
    setTimeout(() => {
      setWalletAddress(wallet);
    }, 500);
  }, [wallet]);

  if (!config.env.ULTRADE_API_URL) {
    return <></>;
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {walletAddress !== wallet ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        <UltradeDashboard
          walletAddress={walletAddress}
          walletType={walletType}
          connector={connector}
        />
      )}
    </div>
  );
}
