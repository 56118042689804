import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/system';
import { Skeleton } from '@mui/material';
import Link from '@mui/material/Link';
import LaunchIcon from '@mui/icons-material/Launch';
import { Asset } from '@opulous/web/src/shared/types';
import {
  Header,
  StyledCard,
  Title,
  SubTitle,
} from '@opulous/web/src/components/Assets/Songs/SongDetailPage';
import config from 'src/config';

const SongDetailInformationWrapper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  height: '100%',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
    boxShadow: 'none',
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.spacing(3),
  padding: theme.spacing(1, 0, 1, 0),
  color: theme.palette.info.main,
  fontWeight: 600,

  fontSize: theme.spacing(2.2),
  height: theme.spacing(5),
  textDecoration: 'none',

  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(1.8),
  },

  '&:hover': {
    color: theme.palette.info.main,
  },
  svg: {
    fontSize: theme.spacing(2.2),
    marginLeft: theme.spacing(1),
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  flexShrink: 0,
  width: theme.spacing(32),
  height: theme.spacing(32),
  borderRadius: theme.spacing(1),
}));

const StyledCardMediaLoading = styled('div')(({ theme }) => ({
  flexShrink: 0,
  width: theme.spacing(32),
  height: theme.spacing(32),
  borderRadius: theme.spacing(1),
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: `${theme.spacing(0, 0, 0, 4)} !important`,
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(4, 0, 0, 0)} !important`,
  },
}));

const Description = styled(Typography)(() => ({
  letterSpacing: 0.2,
}));

const StyledLoader = styled('div')(({ theme }) => ({
  background: theme.palette.grey.A100,
  borderRadius: '8px',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: '13px',
  marginTop: '24px',
  '& p': {
    fontSize: '14px',
  },
  padding: '9px 12px 9px 12px',
  '& .spinning-loader': {
    width: '14px',
    height: '14px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    background: 'transparent',
    'border-left-color': 'transparent',
    'animation-name': 'rotate-s-loader',
    'animation-iteration-count': 'infinite',
    'animation-duration': '1s',
    'animation-timing-function': 'linear',
    position: 'relative',
  },
  '@keyframes rotate-s-loader': {
    from: {
      transform: 'rotate(0)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

interface SongDetailInformationProps {
  asset: Asset;
  transferInProgress?: boolean;
  loading?: boolean;
  wallet: string;
}

const AssetDetailInformation: React.FC<SongDetailInformationProps> = ({
  wallet,
  asset,
  transferInProgress = false,
  loading = false,
}) => {
  const getAssetTitle = (a: Asset) => {
    if (!a) {
      return '';
    }

    return a?.custom?.title ? `${a?.custom?.title}` : a.name;
  };
  const ASSET_LINK = `${config.env.ALGO_EXPLORER_URL}/address/${wallet}`;

  return !loading ? (
    <SongDetailInformationWrapper>
      <StyledCard>
        <StyledCardMedia image={`${asset.image}`} />
        <StyledCardContent>
          <Header>
            <Title variant="h1">{getAssetTitle(asset)}</Title>
            {asset.custom?.artist && (
              <SubTitle>{asset.custom?.artist}</SubTitle>
            )}
          </Header>

          <Description variant="body1">
            {asset.custom?.description || ''}
          </Description>
          {wallet && (
            <StyledLink
              href={ASSET_LINK}
              target="_blank"
              data-testid="asset-detail__explorer-link"
            >
              Explorer
              <LaunchIcon />
            </StyledLink>
          )}
          {transferInProgress && (
            <StyledLoader>
              <div className="spinning-loader" />
              <Typography>
                Transfer in progress… This may take up to 10 days after the
                opt-in.
              </Typography>
            </StyledLoader>
          )}
        </StyledCardContent>
      </StyledCard>
    </SongDetailInformationWrapper>
  ) : (
    <SongDetailInformationWrapper>
      <StyledCard>
        <StyledCardMediaLoading>
          <Skeleton variant="rectangular" height={'100%'}></Skeleton>
        </StyledCardMediaLoading>
        <StyledCardContent>
          <Header>
            <Skeleton variant="text" width="200px" />
          </Header>

          <Skeleton variant="text" width="200px" />
        </StyledCardContent>
      </StyledCard>
    </SongDetailInformationWrapper>
  );
};

export default AssetDetailInformation;
