import React, { ReactElement } from 'react';
import { ArrowBack } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { WalletTypeEnum } from '@opulous/web/src/shared/types';
import StepWrapper from '@opulous/web/src/components/shared/StepWrapper';
import { WalletOption } from '@opulous/web/src/components/Claims/Steps/components';
import MAWLogo from '@opulous/web/src/components/Claims/Steps/assets/maw.svg';
import PeraLogo from '@opulous/web/src/components/Claims/Steps/assets/pera.svg';
import config from '@opulous/web/src/config';

interface IConnectWallet {
  loading: boolean;
  errorText?: string;
  onWalletSelect: (type: WalletTypeEnum) => void;
  onBack?: () => void;
  onClose?: () => void;
}

export const SupportedWalletsMeta = [
  {
    key: WalletTypeEnum.myAlgoWallet,
    name: 'My Algo Wallet',
    image: MAWLogo,
    active: config.env.WALLET_MY_ALGO_ACTIVE,
  },
  {
    key: WalletTypeEnum.peraWallet,
    name: 'Pera Wallet',
    image: PeraLogo,
    active: true,
  },
];
export const AvailableWalletsMeta = SupportedWalletsMeta.filter(
  it => it.active,
);

export default function ConnectWallet({
  loading,
  errorText,
  onWalletSelect,
  onBack,
  onClose,
}: IConnectWallet): ReactElement {
  const actions = AvailableWalletsMeta.map(wt => (
    <WalletOption
      key={wt.key}
      type={wt.key}
      name={wt.name}
      image={wt.image}
      active={wt.active}
      onClick={onWalletSelect}
    />
  ));
  if (onBack) {
    actions.push(
      <div>
        <Button
          size="small"
          onClick={onBack}
          variant="text"
          startIcon={<ArrowBack fontSize="small" />}
        >
          Back
        </Button>
      </div>,
    );
  }

  return (
    <StepWrapper
      title="Connect to a wallet to see your assets"
      loading={loading}
      errorText={errorText}
      actions={actions}
      onClose={onClose}
    />
  );
}
