import { ReactElement } from 'react';
import { styled } from '@mui/system';
import { Paper } from '@mui/material';
import { InvestmentDetails } from '@opulous/web/src/shared/types';

const SectionContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: theme.spacing(1.6),
  marginBottom: theme.spacing(4),
}));
const Thumbnail = styled('img')(({ theme }) => ({
  width: theme.spacing(18),
  height: theme.spacing(18),
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));
const Details = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(2, 3, 0, 3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 2),
  },
}));
const Title = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.spacing(2.8),
  marginBottom: theme.spacing(1.2),
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(2.2),
    marginBottom: theme.spacing(0.8),
  },
}));
const Legend = styled('label')(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.spacing(1.8),
  color: theme.palette.primary.main,
  textDecoration: 'none',
  marginBottom: theme.spacing(1.2),
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(1.6),
    marginBottom: theme.spacing(0.8),
  },
}));
const Description = styled('p')(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  color: theme.palette.grey[500],
  lineHeight: '120%',
  margin: 0,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(1.3),
  },
}));
const PlayNow = styled('a')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.4),
  marginTop: theme.spacing(1.2),
  fontWeight: 400,
  fontSize: theme.spacing(1.8),
  color: theme.palette.primary.main,
  textDecoration: 'none',
  svg: {
    path: {
      stroke: theme.palette.primary.main,
    }
  }
}));

type Props = {
  investment: InvestmentDetails;
};
export default function InvestmentIncludedCatalog({ investment }: Props): ReactElement {
  return (
    <>
      {investment.includedCatalog?.map((it, ix) => (
        <SectionContainer
          key={`perk-section-${ix}`}
          data-testid="investment-included-catalog__section"
        >
          <Thumbnail
            data-testid="investment-included-catalog__section__thumbnail"
            src={it.thumbnail.url}
            onClick={() => {
              if (it.preSaleLink) {
                window.open(it.preSaleLink);
              }
            }}
          />
          <Details data-testid="investment-included-catalog__section__details">
            <Title data-testid="investment-included-catalog__section__details__title">
              {it.title}
            </Title>
            <Legend
              data-testid="investment-included-catalog__section__details__legend"
            >
              {it.legend}
            </Legend>
            <Description data-testid="investment-included-catalog__section__details__description">
              {it.description}
            </Description>
            {it.playLink && <PlayNow href={it.playLink} target="_blank">
              Play Now
              <svg width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 9L10.8586 12.8586C10.9367 12.9367 10.9367 13.0633 10.8586 13.1414L7 17" stroke-width="1.5" stroke-linecap="round"/>
                <path d="M10 13L2 13" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
            </PlayNow>}
          </Details>
        </SectionContainer>
      ))}
    </>
  );
}
