import { styled } from '@mui/system';
import { ArrowDownward } from '@mui/icons-material';

export const Calculator = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(0),
}));

export const StyledArrowDownward = styled(ArrowDownward)(({ theme }) => ({
  boxShadow: '0 0 16px 4px rgba(92, 100, 123, 0.08)',
  backgroundColor: theme.palette.common.white,
  borderRadius: '50%',
  padding: theme.spacing(1.6),
  width: theme.spacing(6),
  height: theme.spacing(6),
  marginTop: `-${theme.spacing(0.8)}`,
  marginBottom: `-${theme.spacing(0.8)}`,
  zIndex: 2,
}));

export const StyledInput = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: `${(theme.shadows as any)[3]}`,
  outline: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1.5, 1.5),
  width: '100%',
  height: theme.spacing(10),
  zIndex: 1,
  '&:focus-within': {
    outline: `2px solid ${theme.palette.primary.main}`,
  },

  label: {
    fontSize: theme.spacing(1.8),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(1.5),
    },
  },
  span: {
    fontSize: theme.spacing(2.5),
    fontWeight: '600',
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    p: {
      fontSize: theme.spacing(2.5),
      fontWeight: '600',
      margin: theme.spacing(-0.1, 0.4, 0, 0),
    },
    input: {
      '&:hover, &:focus, &:focus-visible': {
        border: 'none',
        outline: 'none',
      },
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      border: 'none',
      width: '90%',
      fontWeight: '900',
      fontFamily: 'inherit',
      height: theme.spacing(3.3),
      fontSize: theme.spacing(2.5),
    },
    '&[data-haserror="true"]': {
      p: {
        color: theme.palette.error.main,
      },
      input: {
        color: theme.palette.error.main,
      },
    },
  },
}));