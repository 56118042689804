import React, { FormEvent, useState } from 'react';
import { styled } from '@mui/system'
import { Auth } from 'aws-amplify';
import useAuth from 'src/hooks/useAuth';
import { Navigate, useNavigate } from 'react-router-dom';
import { CognitoUser } from '@aws-amplify/auth';
import {
  Button,
  InputAdornment,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QRCodeCanvas from 'qrcode.react';

const ForgotPasswordButton = styled('a')(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: 700,
  textDecoration: 'none',
  fontSize: '.9rem',
}));

const ErrorMessage = styled('span')(({ theme }) => ({
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.error.main,
  margin: theme.spacing(2, 0, 1, 0),
}));

export default function AdminLogin(): React.ReactElement {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [otp, setOtp] = useState('');
  const [mfaType, setMfaType] = useState<'SMS_MFA'|'SOFTWARE_TOKEN_MFA'|null>(null);
  const [totpSetupUrl, setTotpSetupUrl] = useState('');
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [challengedUser, setChallengedUser] = useState<CognitoUser>();
  const [error, setError] = useState<Error>();
  const [isSendingVerificationCode, setIsSendingVerificationCode] = useState(false);
  const [isOtpPending, setIsOtpPending] = useState(false);
  const { user, isAdmin, isInitializing } = useAuth();
  const navigate = useNavigate();

  const resetFields = () => {
    setUsername('');
    setPassword('');
    setNewPassword('');
    setVerificationCode('');
    setIsNewPasswordRequired(false);
    setIsPasswordReset(false);
    setChallengedUser(undefined);
    setError(undefined);
    setIsSendingVerificationCode(false);
  }

  const handleLoginResponse = async (currentUser: any) => {
    if (isOtpPending) {
      if (totpSetupUrl) {
        await Auth.verifyTotpToken(challengedUser, otp);
      } else {
        await Auth.confirmSignIn(challengedUser, otp, mfaType);
      }
      return navigate('/admin/dashboard');
    }

    if (currentUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setIsNewPasswordRequired(true);
      setChallengedUser(currentUser);
      setError(new Error('New password required'));
    } else if (currentUser.challengeName === 'MFA_SETUP') {
      const code = await Auth.setupTOTP(currentUser);
      setTotpSetupUrl(`otpauth://totp/AWSCognito:${encodeURIComponent(username)}?${new URLSearchParams({
        secret: code,
        issuer: 'OP-Admin',
      }).toString()}`);
      setChallengedUser(currentUser);
      setIsOtpPending(true);
    } else if (currentUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
      setChallengedUser(currentUser);
      setIsOtpPending(true);
      setMfaType('SOFTWARE_TOKEN_MFA');
    } else {
      setIsNewPasswordRequired(false);
      return navigate('/admin/dashboard');
    }
  };

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const currentUser = await Auth.signIn(username, password);
      await handleLoginResponse(currentUser);
    } catch (err: any) {
      if (err.code === 'PasswordResetRequiredException') {
        setIsPasswordReset(true);
      } else {
        setError(err);
      }
    }
  };

  const handlePasswordChange = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await Auth.completeNewPassword(
        challengedUser,
        newPassword,
      );
      setPassword(newPassword);
      setIsNewPasswordRequired(false);
      setError(undefined);

      const currentUser = await Auth.signIn(username, newPassword);
      await handleLoginResponse(currentUser);
    } catch (err: any) {
      if (err.code === 'PasswordResetRequiredException') {
        setIsPasswordReset(true);
      } else {
        setError(err);
      }
    }
  };

  const handlePasswordReset = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await Auth.forgotPasswordSubmit(
        username,
        verificationCode,
        newPassword,
      );
      resetFields();
    } catch (err: any) {
      setError(err);
    }
  };

  const handlePasswordForgot = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    resetFields();
    setIsPasswordReset(true);
  };

  const handleVerificationCodeSend = async () => {
    try {
      setIsSendingVerificationCode(true);
      await Auth.forgotPassword(username);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsSendingVerificationCode(false);
    }
  };

  const handleBack = () => {
    resetFields();
    setIsPasswordReset(false);
  };

  if (isInitializing) {
    return (<span>Loading...</span>);
  }

  if (user) {
    if (!isAdmin) {
      return (<Navigate to="/" />);
    }
    return (<Navigate to="/admin/dashboard" />);
  }

  if (isPasswordReset) {
    return (
      <Box display="flex" justifyContent="center">
        <form onSubmit={handlePasswordReset}>
          <Box display="flex" flexDirection="column" padding={2} gap={1} minWidth="20rem" maxWidth="50rem">
            <div>
              <Button
                size="small"
                onClick={handleBack}
                variant="text"
                startIcon={<ArrowBackIcon fontSize="small" />}
              >
                Back
              </Button>
            </div>
            <Box paddingBottom={2}>
              <Typography variant="h1">Reset password</Typography>
            </Box>
            <TextField
              label="Username"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <TextField
              type="password"
              label="New Password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
            <TextField
              label="Verification Code"
              value={verificationCode}
              onChange={e => setVerificationCode(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      type="button"
                      size="small"
                      onClick={handleVerificationCodeSend}
                      disabled={isSendingVerificationCode}
                    >
                      {isSendingVerificationCode ? (
                        <CircularProgress size="1.5rem" />
                      ) : 'Send'}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            {error && (<ErrorMessage>{error.message}</ErrorMessage>)}
            <Box marginTop={1} display="flex" flexDirection="column">
              <Button type="submit">Reset</Button>
            </Box>
          </Box>
        </form>
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center">
      <form onSubmit={isNewPasswordRequired ? handlePasswordChange : handleFormSubmit}>
        <Box display="flex" flexDirection="column" padding={2} gap={1} minWidth="20rem" maxWidth="50rem">
          <Box paddingBottom={2}>
            <Typography variant="h1">Login</Typography>
          </Box>
          <TextField
            label="Username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            disabled={isNewPasswordRequired || isOtpPending}
          />
          <TextField
            type="password"
            label={isNewPasswordRequired ? 'New Password' : 'Password'}
            value={isNewPasswordRequired ? newPassword : password}
            onChange={e => (isNewPasswordRequired ? setNewPassword : setPassword)(e.target.value)}
            disabled={isOtpPending}
          />
          {isOtpPending && (
            <TextField
              type="text"
              label="OTP"
              value={otp}
              onChange={e => setOtp(e.target.value)}
            />
          )}
          {!isNewPasswordRequired && !totpSetupUrl && (
            <ForgotPasswordButton href="#" onClick={handlePasswordForgot}>
              Forgot password
            </ForgotPasswordButton>
          )}
          {!!totpSetupUrl && (
            <Box paddingY={2}>
              <Box paddingBottom={2} fontSize=".8rem" fontWeight="600">
                Please scan the QRcode with your Authenticator app
              </Box>
              <Box display="flex" justifyContent="center">
                <QRCodeCanvas value={totpSetupUrl}/>
              </Box>
            </Box>
          )}
          {error && (<ErrorMessage>{error.message}</ErrorMessage>)}
          <Box marginTop={1} display="flex" flexDirection="column">
            <Button type="submit">Log in</Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}
