import * as React from 'react';

const RaffleWinIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.483 1.667H6.518c-1.306 0-2.351 1.01-2.351 2.189v4.798c0 2.777 2.177 5.134 4.963 5.555v2.441H6.518c-.523 0-.87.337-.87.842 0 .505.347.842.87.842h6.965c.522 0 .87-.337.87-.842 0-.505-.348-.842-.87-.842H10.87v-2.44c2.786-.422 4.963-2.695 4.963-5.556V3.856c0-1.179-1.045-2.189-2.351-2.189Zm.61 6.987c0 2.188-1.829 3.956-4.093 3.956-2.263 0-4.092-1.768-4.092-3.956V3.856c0-.253.261-.506.61-.506h6.965c.261 0 .61.253.61.506v4.798ZM2.5 2.5c-.5 0-.833.346-.833.866V8.3c0 .52.333.866.833.866.5 0 .834-.347.834-.866V3.366C3.334 2.933 3 2.5 2.5 2.5Zm15 0c-.5 0-.833.346-.833.866V8.3c0 .52.333.866.833.866.5 0 .834-.347.834-.866V3.366c0-.433-.334-.866-.834-.866Z"
    />
  </svg>
)

export default RaffleWinIcon;
