import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Box, Button, CircularProgress, FormControlLabel, Switch, TextField } from '@mui/material';
import * as RaffleService from 'src/services/raffles';
import { styled } from '@mui/system';
import { Link as _Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { validateField } from 'src/utils';
import ErrorIcon from '@mui/icons-material/Error';
import * as Sentry from '@sentry/react';
import axios from 'axios';

interface IValidationErrors {
  title: string
  description: string
  startTimestamp: string
  endTimestamp: string
  numberOfWinners: string
  maxTicketAmount: string
  investmentId: string
  image: string
  hidden: string
}

const ErrorMessage = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `2px solid ${theme.palette.error.main}`,
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.error.light,
  padding: theme.spacing(2),
  color: theme.palette.error.main,
  gap: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));

const Link = styled(_Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  gap: theme.spacing(1),

  '& > svg': {
    width: '1rem',
    height: '1rem',
  },
}));

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
  maxWidth: '50rem',
}));

const Fieldset = styled('fieldset')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  color: theme.palette.text.primary,
  display: 'block',
  position: 'relative',
  minWidth: 0,

  '&:hover': {
    border: `1px solid ${theme.palette.common.black}`,
  },

  '&.error': {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },

  '& > legend': {
    fontSize: '.75rem',
    fontWeight: '300',
  },
}));

const ImageErrorMessage = styled('p')(({ theme }) => ({
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.error.main,
  marginTop: 3,
  marginLeft: 14,
  marginRight: 14,
}));

const PreviewArea = styled('div')(({ theme }) => ({
  height: '30rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.grey[500],
  padding: theme.spacing(1),
}));

export const NULLABLE_FIELDS = new Set([
  'investmentId',
]);

const fieldValidators = {
  title: Yup.string()
    .required('This field is required'),
  description: Yup.string()
    .required('This field is required'),
  image: Yup.mixed()
    .required('Please upload a raffle image'),
  endTimestamp: Yup.date()
    .typeError('Date expected')
    .required('This field is required'),
  numberOfWinners: Yup.number().min(1)
    .typeError('Number expected')
    .required('This field is required'),
  startTimestamp: Yup.date()
    .typeError('Date expected')
    .required('This field is required'),
  maxTicketAmount: Yup.number().min(1)
    .typeError('Number expected')
    .required('This field is required'),
  investmentId: Yup.number().min(1)
    .typeError('Number expected'),
  hidden: Yup.bool()
    .typeError('Boolean expected')
    .required('This field is required'),
};

export default function RaffleCreate(): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const imageRef = useRef<File>();
  const fileInputRef = useRef<HTMLInputElement|null>(null);
  const startTimestampInputRef = useRef<HTMLInputElement|null>(null);
  const endTimestampInputRef = useRef<HTMLInputElement|null>(null);
  const [isDraftMode, setIsDraftMode] = useState(true);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startTimestamp, setStartTimestamp] = useState('');
  const [endTimestamp, setEndTimestamp] = useState('');
  const [numberOfWinners, setNumberOfWinners] = useState('');
  const [maxTicketAmount, setMaxTicketAmount] = useState('');
  const [investmentId, setInvestmentId] = useState('');
  const [previewImageUrl, setPreviewImageUrl] = useState<string|null>(null);
  const [validationErrors, setValidationErrors] = useState<Partial<IValidationErrors>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState<string>('');

  const markDirty = (name: keyof IValidationErrors) => {
    setValidationErrors(errs => { delete errs[name]; return { ...errs }; });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errs: Partial<IValidationErrors> = {};

    for (const [name, value] of ([
      ['hidden', isDraftMode],
      ['title', title],
      ['description', description],
      ['image', imageRef.current],
      ['endTimestamp', endTimestamp],
      ['numberOfWinners', numberOfWinners],
      ['startTimestamp', startTimestamp],
      ['maxTicketAmount', maxTicketAmount],
      ['investmentId', investmentId],
    ] as [keyof typeof fieldValidators, any][])) {
      if (NULLABLE_FIELDS.has(name) && !value) continue;
      const msg = await validateField(fieldValidators[name], value);
      if (msg) {
        errs[name] = msg;
      }
    }

    if (
      !errs.endTimestamp &&
      new Date(endTimestamp + 'Z').getTime() <= Date.now()
    ) {
      errs.endTimestamp = 'End timestamp must be later than now';
    } else if (
      !errs.startTimestamp &&
      !errs.endTimestamp &&
      new Date(startTimestamp).getTime() >= new Date(endTimestamp).getTime()
    ) {
      errs.startTimestamp = 'startTimestamp must be earlier than endTimestamp';
    }

    setValidationErrors(errs);

    if (Object.keys(errs).length) return;

    try {
      setIsSubmitting(true);
      await RaffleService.adminCreateRaffle({
        title,
        description,
        image: imageRef.current as File,
        endTimestamp: endTimestamp + 'Z',
        numberOfWinners: parseInt(numberOfWinners),
        startTimestamp: startTimestamp + 'Z',
        maxTicketAmount: parseInt(maxTicketAmount),
        investmentId: investmentId ? parseInt(investmentId) : null,
        hidden: isDraftMode,
      });

      navigate('/admin/raffles');
      setIsSubmitting(false);
    } catch (err) {
      if (
        axios.isAxiosError(err) &&
        err.response?.status === 400 &&
        err.response?.data?.errors?.length &&
        err.response?.data?.errors?.[0]?.message
      ) {
        setSubmissionErrorMessage(err.response?.data?.errors?.[0]?.message);
      } else {
        setSubmissionErrorMessage('Unexpected error occurred, please try again later.');
      }
      Sentry.captureException(err);
      setIsSubmitting(false);
    }
  }

  const handleImgFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    markDirty('image');
    const files = e.target.files;
    const file = files?.[0];
    Object.assign(imageRef, { current: file });

    if (file) {
      const blob = new Blob([file], { type: file.type });
      const url = URL.createObjectURL(blob);
      setPreviewImageUrl(url);
    } else {
      setPreviewImageUrl(null);
    }
  };

  const handleImageClear = () => {
    markDirty('image');
    setPreviewImageUrl(null);
    Object.assign(imageRef, { current: undefined });
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (startTimestampInputRef.current) {
      startTimestampInputRef.current.min = new Date().toISOString().replace(/:\d{2}(\.\d{3})?Z$/g, '');
    }
    if (endTimestampInputRef.current) {
      endTimestampInputRef.current.min = new Date().toISOString().replace(/:\d{2}(\.\d{3})?Z$/g, '');
    }
  }, []);

  useEffect(() => {
    if (startTimestamp && endTimestampInputRef.current) {
      endTimestampInputRef.current.min = startTimestamp;
    }
  }, [startTimestamp]);

  useEffect(() => {
    if (endTimestamp && startTimestampInputRef.current) {
      startTimestampInputRef.current.max = endTimestamp;
    }
  }, [endTimestamp]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={3}>
      <Form onSubmit={handleSubmit}>
        <div>
          <Link to={(location.state as any)?.back ?? '/admin/raffles'}>
            <ArrowBackIcon />
            <span>Back</span>
          </Link>
        </div>
        <div>
          <h1>Create Raffle</h1>
        </div>
        <FormControlLabel
          label="Draft Mode"
          control={<Switch
            checked={isDraftMode}
            onChange={e => {
              markDirty('hidden');
              setIsDraftMode(e.target.checked);
            }}
          />}
        />
        <TextField
          label="Title"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.title}
          helperText={validationErrors.title}
          value={title}
          onChange={e => {
            markDirty('title');
            setTitle(e.target.value);
          }}
        />
        <TextField
          label="Description"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.description}
          helperText={validationErrors.description}
          rows={8}
          multiline
          value={description}
          onChange={e => {
            markDirty('description');
            setDescription(e.target.value);
          }}
        />
        <TextField
          inputRef={startTimestampInputRef}
          label="Start Timestamp (UTC Time)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.startTimestamp}
          helperText={validationErrors.startTimestamp}
          type="datetime-local"
          value={startTimestamp}
          onChange={e => {
            markDirty('startTimestamp');
            setStartTimestamp(e.target.value);
          }}
        />
        <TextField
          inputRef={endTimestampInputRef}
          label="End Timestamp (UTC Time)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.endTimestamp}
          helperText={validationErrors.endTimestamp}
          type="datetime-local"
          value={endTimestamp}
          onChange={e => {
            markDirty('endTimestamp');
            setEndTimestamp(e.target.value);
          }}
        />
        <TextField
          label="Number Of Winners"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.numberOfWinners}
          helperText={validationErrors.numberOfWinners}
          value={numberOfWinners}
          onChange={e => {
            markDirty('numberOfWinners');
            setNumberOfWinners(e.target.value);
          }}
        />
        <TextField
          label="Max Ticket Amount"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.maxTicketAmount}
          helperText={validationErrors.maxTicketAmount}
          value={maxTicketAmount}
          onChange={e => {
            markDirty('maxTicketAmount');
            setMaxTicketAmount(e.target.value);
          }}
        />
        <TextField
          label="Investment ID (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.investmentId}
          helperText={validationErrors.investmentId}
          value={investmentId}
          onChange={e => {
            markDirty('investmentId');
            setInvestmentId(e.target.value);
          }}
        />
        <div>
          <Fieldset className={`${validationErrors.image ? 'error' : ''}`}>
            <legend>Raffle Image</legend>
            <Box display="flex" gap={1} alignItems="center" flexWrap="wrap" >
              <input
                style={{ display: 'none' }}
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={handleImgFileChange}
              />
              <Button size="small" onClick={() => fileInputRef.current?.click()}>
                Select
              </Button>
              <Button size="small" onClick={handleImageClear}>Clear</Button>
            </Box>
            <Box marginY={2} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" width="100%">
              File Name: {imageRef.current?.name ?? '-'}
            </Box>
            <PreviewArea>
              {previewImageUrl ? (
                <img style={{ objectFit: 'contain', width: '100%', height: '100%' }} src={previewImageUrl} />
              ) : 'Preview'}
            </PreviewArea>
          </Fieldset>
          <ImageErrorMessage>{validationErrors.image}</ImageErrorMessage>
        </div>

        {!!submissionErrorMessage && (
          <ErrorMessage>
            <ErrorIcon />
            <Box flex="1">{submissionErrorMessage}</Box>
          </ErrorMessage>
        )}

        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (<CircularProgress size="1.5rem" color="primary" />) : 'Submit'}
        </Button>
      </Form>
    </Box>
  );
}
