import React, { ReactElement } from 'react';
import { format } from 'date-fns';
import {
  Theme,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Skeleton,
  useMediaQuery,
  TableSortLabel,
  TablePagination,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import MobileTableRow from '@opulous/web/src/components/MobileTableRow';
import { formatCurrency, formatNumber } from '@opulous/web/src/utils';
import {
  TableHeader,
  StyledTableCell,
  TruncatedTableCell,
  StyledTableContainer,
  StyledMobileSkeleton,
  MobilePagination,
} from '@opulous/web/src/components/Royalties/FullOverview/styled-components';
import { prepareTabSortOption } from '@opulous/web/src/components/Royalties/helpers';
import {
  TableTabOption,
  TabOption,
} from '@opulous/web/src/pages/Royalties/types';

const SKELETON_ROWS = new Array(8).fill(1);
const START_OFFSET = 0;

type Props = {
  loading: boolean;
  sortOption?: TableTabOption;
  changeSort: (sort: TableTabOption) => void;
  count: number;
  limit: number;
  offset: number;
  items: {
    date: Date;
    streams: number;
    earning: number;
  }[];
};
export default function MonthsTable({
  loading,
  sortOption,
  changeSort,
  count,
  limit,
  offset,
  items,
}: Props): ReactElement {
  const theme: Theme = useTheme();
  const page = offset / limit;
  const rowsPerPage = limit;

  const handleChangePage = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.ChangeEvent<unknown>
      | null,
    newPage: number,
  ) => {
    if (sortOption) {
      changeSort({ ...sortOption, limit, offset: limit * newPage });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newLimit = parseInt(event.target.value, 10);
    const initPage = 0;
    if (sortOption) {
      changeSort({ ...sortOption, limit: newLimit, offset: initPage });
    }
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const applySort = (column: string) => {
    changeSort(
      prepareTabSortOption(
        TabOption.MONTHS,
        column,
        rowsPerPage,
        START_OFFSET,
        sortOption,
      ),
    );
  };

  const renderTableSortLabel = (
    column: string,
    label: string,
  ): ReactElement => {
    if (sortOption && sortOption.column === column) {
      return (
        <TableSortLabel
          onClick={() => applySort(column)}
          active
          direction={sortOption.direction}
        >
          {label}
        </TableSortLabel>
      );
    }

    return (
      <TableSortLabel onClick={() => applySort(column)}>{label}</TableSortLabel>
    );
  };
  if (isMobile) {
    return (
      <React.Fragment>
        {loading && <StyledMobileSkeleton height={250} />}
        {!loading && (
          <>
            {items.map((row, index) => (
              <MobileTableRow
                key={index}
                data={{
                  name: format(row.date, 'MMMM yyyy'),
                  streams: formatNumber(row.streams),
                  earning: formatCurrency(row.earning),
                }}
              />
            ))}
            <MobilePagination
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
            />
          </>
        )}
      </React.Fragment>
    );
  }

  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>{renderTableSortLabel('month', 'Month')}</TableHeader>
            <TableHeader align="right">
              {renderTableSortLabel('units', 'Streams')}
            </TableHeader>
            <TableHeader align="right">
              {renderTableSortLabel('earning', 'Rewards')}
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            SKELETON_ROWS.map((it, index) => (
              <TableRow key={`table-item-${index}`}>
                <StyledTableCell>
                  <Skeleton variant="text" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton variant="text" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton variant="text" />
                </StyledTableCell>
              </TableRow>
            ))}
          {!loading &&
            items.map((row, index) => (
              <TableRow key={`month-item-${index}`}>
                <TruncatedTableCell>
                  {format(row.date, 'MMMM yyyy')}
                </TruncatedTableCell>
                <StyledTableCell align="right">
                  {formatNumber(row.streams)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatCurrency(row.earning)}
                </StyledTableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledTableContainer>
  );
}
