import { differenceInDays } from 'date-fns';
import { Action, SalesActionType } from './actions';
import { SalesContextState } from './context';

function applyDefaultSort(items: any) {
  return items
    .map((it: any) => {
      let order = 0;
      const now = new Date()
      const saleEndDiffInDays = differenceInDays(
        new Date(it.saleEndDate),
        now,
      );
      if (saleEndDiffInDays <= 0) {
        order = Number(`4.${-1 * saleEndDiffInDays}`);
      } else {
        const saleStartDiffInDays = differenceInDays(
          new Date(it.saleStartDate),
          now,
        );
        const preSaleStartDiffInDays = differenceInDays(
          new Date(it.preSaleStartDate),
          now,
        );

        if (it.soldOut) {
          order = Number(`2.${`${saleEndDiffInDays}`.padStart(2, '0')}`);
        } else if (preSaleStartDiffInDays > 0) {
          order = Number(`3.${`${preSaleStartDiffInDays}`.padStart(2, '0')}`);
        } else if (saleStartDiffInDays > 0) {
          order = Number(`1.${`${saleStartDiffInDays}`.padStart(2, '0')}`);
        } else {
          order = Number(`0.${`${saleEndDiffInDays}`.padStart(2, '0')}`);
        }
      }
      return { ...it, order };
    })
    .sort((a: any, b: any) => a.order - b.order);
}

export const SalesInitializer = (
  initialValue: SalesContextState,
): SalesContextState => initialValue;

export const SalesReducer = (
  state: SalesContextState,
  action: Action<SalesActionType>,
): SalesContextState => {
  switch (action.type) {
    case SalesActionType.SetLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case SalesActionType.SetLoadingMore:
      return {
        ...state,
        loadingMore: action.payload,
      };
    case SalesActionType.SetShowAlert:
      return {
        ...state,
        showAlert: action.payload,
      };
    case SalesActionType.ChangeFilterAndSortSelected:
      return {
        ...state,
        filterAndSortSelected: action.payload,
        pagination: undefined,
      };
    case SalesActionType.LoadAllInvestments: {
      const { hasMore, allInvestments } = action.payload;
      return {
        ...state,
        hasMore,
        allInvestments: state.filterAndSortSelected?.sortBy
          ? allInvestments
          : applyDefaultSort(allInvestments),
      };
    }
    case SalesActionType.LoadMoreInvestments: {
      const { hasMore, newPagination, moreInvestments } = action.payload;
      const newAllInvestments = [...state.allInvestments, ...moreInvestments];
      return {
        ...state,
        hasMore,
        pagination: newPagination,
        allInvestments: state.filterAndSortSelected?.sortBy
          ? newAllInvestments
          : applyDefaultSort(newAllInvestments),
      };
    }
    default:
      return state;
  }
};
