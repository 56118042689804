import { TableTabOption, TabOption } from '../../pages/Royalties/types'

export function prepareTabSortOption(
  tab: TabOption,
  column: string,
  limit: number,
  offset: number,
  previousSortOptions?: TableTabOption
): TableTabOption {
  let nextSortOption = {
    tab,
    direction: 'asc',
    column,
    limit,
    offset,
  } as TableTabOption
  if (previousSortOptions && previousSortOptions.column === column) {
    nextSortOption = {
      tab,
      direction: previousSortOptions.direction === 'asc' ? 'desc' : 'asc',
      column,
      limit,
      offset,
    }
  }
  return nextSortOption
}
