import { createContext } from 'react';
import {
  Investment,
  IWalletContextState,
} from '@opulous/web/src/shared/types';
import { SalesActions } from './actions';
import { FilterAndSortSelected, Pagination } from './types';

export type SalesContextState = {
  walletState: IWalletContextState;
  walletAddress: string;
  loading: boolean;
  loadingMore: boolean;
  hasMore: boolean;
  filterAndSortSelected: FilterAndSortSelected;
  pagination?: Pagination;
  showAlert?: {
    error?: boolean
    success?: boolean
    message?: string
  };
  allInvestments: Investment[];
};

type SalesContextType = {
  state: SalesContextState;
  actions: SalesActions;
};
const SalesContext = createContext<SalesContextType>({} as SalesContextType);
export default SalesContext;
