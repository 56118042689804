import React, { ReactElement, useCallback, useContext, useState } from 'react';
import { format } from 'date-fns';
import * as Sentry from '@sentry/react';
import {
  Box,
  Grid,
  Paper,
  Button,
  Link,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Typography,
  Skeleton,
  CircularProgress,
  Theme,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import LaunchIcon from '@mui/icons-material/Launch';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatMicroUSDC } from '@opulous/web/src/utils';
import MobileTableRow from '@opulous/web/src/components/MobileTableRow';
import RoyaltiesContext from '@opulous/web/src/pages/Royalties/context';
import ConfirmModal from '@opulous/web/src/components/shared/ConfirmModal';
import PeraInfoModal from '@opulous/web/src/components/Wallet/PeraInfoModal';
import { WalletTypeEnum } from '@opulous/web/src/shared/types';
import config from 'src/config';
import MicroUSDC from '@opulous/web/src/shared/valueObjects/MicroUSDC';

const StyledCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'nowrap',
  padding: theme.spacing(1.5, 3),
  color: theme.palette.grey[900],
  fontSize: '0.875rem',
}));

const TruncatedCell = styled(StyledCell)(({ theme }) => ({
  textOverflow: 'ellipsis',
  maxWidth: theme.spacing(21),
  overflow: 'hidden',
}));

const LinkCell = styled(StyledCell)(() => ({
  textAlign: 'right',
}));

const StyledMobileSkeleton = styled(Skeleton)(({ theme }) => ({
  margin: theme.spacing(-5, 2, 0, 2),
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  padding: theme.spacing(3, 3, 2, 3),
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontWeight: 500,
}));

const Value = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 800,
  marginTop: theme.spacing(1),
  '& span': {
    fontSize: theme.spacing(1),
  },
}));

const TableHeader = styled(TableCell)(({ theme }) => ({
  color: theme.palette.grey[400],
  padding: theme.spacing(1.5, 3),
  fontSize: '0.875rem',
  fontWeight: 600,
}));

const ClaimButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  marginTop: theme.spacing(2),
}));

const OptInButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  marginTop: theme.spacing(2),
}));

const PayoutContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

const InfoWrapper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  boxShadow: 'none',
  padding: theme.spacing(3),
}));

const HistoryWrapper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  border: `1px solid ${theme.palette.grey[100]}`,
  marginTop: theme.spacing(4),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  padding: theme.spacing(1, 3, 1, 3),
  color: theme.palette.grey[900],
  textDecoration: 'underline',
  fontWeight: 400,

  fontSize: theme.spacing(2.2),
  height: theme.spacing(5),

  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(1.8),
  },

  '&:hover': {
    color: theme.palette.grey[900],
  },
  svg: {
    fontSize: theme.spacing(2.2),
    marginLeft: theme.spacing(1),
  },
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  marginLeft: '1rem',
  svg: {
    color: theme.palette.primary.contrastText,
  },
}));

const ConfirmModalContent = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const SKELETON_ROWS = new Array(8).fill(1);

export default function PayoutsTab(): ReactElement {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { state: royaltiesContext, actions: royaltiesActions } =
    useContext(RoyaltiesContext);
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    tabLoading: loading,
    royaltiesPayoutInfo: info,
    royaltiesPayoutHistory: history,
  } = royaltiesContext;

  const onClickOptin = useCallback(() => {
    if (!royaltiesContext.optInUSDCTransaction) {
      Sentry.captureException(new Error('OptIn USDC transaction empty'));
      return;
    }
    if (!royaltiesContext.processingOptin) {
      royaltiesActions.optInUSDC(
        royaltiesContext.walletState,
        royaltiesContext.optInUSDCTransaction,
      );
    }
  }, [info]);
  const claimRewards = useCallback(() => {
    setOpenConfirmModal(false);
    if (!royaltiesContext.processingWithdraw) {
      royaltiesActions.withdrawRoyalties(royaltiesContext.walletState);
    }
  }, []);
  const claimButtonDisabled = info.availableBalance.isLessThanOrEqualTo(0);
  const openPeraWalletModal =
    royaltiesContext.processingOptin &&
    royaltiesContext.walletState.walletType === WalletTypeEnum.peraWallet;

  return (
    <PayoutContainer data-testid="royalties-payout-tab">
      {openConfirmModal && (
        <ConfirmModal
          title="Claim confirmation"
          confirmLabel="Confirm"
          onConfirm={claimRewards}
          onClose={() => setOpenConfirmModal(false)}
        >
          <ConfirmModalContent
            data-testid="royalties-payout-tab__confirm-modal"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Label
              color="textSecondary"
              data-testid="royalties-payout-tab__confirm-modal__label"
            >
              Available Rewards
            </Label>
            <Value data-testid="royalties-payout-tab__confirm-modal__value">
              {formatMicroUSDC(info.availableBalance, '$', MicroUSDC.MICRO_DECIMAL)}
            </Value>
          </ConfirmModalContent>
        </ConfirmModal>
      )}
      <InfoWrapper>
        <Grid container spacing={3}>
          <Grid item lg={4} md={12} xs={12}>
            <Label
              variant="h6"
              data-testid="royalties-payout-tab__info__available-label"
            >
              Available rewards
            </Label>
            {loading && (
              <Skeleton
                data-testid="royalties-payout-tab__info__available-skeleton"
                variant="text"
                height={50}
              />
            )}
            {!loading && (
              <Value data-testid="royalties-payout-tab__info__available-value">
                {formatMicroUSDC(info.availableBalance, '$', MicroUSDC.MICRO_DECIMAL)}
              </Value>
            )}
          </Grid>
          <Grid item lg={4} md={12} xs={12}>
            <Label
              variant="h6"
              data-testid="royalties-payout-tab__info__total-label"
            >
              Claimed rewards
            </Label>
            {loading && (
              <Skeleton
                data-testid="royalties-payout-tab__info__total-skeleton"
                variant="text"
                height={50}
              />
            )}
            {!loading && (
              <Value data-testid="royalties-payout-tab__info__total-value">
                {formatMicroUSDC(info.totalEarned, '$', MicroUSDC.MICRO_DECIMAL)}
              </Value>
            )}
          </Grid>
          <Grid item lg={4} md={12} xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {info.usdcAsset.optedIn ? (
                <ClaimButton
                  data-testid="royalties-payout-tab__info__claim-button"
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={claimButtonDisabled || loading}
                  onClick={() => setOpenConfirmModal(true)}
                >
                  {royaltiesContext.processingWithdraw
                    ? 'Claiming rewards'
                    : 'Claim rewards'}
                  {royaltiesContext.processingWithdraw && (
                    <StyledCircularProgress
                      data-testid="royalties-payout-tab__info__claim-button__progress"
                      size={20}
                      color="info"
                    />
                  )}
                </ClaimButton>
              ) : (
                <OptInButton
                  data-testid="royalties-payout-tab__info__optin-button"
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={loading}
                  onClick={onClickOptin}
                >
                  {royaltiesContext.processingOptin ? 'Opting in' : 'Opt-in'}
                  {royaltiesContext.processingOptin && (
                    <StyledCircularProgress
                      data-testid="royalties-payout-tab__info__optin-button__progress"
                      size={20}
                      color="info"
                    />
                  )}
                </OptInButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </InfoWrapper>
      {openPeraWalletModal && (
        <PeraInfoModal
          open={openPeraWalletModal}
          onClose={() => royaltiesActions.setProcessingOptin(false)}
        />
      )}

      <HistoryWrapper data-testid="royalties-payout-tab__history">
        <SubTitle
          variant="subtitle1"
          data-testid="royalties-payout-tab__history__title"
        >
          History
        </SubTitle>
        {isMobile ? (
          <React.Fragment>
            {loading && <StyledMobileSkeleton height={250} />}
            {!loading &&
              history.map((row, index) => (
                <MobileTableRow
                  key={index}
                  data={{
                    amount: formatMicroUSDC(row.amount, '$', MicroUSDC.MICRO_DECIMAL),
                    'claimed on': format(
                      new Date(row.requestedOn),
                      'dd/MM/yyyy, hh:mm a',
                    ),
                  }}
                  action={
                    <StyledLink
                      href={`${config.env.ALGO_EXPLORER_URL}/tx/${row.transactionId}`}
                      target="_blank"
                      data-testid="vesting-legend__history-link"
                    >
                      Open in block explorer
                      <LaunchIcon />
                    </StyledLink>
                  }
                />
              ))}
          </React.Fragment>
        ) : (
          <TableContainer data-testid="royalties-payout-tab__history__table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader data-testid="royalties-payout-tab__history__table__amount-th">
                    Amount
                  </TableHeader>
                  <TableHeader data-testid="royalties-payout-tab__history__table__requestedon-th">
                    Claimed on
                  </TableHeader>
                  <TableHeader />
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  SKELETON_ROWS.map((it, index) => (
                    <TableRow
                      key={`table-item-${index}`}
                      data-testid="royalties-payout-tab__history__table__tr-skeleton"
                    >
                      <StyledCell>
                        <Skeleton variant="text" />
                      </StyledCell>
                      <StyledCell>
                        <Skeleton variant="text" />
                      </StyledCell>
                      <LinkCell>
                        <Skeleton variant="text" />
                      </LinkCell>
                    </TableRow>
                  ))}
                {!loading &&
                  history.map((row, index) => (
                    <TableRow
                      key={index}
                      data-testid="royalties-payout-tab__history__table__tr"
                    >
                      <TruncatedCell data-testid="royalties-payout-tab__history__table__tr__amount-td">
                        {formatMicroUSDC(row.amount, '$', MicroUSDC.MICRO_DECIMAL)}
                      </TruncatedCell>
                      <StyledCell data-testid="royalties-payout-tab__history__table__tr__requestedon-td">
                        {format(
                          new Date(row.requestedOn),
                          'dd/MM/yyyy, hh:mm a',
                        )}
                      </StyledCell>
                      <LinkCell>
                        <StyledLink
                          href={`${process.env.REACT_APP_ALGO_EXPLORER_URL}/tx/${row.transactionId}`}
                          target="_blank"
                          data-testid="royalties-payout-tab__history__table__tr__transaction-link"
                        >
                          Open in block explorer
                          <LaunchIcon />
                        </StyledLink>
                      </LinkCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </HistoryWrapper>
    </PayoutContainer>
  );
}
