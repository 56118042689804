
export enum PurchaseSteps {
  CONFIRMATION = 'CONFIRMATION',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  PURCHASE = 'PURCHASE',
  CONGRATULATION = 'CONGRATULATION',
};

export const Tabs = [
  {
    label: 'About',
    value: 'about',
  },
  // {
  //   label: 'Stats',
  //   value: 'stats',
  // },
  {
    label: 'Updates',
    value: 'updates',
  },
  {
    label: 'FAQ',
    value: 'faq',
  },
  {
    label: 'Details',
    value: 'details',
  },
];

