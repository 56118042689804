import React, { useReducer } from 'react';
import InvestmentContext from './context';
import { useActions } from './actions';
import { InvestmentReducer, InvestmentInitializer } from './reducer';

const InitialState = {
  loading: true,
  loadingAvailability: true,
  processingPayment: false,
};

type Props = {
  children?: React.ReactNode;
};
const InvestmentProvider: React.FC = ({ children }: Props) => {
  const [state, dispatch] = useReducer(
    InvestmentReducer,
    {
      ...InitialState,
    },
    InvestmentInitializer,
  );
  const actions = useActions(dispatch);

  return (
    <InvestmentContext.Provider
      value={{
        state,
        actions,
      }}
    >
      {children}
    </InvestmentContext.Provider>
  );
};

export default InvestmentProvider;
