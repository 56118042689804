import React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const Wrapper = styled(Box)(({ theme }) => ({
  minHeight: `calc(100vh - ${theme.spacing(10)})`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}))

const Description = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0, 4),
}))

interface ErrorMessageProps {
  title: string
  description: string
  ctaText: string
  ctaLink: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  title,
  description,
  ctaText = 'Go to assets page',
  ctaLink = '/assets',
}) => {
  return (
    <Wrapper>
      <Typography variant="h1">{title}</Typography>
      <Description variant="body1">{description}</Description>
      <Button href={ctaLink}>{ctaText}</Button>
    </Wrapper>
  )
}

export default ErrorMessage
