import { ReactElement } from 'react';
import { Paper, Stack, Grid, Box, useTheme } from '@mui/material';
import { styled } from '@mui/system';

import Legend from '@opulous/web/src/components/Vesting/ContractCard/Legend';
import PreClaimed from '@opulous/web/src/components/Vesting/ContractCard/PreClaimed';
import Progress from '@opulous/web/src/components/Vesting/ContractCard/Progress';
import Details from '@opulous/web/src/components/Vesting/ContractCard/Details';
import { ContractStatus } from '@opulous/web/src/shared/types';
import MinorOPUL from '@opulous/web/src/shared/valueObjects/MinorOPUL';

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(3),
}));

const InfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4, 4, 1, 4),
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(3, 3, 1, 3),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 2, 1, 2),
  },
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

const DetailsContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  backgroundColor: theme.palette.grey.A100,
  padding: theme.spacing(4, 4, 1, 4),
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(3, 3, 1, 3),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 2, 1, 2),
  },
}));

const ProgressLegendContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing(1.2),
  marginBottom: theme.spacing(1),
}));
const ProgressLegend = styled(Box)<{ color: string }>(({ color }) => ({
  width: 20,
  height: 20,
  background: color,
  borderRadius: 20,
  marginRight: 1,
}));
const ProgressLegendBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  padding: theme.spacing(0.5, 1.5),
  borderRadius: 20,
}));
const Label = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(0.8),
}));

type Props = {
  address: string;
  status: ContractStatus;
  startAt: Date;
  endAt: Date;
  optedIn: boolean;
  preClaimed?: MinorOPUL;
  totalOpul: MinorOPUL;
  lockedOpul: MinorOPUL;
  claimedOpul: MinorOPUL;
  toClaimOpul: MinorOPUL;
  dailyDistribution: MinorOPUL;
  optInTokens: () => void;
  claimTokens: () => void;
};
export default function VestingContractCard({
  address,
  status,
  startAt,
  endAt,
  optedIn,
  preClaimed,
  totalOpul,
  lockedOpul,
  claimedOpul,
  toClaimOpul,
  dailyDistribution,
  optInTokens,
  claimTokens,
}: Props): ReactElement {
  const theme = useTheme();
  const legendProps = {
    address,
    optedIn,
    fullyClaimed: ContractStatus.claimed === status,
    toClaimOpul,
    optInTokens,
    claimTokens,
  };

  const progressProps = {
    totalOpul,
    claimedOpul,
    toClaimOpul,
    startAt,
    endAt,
  };

  const detailsProps = {
    totalOpul,
    lockedOpul,
    claimedOpul,
    dailyDistribution,
  };

  return (
    <Wrapper data-testid="vesting-contract-card">
      <InfoContainer data-testid="vesting-contract-card__info">
        <Legend {...legendProps} />
        <Grid container>
          {preClaimed?.isGreaterThan(MinorOPUL.ZERO) ? (
            <>
              <Grid
                item
                lg={2}
                xs={12}
                data-testid="vesting-contract-card__pre-claimed"
              >
                <PreClaimed amount={preClaimed || MinorOPUL.ZERO} />
              </Grid>
              <Grid
                item
                lg={10}
                xs={12}
                data-testid="vesting-contract-card__progress"
              >
                <Progress {...progressProps} />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} data-testid="vesting-contract-card__progress">
              <Progress {...progressProps} />
            </Grid>
          )}
        </Grid>
        <ProgressLegendContainer>
          <ProgressLegendBox
            data-testid="vesting-contract-card__progress-legend__claimed"
            sx={{ display: 'inline-flex', alignItems: 'center' }}
          >
            <ProgressLegend color={theme.palette.primary.dark} />
            <Label>Claimed</Label>
          </ProgressLegendBox>
          <ProgressLegendBox
            data-testid="vesting-contract-card__progress-legend__available"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              marginLeft: '1rem',
            }}
          >
            <ProgressLegend color={theme.palette.primary.main} />
            <Label>Available to claim</Label>
          </ProgressLegendBox>
        </ProgressLegendContainer>
      </InfoContainer>
      <DetailsContainer data-testid="vesting-contract-card__details">
        <Details {...detailsProps} />
      </DetailsContainer>
    </Wrapper>
  );
}
