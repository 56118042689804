import React, { useMemo } from 'react'
import { styled } from '@mui/system'
import { Tooltip, useTheme } from '@mui/material'

const CircularStackedBarChart = styled(({
  className,
  data,
  basePercentage = 75,
}: {
  className?: string
  data: Array<{
    percent: number
    color: string
    tooltip?: string
  }>
  basePercentage?: number // The degree of the arc of the circular chart
  strokeWidth?: number
}) => {
  const theme = useTheme();
  const tooltipStyles = useMemo(() => ({
    lineHeight: '1.1rem',
    padding: theme.spacing(1, 2),
    maxWidth: '12rem',
  }), [theme]);

  return (
    <svg viewBox="0 0 36 36" className={className}>
      <path
        className="base-path"
        strokeDasharray={`${basePercentage}, 100`}
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        shapeRendering="geometricPrecision"
      />
      {data.map((x, idx) => !!x.percent && (
        <Tooltip
          key={`${idx},${x.percent}`}
          title={x.tooltip || ''}
          placement="top"
          followCursor
          arrow
          componentsProps={{ tooltip: { style: tooltipStyles } }}
        >
          <path
            strokeDasharray={`${(x.percent / 100 * basePercentage).toFixed(2)}, 100`}
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            shapeRendering="geometricPrecision"
            style={{ stroke: x.color }}
          />
        </Tooltip>
      ))}
    </svg>
  )
})(({ theme, strokeWidth = 2, basePercentage = 75 }) => {
  const remainingAngleDeg = (100 - basePercentage) / 100 * 360 / 2;
  const remainingAngleRadian = remainingAngleDeg * Math.PI / 180;
  const radius = 18; // Since the SVG view box is 36x36

  return {
    width: '100%',
    height: '100%',
    transform:  `rotate(${(-90 - remainingAngleDeg).toFixed(2)}deg)`,
    transformOrigin: 'center',
    marginBottom: -Math.sin(remainingAngleRadian) * radius,

    '& > path': {
      fill: 'none',
      strokeWidth,
      strokeLinecap: 'round',
    },

    '& > path.base-path': {
      stroke: theme.palette.grey[100],
    },
  }
})

export default CircularStackedBarChart
