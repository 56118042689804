import { ReactElement, useState } from 'react';
import { styled } from '@mui/system';
import StyledAlert from '@opulous/web/src/components/Common/StyledAlert';
import { useTheme } from '@mui/material';

const Close = styled('span')(() => ({
  position: 'absolute',
  top: '0.6rem',
  right: '1rem',
  fontSize: '1.5em',
  cursor: 'pointer',
}));

function SupportEmailMessage() {
  return (
    <>
      {' '}
      Send us an email at{' '}
      <a href="mailto:support@opulous.org">support@opulous.org</a> for more
      information.
    </>
  );
}

type Props = {
  message: string;
  onClose?: () => void;
  showSupportEmailMessage?: boolean;
};
export default function AlertError({
  message,
  onClose,
  showSupportEmailMessage = true,
}: Props): ReactElement | null {
  const theme = useTheme();
  const [closed, setClosed] = useState(false);

  if (closed) {
    return null;
  }

  return (
    <StyledAlert
      dataTestId="alert-error"
      padding="14px 11px"
      fontSizeIcon="16px"
      colorIcon={theme.palette.error.dark}
      colorAlert={theme.palette.error.dark}
      severity="error"
      borderRadius="20px"
      backgroundColor={theme.palette.error.light}
    >
      {message}
      {message[message.length - 1] === '\n' ? '' : '.'}
      {showSupportEmailMessage && <SupportEmailMessage />}
      <Close
        onClick={() => {
          if (onClose) onClose();
          setClosed(true);
        }}
      >
        &times;
      </Close>
    </StyledAlert>
  );
}
