import {
  TableCell,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  padding: theme.spacing(1, 0, 2),
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'nowrap',
  padding: theme.spacing(1, 0),
  // paddingRight: 0,
  color: theme.palette.grey[900],
  fontSize: '0.875rem',
  border: 0,
  [theme.breakpoints.down('md')]: {
    fontSize: '0.75rem',
    '&:first-of-type': {
      paddingRight: 0,
    },
    '&:last-child': {
      paddingLeft: 0,
    },
  }
}));

export const TruncatedTableCell = styled(StyledTableCell)(({ theme }) => ({
  textOverflow: 'ellipsis',
  maxWidth: theme.spacing(21),
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    maxWidth: 'auto',
  },
}));

export const ProgressCell = styled(StyledTableCell)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));