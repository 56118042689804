import axios, { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import {
  APIResponse,
  Asset,
  AssetContractMetadata,
  AssetMFT,
  ICreateAsset,
} from '@opulous/web/src/shared/types';
import { getAdminAuthorizationHeader } from '../admin';

interface CancellableRequest<T> {
  promise: Promise<T>;
  cancel: () => void;
}

export const fetchAllAvailableAssetMFTs = async (): Promise<AssetMFT[]> => {
  try {
    const axiosResponse = await axios.get<APIResponse<AssetMFT[]>>(
      `/api/assets/available-mfts`,
    );
    const responseData = axiosResponse.data.data;
    return responseData;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const fetchAssetMetaData = async (unitName: string): Promise<Asset> => {
  try {
    const axiosResponse = await axios.get<APIResponse<Asset>>(
      `/api/assets/metadata/${unitName}`,
    );
    const responseData = axiosResponse.data.data;
    return responseData;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const fetchAllAssetsClaimedAndOptedIn = async (
  wallet: string,
): Promise<AssetContractMetadata[]> => {
  try {
    const axiosResponse = await axios.get<APIResponse<AssetContractMetadata[]>>(
      `/api/assets/${wallet}/`,
    );
    const responseData = axiosResponse.data.data;
    return responseData.map(it => ({
      ...it,
      nftCount: Math.round(it.nftCount / Math.pow(10, it.decimals || 0)),
    }));
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const fetchAllAssetsAvailableToClaim = async (
  wallet: string,
): Promise<AssetContractMetadata[]> => {
  try {
    const axiosResponse = await axios.get<APIResponse<AssetContractMetadata[]>>(
      `/api/assets/optin/${wallet}`,
    );
    const responseData = axiosResponse.data.data;
    return responseData;
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

type ClaimAssetsProps = {
  assets: number[];
  wallet: string;
};
type ClaimAssetResult = {
  txId: string;
  transaction: {
    error: string;
    status: number;
  };
};

type ClaimAssetResponse = ClaimAssetResult[];
export const claimAssets = async ({
  assets,
  wallet,
}: ClaimAssetsProps): Promise<ClaimAssetResponse> => {
  try {
    const axiosResponse = await axios.post<AxiosResponse<ClaimAssetResponse>>(
      `/api/assets/claim/${wallet}`,
      { assetIds: assets },
    );
    const responseData = axiosResponse.data.data;
    return responseData;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export function adminGetAssetsCancellable(
  page: number,
  pageSize: number,
  searchText?: string,
): CancellableRequest<{ data: Asset[]; count: number }> {
  const controller = new AbortController();
  const p = (async () => {
    const {
      data: { data },
    } = await axios({
      method: 'GET',
      url: `/api/admin/assets?${new URLSearchParams({
        page: `${page}`,
        pageSize: `${pageSize}`,
        ...(searchText?.trim() ? { searchText: searchText.trim() } : null),
      }).toString()}`,
      headers: await getAdminAuthorizationHeader(),
      signal: controller.signal,
    });
    return data;
  })();

  return {
    promise: p,
    cancel: () => controller.abort(),
  };
}

export async function adminGetAssets(
  page: number,
  pageSize: number,
  searchText?: string,
): Promise<{ data: Asset[]; count: number }> {
  const {
    data: { data },
  } = await axios({
    method: 'GET',
    url: `/api/admin/assets?${new URLSearchParams({
      page: `${page}`,
      pageSize: `${pageSize}`,
      ...(searchText?.trim() ? { searchText: searchText.trim() } : null),
    }).toString()}`,
    headers: await getAdminAuthorizationHeader(),
  });
  return data;
}

export async function adminGetAsset(unitName: string): Promise<Asset> {
  const {
    data: { data },
  } = await axios({
    method: 'GET',
    url: `/api/admin/assets/${encodeURIComponent(unitName)}`,
    headers: await getAdminAuthorizationHeader(),
  });
  return data;
}

export async function adminCreateAsset(asset: ICreateAsset): Promise<Asset> {
  const {
    data: { data },
  } = await axios({
    method: 'POST',
    url: '/api/admin/assets',
    headers: await getAdminAuthorizationHeader(),
    data: asset,
  });
  return data;
}

export async function adminEditAsset(
  unitName: string,
  asset: Partial<ICreateAsset>,
): Promise<Asset> {
  const {
    data: { data },
  } = await axios({
    method: 'PATCH',
    url: `/api/admin/assets/${encodeURIComponent(unitName)}`,
    headers: await getAdminAuthorizationHeader(),
    data: asset,
  });
  return data;
}

export async function adminDeleteAsset(unitName: string): Promise<void> {
  await axios({
    method: 'DELETE',
    url: `/api/admin/assets/${encodeURIComponent(unitName)}`,
    headers: await getAdminAuthorizationHeader(),
  });
}

export default {
  fetchAssetMetaData,
  fetchAllAssetsClaimedAndOptedIn,
  fetchAllAssetsAvailableToClaim,
  claimAssets,
  adminGetAssets,
  adminGetAsset,
  adminCreateAsset,
  adminEditAsset,
  adminDeleteAsset,
};
