import React from 'react';
import { styled } from '@mui/system';
import Icon from '@mui/material/Icon';
import Logo from '@opulous/web/src/assets/Logo_mobile.svg';

interface OpulIconProps {
  fontSize?: number;
}

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
});

const OpulIcon: React.FC<OpulIconProps> = ({ fontSize = 16 }) => (
  <Icon sx={{ fontSize }}>
    <StyledImage alt="Opul icon" src={Logo} />
  </Icon>
);

export default OpulIcon;
