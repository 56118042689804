import {
  ReactElement,
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  ChangeEvent,
} from 'react';
import { styled } from '@mui/system';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  CircularProgress,
  Fade,
  Grid,
  TextField,
  Typography,
  Link,
  Checkbox,
  IconButton,
  Button,
  InputAdornment,
  useTheme,
  Skeleton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  PageTitle,
  StyledButton,
  InvestmentDetailsCard,
} from '@opulous/web/src/pages/Sales/Investment/styled-components';
import SmartCalculatorForm from '@opulous/web/src/components/Sales/RewardsShareCalculator/SmartCalculatorForm';
import { email as emailValidation } from '@opulous/web/src/validations/common';
import InvestmentContext from '@opulous/web/src/pages/Sales/Investment/context';
import { prepareCalculatorProperties } from '@opulous/web/src/utils';

const Content = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: theme.spacing(62),
}));
const StyledPageTitle = styled(PageTitle)(({ theme }) => ({
  marginTop: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
}));
const Step = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(6),
  },
}));
const StepTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 'bold',
  lineHeight: theme.spacing(3),
}));
const StepDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2.2),
  lineHeight: theme.spacing(2.5),
}));
const CalculatorWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const StyledForm = styled('form')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));
const StyledInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.spacing(1.5),
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  marginLeft: '1rem',
  svg: {
    color: theme.palette.primary.contrastText,
  },
}));

const InputContainer = styled('div')(() => ({}));
const InputCodeContainer = styled('div')(({ theme }) => ({
  marginTop: `-${theme.spacing(3)}`,
}));
const ResendButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[500],
  borderColor: theme.palette.grey[500],
  padding: theme.spacing(0.5, 2),

  '&:not(.Mui-disabled):hover': {
    color: theme.palette.grey[700],
    borderColor: theme.palette.grey[700],
  },

  '&.Mui-disabled': {
    color: theme.palette.grey[200],
    borderColor: theme.palette.grey[200],
  },
}));
const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  marginLeft: `-${theme.spacing(1.5)}`,
}));
const TermsAndConditions = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}));

const ActionButton = styled(StyledButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    maxWidth: '100%',
  },
}));
const CalculatorSkeleton = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: theme.spacing(4),
  span: {
    transform: 'scale(1, 1)',
    borderRadius: theme.spacing(2),
  },
}));

export default function InvestmentEmailConfirmationPage(): ReactElement {
  const { state: investmentState, actions: investmentActions } =
    useContext(InvestmentContext);
  const [sendingConfirmationCode, setSendingConfirmationCode] = useState(false);
  const [confirmationCodeInvalid, setConfirmationCodeInvalid] = useState(false);
  const [confirmationRequested, setConfirmationRequested] = useState(false);
  const emailStep2InputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { loadingAvailability, investment, investmentAvailability } =
    investmentState;

  if (!investment) {
    return <></>;
  }
  const calculatorProperties = useMemo(() => {
    return prepareCalculatorProperties(investment, investmentAvailability);
  }, [loadingAvailability]);

  const formConfirmation = useFormik({
    initialValues: {
      purchaseAmount: '',
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: emailValidation,
      purchaseAmount: Yup.number()
        .required()
        .min(calculatorProperties.minInvest)
        .max(calculatorProperties.allowedMaxInvest),
    }),
    onSubmit: async values => {
      try {
        setSendingConfirmationCode(true);
        await investmentActions.sendConfirmationCode(values);
        setConfirmationRequested(true);
      } finally {
        setSendingConfirmationCode(false);
      }
    },
  });

  const resendConfirmationCode = useCallback(async () => {
    try {
      setSendingConfirmationCode(true);
      await investmentActions.sendConfirmationCode({
        email: formConfirmation.values.email,
      });
      if (emailStep2InputRef?.current) {
        emailStep2InputRef.current.focus();
      }
    } finally {
      setSendingConfirmationCode(false);
    }
  }, [formConfirmation.values.email]);

  const formPurchase = useFormik({
    initialValues: {
      code: '',
      termsAndConditions: false,
    },
    validationSchema: Yup.object().shape({
      code: Yup.number().required(),
      termsAndConditions: Yup.bool().oneOf(
        [true],
        'Terms and Conditions must be checked',
      ),
    }),
    onSubmit: async values => {
      try {
        setConfirmationCodeInvalid(false);
        await investmentActions.verifyConfirmationCode({
          email: formConfirmation.values.email,
          purchaseAmount: Number(formConfirmation.values.purchaseAmount),
          code: values.code,
        });
      } catch (error) {
        setConfirmationCodeInvalid(true);
      }
    },
  });

  const resetConfirmationForm = useCallback(async () => {
    setSendingConfirmationCode(false);
    setConfirmationRequested(false);
    formConfirmation.resetForm();
  }, []);

  const Step1 = useMemo(() => {
    if (investment) {
      return (
        <Step>
          <StepTitle data-testid="investment-email-confirmation-page__step1__title">
            1. Insert your purchase amount
          </StepTitle>
          <StepDescription data-testid="investment-email-confirmation-page__step1__description">
            All MFTs are initially priced at $1.
            <br />
            If you purchase $1000 you get will 1000 MFTs.
          </StepDescription>
          <CalculatorWrapper data-testid="investment-email-confirmation-page__step1__calculator">
            {loadingAvailability ? (
              <CalculatorSkeleton data-testid="investment-email-confirmation-page__step1__calculator__skeleton">
                <Skeleton variant="text" width="100%" height={80} />
                <Skeleton variant="text" width="100%" height={80} />
              </CalculatorSkeleton>
            ) : (
              <SmartCalculatorForm
                autoFocus={!formConfirmation.values.purchaseAmount}
                calculatorProps={calculatorProperties}
                name="purchaseAmount"
                value={formConfirmation.values.purchaseAmount}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  formConfirmation.setFieldValue(
                    'purchaseAmount',
                    Number(`${e.target.value || 0}`.replace(',', '')),
                  );
                }}
              />
            )}
          </CalculatorWrapper>
        </Step>
      );
    }
    return;
  }, [loadingAvailability, investment, confirmationRequested]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    investmentActions.loadInvestmentAvailability(
      investment.id,
      investmentState.walletAddress,
    );
  }, []);

  return (
    <Fade in data-testid="investment-email-confirmation-page">
      <Grid container spacing={6}>
        <Grid item xl={6} xs={12}>
          <Content>
            <StyledPageTitle data-testid="investment-email-confirmation-page__title">
              Complete your purchase
            </StyledPageTitle>
            {!confirmationRequested && (
              <StyledForm onSubmit={formConfirmation.handleSubmit} noValidate>
                {Step1}
                <Step>
                  <StepTitle data-testid="investment-email-confirmation-page__step2__title">
                    2. Confirm your email
                  </StepTitle>
                  <InputContainer>
                    <StepDescription data-testid="investment-email-confirmation-page__step2__description">
                      Enter your email to receive a confirmation code.
                    </StepDescription>
                    <StyledInput
                      autoComplete="off"
                      data-testid="investment-email-confirmation-page__step2__email-input"
                      autoFocus={!!formConfirmation.values.purchaseAmount}
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      variant="outlined"
                      required
                      value={formConfirmation.values.email}
                      onChange={formConfirmation.handleChange}
                      disabled={confirmationRequested}
                    />
                    <ActionButton
                      data-testid="investment-email-confirmation-page__step2__send-code-button"
                      type="submit"
                      disabled={
                        !formConfirmation.isValid || !formConfirmation.dirty
                      }
                    >
                      {formConfirmation.isSubmitting
                        ? 'Sending Code'
                        : 'Send Code'}
                      {formConfirmation.isSubmitting && (
                        <StyledCircularProgress
                          data-testid="investment-email-confirmation-page__step2__send-code-button__progress"
                          size={20}
                          color="info"
                        />
                      )}
                    </ActionButton>
                  </InputContainer>
                </Step>
              </StyledForm>
            )}
            {confirmationRequested && (
              <StyledForm onSubmit={formPurchase.handleSubmit} noValidate>
                {Step1}
                <Step>
                  <StepTitle data-testid="investment-email-confirmation-page__step2__title">
                    2. Confirm your email
                  </StepTitle>
                  <InputContainer>
                    <StepDescription data-testid="investment-email-confirmation-page__step2__description">
                      Enter your email to receive a confirmation code.
                    </StepDescription>
                    <StyledInput
                      autoComplete="off"
                      data-testid="investment-email-confirmation-page__step2__email-input"
                      type="email"
                      name="email"
                      variant="outlined"
                      required
                      value={formConfirmation.values.email}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            size="small"
                            data-testid="investment-email-confirmation-page__step2__email-input__reset"
                            onClick={() => resetConfirmationForm()}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        ),
                      }}
                    />
                  </InputContainer>
                  <InputCodeContainer>
                    <StyledInput
                      autoComplete="off"
                      inputRef={emailStep2InputRef}
                      autoFocus
                      data-testid="investment-email-confirmation-page__step2__confirmation-code-input"
                      type="text"
                      name="code"
                      placeholder="Confirmation code"
                      variant="outlined"
                      required
                      value={formPurchase.values.code}
                      onChange={formPurchase.handleChange}
                      error={confirmationCodeInvalid}
                      helperText={
                        confirmationCodeInvalid
                          ? 'The confirmation code is not valid.'
                          : ''
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <ResendButton
                              data-testid="investment-email-confirmation-page__step2__confirmation-code-input__resend-code"
                              disabled={sendingConfirmationCode}
                              variant="outlined"
                              onClick={resendConfirmationCode}
                            >
                              {sendingConfirmationCode
                                ? 'Resending...'
                                : 'Resend'}
                            </ResendButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <StepDescription data-testid="investment-email-confirmation-page__step2__confirmation-code__description">
                      Check your email address, we’ve just sent the verification
                      code.
                    </StepDescription>
                  </InputCodeContainer>
                  <TermsAndConditions data-testid="investment-email-confirmation-page__step2__terms_conditions">
                    <StyledCheckbox
                      data-testid="investment-email-confirmation-page__step2__terms_conditions__checkbox"
                      checked={formPurchase.values.termsAndConditions}
                      onChange={e =>
                        formPurchase.setFieldValue(
                          'termsAndConditions',
                          e.target.checked,
                        )
                      }
                    />
                    I confirm the &nbsp;
                    <Link href="https://opulous.org/" target="_blank">
                      Terms & Conditions
                    </Link>
                  </TermsAndConditions>
                  <ActionButton
                    type="submit"
                    data-testid="investment-email-confirmation-page__step2__purchase-button"
                    disabled={
                      sendingConfirmationCode ||
                      !formPurchase.isValid ||
                      !formPurchase.dirty ||
                      !formConfirmation.isValid
                    }
                  >
                    {formPurchase.isSubmitting ? 'Confirming' : 'Confirm'}
                    {formPurchase.isSubmitting && (
                      <StyledCircularProgress
                        data-testid="investment-email-confirmation-page__step2__purchase-button__progress"
                        size={20}
                        color="info"
                      />
                    )}
                  </ActionButton>
                </Step>
              </StyledForm>
            )}
          </Content>
        </Grid>
        {!isMobile && (
          <Grid item xl={6} xs={12} display={'flex'} justifyContent={'center'}>
            {investment && <InvestmentDetailsCard investment={investment} />}
          </Grid>
        )}
      </Grid>
    </Fade>
  );
}
