import React, { ReactElement } from 'react';
import { Box, TableCell, Skeleton, TableContainer, Pagination } from '@mui/material';
import { styled } from '@mui/system';

export const AssetImage = styled('img')(({ theme, width }) => ({
  width: width || theme.spacing(4),
  height: 'auto',
  borderRadius: theme.spacing(0.5),
  marginRight: theme.spacing(1),
}));

export const TableHeader = styled(TableCell)(({ theme }) => ({
  color: theme.palette.grey[500],
  padding: theme.spacing(1.5, 3),
  fontSize: '0.875rem',
  fontWeight: 600,
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'nowrap',
  padding: theme.spacing(1.5, 3),
  color: theme.palette.grey[900],
  fontSize: '0.875rem',
}));

export const TruncatedTableCell = styled(StyledTableCell)(({ theme }) => ({
  textOverflow: 'ellipsis',
  maxWidth: theme.spacing(21),
  overflow: 'hidden',
}));

export const TruncatedBox = styled(Box)(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const StyledMobileSkeleton = styled(Skeleton)(({ theme }) => ({
  margin: theme.spacing(-5, 2, 0, 2),
}));

const MobilePaginationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(3, 1),
  'ul > li > button': {
    padding: theme.spacing(1, 1),
  }
}));

type Props = {
  count: number,
  page: number,
  rowsPerPage: number,
  handleChangePage: (event: React.ChangeEvent<unknown>, page: number) => void,
}
export function MobilePagination({ count, page, rowsPerPage, handleChangePage}: Props): ReactElement | null {
  return (
    <MobilePaginationContainer>
      <Pagination
        siblingCount={1}
        count={Math.trunc(count / rowsPerPage)}
        page={page + 1} /* FIRST PAGE FOR BACKEND IS 0 */
        onChange={(e, newPage) => handleChangePage(e, newPage - 1)} /* FIRST PAGE FOR BACKEND IS 0 */
      />
    </MobilePaginationContainer>
  );
}
