import { ReactElement, ReactNode } from 'react'
import {
  Typography,
  Dialog as MuiDialog,
  Box,
} from '@mui/material'
import { styled, Breakpoint } from '@mui/system'

const StyledDialog = styled(MuiDialog)(({ theme, maxWidth }) => ({
  '& .MuiPaper-root': {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(2),
    minWidth: 400,
    maxWidth: maxWidth ? theme.breakpoints.values[maxWidth] : 'inherit',
  },
}))

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 3),
}))

const Title = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '1.4rem',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '.75rem',
  lineHeight: '1rem',
  color: theme.palette.grey[700],
  marginTop: theme.spacing(1),
}));

export default function Dialog({
  title,
  subTitle,
  children,
  onClose,
  maxWidth,
}: {
  title?: string
  subTitle?: string
  children?: ReactNode
  onClose?: () => void
  maxWidth?: Breakpoint
}): ReactElement {
  const handleClose = () => onClose?.();

  return (
    <StyledDialog open onClose={handleClose} maxWidth={maxWidth}>
      <Container>
        {title && (<Title>{title}</Title>)}
        {subTitle && (<SubTitle>{subTitle}</SubTitle>)}
        {children}
      </Container>
    </StyledDialog>
  )
}
