import React, { useReducer, useContext } from 'react';
import WalletContext from '@opulous/web/src/context/context';
import SalesContext from './context';
import { SalesReducer, SalesInitializer } from './reducer';
import { useActions } from './actions';

const InitialState = {
  loading: true,
  loadingMore: false,
  hasMore: false,
  filterAndSortSelected: {},
  allInvestments: [],
};

type Props = {
  children?: React.ReactNode;
};
const SalesProvider: React.FC = ({ children }: Props) => {
  const { state: walletState } = useContext(WalletContext);

  const [state, dispatch] = useReducer(
    SalesReducer,
    {
      ...InitialState,
      walletState,
      walletAddress: walletState.wallet,
    },
    SalesInitializer,
  );
  const actions = useActions(dispatch);

  return (
    <SalesContext.Provider
      value={{
        state,
        actions,
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};

export default SalesProvider;
