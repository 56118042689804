import axios from 'axios';

const filterData = (audioBuffer: any) => {
  const rawData = audioBuffer.getChannelData(0);
  const total = Math.trunc(audioBuffer.duration * 5);
  const blockSize = Math.floor(rawData.length / total);
  return new Array(total)
    .fill(0)
    .map((_, i) =>
      rawData
        .slice(i * blockSize, (i + 1) * blockSize)
        .reduce((sum: any, val: any) => sum + Math.abs(val), 0),
    );
};

const normalizeData = (filteredData: any) => {
  const multiplier = Math.max(...filteredData) ** -1;
  return filteredData.map((val: any) => val * multiplier);
};

export async function loadAudio(audioUrl: string, callback: (trackMap: number[], audio: any) => void) {
  const response = await axios.get(audioUrl, { responseType: 'arraybuffer' });
  const arrayBuffer = response.data;
  const newUrl = URL.createObjectURL(
    new Blob([arrayBuffer], { type: response.headers['content-type'] }),
  );
  const newAudio = new Audio(newUrl) as any;
  newAudio.addEventListener('loadeddata', () => {
    (async () => {
      window.AudioContext = window.AudioContext || (window as any).webkitAudioContext;
      const audioContext = new AudioContext();
      
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      const data = normalizeData(filterData(audioBuffer));
      const newTrackMap = data.map((n: any) => Math.trunc(n * 100));

      callback(newTrackMap, newAudio);
    })();
  });
  newAudio.load();
}