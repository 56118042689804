import { ReactElement, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { intervalToDuration, isAfter, differenceInDays } from 'date-fns';
import {
  InvestmentDetails,
  InvestmentStatus,
} from '@opulous/web/src/shared/types';

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));
const Label = styled('label')(({ theme }) => ({
  fontSize: theme.spacing(2.2),
  fontWeight: 'bold',
}));
const LabelFinished = styled('label')<{ soldOut?: boolean }>(
  ({ soldOut, theme }) => ({
    fontSize: theme.spacing(2.2),
    fontWeight: 'bold',
    padding: theme.spacing(1.6),
    textAlign: 'center',
    borderRadius: theme.spacing(4),
    ...(soldOut ? { color: theme.palette.error.contrastText } : {}),
    background: soldOut ? theme.palette.error.main : theme.palette.grey['A100'],
  }),
);
const CounterLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.grey['A100'],
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1),
  '& > span:nth-of-type(1)': {
    fontSize: theme.spacing(4),
    fontWeight: 'bold',
    paddingTop: theme.spacing(0.6),
  },
  '& > span:nth-of-type(2)': {
    fontSize: theme.spacing(1.5),
    fontWeight: 'normal',
  },
}));

type Props = {
  investment: InvestmentDetails;
  children?: any;
};
let counterInterval: any;
export default function InvestmentInfoCounter({
  investment,
  children,
}: Props): ReactElement {
  const [duration, setDuration] = useState<any>();
  const [counterOption, setCounterOption] = useState<{
    title: string;
    finished?: boolean;
    soldOut?: boolean;
  }>();

  useEffect(() => {
    if (investment.status === InvestmentStatus.COMPLETED) {
      setCounterOption({
        finished: true,
        title: 'Sale is finished!',
      });
    } else if (investment.soldOut) {
      setCounterOption({
        finished: true,
        soldOut: true,
        title: 'Sold Out',
      });
    } else {
      let endDate = new Date();
      if (investment.status === InvestmentStatus.UPCOMING) {
        if (investment.preSaleStartDate) {
          setCounterOption({
            title: 'Pre-sale starts in',
          });
          endDate = investment.preSaleStartDate;
        } else {
          setCounterOption({
            title: 'Sale starts in',
          });
          endDate = investment.saleStartDate;
        }
      } else if (investment.status === InvestmentStatus.PRE_SALE) {
        if (investment.isInWhiteList) {
          setCounterOption({
            title: 'Pre-sale ends in',
          });
        } else {
          setCounterOption({
            title: 'Sale starts in',
          });
        }
        endDate = investment.saleStartDate;
      } else if (investment.status === InvestmentStatus.ACTIVE) {
        endDate = investment.saleEndDate;
        setCounterOption({
          title: 'Sale finishes in',
        });
      }

      if (counterInterval) {
        clearInterval(counterInterval);
      }
      counterInterval = setInterval(() => {
        const now = new Date();
        if (isAfter(now, endDate) && counterInterval) {
          clearInterval(counterInterval);
        }
        const newDuration = intervalToDuration({
          start: now,
          end: endDate,
        });
        if (newDuration.months) {
          newDuration.days = differenceInDays(endDate, now);
        }
        setDuration(newDuration);
      }, 1000);
    }
  }, [investment]);

  useEffect(() => {
    return () => {
      if (counterInterval) {
        clearInterval(counterInterval);
      }
    };
  }, []);

  return (
    <Wrapper id="investment-info-counter" data-testid="investment-info-counter">
      {counterOption?.finished ? (
        <LabelFinished
          soldOut={counterOption?.soldOut}
          data-testid="investment-info-counter__label-finished"
        >
          {counterOption?.title}
        </LabelFinished>
      ) : (
        <Label data-testid="investment-info-counter__status">
          {counterOption?.title}
        </Label>
      )}
      {!counterOption?.finished && (
        <>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <CounterLabel data-testid="investment-info-counter__labels__days">
                <span>{`${Math.max(duration?.days || 0, 0)}`.padStart(2, '0')}</span>
                <span>days</span>
              </CounterLabel>
            </Grid>
            <Grid item xs={3}>
              <CounterLabel data-testid="investment-info-counter__labels__hours">
                <span>
                  {`${Math.max(duration?.hours || 0, 0)}`.padStart(2, '0')}
                </span>
                <span>hours</span>
              </CounterLabel>
            </Grid>
            <Grid item xs={3}>
              <CounterLabel data-testid="investment-info-counter__labels__minutes">
                <span>{`${duration?.minutes || 0}`.padStart(2, '0')}</span>
                <span>minutes</span>
              </CounterLabel>
            </Grid>
            <Grid item xs={3}>
              <CounterLabel data-testid="investment-info-counter__labels__seconds">
                <span>{`${duration?.seconds || 0}`.padStart(2, '0')}</span>
                <span>seconds</span>
              </CounterLabel>
            </Grid>
          </Grid>
        </>
      )}
      {children}
    </Wrapper>
  );
}
