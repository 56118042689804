import React, { ChangeEvent, ReactElement } from 'react';

import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import _SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';

const SearchIcon = styled(_SearchIcon)(({ theme }) => ({
  fill: theme.palette.grey[600],
}));

const PageHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(4.25),
  paddingBottom: theme.spacing(1.75),
  flexWrap: 'wrap',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
}));

const Description = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const SearchInputBase = styled(InputBase)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(0.5, 1.5),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

interface IPageHeader {
  title: string;
  searchBar?: boolean;
  description?: string;
  searchTerm?: string;
  setSearchTerm?: (value: string) => void;
}

const PageHeader: React.FC<IPageHeader> = ({
  title,
  searchBar,
  description,
  searchTerm,
  setSearchTerm,
  children,
}): ReactElement => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (setSearchTerm) {
      setSearchTerm(e.target.value);
    }
  };

  return (
    <PageHeaderWrapper data-testid="page-header">
      <Box>
        <Title variant="h4" data-testid="page-header__title">
          {title}
        </Title>
        {description && (
          <Description data-testid="page-header__description">
            {description}
          </Description>
        )}
      </Box>

      {searchBar && (
        <SearchInputBase
          placeholder="Search"
          value={searchTerm}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
      )}
      {children}
    </PageHeaderWrapper>
  );
};

export default PageHeader;
