import { secondsToDays } from "src/utils"

export const getValidMaxLockup = (maxLockup: number, endTimestamp: string):number => {
  const maxLockupDays = secondsToDays(maxLockup);
  const nowTs = Date.now()
  const poolEndTs = parseInt(endTimestamp, 10) * 1000
  const availableMaxLockup = Math.floor((poolEndTs - nowTs) / 1000 / 3600 / 24)

  const validMaxLockup = Math.min(maxLockupDays, availableMaxLockup)

  return validMaxLockup;
}
