import { ReactElement, useState, useCallback, useMemo } from 'react';
import { isBefore, intervalToDuration, differenceInDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/system';
import { LinearProgress, Button } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Investment, InvestmentStatus } from '@opulous/web/src/shared/types';
import { formatCurrency, formatNumber } from '@opulous/web/src/utils/index';
import InvestimentStepper from '@opulous/web/src/components/Sales/InvestmentStepper';
import useMediaQuery from '@mui/material/useMediaQuery';
import InvestmentStatusChip from '@opulous/web/src/components/Sales/InvestmentStatus';
import {
  Artist,
  DetailsContainer,
  StyledCard,
  StyledImage,
  StyledMedia,
  Title,
} from './styled-components';

const StyledInvestmentStatusChip = styled(InvestmentStatusChip)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(5),
  left: theme.spacing(5),
}));

const State = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: theme.spacing(-7, 2, 2, 2),
  padding: theme.spacing(0.8, 2, 0.8, 2),
  borderRadius: theme.spacing(1),
  fontSize: theme.spacing(1.8),
  backgroundColor: theme.palette.common.white,
}));

const CollapseButton = styled(Button)<{ collapsed?: boolean }>(
  ({ collapsed, theme }) => ({
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    minWidth: theme.spacing(2),
    padding: theme.spacing(0.3),
    borderRadius: '50%',
    color: theme.palette.grey[500],
    transform: collapsed ? 'rotate(90deg)' : 'none',
    transition: 'transform 1s',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  }),
);

const InfoList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.spacing(1.6),
  marginTop: theme.spacing(1),
}));
const InfoRaised = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0.8, 0),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));
const InfoRaisedLabels = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 0),
  'span:nth-of-type(2)': {
    fontWeight: 'bold',
  },
}));
const InfoStepper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.spacing(1),
}));
const Info = styled('div')<{ last?: boolean }>(({ last, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 0),
  borderBottom: last ? 'none' : `1px solid ${theme.palette.grey[300]}`,
  'span:nth-of-type(2)': {
    fontWeight: 'bold',
  },
}));
const LinearProgressContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0, 0.8, 0),
}));
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: theme.spacing(1),
  borderRadius: theme.spacing(1),
  background: alpha(theme.palette.primary.main, 0.2),
  '& .MuiLinearProgress-bar': {
    background: theme.palette.primary.main,
  },
}));

type Props = {
  investment: Investment;
};
export default function InvestmentCard({ investment }: Props): ReactElement {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const isTablet = useMediaQuery('(max-width: 960px)');
  const now = new Date();
  const state = {
    upcoming: isBefore(now, investment.preSaleStartDate || investment.saleStartDate),
    preSale: investment.preSaleStartDate ? isBefore(now, investment.saleStartDate) : false,
    sale: isBefore(now, investment.saleEndDate),
  };

  const IntervalLegend = useMemo(() => {
    const baseDate = state.upcoming
      ? (investment.preSaleStartDate || investment.saleStartDate)
      : state.preSale
      ? investment.saleStartDate
      : investment.saleEndDate;

    const interval = intervalToDuration({
      start: new Date(),
      end: baseDate,
    });

    if (interval.days) {
      if (interval.months) {
        interval.days = differenceInDays(baseDate, new Date());
      }
      if (interval.days > 1) {
        return `${interval.days} days`;
      }
      return `${interval.days} day`;
    }
    if (interval.hours) {
      if (interval.hours > 1) {
        return `${interval.hours} hours`;
      }
      return `${interval.hours} hour`;
    }

    if ((interval.minutes || 0) > 1) {
      return `${interval.minutes} minutes`;
    }
    return `${interval.minutes} minute`;
  }, [state]);

  const navigateToDetailsPage = useCallback(() => {
    navigate(`/mfts/${encodeURIComponent(investment.id)}`);
  }, [investment.id]);

  const isCompleted = investment?.status === InvestmentStatus.COMPLETED;

  return (
    <StyledCard
      onClick={() => navigateToDetailsPage()}
      onMouseEnter={() => {
        setCollapsed(false);
      }}
      onMouseLeave={() => {
        setCollapsed(true);
      }}
      data-testid="investment-card"
    >
      <StyledMedia data-testid="investment-card__media">
        <StyledInvestmentStatusChip
          data-testid="investment-card__media__status"
          status={investment.status}
          soldOut={investment.soldOut}
        />
        <StyledImage
          data-testid="investment-card__media__image"
          src={investment.asset?.nftDescription?.image}
        />
        {![InvestmentStatus.COMPLETED].includes(investment.status) && (
          <State data-testid="investment-card__media__state">
            <span data-testid="investment-card__media__state__label">
              {state.upcoming ? 'Starts' : 'Ends'} in
            </span>
            <span><strong>{IntervalLegend}</strong></span>
          </State>
        )}
      </StyledMedia>
      <DetailsContainer
        data-testid="investment-card__details"
        completed={isCompleted}
        collapsed={collapsed || undefined}
      >
        {isTablet && (
          <CollapseButton
            collapsed={collapsed}
            onClick={e => {
              setCollapsed(!collapsed);
              e.stopPropagation();
            }}
          >
            <MoreHorizIcon />
          </CollapseButton>
        )}
        <Title data-testid="investment-card__details__title">
          {investment.saleTitle}
        </Title>
        <Artist data-testid="investment-card__details__subtitle">
          {investment.saleSubtitle}
        </Artist>
        <InfoList>
          <InfoRaised data-testid="investment-card__details__raised">
            <InfoRaisedLabels>
              <span data-testid="investment-card__details__raised__label">
                Raised
              </span>
              <span data-testid="investment-card__details__raised__value">
                {formatCurrency(investment.fundsRaised || 0, '$', 0)} (
                {investment.fundsRaisedPercent}%)
              </span>
            </InfoRaisedLabels>
            <LinearProgressContainer data-testid="investment-card__details__raised__progress">
              <StyledLinearProgress
                variant="determinate"
                value={investment.fundsRaisedPercent || 0}
              />
            </LinearProgressContainer>
          </InfoRaised>

          <Info>
            <span data-testid="investment-card__details__deal-type__label">
              Deal type
            </span>
            <span data-testid="investment-card__details__deal-type__value">
              {investment.dealType}
            </span>
          </Info>

          {isCompleted ? (
            <>
            <Info>
              <span data-testid="investment-card__details__total-mfts__label">
                Total MFTs
              </span>
              <span data-testid="investment-card__details__total-mfts__value">
                {formatNumber(investment.asset?.nftCount || 0)}
              </span>
            </Info>
            <Info last>
              <span data-testid="investment-card__details__rewards-share__label">
                Rewards share
              </span>
              <span data-testid="investment-card__details__rewards-share__value">
                {investment.rewardsShare}%
              </span>
            </Info>
          </>
          ) : (
            <>
              <Info>
                <span data-testid="investment-card__details__investors__label">
                  Buyers
                </span>
                <span data-testid="investment-card__details__investors__value">
                  {investment.investors}
                </span>
              </Info>
              <InfoStepper>
                <InvestimentStepper
                  preSaleAt={investment.preSaleStartDate}
                  saleAt={investment.saleStartDate}
                  endAt={investment.saleEndDate}
                />
              </InfoStepper>
            </>
          )}
        </InfoList>
      </DetailsContainer>
    </StyledCard>
  );
}
