import {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import PageHeader from '@opulous/web/src/components/PageHeader';
import { Box } from '@mui/material';
import AnalyticsTable from '@opulous/web/src/components/Analytics/AnalyticsTable';
import AnalyticsChart from '@opulous/web/src/components/Analytics/AnalyticsChart';
import FilterSelection from '@opulous/web/src/components/Analytics/FilterSelection';
import { styled } from '@mui/system';
import * as Sentry from '@sentry/react';

import WalletContext from '@opulous/web/src/context/context';
import * as AnalyticsService from '@opulous/web/src/services/analytics';
import {
  AnalyticsChartData,
  AnalyticsFilter,
  AnalyticsTableEntry,
  AnalyticsTrendsDateMap,
  AnalyticsTrendsTypeMap,
  AnalyticsTrendsViewMap,
} from '@opulous/web/src/shared/types';
import { AlertError } from '@opulous/web/src/components/shared';
import { createHexColor } from '@opulous/web/src/shared/helpers';

const Wrapper = styled('div')(() => ({
  position: 'relative',
}));

const FilterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(4, -1, 3),
  [theme.breakpoints.down('lg')]: {
    flexWrap: 'wrap',
  },
}));

const StyledFilterSelection = styled(FilterSelection)(({ theme }) => ({
  margin: theme.spacing(0, 1, 1, 1),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

type FilterSelecled = {
  view: keyof typeof AnalyticsTrendsViewMap;
  by: keyof typeof AnalyticsTrendsTypeMap;
  days: number;
  releaseId?: number;
};

const StoreColorMap: Record<string, string> = {
  Spotify: '#00AF9C',
  Deezer: '#F7B79D',
  'Apple Music': '#2F4858',
  YouTube: '#F32A5E',
  Amazon: '#24CAD3',
  Soundcloud: '#F75300',
  iTunes: '#F4223A',
  TikTok: '#151515',
};
export default function AnalyticsPage(): ReactElement {
  const {
    state: { wallet },
  } = useContext(WalletContext);

  const [loading, setLoading] = useState(true);
  const [showAlertError, setShowAlertError] = useState(false);
  const [analyticsFilter, setAnalyticsFilter] = useState<AnalyticsFilter>({
    releases: [],
  } as AnalyticsFilter);
  const [analyticsTableData, setAnalyticsTableData] = useState<
    AnalyticsTableEntry[]
  >([]);
  const [analyticsChartData, setAnalyticsChartData] =
    useState<AnalyticsChartData>({
      series: [],
      categories: [],
    } as AnalyticsChartData);
  const [filterSelected, setFilterSelected] = useState<FilterSelecled>({
    view: AnalyticsTrendsViewMap.STREAM.value,
    by: AnalyticsTrendsTypeMap.STORES.value,
    days: AnalyticsTrendsDateMap.WEEK.value,
  } as FilterSelecled);

  const loadAnalyticsData = async (
    walletAddress: string,
    filter: FilterSelecled,
  ) => {
    try {
      setLoading(true);
      const response = await AnalyticsService.fetchData(walletAddress, {
        view: filter.view,
        by: filter.by,
        days: filter.days,
        releaseId: filter.releaseId,
      });
      setAnalyticsFilter(response.filter);

      const chartLegends: string[] = [];
      const categoriesMap: Record<string, number[]> = {};
      response.data.data.forEach(it => {
        chartLegends.push(it.key);
        it.series.forEach((s: any) => {
          categoriesMap[s.date] = [...(categoriesMap[s.date] || []), s.total];
        });
      });
      const newAnalyticsChartData = {
        categories: Object.keys(categoriesMap),
        series: Object.values(categoriesMap).length
          ? Object.values(categoriesMap)[0].map((it, ix) => {
              const entry: any = {
                name: chartLegends[ix],
                data: [],
                color: createHexColor(ix),
              };
              Object.values(categoriesMap).forEach((value: number[]) => {
                entry.data.push(value[ix]);
              });
              return entry;
            })
          : [],
      };

      setAnalyticsChartData({
        categories: newAnalyticsChartData.categories,
        series: newAnalyticsChartData.series.slice(0, 10),
      });

      let newAnalyticsTableData = response.data.data.map(it => ({
        name: it.key,
        total: it.total,
      }));
      if (filterSelected.by === AnalyticsTrendsTypeMap.STORES.value) {
        newAnalyticsTableData = newAnalyticsTableData.map(it => ({
          ...it,
          color: StoreColorMap[it.name],
        }));
      }
      setAnalyticsTableData(newAnalyticsTableData);
    } catch (error) {
      Sentry.captureException(error);
      setShowAlertError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = useCallback(
    (newFilterSelected: FilterSelecled) => {
      setFilterSelected(newFilterSelected);
      loadAnalyticsData(wallet, newFilterSelected);
    },
    [],
  );

  useEffect(() => {
    loadAnalyticsData(wallet, filterSelected);
  }, []);

  const analyticsChartProps = {
    loading,
    title: AnalyticsTrendsTypeMap[filterSelected.by].label,
    ...analyticsChartData,
  };
  const analyticsTableProps = {
    loading,
    filterSelected,
    data: [...analyticsTableData],
  };

  return (
    <Wrapper data-testid="analytics-page">
      {showAlertError && (
        <AlertError message="There was an error with the page loading" />
      )}
      <PageHeader
        title="Analytics"
        description="These numbers are estimates and will not necessarily reflect the exact final sales numbers"
      />
      <FilterBox data-testid="analytics-page__filter-box">
        <StyledFilterSelection
          data-testid="analytics-page__filter-box__by"
          label="Group By"
          value={filterSelected.by}
          options={Object.values(AnalyticsTrendsTypeMap).map(it => it)}
          handleChange={value =>
            handleFilterChange({
              ...filterSelected,
              by: value as keyof typeof AnalyticsTrendsTypeMap,
            })
          }
        />
        <StyledFilterSelection
          data-testid="analytics-page__filter-box__view"
          label="View"
          value={filterSelected.view}
          options={Object.values(AnalyticsTrendsViewMap).map(it => it)}
          handleChange={value =>
            handleFilterChange({
              ...filterSelected,
              view: value as keyof typeof AnalyticsTrendsViewMap,
            })
          }
        />
        <StyledFilterSelection
          data-testid="analytics-page__filter-box__days"
          label="Date"
          value={filterSelected.days}
          options={Object.values(AnalyticsTrendsDateMap).map(it => it)}
          handleChange={value =>
            handleFilterChange({ ...filterSelected, days: Number(value) })
          }
        />
        <StyledFilterSelection
          data-testid="analytics-page__filter-box__releases"
          label="Releases"
          value={filterSelected.releaseId}
          options={analyticsFilter.releases}
          handleChange={value =>
            handleFilterChange({ ...filterSelected, releaseId: Number(value) })
          }
        />
      </FilterBox>

      <AnalyticsChart {...analyticsChartProps} />
      <AnalyticsTable {...analyticsTableProps} />
    </Wrapper>
  );
}
