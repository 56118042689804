import { ReactElement } from 'react';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material';
import { InvestmentStatus } from '@opulous/web/src/shared/types';

const Status = styled('div')<{ color: string }>(({ color, theme }) => ({
  padding: theme.spacing(0.2, 1.4, 0.2, 1.4),
  borderRadius: theme.spacing(2),
  fontSize: theme.spacing(1.3),
  fontWeight: 'bold',
  color,
  border: `1px solid ${color}`,
  backgroundColor: theme.palette.common.white,
  textTransform: 'uppercase',
  textAlign: 'center',
}));

type Props = {
  'data-testid'?: string;
  className?: string;
  status: InvestmentStatus;
  soldOut: boolean;
};
export default function InvestmentStatusChip({
  ['data-testid']: dataTestid,
  className,
  status,
  soldOut,
}: Props): ReactElement {
  const theme = useTheme();
  const preparedStatus = (soldOut && status !== InvestmentStatus.COMPLETED) ? InvestmentStatus.SOLD_OUT : status;
  const InventmentStatusColorMap = new Map<string, string>([
    [InvestmentStatus.PRE_SALE, theme.palette.primary.main],
    [InvestmentStatus.ACTIVE, theme.palette.success.main],
  ]);

  return (
    <Status
      className={className}
      data-testid={dataTestid || 'investment-status'}
      color={InventmentStatusColorMap.get(preparedStatus) || theme.palette.common.black}
    >
      {preparedStatus}
    </Status>
  );
}
