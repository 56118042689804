import * as React from 'react';

const SuccessIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m16.667 5-9.166 9.167L3.334 10"
    />
  </svg>
)

export default SuccessIcon
