import { ReactElement, useState } from 'react'
import {
  Box,
  Divider,
  Button,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
} from '@mui/material'
import * as Sentry from '@sentry/react'
import { styled } from '@mui/system'
import Dialog from '../Dialog';
import SuccessIcon from '../icons/SuccessIcon';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const ContentContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));

const TextInput = styled(TextField)(({ theme }) => ({

  '&::placeholder': {
    color: theme.palette.grey[700],
  },

  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1),
  },

  '& .MuiInputBase-root:not(.Mui-error) fieldset': {
    border: 0,
  },
}));

const EmailDisplayBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(.75, 1.25),
  userSelect: 'none',

  '& .label': {
    color: theme.palette.grey[500],
    fontSize: '.75rem',
  },
}));

const ResendButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[500],
  borderColor: theme.palette.grey[500],
  padding: theme.spacing(.5, 2),

  '&:not(.Mui-disabled):hover': {
    color: theme.palette.grey[700],
    borderColor: theme.palette.grey[700],
  },

  '&.Mui-disabled': {
    color: theme.palette.grey[200],
    borderColor: theme.palette.grey[200],
  },
}))

const IconContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.dark,
  width: '3rem',
  height: '3rem',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& > svg': {
    width: '60%',
    height: '60%',
  },
}));

const ErrorMsg = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: '500',
  marginTop: theme.spacing(2),
}));

export default function ClaimModal({
  onClose,
  onSubmit,
  onClaimed,
  onVerificationCodeSend,
}: {
  onClose: () => void
  onSubmit: (email: string, verificationCode: string) => Promise<void>
  onClaimed: () => Promise<void>
  onVerificationCodeSend: (email: string) => Promise<void>
}): ReactElement {
  const [email, setEmail] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [step, setStep] = useState<'claim'|'verify'|'submit'>('claim');
  const [isLoading, setIsLoading]= useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleEmailSubmit = async () => {
    if (EMAIL_REGEX.test(email)) {
      try {
        setErrorMsg('');
        setIsEmailValid(true);
        setIsLoading(true);
        setIsSendingCode(true);
        await onVerificationCodeSend(email);
        setStep('verify');
      } catch (err) {
        Sentry.captureException(err);
        setErrorMsg('An unexpected error ocurred, please try again');
      } finally {
        setIsSendingCode(false);
        setIsLoading(false);
      }
    } else {
      setIsEmailValid(false);
    }
  };

  const handleVerifyAndClaim = async () => {
    try {
      setErrorMsg('');
      setIsLoading(true);
      await onSubmit(email, verificationCode);
      setStep('submit');
    } catch (err) {
      Sentry.captureException(err);
      setErrorMsg('An unexpected error ocurred, please try again');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationCodeResend = async () => {
    try {
      setErrorMsg('');
      setIsSendingCode(true);
      await onVerificationCodeSend(email);
    } catch (err) {
      Sentry.captureException(err);
      setErrorMsg('An unexpected error ocurred, please try again');
    } finally {
      setIsSendingCode(false);
    }
  };

  const handleClaimed = async () => {
    onClose();
    await onClaimed();
  };

  if (isLoading) {
    return (
      <Dialog onClose={onClose}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={5}
        >
          <CircularProgress color="primary" />
        </Box>
      </Dialog>
    );
  }

  if (step === 'verify') {
    return (
      <Dialog
        title="Data verification"
        subTitle="Please check your email and enter the security code below"
        onClose={onClose}
      >
        <ContentContainer>
          <EmailDisplayBox>
            <div className="label">Email</div>
            <div>{email}</div>
          </EmailDisplayBox>
          <Box paddingY={2}><Divider /></Box>
          <TextInput
            type="text"
            placeholder="Enter code"
            fullWidth
            onChange={e => setVerificationCode(e.target.value.slice(0, 10))}
            value={verificationCode}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <ResendButton
                    disabled={isSendingCode}
                    variant="outlined"
                    onClick={handleVerificationCodeResend}
                  >
                    Resend
                  </ResendButton>
                </InputAdornment>
              ),
            }}
          />
        </ContentContainer>

        {!!errorMsg && (<ErrorMsg>{errorMsg}</ErrorMsg>)}

        <Box paddingY={2}><Divider /></Box>
        <Box textAlign="center" padding={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleVerifyAndClaim}
            fullWidth
            disabled={!verificationCode}
          >
            Validate code
          </Button>
        </Box>
      </Dialog>
    );
  }

  if (step === 'submit') {
    return (
      <Dialog onClose={onClose} maxWidth="sm">
        <Box display="flex" justifyContent="center" marginBottom={2}>
          <IconContainer><SuccessIcon /></IconContainer>
        </Box>

        <Box textAlign="center" paddingBottom={3}>
          <Typography variant="h4">We have successfully received your email address.</Typography>
          <Typography variant="h4">Thank you!</Typography>
        </Box>

        <Box textAlign="center" padding={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClaimed}
            fullWidth
          >
            Ok
          </Button>
        </Box>
      </Dialog>
    );
  }

  return (
    <Dialog
      title="Congratulations!"
      subTitle="Your stake won! Submit your email to claim your reward!"
      onClose={onClose}
    >
      <ContentContainer>
        <TextInput
          type="email"
          placeholder="Your email"
          fullWidth
          onChange={e => setEmail(e.target.value)}
          value={email}
          error={!isEmailValid}
        />
        {!!errorMsg && (<ErrorMsg>{errorMsg}</ErrorMsg>)}
      </ContentContainer>
      <Box paddingY={2}><Divider /></Box>
      <Box textAlign="center" padding={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleEmailSubmit}
          fullWidth
        >
          Send
        </Button>
      </Box>
    </Dialog>
  );
}
