import React, { useContext, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Modal } from '@mui/material';
import { styled } from '@mui/system';
import WalletContext from '@opulous/web/src/context/context';
import { WalletTypeEnum } from '@opulous/web/src/shared/types';
import ConnectWallet from '@opulous/web/src/components/Common/ConnectWallet';
import MyAlgoWarningDialog from '@opulous/web/src/components/shared/MyAlgoWarningDialog';

const StyledModalBackground = styled(Modal)(() => ({
  '& .MuiPaper-root': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

interface IWalletModal {
  onClose?: () => void;
  showWalletModal?: boolean;
}

const WalletModal: React.FC<IWalletModal> = ({
  onClose,
  showWalletModal = false,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { initPeraWallet } = useContext(WalletContext);
  const [shouldShowMyAlgoWarningDialog, setShouldShowMyAlgoWarningDialog] =
    useState(false);

  const connectWallet = async (type: WalletTypeEnum) => {
    switch (type) {
      case WalletTypeEnum.myAlgoWallet:
        setShouldShowMyAlgoWarningDialog(true);
        return;
      case WalletTypeEnum.peraWallet:
        initPeraWallet();
        return;
      default:
        return;
    }
  };
  const NewWalletTimeout = 1 * 60 * 1000;

  const handleConnect = async (type: WalletTypeEnum) => {
    try {
      setTimeout(() => {
        setLoading(false);
        setError('');
      }, NewWalletTimeout);

      connectWallet(type);
    } catch (ex) {
      setLoading(false);
      Sentry.captureException(ex);
      setError('Something went wrong, please try again');
    }
  };
  return (
    <>
      <StyledModalBackground
        open={showWalletModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConnectWallet
          onClose={onClose}
          onWalletSelect={type => {
            handleConnect(type);
            onClose && onClose();
          }}
          loading={isLoading}
          errorText={error}
        />
      </StyledModalBackground>

      <MyAlgoWarningDialog
        show={shouldShowMyAlgoWarningDialog}
        onClose={() => setShouldShowMyAlgoWarningDialog(false)}
      />
    </>
  );
};

export default WalletModal;
