import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';
import { styled } from '@mui/system';
import { Fade, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import ConnectWallet from '@opulous/web/src/components/Common/ConnectWallet';
import ErrorMessage from '@opulous/web/src/components/ErrorMessage';
import WalletContext from '@opulous/web/src/context/context';
import { Asset, AssetType, WalletTypeEnum } from '@opulous/web/src/shared/types';
import AssetClaimCard from '@opulous/web/src/components/Claims/AssetClaimCard';
import * as myAlgoService from '@opulous/web/src/services/my-algo';
import PeraInfoModal from '@opulous/web/src/components/Wallet/PeraInfoModal';
import StyledAlert from '@opulous/web/src/components/Common/StyledAlert';
import MyAlgoWarningDialog from 'src/components/shared/MyAlgoWarningDialog';

const StyledBox = styled(Box)(() => ({
  height: 'calc(100vh - 80px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const StyleCard = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow:
    '0px 3px 6px rgba(0, 0, 0, 0.12), 0px 12px 24px rgba(0, 0, 0, 0.1), 0px 30px 60px rgba(0, 0, 0, 0.12)',
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '24px',
  width: '410px',
  maxWidth: '410px',
  '& > span': {
    fontSize: '12px',
  },
}));

const Wrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 'calc(100vh - 80px)',
}));

const CardTitle = styled('div')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '28px',
  color: theme.palette.common.black,
}));

const CardBody = styled('div')(({ theme }) => ({
  fontWeight: 300,
  fontSize: '14px',
  lineHeight: '150%',
  color: theme.palette.grey[700],
}));

const StyledButton = styled('button')<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    backgroundColor: theme.palette.primary.main,
    borderRadius: '40px',
    color: theme.palette.common.white,
    fontSize: '16px',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    padding: '8px 40px',
    cursor: 'pointer',
    ...(disabled && {
      backgroundColor: `${theme.palette.common.black}14`,
      color: `${theme.palette.common.black}4d`,
      cursor: 'default',
    }),
  }),
);

const assets = [
  {
    id: 725937713,
    type: AssetType.artwork,
    unitName: 'MONAW',
    decimals: 0,
    custom: {
      title: 'Mona Lisa (Sold Out)',
      description:
        'We’re living in greater times now. Anyone can be anything they like, and Mona Lisa would have wanted it that way. So she shed their boring brown robe and donned a bejeweled white-fringe coat in an effort to break down all the barriers left in this world. After all, who would want it any other way?',
      fields: [],
      artist: 'Lil Pump ft. Soulja Boy',
      content: { sections: [] },
      sidebar: { items: [] },
    },
    name: 'Mona Lisa (Sold Out)',
    image: '/images/DolorSit.jpg',
    title: 'Mona Lisa (Sold Out)',
    artist: 'Lil Pump ft. Soulja Boy',
    nftCount: 0,
    thumbnail: '/images/DolorSit.jpg',
    percentCopyright: 50,
  },
  {
    id: 834249983,
    type: AssetType.mft,
    unitName: 'ADZ1',
    decimals: 0,
    custom: {
      title: 'Patek Myself',
      description:
        'Ard Adz released his sizzling hot MFT single, Patek Myself, in May 2022. Our joint MFTcampaign sold out in just under 45 minutes, surpassing our fundraising goals!',
      fields: [],
      artist: 'Ard Adz',
      content: { sections: [] },
      sidebar: { items: [] },
    },
    name: 'Patek Myself',
    image: '/images/ADZ1.png',
    title: 'Patek Myself',
    artist: 'Ard Adz',
    nftCount: 0,
    thumbnail: '/images/ADZ1.png',
    percentCopyright: 50,
  },
  {
    id: 871206880,
    type: AssetType.mft,
    unitName: 'KYLE1',
    decimals: 0,
    custom: {
      title: 'It’s Not So Bad',
      description:
        'KYLE released It’s Not So Bad in January 2022. It was our first ever full-album MFT drop, and Together we raised a total of $150,000 with over 300 investors! ',
      fields: [],
      artist: 'Kyle',
      content: { sections: [] },
      sidebar: { items: [] },
    },
    name: 'It’s Not So Bad',
    image: '/images/KYLE1.png',
    title: 'It’s Not So Bad',
    artist: 'Kyle',
    nftCount: 550000,
    thumbnail: '/images/KYLE1.png',
    percentCopyright: 50,
  }
] as Asset[];

interface OptInMFTProps {
  unitName: string;
}

const OptInMFT: React.FC<OptInMFTProps> = ({ unitName }) => {
  const theme = useTheme();
  const [optInAsset] = useState(assets.find(a => a.unitName === unitName));
  const [step, setStep] = useState(1);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [peraDialogOpen, setPeraDialogState] = useState<boolean>(false);
  const [optInError, setOptInError] = useState<string>('');
  const [shouldShowMyAlgoWarningDialog, setShouldShowMyAlgoWarningDialog] =
    useState(false);

  const navigate = useNavigate();
  const {
    state: { wallet, walletType, connector },
    initPeraWallet,
  } = useContext(WalletContext);

  useEffect(() => {
    if (wallet) {
      setStep(2);
    } else {
      setStep(1);
    }
  }, [wallet]);

  const connectWallet = async (type: WalletTypeEnum) => {
    switch (type) {
      case WalletTypeEnum.myAlgoWallet:
        setShouldShowMyAlgoWarningDialog(true);
        return;
      case WalletTypeEnum.peraWallet:
        initPeraWallet();
        return;
      default:
        return;
    }
  };
  const NewWalletTimeout = 1 * 60 * 1000;

  const handleConnect = async (type: WalletTypeEnum) => {
    try {
      setTimeout(() => {
        setLoading(false);
        setError('');
      }, NewWalletTimeout);

      connectWallet(type);
    } catch (ex) {
      setLoading(false);
      Sentry.captureException(ex);
      setError('Something went wrong, please try again');
    }
  };

  const handleOptin = async () => {
    try {
      if (walletType === WalletTypeEnum.peraWallet) {
        Sentry.addBreadcrumb({
          category: '<VerifyPage /> : optInToAsset',
          message: 'Displaying Pera mobile dialog',
          type: 'info',
          data: {
            wallet,
            walletType,
          },
        });
        setPeraDialogState(true);
      }
      if (optInAsset) {
        await myAlgoService.optIn({
          assets: [optInAsset.id],
          wallet,
          connector,
          walletType,
        });
      }

      navigate('/');
    } catch (ex) {
      Sentry.captureException(ex);
      setOptInError(
        'An unexpected error ocurred while opting in. Please try again.',
      );
    }
  };

  return (
    <div>
      <Wrapper>
        {step === 1 && (
          <Fade in>
            <StyledBox>
              <ConnectWallet
                onWalletSelect={handleConnect}
                loading={isLoading}
                errorText={error}
              />
              {error.length > 0 && <ErrorMessage content={error} />}
            </StyledBox>
          </Fade>
        )}
        {optInAsset && step === 2 && (
          <Fade in>
            <StyleCard>
              <CardTitle>It's time to claim your MFTs!</CardTitle>
              <CardBody>
                Please opt-in to the asset below. The tokens will be distributed
                within a week to your wallet.
              </CardBody>

              <StyledAlert
                fontSizeMessage="16px"
                borderRadius="20px"
                severity="info"
                fontWeight="300"
                colorMessage="rgb(11, 50, 85)"
                padding="16px 8px "
                colorIcon="rgb(11, 50, 85)"
                lineHeight="1"
              >
                Please ensure that you have enough ALGO’s available to do the
                transaction(s). Read more about it{' '}
                <a
                  href="https://opulous.medium.com/how-to-buy-algorand-algo-6e9d97e030cf"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </StyledAlert>
              <AssetClaimCard asset={optInAsset} loading={false} />
              <StyledButton onClick={handleOptin}>Opt me in!</StyledButton>
              {!!optInError && (
                <StyledAlert
                  colorIcon={theme.palette.error.dark}
                  colorAlert={theme.palette.error.dark}
                  borderRadius="20px"
                  backgroundColor={theme.palette.error.light}
                  fontWeight="600"
                  padding="11px 8px"
                  severity="error"
                >
                  {optInError}
                </StyledAlert>
              )}
            </StyleCard>
          </Fade>
        )}
      </Wrapper>
      <PeraInfoModal
        open={peraDialogOpen}
        onClose={() => setPeraDialogState(false)}
      />
      <MyAlgoWarningDialog
        show={shouldShowMyAlgoWarningDialog}
        onClose={() => setShouldShowMyAlgoWarningDialog(false)}
      />
    </div>
  );
};

export default OptInMFT;
