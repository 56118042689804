import React from 'react'
import { Asset } from '@opulous/web/src/shared/types'
import { AssetContent, ClaimWrapper } from '../index'

interface IOptIn {
  onOptIn: () => void
  assets: Asset[]
  wallet: string
  loading: boolean
  claiming: boolean
  errorText?: string
  infoText?: string
}

const OptIn: React.FC<IOptIn> = ({
  onOptIn,
  wallet,
  loading = true,
  assets,
  claiming = false,
  errorText = '',
  infoText = '',
}) => {
  const isEmpty = !loading && assets.length === 0;

  return (
    <ClaimWrapper
      title="Claim your assets"
      submitText="Claim now"
      onSubmit={onOptIn}
      loading={claiming}
      errorText={errorText}
      infoText={infoText}
      isEmpty={isEmpty}
    >
      <AssetContent wallet={wallet} assets={assets} loading={loading} />
    </ClaimWrapper>
  )
}

export default OptIn
