import React, { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import _PieChartIcon from '@mui/icons-material/PieChart'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import Typography from '@mui/material/Typography'
import { Button, CircularProgress, Dialog, IconButton, Theme, Tooltip } from '@mui/material'
import { useTheme } from '@mui/styles'
import SvgIcon from '@mui/material/SvgIcon'
import FiberIcon from '@mui/icons-material/FiberSmartRecord'
import { formatDate } from '@opulous/web/src/utils'
import { PoolList } from '../../../shared/types'
import { ReactComponent as OpulSvg } from '../../../assets/opul.svg'
import OPULLabel from '@opulous/web/src/components/shared/OPULLabel'
import _DateRangeIcon from '@mui/icons-material/DateRange';
import _EventIcon from '@mui/icons-material/Event';
import _DeleteIcon from '@mui/icons-material/Delete';
import _EditIcon from '@mui/icons-material/Edit';
import _WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom'
import PoolService from 'src/services/pools';

const WarningIcon = styled(_WarningIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  color: theme.palette.warning.main,
}));

const EditIcon = styled(_EditIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  fill: theme.palette.primary.main,
}));

const DeleteIcon = styled(_DeleteIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  fill: theme.palette.primary.main,
}));

const EventIcon = styled(_EventIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const DateRangeIcon = styled(_DateRangeIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const PieChartIcon = styled(_PieChartIcon)<{ dimmed?: boolean }>(({ theme, dimmed = false }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  color: dimmed ? theme.palette.grey[400]: theme.palette.primary.main,
}));

const PoolIcon = styled(FiberIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  color: theme.palette.primary.main,
  transform: 'rotateY(180deg)',
}))

const StyledCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    borderRadius: theme.spacing(1.5),
  },
  minHeight: theme.spacing(8),
  background: theme.palette.common.white,
  color: theme.palette.common.black,
  position: 'relative',
}))

export const PoolVersionTag = styled('div')<{ dimmed?: boolean }>(({ theme, dimmed = false }) => ({
  color: theme.palette.common.white,
  fontWeight: '600',
  position: 'absolute',
  top: theme.spacing(2),
  right: 0,
  height: theme.spacing(4),
  width: theme.spacing(6),
  fontSize: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(2, 0, 0, 2),
  backgroundColor: dimmed ? theme.palette.grey[400] : theme.palette.primary.main,
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: `${theme.spacing(3.5, 3)} !important`,
}))

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  lineHeight: theme.spacing(3.5),
  fontWeight: 600,
  flex: 1,
  height: theme.spacing(7),
  display: 'inline-flex',
  alignItems: 'center',

  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(4), // NOTE: Offset for the version tag
  },
}))

const CardFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 3),
  borderTop: `1px solid ${theme.palette.grey[200]}`,
}))

const Label = styled(Typography)<{ isna?: boolean }>(
  ({ isna = false, theme }) => ({
    fontSize: theme.spacing(1.75),
    lineHeight: theme.spacing(2.625),
    fontWeight: 600,
    color: isna ? 'rgba(0, 0, 5, 0.3)' : theme.palette.common.black,
  })
)

const Value = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2.5),
  color: theme.palette.common.black,
  fontWeight: 300,
  whiteSpace: 'nowrap',
}))

const CurrencyWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(6),
  height: theme.spacing(6),
  background: theme.palette.grey['A100'],
  borderRadius: '50%',
  border: `2px solid ${theme.palette.grey[100]}`,
}))

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(3, 2),

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

interface PoolCardProps {
  pool: PoolList
  onPoolRefresh: () => Promise<void>
}

const AdminPoolCard: React.FC<PoolCardProps> = ({ pool, onPoolRefresh }) => {
  const theme: Theme = useTheme()
  const navigate = useNavigate();
  const [isNA, setIsNA] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false);
  const [shouldShowDeletionDialog, setShouldShowDeletionDialog] = useState(false);

  const hasInvalidAppId = pool.tokenId === undefined || pool.tokenId === null;

  useEffect(() => {
    if (pool.poolSize === undefined || pool.maxPoolSize === undefined) {
      setIsNA(true)
    } else {
      setIsNA(false)
    }
  }, [pool])

  const handleEdit = (id: number) => () => navigate(`/admin/pools/${encodeURIComponent(id)}/edit`);
  const handlePoolDeletionDialogClose = () => setShouldShowDeletionDialog(false);
  const handlePoolDeletionConfirm = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDeleting(true);
    await PoolService.adminDeletePool(pool.id);
    setIsDeleting(false);
    await onPoolRefresh();
  };

  const isVersionExpired = pool.versionLabel !== pool.latestVersionLabel;

  return (<>
    <Box>
      <StyledCard theme={theme}>
        <StyledCardContent>
          <Box marginBottom={3} display="flex" gap={1} sx={{
            flexDirection: { xs: 'column', md: 'row' },
          }}>
            <CurrencyWrapper>
              {pool.id === 1 ? (
                <SvgIcon
                  component={OpulSvg}
                  viewBox="-9.25 -2.5 54 45"
                  sx={{ width: theme.spacing(3), height: theme.spacing(3) }}
                />
              ) : (
                <MonetizationOnOutlinedIcon sx={{ width: theme.spacing(4) }} />
              )}
            </CurrencyWrapper>
            <Title>{pool.title}</Title>
          </Box>

          <Grid>
            <Box display="flex" flexDirection="column" gap={.5}>
              <Box display="flex" alignItems="center" gap={.5}>
                  <PieChartIcon dimmed={isVersionExpired} />
                  <Label>APY</Label>
              </Box>
              <Value>{hasInvalidAppId ? '-' : `${pool.minAnnualPercentageYield / 100}% - ${
                pool.maxAnnualPercentageYield / 100
              }%`}</Value>
            </Box>

            <Box display="flex" flexDirection="column" gap={.5}>
              <Box display="flex" alignItems="center" gap={.5}>
                  <PoolIcon />
                  <Label isna={isNA}>TVL</Label>
              </Box>
              <Value>
                {hasInvalidAppId ? '-' : (<>
                  <OPULLabel value={BigInt(pool.poolSize).toString()} /> OPUL
                </>)}
              </Value>
            </Box>

            <Box display="flex" flexDirection="column" gap={.5}>
              <Box display="flex" alignItems="center" gap={.5}>
                  <DateRangeIcon />
                  <Label>Stake Period</Label>
              </Box>
              <Value>
                {hasInvalidAppId ? '-' : `${pool.minStakePeriod / 24 / 60 / 60} - ${
                  pool.maxStakePeriod / 24 / 60 / 60
                } days`}
              </Value>
            </Box>

            <Box display="flex" flexDirection="column" gap={.5}>
              <Box display="flex" alignItems="center" gap={.5}>
                  <EventIcon />
                  <Label>End Date</Label>
              </Box>
              <Value>
                {hasInvalidAppId ? '-' : formatDate(new Date(pool.endTimestamp))}
              </Value>
            </Box>
          </Grid>
        </StyledCardContent>
        <CardFooter>
          <Box display="flex" justifyContent="flex-end" gap={1} width="100%">
            {hasInvalidAppId && (
              <Tooltip title={
                <Box padding={.5}>
                  Invalid App ID #{pool.appId}, please check and update the pool ID
                </Box>
              }>
                <Box display="flex" justifyContent="center" alignItems="center" padding={1}>
                  <WarningIcon />
                </Box>
              </Tooltip>
            )}

            <IconButton onClick={handleEdit(pool.id)}>
              <EditIcon />
            </IconButton>

            <IconButton onClick={() => setShouldShowDeletionDialog(true)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </CardFooter>
        {pool.versionLabel && (
          <PoolVersionTag dimmed={isVersionExpired}>{pool.versionLabel}</PoolVersionTag>
        )}
      </StyledCard>
    </Box>

    {shouldShowDeletionDialog && (
      <Dialog
        open={shouldShowDeletionDialog}
        maxWidth="sm"
        onClose={handlePoolDeletionDialogClose}
      >
        <Box margin={3} display="flex" flexDirection="column" gap={3}>
          <Typography variant="h3">Delete Pool #{pool.id}</Typography>
          <Box>
            Are you sure you want to delete pool <b>{pool.title}</b> (#{pool.id}) (App ID: {pool.appId})?
          </Box>
          <Box display="flex" gap={2} justifyContent="center">
            <Button
              color="primary"
              variant="text"
              disabled={isDeleting}
              onClick={handlePoolDeletionDialogClose}
            >
              Cancel
            </Button>
            <Button
              color="error"
              disabled={isDeleting}
              onClick={handlePoolDeletionConfirm}
            >
              {isDeleting ? (
                <CircularProgress size="1.5rem" />
              ) : 'Confirm'}
            </Button>
          </Box>
        </Box>
      </Dialog>
    )}
  </>)
}

export default AdminPoolCard
