import React, { ReactNode } from 'react';
import { styled } from '@mui/system';
import MuiAlert from '@mui/material/Alert';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';

interface AlertProps {
  theme: Theme;
  colorIcon?: string;
  fontSizeIcon?: string;
  colorMessage?: string;
  fontSizeMessage?: string;
  width?: string;
  backgroundColor?: string;
  borderRadius?: string;
  marginTop?: string;
  padding?: string;
  fontWeight?: string;
  paddingLeft?: string;
  marginBottom?: string;
  AlertPoolDetail?: boolean;
  paddingIcon?: string;
  paddingMessage?: string;
  colorAlert?: string;
  lineHeight?: string;
  colorLink?: string;
  fontWeightLink?: string;
  margin?: string;
}

interface StyledAlertProps {
  severity: 'success' | 'error' | 'info' | 'warning';
  children: ReactNode;
  width?: string;
  borderRadius?: string;
  backgroundColor?: string;
  marginTop?: string;
  padding?: string;
  fontWeight?: string;
  paddingLeft?: string;
  marginBottom?: string;
  colorIcon?: string;
  fontSizeIcon?: string;
  colorMessage?: string;
  fontSizeMessage?: string;
  AlertPoolDetail?: boolean;
  paddingIcon?: string;
  paddingMessage?: string;
  colorAlert?: string;
  dataTestId?: string;
  lineHeight?: string;
  colorLink?: string;
  fontWeightLink?: string;
  margin?: string;
}

export const Alert = styled(MuiAlert)(
  ({
    theme,
    colorIcon,
    fontSizeIcon,
    colorMessage,
    fontSizeMessage,
    width = '100%',
    backgroundColor = theme.palette.info.light,
    borderRadius = '4px',
    marginTop = '0px',
    padding = '0px',
    fontWeight = '600',
    paddingLeft = 'Opx',
    marginBottom = '0px',
    AlertPoolDetail = false,
    paddingIcon = '0px',
    paddingMessage = '0px',
    colorAlert,
    lineHeight,
    colorLink,
    fontWeightLink,
    margin,
  }: AlertProps) => ({
    position: 'relative',
    width,
    fontWeight,
    paddingLeft,
    marginBottom,
    color: colorAlert,
    borderRadius,
    backgroundColor,
    padding,
    marginTop,
    lineHeight,
    margin,
    [theme.breakpoints.down('lg')]: {
      width: AlertPoolDetail && '100%',
    },

    '& .MuiAlert-icon': {
      color: colorIcon,
      padding: paddingIcon,
      '& svg': {
        fontSize: fontSizeIcon,
      },
    },
    '& .MuiAlert-message': {
      fontSize: fontSizeMessage,
      color: colorMessage,
      padding: paddingMessage,
      whiteSpace: 'pre-line',
    },
    '& a': {
      color: colorLink,
      fontWeight: fontWeightLink,
    },
  }),
);

const StyledAlert: React.FC<StyledAlertProps> = ({
  children,
  severity,
  width,
  marginTop,
  borderRadius,
  backgroundColor,
  fontWeight,
  paddingLeft,
  marginBottom,
  padding,
  colorIcon,
  fontSizeIcon,
  colorMessage,
  fontSizeMessage,
  AlertPoolDetail,
  paddingIcon,
  paddingMessage,
  colorAlert,
  dataTestId,
  lineHeight,
  colorLink,
  fontWeightLink,
  margin,
}) => {
  const theme: Theme = useTheme();

  return (
    <Alert
      data-testid={dataTestId}
      theme={theme}
      severity={severity}
      width={width}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      marginTop={marginTop}
      padding={padding}
      fontWeight={fontWeight}
      paddingLeft={paddingLeft}
      marginBottom={marginBottom}
      colorIcon={colorIcon}
      fontSizeIcon={fontSizeIcon}
      colorMessage={colorMessage}
      fontSizeMessage={fontSizeMessage}
      AlertPoolDetail={AlertPoolDetail}
      paddingIcon={paddingIcon}
      paddingMessage={paddingMessage}
      colorAlert={colorAlert}
      lineHeight={lineHeight}
      colorLink={colorLink}
      fontWeightLink={fontWeightLink}
      margin={margin}
    >
      {children}
    </Alert>
  );
};

export default StyledAlert;
