import { ReactElement } from 'react';
import {
  Typography,
  Dialog,
  Box,
  Button,
  CircularProgress as MuiCircularProgress,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';
import { formatOpulAmount } from '@opulous/web/src/utils';
import MinorOPUL from '@opulous/web/src/shared/valueObjects/MinorOPUL';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(2),
    minWidth: 400,
  },
}));

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(5),
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 600,
  fontSize: theme.spacing(3),
  lineHeight: '28px',
  marginBottom: theme.spacing(5),
}));

const Content = styled(Box)(({ theme }) => ({
  width: '100%',
  justifyContent: 'flex-start',
  marginBottom: theme.spacing(5),
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  marginBottom: theme.spacing(0.5),
  fontSize: theme.spacing(1.75),
}));

const Amount = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: theme.spacing(2.5),
}));

const WidgetInfo = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(2, 2),
  border: `2px solid ${theme.palette.info.main}`,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.info.light,
}));

const WidgetError = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(2, 2),
  border: `2px solid ${theme.palette.error.main}`,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.error.light,
}));

const CircleProgress = styled(MuiCircularProgress)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  marginRight: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  fontSize: theme.spacing(2.2),
  height: theme.spacing(6),
  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(1.8),
  },
}));

type Props = {
  info?: string;
  error?: string;
  optIn?: boolean;
  amountAvailable?: MinorOPUL;
  cancel: () => void;
};
export default function ClaimModal({
  info,
  error,
  optIn,
  amountAvailable,
  cancel,
}: Props): ReactElement {
  return (
    <StyledDialog open={true} data-testid="vesting-claim-modal">
      <Container>
        <Title data-testid="vesting-claim-modal__title">
          {optIn ? 'Opt-in to OPUL' : 'Claim tokens'}
        </Title>
        {!optIn && (
          <Content>
            <Label data-testid="vesting-claim-modal__label">
              Available OPUL
            </Label>
            <Amount data-testid="vesting-claim-modal__amount">
              {amountAvailable && formatOpulAmount(amountAvailable)}
            </Amount>
          </Content>
        )}
        {info && (
          <WidgetInfo data-testid="vesting-claim-modal__info">
            <CircleProgress size="small" color="info" />
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                data-testid="vesting-claim-modal__info__message"
              >
                {info}
              </Typography>
            </Box>
          </WidgetInfo>
        )}
        {error && (
          <>
            <WidgetError data-testid="vesting-claim-modal__error">
              <InfoIcon style={{ marginRight: '0.8rem' }} color="error" />
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h6"
                  data-testid="vesting-claim-modal__error__message"
                >
                  {error}
                </Typography>
              </Box>
            </WidgetError>
            <StyledButton
              data-testid="vesting-claim-modal__error__cancel-button"
              variant="contained"
              color="primary"
              onClick={cancel}
            >
              Cancel
            </StyledButton>
          </>
        )}
      </Container>
    </StyledDialog>
  );
}
