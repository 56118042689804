import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ReactElement } from 'react'
import { IRaffleEntry } from 'src/shared/types';
import Dialog from '../Dialog';

const WALLET_ADDR_LEN_THRESHOLD = 6;

function getWalletAddressDisplayString(addr: string) {
  if (!addr) return addr;
  if (addr.length > WALLET_ADDR_LEN_THRESHOLD) {
    return addr.slice(0, WALLET_ADDR_LEN_THRESHOLD / 2) + '...' + addr.slice(-WALLET_ADDR_LEN_THRESHOLD/ 2);
  }
  return addr;
}

export default function WinnerListModal({
  winners,
  onClose,
}: {
  winners: IRaffleEntry[]
  onClose: () => void
}): ReactElement {
  return (
    <Dialog
      title="Raffle winners"
      onClose={onClose}
      maxWidth="lg"
    >
      <TableContainer sx={{ maxWidth: '100%' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Wallet</TableCell>
              <TableCell>Tickets</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {winners.map((winner, idx) => (
              <TableRow key={winner.walletAddress}>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>
                  <span>{getWalletAddressDisplayString(winner.walletAddress)}</span>
                </TableCell>
                <TableCell>{winner.ticketsSpentAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
}
