export const StoreDetailsMap: Map<string, { color: string, image: string }> = new Map(Object.entries({
  Spotify: {
    color: '#00AF9C',
    image: 'spotify.png',
  },
  Deezer: {
    color: '#F7B79D',
    image: 'deezer.png',
  },
  'Apple Music': {
    color: '#2F4858',
    image: 'apple.png',
  },
  Youtube: {
    color: '#F32A5E',
    image: 'youtube.png',
  },
  Facebook: {
    color: '#6B98D9',
    image: 'facebook.png',
  }
}));