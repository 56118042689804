import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SignerAppConnect, Session } from '@opulous/signer-app-connect';

import config from '@opulous/web/src/config';

interface SignerAppProviderProps {
  children: ReactNode;
}

interface SignerAppContextData {
  connect: () => Promise<void>;
  refresh: () => Promise<void>;
  disconnect: () => Promise<void>;
  sign: (txns: Buffer[]) => Promise<Buffer[]>;
  session?: Session;
  isConnected: boolean;
}

const SignerAppContext = createContext({} as SignerAppContextData);

export function SignerAppProvider({ children }: SignerAppProviderProps) {
  const [signerAppConnect, setSignerAppConnect] = useState<SignerAppConnect>();
  const [session, setSession] = useState<Session>();

  useEffect(() => {
    const onConnect = (newSession: Session) => {
      setSession(newSession);
    };
    const onDisconnect = () => {
      setSession(undefined);
    };

    if (!signerAppConnect) {
      setSignerAppConnect(
        new SignerAppConnect({
          port: config.env.SIGNER_APP_CONNECT_PORT,
          onConnect,
          onDisconnect,
        }),
      );
    }
  }, [signerAppConnect]);

  const connect = async () => {
    if (!signerAppConnect) {
      throw new Error('SignerAppConnect not initialized');
    }

    try {
      await signerAppConnect.connect();
    } catch (error) {
      setSession(undefined);
    }
  };

  const refresh = async () => {
    if (!signerAppConnect) {
      throw new Error('SignerAppConnect not initialized');
    }

    try {
      const newSession = await signerAppConnect.getSession();
      setSession(newSession);
    } catch (error) {
      setSession(undefined);
    }
  };

  const disconnect = async () => {
    if (!signerAppConnect) {
      throw new Error('SignerAppConnect not initialized');
    }

    signerAppConnect.disconnect();
  };

  const sign = async (txns: Buffer[]) => {
    if (!signerAppConnect) {
      throw new Error('SignerAppConnect not initialized');
    }

    return signerAppConnect.sign(txns);
  };

  return (
    <SignerAppContext.Provider
      value={{
        connect,
        disconnect,
        refresh,
        sign,
        session,
        isConnected: Boolean(session),
      }}
    >
      {children}
    </SignerAppContext.Provider>
  );
}

export const useSignerApp = () => useContext(SignerAppContext);
