import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useFeatureFlags } from '@opulous/web/src/hooks/useFeatureFlags';

interface FeatureProps {
  children: ReactNode;
  flagName: string;
  redirectTo?: string;
}

const Feature: React.FC<FeatureProps> = ({
  children,
  flagName,
  redirectTo,
}) => {
  const { isActive, isLoading } = useFeatureFlags();

  if (isActive(flagName)) {
    return <>{children}</>;
  }

  if (redirectTo && !isLoading) {
    return <Navigate to={redirectTo} />;
  }

  return null;
};

export default Feature;
