import { ReactElement } from 'react';
import { styled } from '@mui/system';
import {
  Select,
  MenuItem,
  FormControl,
  IconButton
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: `${(theme.shadows as string[])[12]}`,
  borderRadius: theme.spacing(4),
  padding: theme.spacing(0, 1),
  'fieldset > legend': {
    marginLeft: theme.spacing(1),
  }
}));
const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

type Props = {
  'data-testid'?: string,
  title: string,
  value?: any,
  options: { key: string, value: string }[],
  onChange: (option?: string) => void,
}
export default function SelectComponent(props: Props): ReactElement {
  const { title, value, options, onChange } = props;
  const testId = props['data-testid'] || 'select-component';

  return (
    <FormControl fullWidth>
      <StyledInputLabel 
        id={`${testId}__label`}
        data-testid={`${testId}__label`}
      >
        {title}
      </StyledInputLabel>
      <StyledSelect
        id={`${testId}`}
        data-testid={`${testId}`}
        labelId={`${testId}__label`}
        label={title}
        value={value || ''}
        onChange={(e) => onChange(e.target.value as string)}
        sx={{'& .MuiSelect-iconOutlined': {display: value ? 'none': ''}}}
        endAdornment={<IconButton data-testid={`${testId}__remove-button`} sx={{display: value ? '' : 'none'}} onClick={() => onChange()}>&times;</IconButton>}
      >
        {options.map((it, ix) => 
          <MenuItem key={`option-${ix}`} value={it.key}>{it.value}</MenuItem>
        )}
      </StyledSelect>
    </FormControl>
  );
}
