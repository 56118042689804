import algosdk from 'algosdk'
import { ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import { OptIn } from '@opulous/web/src/components/Claims'
import WalletContext from '@opulous/web/src/context/context'
import myAlgoService from '@opulous/web/src/services/my-algo'
import assetsService from '@opulous/web/src/services/assets'
import {
  AssetContractMetadata,
  WalletTypeEnum,
} from '@opulous/web/src/./shared/types'
import * as Sentry from '@sentry/react'
import PeraInfoModal from '@opulous/web/src/components/Wallet/PeraInfoModal'
import { isMobile } from 'react-device-detect'
import { algorandErrorString } from 'src/services/my-algo/errors'

const StyledBox = styled(Box)(() => ({
  height: 'calc(100vh - 80px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export default function ClaimsPage(): ReactElement {
  const [assetsAvailableToClaim, setAssetsAvailableToClaim] = useState<
    AssetContractMetadata[]
  >([])
  const [unclaimedAssetsAndOptInTxns, setUnclaimedAssetsAndOptInTxns] =
    useState<{
      unclaimedAssets: AssetContractMetadata[]
      optInTransactions: algosdk.Transaction[]
    }>({ unclaimedAssets: [], optInTransactions: [] })
  const [error, setError] = useState<string>('')
  const [info, setInfoMessage] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isClaiming, setClaiming] = useState<boolean>(false)
  const [peraDialogOpen, setPeraDialogState] = useState<boolean>(false)

  const navigate = useNavigate()
  const {
    state: { wallet, connector, walletType },
  } = useContext(WalletContext)

  useEffect(() => {
    const fetchUnclaimedAssets = async () => {
      setLoading(true)
      try {
        Sentry.addBreadcrumb({
          category: '<ClaimPage /> : fetchUnclaimedAssets',
          message:
            'Loading unclaimed assets and filter assets that need to Optin',
          type: 'info',
          data: {
            wallet,
            walletType,
          },
        })
        setInfoMessage('Fetching account information...')
        const accountInformation = await myAlgoService.getAccountInformation(
          wallet as string,
        )
        const ownedAssetIds = (accountInformation.assets || []).map(
          oa => oa['asset-id'],
        )

        setInfoMessage('Fetching available tokens...')
        const assetsAvailableToClaimFetched =
          await assetsService.fetchAllAssetsAvailableToClaim(wallet as string)
        setAssetsAvailableToClaim(
          assetsAvailableToClaimFetched.filter(
            a => Object.keys(a).length !== 0,
          ),
        )

        const unclaimedAssets: AssetContractMetadata[] = []
        assetsAvailableToClaimFetched.forEach(it => {
          if (Object.keys(it).length !== 0) {
            unclaimedAssets.push(it)
          }
        })

        if (unclaimedAssets.length) {
          const assetsIdsToOptin = unclaimedAssets
            .reduce((all, it) => {
              it.contracts.forEach(c => {
                if (!all.includes(c.assetId)) all.push(c.assetId)
              })
              return all
            }, [] as number[])
            .filter(id => !ownedAssetIds.includes(id))
          const optInTransactions = assetsIdsToOptin.length
            ? await myAlgoService.makeOptInTransactions({
                assets: assetsIdsToOptin,
                wallet,
              })
            : []
          setUnclaimedAssetsAndOptInTxns({
            unclaimedAssets,
            optInTransactions,
          })
        }

        setInfoMessage('')
        setLoading(false)
      } catch (ex) {
        setUnclaimedAssetsAndOptInTxns({
          unclaimedAssets: [],
          optInTransactions: [],
        })
        setLoading(false)
        setInfoMessage('')
        Sentry.captureException(ex)
        setError(algorandErrorString(ex) || 'An unexpected error ocurred. Please try again')
      }
    }

    fetchUnclaimedAssets()
  }, [])

  const handleOptin = async () => {
    setError('')
    setClaiming(true)
    //  handle errors
    setInfoMessage('Opting in to your assets...')
    try {
      Sentry.addBreadcrumb({
        category: '<ClaimPage /> : handleOptin',
        message: 'Opting in assets belongs to wallet cennected',
        type: 'info',
        data: {
          wallet,
          walletType,
        },
      })

      if (unclaimedAssetsAndOptInTxns.optInTransactions.length > 0) {
        if (walletType === WalletTypeEnum.peraWallet) {
          Sentry.addBreadcrumb({
            category: '<ClaimPage /> : handleOptin',
            message: 'Displaying Pera mobile dialog',
            type: 'info',
            data: {
              wallet,
              walletType,
              connector,
              isMobile,
            },
          })
          setPeraDialogState(true)
        }

        const signedTxns = await myAlgoService.signTransactions(
          unclaimedAssetsAndOptInTxns.optInTransactions,
          connector,
          walletType,
        );
        const { data: { result: { txId } } } = await myAlgoService.sendSignedTransactions(signedTxns);
        await myAlgoService.waitForTransactions([txId]);
      }
    } catch (ex) {
      setClaiming(false)
      Sentry.captureException(ex)
      setError(algorandErrorString(ex) || 'An error ocurred while opting in for you assets')
      setInfoMessage('')
      return
    }
    setInfoMessage('Opt-In was successful! Transferring your assets now…')

    try {
      await assetsService.claimAssets({
        assets: unclaimedAssetsAndOptInTxns.unclaimedAssets.reduce(
          (all, it) => {
            it.contracts.forEach(c => all.push(c.assetId))
            return all
          },
          [] as number[],
        ),
        wallet,
      })
    } catch (ex) {
      Sentry.captureException(ex)
      setClaiming(false)
      setInfoMessage('')
      setError(algorandErrorString(ex) || 'An error ocurred while transferring your assets')
      return
    }

    navigate('/assets')
  }

  return (
    <StyledBox data-testid="claim-page">
      <OptIn
        onOptIn={handleOptin}
        assets={assetsAvailableToClaim}
        wallet={wallet}
        errorText={error}
        infoText={info}
        loading={isLoading}
        claiming={isClaiming}
      />
      <PeraInfoModal
        open={peraDialogOpen}
        onClose={() => setPeraDialogState(false)}
      />
    </StyledBox>
  )
}
