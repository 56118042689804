import BigNumber from 'bignumber.js';

export default class USDC extends BigNumber {
  static ZERO = new this('0');
  static MICRO_DECIMAL = 6;

  private constructor(amount: string | BigNumber) {
    super(amount);
    if (isNaN(Number(amount))) {
      throw Error('The amount must a number valid.');
    }
  }

  static fromMicroUSDC(amountMicroUSDC: bigint): USDC {
    return new this(
      new BigNumber(amountMicroUSDC.toString()).dividedBy(
        Math.pow(10, USDC.MICRO_DECIMAL),
      ),
    );
  }

  static create(amount: number): USDC {
    return new this(new BigNumber(amount));
  }

  plus(amount: number | string | BigNumber): USDC {
    return new USDC(super.plus(amount).decimalPlaces(USDC.MICRO_DECIMAL));
  }

  minus(amount: number | string | BigNumber): USDC {
    return new USDC(super.minus(amount).decimalPlaces(USDC.MICRO_DECIMAL));
  }

  div(amount: number | string | BigNumber): USDC {
    return new USDC(super.div(amount).decimalPlaces(USDC.MICRO_DECIMAL));
  }

  dividedBy(amount: number | string | BigNumber): USDC {
    return new USDC(super.dividedBy(amount).decimalPlaces(USDC.MICRO_DECIMAL));
  }

  times(amount: number | string | BigNumber): USDC {
    return new USDC(super.times(amount).decimalPlaces(USDC.MICRO_DECIMAL));
  }

  multipliedBy(amount: number | string | BigNumber): USDC {
    return new USDC(super.multipliedBy(amount).decimalPlaces(USDC.MICRO_DECIMAL));
  }
}
