import algosdk from 'algosdk'
import { getApiBaseUrl } from '@opulous/web/src/axios-setup'
import MyAlgo, { SignedTx } from '@randlabs/myalgo-connect'
import * as Sentry from '@sentry/react'
import { PoolVersion, WalletTypeEnum } from '../shared/types'
import { PeraWalletConnect } from '@perawallet/connect'

function getAlgoClient() {
  const baseUrl = getApiBaseUrl()
  const algoPort = baseUrl.indexOf('localhost') >= 0 ? '4000' : ''
  const algodClient = new algosdk.Algodv2(
    '',
    `${baseUrl}/proxy/rewrite`,
    algoPort
  )
  return algodClient
}

async function signWithMyAlgoWallet(
  transaction: algosdk.Transaction
): Promise<SignedTx> {
  const myAlgoWallet = new MyAlgo()
  return myAlgoWallet.signTransaction(transaction.toByte())
}

export async function signMultipleTransactionsWithPeraWallet(
  transactions: algosdk.Transaction[],
  connector: PeraWalletConnect,
  ): Promise<SignedTx[]> {
  try {
    await connector.reconnectSession().catch(Sentry.captureException);

    const results = await connector.signTransaction([
      transactions.map(txn => ({ txn })),
    ]);

    return results
      .filter(bytes => bytes?.length)
      .map(bytes => {
        const decodedMessage = algosdk.decodeSignedTransaction(Buffer.from(bytes));
        const transaction = decodedMessage.txn as unknown as algosdk.Transaction;
        const txID = transaction.txID()

        return {
          txID,
          blob: bytes,
        };
      });
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
}

export async function signSingleTransactionsWithPeraWallet(
  transaction: algosdk.Transaction,
  connector: PeraWalletConnect
): Promise<SignedTx> {
  try {
    const results = await signMultipleTransactionsWithPeraWallet([transaction], connector);
    if (!results.length) {
      throw new Error('Empty result from Pera wallet transaction signing');
    }
    return results[0];
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

type MakeTXProps = {
  fromAddress: string
  toAddress: string
  assetId: number
  amount: number
}

export async function makeAssetTransferTxn({
  fromAddress,
  toAddress,
  assetId,
  amount,
}: MakeTXProps): Promise<algosdk.Transaction> {
  const algodClient = getAlgoClient()
  const transactionParams = await algodClient.getTransactionParams().do()
  const transaction = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject(
    {
      suggestedParams: {
        ...transactionParams,
      },
      from: fromAddress,
      to: toAddress,
      assetIndex: assetId,
      amount,
      note: new Uint8Array(Buffer.from(`${assetId}`)),
    }
  )
  return transaction
}

type SignTransactionsProps = {
  walletType?: WalletTypeEnum
  connector?: PeraWalletConnect
  transaction: algosdk.Transaction
}
export async function signTransaction({
  walletType,
  connector,
  transaction,
}: SignTransactionsProps): Promise<SignedTx> {
  if (WalletTypeEnum.myAlgoWallet === walletType) {
    return await signWithMyAlgoWallet(transaction)
  } else if (WalletTypeEnum.peraWallet === walletType) {
    if (!connector) {
      throw new Error('connector is required for Pera wallet');
    }
    return await signSingleTransactionsWithPeraWallet(transaction, connector)
  } else {
    throw new Error('Wallet type not supported...')
  }
}

type PoolOptinTXProps = {
  from: string
  applicationId: number
}

export async function makePoolOptInTransaction({
  from,
  applicationId,
}: PoolOptinTXProps): Promise<algosdk.Transaction> {
  const algodClient = getAlgoClient()
  const transactionParams = await algodClient.getTransactionParams().do()

  const txn = algosdk.makeApplicationOptInTxn(
    from,
    transactionParams,
    applicationId
  )

  return txn
}

type makePoolWithdrawTransactionProps = {
  tokenId: number
  from: string
  appId: number
  stakeIndex: number
  poolType: 'opul_staking'|'opul_staking_v2'
}

export async function makePoolWithdrawTransaction({
  tokenId,
  from,
  appId,
  stakeIndex,
  poolType,
}: makePoolWithdrawTransactionProps): Promise<algosdk.Transaction> {
  const algodClient = getAlgoClient()
  const suggestedParams = await algodClient.getTransactionParams().do()
  suggestedParams.flatFee = true

  switch (poolType) {
    case PoolVersion.v1: {
      suggestedParams.fee = 2 * algosdk.ALGORAND_MIN_TX_FEE;
      break;
    }
    case PoolVersion.v2: {
      suggestedParams.fee = 3 * algosdk.ALGORAND_MIN_TX_FEE;
      break;
    }
    default: {
      const err = new Error(`Unknown pool version: ${poolType}`);
      Sentry.captureException(err);
      throw err;
    }
  }

  const txn = algosdk.makeApplicationNoOpTxnFromObject({
    from,
    suggestedParams: suggestedParams,
    appIndex: appId,
    appArgs: [
      new Uint8Array(Buffer.from('withdraw')),
      algosdk.encodeUint64(Number(stakeIndex)),
    ],
    foreignAssets: [tokenId],
  })

  return txn
}

async function signVestingAppCallWithPeraWallet(
  transactions: algosdk.Transaction[],
  connector: PeraWalletConnect
): Promise<SignedTx> {
  const results = await signMultipleTransactionsWithPeraWallet(transactions, connector);
  if (!results.length) {
    throw new Error('Empty result from Pera wallet transaction signing');
  }
  return results[0];
}

type signVestingAppCallTransactionProps = {
  walletType?: WalletTypeEnum
  connector?: PeraWalletConnect
  transactions: algosdk.Transaction[]
}
export async function signVestingAppCallTransaction({
  walletType,
  connector,
  transactions,
}: signVestingAppCallTransactionProps): Promise<SignedTx> {
  let signedTransaction: SignedTx
  if (WalletTypeEnum.myAlgoWallet === walletType) {
    // For MyAlgo wallet it is necessary to pass just appCall transaction
    signedTransaction = await signWithMyAlgoWallet(transactions[0])
  } else if (WalletTypeEnum.peraWallet === walletType) {
    if (!connector) {
      throw new Error('connector is required for Pera wallet');
    }
    // For Pera wallet it is necessary to pass all transactions of the group
    signedTransaction = await signVestingAppCallWithPeraWallet(transactions, connector)
  } else {
    throw new Error('Wallet type not supported...')
  }
  return signedTransaction
}
