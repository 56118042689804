import { ReactElement } from 'react';
import { Stack, Typography, Button, Link, useTheme } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { styled } from '@mui/system';
import OPULLabel from '@opulous/web/src/components/shared/OPULLabel';
import MinorOPUL from '@opulous/web/src/shared/valueObjects/MinorOPUL';
import AcceptIcon from '@opulous/web/src/components/shared/AcceptIcon';
import config from 'src/config';

const LegendContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1.5),
}));

const OpulToBeClaimedValue = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontSize: theme.spacing(5),
  marginBottom: theme.spacing(0.5),
  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(4),
  },
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(3.5),
  },
}));

const AcceptIconFullyClaimed = styled(AcceptIcon)(() => ({
  marginRight: 10,
}));

const StyledOPULLabel = styled(OPULLabel)(({ theme }) => ({
  fontSize: theme.spacing(5),
  lineHeight: theme.spacing(4.5),
  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(4),
  },
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(3.5),
  },
}));
const OpulToBeClaimedLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontWeight: 400,
  fontSize: theme.spacing(2.4),
  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(2.2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: theme.spacing(2.2),
  height: theme.spacing(5),
  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(1.8),
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.spacing(3),
  padding: theme.spacing(1, 3, 1, 3),
  color: theme.palette.info.main,
  fontWeight: 600,

  fontSize: theme.spacing(2.2),
  height: theme.spacing(5),
  textDecoration: 'none',

  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(1.8),
  },

  '&:hover': {
    color: theme.palette.info.main,
  },
  svg: {
    fontSize: theme.spacing(2.2),
    marginLeft: theme.spacing(1),
  },
}));

type Props = {
  address: string;
  optedIn: boolean;
  fullyClaimed: boolean;
  toClaimOpul: MinorOPUL;
  optInTokens: () => void;
  claimTokens: () => void;
};
export default function Legend({
  address,
  optedIn,
  fullyClaimed,
  toClaimOpul,
  optInTokens,
  claimTokens,
}: Props): ReactElement {
  const theme = useTheme();
  const CLAIMING_HISTORY_LINK = `${config.env.ALGO_EXPLORER_URL}/address/${address}`;
  const disableClaimButton = toClaimOpul.isEqualTo(0);

  return (
    <LegendContainer data-testid="vesting-legend">
      <Stack flexDirection={'column'}>
        {fullyClaimed ? (
          <OpulToBeClaimedValue
            variant="h1"
            data-testid="vesting-legend__fully-claimed"
          >
            <AcceptIconFullyClaimed
              data-testid="vesting-legend__fully-claimed__icon"
              fill={theme.palette.error.light}
              stroke={theme.palette.error.dark}
            />
            Fully Claimed
          </OpulToBeClaimedValue>
        ) : (
          <>
            <OpulToBeClaimedValue
              variant="h1"
              data-testid="vesting-legend__value"
            >
              <StyledOPULLabel value={toClaimOpul}></StyledOPULLabel>
              <span style={{ marginLeft: '0.5rem' }}>OPUL</span>
            </OpulToBeClaimedValue>
            <OpulToBeClaimedLabel
              variant="h4"
              data-testid="vesting-legend__label"
            >
              To be claimed
            </OpulToBeClaimedLabel>
          </>
        )}
      </Stack>
      <Stack flexDirection={'column'} alignItems={'center'}>
        {!fullyClaimed &&
          (optedIn ? (
            <StyledButton
              data-testid="vesting-legend__button"
              variant="contained"
              size="small"
              color="primary"
              disabled={disableClaimButton}
              onClick={claimTokens}
            >
              Claim tokens
            </StyledButton>
          ) : (
            <StyledButton
              data-testid="vesting-legend__button"
              variant="contained"
              size="small"
              color="primary"
              onClick={optInTokens}
            >
              Opt-in tokens
            </StyledButton>
          ))}
        <StyledLink
          href={CLAIMING_HISTORY_LINK}
          target="_blank"
          data-testid="vesting-legend__history-link"
        >
          History
          <LaunchIcon />
        </StyledLink>
      </Stack>
    </LegendContainer>
  );
}
