import { ReactElement, useCallback } from 'react';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { openBuyOpulPage } from '@opulous/web/src/shared/externalLinks';

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: theme.spacing(70),
  marginTop: theme.spacing(10),
  borderRadius: theme.spacing(3),
  backgroundColor: '#FCF2EC',
}));
const Title = styled('p')(({ theme }) => ({
  margin: theme.spacing(2),
  fontSize: theme.spacing(5.5),
  color: theme.palette.text.dark,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(3.5),
  },
}));
const Details = styled('p')(({ theme }) => ({
  margin: theme.spacing(2),
  fontSize: theme.spacing(3),
  maxWidth: theme.spacing(52),
  textAlign: 'center',
  lineHeight: '150%',
  color: theme.palette.text.dark,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(2.5),
  },
}));
const BuyButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  margin: theme.spacing(1),
  boxShadow: 'none',
}));
const StakeButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  margin: theme.spacing(1),
  color: theme.palette.grey[700],
  border: `2px solid ${theme.palette.grey[700]}`,
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '&:hover, &:active': {
    border: `2px solid ${theme.palette.grey[700]}`,
    color: theme.palette.grey[700],
    backgroundColor: 'transparent',
  },
}));
const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export default function BuyAndStake(): ReactElement {
  const navigate = useNavigate();

  const navigateToDetailsPage = useCallback(() => {
    navigate('/pools/');
  }, []);

  const handlePurchase = () => {
    openBuyOpulPage();
  };

  return (
    <>
      <Wrapper data-testid="sales__buy-and-stake">
        <Title data-testid="sales__buy-and-stake__title">
          <strong>Buy</strong> and <strong>Stake OPUL</strong>
        </Title>
        <Details data-testid="sales__buy-and-stake__details">
          To get early access to MFT sales and other{' '}
          <strong>exclusive benefits</strong>.
        </Details>
        <Actions>
          <BuyButton
            data-testid="sales__buy-and-stake__buy-button"
            onClick={handlePurchase}
          >
            Buy Opul
          </BuyButton>
          <StakeButton
            data-testid="sales__buy-and-stake__stake-button"
            onClick={() => navigateToDetailsPage()}
            variant="contained"
            color="primary"
          >
            Stake OPUL
          </StakeButton>
        </Actions>
      </Wrapper>
    </>
  );
}
