import { io, Socket } from 'socket.io-client';
import {
  events,
  ClientToServerEvents,
  ServerToClientEvents,
  Session,
} from './types';

type OnConnectCallback = (session: Session) => void;
type OnDisconnectCallback = () => void;

interface SignerAppConnectParams {
  port: number;
  onConnect?: OnConnectCallback;
  onDisconnect?: OnDisconnectCallback;
}

type Args = any[];
type SyncListener = (...args: Args) => any;
type AsyncListener = (...args: Args) => Promise<any>;
type Listerner = SyncListener | AsyncListener;

const errorHandler = (listener: Listerner) => {
  const handleError = (err: unknown) => {
    console.error(err);
  };

  return (...args: Args) => {
    try {
      const ret = listener.apply(this, args);
      if (ret && typeof ret.catch === 'function') {
        // async handler
        ret.catch(handleError);
      }
    } catch (e) {
      // sync handler
      handleError(e);
    }
  };
};

export class SignerAppConnect {
  private socket: Socket<ServerToClientEvents, ClientToServerEvents>;
  private MAX_RETRIES = 5;
  private connectRetriesCounter = 0;
  private onConnect?: OnConnectCallback;
  private onDisconnect?: OnDisconnectCallback;

  private registerDisconnectListener() {
    if (!this.socket) return;

    const onDisconnect = () => {
      if (this.onDisconnect) this.onDisconnect();
    };

    this.socket.on(events.DISCONNECT, errorHandler(onDisconnect));
  }

  private registerListeners() {
    if (!this.socket) return;

    this.socket.removeAllListeners();
    this.registerDisconnectListener();
  }

  constructor({ port, onConnect, onDisconnect }: SignerAppConnectParams) {
    this.socket = io(`ws://127.0.0.1:${port}`, {
      autoConnect: false,
      reconnection: false,
    });
    this.onConnect = onConnect;
    this.onDisconnect = onDisconnect;
  }

  disconnect() {
    this.socket.disconnect();
  }

  connect(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.socket) {
        return reject('socket is not defined');
      }

      this.registerListeners();

      const onConnectError = () => {
        this.connectRetriesCounter++;
        if (this.connectRetriesCounter >= this.MAX_RETRIES) {
          this.socket.disconnect();
          return reject('maximum retries reached');
        }
        setTimeout(() => this.socket.connect(), 2000);
      };

      const onConnectResponse = (session: Session) => {
        this.connectRetriesCounter = 0;

        if (!session) return reject('connect error');

        if (this.onConnect) this.onConnect(session);

        return resolve(session);
      };

      this.socket.on(events.CONNECT_ERROR, errorHandler(onConnectError));
      this.socket.once(
        events.CONNECT_RESPONSE,
        errorHandler(onConnectResponse),
      );

      this.connectRetriesCounter = 0;
      this.socket.connect();
    });
  }

  sign(txns: Buffer[]): Promise<Buffer[]> {
    return new Promise((resolve, reject) => {
      if (!this.socket.connected) {
        return reject('not connected');
      }

      const signResponseCallback = (signedTxns: Buffer[]) => {
        if (!signedTxns) return reject('sign error');

        return resolve(signedTxns);
      };

      this.socket.emit(events.SIGN_REQUEST, txns, signResponseCallback);
    });
  }

  getSession(): Promise<Session> {
    return new Promise((resolve, reject) => {
      if (!this.socket?.connected) {
        return reject('not connected');
      }

      const getSessionCallback = (session: Session) => {
        if (!session) {
          this.socket.disconnect();
          return reject('getSession error');
        }

        return resolve(session);
      };

      this.socket.emit(events.GET_SESSION_REQUEST, getSessionCallback);
    });
  }
}
