import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import assetsService from '@opulous/web/src/services/assets';
import ConnectWallet from '@opulous/web/src/components/Common/ConnectWallet';
import WalletContext from '@opulous/web/src/context/context';
import { WalletTypeEnum } from '@opulous/web/src/shared/types';
import MyAlgoWarningDialog from 'src/components/shared/MyAlgoWarningDialog';

const StyledBox = styled(Box)(() => ({
  height: 'calc(100vh - 80px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const LoginPage: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  const {
    state: { wallet, walletType },
    initPeraWallet,
  } = useContext(WalletContext);
  const [shouldShowMyAlgoWarningDialog, setShouldShowMyAlgoWarningDialog] = useState(false);

  const NewWalletTimeout = 1 * 60 * 1000;

  useEffect(() => {
    Sentry.addBreadcrumb({
      category: '<LoginPage />',
      message: 'useEffect called on wallet change',
      type: 'info',
      data: {
        wallet,
        walletType,
      },
    });
    const tryNavigate = async () => {
      try {
        const unclaimedAssets = await assetsService.fetchAllAssetsAvailableToClaim(wallet);

        Sentry.addBreadcrumb({
          category: '<LoginPage /> : tryNavigate',
          message: 'Fetched unclaimed assets',
          type: 'info',
          data: {
            unclaimedAssets,
            wallet,
            walletType,
          },
        });
        if (unclaimedAssets.length) {
          navigate('/claim');
        } else {
          navigate('/');
        }
      } catch (ex) {
        setError('An error occurred checking your assets');
        Sentry.captureException(ex);
      }
    };
    if (!wallet || !walletType?.trim()) {
      Sentry.addBreadcrumb({
        category: '<LoginPage /> : tryNavigate',
        message: 'Skipping redirect: wallet or walletType are missing',
        type: 'info',
        data: {
          wallet,
          walletType,
        },
      });
      return;
    }

    tryNavigate();
  }, [wallet, walletType]);

  const connectWallet = async (type: WalletTypeEnum) => {
    Sentry.addBreadcrumb({
      category: '<LoginPage /> : connectWallet',
      message: 'connectWallet called',
      type: 'info',
      data: {
        type,
        wallet,
      },
    });
    switch (type) {
      case WalletTypeEnum.myAlgoWallet:
        setShouldShowMyAlgoWarningDialog(true);
        return;
      case WalletTypeEnum.peraWallet:
        initPeraWallet();
        return;
      default:
        return;
    }
  };

  const handleConnect = async (type: WalletTypeEnum) => {
    // let addresses: string[] | undefined = []
    setLoading(true);
    try {
      setTimeout(() => {
        setLoading(false);
        setError('');
      }, NewWalletTimeout);

      connectWallet(type);
      // if (addresses === undefined) {
      //   setLoading(false)

      //   setError('No wallet selected')
      // }
    } catch (ex) {
      // setLoading(false)
      // Sentry.captureException(ex)
      // setError('An unexpected error ocurred, please try again')
    }

    // if (!addresses) {
    //   setError('No wallet selected')
    //   setLoading(false)

    //   return
    // } else if (!!addresses && addresses.length === 0) {
    //   setError('No wallets available')
    //   setLoading(false)

    //   return
    // }
  };

  return (
    <StyledBox>
      <ConnectWallet
        onWalletSelect={handleConnect}
        loading={isLoading}
        errorText={error}
      />
      <MyAlgoWarningDialog
        show={shouldShowMyAlgoWarningDialog}
        onClose={() => setShouldShowMyAlgoWarningDialog(false)}
      />
    </StyledBox>
  );
};

export default LoginPage;
