import { ReactElement } from 'react';
import { styled } from '@mui/system';
import { Card, Skeleton, Typography, useTheme } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  borderRadius: theme.spacing(2),
  cursor: 'pointer',
  minHeight: theme.spacing(8),
  height: theme.spacing(58),
}));

const StyledMedia = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2.5, 2.5, 1, 2.5),
}));
const Status = styled('div')<{ color: string }>(({ color, theme }) => ({
  position: 'absolute',
  top: theme.spacing(5),
  left: theme.spacing(5),
  padding: theme.spacing(0.2, 1.4, 0.2, 1.4),
  borderRadius: theme.spacing(2),
  fontSize: theme.spacing(1.3),
  fontWeight: 'bold',
  backgroundColor: color,
}));
const State = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: theme.spacing(-7, 2, 2, 2),
  padding: theme.spacing(0.8, 2, 0.8, 2),
  borderRadius: theme.spacing(1),
  fontSize: theme.spacing(1.8),
  fontWeight: 'bold',
  backgroundColor: theme.palette.common.white,
}));
const StyledImage = styled('div')(({ theme }) => ({
  width: '100%',
  height: theme.spacing(43),
  backgroundColor: 'rgba(0, 0, 5, 0.11)',
  borderRadius: theme.spacing(2),
}));

const DetailsContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(2.5),
  transition: 'margin-top 1s',
  marginTop: 0,
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(0),
}));
const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 'bold',
  marginBottom: theme.spacing(0),
}));
const Artist = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 'normal',
}));

export default function SkeletonCard(): ReactElement {
  const theme = useTheme();
  return (
    <StyledCard data-testid="skeleton-investment-card">
      <StyledMedia>
        <Status color={theme.palette.common.white}>
          <Skeleton variant="text" width={70} height={30} />
        </Status>
        <StyledImage></StyledImage>
        <State>
          <Skeleton variant="text" width={100} height={30} />
          <Skeleton variant="text" width={100} height={30} />
        </State>
      </StyledMedia>
      <DetailsContainer>
        <Title>
          <Skeleton variant="text" width={'70%'} height={50} />
        </Title>
        <Artist>
          <Skeleton variant="text" width={'70%'} height={30} />
        </Artist>
      </DetailsContainer>
    </StyledCard>
  );
}