import { ReactElement, useMemo, useState } from 'react';
import { isBefore, intervalToDuration, differenceInDays } from 'date-fns';
import { alpha, styled } from '@mui/system';
import { LinearProgress, Box, IconButton, Dialog, Typography, Button, CircularProgress, Tooltip } from '@mui/material';
import { Investment, InvestmentStatus } from '@opulous/web/src/shared/types';
import { formatCurrency } from '@opulous/web/src/utils/index';
import InvestmentStepper from '@opulous/web/src/components/Sales/InvestmentStepper';
import InvestmentStatusChip from '@opulous/web/src/components/Sales/InvestmentStatus';
import _DeleteIcon from '@mui/icons-material/Delete';
import _FileDownloadIcon from '@mui/icons-material/FileDownload';
import _EditIcon from '@mui/icons-material/Edit';
import { Artist, Title } from './styled-components';
import { useNavigate } from 'react-router-dom';
import * as SaleService from 'src/services/sales';

const StyledInvestmentStatusChip = styled(InvestmentStatusChip)(
  ({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    padding: theme.spacing(.7, 1.4),
  }),
);

const State = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  bottom: theme.spacing(3),
  left: theme.spacing(2),
  right: theme.spacing(2),
  position: 'absolute',
  padding: theme.spacing(0.8, 2, 0.8, 2),
  borderRadius: theme.spacing(1),
  fontSize: theme.spacing(1.8),
  backgroundColor: theme.palette.common.white,
}));

const InfoList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.spacing(1.6),
  marginTop: theme.spacing(2.5),
}));
const InfoRaised = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0.8, 0),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));
const InfoRaisedLabels = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 0),
  'span:nth-of-type(2)': {
    fontWeight: 'bold',
  },
}));
const InfoStepper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.spacing(1),
}));
const Info = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 0),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  'span:nth-of-type(2)': {
    fontWeight: 'bold',
  },
}));
const LinearProgressContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0, 0.8, 0),
}));
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: theme.spacing(1),
  borderRadius: theme.spacing(1),
  background: alpha(theme.palette.primary.main, 0.2),
  '& .MuiLinearProgress-bar': {
    background: theme.palette.primary.main,
  },
}));

export const ContentContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(3),
  gridTemplateColumns: 'repeat(auto-fit, minmax(14rem, 1fr))',
}));

export const Card = styled('div')(({ theme }) => ({
  boxShadow: `0px 2px 13px -3px ${theme.palette.grey[300]}`,
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
}));

const EditIcon = styled(_EditIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  fill: theme.palette.primary.main,
}));

const DeleteIcon = styled(_DeleteIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  fill: theme.palette.primary.main,
}));

const FileDownloadIcon = styled(_FileDownloadIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  fill: theme.palette.primary.main,
}));

const Image = styled('img')`
  width: 100%;
  border-radius: ${props => props.theme.spacing(2)};
`;

function downloadCSV(csv: string, fileName: string) {
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const el = document.createElement('a');
  el.setAttribute('target', '_blank');
  el.setAttribute('href', url);
  el.setAttribute('download', fileName);
  el.click();
}

export default function AdminInvestmentCard({
  investment,
  onInvestmentRefresh,
}: {
  investment: Investment
  onInvestmentRefresh: () => Promise<void>
}): ReactElement {
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [investmentToBeDeleted, setInvestmentToBeDeleted] = useState<Investment|null>(null);
  const [isFetchingSaleData, setIsFetchingSaleData] = useState(false);
  const [isFetchingSaleIssuanceData, setIsFetchingSaleIssuanceData] = useState(false);

  const now = new Date();
  const state = {
    upcoming: isBefore(now, investment.preSaleStartDate || investment.saleStartDate),
    preSale: investment.preSaleStartDate ? isBefore(now, investment.saleStartDate) : false,
    sale: isBefore(now, investment.saleEndDate),
  };

  const IntervalLegend = useMemo(() => {
    const baseDate = state.upcoming
      ? (investment.preSaleStartDate || investment.saleStartDate)
      : state.preSale
      ? investment.saleStartDate
      : investment.saleEndDate;

    const interval = intervalToDuration({
      start: new Date(),
      end: baseDate,
    });

    if (interval.days) {
      if (interval.months) {
        interval.days = differenceInDays(baseDate, new Date());
      }
      if (interval.days > 1) {
        return `${interval.days} days`;
      }
      return `${interval.days} day`;
    }
    if (interval.hours) {
      if (interval.hours > 1) {
        return `${interval.hours} hours`;
      }
      return `${interval.hours} hour`;
    }

    if ((interval.minutes || 0) > 1) {
      return `${interval.minutes} minutes`;
    }
    return `${interval.minutes} minute`;
  }, [state]);

  const handleEdit = () => navigate(`/admin/sales/${encodeURIComponent(investment.id)}/edit`);

  const handleInvestmentDelete = (r: Investment) => () => setInvestmentToBeDeleted(r);
  const handleInvestmentDeletionConfirm = async () => {
    setIsDeleting(true);
    investmentToBeDeleted && await SaleService.adminDeleteInvestment(investmentToBeDeleted.id);
    setIsDeleting(false);
    setInvestmentToBeDeleted(null);
    await onInvestmentRefresh();
  };
  const handleInvestmentDeletionDialogClose = () => !isDeleting && setInvestmentToBeDeleted(null);

  const handleSaleDataExport = async () => {
    setIsFetchingSaleData(true);
    const ts = new Date().toISOString().replace(/(\.\d+)Z$/g, '').replace(/[^\d]/g, '_');
    const csv = await SaleService.adminExportInvestmentData(investment.id);
    setIsFetchingSaleData(false);
    const fileName = `sale_${investment.id}_${ts}.csv`;
    downloadCSV(csv, fileName);
  };

  const handleSaleIssuanceDataExport = async () => {
    setIsFetchingSaleIssuanceData(true);
    const ts = new Date().toISOString().replace(/(\.\d+)Z$/g, '').replace(/[^\d]/g, '_');
    const csv = await SaleService.adminExportInvestmentIssuanceData(investment.id);
    setIsFetchingSaleIssuanceData(false);
    const fileName = `sale_issuance_${investment.id}_${ts}.csv`;
    downloadCSV(csv, fileName);
  };

  return (<>
    <Card>
      <ContentContainer>
        <Box display="flex" flexDirection="column" justifyContent="center" position="relative">
          <Box width="100%" position="relative">
            <StyledInvestmentStatusChip status={investment.status} soldOut={investment.soldOut} />
            <Image src={investment.asset?.nftDescription?.image} />
            {![InvestmentStatus.COMPLETED].includes(investment.status) && (
              <State>
                <span>
                  {state.upcoming ? 'Starts' : 'Ends'} in
                </span>
                <span>{IntervalLegend}</span>
              </State>
            )}
          </Box>
        </Box>
        <div>
          <Title>{investment.releaseTitle || investment.asset.custom?.title}</Title>
          <Artist>
            {investment.artistName || investment.asset.custom?.artist}
            ({investment.cmsId})
          </Artist>
          <InfoList>
            <InfoRaised>
              <InfoRaisedLabels>
                <span>
                  Raised
                </span>
                <span>
                  {formatCurrency(investment.fundsRaised || 0, '$', 0)} (
                  {investment.fundsRaisedPercent}%)
                </span>
              </InfoRaisedLabels>
              <LinearProgressContainer>
                <StyledLinearProgress
                  variant="determinate"
                  value={investment.fundsRaisedPercent || 0}
                />
              </LinearProgressContainer>
            </InfoRaised>
            <Info>
              <span>Deal type</span>
              <span>{investment.dealType}</span>
            </Info>
            <Info>
              <span>Buyers</span>
              <span>{investment.investors}</span>
            </Info>
            <Info>
              <span>Rewards share</span>
              <span>{investment.rewardsShare}%</span>
            </Info>
            <InfoStepper>
              <InvestmentStepper
                preSaleAt={investment.preSaleStartDate}
                saleAt={investment.saleStartDate}
                endAt={investment.saleEndDate}
              />
            </InfoStepper>
          </InfoList>
        </div>
      </ContentContainer>

      <Box display="flex" justifyContent="flex-end" marginTop="auto" gap={1}>
        <Tooltip title="Export issuance data">
          <IconButton onClick={handleSaleIssuanceDataExport}>
            {isFetchingSaleIssuanceData ? (<CircularProgress size="1rem" />) : (<FileDownloadIcon />)}
          </IconButton>
        </Tooltip>

        <Tooltip title="Export sale data">
          <IconButton onClick={handleSaleDataExport}>
            {isFetchingSaleData ? (<CircularProgress size="1rem" />) : (<FileDownloadIcon />)}
          </IconButton>
        </Tooltip>

        <Tooltip title="Edit">
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton onClick={handleInvestmentDelete(investment)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Card>

    {!!investmentToBeDeleted && (
      <Dialog
        open={!!investmentToBeDeleted}
        maxWidth="sm"
        onClose={handleInvestmentDeletionDialogClose}
      >
        <Box margin={3} display="flex" flexDirection="column" gap={3}>
          <Typography variant="h3">Delete Investment #{investmentToBeDeleted?.id}</Typography>
          <Box>
            Are you sure you want to delete Investment <b>{investmentToBeDeleted?.releaseTitle}</b> (#{investmentToBeDeleted?.id})?
          </Box>
          <Box display="flex" gap={2} justifyContent="center">
            <Button
              color="primary"
              variant="text"
              disabled={isDeleting}
              onClick={handleInvestmentDeletionDialogClose}
            >
              Cancel
            </Button>
            <Button
              color="error"
              disabled={isDeleting}
              onClick={handleInvestmentDeletionConfirm}
            >
              {isDeleting ? (
                <CircularProgress size="1.5rem" />
              ) : 'Confirm'}
            </Button>
          </Box>
        </Box>
      </Dialog>
    )}
  </>);
}
