import axios from 'axios';
import BigNumber from 'bignumber.js';

export enum AlgorandError {
  INSUFFICIENT_ALGO = 'INSUFFICIENT_ALGO',
  MISSING_ASSET = 'MISSING_ASSET',
  UNDERFLOW = 'UNDERFLOW',
  DEAD_TXN = 'DEAD_TXN',
  ALREADY_IN_LEDGER = 'ALREADY_IN_LEDGER',
  ALREADY_OPTED_IN_TO_APP = 'ALREADY_OPTED_IN_TO_APP',
}

export type AlgorandErrorResponse = {
  type: AlgorandError.INSUFFICIENT_ALGO
  txId: string
  address: string
  balance: string
  minBalance: string
} | {
  type: AlgorandError.MISSING_ASSET
  txId: string
  assetId: string
  address: string
} | {
  type: AlgorandError.UNDERFLOW
  txId: string
  subtractedAmount: string
  amount: string
} | {
  type: AlgorandError.DEAD_TXN
  round: string
  validRoundFrom: string
  validRoundTo: string
} | {
  type: AlgorandError.ALREADY_IN_LEDGER
  txId: string
} | {
  type: AlgorandError.ALREADY_OPTED_IN_TO_APP
  txId: string
  address: string
  appId: string
}

export function algorandErrorString(err: any): string {
  if (!axios.isAxiosError(err)) return '';
  if (!Object.values(AlgorandError).includes(err.response?.data?.type)) return '';

  const res: AlgorandErrorResponse = err.response?.data;

  switch (res.type) {
    case AlgorandError.INSUFFICIENT_ALGO: {
      const amount = new BigNumber(res.minBalance).dividedBy(new BigNumber(1e6));
      return `Insufficient ALGO in the wallet, required at least ${amount.toFixed(2, BigNumber.ROUND_UP)}`;
    }
    case AlgorandError.MISSING_ASSET: {
      return `Please opt in to asset ${res.assetId} first`;
    }
    case AlgorandError.UNDERFLOW: {
      const amount = new BigNumber(res.subtractedAmount).dividedBy(new BigNumber(1e10));
      return `Insufficient amount in the wallet, required at least ${amount.toFixed(2, BigNumber.ROUND_UP)}`;
    }
    case AlgorandError.DEAD_TXN: {
      return 'Transaction expired';
    }
    case AlgorandError.ALREADY_IN_LEDGER: {
      return 'Transaction is already in ledger';
    }
    case AlgorandError.ALREADY_OPTED_IN_TO_APP: {
      return `Already opted in to app ${res.appId}`;
    }
  }
}
