import {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useContext,
  FC,
  useMemo,
} from 'react'
import config from 'src/config'

interface FeatureFlags {
  [key: string]: boolean
}

interface FeatureFlagsProviderProps {
  children: ReactNode
}

interface FeatureFlagsContextData {
  featureFlags: FeatureFlags | undefined
  isLoading: boolean
  isActive: (featureFlag: string) => boolean
}

const FeatureFlagsContext = createContext<FeatureFlagsContextData>(
  {} as FeatureFlagsContextData
)

const SalesInternalTestKey = 'sales-internal-test';

export const FeatureFlagsProvider: FC<FeatureFlagsProviderProps> = ({
  children,
}) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>()
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const parseFeatureFlags = (envVar: string | undefined) => {
      if (!envVar) return {}

      const flagsArray = envVar.split(',')
      if (flagsArray.length === 0) return {}

      const parsedflags = flagsArray.reduce((flags, flagString) => {
        const [flagName, value] = flagString.split('=')

        return {
          ...flags,
          ...JSON.parse(`{"${flagName.trim()}":${value.trim()}}`),
        }
      }, {})

      return parsedflags
    }

    const featureFlagsEnvVar = config.env.FEATURE_FLAGS;
    setFeatureFlags(parseFeatureFlags(featureFlagsEnvVar))
  }, [])

  useEffect(() => {
    // NOTE: We need to store the flags to session storage, since React router does not
    //       carry query parameters over
    if (searchParams.get('theme-internal-test') === 'true') {
      sessionStorage.setItem('theme-internal-test', 'true');
    }
    if (searchParams.get('royalty-internal-test') === 'true') {
      sessionStorage.setItem('royalty-internal-test', 'true');
    }
    if (searchParams.get('sales-internal-test') === 'true') {
      sessionStorage.setItem(SalesInternalTestKey, 'true');
    }
  }, [window.location.search]);

  const isLoading = useMemo(() => !featureFlags, [featureFlags])

  function isActive(flagName: string): boolean {
    if (!featureFlags || isLoading) return false

    if (flagName === 'rewards') {
      return featureFlags[flagName] || (
        searchParams.get('royalty-internal-test') === 'true' ||
        sessionStorage.getItem('royalty-internal-test') === 'true'
      );
    } else if (flagName === 'darkTheme') {
      return featureFlags[flagName] || (
        searchParams.get('theme-internal-test') === 'true' ||
        sessionStorage.getItem('theme-internal-test') === 'true'
      );
    } else if (flagName === 'sales') {
      return featureFlags[flagName] || (
        searchParams.get(SalesInternalTestKey) === 'true' ||
        sessionStorage.getItem(SalesInternalTestKey) === 'true'
      );
    }

    return featureFlags[flagName]
  }

  return (
    <FeatureFlagsContext.Provider value={{ featureFlags, isActive, isLoading }}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export function useFeatureFlags(): FeatureFlagsContextData {
  const context = useContext(FeatureFlagsContext)

  return context
}
