import { ReactElement, ReactNode, useState } from 'react'
import {
  Box,
  Divider,
  Button,
  CircularProgress,
} from '@mui/material'
import { styled } from '@mui/system'
import IntegerInput from './IntegerInput'
import Dialog from '../Dialog';
import { formatNumber } from '@opulous/web/src/utils';

const InputContainer = styled(({
  children,
  className,
}: {
  className?: string
  children?: ReactNode
  error?: boolean
}) => (
  <div className={className}>{children}</div>
))(({ theme, error }) => ({
  border: `1px solid ${error ? theme.palette.error.main : theme.palette.grey[200]}`,
  padding: theme.spacing(1, 2),
  borderRadius: theme.spacing(1),
  margin: theme.spacing(2, 0),
}));

const InputTitle = styled('div')(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: '.7rem',
  fontWeight: '500',
}));

const Badge = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.grey[700],
  fontSize: '.7rem',
  fontWeight: '500',
  padding: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '1.6rem',
  borderRadius: '.8rem',
}));

const ErrorMsgContainer = styled('div')(({ theme }) => ({
  fontSize: '.75rem',
  color: theme.palette.error.main,
  fontWeight: 400,
}));

export default function AddTicketModal({
  numAvailableTickets,
  numAddedTickets,
  maxTickets,
  onClose,
  onSubmit,
}: {
  numAvailableTickets: number
  numAddedTickets: number
  maxTickets: number
  onClose: () => void
  onSubmit: (n: number) => Promise<void>
}): ReactElement {
  const [numberOfTickets, setNumberOfTickets] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<null|string>(null);
  const [isLoading, setIsLoading]= useState(false);

  const handleTicketsAdd = async () => {
    const isValidNumber = /^\d+$/.test(numberOfTickets);

    if (!isValidNumber) {
      setErrorMsg('Invalid number');
      return;
    }

    const numTickets = parseInt(numberOfTickets);
    const hasSufficientTickets = numTickets <= numAvailableTickets;
    const isExceededMaxTickets = (numAddedTickets + numTickets) > maxTickets;

    if (hasSufficientTickets && !isExceededMaxTickets) {
      setErrorMsg(null);
      setIsLoading(true);
      try {
        await onSubmit(numTickets);
        onClose();
      } finally {
        setIsLoading(false);
      }
    } else if (isExceededMaxTickets) {
      if (numAddedTickets) {
        // User has added ticket(s) before
        setErrorMsg(`Oops! You've reached your limit for this raffle. (Maximum: ${formatNumber(maxTickets)} tickets)`);
      } else {
        // The first time user adds ticket(s)
        setErrorMsg(`Oops! You tried to add too many tickets, limit for this raffle is ${formatNumber(maxTickets)} tickets`);
      }
    } else {
      setErrorMsg('You do not have sufficient tickets');
    }
  };

  if (isLoading) {
    return (
      <Dialog title="Adding Tickets" maxWidth="sm">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={5}
        >
          <CircularProgress color="primary" />
        </Box>
      </Dialog>
    );
  }

  return (
    <Dialog title="Adding Tickets" onClose={onClose} maxWidth="sm">
      <Box>
        <InputContainer error={!!errorMsg}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <InputTitle>Tickets to Add</InputTitle>
            <Badge>{formatNumber(numAvailableTickets)} available</Badge>
          </Box>
          <div>
            <IntegerInput
              value={numberOfTickets}
              onChange={setNumberOfTickets}
            />
          </div>
        </InputContainer>
        <ErrorMsgContainer>{errorMsg}</ErrorMsgContainer>
      </Box>
      <Box paddingY={2}><Divider /></Box>
      <Box textAlign="center" padding={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleTicketsAdd}
          disabled={!numberOfTickets || !parseInt(numberOfTickets)}
          fullWidth
        >
          Add tickets
        </Button>
      </Box>
    </Dialog>
  );
}
