import * as React from 'react';

const EndDateIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 18.334c-4.583 0-8.333-3.75-8.333-8.334 0-4.583 3.75-8.333 8.333-8.333 4.584 0 8.334 3.75 8.334 8.333 0 4.584-3.75 8.334-8.334 8.334Zm0-15c-3.666 0-6.666 3-6.666 6.666 0 3.667 3 6.667 6.666 6.667 3.667 0 6.667-3 6.667-6.667 0-3.666-3-6.666-6.667-6.666Z"
    />
    <path
      fill="currentColor"
      d="M14.084 10.834H10c-.5 0-.833-.333-.833-.833V5.917c0-.5.333-.833.833-.833.5 0 .834.333.834.833v3.25h3.25c.5 0 .833.334.833.834 0 .5-.333.833-.833.833Z"
    />
  </svg>
)

export default EndDateIcon;
