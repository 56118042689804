import React, { ReactNode } from 'react';
import { styled } from '@mui/system';
import { TextField, TextFieldProps } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const StyledInput = styled(TextField)<{ error?: boolean }>(
  ({ error, theme }) => ({
    '& > div, .MuiFilledInput-root::before, .MuiFilledInput-root::after': {
      borderTopLeftRadius: '8px !important',
      borderTopRightRadius: '8px !important',
      borderBottomLeftRadius: '8px !important',
      borderBottomRightRadius: '8px !important',
      border: '0px',
      content: 'none',
    },
    input: {
      background: theme.palette.grey['A100'],
      fontSize: '16px',
      lineHeight: '150%',
      fontWeight: '400',
      borderTopLeftRadius: '8px !important',
      borderTopRightRadius: '8px !important',
      borderBottomLeftRadius: '8px !important',
      borderBottomRightRadius: '8px !important',
      border: error ? '1px solid ' + theme.palette.error.main : 'none',
    },
    '.Mui-error': {
      fontWeight: 300,
      fontSize: '14px',
    },
  }),
);

const Icon = styled('div')(() => ({
  width: '15px',
  height: '15px',
  backgroundImage: 'url(/images/icons/question-mark.svg)',
  cursor: 'pointer',
  backgroundSize: 'contain',
  position: 'absolute',
  top: '10.25px',
  right: '13.25px',
  zIndex: 1,
}));

const Wrapper = styled('div')(() => ({
  position: 'relative',
}));

interface Props {
  popover?: boolean;
  contentPopover?: ReactNode | string;
}

const Input: React.FC<Props & TextFieldProps> = ({
  popover,
  contentPopover,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Wrapper>
      {popover && (
        <Icon
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      )}
      <StyledInput {...props} />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {typeof contentPopover === 'string' && (
          <Typography sx={{ p: 1 }}>{contentPopover}</Typography>
        )}
        {typeof contentPopover !== 'string' && <div>{contentPopover}</div>}
      </Popover>
    </Wrapper>
  );
};

export default Input;
