import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { intervalToDuration } from 'date-fns';
import { styled } from '@mui/system';
import { Paper, useTheme, Theme } from '@mui/material';
import { Pause, Play } from './icons';
import { loadAudio } from './helper';

const Wrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));
const AudioPlayerControls = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(4),
  },
}));
const AudioPlayerTrack = styled('canvas')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: `calc(100% - ${theme.spacing(15)})`,
  height: '40px',
  [theme.breakpoints.down('md')]: {
    width: `calc(100% - ${theme.spacing(12)})`,
  },
}));
const Duration = styled('span')(() => ({
}));

const INTERVAL_BASED_ON_DURATION = 5;
const renderCanvas = (
  theme: Theme,
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
  trackMap: number[],
  duration: number,
  currentTime: number,
) => {
  
  const canvas = canvasRef.current as any;
  if (canvas) {
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      const point = canvas.width * (currentTime / duration);
      const width = 1;
      let offSet = 0;
      for (let i = 0; i < trackMap.length; i++) {
        const ht = (trackMap[i] * canvas.height) / 100;
        if (offSet < point) {
          ctx.fillStyle = theme.palette.primary.main;
        } else {
          ctx.fillStyle = theme.palette.grey[300];
        }
        ctx.fillRect(offSet, (canvas.height - ht) / 2, width, ht);
        offSet += width + 1;
      }
    }
  }
};

type Props = {
  url: string;
};
let playInterval: any;
export default function AudioPlayer({ url }: Props): ReactElement {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const theme = useTheme();
  const [audio, setAudio] = useState<any>(null);
  const [paused, setPaused] = useState(true);
  const [trackMap, setTrackMap] = useState<number[]>([]);

  useEffect(() => {
    loadAudio(url, (newTrackMap: number[], newAudio: any) => {
      newAudio.addEventListener('ended', () => {
        setPaused(true);
      });
      setAudio(newAudio);
      setTrackMap(newTrackMap);
      renderCanvas(theme, canvasRef, newTrackMap, newAudio.duration, newAudio.currentTime);
    });
  }, []);

  const play = useCallback(() => {
    if (audio) {
      audio.play();
      setPaused(false);

      const interval = Math.trunc(audio.duration * INTERVAL_BASED_ON_DURATION);
      playInterval = setInterval(() => {
        if (audio.paused || audio.ended) {
          clearInterval(playInterval);
        } else {
          renderCanvas(theme, canvasRef, trackMap, audio.duration, audio.currentTime);
        }
      }, (interval));
    }
  }, [canvasRef, audio, trackMap]);

  const pause = useCallback(() => {
    if (audio) {
      audio.pause();
      setPaused(true);
    }
    clearInterval(playInterval);
  }, [audio]);

  const playFrom = useCallback(
    (event: any) => {
      if(audio) {
        const rect = event.target.getBoundingClientRect();
        const offSet = ((event.clientX - rect.left) / event.target.offsetWidth);
        const newCurrentTime = audio.duration * offSet;
        audio.currentTime = newCurrentTime;
        renderCanvas(theme, canvasRef, trackMap, audio.duration, newCurrentTime);
      };
    },
    [canvasRef, audio, trackMap],
  );

  const durationFormatted = intervalToDuration({ start: 0, end: (audio?.duration || 0) * 1000 });
  return (
    <Wrapper data-testid="audio-player">
      <AudioPlayerControls data-testid="audio-player__controls">
        {paused ? (
          <Play onClick={() => play()} />
        ) : (
          <Pause onClick={() => pause()} />
        )}
      </AudioPlayerControls>
      <AudioPlayerTrack data-testid="audio-player__track" ref={canvasRef} onClick={e => playFrom(e)} />
      <Duration data-testid="audio-player__duration">
        {durationFormatted.minutes}:{durationFormatted.seconds}
      </Duration>
    </Wrapper>
  );
}