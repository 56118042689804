import { ReactElement, useContext } from 'react'
import { Paper, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { TabPanel, TabContext } from '@mui/lab'
import TabBar from '@opulous/web/src/components/TabBar'
import CountriesTable from '@opulous/web/src/components/Royalties/FullOverview/CountriesTable'
import ReleasesTable from '@opulous/web/src/components/Royalties/FullOverview/ReleasesTable'
import StoresTable from '@opulous/web/src/components/Royalties/FullOverview/StoresTable'
import MonthsTable from '@opulous/web/src/components/Royalties/FullOverview/MonthsTable'
import { createHexColor } from '@opulous/web/src/shared/helpers'
import { StoreDetailsMap } from '@opulous/web/src/shared/Constants'
import RoyaltiesContext from '@opulous/web/src/pages/Royalties/context'
import { TableTabOption, TableTabOptionDefault, TableTabs, TabOption } from '@opulous/web/src/pages/Royalties/types'

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const FullOverviewContainer = styled(Paper)(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.grey[100]}`,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  padding: theme.spacing(3, 3),
}));

export default function FullOverview(): ReactElement {
  const { state: royaltiesContext, actions: royaltiesActions } = useContext(RoyaltiesContext)
  const {
    loadingOverview: loading,
    fullOverviewOptionSelected: sortOption,
    royaltiesSalesFullOverview: fullOverview,
  } = royaltiesContext
  const fullOverviewChangeSort = (sort: TableTabOption) => {
    royaltiesActions.loadSalesFullOverview(
      royaltiesContext.walletAddress,
      royaltiesContext.salesFilterSelected,
      { ...sort }
    )
  }

  const releasesProps = {
    loading,
    sortOption,
    changeSort: fullOverviewChangeSort,
    ...fullOverview.releases,
    items: fullOverview.releases.items.map(it => ({
      title: it.releaseTitle,
      artist: it.artistName,
      streams: it.units,
      earning: it.earning,
      image: it.image,
    })),
  }

  const countriesProps = {
    loading,
    sortOption,
    changeSort: fullOverviewChangeSort,
    ...fullOverview.countries,
    items: fullOverview.countries.items.map(it => ({
      name: it.countryName,
      streams: it.units,
      earning: it.earning,
      percent: it.percent
    })),
  }

  const storesProps = {
    loading,
    sortOption,
    changeSort: fullOverviewChangeSort,
    ...fullOverview.stores,
    items: fullOverview.stores.items.map((it, ix) => {
      const store = StoreDetailsMap.get(it.storeName) || { color: createHexColor(ix), image: '' }
      return {
        store: it.storeName,
        streams: it.units,
        earning: it.earning,
        percent: it.percent,
        image: store.image,
      }
    }),
  }

  const monthsProps = {
    loading,
    sortOption,
    changeSort: fullOverviewChangeSort,
    ...fullOverview.months,
    items: fullOverview.months.items.map(it => ({
      streams: it.units,
      earning: it.earning,
      date: new Date(it.month),
    })),
  }

  return (
    <FullOverviewContainer>
      <Title variant="subtitle2">Full overview</Title>
      <TabContext value={sortOption.tab}>
        <TabBar
          tabs={
            TableTabs
          }
          onChangTab={(value: string) => fullOverviewChangeSort({ ...TableTabOptionDefault, tab: value as TabOption })}
        />
        <StyledTabPanel value="releases">
          <ReleasesTable
            {...releasesProps}
            sortOption={
              sortOption && sortOption.tab === 'releases'
                ? sortOption
                : undefined
            }
          />
        </StyledTabPanel>
        <StyledTabPanel value="countries">
          <CountriesTable
            {...countriesProps}
            sortOption={
              sortOption && sortOption.tab === 'countries'
                ? sortOption
                : undefined
            }
          />
        </StyledTabPanel>
        <StyledTabPanel value="stores">
          <StoresTable
            {...storesProps}
            sortOption={
              sortOption && sortOption.tab === 'stores' ? sortOption : undefined
            }
          />
        </StyledTabPanel>
        <StyledTabPanel value="months">
          <MonthsTable
            {...monthsProps}
            sortOption={
              sortOption && sortOption.tab === 'months' ? sortOption : undefined
            }
          />
        </StyledTabPanel>
      </TabContext>
    </FullOverviewContainer>
  );
}
