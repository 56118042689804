import { ReactElement } from 'react';
import { styled } from '@mui/system';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FAQQuestion } from '@opulous/web/src/shared/types';

const Wrapper = styled('div')(() => ({
}));
const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
}));

type Props = {
  questions: FAQQuestion[];
};
export default function FAQ({ questions }: Props): ReactElement {
  return (
    <Wrapper data-testid="investment-faq">
      {questions.map((it, ix) => (
        <StyledAccordion key={`question-${ix}`}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography data-testid="investment-faq__statement">
              <strong>{it.statement}</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography data-testid="investment-faq__answer">{it.answer}</Typography>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </Wrapper>
  );
}
