import { Button, Box, Pagination, Dialog, CircularProgress, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import * as RaffleService from 'src/services/raffles';
import AdminRaffleCard from 'src/components/Raffles/RaffleCard/AdminRaffleCard';
import SkeletonCard from 'src/components/Raffles/RaffleCard/SkeletonCard';
import { IAdminRaffle } from 'src/shared/types';
import SearchIcon from '@mui/icons-material/Search';
import Papa from 'papaparse';

const PAGE_SIZE = 8;

const Container = styled('div')(() => ({
  width: '100%',
  maxWidth: '80rem',
}));

export default function RafflePage(): React.ReactElement {
  const navigate = useNavigate();
  const [raffles, setRaffles] = useState<IAdminRaffle[]>([]);
  const [numRaffles, setNumRaffles] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [pendingWinnerDownloads, setPendingWinnerDownloads] = useState<Set<number>>(new Set());
  const [raffleToBeDeleted, setRaffleToBeDeleted] = useState<IAdminRaffle|null>(null);
  const [raffleIdToBeCloned, setRaffleIdToBeCloned] = useState<number|null>(null);
  const [searchText, setSearchText] = useState('');
  const searchTextRef = useRef('');

  async function fetchRaffles() {
    setIsLoading(true);
    const { data, count } = await RaffleService.adminGetRaffles(page, PAGE_SIZE, searchText);
    setRaffles(data);
    setNumRaffles(count);
    setIsLoading(false);
  }

  const handleRaffleCreate = () => navigate('/admin/raffles/create');

  const handleRaffleEdit = (id: number) => () => navigate(
    '/admin/raffles/' + encodeURIComponent(id) + '/edit'
  );
  const handleRaffleClone = (id: number) => async () => {
    setRaffleIdToBeCloned(id);
    const newRaffle = await RaffleService.adminCloneRaffle(id);
    setRaffleIdToBeCloned(null);
    return navigate('/admin/raffles/' + encodeURIComponent(newRaffle.id) + '/edit');
  };

  const handleRaffleDelete = (r: IAdminRaffle) => () => setRaffleToBeDeleted(r);
  const handleRaffleDeletionConfirm = async () => {
    setIsDeleting(true);
    raffleToBeDeleted && await RaffleService.adminDeleteRaffle(raffleToBeDeleted.id);
    setIsDeleting(false);
    setRaffleToBeDeleted(null);
    await fetchRaffles();
  };
  const handleRaffleDeletionDialogClose = () => !isDeleting && setRaffleToBeDeleted(null);

  const handleSearch = async (e: React.ChangeEvent|React.FormEvent) => {
    e.preventDefault();
    setPage(1);
    setSearchText(searchTextRef.current);
  };

  const handleWinnerListDownload = (raffleId: number) => async () => {
    setPendingWinnerDownloads(s => new Set([...Array.from(s), raffleId]));
    const ts = new Date().toISOString().replace(/(\.\d+)Z$/g, '').replace(/[^\d]/g, '_');
    const winners = await RaffleService.adminGetRaffleWinners(raffleId);
    const csv = Papa.unparse({
      data: winners.map(({ walletAddress, hasClaimed }) => [walletAddress, hasClaimed]),
      fields: [
        'wallet_address',
        'has_claimed',
      ],
    }, {
      delimiter: ';',
      header: true,
      skipEmptyLines: true,
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const downloadUrl = URL.createObjectURL(blob);
    const el = document.createElement('a');
    el.setAttribute('target', '_blank');
    el.setAttribute('download', `raffle_${raffleId}_winners_${ts}.csv`);
    el.setAttribute('href', downloadUrl);
    el.click();
    setPendingWinnerDownloads(s => new Set(Array.from(s).filter(id => id !== raffleId)));
  };

  useEffect(() => {
    fetchRaffles();
  }, [page, searchText]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={3}>
      <Container>
        <div>
          <h1>Raffles</h1>
        </div>
        <Box display="flex" justifyContent="space-between" gap="1rem" paddingY={2}>
          <form onSubmit={handleSearch}>
            <TextField
              variant="outlined"
              placeholder="Search"
              onChange={e => Object.assign(searchTextRef, { current: e.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={handleSearch}
                      disabled={isLoading}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>

          <Button onClick={handleRaffleCreate}>Create</Button>
        </Box>
        <div>
          {isLoading && new Array(3).fill(null).map((_, i) => (
            <SkeletonCard key={i} />
          ))}
          {!isLoading && raffles.map(raffle => (
            <AdminRaffleCard key={raffle.id} raffle={raffle}>
              <Box display="flex" flexDirection="column" width="100%" gap={1}>
                <Button size="small" variant="outlined" onClick={handleRaffleEdit(raffle.id)}>
                  Edit
                </Button>
                <Button size="small" variant="outlined" onClick={handleRaffleClone(raffle.id)}>
                  {raffleIdToBeCloned === raffle.id ? (
                    <CircularProgress size="1.5rem" />
                  ) : 'Clone'}
                </Button>
                <Button size="small" variant="outlined" onClick={handleRaffleDelete(raffle)}>
                  Delete
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={
                    raffle.hidden ||
                    pendingWinnerDownloads.has(raffle.id) ||
                    (new Date(raffle.endTimestamp).getTime() > Date.now())
                  }
                  onClick={handleWinnerListDownload(raffle.id)}
                >
                  {pendingWinnerDownloads.has(raffle.id)
                    ? <CircularProgress size="1.5rem" />
                    : 'Export Winners'}
                </Button>
              </Box>
            </AdminRaffleCard>
          ))}
        </div>
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Pagination
            count={Math.ceil(numRaffles / PAGE_SIZE)}
            color="primary"
            onChange={(_e, p) => setPage(p)}
            page={page}
          />
        </Box>
      </Container>

      {!!raffleToBeDeleted && (
        <Dialog
          open={!!raffleToBeDeleted}
          maxWidth="sm"
          onClose={handleRaffleDeletionDialogClose}
        >
          <Box margin={3} display="flex" flexDirection="column" gap={3}>
            <Typography variant="h3">Delete Raffle #{raffleToBeDeleted?.id}</Typography>
            <Box>
              Are you sure you want to delete Raffle <b>{raffleToBeDeleted?.title}</b> (#{raffleToBeDeleted?.id})?
            </Box>
            <Box display="flex" gap={2} justifyContent="center">
              <Button
                color="primary"
                variant="text"
                disabled={isDeleting}
                onClick={handleRaffleDeletionDialogClose}
              >
                Cancel
              </Button>
              <Button
                color="error"
                disabled={isDeleting}
                onClick={handleRaffleDeletionConfirm}
              >
                {isDeleting ? (
                  <CircularProgress size="1.5rem" />
                ) : 'Confirm'}
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </Box>
  );
}
