import * as React from 'react';

const TotalTicketsIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 13 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.334 8.25C9.75 8.25 12.5 6.583 12.5 4.5 12.5 2.416 9.75.833 6.334.833 2.834.833.167 2.5.167 4.583S2.834 8.25 6.334 8.25Zm0-5.75c2.583 0 4.5 1.083 4.5 2.083 0 1-1.917 2.083-4.5 2.083-2.584-.083-4.5-1.166-4.5-2.083 0-.917 1.916-2.083 4.5-2.083Z"
    />
    <path
      fill="currentColor"
      d="M11.75 8.083c-.417-.25-.917-.083-1.167.25-.417.834-2.083 1.584-4.25 1.584-2.25 0-3.833-.75-4.333-1.584-.25-.333-.75-.5-1.167-.25-.417.25-.5.75-.25 1.167.917 1.417 3.167 2.333 5.75 2.333s4.833-.916 5.75-2.333c.167-.417.083-.917-.333-1.167Zm0 3.75c-.417-.25-.917-.083-1.167.25-.5.75-2.083 1.583-4.333 1.583-2.167 0-3.833-.75-4.333-1.583-.25-.417-.75-.5-1.167-.25-.417.25-.5.75-.25 1.167.917 1.416 3.167 2.333 5.75 2.333S11.083 14.416 12 13c.25-.417.167-.917-.25-1.167Zm0 3.834c-.417-.25-.917-.083-1.167.25-.417.833-2.083 1.583-4.25 1.583S2.5 16.75 2 15.917c-.25-.417-.75-.5-1.167-.25-.417.25-.5.75-.25 1.167.833 1.416 3.083 2.333 5.75 2.333 2.583 0 4.833-.917 5.75-2.333.167-.417.083-.917-.333-1.167Z"
    />
  </svg>
)

export default TotalTicketsIcon;
