import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/system';
import * as Yup from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import _HelpIcon from '@mui/icons-material/Help';
import ErrorIcon from '@mui/icons-material/Error';
import { IconButton, Box, Button, CircularProgress, TextField, debounce, Autocomplete, Tooltip, FormControlLabel, Switch } from '@mui/material';
import { Link as _Link, useNavigate, useParams } from 'react-router-dom';
import { Asset, ICreateInvestment } from 'src/shared/types';
import { validateField } from 'src/utils';
import * as SaleService from 'src/services/sales';
import * as AssetService from 'src/services/assets';
import Papa from 'papaparse';
import { NULLABLE_FIELDS } from './SaleCreate';
import * as Sentry from '@sentry/react';
import axios from 'axios';

const DUMMY_IMAGE_BASE64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

interface IValidationErrors {
  hidden: string
  cmsId: string
  preSaleMinIndividualInvestment: string
  preSaleMaxIndividualInvestment: string
  minIndividualInvestment: string
  maxIndividualInvestment: string
  investmentTargetAmount: string
  preSaleStartDate: string
  saleStartDate: string
  saleEndDate: string
  dealType: string
  investmentWhitelist: string
  assetId: string
  crmTagId: string
  purchaseAmountIncrement: string
}

interface IDirtyLookup {
  hidden: boolean
  cmsId: boolean
  preSaleMinIndividualInvestment: boolean
  preSaleMaxIndividualInvestment: boolean
  minIndividualInvestment: boolean
  maxIndividualInvestment: boolean
  investmentTargetAmount: boolean
  preSaleStartDate: boolean
  saleStartDate: boolean
  saleEndDate: boolean
  dealType: boolean
  investmentWhitelist: boolean
  assetId: boolean
  crmTagId: boolean
  purchaseAmountIncrement: boolean
}

const ErrorMessage = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `2px solid ${theme.palette.error.main}`,
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.error.light,
  padding: theme.spacing(2),
  color: theme.palette.error.main,
  gap: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));

const HelpIcon = styled(_HelpIcon)(({ theme }) => ({
  color: theme.palette.info.main,
  width: '1rem',
  height: '1rem',
}));

const Link = styled(_Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  gap: theme.spacing(1),

  '& > svg': {
    width: '1rem',
    height: '1rem',
  },
}));

const AssetOptionImage = styled('img')(({ theme }) => ({
  width: '3rem',
  height: '3rem',
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.spacing(1),
  border: 0,
  outline: 0,
}));

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
  maxWidth: '50rem',
}));

const Fieldset = styled('fieldset')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  color: theme.palette.text.primary,
  display: 'block',
  position: 'relative',
  minWidth: 0,

  '&:hover': {
    border: `1px solid ${theme.palette.common.black}`,
  },

  '&.error': {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },

  '& > legend': {
    fontSize: '.75rem',
    fontWeight: '300',
  },
}));

const ErrText = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: '700',
  fontSize: '.9rem',
}));

const ImportButton = styled('a')(({ theme }) => ({
  display: 'block',
  color: theme.palette.info.main,
  fontWeight: 600,
  textDecoration: 'none',
  fontSize: '.9rem',
}));

const fieldValidators = {
  hidden: Yup.bool()
    .typeError('Boolean expected'),
  cmsId: Yup.string()
    .required('This field is required'),
  preSaleMinIndividualInvestment: Yup.number()
    .typeError('Number expected')
    .required('This field is required'),
  preSaleMaxIndividualInvestment: Yup.number()
    .typeError('Number expected')
    .required('This field is required'),
  minIndividualInvestment: Yup.number()
    .typeError('Number expected')
    .required('This field is required'),
  maxIndividualInvestment: Yup.number()
    .typeError('Number expected')
    .required('This field is required'),
  investmentTargetAmount: Yup.number()
    .typeError('Number expected')
    .required('This field is required'),
  preSaleStartDate: Yup.date()
    .typeError('Date expected')
    .required('This field is required'),
  saleStartDate: Yup.date()
    .typeError('Date expected')
    .required('This field is required'),
  saleEndDate: Yup.date()
    .typeError('Date expected')
    .required('This field is required'),
  dealType: Yup.string()
    .min(1)
    .required('This field is required'),
  investmentWhitelist: Yup.array().of(
    Yup.string()
      .matches(/^[A-Z2-7]{58}$/, ({ path }) => `Address ${parseInt(path.replace(/^\[|\]$/g, '')) + 1} is invalid`)
      .required('Empty address is not allowed')
  )
    .test(
      'Duplicated labels',
      'Duplicated labels',
      xs => !xs || new Set(xs).size === xs.length,
    ),
  assetId: Yup.number().min(1)
    .typeError('Number expected')
    .required('This field is required'),
  crmTagId: Yup.string(),
  purchaseAmountIncrement: Yup.number().min(1)
    .typeError('Number expected')
    .required('This field is required'),
};

export function FieldRow({
  n,
  value,
  onChange,
  onDelete,
}: {
  n: number
  value: string
  onChange: (s: string) => void
  onDelete: () => void
}) {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gap={2}
      paddingX={2}
      paddingY={1}
    >
      <Box display="flex" flex={1} minWidth="10rem">
        <TextField
          label={`Address ${n}`}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={value}
          onChange={e => onChange(e.target.value)}
          fullWidth={true}
        />
      </Box>

      <Box display="flex" alignItems="center">
        <IconButton color="primary" size="small" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default function SaleEdit(): React.ReactElement {
  const { id: investmentId } = useParams();
  const navigate = useNavigate();
  const dirtyLookup = useRef<Partial<IDirtyLookup>>({}).current;
  const assetOptionReqRef = useRef<ReturnType<typeof AssetService.adminGetAssetsCancellable>>();
  const assetOptionFullListRef = useRef<Asset[]>();

  const [hidden, setHidden] = useState(false);
  const [cmsId, setCmsId] = useState('');
  const [preSaleMinIndividualInvestment, setPreSaleMinIndividualInvestment] = useState('');
  const [preSaleMaxIndividualInvestment, setPreSaleMaxIndividualInvestment] = useState('');
  const [minIndividualInvestment, setMinIndividualInvestment] = useState('');
  const [maxIndividualInvestment, setMaxIndividualInvestment] = useState('');
  const [investmentTargetAmount, setInvestmentTargetAmount] = useState('');
  const [preSaleStartDate, setPreSaleStartDate] = useState('');
  const [saleStartDate, setSaleStartDate] = useState('');
  const [saleEndDate, setSaleEndDate] = useState('');
  const [dealType, setDealType] = useState('');
  const [investmentWhitelist, setInvestmentWhitelist] = useState<string[]>([]);
  const [assetId, setAssetId] = useState<null|number>(null);
  const [crmTagId, setCrmTagId] = useState('');
  const [assetOptions, setAssetOptions] = useState<Asset[]>([]);
  const [assetOptionSearchText, setAssetOptionSearchText] = useState('');
  const [purchaseAmountIncrement, setPurchaseAmountIncrement] = useState('');

  const [validationErrors, setValidationErrors] = useState<Partial<IValidationErrors>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAssetOptions, setIsLoadingAssetOptions] = useState(false);
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState<string>('');

  async function updateCache() {
    const { data } = await AssetService.adminGetAssets(1, 99999);
    Object.assign(assetOptionFullListRef, { current: data });
  }

  const markDirty = (name: keyof typeof dirtyLookup) => {
    dirtyLookup[name] = true;
    setValidationErrors(errs => { delete errs[name]; return { ...errs }; });
  };

  useEffect(() => {
    (async () => {
      if (!investmentId || !/^\d+$/g.test(investmentId)) {
        navigate('/admin/sales/create');
        return;
      }

      setIsLoading(true);
      const [r] = await Promise.all([
        SaleService.adminGetInvestment(parseInt(investmentId)),
        updateCache(),
      ]);

      if (!r) {
        navigate('/admin/sales/create');
        return;
      }

      setHidden(r.hidden);
      setCmsId(r.cmsId);
      setPreSaleMinIndividualInvestment(r.preSaleMinIndividualInvestment?.toString() ?? '');
      setPreSaleMaxIndividualInvestment(r.preSaleMaxIndividualInvestment?.toString() ?? '');
      setMinIndividualInvestment(r.minIndividualInvestment.toString());
      setMaxIndividualInvestment(r.maxIndividualInvestment.toString());
      setInvestmentTargetAmount(r.investmentTargetAmount.toString());
      setPreSaleStartDate(r.preSaleStartDate?.replace(/z$/gi, '') ?? '');
      setSaleStartDate(r.saleStartDate.replace(/z$/gi, ''));
      setSaleEndDate(r.saleEndDate.replace(/z$/gi, ''));
      setDealType(r.dealType);
      setInvestmentWhitelist(r.investmentWhitelist);
      setAssetId(r.assetId);
      setCrmTagId(r.crmTagId ?? '');
      setPurchaseAmountIncrement(r.purchaseAmountIncrement?.toString());

      setAssetOptions(assets => assets.length ? assets : assetOptionFullListRef.current as Asset[]);

      setIsLoading(false);
    })();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!investmentId || !/^\d+$/g.test(investmentId)) return;

    const errs: Partial<IValidationErrors> = {};

    for (const [name, value] of ([
      ['hidden', hidden],
      ['cmsId', cmsId],
      ['preSaleMinIndividualInvestment', preSaleMinIndividualInvestment],
      ['preSaleMaxIndividualInvestment', preSaleMaxIndividualInvestment],
      ['minIndividualInvestment', minIndividualInvestment],
      ['maxIndividualInvestment', maxIndividualInvestment],
      ['investmentTargetAmount', investmentTargetAmount],
      ['preSaleStartDate', preSaleStartDate],
      ['saleStartDate', saleStartDate],
      ['saleEndDate', saleEndDate],
      ['dealType', dealType],
      ['investmentWhitelist', investmentWhitelist],
      ['assetId', assetId],
      ['crmTagId', crmTagId],
      ['purchaseAmountIncrement', purchaseAmountIncrement],
    ] as [keyof typeof fieldValidators, any][])) {
      if (!dirtyLookup[name]) continue;
      if (NULLABLE_FIELDS.has(name) && !value) continue;
      const msg = await validateField(fieldValidators[name], value);
      if (msg) {
        errs[name] = msg;
      }
    }

    setValidationErrors(errs);

    if (Object.keys(errs).length) return;

    if (
      preSaleStartDate &&
      (new Date(saleStartDate + 'Z').getTime() < new Date(preSaleStartDate + 'Z').getTime())
    ) {
      setValidationErrors(es => ({
        ...es,
        saleStartDate: 'Sale start date cannot be earlier than pre-sale start date',
      }));
      return;
    }
    if (new Date(saleEndDate + 'Z').getTime() < new Date(saleStartDate + 'Z').getTime()) {
      setValidationErrors(es => ({
        ...es,
        saleEndDate: 'Sale end date cannot be earlier than sale start date',
      }));
      return;
    }

    try {
      setIsSubmitting(true);
      if (Object.values(dirtyLookup).filter(x => x).length) {
        const data: Partial<ICreateInvestment> = {
          ...(dirtyLookup.hidden && { hidden }),
          ...(dirtyLookup.cmsId && { cmsId }),
          ...(dirtyLookup.preSaleMinIndividualInvestment && {
            preSaleMinIndividualInvestment: (preSaleStartDate && preSaleMinIndividualInvestment)
              ? parseInt(preSaleMinIndividualInvestment)
              : null,
          }),
          ...(dirtyLookup.preSaleMaxIndividualInvestment && {
            preSaleMaxIndividualInvestment: (preSaleStartDate && preSaleMaxIndividualInvestment)
              ? parseInt(preSaleMaxIndividualInvestment)
              : null,
          }),
          ...(dirtyLookup.minIndividualInvestment && {
            minIndividualInvestment: parseInt(minIndividualInvestment),
          }),
          ...(dirtyLookup.maxIndividualInvestment && {
            maxIndividualInvestment: parseInt(maxIndividualInvestment),
          }),
          ...(dirtyLookup.investmentTargetAmount && {
            investmentTargetAmount: parseInt(investmentTargetAmount),
          }),
          ...(dirtyLookup.preSaleStartDate && {
            preSaleStartDate: preSaleStartDate ? preSaleStartDate + 'Z' : null,
            preSaleMinIndividualInvestment: (preSaleStartDate && preSaleMinIndividualInvestment)
              ? parseInt(preSaleMinIndividualInvestment)
              : null,
            preSaleMaxIndividualInvestment: (preSaleStartDate && preSaleMaxIndividualInvestment)
              ? parseInt(preSaleMaxIndividualInvestment)
              : null,
          }),
          ...(dirtyLookup.saleStartDate && { saleStartDate: saleStartDate + 'Z' }),
          ...(dirtyLookup.saleEndDate && { saleEndDate: saleEndDate + 'Z' }),
          ...(dirtyLookup.dealType && { dealType }),
          ...(dirtyLookup.investmentWhitelist && { investmentWhitelist }),
          ...(dirtyLookup.assetId && { assetId: assetId as number }),
          ...(dirtyLookup.crmTagId && { crmTagId: crmTagId || null }),
          ...(dirtyLookup.purchaseAmountIncrement && { purchaseAmountIncrement: parseInt(purchaseAmountIncrement) }),
        };

        await SaleService.adminEditInvestment(parseInt(investmentId), data);
      }

      navigate('/admin/sales');
      setIsSubmitting(false);
    } catch (err) {
      if (
        axios.isAxiosError(err) &&
        err.response?.status === 400 &&
        err.response?.data?.errors?.length &&
        err.response?.data?.errors?.[0]?.message
      ) {
        setSubmissionErrorMessage(err.response?.data?.errors?.[0]?.message);
      } else {
        setSubmissionErrorMessage('Unexpected error occurred, please try again later.');
      }
      Sentry.captureException(err);
      setIsSubmitting(false);
    }
  }

  const handleAddressChange = (n: number) => (addr: string) => {
    markDirty('investmentWhitelist');
    setInvestmentWhitelist(l => l.map((x, i) => i === n ? addr : x));
  };
  const handleAddressDelete = (n: number) => () => {
    markDirty('investmentWhitelist');
    setInvestmentWhitelist(l => l.filter((_, i) => i !== n));
  };
  const handleAddressAdd = () => {
    markDirty('investmentWhitelist');
    setInvestmentWhitelist(l => l.concat(['']));
  };

  const handleAddressesImport = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '.csv');

    const handleFileChange = async () => {
      const files = input.files;
      const file = files?.[0];
      if (file) {
        const parseResult: Papa.ParseResult<string[]> = await new Promise(resolve => {
          Papa.parse<string[]>(file, {
            header: false,
            skipEmptyLines: true,
            delimiter: ';',
            complete(results) {
              return resolve(results);
            },
          });
        });
        if (parseResult.errors.length) {
          setValidationErrors(errs => ({ ...errs, investmentWhitelist: 'Failed to parse the address CSV file' }));
          return;
        }
        markDirty('investmentWhitelist');
        const addrSet = new Set(parseResult.data.map(row => row[0]?.trim()).filter(x => x));
        setInvestmentWhitelist(list => list.filter(addr => !addrSet.has(addr)).concat(Array.from(addrSet)));
      }
      input.remove();
    };
    input.addEventListener('change', handleFileChange);
    input.click();
  };

  const fetchAssetOptionsDebounced = useCallback(debounce(async (text: string) => {
    assetOptionReqRef.current?.cancel();
    setIsLoadingAssetOptions(true);
    if (!text && assetOptionFullListRef.current) {
      // NOTE: use cached list
      setAssetOptions(assetOptionFullListRef.current);
    } else {
      const req = AssetService.adminGetAssetsCancellable(1, 99999, text);
      Object.assign(assetOptionReqRef, { current: req });
      const { data } = await req.promise;
      setAssetOptions(data);
    }
    setIsLoadingAssetOptions(false);
  }, 400), []);

  useEffect(() => {
    fetchAssetOptionsDebounced(assetOptionSearchText);
  }, [assetOptionSearchText]);

  useEffect(() => {
    let timer: any = null;
    const UPDATE_INTERVAL = 1000 * 60 * 5; // NOTE: Update cache every 5 minutes

    function scheduleCacheUpdate() {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await updateCache();
        scheduleCacheUpdate();
      }, UPDATE_INTERVAL);
    }

    scheduleCacheUpdate();

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <Box marginY={4} display="flex" flexDirection="column" gap={4} alignItems="center">
        <CircularProgress size="3rem" color="primary" />
        <div>Retrieving sale data...</div>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={3}>
      <Form onSubmit={handleSubmit}>
        <div>
          <Link to="/admin/sales">
            <ArrowBackIcon />
            <span>Back</span>
          </Link>
        </div>
        <div>
          <h1>Edit Sale</h1>
        </div>
        <TextField
          label="ID"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={investmentId ?? '-'}
          disabled={true}
        />
        <FormControlLabel
          label="Draft Mode"
          control={<Switch
            checked={hidden}
            onChange={e => {
              markDirty('hidden');
              setHidden(e.target.checked)
            }}
          />}
        />
        <TextField
          label="Deal Type"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.dealType}
          helperText={validationErrors.dealType}
          value={dealType}
          onChange={e => {
            markDirty('dealType');
            setDealType(e.target.value)
          }}
        />
        <TextField
          label="CMS ID"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.cmsId}
          helperText={validationErrors.cmsId}
          value={cmsId}
          onChange={e => {
            markDirty('cmsId');
            setCmsId(e.target.value)
          }}
        />

        <TextField
          label="Purchase Amount Increment unit"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.purchaseAmountIncrement}
          helperText={validationErrors.purchaseAmountIncrement}
          value={purchaseAmountIncrement}
          placeholder="e.g. 50"
          onChange={e => {
            markDirty('purchaseAmountIncrement');
            setPurchaseAmountIncrement(e.target.value)
          }}
        />

        <TextField
          label="Pre-Sale Min Individual Investment (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.preSaleMinIndividualInvestment}
          helperText={validationErrors.preSaleMinIndividualInvestment}
          value={preSaleMinIndividualInvestment}
          onChange={e => {
            markDirty('preSaleMinIndividualInvestment');
            setPreSaleMinIndividualInvestment(e.target.value)
          }}
        />

        <TextField
          label="Pre-Sale Max Individual Investment (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.preSaleMaxIndividualInvestment}
          helperText={validationErrors.preSaleMaxIndividualInvestment}
          value={preSaleMaxIndividualInvestment}
          onChange={e => {
            markDirty('preSaleMaxIndividualInvestment');
            setPreSaleMaxIndividualInvestment(e.target.value)
          }}
        />

        <TextField
          label="Min Individual Investment"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.minIndividualInvestment}
          helperText={validationErrors.minIndividualInvestment}
          value={minIndividualInvestment}
          onChange={e => {
            markDirty('minIndividualInvestment');
            setMinIndividualInvestment(e.target.value)
          }}
        />

        <TextField
          label="Max Individual Investment"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.maxIndividualInvestment}
          helperText={validationErrors.maxIndividualInvestment}
          value={maxIndividualInvestment}
          onChange={e => {
            markDirty('maxIndividualInvestment');
            setMaxIndividualInvestment(e.target.value)
          }}
        />

        <TextField
          label="Investment Target Amount"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.investmentTargetAmount}
          helperText={validationErrors.investmentTargetAmount}
          value={investmentTargetAmount}
          onChange={e => {
            markDirty('investmentTargetAmount');
            setInvestmentTargetAmount(e.target.value)
          }}
        />

        <TextField
          label="Pre-sale Start Date (UTC time) (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.preSaleStartDate}
          helperText={validationErrors.preSaleStartDate}
          type="datetime-local"
          value={preSaleStartDate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            markDirty('preSaleStartDate');
            setPreSaleStartDate(e.target.value)
          }}
        />

        <TextField
          label="Sale Start Date (UTC time)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.saleStartDate}
          helperText={validationErrors.saleStartDate}
          type="datetime-local"
          value={saleStartDate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            markDirty('saleStartDate');
            setSaleStartDate(e.target.value)
          }}
        />

        <TextField
          label="Sale End Date (UTC time)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.saleEndDate}
          helperText={validationErrors.saleEndDate}
          type="datetime-local"
          value={saleEndDate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            markDirty('saleEndDate');
            setSaleEndDate(e.target.value)
          }}
        />

        <Fieldset>
          <legend>Investment Whitelist</legend>
          <Box
            paddingX={2}
            paddingY={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
          >
            <ImportButton href="#" onClick={handleAddressesImport}>
              Import additional addresses
            </ImportButton>
            <Tooltip title={
              <Box padding={.5} maxWidth="10rem">
                The uploaded file should be a <strong>single-column CSV file without headers</strong>
              </Box>
            }>
              <HelpIcon />
            </Tooltip>
          </Box>
          <Box paddingY={1}>
            {investmentWhitelist.map((address, i) => (
              <FieldRow
                key={`field-row-${i}`}
                n={i + 1}
                value={address}
                onChange={handleAddressChange(i)}
                onDelete={handleAddressDelete(i)}
              />
            ))}
          </Box>
          {validationErrors.investmentWhitelist && (
            <Box paddingX={2} paddingY={1}>
              <ErrText>{validationErrors.investmentWhitelist}</ErrText>
            </Box>
          )}
          <Box paddingX={2} paddingY={1} display="flex" gap={1} justifyContent="space-between">
            <Button color="primary" size="small" onClick={handleAddressAdd}>
              <AddIcon />
              <Box paddingLeft={1}>Add Address</Box>
            </Button>

            <Button color="primary" size="small" onClick={() => {
              markDirty('investmentWhitelist');
              setInvestmentWhitelist([]);
            }}>
              Clear
            </Button>
          </Box>
        </Fieldset>

        <Autocomplete
          disablePortal
          loading={isLoadingAssetOptions}
          isOptionEqualToValue={option => option.id === assetId}
          options={
            // NOTE: Use empty option list when we are still fetching options, otherwise
            //       Autocomplete component will not show loading state
            // Ref: https://stackoverflow.com/questions/66803107/mui-autocomplete-does-not-show-loading-spinner-when-loading-is-true
            isLoadingAssetOptions ? [] : assetOptions
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Asset ID"
              InputProps={{
                ...params.InputProps,
                startAdornment: (<>
                  <Box display="flex" width="4rem" height="4rem" alignItems="center" justifyContent="center">
                    <AssetOptionImage
                      src={assetOptions.find(option => option.id === assetId)?.image ?? DUMMY_IMAGE_BASE64}
                      loading="lazy"
                    />
                  </Box>
                  {params.InputProps.startAdornment}
                </>)
              }}
            />
          )}
          renderOption={(props, option) => (
            <div {...props as any}>
              <Box display="flex" minHeight="4rem" gap="1rem" alignItems="center">
                <AssetOptionImage
                  src={option.image}
                  loading="lazy"
                />
                <div>
                  <Box flex="1" whiteSpace="nowrap" fontWeight="500" paddingBottom=".1rem">
                    [{option.id}][{option.unitName}] {option.name}
                  </Box>
                  {((option.artist ?? option.custom?.artist) || null) && (
                    <Box fontSize=".8rem" lineHeight="1rem" fontWeight="400">
                      Artist: <strong>{option.artist ?? option.custom?.artist}</strong>
                    </Box>
                  )}
                  {((option.releaseTitle ?? option.custom?.title) || null) && (
                    <Box fontSize=".8rem" lineHeight="1rem" fontWeight="400">
                      Release Title: <strong>{option.releaseTitle ?? option.custom?.title}</strong>
                    </Box>
                  )}
                </div>
              </Box>
            </div>
          )}
          getOptionLabel={option => `[${option.id}][${option.unitName}] ${option.name}`}
          onInputChange={(_e, newValue, reason) => setAssetOptionSearchText(reason !== 'reset' ? newValue : '')}
          onChange={(_e, newValue) => {
            markDirty('assetId');
            setAssetId(newValue?.id ?? null);
          }}
          value={assetOptions.find(option => option.id === assetId)}
        />

        <TextField
          label="CRM Tag ID"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.crmTagId}
          helperText={validationErrors.crmTagId}
          value={crmTagId}
          onChange={e => {
            markDirty('crmTagId');
            setCrmTagId(e.target.value)
          }}
        />

        {!!submissionErrorMessage && (
          <ErrorMessage>
            <ErrorIcon />
            <Box flex="1">{submissionErrorMessage}</Box>
          </ErrorMessage>
        )}

        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (<CircularProgress size="1.5rem" color="primary" />) : 'Submit'}
        </Button>
      </Form>
    </Box>
  );
}
