import React from 'react';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

const SongDetailPageHeaderWrapper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  height: '100%',
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  boxShadow: 'none',
  borderRadius: 0,
  display: 'flex',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

export const Header = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  marginBottom: theme.spacing(3.75),
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
  marginBottom: theme.spacing(1.75),
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2.25),
  color: theme.palette.grey[500],
}));

const SongDetailPageHeader: React.FC = () => {
  return (
    <SongDetailPageHeaderWrapper>
      <StyledCard>
        <CardMedia
          image="/images/asset1.png"
          sx={{
            flexShrink: 0,
            width: theme => theme.spacing(30),
            height: theme => theme.spacing(30),
            backgroundColor: theme => theme.palette.grey[300],
            borderRadius: theme => theme.spacing(1),
          }}
        />
        <CardContent>
          <Header>
            <Title variant="h4">Monkberry Moon Delight</Title>
            <SubTitle>Paul McCartney</SubTitle>
          </Header>

          <Typography variant="body1">
            Culpa modi quos voluptas voluptatem quis minus ad laudantim quod.
            Odit labore quia dolores qtque voluptate sint sed temporibus. Et
            aspernatur aut aspernatur quibusdam officiis magnam sunt nulla. Quo
            ut in doloremque rerum magnam consequatur earum exercitationem esse.
          </Typography>
        </CardContent>
      </StyledCard>
    </SongDetailPageHeaderWrapper>
  );
};

export default SongDetailPageHeader;
