import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import {
  Box,
  BoxProps,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  styled,
  useTheme,
} from '@mui/material';
import WalletIconDisabled from '@mui/icons-material/AccountBalanceWallet';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import CasinoIcon from '@mui/icons-material/Casino';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import LogoutIcon from '@mui/icons-material/Logout';
import CircleIcon from '@mui/icons-material/Circle';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InterestsIcon from '@mui/icons-material/Interests';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

import useAuth from 'src/hooks/useAuth';
import WalletDropDownMenu from '@opulous/web/src/components/Admin/Sidebar/WalletDropDownMenu';
import { useSignerApp } from '@opulous/web/src/context/SignerAppContext';
import { formatWallet } from '@opulous/web/src/shared/helpers';

const DRAWER_WIDTH = 240;

const WalletIconEnabled: React.FC = () => (
  <WalletIconDisabled color="primary" />
);

interface NavButtonProps {
  Icon: React.FC;
  onClick?: () => void;
}

interface NavItem extends NavButtonProps {
  isWallet?: boolean;
  text: string;
  tooltip?: React.ReactNode|string;
  desktopOnly?: boolean;
}

const NavButton = ({ onClick, Icon }: NavButtonProps) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={onClick}
      sx={{
        display: { xs: 'none', sm: 'flex' },
        width: '2.4rem',
        height: '2.4rem',
        padding: 0,
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          color: theme.palette.text.primary,
        },
      }}
    >
      <Icon />
    </IconButton>
  );
};

const ExpandButton = styled('div')`
  position: absolute;
  right: -1rem;
  top: 50%;
  transform: translate(50%, -50%);
  background-color: ${props => props.theme.palette.common.white};
  width: 2rem;
  height: 2.6rem;
  border-radius: 1rem;
  display: none;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 0 2px 0 ${props => props.theme.palette.grey[300]};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.palette.grey[50]};
  }

  @media(min-width: ${props => props.theme.breakpoints.values.sm}px) {
    display: flex;
  }

  & svg {
    transform: rotate(90deg);
  }
`;

const DesktopMenuItem = styled('div')<{ collapsed?: boolean }>`
  display: flex;
  align-items: center;
  gap: .6rem;
  padding-right: ${props => (props.collapsed ? 0 : '.8rem')};
  cursor: ${props => (props.collapsed ? 'default' : 'pointer')};
  border-radius: ${props => (props.collapsed ? 0 : props.theme.spacing(1))};

  ${props => props.collapsed ? '' : `
    & .MuiIconButton-root {
      pointer-events: none;
    }

    &:hover {
      background-color: ${props.theme.palette.grey[100]};
    }
  `}
`;

export default function DashboardBase({
  children,
}: {
  children?: React.ReactNode;
}): React.ReactElement {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isDesktopMenuCollapsed, setIsDesktopMenuCollapsed] = useState(false);
  const { connect, refresh, disconnect, session } = useSignerApp();
  const { isInitializing, user, isAdmin } = useAuth();

  async function handleConnectClick() {
    await connect();
  }

  const navItems: NavItem[] = [
    {
      text: 'Home',
      Icon: HomeIcon,
      onClick: () => navigate('/admin/dashboard'),
    },
    {
      text: 'Raffles',
      tooltip: (
        <Box padding={.5}>
          <Box paddingBottom={.2} fontWeight="600">Raffles</Box>
          <Box maxWidth="10rem">Create new raffle cards or manage existing ones.</Box>
        </Box>
      ),
      Icon: CasinoIcon,
      onClick: () => navigate('/admin/raffles'),
    },
    {
      text: 'Assets',
      tooltip: (
        <Box padding={.5}>
          <Box paddingBottom={.2} fontWeight="600">Assets</Box>
          <Box maxWidth="10rem">Create new assets, edit metadata, and mint units (MFTs, NFTs)</Box>
        </Box>
      ),
      Icon: InterestsIcon,
      onClick: () => navigate('/admin/assets'),
    },
    {
      text: 'Pools',
      tooltip: (
        <Box padding={.5}>
          <Box paddingBottom={.2} fontWeight="600">Pools</Box>
          <Box maxWidth="10rem">Create new OPUL Staking Pools or update the existing ones.</Box>
        </Box>
      ),
      Icon: WorkspacesIcon,
      onClick: () => navigate('/admin/pools'),
    },
    {
      text: 'Sales',
      tooltip: (
        <Box padding={.5}>
          <Box paddingBottom={.2} fontWeight="600">Sales</Box>
          <Box maxWidth="10rem">Create new MFT Sale pages, manage existing ones, and export data (sales, issuance).</Box>
        </Box>
      ),
      Icon: MonetizationOnIcon,
      onClick: () => navigate('/admin/sales'),
    },
    {
      text: 'Issuances',
      tooltip: (
        <Box padding={.5}>
          <Box paddingBottom={.2} fontWeight="600">Issuances</Box>
          <Box maxWidth="10rem">Issue royalty rewards (USDC) and different assets (OPUL, MFTs, NFTs)</Box>
        </Box>
      ),
      Icon: DeveloperBoardIcon,
      onClick: () => navigate('/admin/issuances'),
    },
    {
      text: 'Rewards',
      tooltip: (
        <Box padding={.5}>
          <Box paddingBottom={.2} fontWeight="600">Rewards</Box>
          <Box maxWidth="10rem">Import MFT sales data to be displayed in a dedicated platform module.</Box>
        </Box>
      ),
      Icon: TrendingUpIcon,
      onClick: () => navigate('/admin/rewards'),
    },
    (session ? {
      text: (() => {
        try {
          return `${formatWallet(session.wallet.address)}`;
        } catch (err) {
          Sentry.captureException(err);
          return 'Wallet';
        }
      })(),
      tooltip: (
        <Box display="flex" flexDirection="column" gap=".2rem" padding=".2rem .4rem">
          <div><strong>Sender:</strong> {session.sender.address}</div>
          <div><strong>Wallet:</strong> {session.wallet.address}</div>
        </Box>
      ),
      Icon: WalletIconEnabled,
      isWallet: true,
      desktopOnly: true,
    } : {
      text: 'Connect Wallet',
      Icon: WalletIconDisabled,
      onClick: () => handleConnectClick(),
      desktopOnly: true,
    }),
    {
      text: 'Logout',
      Icon: LogoutIcon,
      onClick: () => Auth.signOut(),
    },
  ];

  const handleDrawerToggle = () => setIsMobileOpen(x => !x);

  if (isInitializing) {
    return <span>Loading...</span>;
  }

  if (!user) {
    return <Navigate to="/admin/login" />;
  }
  if (!isAdmin) {
    return <Navigate to="/" />;
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        {navItems.filter(x => !x.desktopOnly).map(item => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} onClick={item.onClick}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = () => window.document.body;

  async function handleRefreshClick() {
    await refresh();
  }

  function handleDisconnectClick() {
    disconnect();
  }

  const desktopMenuItems = (props?: BoxProps) => (
    <Box
      position="relative"
      sx={{
        display: { xs: 'none', sm: 'flex' },
        ...(isDesktopMenuCollapsed ? null : { minWidth: '12rem' }),
        ...props?.sx,
      }}
      {...props}
    >
      <Box
        flexDirection="column"
        padding="1rem .6rem"
        gap=".6rem"
        sx={{ overflowY: 'auto' }}
      >
        {navItems.map(
          ({ isWallet = false, text, tooltip, onClick, Icon = CircleIcon }) => {
            const showTooltip = isWallet || isDesktopMenuCollapsed || !!tooltip;
            const el = (
              <Tooltip title={showTooltip ? tooltip || text : ''} placement="right">
                <DesktopMenuItem onClick={onClick} collapsed={isDesktopMenuCollapsed}>
                  <NavButton key={text} Icon={Icon} onClick={onClick} />
                  {isDesktopMenuCollapsed ? null : text}
                </DesktopMenuItem>
              </Tooltip>
            );
            return isWallet && session ? (
              <WalletDropDownMenu
                key={text}
                anchor={el}
                onRefresh={handleRefreshClick}
                onDisconnect={handleDisconnectClick}
                senderAddress={session.sender.address}
                walletAddress={session.wallet.address}
              />
            ) : el;
          }
        )}
      </Box>
      {props?.children}
    </Box>
  );

  return (
    <Box
      display="flex"
      sx={{
        flexDirection: { xs: 'column', sm: 'row' },
        gap: { sm: '.6rem' },
        color: theme.palette.text.primary,
      }}
    >
      <Box
        alignItems="stretch"
        position="relative"
        sx={{
          padding: { xs: '.6rem 1rem', sm: '0 1rem 0 0' },
          boxShadow: {
            xs: `0 2px 2px 0 ${theme.palette.grey[300]}`,
            sm: `2px 0 2px 0 ${theme.palette.grey[300]}`,
          },
          minHeight: { xs: 'unset', sm: '100vh' },
        }}
      >
        <IconButton
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            display: { sm: 'none' },
            color: theme.palette.text.primary,
          }}
        >
          <MenuIcon />
        </IconButton>

        {desktopMenuItems({ visibility: 'hidden' })}
        {desktopMenuItems({
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,

          children: (
            <ExpandButton onClick={() => setIsDesktopMenuCollapsed(x => !x)}>
              {isDesktopMenuCollapsed ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
            </ExpandButton>
          ),
        })}
      </Box>

      <Box component="nav">
        {/* For mobile only */}
        <Drawer
          container={container}
          variant="temporary"
          open={isMobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Box component="main" flex="1" padding={3}>
        {children}
      </Box>
    </Box>
  );
}
