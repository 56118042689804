import { SortOption } from "src/shared/types"

export enum TabOption {
  RELEASES = 'releases',
  COUNTRIES = 'countries',
  STORES = 'stores',
  MONTHS = 'months',
}

export type FilterSelected = {
  months?: number;
  releaseUPCs: string[];
  individual?: boolean;
}

export type TableTabOption = {
  tab: TabOption,
  limit: number,
  offset: number,
} & SortOption

export const TableTabOptionDefault: TableTabOption = {
  tab: TabOption.RELEASES,
  direction: 'asc',
  column: '',
  limit: 10,
  offset: 0,
}

export const Tabs = [
  {
    label: 'Reporting',
    value: 'reporting',
  },
  {
    label: 'Payouts',
    value: 'payouts',
  },
]

export const TableTabs = [
  {
    label: 'Releases',
    value: TabOption.RELEASES,
  },
  {
    label: 'Countries',
    value: TabOption.COUNTRIES,
  },
  {
    label: 'Stores',
    value: TabOption.STORES,
  },
  {
    label: 'Months',
    value: TabOption.MONTHS,
  },
]

export const TableTabSalesDefault = {
  count: 0,
  limit: TableTabOptionDefault.limit,
  offset: TableTabOptionDefault.offset,
  items: [],
}
export const RoyaltiesSalesFullOverviewDefault = {
  releases: { ...TableTabSalesDefault },
  countries: { ...TableTabSalesDefault },
  stores: { ...TableTabSalesDefault },
  months: { ...TableTabSalesDefault },
}
