import { ReactElement } from 'react'
import { Grid, Paper } from '@mui/material'
import { styled } from '@mui/system'
import { alpha } from '@mui/system/colorManipulator'

const Wrapper = styled(Paper)<{isSecond: boolean}>(({ isSecond }) => ({
  width: 280,
  boxShadow: isSecond ? '-20px -20px 80px rgba(128, 134, 140, 0.2)' : '-2px -2px 8px rgba(128, 134, 140, 0.2)',
}))

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}))

const StyledGrid = styled(Grid)(() => ({
  marginBottom: '17.5px',
  alignItems: 'center',
}))

const Circle = styled('div')(({ theme }) => ({
  width: '35px',
  height: '35px',
  borderRadius: '50%',
  backgroundColor: alpha(theme.palette.grey['100'], 0.7),
}))

const SmallText = styled('div')<{isBold?: boolean}>(({ theme, isBold = false }) => ({
  width: '100%',
  height: '11.67px',
  backgroundColor: alpha(theme.palette.grey['100'], isBold ? 0.7 : 0.4),
  borderRadius: '4.375px'
}))

const MediumText = styled('div')<{isBold?: boolean}>(({ theme, isBold = false }) => ({
  width: '100%',
  height: '14.58px',
  backgroundColor: alpha(theme.palette.grey['100'], isBold ? 0.7 : 0.4),
  borderRadius: '23px',
}))

const LargeText = styled('div')<{isBold?: boolean}>(({ theme, isBold = false }) => ({
  width: '100%',
  height: '17px',
  backgroundColor: alpha(theme.palette.grey['100'], isBold ? 0.7 : 0.4),
  borderRadius: '23px',
}))

const TextWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '3px',
  marginBottom: '17.5px',
}))

const Line = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.grey['100'], 0.4),
  width: '100%',
  height: '3px',
  borderRadius: '72.9167px',
  marginBottom: '6px'
}))

const Button = styled('div')<{isBold?: boolean}>(({ theme, isBold = false }) => ({
  width: '100%',
  height: '29.17px',
  backgroundColor: alpha(theme.palette.grey['100'], isBold ? 0.7 : 0.4),
  borderRadius: '24.375px'
}))

const PoolErrorWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '67px',
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(5)
}))

const SecondCard = styled('div')(() => ({
  transform: 'translate(-120px, 67px)'
}))

const PoolErrorCard = ({ isSecond = false }: { isSecond?: boolean}): ReactElement => {
  return (
    <Wrapper isSecond={isSecond}>
      <Content style={{paddingBottom: '0'}}>
        <StyledGrid container>
          <Grid xs={6}>
            <Circle />
          </Grid>
          <Grid xs={6}>
            <MediumText />
          </Grid>
        </StyledGrid>
        <StyledGrid container>
          <Grid xs={6}>
            <LargeText />
          </Grid>
        </StyledGrid>
        <TextWrapper>
          <MediumText style={{width: '44px'}} />
          <LargeText style={{width: '93px'}} isBold />
        </TextWrapper>
        <TextWrapper style={{marginBottom: '8.75px'}}>
          <MediumText style={{width: '44px'}} isBold />
        </TextWrapper>
        <Line />
        <StyledGrid container columnSpacing={5}>
          <Grid item xs={6}>
            <SmallText isBold />
          </Grid>
          <Grid item xs={6}>
            <SmallText />
          </Grid>
        </StyledGrid>
      </Content>
      <Line />
      <Content style={{paddingTop: '14.5px'}}>
        <StyledGrid container columnSpacing={7}>
          <Grid item xs={6}>
            <Button isBold />
          </Grid>
          <Grid item xs={6}>
            <Button />
          </Grid>
        </StyledGrid>
      </Content>
    </Wrapper>
  )
}
export default function PoolError(): ReactElement {
  return (
    <PoolErrorWrapper data-testid="pool-card-error">
      <PoolErrorCard />
      <SecondCard>
        <PoolErrorCard isSecond />
      </SecondCard>
    </PoolErrorWrapper>
  )
}