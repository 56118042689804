import React, { ReactElement, useEffect, useRef, useContext } from 'react'
import * as HighCharts from 'highcharts'
import {
  Box,
  Paper,
  Grow,
  Table,
  TableRow,
  TableBody,
  Skeleton,
  TableContainer,
  LinearProgress,
} from '@mui/material'
import { styled } from '@mui/system'
import { alpha } from '@mui/system/colorManipulator'
import { formatCurrency } from '@opulous/web/src/utils'
import { createHexColor } from '@opulous/web/src/shared/helpers'
import RoyaltiesContext from '@opulous/web/src/pages/Royalties/context'
import {
  Title,
  ProgressCell,
  StyledTableCell,
  TruncatedTableCell,
} from '@opulous/web/src/components/Royalties/BestCards/styled-components';

const BestStreamedReleasesContainer = styled(Paper)(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.grey[100]}`,
  padding: theme.spacing(2, 3),
}));

const Label = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const SKELETON_ROWS = new Array(5).fill(1)
type BestPerformingRelease = {
  label: string
  earning: number
  color: string
}

export default function BestStreamedReleases(): ReactElement {
  const { state: royaltiesContext } = useContext(RoyaltiesContext)
  const loading = royaltiesContext.tabLoading
  const data = royaltiesContext.royaltiesReleasesSalesSummary.map((it, ix) => ({
    label: it.releaseTitle,
    color: createHexColor(ix),
    earning: it.earning,
    percent: it.percent,
  }))

  const chartRef = useRef(null)
  const config: HighCharts.Options = {
    chart: {
      renderTo: 'container',
      type: 'pie',
      backgroundColor: 'transparent',
      height: 250,
      title: '',
      marginTop: 10,
      marginBottom: 10,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: 'Store',
        type: 'pie',
        data: data.map((item: BestPerformingRelease) => ({
          name: item.label,
          y: item.earning,
          color: item.color,
        })),
        dataLabels: {
          enabled: false,
        },
        borderWidth: 5,
        innerSize: 160,
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      borderRadius: 20,
      shadow: false,
      borderWidth: 0,
      padding: -10,
      backgroundColor: 'transparent',
      useHTML: true,
      formatter() {
        return `
          <div style="padding: 1rem; border-radius: 0.6rem; background-color: ${
            this.color
          }">
            <div style="color: white; margin-bottom: 10px">${this.key}</div>
            <div style="display: flex">
              <div>
                <div style="color: white">${formatCurrency(this.y)}</div>
              </div>
            </div>
          </div>
        `;
      },
    },
  } as HighCharts.Options

  useEffect(() => {
    if (chartRef.current) {
      HighCharts.chart(chartRef.current, config);
    }
  }, [loading]);

  return (
    <BestStreamedReleasesContainer data-testid="best-streamed-releases">
      <Title variant="subtitle2" data-testid="best-streamed-releases__title">
        Best streamed releases
      </Title>
      {loading ? (
        <Skeleton variant="rectangular" height={240} data-testid="best-streamed-releases__chart-skeleton"></Skeleton>
      ) : (
        <Grow in>
          <div ref={chartRef} />
        </Grow>
      )}

      <TableContainer>
        <Table data-testid="best-streamed-releases__table">
          <TableBody>
            {loading &&
              SKELETON_ROWS.map((it, index) => (
                <TableRow key={`table-item-${index}`}>
                  <StyledTableCell>
                    <Skeleton
                      variant="text"
                      height={40}
                      data-testid="best-streamed-releases__table-skeleton"
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            {!loading &&
              data.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow data-testid="best-streamed-releases__table__tr-data">
                    <TruncatedTableCell>
                      <Box
                        sx={{ display: 'inline-flex', alignItems: 'center' }}
                      >
                        <Box
                          sx={{
                            width: 20,
                            height: 15,
                            background: row.color,
                            borderRadius: 0.2,
                            marginRight: 1,
                          }}
                        />
                        <Label>{row.label}</Label>
                      </Box>
                    </TruncatedTableCell>
                    <StyledTableCell align="right">
                      {`${formatCurrency(row.earning)} (${row.percent}%)`}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow data-testid="best-streamed-releases__table__tr-progress">
                    <ProgressCell colSpan={2} sx={{ pt: 0 }}>
                      <LinearProgress
                        variant="determinate"
                        value={row.percent}
                        sx={{
                          background: alpha(row.color, 0.2),
                          '& .MuiLinearProgress-bar': {
                            background: row.color,
                          },
                        }}
                      />
                    </ProgressCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </BestStreamedReleasesContainer>
  );
}
