/* eslint-disable */
import { ReactElement } from 'react'
import { styled } from '@mui/system'
import { formatDate, calcVestingContractProgress } from '@opulous/web/src/utils'
import MinorOPUL from '@opulous/web/src/shared/valueObjects/MinorOPUL'

const LinearProgressContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(1),
}));
const LinearProgressLegend = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const StyledLinearProgress = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: theme.spacing(1),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.grey[200],
  marginBottom: theme.spacing(0.5),
}));
const AvailableAmount = styled('div')<{ value: number }>(
  ({ value, theme }) => ({
    height: theme.spacing(1),
    width: `${value}%`,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
  }),
);
const ClaimedAmount = styled('div')<{ value: number }>(({ value, theme }) => ({
  height: theme.spacing(1),
  width: `${value}%`,
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.primary.dark,
  marginBottom: theme.spacing(0.5),
}));

type Props = {
  totalOpul: MinorOPUL;
  claimedOpul: MinorOPUL;
  toClaimOpul: MinorOPUL;
  startAt: Date;
  endAt: Date;
};
export default function Progress({
  totalOpul,
  claimedOpul,
  toClaimOpul,
  startAt,
  endAt,
}: Props): ReactElement {
  const claimedProgress = calcVestingContractProgress(totalOpul, claimedOpul);
  const availableProgress = calcVestingContractProgress(totalOpul, toClaimOpul);
  return (
    <LinearProgressContainer data-testid="vesting-progress">
      <StyledLinearProgress>
        {claimedProgress > 0 && (
          <ClaimedAmount
            data-testid="vesting-progress__linear-claimed"
            value={claimedProgress}
          />
        )}
        {availableProgress > 0 && (
          <AvailableAmount
            data-testid="vesting-progress__linear-available"
            value={availableProgress}
          />
        )}
      </StyledLinearProgress>
      <LinearProgressLegend>
        <span data-testid="vesting-progress__startat">{formatDate(startAt)}</span>
        <span data-testid="vesting-progress__endat">{formatDate(endAt)}</span>
      </LinearProgressLegend>
    </LinearProgressContainer>
  );
}
