import { ReactElement } from 'react';
import { styled } from '@mui/system';
import { WalletTypeEnum } from '@opulous/web/src/shared/types';
import StepWrapper from '@opulous/web/src/components/shared/StepWrapper';
import { WalletOption } from '@opulous/web/src/components/Claims/Steps/components';
import { AvailableWalletsMeta } from '@opulous/web/src/components/Common/ConnectWallet';

const DialogOverlay = styled('div')(() => ({
  position: 'fixed',
  zIndex: 9999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0,0,0,.5)',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  opacity: 0,
}));

type Props = {
  onClick: () => void;
  onClose: () => void;
  onConnect: (type: WalletTypeEnum) => void;
  style?: React.CSSProperties;
};
export function DialogConnectWallet({
  style,
  onClick,
  onClose,
  onConnect,
}: Props): ReactElement {
  return (
    <DialogOverlay onClick={onClick} style={style}>
      <div onClick={e => e.stopPropagation()}>
        <StepWrapper
          title="Connect wallet"
          loading={false}
          errorText={''}
          actions={AvailableWalletsMeta.map(wt => (
            <WalletOption
              key={wt.key}
              type={wt.key}
              name={wt.name}
              image={wt.image}
              active={wt.active}
              onClick={onConnect}
            />
          ))}
          onClose={onClose}
        />
      </div>
    </DialogOverlay>
  );
}
