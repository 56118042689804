import React, { ReactNode } from 'react'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { styled } from '@mui/system'
import Collapse from '@mui/material/Collapse'
import { Alert } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(5),
  maxWidth: theme.spacing(46.875),
  width: '100%',
  borderRadius: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
}))

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 600,
  fontSize: theme.spacing(3),
  lineHeight: '28px',
}))

const Content = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2.5, 0),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5, 0),
  },
}))

const Actions = styled(CardActions)(() => ({
  padding: '0 !important',
}))

const Toast = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(4),
  width: 'fit-content',
  padding: theme.spacing(0.5, 1.5),
  marginBottom: theme.spacing(2.5),
  '& span': {
    fontSize: theme.spacing(1.75),
  },
}))

const StyledAlert = styled(Alert)(({ theme }) => ({
  color: theme.palette.error.dark,
  fontWeight: 600,
  paddingLeft: 11,
  marginBottom: 40,
  '& a': {
    color: theme.palette.error.dark,
    fontWeight: 600,
  },
  '& .MuiAlert-icon': {
    marginRight: 9,
    fontSize: '16px',
    paddingTop: 11,
  },
}))

const StyledCloseIconContainer = styled('div')(() => ({
  textAlign: 'right',
  cursor: 'pointer',
}))

// const SuccessToast = styled(Toast)(({ theme }) => ({
//   background: `${theme.palette.success.main}30`,
//   '& span': {
//     color: theme.palette.success.main,
//   },
// }))

const InfoToast = styled(Toast)(({ theme }) => ({
  background: `${theme.palette.info.main}30`,
  '& span': {
    color: theme.palette.info.main,
  },
}))

interface IClaimWrapper {
  title: string
  children?: ReactNode
  actions?: ReactNode | ReactNode[]
  loading: boolean
  errorText?: string
  infoText?: string
  onClose?: () => void
}

const StepWrapper: React.FC<IClaimWrapper> = ({
  title,
  children,
  actions,
  errorText,
  infoText,
  onClose,
}) => {
  // const classes = useStyles()

  return (
    <StyledCard>
      {onClose && (
        <StyledCloseIconContainer onClick={onClose}>
          <CloseIcon />
        </StyledCloseIconContainer>
      )}

      <Title>{title}</Title>
      <Content>{children}</Content>
      <Collapse in={!!infoText}>
        <InfoToast>
          <Typography component="span">{infoText}</Typography>
        </InfoToast>
      </Collapse>
      <Collapse in={!!errorText}>
        <StyledAlert variant="filled" severity="error">
          There was an error with the claiming process. Send us an email at{' '}
          <a href="mailto:support@opulous.org">support@opulous.org</a> for more
          information.
        </StyledAlert>
      </Collapse>
      <Actions>{actions}</Actions>
    </StyledCard>
  )
}

export default StepWrapper
