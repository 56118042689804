import React, { useState } from 'react';
import { styled } from '@mui/system';
import * as Yup from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {
  IconButton,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Link as _Link, useLocation, useNavigate } from 'react-router-dom';
import * as AssetService from 'src/services/assets';
import { AssetType, ICreateAsset, IAssetField } from 'src/shared/types';
import { validateField } from 'src/utils';
import ErrorIcon from '@mui/icons-material/Error';
import * as Sentry from '@sentry/react';
import axios from 'axios';

interface IValidationErrors {
  assetId: string;
  type: string;
  unitName: string;
  nftCount: string;
  percentCopyright: string;
  securitizeTokenId: string;
  parentId: string;
  upc: string;
  releaseTitle: string;
  artistName: string;
  royaltyCopyrightShare: string;

  customDescription: string;
  customFields: string;
  customContent: string;
  customSidebar: string;
}

const ErrorMessage = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `2px solid ${theme.palette.error.main}`,
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.error.light,
  padding: theme.spacing(2),
  color: theme.palette.error.main,
  gap: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));

const Link = styled(_Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  gap: theme.spacing(1),

  '& > svg': {
    width: '1rem',
    height: '1rem',
  },
}));

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
  maxWidth: '50rem',
}));

const Fieldset = styled('fieldset')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  color: theme.palette.text.primary,
  display: 'block',
  position: 'relative',
  minWidth: 0,

  '&:hover': {
    border: `1px solid ${theme.palette.common.black}`,
  },

  '&.error': {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },

  '& > legend': {
    fontSize: '.75rem',
    fontWeight: '300',
  },
}));

const ErrText = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: '700',
  fontSize: '.9rem',
}));

function checkIsValidJsonString(x: string): boolean {
  try {
    JSON.parse(x);
    return true;
  } catch {
    return false;
  }
}

const NULLABLE_FIELDS = new Set([
  'securitizeTokenId',
  'parentId',
  'upc',
  'releaseTitle',
  'artistName',
  'royaltyCopyrightShare',
]);

const fieldValidators = {
  assetId: Yup.number()
    .min(1)
    .max(Math.pow(2, 31) - 1)
    .typeError('Number expected')
    .required('This field is required'),
  type: Yup.mixed()
    .oneOf(['mft', 'artwork', 'ovault'])
    .required('This field is required'),
  unitName: Yup.string()
    .min(1)
    .matches(/^[A-Z0-9]+$/, 'Only uppercase letters and numbers are allowed')
    .required('This field is required'),
  nftCount: Yup.number()
    .min(1)
    .typeError('Number expected')
    .required('This field is required'),
  percentCopyright: Yup.number()
    .typeError('Number expected')
    .required('This field is required'),
  securitizeTokenId: Yup.string().uuid(),
  parentId: Yup.number().min(1).typeError('Number expected'),
  upc: Yup.string(),
  releaseTitle: Yup.string(),
  artistName: Yup.string(),
  royaltyCopyrightShare: Yup.number().typeError('Number expected'),

  customDescription: Yup.string(),
  customFields: Yup.array()
    .of(
      Yup.object()
        .shape({
          label: Yup.string().required(
            'Empty label ,value or identifier are not allowed',
          ),
          value: Yup.string().required(
            'Empty label ,value or identifier are not allowed',
          ),
          identifier: Yup.string().required(
            'Empty label ,value or identifier are not allowed',
          ),
        })
        .required(),
    )
    .test(
      'Duplicated labels',
      'Duplicated labels',
      xs => !xs || new Set(xs.map(x => x.label)).size === xs.length,
    ),
  customContent: Yup.string().test(
    'Invalid JSON',
    'Invalid JSON',
    x => !x || checkIsValidJsonString(x),
  ),
  customSidebar: Yup.string().test(
    'Invalid JSON',
    'Invalid JSON',
    x => !x || checkIsValidJsonString(x),
  ),
};

export function FieldRow({
  label,
  value,
  identifier,
  onLabelChange,
  onValueChange,
  onIdentifierChange,
  onPairDelete,
}: {
  label: string;
  value: string;
  identifier: string;
  onLabelChange: (s: string) => void;
  onValueChange: (s: string) => void;
  onIdentifierChange: (s: string) => void;
  onPairDelete: () => void;
}) {
  return (
    <Box display="flex" flexWrap="wrap" gap={2} paddingX={2} paddingY={1}>
      <Box display="flex" flex={1} minWidth="10rem">
        <TextField
          label="Label"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={label}
          onChange={e => onLabelChange(e.target.value)}
        />
      </Box>

      <Box display="flex" flex={1} minWidth="10rem">
        <TextField
          label="Value"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={value}
          onChange={e => onValueChange(e.target.value)}
        />
      </Box>

      <Box display="flex" flex={1} minWidth="10rem">
        <TextField
          label="Identifier"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={identifier}
          onChange={e => onIdentifierChange(e.target.value)}
        />
      </Box>

      <Box display="flex" alignItems="center">
        <IconButton color="primary" size="small" onClick={onPairDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default function AssetCreate(): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const [assetId, setAssetId] = useState('');
  const [type, setType] = useState<AssetType>(AssetType.artwork);
  const [unitName, setUnitName] = useState('');
  const [nftCount, setNftCount] = useState('');
  const [percentCopyright, setPercentCopyright] = useState('');
  const [securitizeTokenId, setSecuritizeTokenId] = useState('');
  const [parentId, setParentId] = useState('');
  const [upc, setUpc] = useState('');
  const [releaseTitle, setReleaseTitle] = useState('');
  const [artistName, setArtistName] = useState('');
  const [royaltyCopyrightShare, setRoyaltyCopyrightShare] = useState('');
  const [customDescription, setCustomDescription] = useState('');
  const [customFields, setCustomFields] = useState<IAssetField[]>([]);
  const [customContent, setCustomContent] = useState('');
  const [customSidebar, setCustomSidebar] = useState('');
  const [validationErrors, setValidationErrors] = useState<
    Partial<IValidationErrors>
  >({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionErrorMessage, setSubmissionErrorMessage] =
    useState<string>('');

  const markDirty = (name: keyof IValidationErrors) => {
    setValidationErrors(errs => {
      delete errs[name];
      return { ...errs };
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errs: Partial<IValidationErrors> = {};

    for (const [name, value] of [
      ['assetId', assetId],
      ['type', type],
      ['unitName', unitName],
      ['nftCount', nftCount],
      ['percentCopyright', percentCopyright],
      ['securitizeTokenId', securitizeTokenId],
      ['parentId', parentId],
      ['upc', upc],
      ['releaseTitle', releaseTitle],
      ['artistName', artistName],
      ['royaltyCopyrightShare', royaltyCopyrightShare],
      ['customDescription', customDescription],
      ['customFields', customFields],
      ['customContent', customContent],
      ['customSidebar', customSidebar],
    ] as [keyof typeof fieldValidators, any][]) {
      if (NULLABLE_FIELDS.has(name) && !value) continue;
      const msg = await validateField(fieldValidators[name], value);
      if (msg) {
        errs[name] = msg;
      }
    }

    setValidationErrors(errs);

    if (Object.keys(errs).length) return;

    try {
      setIsSubmitting(true);
      const data: ICreateAsset = {
        id: parseInt(assetId),
        type,
        unitName,
        nftCount: parseInt(nftCount),
        percentCopyright: parseFloat(percentCopyright),
        securitizeTokenId: securitizeTokenId || null,
        parentId: parentId ? parseInt(parentId) : null,
        upc: upc || null,
        releaseTitle: releaseTitle || null,
        artistName: artistName || null,
        royaltyCopyrightShare: royaltyCopyrightShare
          ? parseFloat(royaltyCopyrightShare)
          : null,
        custom: {
          fields: customFields,
          ...(releaseTitle ? { title: releaseTitle } : null),
          ...(customDescription ? { description: customDescription } : null),
          ...(artistName ? { artist: artistName } : null),
          ...(customContent ? { content: JSON.parse(customContent) } : null),
          ...(customSidebar ? { sidebar: JSON.parse(customSidebar) } : null),
        },
      };

      await AssetService.adminCreateAsset(data);

      navigate('/admin/assets');
      setIsSubmitting(false);
    } catch (err) {
      if (
        axios.isAxiosError(err) &&
        err.response?.status === 400 &&
        err.response?.data?.errors?.length &&
        err.response?.data?.errors?.[0]?.message
      ) {
        setSubmissionErrorMessage(err.response?.data?.errors?.[0]?.message);
      } else {
        setSubmissionErrorMessage(
          'Unexpected error occurred, please try again later.',
        );
      }
      Sentry.captureException(err);
      setIsSubmitting(false);
    }
  };

  const handleFieldRowAdd = () => {
    markDirty('customFields');
    setCustomFields(xs => [...xs, { label: '', value: '', identifier: '' }]);
  };

  const handleFieldRowDelete = (n: number) => () => {
    markDirty('customFields');
    setCustomFields(xs => xs.filter((_, i) => i !== n));
  };

  const handleFieldLabelChange = (n: number) => (s: string) => {
    markDirty('customFields');
    setCustomFields(xs => xs.map((x, i) => (i === n ? { ...x, label: s } : x)));
  };

  const handleFieldValueChange = (n: number) => (s: string) => {
    markDirty('customFields');
    setCustomFields(xs => xs.map((x, i) => (i === n ? { ...x, value: s } : x)));
  };

  const handleIdentifierChange = (n: number) => (s: string) => {
    markDirty('customFields');
    setCustomFields(xs =>
      xs.map((x, i) => (i === n ? { ...x, identifier: s } : x)),
    );
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={3}>
      <Form onSubmit={handleSubmit}>
        <div>
          <Link to={(location.state as any)?.back ?? '/admin/assets'}>
            <ArrowBackIcon />
            <span>Back</span>
          </Link>
        </div>
        <div>
          <h1>Create Asset</h1>
        </div>
        <TextField
          label="ID"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.assetId}
          helperText={validationErrors.assetId}
          value={assetId}
          onChange={e => {
            markDirty('assetId');
            setAssetId(e.target.value);
          }}
        />
        <FormControl>
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            label="Type"
            onChange={e => {
              markDirty('type');
              setType(e.target.value as AssetType);
            }}
          >
            <MenuItem value={AssetType.artwork}>{AssetType.artwork}</MenuItem>
            <MenuItem value={AssetType.mft}>{AssetType.mft}</MenuItem>
            <MenuItem value={AssetType.ovault}>{AssetType.ovault}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Unit Name"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.unitName}
          helperText={validationErrors.unitName}
          value={unitName}
          onChange={e => {
            markDirty('unitName');
            setUnitName(e.target.value);
          }}
        />
        <TextField
          label="Title (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.releaseTitle}
          helperText={validationErrors.releaseTitle}
          value={releaseTitle}
          onChange={e => {
            markDirty('releaseTitle');
            setReleaseTitle(e.target.value);
          }}
        />
        <TextField
          label="Artist (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.artistName}
          helperText={validationErrors.artistName}
          value={artistName}
          onChange={e => {
            markDirty('artistName');
            setArtistName(e.target.value);
          }}
        />
        <TextField
          label="Description (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.customDescription}
          helperText={validationErrors.customDescription}
          rows={8}
          multiline
          value={customDescription}
          onChange={e => {
            markDirty('customDescription');
            setCustomDescription(e.target.value);
          }}
        />
        <Fieldset>
          <legend>Fields</legend>
          <Box paddingY={1}>
            {customFields.map((r, i) => (
              <FieldRow
                key={`field-row-${i}`}
                label={r.label}
                value={r.value}
                identifier={r.identifier}
                onLabelChange={handleFieldLabelChange(i)}
                onValueChange={handleFieldValueChange(i)}
                onIdentifierChange={handleIdentifierChange(i)}
                onPairDelete={handleFieldRowDelete(i)}
              />
            ))}
          </Box>
          {validationErrors.customFields && (
            <Box paddingX={2} paddingY={1}>
              <ErrText>{validationErrors.customFields}</ErrText>
            </Box>
          )}
          <Box paddingX={2} paddingY={1}>
            <Button color="primary" size="small" onClick={handleFieldRowAdd}>
              <AddIcon />
              <Box paddingLeft={1}>Add Field</Box>
            </Button>
          </Box>
        </Fieldset>
        <TextField
          label="Content (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.customContent}
          helperText={validationErrors.customContent}
          rows={8}
          multiline
          value={customContent}
          onChange={e => {
            markDirty('customContent');
            setCustomContent(e.target.value);
          }}
        />
        <TextField
          label="Sidebar (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.customSidebar}
          helperText={validationErrors.customSidebar}
          rows={8}
          multiline
          value={customSidebar}
          onChange={e => {
            markDirty('customSidebar');
            setCustomSidebar(e.target.value);
          }}
        />
        <TextField
          label="Number of NFT"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.nftCount}
          helperText={validationErrors.nftCount}
          value={nftCount}
          onChange={e => {
            markDirty('nftCount');
            setNftCount(e.target.value);
          }}
        />
        <TextField
          label="Copyright %"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.percentCopyright}
          helperText={validationErrors.percentCopyright}
          value={percentCopyright}
          onChange={e => {
            markDirty('percentCopyright');
            setPercentCopyright(e.target.value);
          }}
        />
        <TextField
          label="Parent ID (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.parentId}
          helperText={validationErrors.parentId}
          value={parentId}
          onChange={e => {
            markDirty('parentId');
            setParentId(e.target.value);
          }}
        />
        <TextField
          label="UPC (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.upc}
          helperText={validationErrors.upc}
          value={upc}
          onChange={e => {
            markDirty('upc');
            setUpc(e.target.value);
          }}
        />
        <TextField
          label="Securitize Token ID (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.securitizeTokenId}
          helperText={validationErrors.securitizeTokenId}
          value={securitizeTokenId}
          onChange={e => {
            markDirty('securitizeTokenId');
            setSecuritizeTokenId(e.target.value);
          }}
        />
        <TextField
          label="Royalty Copyright % (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.royaltyCopyrightShare}
          helperText={validationErrors.royaltyCopyrightShare}
          value={royaltyCopyrightShare}
          onChange={e => {
            markDirty('royaltyCopyrightShare');
            setRoyaltyCopyrightShare(e.target.value);
          }}
        />

        {!!submissionErrorMessage && (
          <ErrorMessage>
            <ErrorIcon />
            <Box flex="1">{submissionErrorMessage}</Box>
          </ErrorMessage>
        )}

        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <CircularProgress size="1.5rem" color="primary" />
          ) : (
            'Submit'
          )}
        </Button>
      </Form>
    </Box>
  );
}
