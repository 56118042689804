import React from 'react';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/system';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { PoolOption } from '@opulous/web/src/shared/types';

const Wrapper = styled(TabList)(({ theme }) => ({
  position: 'relative',
  '& .MuiTabs-scrollButtons': {
    display: 'flex !important',
  },
  width: 'calc(100% + 48px)',
  [theme.breakpoints.down('md')]: {
    maxWidth: 'calc(100vw - 30px)',
  },
  margin: '-18px -24px -18px -24px',
  height: '84px',
  '.MuiTabs-flexContainer': {
    height: '100%',
  },
  '.MuiTabs-indicator, hr': {
    display: 'none',
  },
}));

interface TabProps {
  theme: Theme;
  width: string;
}

const StyledTab = styled(Tab)(({ theme, width }: TabProps) => ({
  textTransform: 'capitalize',
  fontSize: theme.spacing(1.875),
  fontWeight: 600,
  padding: theme.spacing(1.5, 3),
  width: width,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(255, 84, 0, 0.08)',
  },
  height: '100%',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  borderBottom: `1.5px solid ${theme.palette.grey[300]}`,
  position: 'absolute',
  top: '97%',
}));

const StakesCount = styled('div')(() => ({
  fontWeight: '600',
  fontSize: '18px',
  marginLeft: '320px',
  opacity: '0.5',
}));

interface TabBarProps {
  tabs: PoolOption[];
  onChangTab: (value: string) => void;
  tabWidth?: string;
}

const TabBar: React.FC<TabBarProps> = ({
  tabs,
  onChangTab,
  tabWidth = 'auto',
}) => {
  const theme = useTheme() as Theme;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    onChangTab(newValue);
  };

  return (
    <Wrapper onChange={handleChange} variant="scrollable" scrollButtons="auto">
      <StyledDivider />
      {tabs.map(tab =>
        tab.stakes ? (
          <StyledTab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            theme={theme}
            width={tabWidth}
            disableRipple
            icon={<StakesCount>{tab.stakes}</StakesCount>}
            iconPosition="end"
          />
        ) : (
          <StyledTab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            theme={theme}
            width={tabWidth}
            disableRipple
          />
        ),
      )}
    </Wrapper>
  );
};

export default TabBar;
