declare global {
  interface Window {
      Ultrade: {
          [key in WalletKeys]?: PeraWalletSign;
      };
  }
}

type PeraWalletSign = (txGroups: any[][], signerAddress?: string | undefined) => Promise<Uint8Array[]>;

export enum WalletKeys {
  Pera = 'PeraWallet.Wallet',
};