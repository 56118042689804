import axios, { AxiosResponse } from 'axios'
import { WalletTypeEnum } from '@opulous/web/src/shared/types'
import { getApiBaseUrl } from '@opulous/web/src/axios-setup'

const requestCode = (email: string): Promise<AxiosResponse> => {
  return axios.post(`${getApiBaseUrl()}/api/investor/request-code`, { email })
}

const verifyCode = (email: string, code: string): Promise<AxiosResponse> => {
  return axios.post(`${getApiBaseUrl()}/api/investor/verify-code`, {
    email,
    code,
  })
}

const updateProfile = (
  email: string,
  verificationCode: string,
  walletAddress: string,
  walletType: WalletTypeEnum|undefined,
  countryCode: string,
  taxId: string
): Promise<AxiosResponse> => {
  return axios.patch(`${getApiBaseUrl()}/api/investor/profile`, {
    email,
    code: verificationCode,
    walletAddress,
    walletType,
    countryCode,
    taxId,
  })
}

export default {
  requestCode,
  verifyCode,
  updateProfile,
}
