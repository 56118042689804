import { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const Styledooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export function TwitterButton({ ['data-testid']: dataTestid, onClick }: { 'data-testid'?: string, onClick: () => void }): ReactElement {
  return (
    <Styledooltip title="Share on Twitter" placement="top">
      <a data-testid={dataTestid || 'popover-share__twitter-button'} onClick={() => onClick()}>
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none">
          <path
            d="M15.792 4.38635C15.7989 4.54475 15.7989 4.69595 15.7989 4.85435C15.8059 9.65676 12.2775 15.2008 5.82509 15.2008C3.922 15.2008 2.05365 14.632 0.449219 13.5664C0.727042 13.6024 1.00486 13.6168 1.28269 13.6168C2.85933 13.6168 4.3943 13.0696 5.63756 12.0544C4.13732 12.0256 2.81766 11.0104 2.35925 9.52716C2.88711 9.63516 3.42887 9.61356 3.94284 9.46236C2.31063 9.13116 1.13683 7.64076 1.12988 5.90555C1.12988 5.89115 1.12988 5.87675 1.12988 5.86235C1.61607 6.14315 2.16478 6.30155 2.72042 6.31595C1.18545 5.25035 0.706205 3.12634 1.63691 1.46314C3.42192 3.73834 6.04735 5.11355 8.86725 5.26475C8.58248 4.00475 8.97144 2.67994 9.88131 1.78714C11.2913 0.411934 13.5138 0.483934 14.8474 1.94554C15.6322 1.78714 16.3893 1.48474 17.0769 1.05994C16.813 1.90234 16.2643 2.61514 15.535 3.06874C16.2296 2.98234 16.9102 2.78794 17.5492 2.49994C17.0769 3.23434 16.4796 3.86795 15.792 4.38635Z"
            fill="#23262F"
            />
        </svg>
      </a>
    </Styledooltip>
  );
}

export function FacebookButton({ ['data-testid']: dataTestid, onClick }: { 'data-testid'?: string, onClick: () => void }): ReactElement {
  return (
    <Styledooltip title="Share on Facebook" placement="top">
      <a data-testid={dataTestid || 'popover-share__facebook-button'} onClick={() => onClick()}>
        <svg width="10" height="19" viewBox="0 0 10 19" fill="none">
          <path
            d="M9.23613 10.3416L9.74144 7.01432H6.58048V4.85607C6.58048 3.94556 7.02123 3.05752 8.43699 3.05752H9.875V0.224818C9.875 0.224818 8.57055 0 7.32397 0C4.71952 0 3.01884 1.59396 3.01884 4.47837V7.01432H0.125V10.3416H3.01884V18.3856C3.59983 18.4778 4.19418 18.525 4.79966 18.525C5.40514 18.525 5.99949 18.4778 6.58048 18.3856V10.3416H9.23613Z"
            fill="#23262F"
          />
        </svg>
      </a>
    </Styledooltip>
  );
}

export function LinkedInButton({ ['data-testid']: dataTestid, onClick }: { 'data-testid'?: string, onClick: () => void }): ReactElement {
  return (
    <Styledooltip title="Share on LinkedIn" placement="top">
      <a data-testid={dataTestid || 'popover-share__linkedin-button'} onClick={() => onClick()}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path
            d="M0 2.40139C0 1.82223 0.202708 1.34443 0.608108 0.967992C1.01351 0.591538 1.54055 0.40332 2.18919 0.40332C2.82626 0.40332 3.34169 0.588637 3.73552 0.959305C4.14092 1.34154 4.34363 1.8396 4.34363 2.45351C4.34363 3.0095 4.14672 3.47281 3.7529 3.84347C3.3475 4.22571 2.81467 4.41683 2.15444 4.41683H2.13707C1.49999 4.41683 0.984562 4.22571 0.590734 3.84347C0.196905 3.46124 0 2.98053 0 2.40139ZM0.225869 17.6041V5.99791H4.08301V17.6041H0.225869ZM6.22008 17.6041H10.0772V11.1234C10.0772 10.718 10.1236 10.4052 10.2162 10.1852C10.3784 9.79134 10.6245 9.45833 10.9546 9.18614C11.2847 8.91393 11.6988 8.77784 12.1969 8.77784C13.4942 8.77784 14.1429 9.65235 14.1429 11.4014V17.6041H18V10.9497C18 9.23536 17.5946 7.93517 16.7838 7.04907C15.973 6.16297 14.9015 5.71992 13.5695 5.71992C12.0753 5.71992 10.9112 6.36278 10.0772 7.64849V7.68324H10.0598L10.0772 7.64849V5.99791H6.22008C6.24324 6.36857 6.25483 7.52107 6.25483 9.45544C6.25483 11.3898 6.24324 14.106 6.22008 17.6041Z"
            fill="#23262F"
          />
        </svg>
      </a>
    </Styledooltip>
  );
}

export function CopyLinkButton({ ['data-testid']: dataTestid, onClick }: { 'data-testid'?: string, onClick: () => void }): ReactElement {
  return (
    <Styledooltip title="Copy link" placement="top">
      <a data-testid={dataTestid || 'popover-share__copy-link-button'} onClick={() => onClick()}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.3227 4.55811C14.2884 2.59248 17.4753 2.59248 19.4409 4.55811C21.4066 6.52374 21.4066 9.71066 19.4409 11.6763L18.7938 12.3234C18.2577 12.8595 17.3886 12.8595 16.8525 12.3234C16.3164 11.7873 16.3164 10.9182 16.8525 10.3821L17.4996 9.73497C18.3931 8.8415 18.3931 7.3929 17.4996 6.49943C16.6061 5.60597 15.1575 5.60597 14.2641 6.49943L11.6756 9.08786C10.7822 9.98133 10.7822 11.4299 11.6756 12.3234L9.73432 14.2647C7.76869 12.2991 7.76869 9.11217 9.73432 7.14654L12.3227 4.55811Z"
            fill="#23262F"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.6763 19.439C9.71065 21.4046 6.52373 21.4046 4.5581 19.439C2.59247 17.4733 2.59247 14.2864 4.5581 12.3208L5.20521 11.6737C5.74129 11.1376 6.61045 11.1376 7.14653 11.6737C7.68261 12.2098 7.68261 13.0789 7.14653 13.615L6.49942 14.2621C5.60595 15.1556 5.60595 16.6042 6.49942 17.4976C7.39289 18.3911 8.84149 18.3911 9.73496 17.4976L12.3234 14.9092C13.2169 14.0157 13.2169 12.5671 12.3234 11.6737L14.2647 9.73235C16.2303 11.698 16.2303 14.8849 14.2647 16.8505L11.6763 19.439Z"
            fill="#23262F"
          />
        </svg>
      </a>
    </Styledooltip>
  );
}
