import * as React from 'react';

const ApyIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 15"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.644 8.308c.057-.457-.321-.831-.782-.831H7.86a.834.834 0 0 1-.834-.834V1.639c0-.46-.375-.84-.832-.782a6.671 6.671 0 1 0 7.451 7.451Z"
    />
    <path
      fill="currentColor"
      d="M9.175 1.667c.054-.457.471-.792.913-.663a5.003 5.003 0 0 1 3.326 3.15c.153.434-.158.869-.612.947l-3.01.519a.834.834 0 0 1-.97-.919l.353-3.034Z"
    />
  </svg>
)

export default ApyIcon;
