import { ReactElement } from 'react';
import { LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import { formatOpulAmount } from '@opulous/web/src/utils';
import MinorOPUL from '@opulous/web/src/shared/valueObjects/MinorOPUL';

const LinearProgressContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(3),
}));
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: theme.spacing(1),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.grey[200],
  span: {
    backgroundColor: theme.palette.primary.dark,
  },
  marginBottom: theme.spacing(0.5),
}));
const LinearProgressLegend = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  span: {
    fontSize: theme.spacing(1.5),
    lineHeight: 1.3,
  },
}));

type Props = {
  amount: MinorOPUL;
};
export default function PreClaimed({ amount }: Props): ReactElement {
  return (
    <LinearProgressContainer data-testid="vesting-pre-claimed">
      <StyledLinearProgress
        data-testid="vesting-pre-claimed__linear-progress"
        variant="determinate"
        value={100}
      />
      <LinearProgressLegend>
        <span data-testid="vesting-pre-claimed__amount">
          Pre-distributed <br />({formatOpulAmount(amount)}) OPUL
        </span>
      </LinearProgressLegend>
    </LinearProgressContainer>
  );
}
