import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const MobileOnly = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('lg')]: {
    display: 'block',
  },
}));

export const DesktopOnly = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
}));
