import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import { Link } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { Pool } from '@opulous/web/src/shared/types'
import { displayCurrency } from '@opulous/web/src/utils'
import StyledLoadingButton from '@opulous/web/src/components/Common/StyledLoadingButton'

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(2),
    width: 480,
    maxWidth: 480,
  },
}))

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: `${theme.spacing(1.75, 3)} !important`,
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(1.5)} !important`,
  },
}))

export const StyledDialogAction = styled(DialogActions)(({ theme }) => ({
  padding: `${theme.spacing(0, 3, 3)} !important`,
}))

export const DialogTitleText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
}))

export const StyledContent = styled(DialogContent)(({ theme }) => ({
  padding: `${theme.spacing(3.5, 3)} !important`,
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(2.5, 1.5)} !important`,
  },
}))

export const DialogCheckboxContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'start',
}))

export const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  background: theme.palette.grey['A100'],
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(3),
}))

export const DialogCheckboxText = styled(Typography)(() => ({}))

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.black,
}))

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: theme.spacing(0),
  marginRight: theme.spacing(1),
}))

export const GreyText = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
}))

export const SmallText = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: theme.spacing(1.75),
}))

interface InvestModalProps {
  open: boolean
  onClose: (value: boolean) => void
  pool?: Pool
}

const InvestModal: React.FC<InvestModalProps> = ({ open, pool, onClose }) => {
  const [checked, setChecked] = useState<boolean>(false)

  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <DialogTitleText variant="h1" textAlign="center">
          Stake in pool
        </DialogTitleText>
        <IconButton size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </StyledDialogTitle>
      <StyledContent>
        <StyledContainer>
          <SmallText>Amount</SmallText>
          <div>
            <GreyText>
              {pool ? displayCurrency(pool.currencyUnitName) : ''}
            </GreyText>{' '}
            1,000
          </div>
        </StyledContainer>
        <DialogCheckboxContainer>
          <StyledCheckbox
            checked={checked}
            onChange={() => setChecked(!checked)}
            sx={{ paddingTop: 0 }}
          />
          <DialogCheckboxText
            variant="body1"
            onClick={() => setChecked(!checked)}
          >
            I agree with Opulous'{' '}
            <StyledLink to={'/terms'} target="_blank">
              Terms & Conditions
            </StyledLink>
          </DialogCheckboxText>
        </DialogCheckboxContainer>
      </StyledContent>
      <StyledDialogAction>
        <StyledLoadingButton
          size="small"
          variant="contained"
          disabled={!checked}
          onClick={() => onClose(true)}
          borderRadius="32px"
        >
          Invest
        </StyledLoadingButton>
      </StyledDialogAction>
    </StyledDialog>
  )
}

export default InvestModal
