import { SchemaOf } from 'yup';
import * as bigNumber from 'bignumber.js';
import { format, secondsInHour, addMinutes } from 'date-fns';
import MinorOPUL from '../shared/valueObjects/MinorOPUL';
import MicroUSDC from '../shared/valueObjects/MicroUSDC';
import USDC from '../shared/valueObjects/USDC';
import {
  InvestmentAvailability,
  InvestmentDetails,
  InvestmentStatus,
} from '@opulous/web/src/shared/types';

bigNumber.BigNumber.config({
  FORMAT: {
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  },
});

export function formatNumberWithFixedDecimalPlaces(
  value: number,
  places: number,
  withSeparator = true,
): string {
  return new bigNumber.BigNumber(value).toFormat(
    places,
    bigNumber.BigNumber.ROUND_DOWN,
    {
      decimalSeparator: '.',
      groupSeparator: withSeparator ? ',' : '',
      groupSize: 3,
      fractionGroupSize: places,
    },
  );
}

export const formatNumber = (value: number | bigint): string => {
  return value.toLocaleString('en');
};

export const formatFloat = (value: number, fraction?: number): string => {
  return value.toLocaleString('en', {
    minimumFractionDigits: fraction || 2,
  });
};

export const formatCurrency = (
  value: number,
  currency = '$',
  fractionDigits = 2,
): string => {
  return `${currency}${value.toLocaleString('en', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })}`;
};

export const formatMicroUSDC = (
  value: MicroUSDC,
  currency = '$',
  fractionDigits = 2,
): string => {
  return `${currency}${USDC.fromMicroUSDC(value.toBigInt())
    .toNumber()
    .toLocaleString('en', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    })}`;
};

export const isInclude = (str: string, searchTerm: string): boolean => {
  if (!str) return true;

  return str.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
};

export const setLocalStorage = (key: string, value: unknown): void => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // catch possible errors:
  }
};

export const getLocalStorage = (key: string, initialValue: string): string => {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    // if error, return initial value
    return initialValue;
  }
};

export const clearLocalStorage = (key: string): void => {
  window.localStorage.removeItem(key);
};

export const formatOpulAmount = (
  amount: number | MinorOPUL | string,
  places?: number,
): string => {
  const decimals = 10;
  const precision = 10;

  const opulAmount = new bigNumber.BigNumber(amount.toString()).div(
    Math.pow(precision, decimals),
  );

  if (places) {
    return opulAmount.toFormat(places, bigNumber.BigNumber.ROUND_DOWN, {
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
      fractionGroupSize: places,
    });
  }

  return opulAmount
    .toFormat(10, bigNumber.BigNumber.ROUND_DOWN, {
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
      fractionGroupSize: 10,
    })
    .replace(/(\.\d{2}(?:\d*[1-9])?)0*$/g, (_matches, g1) => g1);
};

export const formatUSDCAmount = (
  amount: number | MinorOPUL | string,
  places?: number,
): string => {
  const decimals = 6;
  const precision = 6;

  const opulAmount = new bigNumber.BigNumber(amount.toString()).div(
    Math.pow(precision, decimals),
  );

  if (places) {
    return opulAmount.toFormat(places, bigNumber.BigNumber.ROUND_DOWN, {
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
      fractionGroupSize: places,
    });
  }

  return opulAmount
    .toFormat(10, bigNumber.BigNumber.ROUND_DOWN, {
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
      fractionGroupSize: 10,
    })
    .replace(/(\.\d{2}(?:\d*[1-9])?)0*$/g, (_matches, g1) => g1);
};

export const secondsToDays = (seconds: number): number =>
  Math.ceil(seconds / secondsInHour / 24);

export const displayCurrency = (currencyUnitName: string): string =>
  currencyUnitName === 'USDC' ? '$' : currencyUnitName;

export const formatDateDDMMMYYYY = (date: Date): string => {
  return format(addMinutes(date, date.getTimezoneOffset()), 'dd MMM, yyyy');
};

export const formatYear = (date: Date): string => {
  return format(date, 'yyyy');
};

export const formatDate = (date: Date): string => {
  return format(date, 'MMM dd, yyyy');
};

export const formatDateTime = (date: Date): string => {
  return format(date, 'MMM dd, yyyy HH:mm');
};

export const formatDateTimeUtc = (date: Date): string => {
  return format(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000,
    'MMM dd, yyyy HH:mm',
  );
};

export function calcVestingContractProgress(
  totalOpul: MinorOPUL,
  amountOpul: MinorOPUL,
): number {
  return amountOpul.multipliedBy(100).dividedBy(totalOpul).toNumber();
}

export function isSignTransactionCancelledError(error: unknown): boolean {
  const errorMessage =
    Object.getOwnPropertyDescriptors(error)?.message?.value || '';
  return [
    'Operation cancelled',
    'Transaction Request Rejected',
    'Transaction signing is cancelled by user',
    'Network mismatch between dApp and Wallet',
  ].some(it => errorMessage.indexOf(it) > -1);
}

export const daysToSeconds = (days: number): number => days * 86400;

export const calculateApy = (
  lockup: number,
  minAPY: number,
  maxAPY: number,
  minLockup: number,
  maxLockup: number,
): number => {
  const lockupInSeconds = daysToSeconds(lockup);
  const A = new bigNumber.BigNumber(maxAPY).minus(minAPY);
  const B = new bigNumber.BigNumber(lockupInSeconds).multipliedBy(A);
  const C = new bigNumber.BigNumber(minAPY).multipliedBy(maxLockup);
  const D = new bigNumber.BigNumber(maxAPY).multipliedBy(minLockup);
  const E = new bigNumber.BigNumber(maxLockup).minus(minLockup);
  const apy = B.plus(C).minus(D).dividedBy(E);
  return apy.toNumber();
};

export function calculateReward(
  stakeAmount: number,
  lockup: number,
  minAPY: number,
  maxAPY: number,
  minLockup: number,
  maxLockup: number,
): number {
  const lockupInSeconds = daysToSeconds(lockup);
  const secondsInYear = 31536000;
  const apyUnits = 10000; // % * 100 = 100*100
  const apy = calculateApy(lockup, minAPY, maxAPY, minLockup, maxLockup);

  return (
    Math.trunc(
      (stakeAmount * lockupInSeconds * apy) / (secondsInYear * apyUnits),
    ) / 1e10
  );
}

export const randomMinMax = (min: number, max: number): number => {
  return Math.floor(min + Math.random() * (max - min));
};

export async function validateField<T>(
  validator: SchemaOf<T>,
  value: T,
): Promise<string | null> {
  try {
    await validator.validate(value);
    return null;
  } catch (err) {
    const msg = (err as any)?.errors?.[0];
    if (typeof msg === 'string' && msg.length) {
      return msg[0].toUpperCase() + msg.slice(1);
    }
    return msg || null;
  }
}
export const isAValidEmail = (email: string): boolean => {
  return !!email && /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export const TOTAL_SHARE = 100;
export function getArtistInvestorShares(rewardsShare: number): number {
  return new bigNumber.BigNumber(TOTAL_SHARE).minus(rewardsShare).toNumber();
}
export function calculateIndividualRewardsShare(input: {
  amount?: number;
  targetAmount?: number;
  rewardsShare?: number;
  round?: number;
}): number {
  const rewardsShare =
    (input.amount || 0) /
    ((input.targetAmount || 1) / (input.rewardsShare || 1));
  return (
    Math.round(
      (rewardsShare + Number.EPSILON) * Math.pow(10, input.round || 3),
    ) / Math.pow(10, input.round || 3)
  );
}

export type CalculatorProps = {
  minInvest: number;
  maxInvest: number;
  allowedMaxInvest: number;
  fundsGoal: number;
  rewardsShare: number;
  investedFunds: number;
  incrementAmount: number;
  minMoneyInvestedViolation: string;
  maxMoneyInvestedViolation: string;
};
export function prepareCalculatorProperties(
  investment: InvestmentDetails,
  investmentAvailability?: InvestmentAvailability,
): CalculatorProps {
  const data = {
    minInvest: investment.minIndividualInvestment,
    maxInvest: investment.maxIndividualInvestment,
    allowedMaxInvest: investment.maxIndividualInvestment,
    fundsGoal: investment.investmentTargetAmount,
    rewardsShare: investment.rewardsShare,
    investedFunds: investment.purchaseHistory.reduce(
      (total, it) => total + it.amount,
      0,
    ),
    incrementAmount: investment.purchaseAmountIncrement,
    minMoneyInvestedViolation: '',
    maxMoneyInvestedViolation: '',
  };

  data.allowedMaxInvest = data.maxInvest - data.investedFunds;
  if (
    investmentAvailability &&
    data.allowedMaxInvest > investmentAvailability.amountAvailable
  ) {
    data.allowedMaxInvest = investmentAvailability.amountAvailable;
  }

  const investedFundsFormatted = formatCurrency(data.investedFunds, '$', 0);
  let minInvestFormatted = formatCurrency(data.minInvest, '$', 0);
  let maxInvestFormatted = formatCurrency(data.maxInvest, '$', 0);
  let allowedMaxInvestFormatted = formatCurrency(
    data.allowedMaxInvest > 0 ? data.allowedMaxInvest : 0,
    '$',
    0,
  );

  const maxMoneyInvestedViolationMessages: string[] = [];
  const isInPreSale = [InvestmentStatus.UPCOMING, InvestmentStatus.PRE_SALE].includes(
    investment.status,
  );
  const saleStateLabel = isInPreSale ? 'pre-sale' : 'sale';
  if (isInPreSale) {
    data.minInvest = investment.preSaleMinIndividualInvestment;
    data.maxInvest = investment.preSaleMaxIndividualInvestment;
    minInvestFormatted = formatCurrency(data.minInvest, '$', 0);
    maxInvestFormatted = formatCurrency(data.maxInvest, '$', 0);
    data.allowedMaxInvest = data.maxInvest - data.investedFunds;
    allowedMaxInvestFormatted = formatCurrency(
      data.allowedMaxInvest > 0 ? data.allowedMaxInvest : 0,
      '$',
      0,
    );
  }

  if (investmentAvailability?.amountReserved) {
    const amountReservedFormatted = formatCurrency(investmentAvailability?.amountReserved, '$', 0);
    maxMoneyInvestedViolationMessages.push(
      `Just ${allowedMaxInvestFormatted} is available in this sale.`
    );
    maxMoneyInvestedViolationMessages.push(
      `${amountReservedFormatted} is reserved for in-progress transactions and may be freed if cancelled. Check back for potential additional purchases`,
    );
  } else if (data.investedFunds) {
    maxMoneyInvestedViolationMessages.push(
      `You've already purchased ${investedFundsFormatted} amount in this ${saleStateLabel}.`,
    );
    maxMoneyInvestedViolationMessages.push(
      `Only ${allowedMaxInvestFormatted} is still available for purchase in this ${saleStateLabel}.`,
    );
  } else {
    maxMoneyInvestedViolationMessages.push(
      `The maximum purchase amount in this ${saleStateLabel} is ${maxInvestFormatted}.`,
    );
  }

  maxMoneyInvestedViolationMessages.push(
    `Please enter an amount equal to or less than ${allowedMaxInvestFormatted}.`,
  );

  if (isInPreSale) {
    maxMoneyInvestedViolationMessages.push(
      'Once the sale goes live, you will have the opportunity to make additional purchases',
    );
  }

  data.maxMoneyInvestedViolation = maxMoneyInvestedViolationMessages.join(' ');
  data.minMoneyInvestedViolation = `
    The minimum purchase amount in this ${saleStateLabel} is ${minInvestFormatted}.
    Please enter an amount equal to or greater than ${minInvestFormatted}.
  `;

  return data;
}

export function openSharePopup(url: string, title: string): void {
  const popupWidth = 500;
  const popupHeight = 600;
  const left = (screen.width - popupWidth) / 2;
  const top = (screen.height - popupHeight) / 4;
  const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no, width=${popupWidth},height=${popupHeight},left=${left},top=${top}`;
  window.open(url, title, params);
}

export function dispatchInputEvent(input: HTMLInputElement, value: string) {
  const desc: any = Object.getOwnPropertyDescriptor(
    HTMLInputElement.prototype,
    'value',
  );
  desc.set.call(input, value);
  const event = new Event('input', { bubbles: true });
  input.dispatchEvent(event);
}
