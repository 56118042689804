import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  formatNumber,
  calculateIndividualRewardsShare,
  CalculatorProps,
} from '@opulous/web/src/utils';
import {
  Calculator,
  StyledArrowDownward,
  StyledInput,
} from '@opulous/web/src/components/Sales/RewardsShareCalculator/styled-components';

type Props = {
  calculatorProps: CalculatorProps;
  onInputChange?: (event: CustomEvent) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;
export default function SimpleCalculatorForm({
  name,
  calculatorProps,
  onChange,
  onInputChange,
}: Props): ReactElement {
  const [moneyInvested, setMoneyInvested] = useState<string | undefined>();
  const [yourShare, setYourShare] = useState<number>(0);

  const calculateYourShare = useCallback((newMoneyInvested?: number) => {
    const newMoneyInvestedFormatted = newMoneyInvested
      ? formatNumber(newMoneyInvested)
      : '';
    setMoneyInvested(newMoneyInvestedFormatted);

    let customEvent: CustomEvent = new CustomEvent('custom:change', {
      detail: {
        your: 0,
        investors: calculatorProps.rewardsShare,
        moneyInvested: newMoneyInvested,
      },
    });

    if (
      newMoneyInvested &&
      [
        !Number.isNaN(newMoneyInvested),
        calculatorProps.rewardsShare > 0,
        newMoneyInvested >= calculatorProps.minInvest,
        newMoneyInvested <= calculatorProps.fundsGoal,
      ].every(it => !!it)
    ) {
      const newYourShare = calculateIndividualRewardsShare({
        amount: newMoneyInvested,
        targetAmount: calculatorProps.fundsGoal,
        rewardsShare: calculatorProps.rewardsShare,
      });
      setYourShare(newYourShare);

      let newInvestorsShare = calculatorProps.rewardsShare - newYourShare;
      newInvestorsShare =
        newInvestorsShare > 0
          ? Math.round((newInvestorsShare + Number.EPSILON) * 100) / 100
          : 0;

      customEvent = new CustomEvent('custom:change', {
        detail: {
          your: newYourShare,
          investors: newInvestorsShare,
          moneyInvested: newMoneyInvested,
        },
      });
    } else {
      setYourShare(0);
    }

    if (onInputChange) {
      onInputChange(customEvent);
    }
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newMoneyInvested = event.target.value
        ? Number(event.target.value.replace(',', ''))
        : undefined;

      calculateYourShare(newMoneyInvested);
      if (onChange) {
        onChange(event);
      }
    },
    [],
  );

  useEffect(() => {
    calculateYourShare();
  }, []);

  return (
    <Calculator data-testid="rewards-share-calculator__form">
      <StyledInput>
        <label data-testid="rewards-share-calculator__form__invested__label">
          Purchase Amount
        </label>
        <div data-haserror={!!moneyInvested && yourShare <= 0}>
          <p>{moneyInvested && '$'}</p>
          <input
            autoComplete="off"
            data-testid="rewards-share-calculator__form__invested__input"
            name={name || 'invested_input'}
            type="text"
            onChange={handleChange}
            placeholder={`min $${calculatorProps.minInvest}`}
            value={moneyInvested || ''}
          />
        </div>
      </StyledInput>
      <StyledArrowDownward />
      <StyledInput>
        <label data-testid="rewards-share-calculator__form__your-share__label">
          Your share
        </label>
        <span data-testid="rewards-share-calculator__form__your-share__value">
          {yourShare || Number(0).toFixed(2)} %
        </span>
      </StyledInput>
    </Calculator>
  );
}
