import React, { useReducer, useContext } from 'react';
import RoyaltiesContext from './context';
import WalletContext from '@opulous/web/src/context/context';
import {
  RoyaltiesCountriesSalesSummary,
  RoyaltiesMonthsSalesSummary,
  RoyaltiesPayoutEntry,
  RoyaltiesPayoutInfo,
  RoyaltiesReleasesSalesSummary,
  RoyaltiesSalesFilter,
  RoyaltiesSalesFullOverview,
  RoyaltiesStoresSalesSummary,
} from '@opulous/web/src/shared/types';
import MicroUSDC from '@opulous/web/src/shared/valueObjects/MicroUSDC';
import { RoyaltiesReducer, RoyaltiesInitializer } from './reducer';
import { useActions } from './actions';
import {
  TableTabOption,
  TableTabOptionDefault,
  FilterSelected,
  Tabs,
  RoyaltiesSalesFullOverviewDefault,
} from './types';

const InitialState = {
  tabLoading: true,
  loadingOverview: true,
  processingOptin: false,
  processingWithdraw: false,
  tabSelected: Tabs[0].value,
  royaltiesSalesFilter: {
    date: [
      { label: 'All time' },
      { label: 'Last month', value: 1 },
      { label: 'Last 3 months', value: 3 },
      { label: 'Last 6 months', value: 6 },
    ],
    release: [{ label: 'All releases' }],
    individual: false,
  } as RoyaltiesSalesFilter,
  royaltiesCountriesSalesSummary: [] as RoyaltiesCountriesSalesSummary,
  royaltiesStoresSalesSummary: [] as RoyaltiesStoresSalesSummary,
  royaltiesReleasesSalesSummary: [] as RoyaltiesReleasesSalesSummary,
  royaltiesMonthsSalesSummary: [] as RoyaltiesMonthsSalesSummary,
  royaltiesSalesFullOverview: {
    ...RoyaltiesSalesFullOverviewDefault,
  } as RoyaltiesSalesFullOverview,
  salesFilterSelected: { releaseUPCs: [] } as FilterSelected,
  fullOverviewOptionSelected: {
    ...TableTabOptionDefault,
  } as TableTabOption,
  royaltiesPayoutInfo: {
    usdcAsset: {
      id: 0,
      optedIn: true,
    },
    availableBalance: MicroUSDC.ZERO,
    totalEarned: MicroUSDC.ZERO,
  } as RoyaltiesPayoutInfo,
  royaltiesPayoutHistory: [] as RoyaltiesPayoutEntry[],
};

type Props = {
  children?: React.ReactNode;
};
const RoyaltiesProvider: React.FC = ({ children }: Props) => {
  const { state: walletState } = useContext(WalletContext);

  const [state, dispatch] = useReducer(
    RoyaltiesReducer,
    {
      ...InitialState,
      walletState,
      walletAddress: walletState.wallet,
    },
    RoyaltiesInitializer,
  );
  const actions = useActions(dispatch);

  return (
    <RoyaltiesContext.Provider
      value={{
        state,
        actions,
      }}
    >
      {children}
    </RoyaltiesContext.Provider>
  );
};

export default RoyaltiesProvider;
