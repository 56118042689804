import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { styled } from '@mui/system'
import Collapse from '@mui/material/Collapse'
import { Alert, Button, useTheme } from '@mui/material'
import StyledLoadingButton from '@opulous/web/src/components/Common/StyledLoadingButton'

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(5),
  maxWidth: theme.spacing(46.875),
  width: '100%',
  borderRadius: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
}))

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 600,
  fontSize: theme.spacing(3),
  lineHeight: '28px',
}))

const Content = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2.5, 0),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5, 0),
  },
}))

const Actions = styled(CardActions)(() => ({
  padding: '0 !important',
}))

const Toast = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(4),
  width: 'fit-content',
  padding: theme.spacing(0.5, 1.5),
  marginBottom: theme.spacing(2.5),
  '& span': {
    fontSize: theme.spacing(1.75),
  },
}))

const StyledAlert = styled(Alert)(({ theme }) => ({
  color: theme.palette.error.dark,
  fontWeight: 600,
  paddingLeft: 11,
  marginBottom: 40,
  '& a': {
    color: theme.palette.error.dark,
    fontWeight: 600,
  },
  '& .MuiAlert-icon': {
    marginRight: 9,
    fontSize: '16px',
    paddingTop: 11,
  },
}))

const StyledInfoAlert = styled(Alert)(({ theme }) => ({
  color: theme.palette.info.dark,
  fontWeight: 600,
  paddingLeft: 11,
  marginBottom: 40,
  '& a': {
    color: theme.palette.info.dark,
    fontWeight: 600,
  },
  '& .MuiAlert-icon': {
    marginRight: 9,
    fontSize: '16px',
    paddingTop: 11,
  },
}))

const InfoToast = styled(Toast)(({ theme }) => ({
  background: `${theme.palette.info.main}30`,
  '& span': {
    color: theme.palette.info.main,
  },
}))

interface IClaimWrapper {
  title: string
  submitText: string
  onSubmit: () => void
  children?: ReactNode
  loading: boolean
  errorText?: string
  infoText?: string
  isEmpty?: boolean
}

const ClaimWrapper: React.FC<IClaimWrapper> = ({
  title,
  submitText,
  onSubmit,
  children,
  loading = false,
  errorText,
  infoText,
  isEmpty,
}) => {
  const theme = useTheme();
  const navigate = useNavigate()

  const navigateMyAssets = () => {
    navigate('/')
  }

  return (
    <StyledCard data-testid="claim-wrapper-card">
      <Title data-testid="claim-wrapper-card__title">{title}</Title>
      <Content>{children}</Content>
      <Collapse in={!!infoText}>
        <InfoToast data-testid="claim-wrapper-card__info-toast">
          <Typography component="span">{infoText}</Typography>
        </InfoToast>
      </Collapse>
      <Collapse in={!!errorText}>
        <StyledAlert
          data-testid="claim-wrapper-card__error-toast"
          variant="filled"
          severity="error"
        >
          {errorText}
        </StyledAlert>
      </Collapse>
      <Collapse in={isEmpty}>
        <StyledInfoAlert
          data-testid="claim-wrapper-card__empty-toast"
          variant="filled"
          severity="info"
        >
          It seems you don’t have any assets available. Please check on My
          assets or contact{' '}
          <a href="mailto:support@opulous.org">support@opulous.org</a>
        </StyledInfoAlert>
      </Collapse>
      <Actions>
        {!errorText && !isEmpty && (
          <StyledLoadingButton
            dataTestId="claim-wrapper-card__button"
            variant="contained"
            fullWidth
            size="large"
            color="primary"
            onClick={onSubmit}
            loading={loading}
            borderRadius="32px"
            textTransform="capitalize"
            padding="12px 40px"
            background={theme.palette.primary.main}
          >
            {submitText}
          </StyledLoadingButton>
        )}
        {/* {!!errorText && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            href="mailto:support@opulous.org"
          >
            Send email
          </Button>
        )} */}
        {!loading && isEmpty && (
          <Button
            data-testid="claim-wrapper-card__my-assets-button"
            variant="contained"
            size="large"
            color="primary"
            onClick={navigateMyAssets}
          >
            Go to My Assets
          </Button>
        )}
      </Actions>
    </StyledCard>
  )
}

export default ClaimWrapper
