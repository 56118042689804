import React, { ReactElement, useEffect, useRef } from 'react'
import Paper from '@mui/material/Paper'
import { Theme } from '@mui/material'
import { styled } from '@mui/system'
import { useTheme } from '@mui/styles'

import Typography from '@mui/material/Typography'
import HighCharts from 'highcharts'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import { streams } from '@opulous/web/src/constant/tables.json'

const StreamsContainer = styled(Paper)(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.grey[100]}`,
}))

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  padding: theme.spacing(3, 3),
}))

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'nowrap',
  padding: theme.spacing(1.5, 3),
  color: theme.palette.grey[900],
  fontSize: '0.875rem',
}))

const TotalStreams = (): ReactElement => {
  const chart = useRef(null)
  const { data } = streams
  const theme: Theme = useTheme()

  const config = {
    chart: {
      renderTo: 'container',
      type: 'line',
      backgroundColor: 'transparent',
      height: 240,
      title: '',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: 'line',
        data: [0, 50, 105, 90, 150, 185, 260, 250, 320, 210, 300, 340],
        allowPointSelect: false,
        compareBase: 10,
        color: theme.palette.primary.main,
        fillOpacity: 0.1,
        lineWidth: 1,
        point: {
          marker: {
            enabled: false,
          },
        },
        marker: {
          enabled: false,
        },
      },
      {
        type: 'line',
        data: [0, 70, 90, 110, 120, 195, 270, 240, 310, 200, 310, 320],
        allowPointSelect: false,
        compareBase: 10,
        color: theme.palette.secondary.main,
        fillOpacity: 0.1,
        lineWidth: 1,
        point: {
          marker: {
            enabled: false,
          },
        },
        marker: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    xAxis: {
      opposite: true,
      categories: [
        'Sep',
        'Oct',
        'Now',
        'Dec',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
      ],
      gridLineWidth: 1,
      tickWidth: 1,
      tickmarkPlacement: 'on',
      labels: {
        enabled: false,
        style: {
          color: '#14181A',
        },
      },
    },
    yAxis: {
      grid: {
        borderWidth: 1,
        enabled: true,
      },
      tickInterval: 100,
      title: undefined,
      labels: {
        // @ts-ignore
        formatter: (context) => {
          return '£' + context.value
        },
        style: {
          color: '#212121',
        },
      },
    },
    tooltip: {
      label: '#fff',
      borderRadius: 20,
      shadow: false,
    },
    point: {
      marker: {
        enabled: false,
      },
    },
  }

  useEffect(() => {
    if (chart.current) {
      // @ts-ignore
      HighCharts.chart(chart.current, config)
    }
  }, [])

  return (
    <StreamsContainer>
      <Title variant="subtitle2">Total streams</Title>
      <Content>
        <div ref={chart} />
      </Content>
      <TableContainer>
        <Table>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <StyledTableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{
                        width: 20,
                        height: 15,
                        background: row.color,
                        borderRadius: 0.2,
                        marginRight: 1,
                      }}
                    />
                    <span>{row.label}</span>
                  </Box>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StreamsContainer>
  )
}

export default TotalStreams
