import { ReactElement, useContext, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { useTheme } from '@mui/styles';
import { Theme, Typography, Paper, Skeleton } from '@mui/material';
import HighCharts, { Options, YAxisOptions } from 'highcharts';
import RoyaltiesContext from '@opulous/web/src/pages/Royalties/context';
import { formatCurrency, formatNumber } from '@opulous/web/src/utils';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3, 3),
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: 12,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(4),
}));

const TotalEarningContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3, 3),
  border: `1px solid ${theme.palette.grey[100]}`,
}));

const NoDataLabel = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: `calc(50% - ${theme.spacing(10)})`,
  fontWeight: 600,
  marginBottom: theme.spacing(4),
}))

export default function TotalEarning(): ReactElement {
  const { state: royaltiesContext } = useContext(RoyaltiesContext);
  const { tabLoading: loading, royaltiesMonthsSalesSummary } = royaltiesContext;
  const data = new Map(
    royaltiesMonthsSalesSummary.map(it => [
      it.month.toISOString(),
      { earning: it.earning, streams: it.units },
    ]),
  );
  const maxEarning = Math.max(...Array.from(data.values()).map(it => it.earning));

  const theme: Theme = useTheme();
  const chartRef = useRef(null);

  const config: Options = {
    chart: {
      renderTo: 'container',
      type: 'line',
      backgroundColor: 'transparent',
      height: 240,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: 'line',
        data: Array.from(data.values()).map(it => it.earning),
        allowPointSelect: false,
        color: theme.palette.primary.main,
        lineWidth: 1,
        marker: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    xAxis: {
      opposite: true,
      categories: Array.from(data.keys()),
      gridLineWidth: 1,
      tickWidth: 1,
      tickmarkPlacement: 'on',
      labels: {
        formatter: (context: { value: string | number }): string => {
          return HighCharts.dateFormat(
            '%b/%Y',
            new Date(context.value).getTime(),
          );
        },
        style: {
          color: '#14181A',
        },
      },
    },
    yAxis: {
      grid: {
        borderWidth: 1,
        enabled: true,
      },
      tickInterval: maxEarning < 20 ? 2 : (maxEarning < 200 ? 10 : (maxEarning < 1000 ? 100 : 500)),
      title: undefined,
      labels: {
        formatter: (context: { value: number }): string => {
          return context.value > 0 ? formatCurrency(context.value) : '0';
        },
        style: {
          color: '#212121',
        },
      },
    } as YAxisOptions,
    tooltip: {
      borderRadius: 12,
      shadow: false,
      borderWidth: 0,
      padding: -10,
      backgroundColor: 'transparent',
      useHTML: true,
      formatter() {
        return `
          <div style="padding: 1rem; border-radius: 0.6rem; background-color: ${
            this.color
          }">
            <div style="color: white; margin-bottom: 10px">${HighCharts.dateFormat(
              '%B/%Y',
              new Date(this.x).getTime(),
            )}</div>
            <div style="display: flex">
              <div style="margin-right: 15px">
                <div style="color: #14181A;">Total rewards</div>
                <div style="color: white">${formatCurrency(this.y)}</div>
              </div>
              <div>
                <div style="color: #14181A;">Total streams</div>
                <div style="color: white">${formatNumber(
                  data.get(`${this.x}`)?.streams || 0,
                )}</div>
              </div>
            </div>
          </div>
        `;
      },
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      HighCharts.chart(chartRef.current, config);
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <StyledPaper data-testid="total-earning">
          <Skeleton
            variant="rectangular"
            height={300}
            data-testid="total-earning__skeleton"
          ></Skeleton>
        </StyledPaper>
      ) : (
        <TotalEarningContainer data-testid="total-earning">
          <Title variant="subtitle2" data-testid="total-earning__title">
            Total rewards
          </Title>
          {!data.size && <NoDataLabel>No data to display</NoDataLabel>}
          <div ref={chartRef} />
        </TotalEarningContainer>
      )}
    </>
  );
}
