import React from 'react'
import { styled } from '@mui/system'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import CropOriginalIcon from '@mui/icons-material/CropOriginal'
import Skeleton from '@mui/material/Skeleton'
import { Asset, AssetType } from '../../../shared/types'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material'
import { OpulIcon, UsdcIcon } from './components'
import { formatOpulAmount, formatUSDCAmount } from '@opulous/web/src/utils'

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  borderRadius: 8,
  marginBottom: theme.spacing(1),
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: 'calc(100% - 56px)', // need to set fixed with for the ellipsis to work on the text
  padding: `${theme.spacing(0.875, 1)} !important`,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  paddingRight: theme.spacing(1),
}));

const StyledTypographySm = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.5),
}));

interface IAssetCard {
  'data-testid'?: string
  asset: Asset
  loading?: boolean
}

// type IAssetClaimCard = ILoader | IAssetCard

const AssetClaimCard: React.FC<IAssetCard> = (props: IAssetCard) => {
  const { asset, loading = false } = props;

  const getIcon = (type: AssetType) => {
    switch (type) {
      case AssetType.artwork:
        return <CropOriginalIcon sx={{ fontSize: 16 }} />;
      case AssetType.opul:
        return <OpulIcon />
      case AssetType.usdc:
        return <UsdcIcon />
      default:
        return;
    }
  };

  return (
    <StyledCard data-testid={props['data-testid'] || 'asset-claim-card'}>
      {!loading ? (
        <CardMedia
          data-testid="asset-claim-card__image"
          sx={{
            width: (theme: Theme) => theme.spacing(7),
            height: (theme: Theme) => theme.spacing(7),
            padding: asset.type === AssetType.opul ? '5px' : 0,
          }}
          component={'img'}
          image={asset.image}
          alt="asset"
        />
      ) : (
        <Skeleton
          data-testid="asset-claim-card__image-skeleton"
          variant="rectangular"
          sx={{
            width: (theme: Theme) => theme.spacing(7),
            height: (theme: Theme) => theme.spacing(7),
          }}
        />
      )}
      <StyledCardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <StyledTypography
            variant="caption"
            data-testid="asset-claim-card__title"
          >
            {loading ? (
              <Skeleton
                width={140}
                data-testid="asset-claim-card__title-skeleton"
              />
            ) : (
              asset?.custom?.title || asset.name
            )}
          </StyledTypography>
          {!loading && getIcon(asset.type)}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <StyledTypography
            variant="overline"
            data-testid="asset-claim-card__artist"
          >
            {loading ? (
              <Skeleton
                width={100}
                data-testid="asset-claim-card__artist-skeleton"
              />
            ) : (
              asset.custom?.artist || ''
            )}
          </StyledTypography>
          {(asset.type === AssetType.opul) && (
            <StyledTypographySm
              variant="overline"
              data-testid="asset-claim-card__opul-amount"
            >
              {loading ? (
                <Skeleton
                  width={24}
                  data-testid="asset-claim-card__opul-amount-skeleton"
                />
              ) : (
                formatOpulAmount(asset.nftCount)
              )}
            </StyledTypographySm>
          )}
          {(asset.type === AssetType.usdc) && (
            <StyledTypographySm
              variant="overline"
              data-testid="asset-claim-card__opul-amount"
            >
              {loading ? (
                <Skeleton
                  width={24}
                  data-testid="asset-claim-card__opul-amount-skeleton"
                />
              ) : (
                formatUSDCAmount(asset.nftCount)
              )}
            </StyledTypographySm>
          )}

        </Box>
      </StyledCardContent>
    </StyledCard>
  );
};

export default AssetClaimCard;
