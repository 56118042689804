import { ReactElement, useCallback, useContext } from 'react';
import { styled } from '@mui/system';
import { Grid, OutlinedInput } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { InvestmentStatus } from '@opulous/web/src/shared/types';
import SalesContext from '@opulous/web/src/pages/Sales/context';
import {
  FilterAndSortSelected,
} from '@opulous/web/src/pages/Sales/types';
import SelectComponent from './Select';

const FilterBarContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
}));
const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: `${(theme.shadows as string[])[12]}`,
  borderRadius: theme.spacing(4),
  width: '100%',
  padding: theme.spacing(0, 2),
}));

export default function FilterBar(): ReactElement {
  const { state: salesContext, actions: salesActions } = useContext(SalesContext);
  const handleChangeTerm = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newFilterAndSortSelected = {
        ...salesContext.filterAndSortSelected,
        term: event.target.value,
      } as FilterAndSortSelected;
      salesActions.changeFilterAndSortSelected(newFilterAndSortSelected);
      if (!event.target.value) {
        salesActions.loadAllInvestments(newFilterAndSortSelected);
      }
    },
    [salesContext.filterAndSortSelected],
  );
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.key === 'Enter') {
        salesActions.loadAllInvestments(salesContext.filterAndSortSelected);
      }
    },
    [salesContext.filterAndSortSelected],
  );

  const handleChangeFilterByStatus = useCallback(
    (status?: string) => {
      const newFilterAndSortSelected = {
        ...salesContext.filterAndSortSelected,
        status,
      } as FilterAndSortSelected;
      salesActions.changeFilterAndSortSelected(newFilterAndSortSelected);
      salesActions.loadAllInvestments(newFilterAndSortSelected);
    },
    [salesContext.filterAndSortSelected],
  );

  return (
    <FilterBarContainer data-testid="filter-bar">
      <Grid container spacing={4}>
        <Grid item lg={8} xs={12}>
          <StyledOutlinedInput
            data-testid="filter-bar__search-input"
            placeholder="Start typing to search..."
            onKeyDown={handleKeyDown}
            onChange={handleChangeTerm}
            value={salesContext.filterAndSortSelected?.term}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <SelectComponent
            data-testid="filter-bar__status-select"
            title="Filter by status"
            value={salesContext.filterAndSortSelected?.status}
            options={Object.entries(InvestmentStatus)
              .filter(([, value]) => value !== InvestmentStatus.SOLD_OUT)
              .map(([key, value]) => ({ key, value }))}
            onChange={handleChangeFilterByStatus}
          />
        </Grid>
      </Grid>
    </FilterBarContainer>
  );
}
