import React, { useState } from 'react'
import { fromUnixTime } from 'date-fns'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { Button, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import { PoolDetails } from '@opulous/web/src/shared/types'
import StakedIcon from './StakedIcon'
import ApyIcon from './ApyIcon'
import WithdrawnIcon from './WithdrawnIcon'
import { formatDateTime, formatNumber } from '../../../utils'
import OPULLabel from '@opulous/web/src/components/shared/OPULLabel'

const Container = styled('div')(() => ({
  height: '100%',
}))

const Label = styled('div')(({ theme }) => ({
  fontSize: '.75rem',
  color: theme.palette.grey[500],
  fontWeight: 700,
}))

const HistoryContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.grey[100]}`,
}))

const HistorySummaryContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}))

const Link = styled('a')(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: 600,
  textDecoration: 'none',
  fontSize: '.75rem',
}));

interface Props {
  onClickWithdraw: (stakeIndex: number) => void
  pool: PoolDetails
}

const OwnStakesPanel: React.FC<Props> = ({ onClickWithdraw, pool }) => {
  const theme = useTheme()
  const [expandedHistoryIdx, setExpandedHistoryIdx] = useState<number | null>(
    null
  )

  return (
    <Container>
      <Box padding={2}>
        <Box
          padding={0.5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Staked Tokens</Typography>
          <OPULLabel hideUnit value={pool.totalStaked} />
        </Box>
        <Box
          padding={0.5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontWeight={600}>Current Balance</Typography>
          <OPULLabel hideUnit value={pool.totalCurrentBalance} />
        </Box>
      </Box>

      {pool.history?.map((rec, index) => {
        const isExpandable = !!(
          rec.currentBalance ||
          rec.projectedStake ||
          rec.unlockPeriod
        )
        const handleExpand = () =>
          isExpandable &&
          setExpandedHistoryIdx((originalIdx) =>
            index === originalIdx ? null : index
          )

        return (
          <HistoryContainer key={`history-${index}`}>
            <HistorySummaryContainer
              style={{ cursor: isExpandable ? 'pointer' : 'default' }}
              onClick={handleExpand}
            >
              <Box
                color={
                  rec.type === 'staked'
                    ? theme.palette.primary.main
                    : theme.palette.grey[500]
                }
                marginRight={1}
                flex="1.2rem 0 0"
                paddingTop={1}
              >
                {rec.type === 'staked' ? (
                  <StakedIcon width="100%" />
                ) : (
                  <WithdrawnIcon width="100%" />
                )}
              </Box>
              <Box flex={1}>
                <Label>Staked Tokens</Label>
                <Typography variant="h4">
                  <OPULLabel hideUnit value={rec.stakeAmount} />
                </Typography>
                <Typography fontSize=".75rem">
                  {formatDateTime(new Date(parseInt(rec.stakeTimestamp) * 1000))}
                </Typography>
              </Box>

              <Box flex={1}>
                {typeof rec.annualPercentageYield === 'number' && (
                  <>
                    <Label>APY</Label>
                    <Box display="flex" alignItems="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="1rem"
                        height="1rem"
                        marginRight={1}
                        color={theme.palette.primary.main}
                      >
                        <ApyIcon width="100%" height="100%" />
                      </Box>
                      <Typography fontWeight="600">
                        {formatNumber(rec.annualPercentageYield)}%
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>

              <Box
                color={theme.palette.primary.main}
                flex="1rem 0 0"
                display="flex"
                alignItems="center"
                style={{ visibility: isExpandable ? 'visible' : 'hidden' }}
              >
                {expandedHistoryIdx === index ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </Box>
            </HistorySummaryContainer>

            {index === expandedHistoryIdx && (
              <Box display="flex" paddingY={1}>
                <Box flex="1.2rem 0 0" marginRight={2} />
                <Box flex="1">
                  {rec.type === 'staked' && !!rec.currentBalance && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingY={0.5}
                    >
                      <Label>Current Balance:</Label>
                      <Typography fontSize=".875rem" fontWeight="500">
                        <OPULLabel hideUnit value={rec.currentBalance} />
                      </Typography>
                    </Box>
                  )}

                  {rec.type === 'staked' && !!rec.earnedTokens && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingY={0.5}
                    >
                      <Label>Projected Stake:</Label>
                      <Typography fontSize=".875rem" fontWeight="500">
                        <OPULLabel
                          hideUnit
                          value={(
                            parseFloat(rec.projectedStake)
                          ).toString()}
                        />
                      </Typography>
                    </Box>
                  )}

                  {rec.type === 'staked' && !!rec.unlockPeriod && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingY={0.5}
                    >
                      <Label>Unlock Date:</Label>
                      <Typography fontSize=".875rem" fontWeight="500">
                        {formatDateTime(new Date(rec.unlockPeriod))}
                      </Typography>
                    </Box>
                  )}

                  {rec.type === 'withdrawn' && !!rec.earnedTokens && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingY={0.5}
                    >
                      <Label>Rewards:</Label>
                      <Typography fontSize=".875rem" fontWeight="500">
                        <OPULLabel hideUnit value={rec.earnedTokens} />
                      </Typography>
                    </Box>
                  )}

                  {rec.type === 'withdrawn' && !!rec.withdrawnAmount && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingY={0.5}
                    >
                      <Label>Withdrawn amount:</Label>
                      <Typography fontSize=".875rem" fontWeight="500">
                        <OPULLabel hideUnit value={rec.withdrawnAmount} />
                      </Typography>
                    </Box>
                  )}

                  {rec.type === 'withdrawn' && !!rec.withdrawnTimestamp && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingY={0.5}
                    >
                      <Label>Withdrawal Date:</Label>
                      <Typography fontSize=".875rem" fontWeight="500">
                        {formatDateTime(fromUnixTime(parseInt(rec.withdrawnTimestamp, 10)))}
                      </Typography>
                    </Box>
                  )}

                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    paddingY={0.5}
                  >
                    <Link
                      target="_blank"
                      href={`${process.env.REACT_APP_ALGO_EXPLORER_URL}/tx/${rec.transactionId}`}
                    >
                      Transaction
                    </Link>
                  </Box>

                  {rec.type === 'staked' && !!rec.unlockPeriod && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingTop={2}
                    >
                      <Button
                        color="primary"
                        size="small"
                        fullWidth
                        onClick={() => onClickWithdraw(rec.index)}
                        disabled={
                          Date.now() < new Date(rec.unlockPeriod).getTime()
                        }
                      >
                        Withdraw
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </HistoryContainer>
        )
      })}
    </Container>
  )
}

export default OwnStakesPanel
