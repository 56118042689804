import { ReactElement, useEffect, useRef } from 'react';
import { SignerTransaction } from '@perawallet/connect/dist/util/model/peraWalletModels';
import { PeraWalletConnect } from '@perawallet/connect';
import { signMultipleTransactionsWithPeraWallet } from '@opulous/web/src/helpers/AlgorandHelper';
import { WalletTypeEnum } from '@opulous/web/src/shared/types';
import config from '@opulous/web/src/config';
import { WalletKeys } from './types';

function UltradeWidget({
  buyAndSellWidget = false,
  symbol,
  walletAddress,
  walletType,
  connector,
}: {
  buyAndSellWidget?: boolean;
  symbol?: string;
  walletAddress: string;
  walletType: WalletTypeEnum | undefined;
  connector: PeraWalletConnect;
}): ReactElement {
  const ultradeRootRef = useRef<HTMLDivElement>(null);

  if (!window.Ultrade) {
    window.Ultrade = {};
  }

  const snippetUrl = new URL(`${config.env.ULTRADE_API_URL}/wl/snippet`);
  if (walletAddress) {
    snippetUrl.searchParams.set('walletInheritance', 'true');
  }
  if (buyAndSellWidget) {
    snippetUrl.searchParams.set('mode', 'widget');
  }
  if (symbol) {
    snippetUrl.searchParams.set('symbol', `${symbol}_USDC`);
  }

  useEffect(() => {
    if (walletAddress && walletType === WalletTypeEnum.peraWallet) {
      window.Ultrade[WalletKeys.Pera] = async (
        txGroups: SignerTransaction[][],
      ) => {
        const signedTxs = await signMultipleTransactionsWithPeraWallet(
          txGroups[0].map(stx => stx.txn),
          connector,
        );
        return signedTxs.map(tx => tx.blob);
      };
    } else {
      window.Ultrade[WalletKeys.Pera] = undefined;
    }

    return () => (window.Ultrade[WalletKeys.Pera] = undefined);
  }, [walletAddress]);

  useEffect(() => {
    let ultradeDashboard: HTMLScriptElement;
    if (ultradeRootRef?.current) {
      ultradeDashboard = document.createElement('script');
      ultradeDashboard.src = snippetUrl.href;
      ultradeDashboard.async = true;

      ultradeRootRef.current.appendChild(ultradeDashboard);
    }

    return () => {
      ultradeRootRef.current &&
        ultradeRootRef.current.removeChild(ultradeDashboard);
    };
  }, [snippetUrl]);

  return (
    <div
      data-testid="ultrade-widget"
      id="ultrade-root"
      style={{ width: '100%', height: '100%' }}
      ref={ultradeRootRef}
    ></div>
  );
}

type UltradeWidgetProps = {
  walletAddress: string;
  walletType: WalletTypeEnum | undefined;
  connector: PeraWalletConnect;
};

export function UltradeDashboard(props: UltradeWidgetProps): ReactElement {
  if (!config.env.ULTRADE_API_URL) {
    return <></>;
  }
  return <UltradeWidget {...props} />;
}

export function UltradeBuyAndSell(
  props: UltradeWidgetProps & { assetUnitName: string },
): ReactElement {
  if (!config.env.ULTRADE_API_URL) {
    return <></>;
  }
  return (
    <UltradeWidget {...props} buyAndSellWidget symbol={props.assetUnitName} />
  );
}
