import { styled } from '@mui/system';
import { Card, Typography } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: '368px',
  position: 'relative',
  borderRadius: theme.spacing(2),
  cursor: 'pointer',
  minHeight: theme.spacing(8),
  height: theme.spacing(56),
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    borderRadius: theme.spacing(1.5),
  },
}));

export const StyledMedia = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2.5, 2.5, 1, 2.5),
}));

export const StyledImage = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  width: `100%`,
  borderRadius: theme.spacing(2),
}));

export const DetailsContainer = styled('div')<{ completed?: boolean, collapsed?: boolean }>(
  ({ completed, collapsed, theme }) => ({
    position: 'absolute',
    width: '100%',
    bottom: collapsed ? `-${theme.spacing(completed ? 25 : 30)}` : '0',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
    transition: 'bottom 1s',
    backgroundColor: theme.palette.common.white,
  }),
);

export const Title = styled(Typography)(({ theme }) => ({
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: theme.spacing(3),
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));

export const Artist = styled(Typography)(({ theme }) => ({
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: theme.spacing(2),
  fontWeight: 'normal',
}));
