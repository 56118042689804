/* eslint-disable */
import React, { useEffect, useMemo, useState, useContext } from 'react'
import PageHeader from '@opulous/web/src/components/PageHeader'
import AssetList from '@opulous/web/src/components/Assets/AssetList'

import { AssetContractMetadata, AssetType } from '@opulous/web/src/shared/types'
import { isInclude } from '@opulous/web/src/utils'
import useDebounce from '@opulous/web/src/hooks/useDebounce'
import WalletContext from '@opulous/web/src/context/context'
import * as MyAlgoService from '@opulous/web/src/services/my-algo'
import * as AssetService from '@opulous/web/src/services/assets'

const filterAssets = (assets: AssetContractMetadata[], assetIds: number[]): AssetContractMetadata[] => {
  return assets.filter((it) => {
    return it.contracts.map(c => c.assetId).some(id => assetIds.indexOf(id) >= 0)
  })
}

const AssetsPage: React.FC = () => {
  const [assets, setAssets] = useState<AssetContractMetadata[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)
  const {
    state: { wallet },
  } = useContext(WalletContext)

  const [searchTerm, setSearchTerm] = useState('')
  const debounceSearchTerm = useDebounce(searchTerm, 300)

  const getAccountInformation = async () => {
    if (!wallet) {
      return []
    }
    return await MyAlgoService.getAssetsOwnedByWallet(wallet)
  }

  const fetchClaimedAssets = async () => {
    setLoading(true)
    const assetIds = await getAccountInformation()
    if (!assetIds || assetIds.length === 0) {
      setAssets([])
      setLoading(false)
      return
    }

    try {
      const allAssets = await AssetService.fetchAllAssetsClaimedAndOptedIn(
        wallet
      )
      // ignore contracts with asset-optin status
      setAssets(filterAssets(allAssets, assetIds))
    } catch (error) {
      setAssets([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchClaimedAssets()
  }, [])

  const filteredAssets = useMemo(
    () =>
      (assets as AssetContractMetadata[]).filter(
        (item: AssetContractMetadata) =>
          (item.type === AssetType.mft || item.type === AssetType.artwork) &&
          (isInclude(item.title, debounceSearchTerm) ||
            isInclude(item.artist, debounceSearchTerm))
      ),
    [debounceSearchTerm, JSON.stringify(assets)]
  )

  return (
    <div data-testid="assets-page">
      <PageHeader
        title="My assets"
        searchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />

      <AssetList
        loading={isLoading}
        assets={filteredAssets}
        emptyMessage="You don't have any assets in your wallet. Check out our website to see the next sales."
      />
    </div>
  )
}

export default AssetsPage
