import { ReactElement } from 'react';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import TvIcon from '@mui/icons-material/Tv';
import CastIcon from '@mui/icons-material/Cast';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import RadioIcon from '@mui/icons-material/Radio';
import MicNoneIcon from '@mui/icons-material/MicNone';
import DownloadIcon from '@mui/icons-material/Download';
import { IContentIconListItem } from '@opulous/web/src/shared/types';

interface ContentIconListProps {
  items: IContentIconListItem[];
}

const Title = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '21px',
}));

const Description = styled(Typography)(() => ({
  fontWeight: 300,
  fontSize: 10,
  lineHeight: '15px',
}));

const IconListWrapper = styled('div')(() => ({
  marginBottom: 8,
}));

const Header = styled('div')(() => ({
  marginBottom: 4,
  display: 'flex',
  alignItems: 'center',
}));

const IconListContainer = styled('div')(() => ({
  marginBottom: 24,
}));

enum IconType {
  tv = 'tv',
  cast = 'cast',
  download = 'download',
  mic = 'mic',
  radio = 'radio',
  sync = 'sync',
}

const ContentIconList = ({ items }: ContentIconListProps): ReactElement => {
  // const theme: Theme = useTheme()

  const renderIcon = (iconName: IconType) => {
    switch (iconName) {
      case IconType.tv:
        return <TvIcon color="primary" sx={{ marginRight: '8px' }} />;
      case IconType.cast:
        return <CastIcon color="primary" sx={{ marginRight: '8px' }} />;
      case IconType.sync:
        return (
          <VideogameAssetIcon color="primary" sx={{ marginRight: '8px' }} />
        );
      case IconType.radio:
        return <RadioIcon color="primary" sx={{ marginRight: '8px' }} />;
      case IconType.mic:
        return <MicNoneIcon color="primary" sx={{ marginRight: '8px' }} />;
      case IconType.download:
        return <DownloadIcon color="primary" sx={{ marginRight: '8px' }} />;

      default:
        null;
    }
  };
  return (
    <IconListContainer>
      {items.map(({ icon, title, description }) => {
        return (
          <IconListWrapper>
            <Header>
              {renderIcon(icon as IconType)}
              <Title>{title}</Title>
            </Header>
            <Description>{description}</Description>
          </IconListWrapper>
        );
      })}
    </IconListContainer>
  );
};

export default ContentIconList;
