import React from 'react'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import { styled } from '@mui/system'
import { WalletTypeEnum } from '@opulous/web/src/shared/types'

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  textTransform: 'capitalize',
  padding: theme.spacing(1.5),
  background: theme.palette.common.white,
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
  borderColor: theme.palette.grey[200],
  justifyContent: 'flex-start',
  marginLeft: '0!important',

  '&:hover': {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

interface WalletOptionProps {
  type: WalletTypeEnum
  name: string
  image: string
  onClick: (type: WalletTypeEnum) => void
  active?: boolean
}

const WalletOption: React.FC<WalletOptionProps> = ({
  name,
  image,
  type,
  onClick,
  active = false,
}) => {
  return (
    <StyledButton
      variant="contained"
      color="secondary"
      data-testid={`wallet-option-${type}`}
      startIcon={<Avatar src={image} />}
      onClick={() => onClick(type)}
      disabled={!active}
    >
      {name}
    </StyledButton>
  )
}

export default WalletOption
