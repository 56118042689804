import { ReactElement } from 'react';
import { styled } from '@mui/system';
import { Paper } from '@mui/material';
import { InvestmentDetails } from '@opulous/web/src/shared/types';
import {
  formatCurrency,
  formatNumber,
  formatDate,
  calculateIndividualRewardsShare,
} from '@opulous/web/src/utils/index';

const DetailsSectionContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(4),
  marginBottom: theme.spacing(6),
}));
const DetailsRow = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 0),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  '&:last-of-type': {
    borderBottom: 'none',
  },
  span: {
    fontWeight: 'bold',
  },
}));

type Props = {
  investment: InvestmentDetails;
};
export default function InvestmentDetailsComponent({
  investment,
}: Props): ReactElement {
  const detailsMap: { label: string; value: string }[][] = [
    [
      { label: 'Music Release', value: investment.dealType },
      {
        label: 'Funding Goal',
        value: formatCurrency(investment.investmentTargetAmount, '$', 0),
      },
      {
        label: 'Total MFTs Available',
        value: formatNumber(investment.investmentTargetAmount),
      },
      { label: 'Price per MFT', value: '$1' },
      { label: 'Total Rewards Share', value: `${formatNumber(investment.rewardsShare)}%` },
      {
        label: 'Rewards Share per MFT',
        value: `${calculateIndividualRewardsShare({
          amount: 1,
          rewardsShare: investment.rewardsShare,
          targetAmount: investment.investmentTargetAmount,
          round: 4,
        })}%`,
      },
      { label: 'Pre-sale Winners', value: `${investment.preSaleWinners}` },
    ],
    [
      {
        label: 'Minimum Purchase',
        value: formatCurrency(investment.minIndividualInvestment, '$', 0),
      },
      {
        label: 'Maximum Pre-Sale Purchase',
        value: formatCurrency(
          investment.preSaleMaxIndividualInvestment,
          '$',
          0,
        ),
      },
      {
        label: 'Maximum Sale Purchase',
        value: formatCurrency(investment.maxIndividualInvestment, '$', 0),
      },
    ],
    [
      {
        label: 'Pre-sale Date',
        value: investment.preSaleStartDate ? formatDate(investment.preSaleStartDate) : '-',
      },
      {
        label: 'Sale Date',
        value: formatDate(investment.saleStartDate),
      },
      {
        label: 'MFT Distribution Date',
        value: formatDate(investment.mftDistributionDate),
      },
      {
        label: 'Next Reward Distribution',
        value: formatDate(investment.nextRewardDistribution),
      },
    ],
  ];

  return (
    <>
      {detailsMap.map((sec, ix) => (
        <DetailsSectionContainer
          key={`details-section-${ix}`}
          data-testid="investment-details__section"
        >
          {sec.map((it, jx) => (
            <DetailsRow
              key={`details-section-${ix}-${jx}`}
              data-testid="investment-details__section__item"
            >
              <label>{it.label}</label>
              <span>{it.value}</span>
            </DetailsRow>
          ))}
        </DetailsSectionContainer>
      ))}
    </>
  );
}
