import { Action, RoyaltiesActionType } from './actions'
import { RoyaltiesContextState } from './context'

export const RoyaltiesInitializer = (
  initialValue: RoyaltiesContextState
): RoyaltiesContextState => initialValue

export const RoyaltiesReducer = (
  state: RoyaltiesContextState,
  action: Action<RoyaltiesActionType>
): RoyaltiesContextState => {
  switch (action.type) {
    case RoyaltiesActionType.SetTabLoading:
      return {
        ...state,
        tabLoading: action.payload,
      }
    case RoyaltiesActionType.SetLoadingOverview:
      return {
        ...state,
        loadingOverview: action.payload,
      }
    case RoyaltiesActionType.SetProcessingOptin:
      return {
        ...state,
        processingOptin: action.payload,
      }
    case RoyaltiesActionType.SetProcessingWithdraw:
      return {
        ...state,
        processingWithdraw: action.payload,
      }
    case RoyaltiesActionType.SetShowAlert:
      return {
        ...state,
        showAlert: action.payload,
      }
    case RoyaltiesActionType.ChangeTabSelected:
      return {
        ...state,
        tabSelected: action.payload,
      }
    case RoyaltiesActionType.ChangeFilterSelected:
      return {
        ...state,
        salesFilterSelected: action.payload,
      }
    case RoyaltiesActionType.LoadSalesSummaryData: {
      const {
        newSalesFilterSelected,
        newRoyaltiesSalesFilter,
        countriesSalesSummary,
        storesSalesSummary,
        releasesSalesSummary,
        monthsSalesSummary,
      } = action.payload
      return {
        ...state,
        salesFilterSelected: newSalesFilterSelected,
        royaltiesSalesFilter: newRoyaltiesSalesFilter,
        royaltiesCountriesSalesSummary: countriesSalesSummary,
        royaltiesStoresSalesSummary: storesSalesSummary,
        royaltiesReleasesSalesSummary: releasesSalesSummary,
        royaltiesMonthsSalesSummary: monthsSalesSummary,
      }
    }
    case RoyaltiesActionType.LoadSalesFullOverview: {
      const {
        newFullOverviewOptionSelected,
        newRoyaltiesSalesFullOverview,
      } = action.payload
      return {
        ...state,
        fullOverviewOptionSelected: newFullOverviewOptionSelected,
        royaltiesSalesFullOverview: newRoyaltiesSalesFullOverview,
      }
    }
    case RoyaltiesActionType.LoadPayoutData: {
      const {
        newOptInUSDCTransaction,
        newRoyaltiesPayoutInfo,
        newRoyaltiesPayoutHistory,
      } = action.payload
      return {
        ...state,
        optInUSDCTransaction: newOptInUSDCTransaction,
        royaltiesPayoutInfo: newRoyaltiesPayoutInfo,
        royaltiesPayoutHistory: newRoyaltiesPayoutHistory,
      }
    }
    case RoyaltiesActionType.SetUSDCOptedIn: {
      return {
        ...state,
        royaltiesPayoutInfo: {
          ...state.royaltiesPayoutInfo,
          usdcAsset: {
            ...state.royaltiesPayoutInfo.usdcAsset,
            optedIn: true,
          }
        },
      }
    }
    default:
      return state
  }
}
