import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import {
  PoolDetails,
  StakeData,
  GetStakeTransactionResponse,
  WalletTypeEnum,
} from '../../../shared/types'
import { formatNumber, formatNumberWithFixedDecimalPlaces } from '../../../utils'
import * as Sentry from '@sentry/react'
import { formatDate } from '@opulous/web/src/utils'
import PeraInfoModal from '@opulous/web/src/components/Wallet/PeraInfoModal'

import {
  DialogTitleText,
  StyledContent,
  StyledDialogTitle,
  StyledDialog,
} from '../InvestModal'
import {
  Label,
  Value,
  StyledInput as BaseStyledInput,
  Widget as BaseWidget,
  SubText as BaseSubText,
  BorderedInfo as BaseBorderedInfo,
  InfoItem as BaseInfoItem,
} from '../PoolStakePanel'
import algosdk from 'algosdk'
import { AxiosResponse } from 'axios'
import StyledAlert from '@opulous/web/src/components/Common/StyledAlert'
import { Collapse, useTheme } from '@mui/material'
import StyledLoadingButton from '@opulous/web/src/components/Common/StyledLoadingButton'
import { Link } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { addDays } from 'date-fns'
import { PeraWalletConnect } from '@perawallet/connect'
import { sendSignedTransactions, signTransactions } from 'src/services/my-algo'
import { algorandErrorString } from 'src/services/my-algo/errors'

const MAX_NUM_STAKES = 10;

const Widget = styled(BaseWidget)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),

  '&.disabled': {
    backgroundColor: theme.palette.grey.A100,
    pointerEvents: 'none',
    userSelect: 'none',
  },
}))

export const StyledInput = styled(BaseStyledInput)(() => ({
  input: {
    height: '36px',
  },
}))

export const BorderedInfo = styled(BaseBorderedInfo)<{
  isNotMarginTop?: number
  isBorderBottom?: number
  isBorderRadiusTop?: number
  isBorderRadiusBottom?: number
}>(
  ({
    isNotMarginTop = 0,
    isBorderBottom = 0,
    isBorderRadiusTop = 0,
    isBorderRadiusBottom = 1,
    theme,
  }) => ({
    borderRadius: theme.spacing(
      isBorderRadiusTop ? 1.5 : 0,
      isBorderRadiusTop ? 1.5 : 0,
      isBorderRadiusBottom ? 1.5 : 0,
      isBorderRadiusBottom ? 1.5 : 0
    ),
    ...(isNotMarginTop ? { marginTop: 0 } : {}),
    ...(isBorderBottom ? {} : { borderBottom: 'none' }),
  })
)

const CloseIconComponent = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.common.black,
}))

const InfoItem = styled(BaseInfoItem)<{ fullWidth?: number }>(
  ({ fullWidth = 0 }) => ({
    ...(fullWidth ? { width: '100%' } : {}),
    '&:first-child': {
      ...(fullWidth ? { borderRight: 'none' } : {}),
    },
  })
)

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(1.5),
}))

const SubText = styled(BaseSubText)(({ theme }) => ({
  color: theme.palette.grey[700],
}))

const AgreeText = styled('p')(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '18px',
}))

const TermText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
}))

interface ConfirmModalProps {
  open: boolean
  onClose: (value: boolean) => void
  pool: PoolDetails
  data: StakeData
  wallet: string
  walletType?: WalletTypeEnum
  connector: PeraWalletConnect
  stakeInPool: (txIds: string[]) => Promise<void>
  getStakeTransactions: (
    id: number,
    amount: BigNumber,
    period: number,
    walletAddress: string
  ) => Promise<AxiosResponse<GetStakeTransactionResponse>>
  activeStakeCount: number
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  data,
  pool,
  onClose,
  getStakeTransactions,
  wallet,
  walletType,
  connector,
  stakeInPool,
  activeStakeCount,
}) => {
  const theme = useTheme();
  const [checked, setChecked] = useState(false)
  const [isStaking, setIsStaking] = useState<boolean>(false)
  const [stakingError, setStakingError] = useState<string>('')
  const [peraDialogOpen, setPeraDialogState] = useState<boolean>(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const handleStake = async () => {
    if (activeStakeCount >= MAX_NUM_STAKES) {
      setStakingError(`You reached a maximum number of stakes (${MAX_NUM_STAKES}), further staking is not possible until you withdraw at least one stake!`);
      setIsStaking(false);
      return;
    }

    if (walletType === WalletTypeEnum.peraWallet) {
      Sentry.addBreadcrumb({
        category: '<ConfirmModal /> : handleStake',
        message: 'Displaying Pera mobile dialog',
        type: 'info',
        data: {
          wallet,
          walletType,
          connector,
        },
      })
      setPeraDialogState(true)
    }

    setStakingError('')
    setIsStaking(true)
    try {
      const { data: { data: stakeTransactions } } = await getStakeTransactions(
        pool.pool.id,
        data.amount,
        data.stake * 24 * 60 * 60,
        wallet
      );

      const txns = [
        stakeTransactions.assetPaymentTransaction,
        stakeTransactions.stakeIndexTransaction,
      ]
      const decodedTransactions = txns.map((t) => {
        return algosdk.decodeUnsignedTransaction(new Uint8Array(t))
      });
      const signedTxns = await signTransactions(decodedTransactions, connector, walletType);
      const { data: { result: { txId } } } = await sendSignedTransactions(signedTxns);
      setPeraDialogState(false);
      stakeInPool?.([txId]);
    } catch (ex) {
      Sentry.addBreadcrumb({
        category: '<ConfirmModal /> : handleStake',
        message: 'Error during stake transaction signing',
        type: 'error',
        data: {
          wallet,
          walletType,
          connector,
          ex,
        },
      })
      Sentry.captureException(ex)
      setStakingError(algorandErrorString(ex) || 'An error ocurred while staking, please try again');
      setPeraDialogState(false);
    } finally {
      setIsStaking(false)
    }
  }

  const unlockDate = formatDate(addDays(new Date(), data.stake));

  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <DialogTitleText variant="h6" textAlign="left">
          Stake confirmation
        </DialogTitleText>
        <IconButton
          size="small"
          onClick={() => onClose(false)}
          data-testid="Pool_ConfirmModal_Close-button"
        >
          <CloseIconComponent fontSize="small" />
        </IconButton>
      </StyledDialogTitle>
      <StyledContent>
        <Wrapper>
          <div>
            <Widget className="disabled">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Label color="textSecondary">Amount to stake</Label>
              </Box>
              <Value>
                <StyledInput value={formatNumberWithFixedDecimalPlaces(Number(data.amount.div(1e10)), 2)} />
                <SubText variant="h6" color="textSecondary">
                  OPUL
                </SubText>
              </Value>
            </Widget>
          </div>
          <div>
            <Widget className="disabled">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Label color="textSecondary">Stake Period</Label>
              </Box>
              <Value>
                <StyledInput className="disabled" value={formatNumber(Number(data.stake))} />
                <SubText variant="h6" color="textSecondary">
                  days
                </SubText>
              </Value>
            </Widget>
          </div>
          <div>
            <BorderedInfo
              isNotMarginTop={1}
              isBorderBottom={1}
              isBorderRadiusTop={1}
              isBorderRadiusBottom={0}
            >
              <InfoItem>
                <Label color="textSecondary">APY</Label>
                <Label data-testid="Pool_ConfirmModal_Apy">{formatNumberWithFixedDecimalPlaces(data.apy ?? 0, 2)} %</Label>
              </InfoItem>
              <InfoItem>
                <Label color="textSecondary">Est. Daily Profits</Label>
                <Label>{formatNumberWithFixedDecimalPlaces(parseFloat(data.dailyProfits || '0'), 2)} OPUL</Label>
              </InfoItem>
            </BorderedInfo>
            <BorderedInfo isNotMarginTop={1} isBorderRadiusBottom={1}>
              <InfoItem fullWidth={1}>
                <Label color="textSecondary">Unlock Date</Label>
                <Label>
                  {unlockDate}
                </Label>
              </InfoItem>
            </BorderedInfo>
          </div>
          <Box display="flex" alignItems={'center'}>
            <Checkbox
              color="primary"
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <AgreeText>
              I agree to the 
              <Link style={{textDecoration: 'none'}} to="/terms" target="_blank">
                <TermText>Terms and Conditions</TermText>
              </Link>
            </AgreeText>
          </Box>

          <Collapse in={!!stakingError}>
            <StyledAlert
              margin="0px 0px 40px 0px !important"
              width="100% !important"
              fontWeight="600"
              colorIcon={theme.palette.error.dark}
              colorMessage={theme.palette.error.dark}
              fontSizeMessage="15px"
              fontSizeIcon="16px"
              padding="14px 11px"
              backgroundColor={theme.palette.error.light}
              severity="error"
              dataTestId="pools__confirm-modal__error"
            >
              {stakingError}
            </StyledAlert>
          </Collapse>
          <StyledLoadingButton
            color="primary"
            size="small"
            fullWidth
            variant="contained"
            loading={isStaking}
            disabled={!checked}
            onClick={handleStake}
            borderRadius="32px"
            textTransform="capitalize"
            padding="12px 40px"
            background={theme.palette.primary.main}
            backgroundHover={theme.palette.primary.dark}
          >
            Confirm
          </StyledLoadingButton>
        </Wrapper>
      </StyledContent>
      <PeraInfoModal
        open={peraDialogOpen}
        onClose={() => setPeraDialogState(false)}
      />
    </StyledDialog>
  )
}

export default ConfirmModal
