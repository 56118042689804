import React, { ReactElement } from 'react';
import { styled } from '@mui/system';
import { Grid, Link, Typography } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

import {
  Asset,
  ISection,
  SectionArtistItem,
  SectionType,
} from '@opulous/web/src/shared/types';
import {
  SectionHighlights,
  SectionArtists,
  SidebarItem,
} from '@opulous/web/src/components/Assets/Songs/SongDetailTab/components';

const Section = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3.5),
}));

const StyledContainer = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));

const Title = styled(Typography)(() => ({
  fontSize: 24,
  lineHeight: '28.8px',
  fontWeight: 600,
  marginBottom: 24,
}));

const SecFilingsLinksContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
const StyledSecLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.spacing(3),
  padding: theme.spacing(0),
  color: theme.palette.info.main,
  fontWeight: 600,

  fontSize: theme.spacing(1.8),
  textDecoration: 'none',

  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(1.8),
  },

  '&:hover': {
    color: theme.palette.info.main,
  },
  svg: {
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}));

interface SongDetailTabProps {
  isAnalytic?: boolean;
  asset: Asset;
}

const SongDetailTab: React.FC<SongDetailTabProps> = ({
  asset,
}): ReactElement => {
  const renderSectionContent = (section: ISection) => {
    switch (section.type) {
      case SectionType.highlights:
        return (
          <SectionHighlights
            note={section.note}
            items={section.items as string[]}
          />
        );
      case SectionType.artists:
        return <SectionArtists items={section.items as SectionArtistItem[]} />;
      default:
        return null;
    }
  };

  return (
    <StyledContainer container spacing={4}>
      <Grid item xl={8} lg={12}>
        {asset?.custom?.content?.sections
          ?.sort((s1, s2) => s1.order - s2.order)
          .map((section, index) => (
            <Section key={index}>
              <Title>{section.title}</Title>
              {renderSectionContent(section)}
            </Section>
          ))}
          {asset?.custom?.secFilingsLinks?.length && (
            <Section key="sec-filings-links">
              <Title>SEC Filings Links</Title>
              <SecFilingsLinksContainer>
                {asset?.custom?.secFilingsLinks?.map((it, ix) => (
                  <StyledSecLink
                    key={`sec-link-${ix}`}
                    href={it.link}
                    target="_blank"
                    data-testid="asset-detail__sec-link"
                  >
                    {it.label}
                    <LaunchIcon />
                  </StyledSecLink>
                ))}
              </SecFilingsLinksContainer>
            </Section>
          )}
      </Grid>
      <Grid item xl={4} lg={12}>
        {asset?.custom?.sidebar?.items?.map(sidebarItem => (
          <SidebarItem {...sidebarItem} />
        ))}
      </Grid>
    </StyledContainer>
  );
};

export default SongDetailTab;
