import * as Yup from 'yup';

import { email, code } from '../common';

export const Step1Schema = Yup.object().shape({
    email,
});

export const Step2Schema = Yup.object().shape({
    verificationCode: code,
});

export const Step3Schema = Yup.object().shape({
    walletAddress: Yup.string().required('Please connect your wallet'),
    country: Yup.string().required('Please select a country'),
    taxId: Yup.string().required('TAX ID is required'),
});
export const Step3SchemaWithouTax = Yup.object().shape({
    walletAddress: Yup.string().required('Please connect your wallet'),
});
