import { ReactElement, useContext } from 'react';
import { styled } from '@mui/system';
import { Paper } from '@mui/material';
import WalletContext from '@opulous/web/src/context/context';
import { UltradeBuyAndSell } from '@opulous/web/src/components/UltradeWidget';
import { Asset } from '@opulous/web/src/shared/types';
import config from '@opulous/web/src/config';

const StyledUltradeWidgetContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 0,
  iframe: {
    border: 'none',
    height: theme.spacing(65),
    borderRadius: theme.spacing(2),
  },
}));

export default function StyledUltradeWidget({
  asset,
}: {
  asset: Asset;
}): ReactElement {
  const {
    state: { wallet, walletType, connector },
  } = useContext(WalletContext);

  if (!asset.unitName || !wallet || !config.env.ULTRADE_API_URL) {
    return <></>;
  }
  return (
    <StyledUltradeWidgetContainer data-testid="ultrade-widget__buy-and-sell">
      <UltradeBuyAndSell
        walletAddress={wallet}
        walletType={walletType}
        connector={connector}
        assetUnitName={asset.unitName}
      />
    </StyledUltradeWidgetContainer>
  );
}
