import React from 'react'
import ErrorMessage from '@opulous/web/src/components/shared/ErrorMessage'

const NotFoundPage: React.FC = () => {
  return (
    <ErrorMessage
      title="We're sorry"
      description="The page you are looking for doesn't exist"
      ctaLink="/admin/dashboard"
      ctaText="Go to Dashboard"
    />
  );
}

export default NotFoundPage
