import { ReactElement, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/system';
import { Paper } from '@mui/material';
import {
  InvestmentDetails,
  ExclusivePerkByLevel,
} from '@opulous/web/src/shared/types';
import { formatCurrency } from '@opulous/web/src/utils/index';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const SectionContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(2),
  padding: theme.spacing(3, 3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 2),
  },
  marginBottom: theme.spacing(4),
}));

const DetailsByLevel = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  gap: theme.spacing(1),
  height: theme.spacing(10),
  paddingLeft: theme.spacing(2),
  minWidth: theme.spacing(17),
  borderRight: `1px solid ${theme.palette.grey[100]}`,
  [theme.breakpoints.down('md')]: {
    minWidth: theme.spacing(14),
    paddingLeft: theme.spacing(1),
  },
}));
const Badge = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  img: {
    width: theme.spacing(2.6),
    height: theme.spacing(2.6),
  },
  span: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(1.7),
    fontWeight: '400',
  },
}));
const Value = styled('label')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.spacing(3.4),
  fontWeight: 'bold',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(3.2),
  },
}));
const Items = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  gap: theme.spacing(2),
  width: '100%',
  maxWidth: '100%',
  padding: theme.spacing(1),
  overflowX: 'hidden',
}));
const GoLeft = styled(ArrowBack)(({ theme }) => ({
  position: 'sticky',
  top: `calc(50% - ${theme.spacing(2.5)})`,
  left: 0,
  boxShadow: '0 0 16px 4px rgba(92, 100, 123, 0.08)',
  backgroundColor: theme.palette.common.white,
  borderRadius: '50%',
  padding: theme.spacing(1.2),
  width: theme.spacing(5),
  height: theme.spacing(5),
  zIndex: 2,
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    top: `calc(50% - ${theme.spacing(2)})`,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));
const GoRight = styled(ArrowForward)(({ theme }) => ({
  position: 'sticky',
  top: `calc(50% - ${theme.spacing(2.5)})`,
  right: 0,
  boxShadow: '0 0 16px 4px rgba(92, 100, 123, 0.08)',
  backgroundColor: theme.palette.common.white,
  borderRadius: '50%',
  padding: theme.spacing(1.2),
  width: theme.spacing(5),
  height: theme.spacing(5),
  zIndex: 2,
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    top: `calc(50% - ${theme.spacing(2)})`,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));
const Item = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: theme.spacing(15),
  width: theme.spacing(15),
  height: theme.spacing(20),
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.palette.shadows[2],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  label: {
    color: theme.palette.primary.black,
    fontSize: theme.spacing(1.5),
    fontWeight: '400',
    width: '100%',
    textAlign: 'center',
    lineHeight: '120%',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(1.2),
      padding: theme.spacing(0, 0.5),
    },
  },
}));

const Thumbnail = styled('img')(({ theme }) => ({
  width: theme.spacing(13),
  height: theme.spacing(13),
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));
const Details = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(2, 3, 0, 3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 2, 0, 0),
  },
}));
const Price = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.spacing(2.8),
  marginBottom: theme.spacing(1.2),
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(2.2),
    marginBottom: theme.spacing(0.8),
  },
}));
const Legend = styled('label')(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.spacing(1.8),
  color: theme.palette.primary.main,
  textDecoration: 'none',
  marginBottom: theme.spacing(1.2),
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(1.6),
    marginBottom: theme.spacing(0.8),
  },
}));
const Description = styled('p')(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  color: theme.palette.grey[500],
  lineHeight: '120%',
  margin: 0,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(1.3),
  },
}));

function InvestmentExclusivePerkByLevel({
  exclusivePerk,
}: {
  exclusivePerk: ExclusivePerkByLevel;
}): ReactElement {
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const [showGoLeft, setShowGoLeft] = useState(false);
  const [showGoRight, setShowGoRight] = useState(true);

  const goLeft = () => {
    if (itemsContainerRef?.current) {
      const container = itemsContainerRef?.current;

      let newScrollLeft = container.scrollLeft;
      newScrollLeft = newScrollLeft > 150 ? newScrollLeft - 150 : 0;

      container.scrollLeft = newScrollLeft;
      setShowGoLeft(newScrollLeft > 0);
      setShowGoRight(newScrollLeft <= 0);
    }
  };
  const goRight = () => {
    if (itemsContainerRef?.current) {
      const container = itemsContainerRef?.current;

      let newScrollLeft = container.scrollLeft;
      const diff =
        container.scrollWidth - (newScrollLeft + 150 + container.clientWidth);
      newScrollLeft = newScrollLeft + (diff > 0 ? 150 : 150 - diff);
      container.scrollLeft = newScrollLeft;
      setShowGoLeft(true);
      setShowGoRight(
        container.scrollWidth > newScrollLeft + container.clientWidth,
      );
    }
  };

  useEffect(() => {
    if (itemsContainerRef?.current) {
      const container = itemsContainerRef?.current;
      setShowGoLeft(container.scrollLeft > 0);
      setShowGoRight(container.scrollWidth > container.clientWidth);
    }
  }, []);

  return (
    <Items
      data-testid="investment-exclusive-perk-by-level__section__items"
      ref={itemsContainerRef}
    >
      {showGoLeft && <GoLeft onClick={goLeft} />}
      {exclusivePerk.items?.map((it, ix) => (
        <Item
          key={`perk-section-item-${ix}`}
          data-testid="investment-exclusive-perk-by-level__section__item"
        >
          <Thumbnail
            data-testid="investment-exclusive-perk-by-level__section__item__thumbnail"
            src={it.thumbnail.url}
          />
          <label data-testid="investment-exclusive-perk-by-level__section__item__title">
            {it.title}
          </label>
        </Item>
      ))}
      {showGoRight && <GoRight onClick={goRight} />}
    </Items>
  );
}

type Props = {
  investment: InvestmentDetails;
};
export default function InvestmentExclusivePerks({
  investment,
}: Props): ReactElement {
  return (
    <>
      {investment.exclusivePerks?.map((it, ix) => (
        <SectionContainer
          key={`perk-section-${ix}`}
          data-testid="investment-exclusive-perk__section"
        >
          <Thumbnail
            data-testid="investment-exclusive-perk__section__thumbnail"
            src={it.thumbnail.url}
          />
          <Details data-testid="investment-exclusive-perk__section__details">
            <Price data-testid="investment-exclusive-perk__section__details__value">
              {formatCurrency(it.value, '$', 0)}
            </Price>
            <Legend data-testid="investment-exclusive-perk__section__details__legend">
              {it.legend}
            </Legend>
            <Description data-testid="investment-exclusive-perk__section__details__description">
              {it.description}
            </Description>
          </Details>
        </SectionContainer>
      ))}
      {investment.exclusivePerksByLevel?.map((pk, ix) => (
        <SectionContainer
          key={`perk-section-${ix}`}
          data-testid="investment-exclusive-perk-by-level__section"
        >
          <DetailsByLevel data-testid="investment-exclusive-perk-by-level__section__details">
            <Badge>
              <img
                data-testid="investment-exclusive-perk-by-level__section__details__badge"
                src={pk.badge.url}
              />
              <span data-testid="investment-exclusive-perk-by-level__section__details__level-name">
                {pk.name}
              </span>
            </Badge>
            <Value data-testid="investment-exclusive-perk-by-level__section__details__value">
              {formatCurrency(pk.value, '$', 0)}
            </Value>
          </DetailsByLevel>
          <InvestmentExclusivePerkByLevel exclusivePerk={pk} />
        </SectionContainer>
      ))}
    </>
  );
}
