import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close'

const Link = styled('a')(({ theme }) => ({
  display: 'block',
  color: theme.palette.info.main,
  fontWeight: 600,
  textDecoration: 'none',
}));

export default function MyAlgoWarningDialog({
  show,
  onClose,
}: {
  show: boolean
  onClose: () => void
}) {
  return (
    <Dialog
      title="MyAlgoWallet warning"
      onClose={onClose}
      maxWidth="lg"
      open={show}
    >
      <Box display="flex" alignItems="center" padding={4} flex="1">
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h3">Warning</Typography>
            <IconButton onClick={onClose}><CloseIcon /></IconButton>
          </Box>
          <div>MyAlgoWallet has reported a security issue. Please migrate to Pera Wallet as soon as possible.</div>
          <Box display="flex" gap={2} flexWrap="wrap">
            <Link target="_blank" href="https://twitter.com/myalgo_/status/1630185695791706120">Report</Link>
            <Link target="_blank" href="https://support.perawallet.app/en/article/how-to-rekey-an-algorand-account-with-pera-web-wallet-9alza3/">Migrate to Pera Wallet</Link>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
