import { ReactElement } from 'react';
import { Theme, Tooltip } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { formatNumber, formatOpulAmount } from '@opulous/web/src/utils';
import MinorOPUL from '@opulous/web/src/shared/valueObjects/MinorOPUL';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      fontWeight: 400,
      boxShadow: `0px 0 5px ${theme.palette.grey[300]}`,
      borderRadius: '5px',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      padding: theme.spacing(0.2, 1.5),
      fontSize: theme.spacing(2.4),
    },

    text: {
      overflowWrap: 'anywhere',
    },
  }),
);

type Props = {
  'data-testid'?: string;
  className?: string;
  hideUnit?: boolean;
  showValue?: boolean;
  value: MinorOPUL | string;
};
export default function OPULLabel(props: Props): ReactElement | null {
  const { className, value, hideUnit = false } = props;
  const classes = useStyles();

  if (value === null || value === undefined) {
    return <span className={className}>-</span>;
  }

  return (
    <Tooltip
      title={props.showValue ? formatNumber(MinorOPUL.create(value).toBigInt()) : `${formatOpulAmount(value)}${!hideUnit ? ' OPUL' : ''}`}
      componentsProps={{
        tooltip: { className: classes.tooltip },
      }}
    >
      <span
        className={`${classes.text} ${className ?? ''}`}
        data-testid={props['data-testid'] || 'opul-label'}
      >
        {formatOpulAmount(value, 2)}
      </span>
    </Tooltip>
  );
}
