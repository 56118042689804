import { ReactElement, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Fade, Grid, Modal, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  AssetType,
} from '@opulous/web/src/shared/types';
import {
  PageTitle,
  StyledButton,
  InvestmentDetailsCard,
} from '@opulous/web/src/pages/Sales/Investment/styled-components';
import InvestmentContext from '@opulous/web/src/pages/Sales/Investment/context';
import ConnectWallet from '@opulous/web/src/components/Common/ConnectWallet';
import {
  PaymentMethodOptions,
  WalletTypeEnum,
} from '@opulous/web/src/shared/types';
import WalletContext from '@opulous/web/src/context/context';
import {
  BitCoinIcon,
  CreditCardIcon,
  EthereumIcon,
  GenericIcon,
  OPULIcon,
} from '@opulous/web/src/pages/Sales/Investment/PaymentMethod/icons';

const StyledPageTitle = styled(PageTitle)(({ theme }) => ({
  fontSize: theme.spacing(4.5),
  marginTop: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
}));
const OptionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: theme.spacing(60),
  gap: theme.spacing(2),
  marginTop: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(6),
  },
}));
const OptionButton = styled('button')<{ active?: boolean; disabled?: boolean }>(
  ({ theme, active, disabled }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(3),
    color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
    fontSize: theme.spacing(2.2),
    fontWeight: '600',
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${
      disabled
        ? theme.palette.grey[200]
        : active
        ? theme.palette.primary.main
        : theme.palette.grey[400]
    }`,
    '& > div': {
      display: 'flex',
      gap: theme.spacing(0.5),
      img: {
        width: theme.spacing(4),
        opacity: disabled ? 0.4 : 1,
      },
    },
  }),
);

const ActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: theme.spacing(60),
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
}));
const StyledModalBackground = styled(Modal)(() => ({
  '& .MuiPaper-root': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const ActionButton = styled(StyledButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    maxWidth: '100%',
  },
}));

export default function InvestmentPaymentMethodPage(): ReactElement {
  const {
    state: walletState,
    initPeraWallet,
    initMyAlgoWallet,
  } = useContext(WalletContext);
  const { state: investmentState, actions: investmentActions } =
    useContext(InvestmentContext);
  const [openModalWallet, setOpenModalWallet] = useState(false);
  const [optionSelected, setOptionSelected] = useState<PaymentMethodOptions>(
    PaymentMethodOptions.CRYPTO,
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { investment } = investmentState;

  const handleConnectWallet = async (type: WalletTypeEnum) => {
    switch (type) {
      case WalletTypeEnum.myAlgoWallet:
        initMyAlgoWallet();
        return;
      case WalletTypeEnum.peraWallet:
        initPeraWallet();
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    if (walletState.wallet) {
      setOpenModalWallet(false);
    }
  }, [walletState.wallet]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const isAnOvault = investment?.asset?.type === AssetType.ovault;

  return (
    <Fade in data-testid="investment-payment-method-page">
      <Grid container spacing={6}>
        <Grid item xl={6} xs={12}>
          <StyledPageTitle data-testid="investment-payment-method-page__title">
            Choose your payment method
          </StyledPageTitle>
          <OptionsWrapper>
            <OptionButton
              data-testid="investment-payment-method-page__options__crypto"
              active={optionSelected === PaymentMethodOptions.CRYPTO}
              onClick={() => setOptionSelected(PaymentMethodOptions.CRYPTO)}
            >
              <span>Cryptocurrencies</span>
              <div>
                <BitCoinIcon />
                <EthereumIcon />
                <GenericIcon />
              </div>
            </OptionButton>
            {!isAnOvault && <OptionButton
              data-testid="investment-payment-method-page__options__opul"
              active={optionSelected === PaymentMethodOptions.OPUL}
              onClick={() => setOptionSelected(PaymentMethodOptions.OPUL)}
            >
              <span>OPUL</span>
              <div>
                <OPULIcon />
              </div>
            </OptionButton>}
            <OptionButton
              data-testid="investment-payment-method-page__options__credit-card"
              active={optionSelected === PaymentMethodOptions.CREDI_CARD}
              disabled
            >
              <span>Credit card (soon)</span>
              <div>
                <CreditCardIcon />
              </div>
            </OptionButton>
          </OptionsWrapper>
          <ActionsWrapper>
            {walletState.wallet && (
              <ActionButton
                data-testid="investment-payment-method-page__next-button"
                disabled={!optionSelected}
                onClick={() =>
                  investmentActions.renderPurchaseConfirmationStep({
                    paymentMethod: optionSelected || PaymentMethodOptions.OPUL,
                  })
                }
              >
                Next
              </ActionButton>
            )}
            {!walletState.wallet && (
              <StyledButton
                data-testid="investment-payment-method-page__options__connect-wallet-button"
                onClick={() => setOpenModalWallet(true)}
              >
                Connect wallet
              </StyledButton>
            )}
          </ActionsWrapper>
          {openModalWallet && (
            <StyledModalBackground open={openModalWallet}>
              <ConnectWallet
                onClose={() => setOpenModalWallet(false)}
                onWalletSelect={type => {
                  handleConnectWallet(type);
                }}
                loading={false}
                errorText={''}
              />
            </StyledModalBackground>
          )}
        </Grid>
        {!isMobile && (
          <Grid item xl={6} xs={12} display={'flex'} justifyContent={'center'}>
            {investment && <InvestmentDetailsCard investment={investment} />}
          </Grid>
        )}
      </Grid>
    </Fade>
  );
}
