import React, { useEffect, useRef, useState } from 'react';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { formatNumber } from '@opulous/web/src/utils';

const MAX_INPUT_LEN = 16; // Length of Number.MAX_SAFE_INTEGER
const FOCUS_TIMEOUT_MS = 5000;

const useStyles = makeStyles((theme: Theme) => createStyles({
  input: {
    border: 0,
    outline: 0,
    fontSize: theme.spacing(4),
    lineHeight: theme.spacing(5.75),
    fontWeight: '300',
    padding: theme.spacing(2),
    width: '100%',
  },

  displayText: {
    userSelect: 'none',
  },
}));

function IntegerInput({
  className,
  style,
  onChange,
  value,
  ...props
}: Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'|'value'> & {
  onChange: (n: string) => void
  value: string
}): React.ReactElement {
  const classes = useStyles();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const displayTextRef = useRef<HTMLInputElement>(null);
  const timerRef = useRef<number>(-1);

  const resetFocusTimeout = () => {
    clearTimeout(timerRef.current);
    Object.assign(timerRef, {
      current: setTimeout(() => {
        setIsFocus(false);
        displayTextRef.current?.blur();
      }, FOCUS_TIMEOUT_MS),
    });
  };

  const handleFocus = () => setIsFocus(true);
  const handleBlur = () => setIsFocus(false);

  const handleChange = () => {
    resetFocusTimeout();

    const v = inputRef.current?.value || '';
    let str = '';
    for (const chr of v) {
      if (str.length >= MAX_INPUT_LEN) break;
      if (chr >= '0' && chr <= '9') {
        str += chr;
      } else {
        break;
      }
    }
    onChange(str);
  };

  useEffect(() => {
    if (isFocus) {
      resetFocusTimeout();
    } else {
      clearTimeout(timerRef.current);
      displayTextRef.current?.blur();
    }
  }, [isFocus]);

  if (!isFocus) {
    return (
      <input
        className={`${classes.input} ${classes.displayText} ${className ?? ''}`}
        type="text"
        style={style}
        value={value ? formatNumber(parseInt(value)) : '-'}
        onClick={handleFocus}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoFocus={false}
        ref={displayTextRef}
      />
    );
  }

  return (
    <input
      className={`${classes.input} ${className ?? ''}`}
      type="text"
      style={style}
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      onPaste={handleChange}
      ref={inputRef}
      autoFocus
      {...props}
    />
  );
}

export default IntegerInput;
