import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import _InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import PageHeader from '../../components/PageHeader';
import { raffles as RaffleService } from '@opulous/web/src/services';
import WalletContext from '@opulous/web/src/context/context';
import { IRaffle } from '@opulous/web/src/shared/types';
import RaffleCard from '../../components/Raffles/RaffleCard';
import SkeletonCard from '@opulous/web/src/components/Raffles/RaffleCard/SkeletonCard';
import { formatNumber } from '@opulous/web/src/utils';

const MAX_NUM_COMPLETED_RAFFLES_DISPLAY = 5;

const Wrapper = styled('div')(() => ({
  position: 'relative',
}));

const Container = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const StyledBox = styled(Box)(() => ({
  width: 'calc(100% - 24px) !important',
  margin: 0,
}));
const EmptyMessage = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(3),
}));

const InfoIcon = styled(_InfoIcon)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const InfoBadge = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  fontWeight: '600',
  boxShadow: theme.palette.shadows[2],
  borderRadius: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  fontSize: '.875rem',

  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
  },

  svg: {
    width: '1rem',
    height: '1rem',
  },
}));

const PoolLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  fontWeight: '600',
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.75),

  '& svg': {
    width: '1rem',
    height: '1rem',
  },
}));

export default function RafflesPage(): ReactElement {
  const theme = useTheme();
  const {
    state: { wallet },
  } = useContext(WalletContext);
  const [isLoading, setIsLoading] = useState(false);
  const [raffles, setRaffles] = useState<IRaffle[]>([]);
  const [numAvailableTickets, setNumAvailableTickets] = useState<number>(0);
  const [shouldShowAllCompletedRaffles, setShouldShowAllCompletedRaffles] =
    useState(false);

  const tooltipStyles = useMemo(
    () => ({
      lineHeight: '1.1rem',
      padding: theme.spacing(1, 2),
      maxWidth: '12rem',
    }),
    [theme],
  );

  const [activeRaffles, upcomingRaffles, endedRaffles, completedRaffleCount] =
    useMemo(() => {
      const _activeRaffles: IRaffle[] = [];
      const _upcomingRaffles: IRaffle[] = [];
      const _endedRaffles: IRaffle[] = [];

      const sortedRaffles = raffles
        .filter(r => r.title !== 'OPUL Airdrops') // hack until raffle editor is live
        .sort((a, b) =>
          new Date(a.endTimestamp).getTime() <
          new Date(b.endTimestamp).getTime()
            ? 1
            : -1,
        );

      let _completedRaffleCount = 0;

      for (const raffle of sortedRaffles) {
        const start = new Date(raffle.startTimestamp).getTime();
        const end = new Date(raffle.endTimestamp).getTime();
        if (Date.now() > end) {
          _completedRaffleCount++;
          if (shouldShowAllCompletedRaffles) {
            _endedRaffles.push(raffle);
          } else if (
            _endedRaffles.length < MAX_NUM_COMPLETED_RAFFLES_DISPLAY ||
            (raffle.walletRaffleEntry?.hasWon === true && // NOTE: We also need to check `hasWon` data type
              raffle.walletRaffleEntry?.hasClaimed === false) // NOTE: We also need to check `hasClaimed` data type
          ) {
            _endedRaffles.push(raffle);
          }
        } else if (Date.now() < start) {
          _upcomingRaffles.push(raffle);
        } else {
          _activeRaffles.push(raffle);
        }
      }

      return [
        _activeRaffles,
        _upcomingRaffles,
        _endedRaffles,
        _completedRaffleCount,
      ];
    }, [raffles, shouldShowAllCompletedRaffles]);

  async function fetchRaffles() {
    setIsLoading(true);

    const {
      data: {
        data: { raffles: _raffles, numAvailableTickets: _numAvailableTickets },
      },
    } = await RaffleService.getRaffles(wallet || '');

    setRaffles(_raffles);
    setNumAvailableTickets(wallet ? parseInt(_numAvailableTickets) : 0);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchRaffles();
  }, [wallet]);

  const handleTicketsAdd = () => fetchRaffles();
  const handleRewardClaimed = () => fetchRaffles();

  return (
    <Wrapper data-testid="raffles-page">
      <PageHeader title="Raffles">
        {!isLoading && (
          <Box display="flex" alignItems="center" gap={2}>
            {!!wallet && (
              <InfoBadge>
                <span>
                  {formatNumber(numAvailableTickets)} tickets available
                </span>
                <Tooltip
                  title="One daily ticket for every 10 OPUL you stake!"
                  componentsProps={{ tooltip: { style: tooltipStyles } }}
                  arrow
                >
                  <InfoIcon />
                </Tooltip>
              </InfoBadge>
            )}

            <Tooltip
              title="Earn one daily ticket for every OPUL you stake."
              componentsProps={{ tooltip: { style: tooltipStyles } }}
              placement="top"
              arrow
            >
              <PoolLink to="/pools">
                <span>Get tickets</span>
              </PoolLink>
            </Tooltip>
          </Box>
        )}
      </PageHeader>
      <Container container spacing={3}>
        <Grid
          item
          xl={8}
          lg={10}
          xs={12}
          style={{
            maxWidth: 'inherit',
            flex: 1,
          }}
        >
          {isLoading &&
            new Array(3).fill(null).map((_, i) => <SkeletonCard key={i} />)}
          {!isLoading &&
            (raffles.length ? (
              <>
                {!!activeRaffles.length && (
                  <>
                    <Typography variant="h4" marginTop={1} marginBottom={3}>
                      Active Raffles
                    </Typography>
                    {activeRaffles
                      .sort((r1, r2) => r2.id - r1.id)
                      .map(raffle => (
                        <RaffleCard
                          key={raffle.id}
                          raffle={raffle}
                          numAvailableTickets={numAvailableTickets}
                          onTicketsAdd={handleTicketsAdd}
                        />
                      ))}
                  </>
                )}
                {!!upcomingRaffles.length && (
                  <>
                    <Typography variant="h4" marginTop={1} marginBottom={3}>
                      Upcoming Raffles
                    </Typography>
                    {upcomingRaffles.map(raffle => (
                      <RaffleCard
                        key={raffle.id}
                        raffle={raffle}
                        numAvailableTickets={numAvailableTickets}
                      />
                    ))}
                  </>
                )}
                {!!endedRaffles.length && (
                  <>
                    <Typography variant="h4" marginTop={1} marginBottom={3}>
                      Completed Raffles
                    </Typography>
                    {endedRaffles.map(raffle => (
                      <RaffleCard
                        key={raffle.id}
                        raffle={raffle}
                        numAvailableTickets={numAvailableTickets}
                        onRewardClaimed={handleRewardClaimed}
                      />
                    ))}
                    {completedRaffleCount > MAX_NUM_COMPLETED_RAFFLES_DISPLAY &&
                      !shouldShowAllCompletedRaffles && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          paddingBottom={3}
                        >
                          <Button
                            variant="outlined"
                            onClick={() =>
                              setShouldShowAllCompletedRaffles(true)
                            }
                          >
                            See all completed raffles
                          </Button>
                        </Box>
                      )}
                  </>
                )}
              </>
            ) : (
              <StyledBox data-testid="raffles-page__empty-wrapper">
                <EmptyMessage
                  variant="body1"
                  gutterBottom
                  data-testid="raffles-page__empty-wrapper__message"
                >
                  No raffle at the moment
                </EmptyMessage>
              </StyledBox>
            ))}
        </Grid>
      </Container>
    </Wrapper>
  );
}
