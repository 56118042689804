import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Pagination, Skeleton, useTheme } from '@mui/material';
import * as SaleService from 'src/services/sales';
import { Investment } from 'src/shared/types';
import AdminInvestmentCard, { Card, ContentContainer } from 'src/components/Sales/InvestmentCard/AdminInvestmentCard';

const PAGE_SIZE = 12;

const Container = styled('div')(() => ({
  width: '100%',
  maxWidth: '80rem',
}));

const SaleGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: '1rem',
  gridTemplateColumns: 'repeat(auto-fill, minmax(34rem, 1fr))',

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))',
  },
}));

export function SaleSkeleton() {
  const theme = useTheme();
  return (
    <Card>
      <ContentContainer>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Skeleton variant="rectangular" width="100%" style={{
            borderRadius: theme.spacing(2),
            overflow: 'hidden',
            objectFit: 'contain',
            paddingBottom: '100%',
          }} />
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Skeleton variant="text" height="2rem" width="85%" />
          <Skeleton variant="text" height="1rem" width="40%" />
          <Skeleton
            variant="rectangular"
            style={{ minHeight: '12rem', flex: '1', borderRadius: theme.spacing(2) }}
          />
        </Box>
      </ContentContainer>
      <Box display="flex" justifyContent="flex-end" gap={1}>
        <Skeleton variant="circular" width="2rem" height="2rem" />
        <Skeleton variant="circular" width="2rem" height="2rem" />
      </Box>
    </Card>
  );
}

export default function SalePage(): React.ReactElement {
  const navigate = useNavigate();
  const [investments, setInvestments] = useState<Investment[]>([]);
  const [numSales, setNumSales] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchInvestments() {
    setIsLoading(true);
    const { data, count } = await SaleService.adminGetInvestments(page, PAGE_SIZE);
    setInvestments(data);
    setNumSales(count);
    setIsLoading(false);
  }

  const handleInvestmentCreate = () => navigate('/admin/sales/create');

  useEffect(() => {
    fetchInvestments();
  }, [page]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={3}>
      <Container>
        <div>
          <h1>Sales</h1>
        </div>
        <Box display="flex" justifyContent="flex-end" paddingY={2}>
          <Button onClick={handleInvestmentCreate}>Create</Button>
        </Box>
        <div>
          <SaleGrid>
            {isLoading && (new Array(3).fill(null).map((_, i) => (
              <SaleSkeleton key={`skeleton-${i}`} />
            )))}
            {!isLoading && investments.map(investment => (
              <AdminInvestmentCard
                key={investment.id}
                investment={investment}
                onInvestmentRefresh={fetchInvestments}
              />
            ))}
          </SaleGrid>
        </div>
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Pagination
            count={Math.ceil(numSales / PAGE_SIZE)}
            color="primary"
            onChange={(_e, p) => setPage(p)}
            page={page}
          />
        </Box>
      </Container>
    </Box>
  );
}
