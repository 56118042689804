import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { isIOS } from 'react-device-detect';
import { isMobile } from 'react-device-detect';

import {
  StyledContent,
  StyledDialogAction,
  StyledDialog,
} from '../../Pools/InvestModal'
import Typography from '@mui/material/Typography'
import StyledLoadingButton from '@opulous/web/src/components/Common/StyledLoadingButton'
import _PeraWalletIcon from './PeraWalletIcon'

const Widget = styled(Box)(({ theme }) => ({
  // borderRadius: theme.spacing(1.5),
  // padding: theme.spacing(1, 1.5),
  // border: `1px solid ${theme.palette.grey[200]}`,
  position: 'relative',
  backgroundColor: theme.palette.common.white,
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: '.875rem',
  margin: theme.spacing(0, 0.25),
}));

const StyledDialogTitle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.75, 3),
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5),
  },
}))

const PeraWalletIcon = styled(_PeraWalletIcon)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  width: '3rem',
  height: '3rem',
}));

interface PeraInfoModalProps {
  open: boolean;
  onClose: () => void;
}

const PeraInfoModal: React.FC<PeraInfoModalProps> = ({ open, onClose }) => {
  const peraUniversalLink = isIOS
    ? 'algorand-wc://wc?uri=wc:00e46b69-d0cc-4b3e-b6a2-cee442f97188@1'
    : 'wc:00e46b69-d0cc-4b3e-b6a2-cee442f97188@1';

  const handleAppClick = () => {
    onClose();
    window.location.href = peraUniversalLink;
  };

  return (
    <StyledDialog open={open} onClose={onClose} data-testid="pera-wallet-modal">
      <StyledDialogTitle>
        <Box flex="1" />
        <Box flex="1" display="flex" alignItems="center" justifyContent="center">
          <Typography
            variant="h6"
            textAlign="left"
            whiteSpace="nowrap"
            fontSize="1rem"
            data-testid="pera-wallet-modal__title"
          >
            Confirm transactions
          </Typography>
        </Box>
        <Box flex="1" display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => onClose()}
            data-testid="pera-wallet-modal__close-button"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </StyledDialogTitle>
      <StyledContent data-testid="pera-wallet-modal__content">
        <Widget>
          <Box display="flex" justifyContent="center" alignItems="center">
            <PeraWalletIcon />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingTop={2}
          >
            <Label data-testid="pera-wallet-modal__content__legend" textAlign="center">
              Please <b>open your Pera wallet app</b> to confirm the transactions.
            </Label>
          </Box>
        </Widget>
      </StyledContent>
      {isMobile && (
        <StyledDialogAction>
          <StyledLoadingButton
            variant="contained"
            size="small"
            borderRadius="32px"
            onClick={handleAppClick}
          >
            Open app
          </StyledLoadingButton>
        </StyledDialogAction>
      )}
    </StyledDialog>
  );
};

export default PeraInfoModal;
