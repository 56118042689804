import React from 'react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { AssetType, InvestmentRaw } from '@opulous/web/src/shared/types';
import { formatNumber } from '@opulous/web/src/utils';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  borderRadius: theme.spacing(2),
  cursor: 'pointer',
  minHeight: theme.spacing(8),
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  paddingBottom: '100%',
  backgroundColor: theme.palette.grey[300],
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: `${theme.spacing(2)} !important`,
  position: 'relative',
}));

const StyledLoader = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(1),
  display: 'flex',
  position: 'absolute',
  top: theme.spacing(-4),
  right: theme.spacing(1),
  left: theme.spacing(1),
  alignItems: 'center',
  gap: '9px',
  '& p': {
    fontSize: '10px',
  },
  '& .spinning-loader': {
    width: '10px',
    height: '10px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    background: 'transparent',
    borderLeftColor: 'transparent',
    animationName: 'rotate-s-loader',
    animationIterationCount: 'infinite',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    position: 'relative',
    marginLeft: '9px',
  },
  '@keyframes rotate-s-loader': {
    from: {
      transform: 'rotate(0)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '90%',
  overflow: 'hidden',
  color: theme.palette.text.primary,
  fontWeight: 'bold',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  minHeight: theme.spacing(6),
  fontSize: theme.spacing(1.875),
  color: theme.palette.grey[400],
}));

const AssetTypeTitle = styled('span')<{ type: AssetType }>(
  ({ type, theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    lineHeight: theme.spacing(2),
    borderRadius: theme.spacing(2),
    padding: '5px 10px',
    backgroundColor:
      type === AssetType.artwork
        ? theme.palette.secondary.dark
        : theme.palette.primary.main,
  }),
);

const AssetMount = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.common.white,
  fontWeight: 500,
  fontSize: theme.spacing(1.75),
  lineHeight: theme.spacing(2),
  borderRadius: theme.spacing(2),
  padding: '5px 10px',
  backgroundColor: 'rgba(0, 0, 5, 0.4)',
}));

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: '6px 10px',
    marginBottom: 6,
  },
}));

interface AssetCardProps {
  unitName: string;
  image: string;
  title: string;
  subTitle?: string;
  nftCount?: number;
  decimals?: number;
  type: AssetType;
  transferInProgress?: boolean;
  investment?: InvestmentRaw;
}

const AssetCard: React.FC<AssetCardProps> = ({
  image,
  title,
  subTitle,
  type,
  nftCount,
  unitName,
  transferInProgress = false,
  investment,
}) => {
  const navigate = useNavigate();

  const goToDetailPage = () => {
    if (Number.isInteger(investment?.id)) {
      return navigate(`/mfts/${investment?.id}`);
    }
    navigate(`/tokens/${unitName}`, { state: { transferInProgress } });
  };

  return (
    <StyledCard onClick={goToDetailPage} data-testid="asset-card">
      <AssetTypeTitle data-testid={`asset-type-${type}`} type={type}>
        {type === AssetType.artwork ? 'Artwork' : 'MFT'}
      </AssetTypeTitle>
      {!!nftCount && nftCount > 0 && (
        <BootstrapTooltip
          title={`${nftCount} owned ${nftCount > 1 ? 'assets' : 'asset'}`}
          placement="top"
        >
          <AssetMount data-testid="asset-amount">{formatNumber(nftCount)}</AssetMount>
        </BootstrapTooltip>
      )}
      <StyledCardMedia image={`${image}`} data-testid="asset-card__image" />
      <StyledCardContent>
        <Title data-testid="asset-card__title">{title}</Title>
        <SubTitle data-testid="asset-card__subtitle">
          {subTitle || null}
        </SubTitle>
        {transferInProgress && (
          <StyledLoader data-testid="asset-card__transfering">
            <div className="spinning-loader" />
            <Typography>Transfer in progress…</Typography>
          </StyledLoader>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

export default AssetCard;
