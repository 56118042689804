import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress as _CircularProgress,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';
import Papa from 'papaparse';
import {
  IssuanceFileStatus,
  IssuanceType,
  IssuanceCurrentState,
} from 'src/shared/types';
import startCase from 'lodash/startCase';
import * as IssuanceService from 'src/services/issuance';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { formatDateTimeUtc } from 'src/utils';
import { useSignerApp } from '@opulous/web/src/context/SignerAppContext';
import IssuanceStateModal from '@opulous/web/src/components/Admin/Issuances/IssuanceStateModal';
import axios from 'axios';
import config from 'src/config';
import { formatWallet } from '@opulous/web/src/shared/helpers';

const PAGE_SIZE = 10;
const FILE_NAME_PLACEHOLDER = '<File Name>';

enum IssuancePageStatus {
  NONE = 'NONE',
  DOWNLOADING = 'DOWNLOADING',
  SIGNING = 'SIGNING',
  ISSUING = 'ISSUING',
  CHECKING = 'CHECKING',
}

interface Issuance extends IssuanceService.IIssuanceFile {
  pageStatus: IssuancePageStatus;
  isSigned: boolean;
  errorMsg: string;
}

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '80rem',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const ErrText = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: '700',
  fontSize: '.9rem',
}));

const Fieldset = styled('fieldset')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  color: theme.palette.text.primary,
  display: 'block',
  position: 'relative',
  minWidth: 0,

  '&:hover': {
    border: `1px solid ${theme.palette.common.black}`,
  },

  '&.error': {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },

  '& > legend': {
    fontSize: '.75rem',
    fontWeight: '300',
  },
}));

const FileKeyContainer = styled('div')(() => ({
  overflowWrap: 'anywhere',
}));

const SampleFileLink = styled('a')(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: 700,
  textDecoration: 'none',
  fontSize: '.8rem',
}));

async function parseCsvFile(file: File): Promise<Papa.ParseResult<string[]>> {
  return new Promise(resolve => {
    Papa.parse<string[]>(file, {
      header: false,
      skipEmptyLines: true,
      complete(results) {
        return resolve(results);
      },
    });
  });
}

function validateCsvFileColumns(csv: string[][]): Error | null {
  // NOTE: Columns: <assetId> <receiver> <amount> <issuer>
  const receiverAssetMap = new Map<string, boolean>();

  for (let i = 0; i < csv.length; i++) {
    const row = csv[i];
    const rowNumber = i + 1;

    if (row.length !== 4) {
      return new Error(
        `File should have 4 columns <assetId>,<receiver>,<amount>,<issuer>, found ${row.length} at row ${rowNumber} instead`,
      );
    }

    if (!/^\d+$/.test(row[0])) {
      return new Error(`Invalid asset ID at row ${rowNumber}`);
    }
    if (!/^[A-Z2-7]{58}$/.test(row[1])) {
      return new Error(`Invalid receiver wallet address at row ${rowNumber}`);
    }
    if (!/^\d+(\.\d+)?$/.test(row[2])) {
      return new Error(`Invalid amount at row ${rowNumber}`);
    }
    if (!/^[A-Z2-7]{58}$/.test(row[3])) {
      return new Error(`Invalid issuer wallet address at row ${rowNumber}`);
    }

    const receiverAssetKey = `${row[1]}-${row[0]}`;
    if (receiverAssetMap.has(receiverAssetKey)) {
      return new Error(
        `Duplicate asset ID for receiver ${formatWallet(
          row[1],
        )} at row ${rowNumber}`,
      );
    }
    receiverAssetMap.set(receiverAssetKey, true);
  }
  return null;
}

function IssuanceTableRow({
  issuance,
  isWalletConnected,
  actions,
}: {
  issuance: Issuance;
  isWalletConnected: boolean;
  actions: {
    handleSign: (issuanceFileId: number) => Promise<void>;
    handleIssue: () => Promise<void>;
    handleCheckIssuance: (issuanceFileId: number) => Promise<void>;
    handleDownload: (fileKey: string) => Promise<void>;
  };
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Box
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            maxWidth="16rem"
            title={issuance.displayName}
          >
            {issuance.displayName}
          </Box>
        </TableCell>
        <TableCell align="right">{issuance.type}</TableCell>
        <TableCell align="right">{issuance.status}</TableCell>
        <TableCell>
          <Box display="flex" alignItems="center" justifyContent="center">
            {
              {
                [IssuanceFileStatus.NEW]: <></>,
                [IssuanceFileStatus.RUNNING]: (
                  <_CircularProgress size="1.5rem" />
                ),
                [IssuanceFileStatus.FAILED]: <ErrorIcon color="error" />,
                [IssuanceFileStatus.SUCCEEDED]: (
                  <CheckCircleIcon color="success" />
                ),
              }[issuance.status]
            }
          </Box>
        </TableCell>
        <TableCell align="right">
          {formatDateTimeUtc(new Date(issuance.createdAt))}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display="flex" flexDirection="column" padding={1} gap={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>File Key</TableCell>
                    <TableCell>
                      <FileKeyContainer>{issuance.fileKey}</FileKeyContainer>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Uploader ID</TableCell>
                    <TableCell>{issuance.uploadedBy}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Box
                display="flex"
                justifyContent="flex-end"
                flexWrap="wrap"
                paddingY={1}
                gap={1}
              >
                <LoadingButton
                  variant="contained"
                  size="small"
                  onClick={() => actions.handleDownload(issuance.fileKey)}
                  loading={
                    issuance.pageStatus === IssuancePageStatus.DOWNLOADING
                  }
                >
                  Download
                </LoadingButton>
                {issuance.status !== IssuanceFileStatus.NEW && (
                  <LoadingButton
                    variant="contained"
                    size="small"
                    onClick={() => actions.handleCheckIssuance(issuance.id)}
                    loading={
                      issuance.pageStatus === IssuancePageStatus.CHECKING
                    }
                  >
                    Check
                  </LoadingButton>
                )}
                {isWalletConnected &&
                  issuance.status === IssuanceFileStatus.NEW && (
                    <LoadingButton
                      variant="contained"
                      size="small"
                      onClick={() => actions.handleSign(issuance.id)}
                      loading={
                        issuance.pageStatus === IssuancePageStatus.SIGNING
                      }
                    >
                      Sign Transactions
                    </LoadingButton>
                  )}
                {isWalletConnected &&
                  issuance.status === IssuanceFileStatus.NEW && (
                    <LoadingButton
                      variant="contained"
                      size="small"
                      disabled={!issuance.isSigned}
                      onClick={() => actions.handleIssue()}
                      loading={
                        issuance.pageStatus === IssuancePageStatus.ISSUING
                      }
                    >
                      Issue
                    </LoadingButton>
                  )}
              </Box>

              {issuance.errorMsg && (
                <Box minHeight="2rem" display="flex" justifyContent="flex-end">
                  <ErrText>{issuance.errorMsg}</ErrText>
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function SkeletonIssuanceTable() {
  return (
    <Box display="flex" flexDirection="column" overflow="auto">
      <TableContainer component={Box}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>File</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell />
              <TableCell align="right">Creation date (UTC Time)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {new Array(3).fill(3).map((_row, i) => (
              <TableRow key={`skeleton-row-${i}`}>
                <TableCell>
                  <IconButton size="small" disabled>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton width="14rem" />
                </TableCell>
                {new Array(4).fill(null).map((_field, j) => (
                  <TableCell key={`skeleton-field-${j}`} align="right">
                    <Box display="inline-block">
                      <Skeleton width="10rem" />
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function IssuanceTable({
  rows,
  count,
  page,
  pageSize,
  onPageChange,
  isWalletConnected,
  actions,
}: {
  rows: Issuance[];
  count: number;
  page: number;
  pageSize: number;
  onPageChange: (p: number) => void;
  isWalletConnected: boolean;
  actions: {
    handleSign: (issuanceFileId: number) => Promise<void>;
    handleIssue: () => Promise<void>;
    handleCheckIssuance: (issuanceFileId: number) => Promise<void>;
    handleDownload: (fileKey: string) => Promise<void>;
  };
}) {
  return (
    <Box display="flex" flexDirection="column" overflow="auto">
      <TableContainer component={Box}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>File</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell />
              <TableCell align="right">Creation date (UTC Time)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <IssuanceTableRow
                key={row.id}
                issuance={row}
                isWalletConnected={isWalletConnected}
                actions={actions}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[pageSize]}
        count={count}
        rowsPerPage={pageSize}
        page={page - 1}
        onPageChange={(_e, p) => onPageChange(p + 1)}
      />
    </Box>
  );
}

const mapIssuanceFileToIssuance = (
  issuanceFile: IssuanceService.IIssuanceFile,
): Issuance => ({
  ...issuanceFile,
  pageStatus: IssuancePageStatus.NONE,
  isSigned: false,
  errorMsg: '',
});

export default function IssuancePage(): React.ReactElement {
  const csvFileInputRef = useRef<HTMLInputElement | null>(null);
  const [issuanceType, setIssuanceType] = useState<IssuanceType>(
    IssuanceType.REGULAR,
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [csvFileName, setCsvFileName] = useState(FILE_NAME_PLACEHOLDER);

  const [issuances, setIssuances] = useState<Issuance[]>([]);
  const [numIssuances, setNumIssuances] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [issuanceCurrentState, setIssuanceCurrentState] = useState<
    IssuanceCurrentState | undefined
  >();

  const { sign, session, isConnected } = useSignerApp();
  const [signedTxns, setSignedTxns] = useState<
    { issuanceFileId: number; signedTxns: number[][] } | undefined
  >(undefined);

  const updateIssuance = (issuanceFileId: number, data: Partial<Issuance>) =>
    setIssuances(currentIssuances =>
      currentIssuances.map(issuance =>
        issuance.id === issuanceFileId ? { ...issuance, ...data } : issuance,
      ),
    );

  useEffect(() => {
    const runningIssuances = issuances.filter(
      i => i.status === IssuanceFileStatus.RUNNING,
    );

    const setIntervalIds = runningIssuances.map(i =>
      setInterval(async () => {
        const newIssuance = mapIssuanceFileToIssuance(
          await IssuanceService.adminGetIssuanceFile(i.id),
        );

        if (newIssuance.status !== IssuanceFileStatus.RUNNING) {
          updateIssuance(newIssuance.id, newIssuance);
        }
      }, 3000),
    );

    return () =>
      setIntervalIds.forEach(setIntervalId => clearInterval(setIntervalId));
  }, [issuances]);

  async function fetchIssuances() {
    setIsLoading(true);
    const { data, count } = await IssuanceService.adminGetIssuanceFiles(
      page,
      PAGE_SIZE,
    );
    const newIssuances = data.map(mapIssuanceFileToIssuance);
    setIssuances(newIssuances);
    setNumIssuances(count);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchIssuances();
  }, [page]);

  const setIssuanceIsSigned = (issuanceFileId?: number) =>
    setIssuances(currentIssuances =>
      currentIssuances.map(issuance => ({
        ...issuance,
        isSigned: issuance.id === issuanceFileId,
      })),
    );

  const setIssuanceErrorMsg = (issuanceFileId: number, errorMsg: string) =>
    setIssuances(currentIssuances =>
      currentIssuances.map(issuance => ({
        ...issuance,
        errorMsg: issuance.id === issuanceFileId ? errorMsg : issuance.errorMsg,
      })),
    );

  const setIssuancePageStatus = (
    issuanceFileId: number,
    pageStatus: IssuancePageStatus,
  ) =>
    setIssuances(currentIssuances =>
      currentIssuances.map(issuance =>
        issuance.id === issuanceFileId ? { ...issuance, pageStatus } : issuance,
      ),
    );

  useEffect(() => {
    setIssuanceIsSigned(signedTxns?.issuanceFileId);
  }, [signedTxns]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCsvFileName(e.target.files?.[0]?.name ?? FILE_NAME_PLACEHOLDER);
  };

  const handleFileClear = () => {
    if (csvFileInputRef.current) {
      csvFileInputRef.current.value = '';
      setCsvFileName(FILE_NAME_PLACEHOLDER);
    }
  };

  const handleCsvFileUpload = async () => {
    if (!csvFileInputRef.current) return;

    try {
      setIsUploading(true);

      const files = csvFileInputRef.current.files;
      const file = files?.[0];

      if (file) {
        const parseResult = await parseCsvFile(file);
        if (parseResult.errors.length) {
          const err = parseResult.errors[0];
          setErrorMessage(`${err.message} at row ${err.row + 1}`);
        } else {
          const validationErr = validateCsvFileColumns(parseResult.data);
          if (validationErr) {
            setErrorMessage(validationErr.message);
          } else {
            setErrorMessage('');

            await IssuanceService.adminUploadIssuanceCsvFile(
              issuanceType,
              csvFileName,
              Papa.unparse(parseResult.data, {
                delimiter: ';',
                skipEmptyLines: true,
              }),
            );

            csvFileInputRef.current.value = '';
            setCsvFileName(FILE_NAME_PLACEHOLDER);

            setPage(1);
            await fetchIssuances();
          }
        }
      } else {
        setErrorMessage('Please select an issuance CSV file');
      }
    } finally {
      setIsUploading(false);
    }
  };

  const handleSign = async (issuanceFileId: number) => {
    if (!session) return;

    try {
      setIssuanceErrorMsg(issuanceFileId, '');
      setIssuancePageStatus(issuanceFileId, IssuancePageStatus.SIGNING);
      const issuanceTxns = await IssuanceService.adminGenerateTransactions(
        session.wallet.address,
        issuanceFileId,
      );

      const adminTxns = issuanceTxns
        .map(txns => [
          Buffer.from(txns.fundRegularSmartSignatureTxn),
          Buffer.from(txns.transferAssetsToRegularSmartSignatureTxn),
        ])
        .flat();

      const sTxns = await sign(adminTxns);

      if (sTxns.length !== adminTxns.length) return;

      const txnsToSend = issuanceTxns
        .map(txns => {
          const otherSignedTxns = sTxns
            .splice(0, 2)
            .map(t => Array.from(new Uint8Array(t)));

          return [
            txns.regularSmartSignatureOptInToAssetSignedTxn,
            ...otherSignedTxns,
          ];
        })
        .flat();

      setSignedTxns({ issuanceFileId, signedTxns: txnsToSend });
    } catch (err) {
      const unkownErrorText =
        'An error has occured while trying to generate and sign the transactions';
      if (axios.isAxiosError(err)) {
        setIssuanceErrorMsg(
          issuanceFileId,
          err.response?.data?.errors[0]?.message || unkownErrorText,
        );
      } else {
        setIssuanceErrorMsg(issuanceFileId, unkownErrorText);
      }
    } finally {
      setIssuancePageStatus(issuanceFileId, IssuancePageStatus.NONE);
    }
  };

  const handleIssue = async () => {
    if (!signedTxns) return;

    try {
      setIssuanceErrorMsg(signedTxns.issuanceFileId, '');
      setIssuancePageStatus(
        signedTxns.issuanceFileId,
        IssuancePageStatus.ISSUING,
      );
      await IssuanceService.adminIssue(
        signedTxns.signedTxns,
        signedTxns.issuanceFileId,
      );
      updateIssuance(signedTxns.issuanceFileId, {
        status: IssuanceFileStatus.RUNNING,
      });
    } catch (err) {
      const unkownErrorText = 'An error has occured while trying to issue';
      if (axios.isAxiosError(err)) {
        setIssuanceErrorMsg(
          signedTxns.issuanceFileId,
          err.response?.data?.errors[0]?.message || unkownErrorText,
        );
      } else {
        setIssuanceErrorMsg(signedTxns.issuanceFileId, unkownErrorText);
      }
    } finally {
      setIssuancePageStatus(signedTxns.issuanceFileId, IssuancePageStatus.NONE);
      setSignedTxns(undefined);
    }
  };

  const handleCheckIssuance = async (issuanceFileId: number) => {
    try {
      setIssuanceErrorMsg(issuanceFileId, '');
      setIssuancePageStatus(issuanceFileId, IssuancePageStatus.CHECKING);
      const issuanceState = await IssuanceService.adminCheckIssuance(
        issuanceFileId,
      );
      setIssuanceCurrentState(issuanceState);
    } catch (err) {
      const unkownErrorText =
        'An error has occured while trying to check issuance';
      if (axios.isAxiosError(err)) {
        setIssuanceErrorMsg(
          issuanceFileId,
          err.response?.data?.errors[0]?.message || unkownErrorText,
        );
      } else {
        setIssuanceErrorMsg(issuanceFileId, unkownErrorText);
      }
    } finally {
      setIssuancePageStatus(issuanceFileId, IssuancePageStatus.NONE);
      setSignedTxns(undefined);
    }
  };

  const handleDownload = async (fileKey: string) => {
    const issuance = issuances.find(i => i.fileKey === fileKey);
    if (!issuance) return;

    try {
      setIssuancePageStatus(issuance.id, IssuancePageStatus.DOWNLOADING);
      const downloadUrl =
        await IssuanceService.adminGetIssuanceCsvFileDownloadUrl(fileKey);
      const el = document.createElement('a');
      el.setAttribute('target', '_blank');
      el.setAttribute('href', downloadUrl);
      el.click();
    } finally {
      setIssuancePageStatus(issuance.id, IssuancePageStatus.NONE);
    }
  };

  const handleSampleRegularCsvFileDownload = (
    e: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    const csv = Papa.unparse(
      [
        [
          '81004769',
          'MMAXZ2Z3ZVNTHG47J5EQ45Z2QBYNUQ7QZMVU4D4WOLX7BDW3AAAAAAAAAA',
          '1',
          '5EOG6JXSC5DG5GNI5CX5OUOOQ5W7HWF57FN5AGM2AAGDOCCTAAAAAAAAAA',
        ],
        [
          '81004770',
          'MMAXZ2Z3ZVNTHG47J5EQ45Z2QBYNUQ7QZMVU4D4WOLX7BDW3AAAAAAAAAA',
          '1',
          '5EOG6JXSC5DG5GNI5CX5OUOOQ5W7HWF57FN5AGM2AAGDOCCTAAAAAAAAAA',
        ],
        [
          '81004771',
          'MMAXZ2Z3ZVNTHG47J5EQ45Z2QBYNUQ7QZMVU4D4WOLX7BDW3AAAAAAAAAA',
          '1',
          '5EOG6JXSC5DG5GNI5CX5OUOOQ5W7HWF57FN5AGM2AAGDOCCTAAAAAAAAAA',
        ],
      ],
      {
        delimiter: ';',
        header: false,
        skipEmptyLines: true,
      },
    );
    const downloadUrl = URL.createObjectURL(
      new Blob([csv], { type: 'text/csv' }),
    );
    const el = document.createElement('a');
    el.setAttribute('target', '_blank');
    el.setAttribute('download', 'sample_regular_csv_file.csv');
    el.setAttribute('href', downloadUrl);
    el.click();
  };

  const handleSampleRoyaltyCsvFileDownload = (
    e: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    const csv = Papa.unparse(
      [
        [
          config.env.ALGORAND_USDC_ASSET_ID,
          'MMAXZ2Z3ZVNTHG47J5EQ45Z2QBYNUQ7QZMVU4D4WOLX7BDW3AAAAAAAAAA',
          '10',
          '5EOG6JXSC5DG5GNI5CX5OUOOQ5W7HWF57FN5AGM2AAGDOCCTAAAAAAAAAA',
        ],
        [
          config.env.ALGORAND_USDC_ASSET_ID,
          'AOVS46ZEIIXENRL3X3OKRT53UM5OP2NMDZMOLI2YCFLPIN2YAAAAAAAAAA',
          '10',
          '5EOG6JXSC5DG5GNI5CX5OUOOQ5W7HWF57FN5AGM2AAGDOCCTAAAAAAAAAA',
        ],
        [
          config.env.ALGORAND_USDC_ASSET_ID,
          '6KOSPXZZTVWZAXXUJYKHKGIDKEQHTAF33UF4SRNCLMEZWNKNAAAAAAAAAA',
          '10',
          '5EOG6JXSC5DG5GNI5CX5OUOOQ5W7HWF57FN5AGM2AAGDOCCTAAAAAAAAAA',
        ],
      ],
      {
        delimiter: ';',
        header: false,
        skipEmptyLines: true,
      },
    );
    const downloadUrl = URL.createObjectURL(
      new Blob([csv], { type: 'text/csv' }),
    );
    const el = document.createElement('a');
    el.setAttribute('target', '_blank');
    el.setAttribute('download', 'sample_royalty_csv_file.csv');
    el.setAttribute('href', downloadUrl);
    el.click();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingX={3}
      paddingBottom={4}
    >
      <Container>
        <div>
          <h1>Issuance</h1>
        </div>

        <Fieldset>
          <legend>Issuance CSV file upload</legend>
          <input
            style={{ display: 'none' }}
            ref={csvFileInputRef}
            type="file"
            accept="text/csv"
            onChange={handleFileChange}
          />

          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            padding={3}
            boxSizing="border-box"
          >
            <FormControl>
              <InputLabel>Issuance Type</InputLabel>
              <Select
                value={issuanceType}
                label="Issuance Type"
                onChange={e => setIssuanceType(e.target.value as IssuanceType)}
              >
                {Object.values(IssuanceType).map(type => (
                  <MenuItem key={type} value={type}>
                    {startCase(type)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              minWidth={0}
              overflow="hidden"
            >
              <Box
                paddingX={1}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {csvFileName}
              </Box>
              <Box display="flex" gap={1} flexWrap="wrap">
                <Button
                  size="small"
                  onClick={() => csvFileInputRef.current?.click()}
                >
                  Choose File
                </Button>
                <Button size="small" onClick={handleFileClear}>
                  Clear
                </Button>
              </Box>
            </Box>

            <Box minHeight="2rem">
              <ErrText>{errorMessage}</ErrText>
            </Box>

            <Box display="flex" flexDirection="column" gap=".4rem">
              <SampleFileLink
                href="#"
                onClick={handleSampleRegularCsvFileDownload}
              >
                Download sample Regular CSV file
              </SampleFileLink>
              <SampleFileLink
                href="#"
                onClick={handleSampleRoyaltyCsvFileDownload}
              >
                Download sample Royalty CSV file
              </SampleFileLink>
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                variant="contained"
                loading={isUploading}
                onClick={handleCsvFileUpload}
              >
                Upload
              </LoadingButton>
            </Box>
          </Box>
        </Fieldset>

        {isLoading ? (
          <SkeletonIssuanceTable />
        ) : (
          <IssuanceTable
            pageSize={PAGE_SIZE}
            page={page}
            count={numIssuances}
            rows={issuances}
            onPageChange={p => setPage(p)}
            isWalletConnected={isConnected}
            actions={{
              handleSign,
              handleIssue,
              handleDownload,
              handleCheckIssuance,
            }}
          />
        )}
      </Container>
      {issuanceCurrentState && (
        <IssuanceStateModal
          onClose={() => setIssuanceCurrentState(undefined)}
          issuanceState={issuanceCurrentState}
        />
      )}
    </Box>
  );
}
