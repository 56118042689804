import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const FieldGroup = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),

  '&:last-child': {
    marginBottom: theme.spacing(0),
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  marginBottom: theme.spacing(0.5),
}));

const Value = styled(Typography)(() => ({
  fontWeight: 700,
}));

interface IExtraInfoRow {
  label: string;
  value: string | number | undefined;
}

const ExtraInfoRow: React.FC<IExtraInfoRow> = ({ label, value }) => {
  return (
    <FieldGroup>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </FieldGroup>
  );
};

export default ExtraInfoRow;
