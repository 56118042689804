import Grid from '@mui/material/Grid'
import React from 'react'
import PoolGridCard from '../PoolGridCard'
import Skeletons from '@opulous/web/src/components/Skeleton'
import { PoolList } from '@opulous/web/src/shared/types'

const LoadingPoolList: React.FC = () => (
  <Grid container spacing={5} data-testid="Pool_PoolsList_Loading-component">
    <Skeletons />
    <Skeletons />
    <Skeletons />
  </Grid>
)

interface PoolsListProps {
  pools: PoolList[]
  isLoading?: boolean
}

const PoolsList: React.FC<PoolsListProps> = ({ isLoading, pools }) => {
  if (isLoading) {
    return <LoadingPoolList />
  }

  return (
    <Grid container spacing={3}>
      {pools.map((pool) => (
        <Grid
          key={pool.id}
          item
          xs={12}
          lg={6}
          xl={4}
          data-testid="Pool_PoolsList_Pool-item"
        >
          <PoolGridCard pool={pool} />
        </Grid>
      ))}
    </Grid>
  )
}

export default PoolsList
