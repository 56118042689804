// import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import axios from 'axios';
import config from './config';

export const getApiBaseUrl = (): string => {
  const envDomain = config.env.API_URL;
  const hostname = window.location.hostname

  if (hostname === 'localhost') {
    return 'http://localhost:4000'
  } else if (hostname === 'dev.abop.io') {
    return 'https://api-dev.abop.io'
  } else if (hostname === 'staging.abop.io') {
    return 'https://api-staging.abop.io'
  } else if (hostname === 'app.opulous.org') {
    return 'https://nft-api.opulous.org'
  }

  return envDomain
}

// const requestHandler = (request: AxiosRequestConfig) => {
//   // insert token here if needed
//   // request.headers.Authorization = 'Bearer <token>';
//   return request;
// };

// const responseHandler = (response: AxiosResponse) => {
//   return response;
// };

// const errorHandler = (error: AxiosError) => {
//   if (error && error.response?.status === 500) {
//     if (error.response.request?.responseURL.indexOf('https://api-dev.abop.io/assets/metadata') >= 0) {
//       // TODO this is very temporary because backend returns 500 for not found error
//       window.location.href = window.location.origin + '/not-found';
//     }
//   }
//   return Promise.reject(error);
// };

const init = () => {
  axios.defaults.baseURL = getApiBaseUrl()
  // axios.interceptors.request.use(
  //   (request) => requestHandler(request),
  //   (error) => errorHandler(error)
  // );

  // axios.interceptors.response.use(
  //   (response) => responseHandler(response),
  //   (error) => errorHandler(error)
  // );
}

init()
