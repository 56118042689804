import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/system';
import { useTheme } from '@mui/styles';
import { Grid, Theme } from '@mui/material';
import * as HighCharts from 'highcharts';
import useMediaQuery from '@mui/material/useMediaQuery';
import { InvestmentDetails } from '@opulous/web/src/shared/types';
import {
  getArtistInvestorShares,
  prepareCalculatorProperties,
} from '@opulous/web/src/utils';
import SimpleCalculatorForm from '@opulous/web/src/components/Sales/RewardsShareCalculator/SimpleCalculatorForm';

const CalculatorWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('xl')]: {
    marginLeft: 0,
  },
}));
const ChartWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  maxWidth: theme.spacing(50),
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('xl')]: {
    marginTop: theme.spacing(4),
  },
}));
const SupportersLabel = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: theme.spacing(29),
  top: 0,
  right: 0,
  zIndex: 2,
  textAlign: 'center',
  div: {
    marginTop: theme.spacing(4),
    '& > span': {
      color: theme.palette.text.dark,
    },
  },
}));
const YourShareLabel = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: theme.spacing(15),
  top: `calc(50% - ${theme.spacing(4)})`,
  left: `calc(50% - ${theme.spacing(18)})`,
  [theme.breakpoints.down('lg')]: {
    left: `calc(50% - ${theme.spacing(21)})`,
  },
  zIndex: 2,
  textAlign: 'center',
}));
const ArtistLabel = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: theme.spacing(29),
  bottom: 0,
  right: 0,
  zIndex: 2,
  textAlign: 'center',
  '& > span': {
    color: theme.palette.text.dark,
  },
  div: {
    marginTop: theme.spacing(4),
  },
}));
const ChartContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'right',
  marginRight: `-${theme.spacing(1.5)}`,
}));

type Props = {
  investment: InvestmentDetails;
};
export default function RewardsShareCalculator({
  investment,
}: Props): ReactElement {
  const chart = useRef(null);
  const artistShare = useMemo(
    () => getArtistInvestorShares(investment.rewardsShare),
    [],
  );
  const [yourShare, setYourShare] = useState<number>(0);
  const [investorsShare, setInvestorsShare] = useState<number>(
    investment.rewardsShare,
  );
  const [chartItems, setChartItems] = useState([investorsShare, artistShare]);
  const AppTheme: Theme = useTheme();
  const isMobile = useMediaQuery(AppTheme.breakpoints.down('md'));

  useEffect(() => {
    if (chart.current) {
      const config: HighCharts.Options = {
        chart: {
          renderTo: 'container',
          type: 'pie',
          backgroundColor: 'transparent',
          height: 250,
          width: 260,
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        colors: ['#dddfe7', '#f3f5f7', '#fd4b1e'],
        series: [
          {
            name: '',
            colorByPoint: true,
            data:
              chartItems?.map((item: any) => ({
                y: item,
              })) || [],
            dataLabels: {
              enabled: false,
            },
          },
        ],
        plotOptions: {
          pie: {
            size: 180,
            startAngle: 270 + yourShare * 3.8,
            innerSize: '35%',
            dataLabels: { distance: 0 },
            borderWidth: 0,
          },
        },
      } as HighCharts.Options;
      HighCharts.chart(chart.current, config);
    }
  }, [yourShare]);

  const handleChange = (event: CustomEvent) => {
    const { your, investors } = event.detail;
    setYourShare(your);
    setInvestorsShare(investors);
    if (your) {
      setChartItems([investors, artistShare, your]);
    } else {
      setChartItems([investors, artistShare]);
    }
  };

  return (
    <Grid
      data-testid="rewards-share-calculator"
      container
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Grid item xl={6} xs={12}>
        <CalculatorWrapper>
          <SimpleCalculatorForm
            calculatorProps={prepareCalculatorProperties(investment)}
            onInputChange={handleChange}
          />
        </CalculatorWrapper>
      </Grid>
      <Grid item xl={6} xs={12}>
        {!isMobile && (
          <ChartWrapper>
            <SupportersLabel>
              <span data-testid="rewards-share-calculator__chart__supporters__label">
                Supporters
              </span>
              <div>
                <span data-testid="rewards-share-calculator__chart__supporters__percent">
                  {chartItems[0]}%
                </span>
              </div>
            </SupportersLabel>
            <YourShareLabel>
              <span data-testid="rewards-share-calculator__chart__your__label">
                Your share
              </span>
              <div>
                <span data-testid="rewards-share-calculator__chart__your__percent">
                  {chartItems.length > 2 ? chartItems[2] : 0}%
                </span>
              </div>
            </YourShareLabel>
            <ChartContainer
              data-testid="rewards-share-calculator__chart"
              ref={chart}
            />
            <ArtistLabel>
              <span data-testid="rewards-share-calculator__chart__artist__percent">
                {chartItems[1]}%
              </span>
              <div>
                <span data-testid="rewards-share-calculator__chart__artist__label">
                  Artist
                </span>
              </div>
            </ArtistLabel>
          </ChartWrapper>
        )}
      </Grid>
    </Grid>
  );
}
