import {
  Action,
  InvestmentActionType,
} from '@opulous/web/src/pages/Sales/Investment/actions';
import { InvestmentContextState } from '@opulous/web/src/pages/Sales/Investment/context';
import { PurchaseSteps } from '@opulous/web/src/pages/Sales/Investment/types';

export const InvestmentInitializer = (
  initialValue: InvestmentContextState,
): InvestmentContextState => initialValue;

export const InvestmentReducer = (
  state: InvestmentContextState,
  action: Action<InvestmentActionType>,
): InvestmentContextState => {
  switch (action.type) {
    case InvestmentActionType.SetState:
      return {
        ...state,
        ...action.payload,
      };
    case InvestmentActionType.SetLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case InvestmentActionType.SetLoadingAvailability:
      return {
        ...state,
        loadingAvailability: action.payload,
      };
    case InvestmentActionType.SetProcessingPayment:
      return {
        ...state,
        processingPayment: action.payload.processingPayment,
        openCryptoPaymentIframe: action.payload.openCryptoPaymentIframe || false,
      };
    case InvestmentActionType.SetShowAlert:
      return {
        ...state,
        showAlert: action.payload,
      };
    case InvestmentActionType.LoadInvestment: {
      const { investment, ownedAsset, walletAddress } = action.payload;
      return {
        ...state,
        investment,
        ownedAsset,
        walletAddress,
      };
    }
    case InvestmentActionType.LoadInvestmentAvailability: {
      const { investmentAvailability } = action.payload;
      return {
        ...state,
        investmentAvailability,
      };
    }
    case InvestmentActionType.RenderEmailConfirmationStep: {
      return {
        ...state,
        purchaseFlow: {
          ...state.purchaseFlow,
          currentStep: PurchaseSteps.CONFIRMATION,
        },
      };
    }
    case InvestmentActionType.RenderPaymentMethodStep: {
      const { 
        purchaseAmount,
        email,
      } = action.payload;
      return {
        ...state,
        purchaseFlow: {
          ...state.purchaseFlow,
          currentStep: PurchaseSteps.PAYMENT_METHOD,
          purchaseAmount,
          email
        },
      };
    }
    case InvestmentActionType.RenderPurchaseConfirmationStep: {
      const { 
        paymentMethod,
      } = action.payload;
      return {
        ...state,
        purchaseFlow: {
          ...state.purchaseFlow,
          currentStep: PurchaseSteps.PURCHASE,
          paymentMethod,
        },
      };
    }
    case InvestmentActionType.SetPaymentIntent: {
      const { 
        paymentIntent,
      } = action.payload;
      return {
        ...state,
        paymentIntent,
      };
    }    
    case InvestmentActionType.RenderCongratulationStep: {
      return {
        ...state,
        purchaseFlow: {
          ...state.purchaseFlow,
          currentStep: PurchaseSteps.CONGRATULATION,
        },
      };
    }
    default:
      return state;
  }
};
