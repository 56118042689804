import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(2),
    width: 480,
    maxWidth: 480,
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: `${theme.spacing(1.75, 3)} !important`,
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(1.5)} !important`,
  },
}));

const DialogTitleText = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(2),
}));

const StyledContent = styled(DialogContent)(({ theme }) => ({
  padding: `${theme.spacing(3.5, 3)} !important`,
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(2.5, 1.5)} !important`,
  },
}));

const StyledConfirmButton = styled(Button)(({ theme }) => ({
  fontSize: theme.spacing(2.5),
  height: theme.spacing(6),
  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(2),
  },
}));

const CloseIconComponent = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(1.5),
}));

type ConfirmModalProps = {
  title: string;
  confirmLabel: string;
  onConfirm: () => void;
  onClose: () => void;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  children,
  title,
  confirmLabel,
  onConfirm,
  onClose,
}) => {
  return (
    <StyledDialog open={true} onClose={onClose} data-testid="confirm-modal">
      <StyledDialogTitle>
        <DialogTitleText data-testid="confirm-modal__title">
          {title}
        </DialogTitleText>
        <IconButton
          size="small"
          onClick={() => onClose()}
          data-testid="confirm-modal__close-button"
        >
          <CloseIconComponent fontSize="small" />
        </IconButton>
      </StyledDialogTitle>
      <StyledContent data-testid="confirm-modal__content">
        <Wrapper>
          {children}
          <StyledConfirmButton
            data-testid="confirm-modal__content__confirm-button"
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            {confirmLabel}
          </StyledConfirmButton>
        </Wrapper>
      </StyledContent>
    </StyledDialog>
  );
};

export default ConfirmModal;
