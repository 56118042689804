import React from 'react'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import AssetClaimCard from '../AssetClaimCard'
import { Asset } from '@opulous/web/src/shared/types'
import { formatWallet } from '@opulous/web/src/shared/helpers'

const Row = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2.75),
}))

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.75, 0, 0),
}))

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  marginBottom: theme.spacing(1),
  fontSize: theme.spacing(1.75),
}))

const AssetsContainer = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(1),
}))

interface IAssetContent {
  assets: Asset[]
  wallet: string
  loading: boolean
}

const loadingAsset = {} as Asset;

const AssetContent: React.FC<IAssetContent> = ({
  assets,
  wallet,
  loading = false,
}) => {
  return (
    <Content data-testid="assets-content">
      <Row>
        <Label data-testid="assets-content__wallet__title">Wallet address</Label>
        <Typography data-testid="assets-content__wallet__value">{formatWallet(wallet, 12, 12)}</Typography>
      </Row>

      <Row>
        <Label data-testid="assets-content__available__title">Available assets</Label>
        <AssetsContainer>
          {loading && <AssetClaimCard data-testid="assets-content__available__skeleton" loading asset={loadingAsset} />}
          {!loading && assets.map((asset) => (
            <AssetClaimCard data-testid="assets-content__available__claim-card" key={asset.id} asset={asset} />
          ))}
        </AssetsContainer>
      </Row>
    </Content>
  )
}

export default AssetContent
