import {
  ReactElement,
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import algosdk from 'algosdk';
import * as Sentry from '@sentry/react';
import { styled } from '@mui/system';
import { Box, Grid, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';

import PageHeader from '@opulous/web/src/components/PageHeader';
import VestingContractCard from '@opulous/web/src/components/Vesting/ContractCard';
import CardSkeleton from '@opulous/web/src/components/Vesting/CardSkeleton';

import WalletContext from '@opulous/web/src/context/context';
import * as VestingsService from '@opulous/web/src/services/vestings';
import {
  VestingContract,
  WithdrawTransactionsWithAppCallToSign,
} from '@opulous/web/src/shared/types';

import { AlertError } from '@opulous/web/src/components/shared';
import ClaimModal from '@opulous/web/src/components/Vesting/ContractCard/ClaimModal';
import MinorOPUL from '@opulous/web/src/shared/valueObjects/MinorOPUL';

import PeraInfoModal from '@opulous/web/src/components/Wallet/PeraInfoModal';
import { WalletTypeEnum } from '@opulous/web/src/shared/types';
import { isSignTransactionCancelledError } from '@opulous/web/src/utils';

const Wrapper = styled('div')(() => ({
  position: 'relative',
}));

const Container = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const StyledBox = styled(Box)(() => ({
  width: 'calc(100% - 24px) !important',
  margin: 0,
}));
const EmptyMessage = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(3),
}));

type VestingContractWithTransactions = {
  contract: VestingContract;
  optInTransaction?: algosdk.Transaction;
  withdrawTransactions?: WithdrawTransactionsWithAppCallToSign;
};

export default function VestingPage(): ReactElement {
  const {
    state: { wallet, connector, walletType },
  } = useContext(WalletContext);

  const [loading, setLoading] = useState(true);
  const [claimingTokens, setClaimingTokens] = useState<
    | {
        info?: string;
        error?: string;
        optIn?: boolean;
        amountAvailable?: MinorOPUL;
      }
    | undefined
  >();
  const [alertError, setAlertError] = useState<
    | {
        show: boolean;
        message: string;
      }
    | undefined
  >();
  const [
    vestingContractsWithTransactions,
    setVestingContractsWithTransactions,
  ] = useState<VestingContractWithTransactions[]>([]);
  const [peraDialogOpen, setPeraDialogState] = useState<boolean>(false);

  const loadVestingContracts = useCallback(async () => {
    try {
      const response = await VestingsService.getAllAvailableContracts(wallet);
      const contractsWithTransactions = await Promise.all(
        response.contracts.map(it =>
          (async () => {
            const contractWithTransactions: VestingContractWithTransactions = {
              contract: it,
            };
            if (!it.optedIn) {
              contractWithTransactions.optInTransaction =
                await VestingsService.makeOptInTransaction({
                  walletAddress: wallet,
                  assetId: it.assetId,
                });
            }
            if (it.toClaimOpul.isGreaterThan(0)) {
              contractWithTransactions.withdrawTransactions =
                await VestingsService.fetchWithdrawTransactionsWithAppCallToSign(
                  {
                    contractAddress: it.address,
                    walletAddress: wallet,
                  },
                );
            }
            return contractWithTransactions;
          })(),
        ),
      );
      setVestingContractsWithTransactions(contractsWithTransactions);
    } catch (error) {
      Sentry.captureException(error);
      setAlertError({
        show: true,
        message: 'An error occurred while trying to load Vesting Contracts',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    Sentry.addBreadcrumb({
      category: '<VestingPage /> : useEffect [loadVestingContracts]',
      message: 'Loading vesting contracts',
      type: 'info',
      data: {
        wallet,
        walletType,
        vestingContractsWithTransactions,
        claimingTokens,
        connector,
        isMobile,
      },
    });
    loadVestingContracts();
  }, [loadVestingContracts]);

  async function optInToOPUL(
    contractWithTransactions: VestingContractWithTransactions,
  ) {
    Sentry.addBreadcrumb({
      category: '<VestingPage /> : optInToOPUL',
      message: 'Call',
      type: 'info',
      data: {
        wallet,
        walletType,
        contractWithTransactions,
        claimingTokens,
        connector,
        isMobile,
      },
    });
    try {
      setClaimingTokens({
        info: 'Opting in to OPUL...',
        optIn: true,
      });
      try {
        if (!contractWithTransactions.optInTransaction) {
          throw new Error('Vesting contract with OptIn transaction empty');
        }
        if (walletType === WalletTypeEnum.peraWallet) {
          Sentry.addBreadcrumb({
            category: '<VestingPage /> : optInToOPUL',
            message: 'Displaying Pera mobile dialog',
            type: 'info',
            data: {
              wallet,
              walletType,
              contractWithTransactions,
              claimingTokens,
              connector,
              isMobile,
            },
          });
          setPeraDialogState(true);
        }
        await VestingsService.optInToOPUL({
          transaction: contractWithTransactions.optInTransaction,
          walletType,
          connector,
        });
        setClaimingTokens(undefined);
      } catch (error) {
        if (isSignTransactionCancelledError(error)) {
          setClaimingTokens(undefined);
          return;
        }

        setClaimingTokens({
          error: 'An error occurred while opting in to OPUL',
          optIn: true,
        });
        return;
      }

      setLoading(true);
      loadVestingContracts();
    } catch (error) {
      Sentry.captureException(error);
      setAlertError({
        show: true,
        message: 'An error occurred while trying to opt-in to OPUL',
      });
      setClaimingTokens(undefined);
    }
  }

  async function claimAvailableTokens(
    contractWithTransactions: VestingContractWithTransactions,
  ) {
    Sentry.addBreadcrumb({
      category: '<VestingPage /> : claimAvailableTokens',
      message: 'Call',
      type: 'info',
      data: {
        wallet,
        walletType,
        contractWithTransactions,
        claimingTokens,
        connector,
        isMobile,
      },
    });
    try {
      const { contract, withdrawTransactions } = contractWithTransactions;
      setClaimingTokens({
        info: 'Claiming available tokens...',
        amountAvailable: contract.toClaimOpul,
      });

      if (!withdrawTransactions) {
        throw new Error('Vesting contract with Withdraw transactions empty');
      }

      if (walletType === WalletTypeEnum.peraWallet) {
        Sentry.addBreadcrumb({
          category: '<VestingPage /> : claimAvailableTokens',
          message: 'Displaying pera wallet',
          type: 'info',
          data: {
            wallet,
            walletType,
            contractWithTransactions,
            claimingTokens,
            connector,
            isMobile,
          },
        });
        setPeraDialogState(true);
      }

      await VestingsService.withdrawAvaliableTokens({
        withdrawTransactionsWithAppCallToSign: withdrawTransactions,
        walletType,
        connector,
      });
      setClaimingTokens(undefined);

      setLoading(true);
      loadVestingContracts();
    } catch (error) {
      if (isSignTransactionCancelledError(error)) {
        setClaimingTokens(undefined);
        return;
      }

      Sentry.captureException(error);
      setAlertError({
        show: true,
        message: 'An error occurred while trying to claim tokens',
      });
      setClaimingTokens(undefined);
    }
  }

  const displayClaimingDialog =
    !(isMobile && peraDialogOpen) && claimingTokens;

  return (
    <Wrapper data-testid="vesting-page">
      {alertError && <AlertError message={alertError.message} />}
      <PageHeader title="My Vestings" />
      <Container container spacing={3}>
        <Grid item xl={8} lg={10} xs={12}>
          {loading && <CardSkeleton />}
          {!loading &&
            (vestingContractsWithTransactions.length ? (
              vestingContractsWithTransactions.map((it, index) => (
                <VestingContractCard
                  key={`vesting-card-${index}`}
                  {...{
                    ...it.contract,
                    optInTokens: () => optInToOPUL(it),
                    claimTokens: () => claimAvailableTokens(it),
                  }}
                />
              ))
            ) : (
              <StyledBox data-testid="vesting-page__empty-wrapper">
                <EmptyMessage
                  variant="body1"
                  gutterBottom
                  data-testid="vesting-page__empty-wrapper__message"
                >
                  You don't have any vesting contracts in your wallet.
                </EmptyMessage>
              </StyledBox>
            ))}
        </Grid>
        <PeraInfoModal
          open={peraDialogOpen}
          onClose={() => setPeraDialogState(false)}
        />
      </Container>
      {displayClaimingDialog && (
        <ClaimModal
          data-testid="vesting-page__claim-modal"
          {...{
            ...claimingTokens,
            cancel: () => setClaimingTokens(undefined),
          }}
        />
      )}
    </Wrapper>
  );
}
