import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, CircularProgress, IconButton, InputAdornment, Pagination, Skeleton, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import AdminAssetCard from 'src/components/Assets/AssetCard/AdminAssetCard';
import * as AssetService from 'src/services/assets';
import { Asset } from 'src/shared/types';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import Papa from 'papaparse';
import config from 'src/config';

const PAGE_SIZE = 30;

const Container = styled('div')(() => ({
  width: '100%',
  maxWidth: '80rem',
}));

const AssetGrid = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))',
  gap: '1rem',
}));

export function AssetSkeleton() {
  return (
    <Card>
      <Box position="relative" width="100%" paddingBottom="100%">
        <Box position="absolute" width="100%" height="100%">
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </Box>
      </Box>
      <Box padding={2}>
        <Skeleton width="100%" height="1.5rem" />
        <Skeleton width="60%" />
        <Box paddingBottom={4} />
      </Box>
    </Card>
  );
}

export default function AssetPage(): React.ReactElement {
  const navigate = useNavigate();
  const [assets, setAssets] = useState<Asset[]>([]);
  const [numAssets, setNumAssets] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [searchText, setSearchText] = useState('');
  const searchTextRef = useRef('');

  async function fetchAssets() {
    setIsLoading(true);
    const { data, count } = await AssetService.adminGetAssets(page, PAGE_SIZE, searchText);
    setAssets(data);
    setNumAssets(count);
    setIsLoading(false);
  }

  const handleAssetCreate = () => navigate('/admin/assets/create');
  const handleSearch = async (e: React.ChangeEvent|React.FormEvent) => {
    e.preventDefault();
    setPage(1);
    setSearchText(searchTextRef.current);
  };

  const handleAssetCsvExport = async () => {
    setIsExporting(true);
    const ts = new Date().toISOString().replace(/(\.\d+)Z$/g, '').replace(/[^\d]/g, '_');
    const { data } = await AssetService.adminGetAssets(1, Number.MAX_SAFE_INTEGER);
    const { origin } = new URL(window.location.href);
    const csv = Papa.unparse({
      data: data.map(x => [
        x.id,
        x.unitName,
        x.type,
        x.nftCount,
        Number.isInteger(x.lastInvestmentLinked?.id)
          ? `${origin}/mfts/${encodeURIComponent(x.lastInvestmentLinked?.id as number)}`
          : `${origin}/tokens/${encodeURIComponent(x.unitName)}`,
        `${config.env.ALGO_EXPLORER_URL}/asset/${encodeURIComponent(x.id)}`,
      ]),
      fields: [
        'asset_id',
        'unit_name',
        'type',
        'nft_count',
        'platform_url',
        'algo_explorer_url',
      ],
    }, {
      header: true,
      delimiter: ';',
      skipEmptyLines: true,
    });
    setIsExporting(false);

    const downloadUrl = URL.createObjectURL(
      new Blob([csv], { type: 'text/csv' }),
    );
    const el = document.createElement('a');
    el.setAttribute('target', '_blank');
    el.setAttribute('download', `all_assets_${ts}.csv`);
    el.setAttribute('href', downloadUrl);
    el.click();
  };

  useEffect(() => {
    fetchAssets();
  }, [page, searchText]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={3}>
      <Container>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
          <h1>Assets</h1>
          <Button disabled={isExporting} variant="outlined" size="small" onClick={handleAssetCsvExport}>{
            isExporting
              ? (<CircularProgress size="1.5rem" />)
              : (<>
                <Box display="inline-block" width="1rem" height="1rem" marginRight={1}>
                  <DownloadIcon sx={{ width: '100%', height: '100%' }} />
                </Box>
                <span>Export all assets</span>
              </>)
          }</Button>
        </Box>
        <Box display="flex" justifyContent="space-between" gap={1} paddingY={2}>
          <form onSubmit={handleSearch}>
            <TextField
              variant="outlined"
              placeholder="Search"
              onChange={e => Object.assign(searchTextRef, { current: e.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={handleSearch}
                      disabled={isLoading}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
          <Button onClick={handleAssetCreate}>Create</Button>
        </Box>
        <div>
          <AssetGrid>
            {isLoading && (new Array(3).fill(null).map((_, i) => (
              <AssetSkeleton key={`skeleton-${i}`} />
            )))}
            {!isLoading && assets.map(asset => (
              <AdminAssetCard
                key={asset.id}
                assetId={asset.id}
                unitName={asset.unitName}
                title={asset?.custom?.title || asset.unitName}
                image={asset.image}
                subTitle={asset.custom?.artist}
                type={asset.type}
                nftCount={asset.nftCount}
                hasMetadataError={asset.name === undefined}
                investment={asset.lastInvestmentLinked}
                onAssetRefresh={fetchAssets}
              />
            ))}
          </AssetGrid>
        </div>
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Pagination
            count={Math.ceil(numAssets / PAGE_SIZE)}
            color="primary"
            onChange={(_e, p) => setPage(p)}
            page={page}
          />
        </Box>
      </Container>
    </Box>
  );
}
