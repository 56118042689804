export enum events {
  CONNECT_ERROR = 'connect_error',
  CONNECT_RESPONSE = 'connect_response',
  DISCONNECT = 'disconnect',
  SIGN_REQUEST = 'sign_request',
  GET_SESSION_REQUEST = 'get_session_request',
}

export interface Session {
  wallet: {
    type: 'Ledger' | 'Mnemonic';
    address: string;
  };
  sender: {
    type: 'Wallet' | 'Multisig';
    address: string;
  };
}

export interface ClientToServerEvents {
  [events.SIGN_REQUEST]: (
    txns: Buffer[],
    cb: (signedTxns: Buffer[]) => void,
  ) => void;
  [events.GET_SESSION_REQUEST]: (cb: (session: Session) => void) => void;
}

export interface ServerToClientEvents {
  [events.CONNECT_RESPONSE]: (session: Session) => void;
  [events.CONNECT_ERROR]: () => void;
  [events.DISCONNECT]: () => void;
}
