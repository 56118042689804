import { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import OPULLabel from '@opulous/web/src/components/shared/OPULLabel';
import MinorOPUL from '@opulous/web/src/shared/valueObjects/MinorOPUL';

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontWeight: 500,
  fontSize: theme.spacing(2.2),
  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(1.8),
  },
}));

const Value = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 800,
  marginTop: theme.spacing(0),
  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(2.6),
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: theme.spacing(3),
  },
}));

type Props = {
  totalOpul: MinorOPUL;
  lockedOpul: MinorOPUL;
  claimedOpul: MinorOPUL;
  dailyDistribution: MinorOPUL;
};
export default function Details({
  totalOpul,
  lockedOpul,
  claimedOpul,
  dailyDistribution,
}: Props): ReactElement {
  return (
    <StyledGrid container spacing={3} data-testid="vesting-details">
      <Grid item lg={3} xs={6}>
        <Label variant="h5" data-testid="vesting-details__total-label">
          Total
        </Label>
        <Value data-testid="vesting-details__total-value">
          <OPULLabel value={totalOpul}></OPULLabel>
        </Value>
      </Grid>
      <Grid item lg={3} xs={6}>
        <Label variant="h5" data-testid="vesting-details__locked-label">
          Locked
        </Label>
        <Value data-testid="vesting-details__locked-value">
          <OPULLabel value={lockedOpul}></OPULLabel>
        </Value>
      </Grid>
      <Grid item lg={3} xs={6}>
        <Label variant="h5" data-testid="vesting-details__claimed-label">
          Claimed
        </Label>
        <Value data-testid="vesting-details__claimed-value">
          <OPULLabel value={claimedOpul}></OPULLabel>
        </Value>
      </Grid>
      <Grid item lg={3} xs={6}>
        <Label
          variant="h5"
          data-testid="vesting-details__daily-distribution-label"
        >
          Daily <small>distribution</small>
        </Label>
        <Value data-testid="vesting-details__daily-distribution-value">
          <OPULLabel value={dailyDistribution}></OPULLabel>
        </Value>
      </Grid>
    </StyledGrid>
  );
}
