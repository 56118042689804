import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/system';
import * as Yup from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Link as _Link, useNavigate, useParams } from 'react-router-dom';
import * as AssetService from 'src/services/assets';
import { AssetType, ICreateAsset, IAssetField } from 'src/shared/types';
import { validateField } from 'src/utils';
import * as Sentry from '@sentry/react';
import axios from 'axios';

interface IValidationErrors {
  assetId: string
  type: string
  nftCount: string
  percentCopyright: string
  securitizeTokenId: string
  parentId: string
  upc: string
  releaseTitle: string
  artistName: string
  royaltyCopyrightShare: string


  customDescription: string
  customFields: string
  customContent: string
  customSidebar: string
}

interface IDirtyLookup {
  assetId: boolean
  type: boolean
  nftCount: boolean
  percentCopyright: boolean
  securitizeTokenId: boolean
  parentId: boolean
  upc: boolean
  releaseTitle: boolean
  artistName: boolean
  royaltyCopyrightShare: boolean

  customDescription: boolean
  customFields: boolean
  customContent: boolean
  customSidebar: boolean
}

const ErrorMessage = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `2px solid ${theme.palette.error.main}`,
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.error.light,
  padding: theme.spacing(2),
  color: theme.palette.error.main,
  gap: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));

const Link = styled(_Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  gap: theme.spacing(1),

  '& > svg': {
    width: '1rem',
    height: '1rem',
  },
}));

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
  maxWidth: '50rem',
}));

const Fieldset = styled('fieldset')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  color: theme.palette.text.primary,
  display: 'block',
  position: 'relative',
  minWidth: 0,

  '&:hover': {
    border: `1px solid ${theme.palette.common.black}`,
  },

  '&.error': {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },

  '& > legend': {
    fontSize: '.75rem',
    fontWeight: '300',
  },
}));

const ErrText = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: '700',
  fontSize: '.9rem',
}));

const WarningBanner = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.warning.light,
  color: theme.palette.warning.main,
  fontWeight: '500',
  borderRadius: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  '& > svg': {
    width: '1.5rem',
    height: '1.5rem',
  },
}));

function checkIsValidJsonString(x: string): boolean {
  try { JSON.parse(x); return true; } catch { return false; }
}

const NULLABLE_FIELDS = new Set([
  'securitizeTokenId',
  'parentId',
  'upc',
  'releaseTitle',
  'artistName',
  'royaltyCopyrightShare',
]);

const fieldValidators = {
  assetId: Yup.number().min(1).max(Math.pow(2, 31) - 1)
    .typeError('Number expected')
    .required('This field is required'),
  type: Yup.mixed().oneOf(['mft', 'artwork'])
    .required('This field is required'),
  nftCount: Yup.number().min(1)
    .typeError('Number expected')
    .required('This field is required'),
  percentCopyright: Yup.number()
    .typeError('Number expected')
    .required('This field is required'),
  securitizeTokenId: Yup.string().uuid(),
  parentId: Yup.number().min(1).typeError('Number expected'),
  upc: Yup.string(),
  releaseTitle: Yup.string(),
  artistName: Yup.string(),
  royaltyCopyrightShare: Yup.number().typeError('Number expected'),

  customDescription: Yup.string(),
  customFields: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required('Empty label ,value or identifier are not allowed'),
      value: Yup.string().required('Empty label ,value or identifier are not allowed'),
      identifier: Yup.string().required('Empty label ,value or identifier are not allowed'),
    }).required()
  ).test(
    'Duplicated labels',
    'Duplicated labels',
    xs => !xs || new Set(xs.map(x => x.label)).size === xs.length,
  ),
  customContent: Yup.string().test(
    'Invalid JSON',
    'Invalid JSON',
    x => !x || checkIsValidJsonString(x),
  ),
  customSidebar: Yup.string().test(
    'Invalid JSON',
    'Invalid JSON',
    x => !x || checkIsValidJsonString(x),
  ),
};

export function FieldRow({
  label,
  value,
  identifier,
  onLabelChange,
  onValueChange,
  onIdentifierChange,
  onPairDelete,
}: {
  label: string
  value: string
  identifier: string
  onLabelChange: (s: string) => void
  onValueChange: (s: string) => void
  onIdentifierChange: (s: string) => void
  onPairDelete: () => void
}) {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gap={2}
      paddingX={2}
      paddingY={1}
    >
      <Box display="flex" flex={1} minWidth="10rem">
        <TextField
          label="Label"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={label}
          onChange={e => onLabelChange(e.target.value)}
        />
      </Box>

      <Box display="flex" flex={1} minWidth="10rem">
        <TextField
          label="Value"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={value}
          onChange={e => onValueChange(e.target.value)}
        />
      </Box>

      <Box display="flex" flex={1} minWidth="10rem">
        <TextField
          label="Identifier"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={identifier}
          onChange={e => onIdentifierChange(e.target.value)}
        />
      </Box>

      <Box display="flex" alignItems="center">
        <IconButton color="primary" size="small" onClick={onPairDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default function AssetEdit(): React.ReactElement {
  const { unitName } = useParams();
  const navigate = useNavigate();
  const dirtyLookup = useRef<Partial<IDirtyLookup>>({}).current;

  const [assetId, setAssetId] = useState('');
  const [type, setType] = useState<AssetType>(AssetType.artwork);
  const [nftCount, setNftCount] = useState('');
  const [percentCopyright, setPercentCopyright] = useState('');
  const [securitizeTokenId, setSecuritizeTokenId] = useState('');
  const [parentId, setParentId] = useState('');
  const [upc, setUpc] = useState('');
  const [releaseTitle, setReleaseTitle] = useState('');
  const [artistName, setArtistName] = useState('');
  const [royaltyCopyrightShare, setRoyaltyCopyrightShare] = useState('');
  const [customDescription, setCustomDescription] = useState('');
  const [customFields, setCustomFields] = useState<IAssetField[]>([]);
  const [customContent, setCustomContent] = useState('');
  const [customSidebar, setCustomSidebar] = useState('');
  const [validationErrors, setValidationErrors] = useState<Partial<IValidationErrors>>({});
  const [hasMetadataError, setHasMetadataError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (!unitName) {
        navigate('/admin/assets/create');
        return;
      }

      setIsLoading(true);
      const r = await AssetService.adminGetAsset(unitName);
      if (!r) {
        navigate('/admin/assets/create');
        return;
      }

      setAssetId(r.id.toString());
      setType(r.type);
      setNftCount(r.nftCount.toString());
      setPercentCopyright(r.percentCopyright.toString());
      setSecuritizeTokenId(r.securitizeTokenId ?? '');
      setParentId(r.parentId?.toString() ?? '');
      setUpc(r.upc ?? '');
      setReleaseTitle(r.releaseTitle ?? '');
      setArtistName(r.custom?.artist ?? r.artistName ?? '');
      setRoyaltyCopyrightShare(r.royaltyCopyrightShare?.toString() ?? '');
      setCustomDescription(r.custom?.description ?? '');
      setCustomFields(r.custom?.fields ?? []);

      // TODO: Show proper input boxes for content and sidebar for better UX
      setCustomContent(r.custom?.content ? JSON.stringify(r.custom.content, null, 2) : '');
      setCustomSidebar(r.custom?.sidebar ? JSON.stringify(r.custom.sidebar, null, 2) : '');

      setHasMetadataError(r.name === undefined);

      setIsLoading(false);
    })();
  }, []);

  const markDirty = (name: keyof typeof dirtyLookup) => {
    dirtyLookup[name] = true;
    setValidationErrors(errs => { delete errs[name]; return { ...errs }; });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errs: Partial<IValidationErrors> = {};

    for (const [name, value] of ([
      ['assetId', assetId],
      ['type', type],
      ['nftCount', nftCount],
      ['percentCopyright', percentCopyright],
      ['securitizeTokenId', securitizeTokenId],
      ['parentId', parentId],
      ['upc', upc],
      ['releaseTitle', releaseTitle],
      ['artistName', artistName],
      ['royaltyCopyrightShare', royaltyCopyrightShare],
      ['customDescription', customDescription],
      ['customFields', customFields],
      ['customContent', customContent],
      ['customSidebar', customSidebar],
    ] as [keyof typeof fieldValidators, any][])) {
      if (!dirtyLookup[name]) continue;
      if (NULLABLE_FIELDS.has(name) && !value) continue;
      const msg = await validateField(fieldValidators[name], value);
      if (msg) {
        errs[name] = msg;
      }
    }

    setValidationErrors(errs);

    if (Object.keys(errs).length) return;

    try {
      setIsSubmitting(true);
      if (Object.values(dirtyLookup).filter(x => x).length) {
        const data: Partial<ICreateAsset> = {};

        dirtyLookup.assetId && Object.assign(data, { id: parseInt(assetId) });
        dirtyLookup.type && Object.assign(data, { type });
        dirtyLookup.nftCount && Object.assign(data, { nftCount: parseInt(nftCount) });
        dirtyLookup.percentCopyright && Object.assign(data, { percentCopyright: parseFloat(percentCopyright) });
        dirtyLookup.securitizeTokenId && Object.assign(data, { securitizeTokenId: securitizeTokenId || null });
        dirtyLookup.parentId && Object.assign(data, { parentId: parentId ? parseInt(parentId) : null });
        dirtyLookup.upc && Object.assign(data, { upc: upc || null });
        dirtyLookup.releaseTitle && Object.assign(data, { releaseTitle: releaseTitle || null });
        dirtyLookup.artistName && Object.assign(data, { artistName: artistName || null });
        dirtyLookup.royaltyCopyrightShare && Object.assign(data, {
          royaltyCopyrightShare: royaltyCopyrightShare
            ? parseFloat(royaltyCopyrightShare)
            : null
        });
        (
          dirtyLookup.releaseTitle ||
          dirtyLookup.customDescription ||
          dirtyLookup.artistName ||
          dirtyLookup.customFields ||
          dirtyLookup.customContent ||
          dirtyLookup.customSidebar
        ) && Object.assign(data, {
          custom: {
            fields: customFields,
            ...(releaseTitle ? { title: releaseTitle } : null),
            ...(customDescription ? { description: customDescription } : null),
            ...(artistName ? { artist: artistName } : null),
            ...(customContent ? { content: JSON.parse(customContent) } : null),
            ...(customSidebar ? { sidebar: JSON.parse(customSidebar) } : null),
          },
        });

        await AssetService.adminEditAsset(unitName as string, data);
      }

      navigate('/admin/assets');
      setIsSubmitting(false);
    } catch (err) {
      if (
        axios.isAxiosError(err) &&
        err.response?.status === 400 &&
        err.response?.data?.errors?.length &&
        err.response?.data?.errors?.[0]?.message
      ) {
        setSubmissionErrorMessage(err.response?.data?.errors?.[0]?.message);
      } else {
        setSubmissionErrorMessage('Unexpected error occurred, please try again later.');
      }
      Sentry.captureException(err);
      setIsSubmitting(false);
    }
  }

  const handleFieldRowAdd = () => {
    markDirty('customFields');
    setCustomFields(xs => [...xs, { label: '', value: '', identifier: '' }]);
  };
  const handleFieldRowDelete = (n: number) => () => {
    markDirty('customFields');
    setCustomFields(xs => xs.filter((_, i) => i !== n));
  };
  const handleFieldLabelChange = (n: number) => (s: string) => {
    markDirty('customFields');
    setCustomFields(xs => xs.map((x, i) =>
      i === n ? { ...x, label: s } : x
    ));
  };
  const handleFieldValueChange = (n: number) => (s: string) => {
    markDirty('customFields');
    setCustomFields(xs => xs.map((x, i) =>
      i === n ? { ...x, value: s } : x
    ));
  };
  const handleIdentifierChange = (n: number) => (s: string) => {
    markDirty('customFields');
    setCustomFields(xs => xs.map((x, i) =>
      i === n ? { ...x, identifier: s } : x
    ));
  };

  if (isLoading) {
    return (
      <Box marginY={4} display="flex" flexDirection="column" gap={4} alignItems="center">
        <CircularProgress size="3rem" color="primary" />
        <div>Retrieving asset data...</div>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={3}>
      <Form onSubmit={handleSubmit}>
        <div>
          <Link to="/admin/assets">
            <ArrowBackIcon />
            <span>Back</span>
          </Link>
        </div>
        <div>
          <h1>Edit Asset</h1>
        </div>

        {hasMetadataError && (
          <Box paddingBottom={1}>
            <WarningBanner>
              <WarningIcon />
              <span>The asset metadata is missing. You probably misconfigured the asset or forgot to upload the metadata file `{unitName}.json` to the server.</span>
            </WarningBanner>
          </Box>
        )}

        <TextField
          label="ID"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.assetId}
          helperText={validationErrors.assetId}
          value={assetId}
          onChange={e => {
            markDirty('assetId');
            setAssetId(e.target.value);
          }}
        />
        <TextField
          label="Unit Name"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={unitName}
          disabled={true}
        />
        <FormControl>
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            label="Type"
            onChange={e => {
              markDirty('type');
              setType(e.target.value as AssetType);
            }}
          >
            <MenuItem value={AssetType.artwork}>{AssetType.artwork}</MenuItem>
            <MenuItem value={AssetType.mft}>{AssetType.mft}</MenuItem>
            <MenuItem value={AssetType.ovault}>{AssetType.ovault}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Title (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.releaseTitle}
          helperText={validationErrors.releaseTitle}
          value={releaseTitle}
          onChange={e => {
            markDirty('releaseTitle');
            setReleaseTitle(e.target.value)
          }}
        />
        <TextField
          label="Artist (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.artistName}
          helperText={validationErrors.artistName}
          value={artistName}
          onChange={e => {
            markDirty('artistName');
            setArtistName(e.target.value)
          }}
        />
        <TextField
          label="Description (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.customDescription}
          helperText={validationErrors.customDescription}
          rows={8}
          multiline
          value={customDescription}
          onChange={e => {
            markDirty('customDescription');
            setCustomDescription(e.target.value)
          }}
        />
        <Fieldset>
          <legend>Fields</legend>
          <Box paddingY={1}>
            {customFields.map((r, i) => (
              <FieldRow
                key={`field-row-${i}`}
                label={r.label}
                value={r.value}
                identifier={r.identifier}
                onLabelChange={handleFieldLabelChange(i)}
                onValueChange={handleFieldValueChange(i)}
                onIdentifierChange={handleIdentifierChange(i)}
                onPairDelete={handleFieldRowDelete(i)}
              />
            ))}
          </Box>
          {validationErrors.customFields && (
            <Box paddingX={2} paddingY={1}>
              <ErrText>{validationErrors.customFields}</ErrText>
            </Box>
          )}
          <Box paddingX={2} paddingY={1}>
            <Button color="primary" size="small" onClick={handleFieldRowAdd}>
              <AddIcon />
              <Box paddingLeft={1}>Add Field</Box>
            </Button>
          </Box>
        </Fieldset>
        <TextField
          label="Content (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.customContent}
          helperText={validationErrors.customContent}
          rows={8}
          multiline
          value={customContent}
          onChange={e => {
            markDirty('customContent');
            setCustomContent(e.target.value)
          }}
        />
        <TextField
          label="Sidebar (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.customSidebar}
          helperText={validationErrors.customSidebar}
          rows={8}
          multiline
          value={customSidebar}
          onChange={e => {
            markDirty('customSidebar');
            setCustomSidebar(e.target.value)
          }}
        />
        <TextField
          label="Number of NFT"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.nftCount}
          helperText={validationErrors.nftCount}
          value={nftCount}
          onChange={e => {
            markDirty('nftCount');
            setNftCount(e.target.value)
          }}
        />
        <TextField
          label="Copyright %"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.percentCopyright}
          helperText={validationErrors.percentCopyright}
          value={percentCopyright}
          onChange={e => {
            markDirty('percentCopyright');
            setPercentCopyright(e.target.value)
          }}
        />
        <TextField
          label="Parent ID (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.parentId}
          helperText={validationErrors.parentId}
          value={parentId}
          onChange={e => {
            markDirty('parentId');
            setParentId(e.target.value)
          }}
        />
        <TextField
          label="UPC (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.upc}
          helperText={validationErrors.upc}
          value={upc}
          onChange={e => {
            markDirty('upc');
            setUpc(e.target.value)
          }}
        />
        <TextField
          label="Securitize Token ID (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.securitizeTokenId}
          helperText={validationErrors.securitizeTokenId}
          value={securitizeTokenId}
          onChange={e => {
            markDirty('securitizeTokenId');
            setSecuritizeTokenId(e.target.value);
          }}
        />
        <TextField
          label="Royalty Copyright % (Optional)"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!!validationErrors.royaltyCopyrightShare}
          helperText={validationErrors.royaltyCopyrightShare}
          value={royaltyCopyrightShare}
          onChange={e => {
            markDirty('royaltyCopyrightShare');
            setRoyaltyCopyrightShare(e.target.value)
          }}
        />

        {!!submissionErrorMessage && (
          <ErrorMessage>
            <ErrorIcon />
            <Box flex="1">{submissionErrorMessage}</Box>
          </ErrorMessage>
        )}

        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (<CircularProgress size="1.5rem" color="primary" />) : 'Submit'}
        </Button>
      </Form>
    </Box>
  );
}
