import React, { useContext } from 'react';
import { styled } from '@mui/system';
import ErrorIcon from '@mui/icons-material/Error';
import { Box } from '@mui/material';
import WalletContext from '@opulous/web/src/context/context';
import { WalletTypeEnum } from 'src/shared/types';

const Popup = styled(({
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { type: 'success'|'error'|'info' }) => (
  <div {...props}>{children}</div>
))(({ theme, type }) => ({
  backgroundColor: theme.palette[type]?.light,
  color: theme.palette[type]?.dark,
  display: 'flex',
  padding: theme.spacing(1),
  lineHeight: '1rem',
  fontWeight: '600',
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(2),
}));

const Link = styled('a')(({ theme }) => ({
  display: 'block',
  color: theme.palette.info.main,
  fontWeight: 600,
  textDecoration: 'none',
}));

export default function MyAlgoWarningBanner() {
  const {
    state: { wallet, walletType },
  } = useContext(WalletContext);

  if (!wallet || walletType !== WalletTypeEnum.myAlgoWallet) return null;

  return (
    <Box paddingTop={3}>
      <Popup type="error">
        <Box display="flex" alignItems="center" paddingLeft={2}>
          <ErrorIcon />
        </Box>

        <Box display="flex" alignItems="center" padding={2} flex="1">
          <Box display="flex" flexDirection="column" gap={1}>
            <div>MyAlgoWallet has reported a security issue. Please migrate to Pera Wallet as soon as possible.</div>
            <Box display="flex" gap={2} flexWrap="wrap">
              <Link target="_blank" href="https://twitter.com/myalgo_/status/1630185695791706120">Report</Link>
              <Link target="_blank" href="https://support.perawallet.app/en/article/how-to-rekey-an-algorand-account-with-pera-web-wallet-9alza3/">Migrate to Pera Wallet</Link>
            </Box>
          </Box>
        </Box>
      </Popup>
    </Box>
  );
}
