import { ReactElement } from 'react'
import { styled } from '@mui/system'
import Grid from '@mui/material/Grid'
import TotalStreams from '../../../Royalties/TotalStreams'

const GridContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 0),
  [theme.breakpoints.down('md')]: {
    maxWidth: 'calc(100vw - 50px)',
  },
}));

const RoyaltiesTab = (): ReactElement => {
  return (
    <GridContainer>
      <Grid container spacing={3}>
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
          {/* <ExtraInfo loading={false} lastMonth={0} earning={0} balance={0} /> */}
        </Grid>
        <Grid item xl={8} lg={6} md={12} sm={12} xs={12}>
          {/* <TotalEarning {...totalEarningChartProps} /> */}
        </Grid>
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
          {/* <BestPerformingCountries {...bestPerformingCountriesProps} /> */}
        </Grid>
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
          {/* <BestPerformingStores {...bestPerformingStoresProps} /> */}
        </Grid>
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
          <TotalStreams />
        </Grid>
        <Grid item xl={12} lg={6} md={12} sm={12} xs={12}>
          {/* <FullOverview {...fullOverviewProps} /> */}
        </Grid>
      </Grid>
    </GridContainer>
  );
};

export default RoyaltiesTab;
