import * as React from 'react';

const WithdrawnIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11 6h2v8l3.5-3.5 1.42 1.42L12 17.84l-5.92-5.92L7.5 10.5 11 14V6Zm1 16a10 10 0 1 1 0-20.002A10 10 0 0 1 12 22Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20Z"
    />
  </svg>
)

export default WithdrawnIcon;
