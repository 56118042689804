import { ReactElement, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Paper, Skeleton, Typography } from '@mui/material';
import * as HighCharts from 'highcharts';

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: theme.spacing(45),
  padding: theme.spacing(3, 3),
  border: `1px solid ${theme.palette.grey[100]}`,
  marginBottom: theme.spacing(3),
  borderRadius: 12,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}));

const NoDataLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 'bold',
  textAlign: 'center',
}));

type Props = {
  loading: boolean;
  title: string;
  series: { name: string; color: string; data: number[] }[];
  categories: string[];
};
export default function AnalyticsChart({
  loading,
  title,
  series,
  categories,
}: Props): ReactElement {
  const chartRef = useRef(null);
  const config: HighCharts.Options = {
    chart: {
      renderTo: 'container',
      type: 'line',
      backgroundColor: 'transparent',
      height: 300,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    series: series.map(it => ({
      name: it.name,
      type: 'line',
      data: it.data,
      color: it.color,
      lineWidth: 1,
      marker: {
        enabled: false,
      },
    })),
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      useHTML: true,
      labelFormatter: function () {
        switch (this.name) {
          case 'Series 1':
            return (
              this.name +
              '<br/><br/><span style="font-size: 17px;">Device 2 legend:</span>'
            );
          case 'Series 2':
            return this.name;
          default:
            return this.name;
        }
      },
      title: {
        text: `<span style="font-size: 17px"><small>Top 10</small><br />${title}</span><br/>`,
      },
    },
    xAxis: {
      opposite: true,
      categories: categories,
      gridLineWidth: 1,
      tickWidth: 1,
      tickmarkPlacement: 'on',
      endOnTick: true,
      startOnTick: true,
      minPadding: 0.01,
      maxPadding: 0.01,
      minorTicks: true,
      minorTickWidth: 10,
      minorTickInterval: 'auto',
      minorTickLength: 2,
      labels: {
        formatter: (context: { value: string | number }): string => {
          return HighCharts.dateFormat(
            '%d %b',
            new Date(context.value).getTime(),
          );
        },
        style: {
          color: '#14181A',
        },
      },
    },
    yAxis: {
      grid: {
        borderWidth: 1,
        enabled: true,
      },
      title: undefined,
      labels: {
        style: {
          color: '#212121',
          fontWeight: 'bold',
        },
      },
    },
    tooltip: {
      shadow: false,
      borderWidth: 0,
      padding: -10,
      backgroundColor: 'transparent',
      useHTML: true,
      formatter() {
        return `
          <div style="padding: 1rem; border-radius: 0.6rem; background-color: ${
            this.color
          }">
            <div style="color: white; margin-bottom: 10px">${HighCharts.dateFormat(
              '%A %d %b %Y',
              new Date(this.x).getTime(),
            )}</div>
            <div style="display: flex">
              <div>
                <div style="color: #000005;">Total streams (est)</div>
                <div style="color: white">${this.y}</div>
              </div>
            </div>
          </div>
        `;
      },
    },
    point: {
      marker: {
        enabled: false,
      },
    },
    plotOptions: {
      area: {
        point: {
          marker: {
            enabled: false,
          },
        },
      },
    },
  } as HighCharts.Options;

  useEffect(() => {
    if (chartRef.current) {
      HighCharts.chart(chartRef.current, config);
    }
  }, [loading]);

  return (
    <StyledPaper data-testid="analytics-chart">
      {loading ? (
        <Skeleton
          data-testid="analytics-chart__loading"
          variant="rectangular"
          height={300}
        ></Skeleton>
      ) : categories.length ? (
        <div
          style={{ width: '100%' }}
          data-testid="analytics-chart__chart"
          ref={chartRef}
        />
      ) : (
        <NoDataLabel data-testid="analytics-chart__no-data-label">
          No data to display
        </NoDataLabel>
      )}
    </StyledPaper>
  );
}
