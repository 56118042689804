import { ReactElement } from 'react'
import { Box, Grid, Paper, Skeleton } from '@mui/material'
import { styled } from '@mui/system'

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(3),
  overflow: 'hidden',
}));

const RaffleImg = styled(Skeleton)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(36),
  minHeight: '100%',
  borderRadius: theme.spacing(2),
}));

const ActionContainer = styled('div')(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.grey[100]}`,
  width: '100%',
  height: '100%',
  paddingLeft: theme.spacing(2),

  [theme.breakpoints.down('lg')]: {
  paddingLeft: 0,
  borderLeft: 'none',
  },
}));

const Tile = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(1, 2),
  borderRadius: theme.spacing(1),
}));

const TileGrid = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: 'repeat(auto-fit, minmax(6rem, 1fr))',
}));

export default function SkeletonCard(): ReactElement {
  return (
    <Wrapper>
      <Grid container>
        <Grid item xl={3} lg={12} sm={12} xs={12}>
          <RaffleImg variant="rectangular" />
        </Grid>
        <Grid item xl={6} lg={8} sm={12} xs={12} padding={3}>
          <Skeleton height="2rem" width="50%" />
          <Skeleton height="8rem" />
          <TileGrid>
            {new Array(4).fill(null).map((_, i) => (
              <Tile key={i}>
                <Skeleton variant="circular" width="1.2rem" height="1.2rem" />
                <Skeleton />
                <Skeleton />
              </Tile>
            ))}
          </TileGrid>
        </Grid>
        <Grid item xl={3} lg={4} sm={12} xs={12} padding={3} display="flex" alignItems="center" justifyContent="center">
          <ActionContainer>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              gap="1rem"
            >
              <Skeleton variant="rectangular" width="80%" height="12rem" />
              <Skeleton variant="rectangular" width="80%" height="2rem" />
            </Box>
          </ActionContainer>
        </Grid>
      </Grid>
    </Wrapper>
  )
}
