import { ReactElement } from 'react';
import { styled } from '@mui/system';
import { Skeleton, Grid, Paper, useTheme, useMediaQuery } from '@mui/material';

const Details = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}));
const TitleContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  marginLeft: theme.spacing(3),
}));

const StyledSkeletonRectangular = styled(Skeleton)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  margin: theme.spacing(0, 0, 1, 0),
}));

const AudioPlayerWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));

export function SkeletonDetails(): ReactElement {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Details>
        <StyledSkeletonRectangular
          variant="rectangular"
          width={90}
          height={65}
        />
        <TitleContainer>
          <Skeleton variant="text" width={200} height={42} />
          <Skeleton variant="text" width={140} height={20} />
        </TitleContainer>
      </Details>
      <StyledSkeletonRectangular
        variant="rectangular"
        width="100%"
        height={isMobile ? 200 : 425}
        style={{ marginTop: '10px' }}
      />
    </>
  );
}

export function SkeletonFunds(): ReactElement {
  return (
    <>
      <Skeleton variant="text" width={200} height={70} />
      <Grid container>
        <Grid item xl={6} xs={12}>
          <Skeleton variant="text" width={120} height={58} />
        </Grid>
        <Grid item xl={6} xs={12}>
          <Skeleton variant="text" width={130} height={60} />
        </Grid>
      </Grid>
    </>
  );
}

export function SkeletonCounter(): ReactElement {
  return (
    <>
      <Skeleton variant="text" width={120} height={32} />
      <StyledSkeletonRectangular
        variant="rectangular"
        width="100%"
        height={70}
      />
      <Skeleton variant="text" width="100%" height={60} />
    </>
  );
}

export function SkeletonAudioPlayer(): ReactElement {
  return (
    <AudioPlayerWrapper>
      <Skeleton variant="text" width="100%" height={60} />
    </AudioPlayerWrapper>
  );
}
