import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import { AssetType, InvestmentRaw } from '@opulous/web/src/shared/types'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress, Dialog, IconButton, Button } from '@mui/material'
import _DeleteIcon from '@mui/icons-material/Delete';
import _EditIcon from '@mui/icons-material/Edit';
import _WarningIcon from '@mui/icons-material/Warning';
import _OpenInNewIcon from '@mui/icons-material/OpenInNew';
import _DownloadIcon from '@mui/icons-material/Download';
import * as AssetService from 'src/services/assets';
import Papa from 'papaparse';
import config from 'src/config';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  borderRadius: theme.spacing(2),
  boxShadow: `0px 2px 13px -3px ${theme.palette.grey[500]}`,
  minHeight: theme.spacing(8),
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  paddingBottom: '100%',
  backgroundColor: theme.palette.grey[300],
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: `${theme.spacing(2)} !important`,
  position: 'relative',
}));

const Title = styled(Typography)(({ theme }) => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '90%',
  overflow: 'hidden',
  color: theme.palette.text.primary,
  fontWeight: 'bold',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  minHeight: theme.spacing(6),
  fontSize: theme.spacing(1.875),
  color: theme.palette.grey[400],
}));

const DeleteIcon = styled(_DeleteIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  fill: theme.palette.primary.main,
}));

const EditIcon = styled(_EditIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  fill: theme.palette.primary.main,
}));

const DownloadIcon = styled(_DownloadIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  fill: theme.palette.primary.main,
}));

const WarningIcon = styled(_WarningIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  color: theme.palette.warning.main,
}));

const OpenInNewIcon = styled(_OpenInNewIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  fill: theme.palette.primary.main,
}));

const AssetTypeTitle = styled('span')<{ type: AssetType }>(
  ({ type, theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    lineHeight: theme.spacing(2),
    borderRadius: theme.spacing(2),
    padding: '5px 10px',
    backgroundColor:
      type === AssetType.artwork
        ? theme.palette.secondary.dark
        : theme.palette.primary.main,
  })
)

const AssetMount = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.common.white,
  fontWeight: 500,
  fontSize: theme.spacing(1.75),
  lineHeight: theme.spacing(2),
  borderRadius: theme.spacing(2),
  padding: '5px 10px',
  backgroundColor: 'rgba(0, 0, 5, 0.4)',
}))

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: '6px 10px',
    marginBottom: 6,
  },
}))

interface AssetCardProps {
  assetId: number
  unitName: string
  image: string
  title: string
  subTitle?: string
  nftCount?: number
  type: AssetType
  hasMetadataError?: boolean
  investment?: InvestmentRaw
  onAssetRefresh: () => Promise<void>
}

const AdminAssetCard: React.FC<AssetCardProps> = ({
  assetId,
  image,
  title,
  subTitle,
  type,
  nftCount,
  unitName,
  hasMetadataError,
  investment,
  onAssetRefresh,
}) => {
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [shouldShowDeletionDialog, setShouldShowDeletionDialog] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const handleEdit = () => navigate(`/admin/assets/${encodeURIComponent(unitName)}/edit`);

  const handleAssetDeletionDialogClose = () => setShouldShowDeletionDialog(false);
  const handleAssetDeletionConfirm = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDeleting(true);
    await AssetService.adminDeleteAsset(unitName);
    setIsDeleting(false);
    await onAssetRefresh();
  };
  const handleCsvExport = () => {
    setIsExporting(true);
    const ts = new Date().toISOString().replace(/(\.\d+)Z$/g, '').replace(/[^\d]/g, '_');
    const { origin } = new URL(window.location.href);
    const csv = Papa.unparse({
      data: [
        assetId,
        unitName,
        type,
        nftCount,
        Number.isInteger(investment?.id)
          ? `${origin}/mfts/${encodeURIComponent(investment?.id as number)}`
          : `${origin}/tokens/${encodeURIComponent(unitName)}`,
        `${config.env.ALGO_EXPLORER_URL}/asset/${encodeURIComponent(assetId)}`,
      ],
      fields: [
        'asset_id',
        'unit_name',
        'type',
        'nft_count',
        'platform_url',
        'algo_explorer_url',
      ],
    }, {
      header: true,
      delimiter: ';',
      skipEmptyLines: true,
    });
    setIsExporting(false);

    const downloadUrl = URL.createObjectURL(
      new Blob([csv], { type: 'text/csv' }),
    );
    const el = document.createElement('a');
    el.setAttribute('target', '_blank');
    el.setAttribute('download', `asset_${assetId}_${ts}.csv`);
    el.setAttribute('href', downloadUrl);
    el.click();
  };

  return (<>
    <StyledCard>
      <AssetTypeTitle type={type}>
        {type === AssetType.artwork ? 'Artwork' : 'MFT'}
      </AssetTypeTitle>
      {!!nftCount && nftCount > 0 && (
        <BootstrapTooltip
          title={`${nftCount} owned ${nftCount > 1 ? 'assets' : 'asset'}`}
          placement="top"
        >
          <AssetMount>{nftCount}</AssetMount>
        </BootstrapTooltip>
      )}
      <StyledCardMedia image={`${image}`} />
      <StyledCardContent>
        <Title>{title}</Title>
        <SubTitle>{subTitle || null}</SubTitle>
        <Box display="flex" justifyContent="flex-end" gap={1}>
          {hasMetadataError ? (
            <Tooltip title={
              <Box padding={.5}>
                The asset metadata is missing. You probably misconfigured the asset or forgot to upload the metadata file `{unitName}.json` to the server.
              </Box>
            }>
              <Box display="flex" justifyContent="center" alignItems="center" padding={1}>
                <WarningIcon />
              </Box>
            </Tooltip>
          ) : (
            <Tooltip title="Open asset page in new tab">
              <IconButton onClick={() => window.open(
                Number.isInteger(investment?.id)
                  ? `/mfts/${encodeURIComponent(investment?.id as number)}`
                  : `/tokens/${encodeURIComponent(unitName)}`
              )}>
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Export asset CSV">
            <IconButton onClick={handleCsvExport}>
              {isExporting ? <CircularProgress size="1rem" /> : <DownloadIcon />}
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit">
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton onClick={() => setShouldShowDeletionDialog(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </StyledCardContent>
    </StyledCard>

    {shouldShowDeletionDialog && (
        <Dialog
          open={shouldShowDeletionDialog}
          maxWidth="sm"
          onClose={handleAssetDeletionDialogClose}
        >
          <Box margin={3} display="flex" flexDirection="column" gap={3}>
            <Typography variant="h3">Delete Asset {unitName}</Typography>
            <Box>
              Are you sure you want to delete Asset <b>{title}</b> ({unitName})?
            </Box>
            <Box display="flex" gap={2} justifyContent="center">
              <Button
                color="primary"
                variant="text"
                disabled={isDeleting}
                onClick={handleAssetDeletionDialogClose}
              >
                Cancel
              </Button>
              <Button
                color="error"
                disabled={isDeleting}
                onClick={handleAssetDeletionConfirm}
              >
                {isDeleting ? (
                  <CircularProgress size="1.5rem" />
                ) : 'Confirm'}
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
  </>);
};

export default AdminAssetCard;
