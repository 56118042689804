import { ReactElement, useCallback, useContext } from 'react';
import {
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import FilterSelection from '@opulous/web/src/components/Common/FilterSelection';
import FilterMultiSelection from '@opulous/web/src/components/Common/FilterMultiSelection';
import RoyaltiesContext from '@opulous/web/src/pages/Royalties/context';
import { FilterSelected } from '@opulous/web/src/pages/Royalties/types';

const FilterBarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(4, -1, 3),
  [theme.breakpoints.down('lg')]: {
    flexWrap: 'wrap',
  },
}));

const StyledFilterSelection = styled(FilterSelection)(({ theme }) => ({
  margin: theme.spacing(0, 1, 1, 1),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

const StyledFilterMultiSelection = styled(FilterMultiSelection)(
  ({ theme }) => ({
    margin: theme.spacing(0, 1, 1, 1),
    maxWidth: '240px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      maxWidth: '100%',
    },
  }),
);

const StyledRadioContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  minWidth: theme.spacing(30),
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 0),
  margin: theme.spacing(0, 1, 1, 1),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontWeight: 500,
  padding: theme.spacing(0, 2),
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  margin: theme.spacing(0, 2),
}));

export default function FilterBar(): ReactElement {
  const { state: royaltiesContext, actions: royaltiesActions } =
    useContext(RoyaltiesContext);
  const { royaltiesSalesFilter: filter, salesFilterSelected: filterSelected } =
    royaltiesContext;

  const handleChange = useCallback((newFilterSelected: FilterSelected) => {
    royaltiesActions.changeFilterSelected(newFilterSelected);
    royaltiesActions.loadSalesSummaryData(
      royaltiesContext.walletAddress,
      royaltiesContext.royaltiesSalesFilter,
      newFilterSelected,
    );
    royaltiesActions.loadSalesFullOverview(
      royaltiesContext.walletAddress,
      newFilterSelected,
      royaltiesContext.fullOverviewOptionSelected,
    );
  }, []);

  return (
    <FilterBarContainer data-testid="filter-bar">
      <StyledFilterSelection
        data-testid="filter-bar__date-select"
        label="Date"
        options={filter.date}
        value={filterSelected.months}
        handleChange={value =>
          handleChange({ ...filterSelected, months: Number(value) })
        }
      />
      <StyledFilterMultiSelection
        data-testid="filter-bar__release-select"
        label="Releases"
        options={filter.release}
        value={filterSelected.releaseUPCs}
        handleChange={values =>
          handleChange({ ...filterSelected, releaseUPCs: values as string[] })
        }
      />
      <StyledRadioContainer data-testid="filter-bar__individual-radio">
        <Label variant="h6">
          <small data-testid="filter-bar__individual-radio__title">
            Rewards
          </small>
        </Label>
        <StyledRadioGroup
          row
          defaultValue={!!filterSelected.individual}
          name="radio-buttons-group"
          onChange={({ target: { value } }) =>
            handleChange({ ...filterSelected, individual: JSON.parse(value) })
          }
        >
          <FormControlLabel
            data-testid="filter-bar__individual-radio__true-option"
            value={true}
            control={<Radio />}
            label="My Rewards"
          />
          <FormControlLabel
            data-testid="filter-bar__individual-radio__false-option"
            value={false}
            control={<Radio />}
            label="Total"
          />
        </StyledRadioGroup>
      </StyledRadioContainer>
    </FilterBarContainer>
  );
}
