import React, { ReactElement, useContext } from 'react';
import {
  Box,
  Paper,
  Table,
  TableRow,
  TableBody,
  Skeleton,
  TableContainer,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import { alpha } from '@mui/system/colorManipulator';

import * as HighChartsMaps from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import mapDataWorld from '@highcharts/map-collection/custom/world.geo.json';
import { createHexColor } from 'src/shared/helpers';
import RoyaltiesContext from 'src/pages/Royalties/context';

import {
  Title,
  ProgressCell,
  StyledTableCell,
  TruncatedTableCell,
} from '@opulous/web/src/components/Royalties/BestCards/styled-components';
import { formatCurrency } from '@opulous/web/src/utils';

const CountryPerformanceContainer = styled(Paper)(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.grey[100]}`,
  padding: theme.spacing(2, 3),
}));

const Label = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const TooltipPointFormat = `
  <div style="padding: 1rem; border-radius: 0.6rem; background-color: {point.color}">
    <div style="color: white; margin-bottom: 10px">{point.name}</div>
    <div style="display: flex">
      <div>
        <div style="color: white">\${point.value:,.2f}</div>
      </div>
    </div>
  </div>
`;

const SKELETON_ROWS = new Array(5).fill(1);
type SeriesMapDataOptions = {
  code: string;
  name: string;
  value: number;
  color: string;
};

export default function BestPerformingCountries(): ReactElement {
  const { state: royaltiesContext } = useContext(RoyaltiesContext);
  const loading = royaltiesContext.tabLoading;
  const countriesSalesSummaryPrepared =
    royaltiesContext.royaltiesCountriesSalesSummary.map((it, ix) => ({
      code: it.countryCode,
      name: it.countryName,
      value: it.earning,
      color: createHexColor(ix),
      streams: it.units,
      earning: it.earning,
      percent: it.percent,
    }));
  const chart = countriesSalesSummaryPrepared.map(it => ({
    code: it.code,
    name: it.name,
    value: it.value,
    color: it.color,
  }));
  const data = countriesSalesSummaryPrepared.map(it => ({
    name: it.name,
    color: it.color,
    streams: it.streams,
    earning: it.earning,
    percent: it.percent,
  }));

  const options: HighChartsMaps.Options = {
    chart: {
      plotBorderWidth: 0,
      height: 250,
      margin: 0,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    colors: ['#CBD1D6'],
    series: [
      {
        type: 'map',
        name: '',
        showInLegend: false,
        mapData: mapDataWorld,
        data: chart as SeriesMapDataOptions[],
        joinBy: ['iso-a2', 'code'],
        cursor: 'pointer',
        borderWidth: 0.5,
      },
    ],
    tooltip: {
      borderRadius: 20,
      shadow: false,
      borderWidth: 0,
      padding: -10,
      backgroundColor: 'transparent',
      useHTML: true,
      pointFormat: TooltipPointFormat,
    },
  };

  return (
    <CountryPerformanceContainer data-testid="best-performing-countries">
      <Title variant="subtitle2" data-testid="best-performing-countries__title">
        Best performing countries
      </Title>
      {loading ? (
        <Skeleton
          variant="rectangular"
          height={240}
          data-testid="best-performing-countries__chart-skeleton"
        ></Skeleton>
      ) : (
        <HighchartsReact
          options={options}
          highcharts={HighChartsMaps}
          constructorType={'mapChart'}
        />
      )}
      <TableContainer
        sx={{ pb: 2 }}
        data-testid="best-performing-countries__table"
      >
        <Table>
          <TableBody>
            {loading &&
              SKELETON_ROWS.map((it, index) => (
                <TableRow key={`table-item-${index}`}>
                  <StyledTableCell>
                    <Skeleton
                      variant="text"
                      height={40}
                      data-testid="best-performing-countries__table-skeleton"
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            {!loading &&
              data.map((row, index) => (
                <React.Fragment key={`country-item-${index}`}>
                  <TableRow data-testid="best-performing-countries__table__tr-data">
                    <TruncatedTableCell>
                      <Box
                        sx={{ display: 'inline-flex', alignItems: 'center' }}
                      >
                        <Box
                          sx={{
                            width: 20,
                            height: 15,
                            background: row.color,
                            borderRadius: 0.2,
                            marginRight: 1,
                          }}
                        />
                        <Label>{row.name}</Label>
                      </Box>
                    </TruncatedTableCell>
                    <StyledTableCell align="right">
                      {`${formatCurrency(row.earning)} (${row.percent}%)`}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    key={`country-item-percent-${index}`}
                    data-testid="best-performing-countries__table__tr-progress"
                  >
                    <ProgressCell colSpan={2} sx={{ pt: 0 }}>
                      <LinearProgress
                        variant="determinate"
                        value={row.percent}
                        sx={{
                          background: alpha(row.color, 0.2),
                          '& .MuiLinearProgress-bar': {
                            background: row.color,
                          },
                        }}
                      />
                    </ProgressCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CountryPerformanceContainer>
  );
}
