import { alpha, styled } from '@mui/system';
import { Button, LinearProgress } from '@mui/material';

export const LinearProgressContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0, 0.8, 0),
}));

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: theme.spacing(1),
  borderRadius: theme.spacing(1),
  background: alpha(theme.palette.primary.main, 0.2),
  '& .MuiLinearProgress-bar': {
    background: theme.palette.primary.main,
  },
}));

export const InvestmentButtonFloat = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  marginTop: theme.spacing(2),
  position: 'sticky',
  width: '100%',
  top: 'calc(100vh - 70px)',
  zIndex: 3,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
}));

export const InvestmentButton = styled(Button)<{ disabled?: boolean }>(({ disabled, theme }) => ({
  borderRadius: theme.spacing(4),
  marginTop: theme.spacing(2),
  ...(disabled ? {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey['A100'],
      '&:hover': {
        backgroundColor: theme.palette.grey['A100'],
        boxShadow: 'none',
      }
    }
  } : {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  })
}));

