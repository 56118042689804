import algosdk from 'algosdk'
import { createContext } from 'react'
import {
  IWalletContextState,
  RoyaltiesCountriesSalesSummary,
  RoyaltiesMonthsSalesSummary,
  RoyaltiesPayoutEntry,
  RoyaltiesPayoutInfo,
  RoyaltiesReleasesSalesSummary,
  RoyaltiesSalesFilter,
  RoyaltiesSalesFullOverview,
  RoyaltiesStoresSalesSummary,
} from '@opulous/web/src/shared/types'
import { RoyaltiesActions } from './actions'
import { FilterSelected, TableTabOption } from './types'

export type RoyaltiesContextState = {
  walletState: IWalletContextState
  walletAddress: string
  tabLoading: boolean
  loadingOverview: boolean
  processingOptin: boolean
  processingWithdraw: boolean
  showAlert?: {
    error?: boolean
    success?: boolean
    message?: string
  }
  tabSelected: string
  optInUSDCTransaction?: algosdk.Transaction
  royaltiesSalesFilter: RoyaltiesSalesFilter
  royaltiesCountriesSalesSummary: RoyaltiesCountriesSalesSummary
  royaltiesStoresSalesSummary: RoyaltiesStoresSalesSummary
  royaltiesReleasesSalesSummary: RoyaltiesReleasesSalesSummary
  royaltiesMonthsSalesSummary: RoyaltiesMonthsSalesSummary
  royaltiesSalesFullOverview: RoyaltiesSalesFullOverview
  salesFilterSelected: FilterSelected
  fullOverviewOptionSelected: TableTabOption
  royaltiesPayoutInfo: RoyaltiesPayoutInfo
  royaltiesPayoutHistory: RoyaltiesPayoutEntry[]
}

type RoyaltiesContextType = {
  state: RoyaltiesContextState
  actions: RoyaltiesActions
}
const RoyaltiesContext = createContext<RoyaltiesContextType>(
  {} as RoyaltiesContextType
)
export default RoyaltiesContext
