import MenuItem from '@mui/material/MenuItem'
import { SelectChangeEvent, Select} from '@mui/material'
import { ReactElement } from 'react'
import { styled } from '@mui/system'
import { InputBase, Typography, IconButton } from '@mui/material'

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontWeight: 500,
  padding: theme.spacing(0, 2),
}))

const SelectionContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  minWidth: theme.spacing(30),
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 0),
  'button': {
    right: theme.spacing(0.8),
    padding: theme.spacing(0, 1),
  }
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0, 1, 0, 2),
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type OptionType = {
  value?: string | number
  label: string
}

interface Props {
  'data-testid'?: string
  className?: string
  label: string
  value: string[] | number[]
  options: OptionType[]
  handleChange: (value: string[] | number[]) => void
}
export default function FilterSelection(props: Props): ReactElement {
  const { className, label, value, options, handleChange } = props

  const handleSelection = (event: SelectChangeEvent<string[] | number[]>) => {
    handleChange(
      // On autofill we get a stringified value.
      typeof event.target.value === 'string'
        ? event.target.value.split(',')
        : event.target.value
    )
  }

  const handleClearClick = () => {
    handleChange([])
  }

  return (
    <SelectionContainer
      className={className}
      data-testid={props['data-testid'] || 'filter-multi-selection'}
    >
      <Label variant="h6">
        <small>{label}</small>
      </Label>
      <Select
        multiple
        displayEmpty
        value={value}
        onChange={handleSelection}
        input={<StyledInputBase />}
        renderValue={
          value.length > 0 ? undefined : () => <span>{options.find((it) => !it.value)?.label}</span>
        }
        sx={{'& .MuiSelect-iconOutlined': {display: value.length ? 'none': ''}}}
        MenuProps={MenuProps}
        endAdornment={<IconButton data-testid="filter-multi-selection__remove-button" sx={{display: value.length ? '' : 'none'}} onClick={handleClearClick}>&times;</IconButton>}
      >
        {options
          .filter((it) => !!it.value)
          .map((option, index) => (
            <MenuItem key={`option-${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </SelectionContainer>
  )
}
