import { ReactElement } from 'react';
import { styled } from '@mui/system';
import {
  InputBase,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent
} from '@mui/material';

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontWeight: 500,
  padding: theme.spacing(0, 2),
}));

const SelectionContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  minWidth: theme.spacing(30),
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 0),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0, 1, 0, 2),
  },
}));

type OptionType = {
  value?: string | number,
  label: string,
};

interface Props {
  'data-testid'?: string,
  className?: string;
  label: string;
  value?: string | number;
  options: OptionType[];
  handleChange?: (value: string | number) => void;
}
export default function FilterSelection(props: Props): ReactElement {
  const { className, label, value, options, handleChange } = props
  const handleSelection = (event: SelectChangeEvent) => {
    if (handleChange) {
      handleChange(event.target.value)
    }
  }

  return (
    <SelectionContainer className={className} data-testid={props['data-testid'] || 'filter-selection'}>
      <Label variant="h6"><small data-testid="filter-selection__label">{label}</small></Label>
      <Select
        data-testid="filter-selection__select"
        value={value ? `${value}` : ''}
        displayEmpty
        onChange={handleSelection}
        input={<StyledInputBase />}
      >
        {options.map((option, index) => (
          <MenuItem key={`option-${index}`} value={option.value || ''}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </SelectionContainer>
  )
}
