import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { intervalToDuration, isAfter } from 'date-fns';
import { styled } from '@mui/system';
import {
  Box,
  CircularProgress,
  Dialog,
  Fade,
  Grid,
  Link,
  Skeleton,
  useTheme,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  StyledPaper,
  StyledCircularProgress,
  InvestmentDetailsCard,
} from '@opulous/web/src/pages/Sales/Investment/styled-components';
import {
  PageTitle,
  StyledButton,
} from '@opulous/web/src/pages/Sales/Investment/styled-components';
import InvestmentContext from '@opulous/web/src/pages/Sales/Investment/context';
import {
  formatCurrency,
  formatNumberWithFixedDecimalPlaces,
} from '@opulous/web/src/utils';
import WalletContext from '@opulous/web/src/context/context';
import OPULLabel from '@opulous/web/src/components/shared/OPULLabel';
import MinorOPUL from '@opulous/web/src/shared/valueObjects/MinorOPUL';
import useOpulPurchasePopup from '@opulous/web/src/hooks/useOpulPurchasePopup';
import { PaymentMethodOptions } from '@opulous/web/src/shared/types';
import config from '@opulous/web/src/config';

const StyledPageTitle = styled(PageTitle)(({ theme }) => ({
  fontSize: theme.spacing(4.5),
  marginTop: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
}));
const DetailsWrapper = styled(StyledPaper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  width: '100%',
  maxWidth: theme.spacing(65),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(4),
  },
}));
const DetailsItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const ActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  marginTop: theme.spacing(3),
  width: '100%',
  maxWidth: theme.spacing(65),
}));
const Message = styled('p')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  gap: theme.spacing(0.5),
}));
const ExpireMessage = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));
const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.grey[800],
  textDecorationColor: theme.palette.grey[800],
  marginTop: theme.spacing(3),
  cursor: 'pointer',
}));
const PayButton = styled('button')<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
    height: theme.spacing(6),
    borderRadius: theme.spacing(6),
    backgroundColor: disabled
      ? theme.palette.grey[300]
      : theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: theme.spacing(2),
    fontWeight: '600',
    border: 'none',
    outline: 'none',
    marginTop: theme.spacing(1.5),
    cursor: 'pointer',
  }),
);
const StyledErrorIcon = styled(ErrorIcon)(({ theme }) => ({
  width: '3rem',
  height: '3rem',
  fill: theme.palette.error.main,
}));
const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  width: '3rem',
  height: '3rem',
  fill: theme.palette.warning.main,
}));

const RocketfuelDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    maxWidth: '500px',
    height: '800px',
  },
  iframe: {
    width: '500px',
    height: '800px',
    border: 'none',
  },
}));

enum RocketfuelIframeEventType {
  CANCEL = 'rocketfuel_iframe_close',
  PAYMENT_CONFIRMED = 'rocketfuel_result_ok',
}

let counterInterval: any;
export default function InvestmentPurchaseConfirmationPage(): ReactElement {
  const { state: walletState, reloadWallet } = useContext(WalletContext);
  const { state: investmentState, actions: investmentActions } =
    useContext(InvestmentContext);
  const { investment, paymentIntent } = investmentState;
  const [counterDown, setCounterDown] = useState<any>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    isLoading: preparingBuyOpul,
    onPurchase,
    isOptingIn,
    isTimeout,
    hasError,
  } = useOpulPurchasePopup();
  const [shouldShowDialog, setShouldShowDialog] = useState(true);

  const createPaymentIntent = useCallback(() => {
    if (
      investment?.id &&
      investmentState.purchaseFlow?.purchaseAmount &&
      investmentState.purchaseFlow.email &&
      investmentState.purchaseFlow?.paymentMethod
    ) {
      investmentActions.createPaymentIntent({
        investmentId: investment?.id,
        walletAddress: walletState.wallet,
        email: investmentState.purchaseFlow.email,
        purchaseAmount: investmentState.purchaseFlow?.purchaseAmount,
        paymentMethod: investmentState.purchaseFlow?.paymentMethod,
      });
    }
  }, [investment, investmentState]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    createPaymentIntent();
    return () => {
      if (counterInterval) {
        clearInterval(counterInterval);
      }
    };
  }, []);

  const expirePaymentIntent = () => {
    if (investmentState.processingPayment) {
      return;
    }
    if (counterInterval) {
      clearInterval(counterInterval);
    }
    investmentActions.expirePaymentIntent();
  };

  useEffect(() => {
    if (paymentIntent && paymentIntent?.payment?.balanceEnough) {
      if (counterInterval) {
        clearInterval(counterInterval);
      }
      counterInterval = setInterval(() => {
        const now = new Date();
        if (isAfter(now, paymentIntent.payment.expirationDate)) {
          expirePaymentIntent();
        }
        const newDuration = intervalToDuration({
          start: now,
          end: paymentIntent.payment.expirationDate,
        });

        setCounterDown(newDuration);
      }, 1000);
    }
  }, [paymentIntent]);

  const reloadPaymentIntent = useCallback(async () => {
    createPaymentIntent();
    reloadWallet();
  }, [investment, investmentState]);

  const buyOpul = useCallback(async () => {
    await onPurchase();
  }, [preparingBuyOpul]);

  const completePaymentTransaction = useCallback(async () => {
    if (!investmentState.processingPayment && paymentIntent) {
      const isPaymentWithOPUL =
        investmentState.purchaseFlow?.paymentMethod ===
        PaymentMethodOptions.OPUL;
      const isPaymentWithCRYPTO =
        investmentState.purchaseFlow?.paymentMethod ===
        PaymentMethodOptions.CRYPTO;
      if (
        isPaymentWithOPUL &&
        walletState.walletType &&
        paymentIntent?.transactionEncoded
      ) {
        await investmentActions.completeOpulPaymentTransaction({
          externalId: paymentIntent.payment.externalId,
          walletType: walletState.walletType,
          connector: walletState.connector,
          transactionEncoded: paymentIntent?.transactionEncoded,
          reloadWalletCallback: reloadWallet,
        });
      } else if (isPaymentWithCRYPTO) {
        investmentActions.startPaymentWithCrypto();
      }
    }
  }, [investmentState.processingPayment, walletState, paymentIntent]);

  const renderMessagePayment = () => {
    const notPaymentWithOPUL =
      investmentState.purchaseFlow?.paymentMethod !== PaymentMethodOptions.OPUL;
    if (notPaymentWithOPUL || paymentIntent?.payment?.balanceEnough) {
      return (
        <ExpireMessage>
          {investmentState.paymentIntentExpired ? (
            'Your reservation expired'
          ) : (
            <>
              Your reservation will expire in
              <span>
                {`${counterDown?.minutes || 0}`.padStart(2, '0') +
                  ':' +
                  `${counterDown?.seconds || 0}`.padStart(2, '0')}
              </span>
            </>
          )}
        </ExpireMessage>
      );
    }

    return (
      <span data-testid="investment-purchase-confirmation-page__message__insufficient-balance">
        Insufficient balance, please purchase OPUL to complete your order
      </span>
    );
  };

  const renderPayButton = () => {
    const notPaymentWithOPUL =
      investmentState.purchaseFlow?.paymentMethod !== PaymentMethodOptions.OPUL;
    if (notPaymentWithOPUL || paymentIntent?.payment?.balanceEnough) {
      const titleMap = {
        [PaymentMethodOptions.OPUL.toString()]: 'Pay with OPUL',
        [PaymentMethodOptions.CRYPTO.toString()]: 'Pay with Crypto',
      };
      return (
        <PayButton
          data-testid="investment-purchase-confirmation-page__pay-button"
          disabled={!paymentIntent || investmentState.paymentIntentExpired}
          onClick={completePaymentTransaction}
        >
          {investmentState.processingPayment
            ? 'Confirming transaction'
            : titleMap[`${investmentState.purchaseFlow?.paymentMethod}`]}
          {investmentState.processingPayment && (
            <StyledCircularProgress
              data-testid="investment-purchase-confirmation-page__pay-button__progress"
              size={20}
              color="info"
            />
          )}
        </PayButton>
      );
    }

    return (
      <StyledButton
        data-testid="investment-purchase-confirmation-page__buy-button"
        disabled={!paymentIntent}
        onClick={buyOpul}
      >
        {preparingBuyOpul ? 'Preparing...' : 'Buy OPUL'}
      </StyledButton>
    );
  };

  useEffect(() => {
    function manageIframeEvents(event: any) {
      if (event.data.type === RocketfuelIframeEventType.CANCEL) {
        investmentActions.cancelProcessingPayment();
      } else if (event.data.type === RocketfuelIframeEventType.PAYMENT_CONFIRMED) {
        investmentActions.confirmPaymentWithCrypto();
      }
    }
    window.addEventListener('message', manageIframeEvents);

    return () => {
      window.removeEventListener('message', manageIframeEvents);
    };
  }, []);

  return (
    <Fade in data-testid="investment-purchase-confirmation-page">
      <Grid container spacing={6}>
        <Grid item xl={6} xs={12}>
          <StyledPageTitle data-testid="investment-purchase-confirmation-page__title">
            Confirm your purchase
          </StyledPageTitle>
          <DetailsWrapper>
            <DetailsItem>
              <span data-testid="investment-purchase-confirmation-page__mft__label">
                Number of MFTs
              </span>
              <span data-testid="investment-purchase-confirmation-page__mft__value">
                <strong>
                  {formatNumberWithFixedDecimalPlaces(
                    investmentState.purchaseFlow?.purchaseAmount || 0,
                    0,
                  )}
                </strong>
              </span>
            </DetailsItem>
            <DetailsItem>
              <span data-testid="investment-purchase-confirmation-page__usd__label">
                Total in USD
              </span>
              <span data-testid="investment-purchase-confirmation-page__usd__value">
                <strong>
                  {formatCurrency(
                    investmentState.purchaseFlow?.purchaseAmount || 0,
                    '$',
                    2,
                  )}
                </strong>
              </span>
            </DetailsItem>
            {investmentState.purchaseFlow?.paymentMethod ===
              PaymentMethodOptions.OPUL && (
              <>
                <DetailsItem>
                  <span data-testid="investment-purchase-confirmation-page__opul__label">
                    Total in OPUL
                  </span>
                  <span data-testid="investment-purchase-confirmation-page__opul__value">
                    {!paymentIntent ? (
                      <Skeleton
                        data-testid="investment-purchase-confirmation-page__opul__skeleton"
                        variant="text"
                        width={180}
                        height={25}
                      />
                    ) : (
                      <strong>
                        <OPULLabel
                          value={MinorOPUL.fromOPUL(
                            paymentIntent.payment.settlementAmount,
                          )}
                          hideUnit
                          showValue
                        />
                      </strong>
                    )}
                  </span>
                </DetailsItem>
                <DetailsItem>
                  <span data-testid="investment-purchase-confirmation-page__rate__label">
                    Exchange Rate
                  </span>
                  <span data-testid="investment-purchase-confirmation-page__rate__value">
                    {!paymentIntent ? (
                      <Skeleton
                        data-testid="investment-purchase-confirmation-page__rate__skeleton"
                        variant="text"
                        width={180}
                        height={25}
                      />
                    ) : (
                      <strong>
                        1 OPUL ={' '}
                        {formatCurrency(
                          paymentIntent.payment.exchangeRate,
                          '$',
                          4,
                        )}{' '}
                        USD
                      </strong>
                    )}
                  </span>
                </DetailsItem>
              </>
            )}
          </DetailsWrapper>
          <ActionsWrapper>
            <Message data-testid="investment-purchase-confirmation-page__message">
              {paymentIntent ? (
                renderMessagePayment()
              ) : (
                <Skeleton
                  data-testid="investment-purchase-confirmation-page__message__skeleton"
                  variant="text"
                  width={isMobile ? 348 : 400}
                  height={50}
                />
              )}
            </Message>
            {paymentIntent ? (
              <>
                {renderPayButton()}
                {investmentState.paymentIntentExpired && (
                  <StyledLink
                    data-testid="investment-purchase-confirmation-page__reload-page"
                    onClick={reloadPaymentIntent}
                  >
                    Refresh page
                  </StyledLink>
                )}
              </>
            ) : (
              <>
                <Skeleton
                  data-testid="investment-purchase-confirmation-page__buy-button__skeleton"
                  variant="text"
                  width={200}
                  height={100}
                />
                <Skeleton
                  data-testid="investment-purchase-confirmation-page__reload-page__skeleton"
                  variant="text"
                  width={100}
                  height={30}
                />
              </>
            )}
          </ActionsWrapper>
        </Grid>
        {!isMobile && (
          <Grid item xl={6} xs={12} display={'flex'} justifyContent={'center'}>
            {investment && <InvestmentDetailsCard investment={investment} />}
          </Grid>
        )}
        <Dialog
          title="OPUL Opt-In"
          onClose={() => setShouldShowDialog(false)}
          maxWidth="lg"
          open={shouldShowDialog && isOptingIn}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            padding={4}
            alignItems="center"
          >
            {isTimeout ? (
              <>
                <StyledWarningIcon />
                <span>Operation has timed out</span>
              </>
            ) : hasError ? (
              <>
                <StyledErrorIcon />
                <span>An unexpected error ocurred</span>
              </>
            ) : (
              <>
                <span>Please opt in to OPUL before purchase</span>
                <CircularProgress size="1.5rem" color="primary" />
              </>
            )}
          </Box>
        </Dialog>
        {!!investmentState.openCryptoPaymentIframe && (
          <RocketfuelDialog
            title="Pay with Crypto"
            onClose={() => investmentActions.cancelProcessingPayment()}
            open={!!investmentState.openCryptoPaymentIframe}
          >
            <iframe
              src={`${config.env.ROCKETFUEL_HOSTED_PAGE_URL}/${paymentIntent?.payment?.externalId}`}
            ></iframe>
          </RocketfuelDialog>
        )}
      </Grid>
    </Fade>
  );
}
