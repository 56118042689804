import { createTheme } from '@mui/material/styles'
import { alpha, darken, lighten } from '@mui/system/colorManipulator'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import './assets/fonts/fonts.css'
import { ThemeMode } from './context/ThemeColorModeContext'

const white = '#FFFFFF'
const black = '#000005'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 568,
      lg: 768,
      xl: 1280, // keep this value, defines the page Container maxWidth
    },
  },
  shape: {
    borderRadius: 16,
  },
  typography: {
    fontFamily: 'Lexend, Helvetica, sans-serif',
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 600,
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: '2.25rem',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '1.75rem',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },
    subtitle1: {
      fontSize: '1.125rem',
      fontWeight: 300,
      lineHeight: '1.625rem',
    },
    subtitle2: {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: '1.625rem',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 300,
      lineHeight: '1.5rem',
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },
    button: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
      textTransform: 'initial',
    },
    caption: {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.25rem',
    },
    overline: {
      fontSize: '0.875rem',
      fontWeight: 300,
      lineHeight: '1.25rem',
      textTransform: 'initial',
    },
  },
  components: {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: <CheckCircleIcon />,
          info: <InfoIcon />,
        },
      },
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
        icon: {
          display: 'initial',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowX: 'inherit',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
        color: 'primary',
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: '5rem',
        },
        sizeSmall: {
          fontSize: '1rem',
        },
        sizeLarge: {
          fontSize: '1.125rem',
        },
        outlined: {
          borderWidth: '2px !important', // important needed for disabled state
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          flexDirection: 'column',
          alignItems: 'stretch',
          margin: 0,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h2',
        },
      },
      styleOverrides: {
        root: {
          textAlign: 'center',
          margin: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 24,
          maxWidth: 375,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          flexDirection: 'column',
          alignItems: 'stretch',
          margin: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    }
  },
})

const createColorTheme = (palette: Record<string, any>) => {
  const shadowBaseColor = palette.grey[700];
  const shadows = [
    'none',
    '0px 2px 2px 0px rgba(0, 0, 0, 0.20)', // Used by Header
    '0px 0px 0px 2px rgba(222, 223, 230, 0.4)', // Used by Paper and Card
    '0px 0px 0px 2px rgba(222, 223, 230, 0.4)', // Used by Input and Select
    `0px 10px 20px 0px ${alpha(shadowBaseColor, 0.04)}, 0px 4px 8px 0px ${alpha(shadowBaseColor, 0.04)}, 0px 1px 2px 0px ${alpha(shadowBaseColor, 0.12)}`,
    `0px 20px 40px 0px ${alpha(shadowBaseColor, 0.08)}, 0px 8px 16px 0px ${alpha(shadowBaseColor, 0.08)}, 0px 2px 4px 0px ${alpha(shadowBaseColor, 0.12)}`,
    `0px 30px 60px 0px ${alpha(shadowBaseColor, 0.12)}, 0px 12px 24px 0px ${alpha(shadowBaseColor, 0.12)}, 0px 3px 6px 0px ${alpha(shadowBaseColor, 0.12)}`,
    `0px 40px 80px 0px ${alpha(shadowBaseColor, 0.16)}, 0px 16px 32px 0px ${alpha(shadowBaseColor, 0.16)}, 0px 4px 8px 0px ${alpha(shadowBaseColor, 0.12)}`,
    `0px 50px 100px 0px ${alpha(shadowBaseColor, 0.20)}, 0px 20px 40px 0px ${alpha(shadowBaseColor, 0.20)}, 0px 5px 10px 0px ${alpha(shadowBaseColor, 0.12)}`,
    '','','','','','','','','','','','','','','','','','','',
  ];
  return createTheme(theme, { palette, shadows }, {
    components: {
      MuiAlert: {
        styleOverrides: {
          filledSuccess: {
            color: palette.success.dark,
            backgroundColor: palette.success.light,
          },
          filledInfo: {
            color: palette.info.dark,
            backgroundColor: palette.info.light,
          },
          filledWarning: {
            color: palette.warning.dark,
            backgroundColor: palette.warning.light,
          },
          filledError: {
            color: palette.error.dark,
            backgroundColor: palette.error.light,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: palette.grey['A900'],
            boxShadow: shadows[1],
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              borderColor: palette.action.disabledBackground,
            },
          },
          sizeSmall: {
            padding: theme.spacing(1, 2.5),
          },
          sizeMedium: {
            padding: theme.spacing(1.5, 3.5),
          },
          sizeLarge: {
            padding: theme.spacing(2, 5),
            [theme.breakpoints.down('md')]: {
              fontSize: '1rem',
              padding: `${theme.spacing(1, 2.5)} !important`,
            },
          },
          contained: {
            boxShadow: shadows[1],
            '&:hover': {
              boxShadow: shadows[1],
            },
          },
          containedInherit: {
            backgroundColor: palette.grey[200],
            color: palette.text.primary,
            '&:hover': {
              backgroundColor: palette.grey[100],
            },
          },
          outlinedPrimary: {
            borderColor: palette.primary.main,
            color: palette.text.primary,
            '&:hover': {
              borderColor: palette.primary.dark,
            },
          },
          outlinedSecondary: {
            borderColor: palette.common.white,
            color: palette.common.white,
            '&:hover': {
              borderColor: palette.grey[100],
            },
          },
          outlinedInherit: {
            borderColor: palette.grey[100],
            color: palette.text.primary,
            '&:hover': {
              borderColor: palette.grey[300],
            },
          },
          textPrimary: {
            color: palette.text.primary,
            '&:hover': {
              backgroundColor: palette.grey[50],
            },
          },
          textSecondary: {
            color: palette.common.white,
            '&:hover': {
              backgroundColor: palette.grey[800],
            },
          },
          textInherit: {
            color: palette.text.secondary,
            '&:hover': {
              backgroundColor: palette.grey[50],
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: shadows[2],
            backgroundColor: palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(0),
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: theme.spacing(0, 5, 5, 5),
            '&:first-of-type': { paddingTop: theme.spacing(5) },
            '&:last-child': { paddingBottom: theme.spacing(5) },
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: theme.spacing(0, 5, 5, 5),
            '&:first-of-type': { paddingTop: theme.spacing(5) },
            '&:last-child': { paddingBottom: theme.spacing(5) },
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: theme.spacing(5, 5, 5, 5),
            '&:last-child': { paddingBottom: theme.spacing(5) },
          },
          subheader: {
            marginTop: theme.spacing(2.5),
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            boxShadow: shadows[3],
            backgroundColor: palette.background.paper,
            padding: theme.spacing(0),
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: theme.spacing(0, 5, 5, 5),
            '&:first-of-type': { paddingTop: theme.spacing(5) },
            '&:last-child': { paddingBottom: theme.spacing(5) },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: theme.spacing(0, 5, 5, 5),
            '&:first-of-type': { paddingTop: theme.spacing(5) },
            '&:last-child': { paddingBottom: theme.spacing(5) },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: theme.spacing(0, 5, 5, 5),
            '&:first-of-type': { paddingTop: theme.spacing(5) },
            '&:last-child': { paddingBottom: theme.spacing(5) },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            '& + .MuiCardHeader-root': { paddingTop: theme.spacing(5) },
            '& + .MuiCardContent-root': { paddingTop: theme.spacing(5) },
            '& + .MuiCardActions-root': { paddingTop: theme.spacing(5) },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            [theme.breakpoints.up('sm')]: {
              paddingRight: theme.spacing(10),
              paddingLeft: theme.spacing(10),
              minHeight: theme.spacing(10),
            },
            [theme.breakpoints.down('md')]: {
              paddingRight: theme.spacing(3),
              paddingLeft: theme.spacing(3),
            },
            [theme.breakpoints.down('sm')]: {
              paddingRight: theme.spacing(2),
              paddingLeft: theme.spacing(2),
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: shadows[2],
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            boxShadow: shadows[3],
            '& .MuiSvgIcon-root': {
              color: palette.text.primary,
              fill: palette.grey[300],
            }
          }
        }
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            '& .MuiSvgIcon-root': {
              color: palette.text.primary,
              fill: palette.grey[300],
            }
          }
        }
      }
    },
  });
};

export const lightTheme = createColorTheme({
  mode: ThemeMode.LIGHT,
  common: {
    black: black,
    white: white,
  },
  primary: {
    light: '#FFEAE0',
    main: '#FF5400',
    dark: '#CC4300',
    contrastText: white,
  },
  secondary: {
    light: '#F5E0ED',
    main: '#BF3D8D',
    dark: '#832A61',
    contrastText: white,
  },
  error: {
    light: '#FFF5F5',
    main: '#F03E3E',
    dark: '#A32A2A',
    contrastText: white,
  },
  warning: {
    light: '#FFF4E6',
    main: '#F59F00',
    dark: '#BA7900',
    contrastText: black,
  },
  info: {
    light: '#E7F5FF',
    main: '#1C7ED6',
    dark: darken('#1C7ED6', 0.6),
    contrastText: white,
  },
  success: {
    light: '#EBFBEE',
    main: '#40C057',
    dark: darken('#40C057', 0.6),
    contrastText: white,
  },
  grey: {
    A100: '#F8F8F8',
    A200: '#E5E5E5',
    A900: '#fbfbfb',
    50: '#F5F8FA',
    100: '#DFE4E8',
    200: '#CBD1D6',
    300: '#AFB7BD',
    400: '#98A0A6',
    500: '#80868C',
    600: '#686E73',
    700: '#43494D',
    800: '#282E30',
    900: '#14181A',
  },
  text: {
    primary: black,
    light: white,
    dark: black,
    secondary: alpha(black, 0.5),
    disabled: alpha(black, 0.3),
  },
  divider: alpha(black, 0.12),
  background: {
    paper: white,
    default: '#F5F8FA',
  },
  action: {
    disabled: alpha(black, 0.24),
    disabledBackground: alpha(black, 0.08),
  },
  shadows: [
    'none',
    '0px 2px 2px 0px rgba(0, 0, 0, 0.20)', // Used by Header
    '0px 0px 0px 2px rgba(222, 223, 230, 0.4)', // Used by Paper and Card
    '0px 0px 0px 2px rgba(222, 223, 230, 0.4)', // Used by Input and Select
  ],
});

export const darkTheme = createColorTheme({
  mode: ThemeMode.DARK,
  common: {
    black: white,
    white: black,
  },
  primary: {
    light: '#00151F',
    main: '#00ABFF',
    dark: '#33BCFF',
    contrastText: black,
  },
  secondary: {
    light: '#0A1F12',
    main: '#40C272',
    dark: '#7CD59E',
    contrastText: black,
  },
  error: {
    light: '#A32A2A',
    main: '#ff1d23',
    dark: '#d01717',
    contrastText: white,
  },
  warning: {
    light: '#BA7900',
    main: '#F59F00',
    dark: '#FFF4E6',
    contrastText: white,
  },
  info: {
    light: darken('#1C7ED6', 0.6),
    main: '#1C7ED6',
    dark: '#E7F5FF',
    contrastText: black,
  },
  success: {
    light: darken('#40C057', 0.6),
    main: '#40C057',
    dark: '#EBFBEE',
    contrastText: black,
  },
  grey: {
    A100: '#070707',
    A200: '#1A1A1A',
    A900: black,
    50: '#14181A',
    100: '#282E30',
    200: '#43494D',
    300: '#686E73',
    400: '#80868C',
    500: '#98A0A6',
    600: '#AFB7BD',
    700: '#CBD1D6',
    800: '#DFE4E8',
    900: '#F5F8FA',
  },
  text: {
    primary: white,
    light: white,
    dark: black,
    secondary: alpha(white, 0.5),
    disabled: alpha(white, 0.3),
  },
  divider: alpha(white, 0.12),
  background: {
    paper: lighten(black, .05),
    default: '#0A0705',
  },
  action: {
    disabled: alpha(white, 0.24),
    disabledBackground: alpha(white, 0.08),
  },
  shadows: [
    'none',
    '0px 2px 2px 0px rgba(0, 0, 0, 0.20)', // Used by Header
    '0px 0px 0px 2px rgba(222, 223, 230, 0.4)', // Used by Paper and Card
    '0px 0px 0px 2px rgba(222, 223, 230, 0.4)', // Used by Input and Select
  ],
});
