import axios from 'axios';
import algosdk from 'algosdk';
import { parse } from 'date-fns';
import * as Sentry from '@sentry/react';
import {
  RoyaltiesPayoutSummary,
  RoyaltiesSalesSummaryResponseData,
  APIResponse,
  RoyaltiesReleasesSalesSummary,
  RoyaltiesCountriesSalesSummary,
  RoyaltiesStoresSalesSummary,
  RoyaltiesMonthsSalesSummary,
  ResponsePageable,
  WalletTypeEnum,
} from '@opulous/web/src/shared/types';
import MicroUSDC from '@opulous/web/src/shared/valueObjects/MicroUSDC';
import WalletConnect from '@walletconnect/client';
import {
  makeAssetTransferTxn,
  signTransaction,
} from '@opulous/web/src/helpers/AlgorandHelper';
import { PeraWalletConnect } from '@perawallet/connect'
import { sendSignedTransactions } from '@opulous/web/src/services/my-algo';
import { getAdminAuthorizationHeader } from '../admin';

const YEAR_MONTH_FORMAT = 'yyyy-MM';

type SalesSummaryFilter = {
  walletAddress?: string;
  months?: number;
  individual?: boolean;
  releaseUPCs: string[];
  direction?: string;
  property?: string;
  limit?: number;
  offset?: number;
};
export const fetchSalesSummary = async (
  filter: SalesSummaryFilter,
): Promise<RoyaltiesSalesSummaryResponseData> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/royalties : fetchSalesSummary',
      message: 'Call',
      type: 'info',
    });
    const urlParams = new URLSearchParams();
    if (filter.walletAddress) {
      urlParams.append('walletAddress', filter.walletAddress);
    }
    if (filter.months) {
      urlParams.append('months', `${filter.months}`);
    }
    if (filter.individual) {
      urlParams.append('individual', `${filter.individual}`);
    }
    filter.releaseUPCs.forEach(id => {
      urlParams.append('releaseUPCs', `${id}`);
    });

    const axiosResponse = await axios.get<
      APIResponse<RoyaltiesSalesSummaryResponseData>
    >(`/api/royalties/sales?${urlParams.toString()}`);
    const responseData = axiosResponse.data.data;

    return {
      ...responseData,
      monthsSalesSummary: responseData.monthsSalesSummary.map(it => ({
        ...it,
        month: parse(it.month.toString(), YEAR_MONTH_FORMAT, new Date()),
      })),
    };
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const fetchAllSalesGroupedByReleases = async (
  filter: SalesSummaryFilter,
): Promise<ResponsePageable<RoyaltiesReleasesSalesSummary>> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/royalties : fetchAllSalesGroupedByReleases',
      message: 'Call',
      type: 'info',
    });
    const urlParams = new URLSearchParams();
    if (filter.walletAddress) {
      urlParams.append('walletAddress', filter.walletAddress);
    }
    if (filter.months) {
      urlParams.append('months', `${filter.months}`);
    }
    if (filter.individual) {
      urlParams.append('individual', `${filter.individual}`);
    }
    filter.releaseUPCs.forEach(id => {
      urlParams.append('releaseUPCs', `${id}`);
    });
    if (filter.direction && filter.property) {
      urlParams.append('direction', `${filter.direction}`);
      urlParams.append('property', `${filter.property}`);
    }
    if (filter.limit !== undefined && filter.offset !== undefined) {
      urlParams.append('limit', `${filter.limit}`);
      urlParams.append('offset', `${filter.offset}`);
    }

    const axiosResponse = await axios.get<
      ResponsePageable<RoyaltiesReleasesSalesSummary>
    >(`/api/royalties/sales/releases?${urlParams.toString()}`);
    const responseData = axiosResponse.data;
    return responseData;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const fetchAllSalesGroupedByCountries = async (
  filter: SalesSummaryFilter,
): Promise<ResponsePageable<RoyaltiesCountriesSalesSummary>> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/royalties : fetchAllSalesGroupedByCountries',
      message: 'Call',
      type: 'info',
    });
    const urlParams = new URLSearchParams();
    if (filter.walletAddress) {
      urlParams.append('walletAddress', filter.walletAddress);
    }
    if (filter.months) {
      urlParams.append('months', `${filter.months}`);
    }
    if (filter.individual) {
      urlParams.append('individual', `${filter.individual}`);
    }
    filter.releaseUPCs.forEach(id => {
      urlParams.append('releaseUPCs', `${id}`);
    });
    if (filter.direction && filter.property) {
      urlParams.append('direction', `${filter.direction}`);
      urlParams.append('property', `${filter.property}`);
    }
    if (filter.limit !== undefined && filter.offset !== undefined) {
      urlParams.append('limit', `${filter.limit}`);
      urlParams.append('offset', `${filter.offset}`);
    }

    const axiosResponse = await axios.get<
      ResponsePageable<RoyaltiesCountriesSalesSummary>
    >(`/api/royalties/sales/countries?${urlParams.toString()}`);
    const responseData = axiosResponse.data;
    return responseData;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const fetchAllSalesGroupedByStores = async (
  filter: SalesSummaryFilter,
): Promise<ResponsePageable<RoyaltiesStoresSalesSummary>> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/royalties : fetchAllSalesGroupedByStores',
      message: 'Call',
      type: 'info',
    });
    const urlParams = new URLSearchParams();
    if (filter.walletAddress) {
      urlParams.append('walletAddress', filter.walletAddress);
    }
    if (filter.months) {
      urlParams.append('months', `${filter.months}`);
    }
    if (filter.individual) {
      urlParams.append('individual', `${filter.individual}`);
    }
    filter.releaseUPCs.forEach(id => {
      urlParams.append('releaseUPCs', `${id}`);
    });
    if (filter.direction && filter.property) {
      urlParams.append('direction', `${filter.direction}`);
      urlParams.append('property', `${filter.property}`);
    }
    if (filter.limit !== undefined && filter.offset !== undefined) {
      urlParams.append('limit', `${filter.limit}`);
      urlParams.append('offset', `${filter.offset}`);
    }

    const axiosResponse = await axios.get<
      ResponsePageable<RoyaltiesStoresSalesSummary>
    >(`/api/royalties/sales/stores?${urlParams.toString()}`);
    const responseData = axiosResponse.data;
    return responseData;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const fetchAllSalesGroupedByMonths = async (
  filter: SalesSummaryFilter,
): Promise<ResponsePageable<RoyaltiesMonthsSalesSummary>> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/royalties : fetchAllSalesGroupedByMonths',
      message: 'Call',
      type: 'info',
    });
    const urlParams = new URLSearchParams();
    if (filter.walletAddress) {
      urlParams.append('walletAddress', filter.walletAddress);
    }
    if (filter.months) {
      urlParams.append('months', `${filter.months}`);
    }
    if (filter.individual) {
      urlParams.append('individual', `${filter.individual}`);
    }
    filter.releaseUPCs.forEach(id => {
      urlParams.append('releaseUPCs', `${id}`);
    });
    if (filter.direction && filter.property) {
      urlParams.append('direction', `${filter.direction}`);
      urlParams.append('property', `${filter.property}`);
    }
    if (filter.limit !== undefined && filter.offset !== undefined) {
      urlParams.append('limit', `${filter.limit}`);
      urlParams.append('offset', `${filter.offset}`);
    }

    const axiosResponse = await axios.get<
      ResponsePageable<RoyaltiesMonthsSalesSummary>
    >(`/api/royalties/sales/months?${urlParams.toString()}`);
    const responseData = axiosResponse.data;

    return {
      ...responseData,
      items: responseData.items.map(it => ({
        ...it,
        month: parse(it.month.toString(), YEAR_MONTH_FORMAT, new Date()),
      })),
    };
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const fetchPayoutSummary = async (
  walletAddress: string,
): Promise<RoyaltiesPayoutSummary> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/royalties : fetchPayoutSummary',
      message: 'Call',
      type: 'info',
    });
    const axiosResponse = await axios.get<APIResponse<RoyaltiesPayoutSummary>>(
      `/api/royalties/${walletAddress}/payout`,
    );
    const responseData = axiosResponse.data.data;
    return {
      info: {
        usdcAsset: responseData.info.usdcAsset,
        availableBalance: MicroUSDC.create(responseData.info.availableBalance),
        totalEarned: MicroUSDC.create(responseData.info.totalEarned),
      },
      history: responseData.history.map(it => ({
        ...it,
        amount: MicroUSDC.create(it.amount),
        requestedOn: new Date(it.requestedOn),
      })),
    };
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const withdrawRoyalties = async (
  walletAddress: string,
): Promise<void> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/royalties : withdrawRoyalties',
      message: 'Call',
      type: 'info',
    });

    await axios.post<APIResponse<RoyaltiesPayoutSummary>>(
      `/api/royalties/${walletAddress}/payout/withdraw`,
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

type MakeOptInTransactionProps = {
  walletAddress: string;
  assetId: number;
  walletType?: WalletTypeEnum;
  connector?: WalletConnect;
};
export const makeOptInTransaction = async ({
  walletAddress,
  assetId,
}: MakeOptInTransactionProps): Promise<algosdk.Transaction> => {
  // Default Definitions to Opt-In Transaction
  Sentry.addBreadcrumb({
    category: 'services/vestings : optInToUSDC',
    message: 'Called',
    type: 'info',
    data: {
      walletAddress,
      assetId,
    },
  });
  const sender = walletAddress;
  const recipient = walletAddress;
  const amount = 0;

  const assetOptInTransaction = await makeAssetTransferTxn({
    fromAddress: sender,
    toAddress: recipient,
    assetId,
    amount,
  });
  return assetOptInTransaction;
};

type OptInProps = {
  transaction: algosdk.Transaction;
  walletType?: WalletTypeEnum;
  connector?: PeraWalletConnect;
};
export const optInToUSDC = async ({
  transaction,
  walletType,
  connector,
}: OptInProps): Promise<void> => {
  const assetOptInTransactionSigned = await signTransaction({
    walletType,
    connector,
    transaction,
  });

  Sentry.addBreadcrumb({
    category: 'services/vestings : optInToUSDC',
    message: 'Transaction signed',
    type: 'info',
    data: {
      walletType,
      transaction,
      connector,
      assetOptInTransactionSigned,
    },
  })
  await sendSignedTransactions([assetOptInTransactionSigned]);
}

export async function adminUploadRoyaltySalesCsvFile(displayName: string, csv: string) {
  const { data: { data: { url, fileKey } } } = await axios({
    method: 'GET',
    url: '/api/admin/royalties/sales-upload-url',
    headers: await getAdminAuthorizationHeader(),
  });
  await axios({
    method: 'PUT',
    url: url,
    data: csv,
  });
  await axios({
    method: 'POST',
    url: '/api/admin/royalties/royalties-sales-file',
    headers: await getAdminAuthorizationHeader(),
    data: {
      fileKey,
      displayName,
    },
  });
}

export interface IRoyaltiesSalesFile {
  fileName: string
  displayName?: string
  status: 'NEW'|'PROCESSED'
  createdAt: string
  updatedAt: string
  uploadedBy?: string
};

export async function adminGetRoyaltiesSalesFiles(
  page: number,
  pageSize: number,
): Promise<{
  count: number,
  data: IRoyaltiesSalesFile[],
}> {
  const { data: { data } } = await axios({
    method: 'GET',
    url: `/api/admin/royalties/admin-list-royalties-sales-files?${new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString(),
    }).toString()}`,
    headers: await getAdminAuthorizationHeader(),
  });
  return data;
}

export async function adminGetRoyaltiesSalesDownloadUrl(
  fileKey: string,
): Promise<string> {
  const { data: { data } } = await axios({
    method: 'GET',
    url: `/api/admin/royalties/sales-file/${encodeURIComponent(fileKey)}/download-url`,
    headers: await getAdminAuthorizationHeader(),
  });
  return data;
}
