import axios from 'axios';
import algosdk from 'algosdk';
import * as Sentry from '@sentry/react';
import {
  signTransaction,
  makeAssetTransferTxn,
  signVestingAppCallTransaction,
} from '@opulous/web/src/helpers/AlgorandHelper'
import {
  APIResponse,
  VestingsResponseData,
  WalletConnect,
  WalletTypeEnum,
  WithdrawTransactionsWithAppCallEncoded,
  WithdrawTransactionsWithAppCallToSign,
} from '@opulous/web/src/shared/types';
import MinorOPUL from '@opulous/web/src/shared/valueObjects/MinorOPUL';
import { PeraWalletConnect } from '@perawallet/connect';
import { sendSignedTransactions } from '../my-algo';

export const getAllAvailableContracts = async (
  walletAddress: string,
): Promise<VestingsResponseData> => {
  try {
    const axiosResponse = await axios.get<APIResponse<VestingsResponseData>>(
      `/api/vestings/${walletAddress}`,
    );
    const responseData = axiosResponse.data.data;
    return {
      contracts: responseData.contracts.map(it => ({
        ...it,
        preClaimed: it.preClaimed ? MinorOPUL.create(it.preClaimed) : undefined,
        totalOpul: MinorOPUL.create(it.totalOpul),
        lockedOpul: MinorOPUL.create(it.lockedOpul),
        claimedOpul: MinorOPUL.create(it.claimedOpul),
        toClaimOpul: MinorOPUL.create(it.toClaimOpul),
        dailyDistribution: MinorOPUL.create(it.dailyDistribution),
        startAt: new Date(it.startAt),
        endAt: new Date(it.endAt),
      })),
    };
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

type MakeOptInTransactionProps = {
  walletAddress: string;
  assetId: number;
  walletType?: WalletTypeEnum;
  connector?: WalletConnect;
};
export const makeOptInTransaction = async ({
  walletAddress,
  assetId,
}: MakeOptInTransactionProps): Promise<algosdk.Transaction> => {
  // Default Definitions to Opt-In Transaction
  Sentry.addBreadcrumb({
    category: 'services/vestings : optInToOPUL',
    message: 'Called',
    type: 'info',
    data: {
      walletAddress,
      assetId,
    },
  });
  const sender = walletAddress;
  const recipient = walletAddress;
  const amount = 0;

  const assetOptInTransaction = await makeAssetTransferTxn({
    fromAddress: sender,
    toAddress: recipient,
    assetId,
    amount,
  });
  return assetOptInTransaction;
};

type OptInProps = {
  transaction: algosdk.Transaction;
  walletType?: WalletTypeEnum;
  connector?: PeraWalletConnect;
};
export const optInToOPUL = async ({
  transaction,
  walletType,
  connector,
}: OptInProps): Promise<void> => {
  const assetOptInTransactionSigned = await signTransaction({
    walletType,
    connector,
    transaction,
  });

  Sentry.addBreadcrumb({
    category: 'services/vestings : optInToOPUL',
    message: 'Transaction signed',
    type: 'info',
    data: {
      walletType,
      transaction,
      connector,
      assetOptInTransactionSigned,
    },
  });

  await sendSignedTransactions([assetOptInTransactionSigned]);
};

type FetchWithdrawTransactionsWithAppCallToSignProps = {
  contractAddress: string;
  walletAddress: string;
};
export const fetchWithdrawTransactionsWithAppCallToSign = async ({
  contractAddress,
  walletAddress,
}: FetchWithdrawTransactionsWithAppCallToSignProps): Promise<WithdrawTransactionsWithAppCallToSign> => {
  try {
    const axiosResponse = await axios.get<
      APIResponse<WithdrawTransactionsWithAppCallEncoded>
    >(`/api/vestings/${walletAddress}/withdrawTransactions/${contractAddress}`);
    const withdrawTransactionsWithAppCallEncoded = axiosResponse.data.data;

    const appCallTransaction = algosdk.decodeUnsignedTransaction(
      new Uint8Array(
        withdrawTransactionsWithAppCallEncoded.appCallTransactionEncoded,
      ),
    );
    const withdrawTransactionsWithAppCallToSign = {
      appCallTransaction,
      assetTransferTransactionSigned:
        withdrawTransactionsWithAppCallEncoded.assetTransferTransactionSigned,
      defundTransactionSigned:
        withdrawTransactionsWithAppCallEncoded.defundTransactionSigned,
    } as WithdrawTransactionsWithAppCallToSign;

    return withdrawTransactionsWithAppCallToSign;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

type WithdrawAvailableTokensProps = {
  withdrawTransactionsWithAppCallToSign: WithdrawTransactionsWithAppCallToSign;
  walletType?: WalletTypeEnum;
  connector?: PeraWalletConnect;
};
export const withdrawAvaliableTokens = async ({
  withdrawTransactionsWithAppCallToSign,
  walletType,
  connector,
}: WithdrawAvailableTokensProps): Promise<void> => {
  try {
    Sentry.addBreadcrumb({
      category: 'services/vestings : withdrawAvaliableTokens',
      message: 'Call',
      type: 'info',
      data: {
        walletType,
        connector,
        withdrawTransactionsWithAppCallToSign,
      },
    })

    const transactions = [
      withdrawTransactionsWithAppCallToSign.appCallTransaction,
      algosdk.decodeSignedTransaction(
        new Uint8Array(withdrawTransactionsWithAppCallToSign.assetTransferTransactionSigned.blob)
      ).txn,
    ]
    if (withdrawTransactionsWithAppCallToSign.defundTransactionSigned) {
      transactions.push(
        algosdk.decodeSignedTransaction(
          new Uint8Array(withdrawTransactionsWithAppCallToSign.defundTransactionSigned.blob)
        ).txn
      )
    }
    const appCallTransactionSigned = await signVestingAppCallTransaction({
      walletType,
      connector,
      transactions,
    }) 

    Sentry.addBreadcrumb({
      category: 'services/vestings : withdrawAvaliableTokens',
      message: 'appCallTransactionSigned signed',
      type: 'info',
      data: {
        walletType,
        connector,
        withdrawTransactionsWithAppCallToSign,
        appCallTransactionSigned,
      },
    });

    const withdrawTransactionsSigned = [
      appCallTransactionSigned,
      {
        txID: withdrawTransactionsWithAppCallToSign.assetTransferTransactionSigned.txID,
        blob: new Uint8Array(withdrawTransactionsWithAppCallToSign.assetTransferTransactionSigned.blob),
      },
    ];
    if (withdrawTransactionsWithAppCallToSign.defundTransactionSigned) {
      withdrawTransactionsSigned.push(
        {
          txID: withdrawTransactionsWithAppCallToSign.defundTransactionSigned.txID,
          blob: new Uint8Array(withdrawTransactionsWithAppCallToSign.defundTransactionSigned.blob),
        },
      );
    }
    Sentry.addBreadcrumb({
      category: 'services/vestings : withdrawAvaliableTokens',
      message: 'withdrawTransactions signed',
      type: 'info',
      data: {
        walletType,
        connector,
        withdrawTransactionsWithAppCallToSign,
        appCallTransactionSigned,
        withdrawTransactionsSigned,
      },
    });

    await sendSignedTransactions(withdrawTransactionsSigned);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
