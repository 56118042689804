import algosdk from 'algosdk';
import { Dispatch } from 'react';
import * as Sentry from '@sentry/react';
import {
  FilterSelected,
  RoyaltiesSalesFullOverviewDefault,
  TableTabOption,
  TabOption,
} from './types';
import * as RoyaltiesService from '@opulous/web/src/services/royalties';
import {
  IWalletContextState,
  RoyaltiesSalesFilter,
  RoyaltiesSalesFullOverview,
} from '@opulous/web/src/shared/types';
import { isSignTransactionCancelledError } from '@opulous/web/src/utils';

export type Action<T> = {
  type: T;
  payload?: any; //eslint-disable-line
};

export enum RoyaltiesActionType {
  SetTabLoading,
  SetLoadingOverview,
  SetProcessingWithdraw,
  SetProcessingOptin,
  SetShowAlert,
  ChangeTabSelected,
  ChangeFilterSelected,
  LoadSalesSummaryData,
  LoadSalesFullOverview,
  LoadPayoutData,
  SetUSDCOptedIn,
}

export type RoyaltiesActions = {
  setTabLoading: (status: boolean) => void;
  setLoadingOverview: (status: boolean) => void;
  setProcessingOptin: (status: boolean) => void;
  setProcessingWithdraw: (status: boolean) => void;
  setShowAlertError: (status: boolean, message?: string) => void;
  setShowAlertSuccess: (message: string) => void;
  changeTabSelected: (newTab: string) => void;
  changeFilterSelected: (newFilterSelected: FilterSelected) => void;
  loadSalesSummaryData: (
    wallet: string,
    royaltiesSalesFilter: RoyaltiesSalesFilter,
    filter: FilterSelected,
  ) => void;
  loadSalesFullOverview: (
    wallet: string,
    filter: FilterSelected,
    tableTabOptionSelected: TableTabOption,
  ) => void;
  loadPayoutData: (walletState: IWalletContextState) => void;
  optInUSDC: (
    walletState: IWalletContextState,
    transaction: algosdk.Transaction,
  ) => void;
  withdrawRoyalties: (walletState: IWalletContextState) => void;
};
export function useActions(
  dispatch: Dispatch<Action<RoyaltiesActionType>>,
): RoyaltiesActions {
  const actions = {
    setTabLoading: (status: boolean) => {
      dispatch({ type: RoyaltiesActionType.SetTabLoading, payload: status });
    },
    setLoadingOverview: (status: boolean) => {
      dispatch({
        type: RoyaltiesActionType.SetLoadingOverview,
        payload: status,
      });
    },
    setProcessingOptin: (status: boolean) => {
      dispatch({
        type: RoyaltiesActionType.SetProcessingOptin,
        payload: status,
      });
    },
    setProcessingWithdraw: (status: boolean) => {
      dispatch({
        type: RoyaltiesActionType.SetProcessingWithdraw,
        payload: status,
      });
    },
    setShowAlertError: (status: boolean) => {
      dispatch({
        type: RoyaltiesActionType.SetShowAlert,
        payload: status ? { error: true } : undefined,
      });
    },
    setShowAlertSuccess: (message: string) => {
      dispatch({
        type: RoyaltiesActionType.SetShowAlert,
        payload: { success: true, message },
      });
    },
    changeTabSelected: (newTab: string) => {
      dispatch({
        type: RoyaltiesActionType.ChangeTabSelected,
        payload: newTab,
      });
    },
    changeFilterSelected: (newFilterSelected: FilterSelected) => {
      dispatch({
        type: RoyaltiesActionType.ChangeFilterSelected,
        payload: newFilterSelected,
      });
    },
    loadSalesSummaryData: (
      walletAddress: string,
      royaltiesSalesFilter: RoyaltiesSalesFilter,
      filter: FilterSelected,
    ) => {
      (async () => {
        try {
          actions.setTabLoading(true);
          const preparedFilter = {
            walletAddress,
            individual: filter.individual,
            months: filter.months,
            releaseUPCs: filter.releaseUPCs,
          };
          const responseSummary = await RoyaltiesService.fetchSalesSummary(
            preparedFilter,
          );
          const newReleaseUPCs = filter.releaseUPCs.filter((id: string) =>
            responseSummary.releases.some(it => it.id === id),
          );

          const newRoyaltiesSalesFilter = {
            ...royaltiesSalesFilter,
            release: [
              { label: 'All releases' },
              ...responseSummary.releases.map(it => ({
                label: it.title,
                value: it.id,
              })),
            ],
          };
          dispatch({
            type: RoyaltiesActionType.LoadSalesSummaryData,
            payload: {
              newSalesFilterSelected: {
                ...filter,
                releaseUPCs: newReleaseUPCs,
              },
              newRoyaltiesSalesFilter,
              countriesSalesSummary: responseSummary.countriesSalesSummary,
              storesSalesSummary: responseSummary.storesSalesSummary,
              releasesSalesSummary: responseSummary.releasesSalesSummary,
              monthsSalesSummary: responseSummary.monthsSalesSummary,
            },
          });
        } catch (error) {
          Sentry.captureException(error);
          actions.setShowAlertError(true);
        } finally {
          actions.setTabLoading(false);
        }
      })();
    },
    loadSalesFullOverview: (
      walletAddress: string,
      filter: FilterSelected,
      tableTabOptionSelected: TableTabOption,
    ) => {
      (async () => {
        try {
          actions.setLoadingOverview(true);
          const preparedFilter = {
            walletAddress,
            individual: filter.individual,
            months: filter.months,
            releaseUPCs: filter.releaseUPCs,
            direction: tableTabOptionSelected.direction?.toUpperCase(),
            property: tableTabOptionSelected.column,
            limit: tableTabOptionSelected.limit,
            offset: tableTabOptionSelected.offset,
          };

          const newRoyaltiesSalesFullOverview = {
            ...RoyaltiesSalesFullOverviewDefault,
          } as RoyaltiesSalesFullOverview;
          switch (tableTabOptionSelected.tab) {
            case TabOption.RELEASES:
              newRoyaltiesSalesFullOverview.releases =
                await RoyaltiesService.fetchAllSalesGroupedByReleases(
                  preparedFilter,
                );
              break;
            case TabOption.COUNTRIES:
              newRoyaltiesSalesFullOverview.countries =
                await RoyaltiesService.fetchAllSalesGroupedByCountries(
                  preparedFilter,
                );
              break;
            case TabOption.STORES:
              newRoyaltiesSalesFullOverview.stores =
                await RoyaltiesService.fetchAllSalesGroupedByStores(
                  preparedFilter,
                );
              break;
            case TabOption.MONTHS:
              newRoyaltiesSalesFullOverview.months =
                await RoyaltiesService.fetchAllSalesGroupedByMonths(
                  preparedFilter,
                );
              break;
            default:
              break;
          }

          dispatch({
            type: RoyaltiesActionType.LoadSalesFullOverview,
            payload: {
              newFullOverviewOptionSelected: tableTabOptionSelected,
              newRoyaltiesSalesFullOverview,
            },
          });
        } catch (error) {
          Sentry.captureException(error);
          actions.setShowAlertError(true);
        } finally {
          actions.setLoadingOverview(false);
        }
      })();
    },
    loadPayoutData: (walletState: IWalletContextState) => {
      (async () => {
        try {
          actions.setTabLoading(true);
          const response = await RoyaltiesService.fetchPayoutSummary(
            walletState.wallet,
          );
          const payload = {
            newRoyaltiesPayoutInfo: response.info,
            newRoyaltiesPayoutHistory: response.history,
          } as any;
          if (!response.info.usdcAsset.optedIn) {
            payload.newOptInUSDCTransaction =
              await RoyaltiesService.makeOptInTransaction({
                walletAddress: walletState.wallet,
                assetId: response.info.usdcAsset.id,
              });
          }

          dispatch({
            type: RoyaltiesActionType.LoadPayoutData,
            payload,
          });
        } catch (error) {
          Sentry.captureException(error);
          actions.setShowAlertError(true);
        } finally {
          actions.setTabLoading(false);
        }
      })();
    },
    optInUSDC: (
      walletState: IWalletContextState,
      transaction: algosdk.Transaction,
    ) => {
      (async () => {
        try {
          actions.setProcessingOptin(true);
          await RoyaltiesService.optInToUSDC({
            transaction,
            walletType: walletState.walletType,
            connector: walletState.connector,
          });
          dispatch({ type: RoyaltiesActionType.SetUSDCOptedIn });
        } catch (error) {
          if (!isSignTransactionCancelledError(error)) {
            Sentry.captureException(error);
            actions.setShowAlertError(true);
          }
        } finally {
          actions.setProcessingOptin(false);
        }
      })();
    },
    withdrawRoyalties: (walletState: IWalletContextState) => {
      (async () => {
        try {
          actions.setProcessingWithdraw(true);
          await RoyaltiesService.withdrawRoyalties(walletState.wallet);
          await new Promise(r => setTimeout(r, 5000));
          actions.setShowAlertSuccess(
            'Congratulations! You have successfully withdrawn your rewards.',
          );
          actions.loadPayoutData(walletState);
        } catch (error) {
          Sentry.captureException(error);
          actions.setShowAlertError(true);
        } finally {
          actions.setProcessingWithdraw(false);
        }
      })();
    },
  };
  return actions;
}
