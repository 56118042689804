import { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import { InvestmentDetails } from '@opulous/web/src/shared/types';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
  PreviousReleaseSectionContainer,
  PreviousReleaseTable,
  RewardsShareContainer,
  SectionContainer,
  SectionTitle,
  StatsContainer,
  StatsLegend,
  StatsWrapper,
  StyledSection,
} from '@opulous/web/src/pages/Sales/Investment/styled-components';
import RewardsShareCalculator from '@opulous/web/src/components/Sales/RewardsShareCalculator';
import {
  AudienceIcon,
  PlaysIcon,
} from '@opulous/web/src/components/Sales/Icons';
import {
  formatCurrency,
  formatNumber,
  formatYear,
} from '@opulous/web/src/utils';
import InvestmentExclusivePerks from '@opulous/web/src/components/Sales/InvestmentExclusivePerks';
import InvestmentIncludedCatalog from '@opulous/web/src/components/Sales/InvestmentIncludedCatalog';

type Props = {
  investment: InvestmentDetails;
};

export function RewardsCalculator({ investment }: Props): ReactElement {
  return (
    <StyledSection>
      <SectionTitle data-testid="investment-details-page__about__rewards-share__title">
        Rewards calculator
      </SectionTitle>
      <SectionContainer>
        <RewardsShareContainer>
          <RewardsShareCalculator investment={investment} />
        </RewardsShareContainer>
      </SectionContainer>
    </StyledSection>
  );
}

export function RewardsAPY({ investment }: Props): ReactElement {
  if (investment.rewardsApy) {
    return (
      <StyledSection>
        <SectionTitle data-testid="investment-details-page__about__rewards-apy__title">
          Rewards
        </SectionTitle>
        <StatsWrapper>
          <SectionContainer>
            <StatsContainer>
              <AudienceIcon data-testid="investment-details-page__about__rewards-apy__card__icon" />
              <StatsLegend>
                <strong data-testid="investment-details-page__about__rewards-apy__card__value">
                  {investment.rewardsApy}
                </strong>
                <small data-testid="investment-details-page__about__rewards-apy__card__title">
                  Projected Rewards APY
                </small>
              </StatsLegend>
            </StatsContainer>
          </SectionContainer>
        </StatsWrapper>
      </StyledSection>
    );
  }
  return <></>;
}

export function ArtistStats({ investment }: Props): ReactElement {
  if (investment.spotifyStats && investment.instagramStats) {
    return (
      <StyledSection>
        <SectionTitle data-testid="investment-details-page__about__artist-stats__title">
          Stats
        </SectionTitle>
        <StatsWrapper>
          <SectionContainer>
            <StatsContainer>
              <AudienceIcon data-testid="investment-details-page__about__artist-stats__audience__icon" />
              <StatsLegend>
                <strong data-testid="investment-details-page__about__artist-stats__audience__value">
                  {formatCurrency(investment?.spotifyStats || 0, '', 0)}
                </strong>
                <small data-testid="investment-details-page__about__artist-stats__audience__title">
                  Total Audience
                </small>
              </StatsLegend>
            </StatsContainer>
          </SectionContainer>
          <SectionContainer>
            <StatsContainer>
              <PlaysIcon data-testid="investment-details-page__about__artist-stats__plays__icon" />
              <StatsLegend>
                <strong data-testid="investment-details-page__about__artist-stats__plays__value">
                  {formatCurrency(investment?.instagramStats || 0, '', 0)}
                </strong>
                <small data-testid="investment-details-page__about__artist-stats__plays__title">
                  Total Songs
                </small>
              </StatsLegend>
            </StatsContainer>
          </SectionContainer>
        </StatsWrapper>
      </StyledSection>
    );
  }
  return <></>;
}

export function AboutArtist({ investment }: Props): ReactElement {
  return (
    <StyledSection>
      <SectionTitle data-testid="investment-details-page__about__about-the-artist__title">
        About
      </SectionTitle>
      <SectionContainer>
        <ReactMarkdown>{investment?.aboutTheArtistContent || ''}</ReactMarkdown>
      </SectionContainer>
    </StyledSection>
  );
}

export function ExclusivePerks({ investment }: Props): ReactElement {
  if (investment.exclusivePerks?.length) {
    return (
      <StyledSection>
        <SectionTitle data-testid="investment-details-page__about__exclusive-perks__title">
          Exclusive perks
        </SectionTitle>
        <InvestmentExclusivePerks investment={investment} />
      </StyledSection>
    );
  }
  return <></>;
}

export function IncludedCatalog({ investment }: Props): ReactElement {
  if (investment.includedCatalog?.length) {
    return (
      <StyledSection>
        <SectionTitle data-testid="investment-details-page__about__included-catalog__title">
          Included Music
        </SectionTitle>
        <InvestmentIncludedCatalog investment={investment} />
      </StyledSection>
    );
  }
  return <></>;
}

export function Highlights({ investment }: Props): ReactElement {
  return (
    <StyledSection>
      <SectionTitle data-testid="investment-details-page__about__highlights__title">
        Highlights
      </SectionTitle>
      <SectionContainer>
        <ReactMarkdown data-testid="investment-details-page__about__highlights__content">
          {investment?.highlightsContent || ''}
        </ReactMarkdown>
      </SectionContainer>
    </StyledSection>
  );
}

export function PreviousReleases({ investment }: Props): ReactElement {
  if (investment.previousReleases?.length) {
    return (
      <StyledSection>
        <SectionTitle data-testid="investment-details-page__about__previous-releases__title">
          Previous releases
        </SectionTitle>
        <PreviousReleaseSectionContainer>
          <PreviousReleaseTable data-testid="investment-details-page__about__previous-releases__table">
            <TableHead>
              <TableRow data-testid="investment-details-page__about__previous-releases__table__head">
                <TableCell>Track</TableCell>
                <TableCell>Release date</TableCell>
                <TableCell>Overall streams</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {investment?.previousReleases.map(it => (
                <TableRow
                  data-testid="investment-details-page__about__previous-releases__table__row"
                  key={it.track}
                >
                  <TableCell>{it.track}</TableCell>
                  <TableCell>{formatYear(it.releaseDate)}</TableCell>
                  <TableCell>{formatNumber(it.overallStreams)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </PreviousReleaseTable>
        </PreviousReleaseSectionContainer>
      </StyledSection>
    );
  }
  return <></>;
}
