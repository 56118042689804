import { ReactElement } from 'react';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { IContentTimeLineItem } from '@opulous/web/src/shared/types';

interface ContentTimeLineProps {
  items: IContentTimeLineItem[];
}

const Index = styled('div')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '21px',
  background: theme.palette.primary.main,
  padding: 5,
  borderRadius: '50%',
  width: 20,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  fontWeight: 'bold',
  marginRight: 8,
}));

const Description = styled(Typography)(() => ({
  fontWeight: 300,
  fontSize: 14,
  lineHeight: '21px',
}));

const TimelineWrapper = styled('div')(() => ({
  marginBottom: 8,
  display: 'flex',
}));

const TimelineContainer = styled('div')(() => ({
  marginBottom: 24,
}));

const ContentTimeLine = ({ items }: ContentTimeLineProps): ReactElement => {
  return (
    <TimelineContainer>
      {items.map(({ description }, index) => {
        return (
          <TimelineWrapper>
            <Index>{index + 1}</Index>
            <Description>{description}</Description>
          </TimelineWrapper>
        );
      })}
    </TimelineContainer>
  );
};

export default ContentTimeLine;
