import React from 'react';

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark',
}

const ThemeColorModeContext = React.createContext<{
  toggle: () => void,
  mode: ThemeMode,
}>({
  toggle: () => { return },
  mode: ThemeMode.LIGHT,
});

export default ThemeColorModeContext;
