import React, { useState } from 'react';
import { styled } from '@mui/system';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Grid } from '@mui/material';
import { Skeleton } from '@mui/material';
import Card from '@mui/material/Card';

import TabBar from '@opulous/web/src/components/TabBar';
import RoyaltiesTab from '@opulous/web/src/components/Assets/Songs/RoyaltiesTab';
import SongDetailTab from '@opulous/web/src/components/Assets/Songs/SongDetailTab';
import { Asset } from '@opulous/web/src/shared/types';

const Section = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3.5),
}));

const StyledTabPanel = styled(TabPanel)(() => ({
  padding: 0,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.grey['A100'],
  marginTop: theme.spacing(4),
}));

const WrappedCard = styled(StyledCard)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const tabs = [
  {
    label: 'Description',
    value: 'description',
  },
  // {
  //   label: 'Royalties',
  //   value: 'royalties',
  // },
  // {
  //   label: 'Analytics',
  //   value: 'analytics',
  // },
];

interface SongDescriptionProps {
  asset: Asset;
  loading?: boolean;
}

const SongDescription: React.FC<SongDescriptionProps> = ({
  asset,
  loading,
}) => {
  const [tab, setTab] = useState('description');

  return !loading ? (
    <TabContext value={tab}>
      <TabBar tabs={tabs} onChangTab={setTab} />

      <StyledTabPanel value="description">
        <SongDetailTab asset={asset} />
      </StyledTabPanel>
      <StyledTabPanel value="analytics">
        <SongDetailTab isAnalytic asset={asset} />
      </StyledTabPanel>
      <StyledTabPanel value="royalties">
        <RoyaltiesTab />
      </StyledTabPanel>
    </TabContext>
  ) : (
    <TabContext value={tab}>
      <TabBar tabs={tabs} onChangTab={setTab} />

      <StyledTabPanel value="description">
        <Grid container spacing={4}>
          <Grid item xl={8} lg={12}>
            <Section>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Section>
          </Grid>
          <Grid item xl={4} lg={12}>
            <WrappedCard>
              <Skeleton variant="text" />
            </WrappedCard>
          </Grid>
        </Grid>
      </StyledTabPanel>
    </TabContext>
  );
};

export default SongDescription;
