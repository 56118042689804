import MyAlgo, { SignedTx } from '@randlabs/myalgo-connect';
import axios, { AxiosResponse } from 'axios';
import {
  AlgoAsset,
  Asset,
  WalletTypeEnum,
} from '@opulous/web/src/shared/types';
import algosdk from 'algosdk';

import * as Sentry from '@sentry/react';

const AssetUrl = '/assets';
// swap with /proxy on BE

import { getApiBaseUrl } from '@opulous/web/src/axios-setup';
import { PeraWalletConnect } from '@perawallet/connect';
import { signMultipleTransactionsWithPeraWallet } from 'src/helpers/AlgorandHelper';
import * as AssetService from '@opulous/web/src/services/assets';
import config from 'src/config';

const getAlgoClient = () => {
  const baseUrl = getApiBaseUrl();
  const algoPort = baseUrl.indexOf('localhost') >= 0 ? '4000' : '';
  const algodClient = new algosdk.Algodv2(
    '',
    `${baseUrl}/proxy/rewrite`,
    algoPort,
  );

  return algodClient;
};
export const connect = async (): Promise<string[] | undefined> => {
  try {
    const myAlgoWallet = new MyAlgo();
    const accounts = await myAlgoWallet.connect({
      shouldSelectOneAccount: true,
    });

    const addresses = accounts.map(account => account.address);
    return addresses;
  } catch (err) {
    // console.error(err)
    // sentry stuff
    // log error
  }
};

export const getUserAssets = async (wallet: string): Promise<Asset[]> => {
  return await axios
    .get(`${AssetUrl}/${wallet}`)
    .then(res => {
      return res.data;
    })
    .catch(() => []);
};

export interface IAccountInformationAsset {
  amount: number;
  'asset-id': number;
}

export interface IAccountInformationResponse {
  address: string;
  amount: number;
  assets: IAccountInformationAsset[];
}

export const getAccountInformation = async (
  wallet: string,
): Promise<IAccountInformationResponse> => {
  return await axios
    .get(`/proxy/v2/accounts/${wallet}`)
    .then(res => {
      return res.data;
    })
    .catch(() => ({}));
};

export const getOpulAssetCount = async (wallet: string): Promise<number> => {
  const accountInformation = await getAccountInformation(wallet);

  if (!accountInformation || !accountInformation.assets) {
    return 0;
  }

  const opulAsset = accountInformation.assets.find(
    x => x['asset-id'] === config.env.ALGORAND_OPUL_ASSET_ID,
  );
  if (!opulAsset) {
    return 0;
  }

  return opulAsset.amount;
};

export const getAssetsOwnedByWallet = async (
  wallet: string,
): Promise<number[]> => {
  const assetIds: number[] = [];
  if (!wallet) {
    return assetIds;
  }

  const { assets } = (await getAccountInformation(wallet)) || {};
  const userAssets = assets || [];
  return userAssets.map(a => a['asset-id']);
};

interface OptInProps {
  assets: number[];
  wallet: string;
  walletType?: WalletTypeEnum;
  connector: PeraWalletConnect;
}

export async function signTransactions(
  transactions: algosdk.Transaction[],
  connector: PeraWalletConnect,
  walletType?: WalletTypeEnum,
) {
  try {
    if (walletType === WalletTypeEnum.myAlgoWallet) {
      const myAlgoWallet = new MyAlgo();
      const signedTxns = await myAlgoWallet.signTransaction(
        transactions.map(txn => txn.toByte()),
      );
      return signedTxns;
    } else if (walletType === WalletTypeEnum.peraWallet) {
      const results = await signMultipleTransactionsWithPeraWallet(
        transactions,
        connector,
      );
      if (!results.length) {
        throw new Error('Empty result from Pera wallet transaction signing');
      }
      return results;
    } else {
      throw new Error(`Unknown wallet type "${walletType}"`);
    }
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
}

export async function sendSignedTransactions(transactions: SignedTx[]): Promise<
  AxiosResponse<{
    result: { txId: string };
    txs: string[];
  }>
> {
  return axios.post('/proxy/v2/transaction', {
    txns: JSON.stringify(
      transactions.map(x => ({
        ...x,
        blob: Array.from(x.blob),
      })),
    ),
  });
}

export async function waitForTransactions(txIds: string[]) {
  return axios({
    method: 'POST',
    url: '/proxy/v2/transaction/wait',
    data: { txIds },
    timeout: 30000,
  });
}

export const optIn = async ({
  assets,
  wallet,
  connector,
  walletType,
}: OptInProps): Promise<void> => {
  // Unhandled Rejection (PopupOpenError): Can not open popup window - blocked
  const algodClient = getAlgoClient();
  const params = await algodClient.getTransactionParams().do();
  const txns = assets.map(assetId => {
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      suggestedParams: {
        ...params,
      },
      from: wallet,
      to: wallet,
      assetIndex: assetId,
      amount: 0,
      note: new Uint8Array(Buffer.from(`${assetId}-${Date.now().toString()}`)),
    });
    return txn;
  });

  const groupID = algosdk.computeGroupID(txns);
  for (let i = 0; i < txns.length; i++) {
    txns[i].group = groupID;
  }
  let signedTxns: SignedTx[] = [];
  if (walletType === WalletTypeEnum.myAlgoWallet) {
    const myAlgoWallet = new MyAlgo();
    signedTxns = await myAlgoWallet.signTransaction(
      txns.map(txn => txn.toByte()),
    );
    await sendSignedTransactions(signedTxns);
  } else if (walletType === WalletTypeEnum.peraWallet) {
    try {
      const results = await signMultipleTransactionsWithPeraWallet(
        txns,
        connector,
      );
      if (!results.length) {
        throw new Error('Empty result from Pera wallet transaction signing');
      }
      await sendSignedTransactions(results);
      return;
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
  }
};

export async function makeOptInTransactions({
  assets,
  wallet,
}: Omit<OptInProps, 'connector'>): Promise<algosdk.Transaction[]> {
  const algodClient = getAlgoClient();
  const params = await algodClient.getTransactionParams().do();
  const txns = assets.map(assetId => {
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      suggestedParams: {
        ...params,
      },
      from: wallet,
      to: wallet,
      assetIndex: assetId,
      amount: 0,
      note: new Uint8Array(Buffer.from(`${assetId}`)),
    });
    return txn;
  });

  const groupID = algosdk.computeGroupID(txns);
  for (let i = 0; i < txns.length; i++) {
    txns[i].group = groupID;
  }
  return txns;
}

export async function getAssetDetailsOwnedByWallet(input: {
  assetId: number;
  unitName: string;
  walletAddress: string;
}): Promise<AlgoAsset | undefined> {
  if (!input.walletAddress) {
    return;
  }
  try {
    const assetMetadata = await AssetService.fetchAssetMetaData(input.unitName);
    const accountInformation = await getAccountInformation(input.walletAddress);
    const asset = (accountInformation.assets || []).find(
      it => it['asset-id'] === input.assetId,
    );
  
    if (asset) {
      return {
        assetId: asset['asset-id'],
        amount: Math.round(asset.amount / Math.pow(10, assetMetadata.decimals || 0)),
      };
    }
  } catch (err) {
    Sentry.captureException(err);
  }
  return;
}

export default {
  connect,
  getUserAssets,
  optIn,
  getAccountInformation,
  getOpulAssetCount,
  makeOptInTransactions,
  signTransactions,
  sendSignedTransactions,
  waitForTransactions,
};
