import React, { ReactNode } from 'react';
import { styled } from '@mui/system';

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.5, 2),
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(0.8, 0),
}));

const Field = styled('span')(({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.palette.grey[500],
  marginRight: theme.spacing(1),
  fontSize: theme.spacing(1.6),
  fontWeight: 600,
}));

const ValueSpan = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(1.8),
}));

interface MobileTableRowProps {
  data?: {
    [key: string]: string | number | ReactNode;
  };
  action?: ReactNode;
}

const MobileTableRow: React.FC<MobileTableRowProps> = ({ data, action }) => {
  return (
    <Wrapper>
      {data &&
        Object.entries(data).map(([field, value]) => (
          <Row key={field}>
            <Field>{field}</Field>
            {typeof value === 'object' ? value : <ValueSpan>{value}</ValueSpan>}
          </Row>
        ))}
      {Boolean(action) && <Row>{action}</Row>}
    </Wrapper>
  );
};

export default MobileTableRow;
