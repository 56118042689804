import { ReactElement } from 'react';
import { styled } from '@mui/system';
import { Table, Paper, Typography, CircularProgress, Card, Grid } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import TabPanel from '@mui/lab/TabPanel';
import { InvestmentDetails } from '@opulous/web/src/shared/types';

export const GridSticky = styled(Grid)(() => ({
  position: 'sticky',
  top: '48px',
  right: '0px',
  marginTop: '46px',
  width: '100%',
  maxHeight: 'calc(100vh - 150px)',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  '&.MuiGrid-item': {
    paddingTop: '2px',
    paddingRight: '2px',
    paddingBottom: '10px',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const DetailsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(3),
}));

export const StyledHeader = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(1.6),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(0.6),
  },
}));
export const Details = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}));
export const TitleContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  marginLeft: theme.spacing(2),
}));
export const SaleTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  fontSize: theme.spacing(3.8),
  lineHeight: '110%',
  div: {
    maxWidth: 'calc(100% - 120px)',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100% - 72px)',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  img: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(3.2),
    height: theme.spacing(3.2),
  },
  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(3.5),
    lineHeight: '150%',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(2.5),
    lineHeight: '150%',
  },
}));
export const SaleSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'normal',
  fontSize: theme.spacing(2.3),
  lineHeight: '110%',
}));
export const Thumbnail = styled('img')(({ theme }) => ({
  width: theme.spacing(8.2),
  height: theme.spacing(8.2),
  borderRadius: theme.spacing(2),
}));
export const StatusAndActions = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1.6),
  },
}));
export const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: theme.spacing(1.6),
  '& > button': {
    marginLeft: theme.spacing(0.8),
    marginRIght: theme.spacing(0.8),
  }
}));

export const VideoPlayer = styled('iframe')(({ theme }) => ({
  marginTop: theme.spacing(1.2),
  background: 'transparent',
  border: 'none',
  width: '100%',
  height: theme.spacing(53),
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vw * 0.5)',
  },
}));

export const InfoSummary = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3, 4),
}));
export const InfoSummaryTitle = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.spacing(3.4),
  lineHeight: '150%',
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
}));
export const InfoSummaryEntry = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: theme.spacing(1),
  color: theme.palette.text.primary,
}));

export const SidebarDetailsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3.2),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(4),
  },
}));
export const InfoFunds = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(3, 4),
}));
export const InfoRaised = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.spacing(4.4),
  lineHeight: '150%',
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
}));
export const InfoGoal = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.spacing(2),
  lineHeight: '150%',
  paddingTop: theme.spacing(1),
  color: theme.palette.text.secondary,
  strong: {
    color: theme.palette.text.primary,
    fontSize: theme.spacing(2),
    lineHeight: '150%',
  },
}));
export const InfoInvestors = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.spacing(2),
  lineHeight: '150%',
  paddingTop: theme.spacing(1),
  color: theme.palette.text.secondary,
  strong: {
    color: theme.palette.text.primary,
    fontSize: theme.spacing(2),
    lineHeight: '150%',
  },
}));

export const InfoStepper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(3, 4),
}));
export const InfoCounter = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(3, 4),
}));

export const StyledTabWrapper = styled('div')<{ isMobile?: boolean }>(({ isMobile, theme }) => ({
  marginTop: isMobile ? theme.spacing(0) : theme.spacing(4),
}));
export const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: theme.spacing(3, 0),
}));
export const StyledSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: theme.spacing(5),
  textAlign: 'justify',
  textJustify: 'inter-word',
}));
export const SectionTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  fontSize: theme.spacing(3.5),
  lineHeight: '110%',
}));
export const SectionContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(4),
  'h1, h2, h3, h4, h5, h6, p, a, li, span': {
    lineHeight: '125%',
  },
  p: {
    marginTop: theme.spacing(1.4),
  },
  'ol > li, ul > li': {
    lineHeight: '150%',
    textAlign: 'justify',
    textJustify: 'inter-word',
    marginBottom: theme.spacing(1.4),
    marginLeft: `-${theme.spacing(2.8)}`,
    [theme.breakpoints.down('md')]: {
      marginLeft: `-${theme.spacing(3.4)}`,
    },
  },
  'p > img': {
    width: '100%',
    borderRadius: theme.spacing(2),
  },
}));
export const SectionDate = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

export const StatsWrapper = styled('div')<{ def?: string }>(({ def, theme }) => ({
  display: 'grid',
  gridTemplateColumns: def || '1fr 1fr',
  gap: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: def || '1fr',
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down('xl')]: {
    marginTop: theme.spacing(1.5),
  },
}));
export const StatsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}));
export const StatsLegend = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(2),
}));

export const RewardsShareContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: theme.spacing(5),
  lineHeight: theme.spacing(5),
  fontWeight: '900',
}));
export const StyledButton = styled('button')<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: theme.spacing(28),
    width: theme.spacing(22),
    height: theme.spacing(6),
    borderRadius: theme.spacing(6),
    backgroundColor: disabled
      ? theme.palette.grey[300]
      : theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: theme.spacing(2),
    fontWeight: '600',
    border: 'none',
    outline: 'none',
    marginTop: theme.spacing(1.5),
    cursor: 'pointer',
  }),
);

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  marginLeft: '1rem',
  svg: {
    color: theme.palette.primary.contrastText,
  },
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(19),
  width: '100%',
  maxWidth: theme.spacing(50),
  position: 'relative',
  borderRadius: theme.spacing(2),
  cursor: 'pointer',
  minHeight: theme.spacing(8),
  height: theme.spacing(60),
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    borderRadius: theme.spacing(1.5),
  },
}));
export const StyledMedia = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2.5, 2.5, 1, 2.5),
}));

export const StyledImage = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  width: `100%`,
  borderRadius: theme.spacing(2),
}));
export const CardDetailsContainer = styled('div')<{ collapsed?: boolean }>(
  ({ collapsed, theme }) => ({
    position: 'absolute',
    width: '100%',
    bottom: collapsed ? `-${theme.spacing(36)}` : '0',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
    transition: 'bottom 1s',
    backgroundColor: theme.palette.common.white,
  }),
);
export const CardTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: theme.spacing(3),
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));
export const CardArtist = styled(Typography)(({ theme }) => ({
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: theme.spacing(2),
  fontWeight: 'normal',
}));
type InvestmentDetailsCardProps = {
  investment: InvestmentDetails;
}
export function InvestmentDetailsCard({ investment }: InvestmentDetailsCardProps): ReactElement {
  return (
    <StyledCard data-testid="investment-details-card">
      <StyledMedia data-testid="investment-details-card__media">
        <StyledImage
          data-testid="investment-details-card__media__image"
          src={investment?.asset?.nftDescription?.image}
        />
      </StyledMedia>
      <CardDetailsContainer>
        <CardTitle data-testid="investment-details-card__details__title">
          {investment?.saleTitle}
        </CardTitle>
        <CardArtist data-testid="investment-details-card__details__subtitle">
          {investment?.saleSubtitle}
        </CardArtist>
      </CardDetailsContainer>
    </StyledCard>
  );
}

export const PreviousReleaseSectionContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0.5),
  },
}));
export const PreviousReleaseTable = styled(Table)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    thead: {
      'tr > th': {
        fontSize: theme.spacing(1.8),
        lineHeight: theme.spacing(2.2),
      },
    },
    tbody: {
      'tr td': {
        fontSize: theme.spacing(1.8),
        lineHeight: theme.spacing(2.2),
      },
    },
  },
  thead: {
    'tr > th': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    'tr th:nth-of-type(2)': {
      textAlign: 'center',
    },
    'tr th:nth-of-type(3)': {
      textAlign: 'right',
    },
  },
  tbody: {
    'tr td': {
      border: 'none',
    },
    'tr td:nth-of-type(1)': {
      fontWeight: 'normal',
    },
    'tr td:nth-of-type(2)': {
      fontWeight: 'bold',
      opacity: '0.4',
      textAlign: 'center',
    },
    'tr td:nth-of-type(3)': {
      textAlign: 'right',
      fontWeight: 'bold',
    },
  },
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1, 2),
  },
}));
