import { ReactElement } from 'react';
import { Paper, Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/system';

const Wrapper = styled(Paper)(() => ({
  padding: 0,
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const InfoContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));
const LegendContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  paddingBottom: theme.spacing(1),
}));
const ActionContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(1),
}));

export default function CardSkeleton(): ReactElement {
  return (
    <Wrapper data-testid="vesting-card-skeleton">
      <InfoContainer>
        <LegendContainer container spacing={3}>
          <Grid item lg={4} md={7} xs={7}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item lg={3} md={5} xs={5}>
            <Skeleton variant="text" height={70} />
          </Grid>
        </LegendContainer>
        <Skeleton variant="text" height={30} />
        <StyledGrid container spacing={3}>
          <Grid item lg={2} md={5} xs={5}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item lg={3} md={7} xs={7}>
            <Skeleton variant="text" height={70} />
          </Grid>
        </StyledGrid>
      </InfoContainer>
      <ActionContainer>
        <StyledGrid container spacing={3}>
          <Grid item lg={3} xs={6}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item lg={3} xs={6}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item lg={3} xs={6}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item lg={3} xs={6}>
            <Skeleton variant="text" height={70} />
          </Grid>
        </StyledGrid>
      </ActionContainer>
    </Wrapper>
  );
}
