import React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import * as Sentry from '@sentry/react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { PoolDetails, StakeData, WalletTypeEnum } from '@opulous/web/src/shared/types'
import StyledLoadingButton from '@opulous/web/src/components/Common/StyledLoadingButton'
import {
  calculateReward,
  calculateApy,
  formatNumber,
  formatNumberWithFixedDecimalPlaces,
  formatOpulAmount,
  formatDate,
} from '@opulous/web/src/utils'
import WalletContext from '../../../context/context'
import PeraInfoModal from '@opulous/web/src/components/Wallet/PeraInfoModal'
import {
  makePoolOptInTransaction,
  signTransaction,
} from '@opulous/web/src/helpers/AlgorandHelper'
import WalletModal from '@opulous/web/src/components/Wallet/WalletModal'
import { InputAdornment, TextField, useTheme } from '@mui/material'
import myAlgoService, {
  IAccountInformationResponse, sendSignedTransactions, waitForTransactions,
} from '../../../services/my-algo'
import OpulInput from './OpulInput'
import { getValidMaxLockup } from './helpers'
import * as bigNumber from 'bignumber.js'
import _CheckCircleIcon from '@mui/icons-material/CheckCircle';
import _ErrorIcon from '@mui/icons-material/Error';
import _InfoIcon from '@mui/icons-material/Info';
import OptInLoadingDialog from './OptInLoadingDialog'
import { Link as _Link } from 'react-router-dom'
import { algorandErrorString } from 'src/services/my-algo/errors'
import config from '@opulous/web/src/config'

const MIN_STAKE_AMOUNT = 1;

const secondsToDays = (seconds: number) => seconds / 3600 / 24

const Link = styled(_Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.info.main,
}));

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}))

export const Widget = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1.5, 2),
  border: `1px solid ${theme.palette.grey[200]}`,
  position: 'relative',
  backgroundColor: theme.palette.common.white,
}))

export const Label = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  lineHeight: theme.spacing(2),
  overflowWrap: 'anywhere',
}))

const AfterStakingLabel = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '13px',
  color: theme.palette.grey[600],
}))

const Total = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(5),
  lineHeight: theme.spacing(5.5),
  fontWeight: 500,
  overflow: 'hidden',
  overflowWrap: 'anywhere',
}))

export const Value = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const Chip = styled(Box)<{ dark: boolean }>(({ theme, dark }) => ({
  borderRadius: 6,
  backgroundColor: theme.palette.common[dark ? 'black' : 'white'],
  color: theme.palette.common[dark ? 'white' : 'black'],
  border: `1px solid ${theme.palette.common.black}`,
  fontSize: 10,
  padding: '0px 6px',
  height: 22,
  display: 'flex',
  alignItems: 'center',
}))

export const BorderedInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 0, 1),
  marginTop: theme.spacing(-1),
  backgroundColor: theme.palette.grey.A100,
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
}))

const Info = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: theme.spacing(0, 0, 1.5, 1.5),
  padding: theme.spacing(2, 0, 1),
  backgroundColor: theme.palette.grey.A100,
}))

export const InfoItem = styled('div')(({ theme }) => ({
  width: '50%',
  padding: theme.spacing(0, 1.5),
  '&:first-child': {
    borderRight: `1px solid ${theme.palette.grey[100]}`,
  },
}))

const Action = styled(Box)(({ theme }) => ({
  borderTop: `1.5px solid ${theme.palette.grey[100]}`,
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3, 0),
  },
}))

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3, 0),
  },
}))

export const SubText = styled(Typography)(() => ({
  lineHeight: '14px',
}))

interface Props {
  onStake: (data: StakeData) => void
  pool: PoolDetails
}

export const StyledInput = styled(TextField)(() => ({
  '*': {
    border: '0px !important',
    padding: '0px !important',
  },
  '.MuiOutlinedInput-root': {
    boxShadow: 'none',
  },
  input: {
    fontSize: '32px !important',
    fontWeight: '300 !important',
  },
}))

const TotalTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}))

const Popup = styled(({
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { type: 'success'|'error'|'info' }) => (
  <div {...props}>{children}</div>
))(({ theme, type }) => ({
  backgroundColor: theme.palette[type]?.light,
  color: theme.palette[type]?.dark,
  display: 'flex',
  padding: theme.spacing(1),
  fontSize: '.75rem',
  lineHeight: '1rem',
  fontWeight: '600',
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(2),
}));

const CheckCircleIcon = styled(_CheckCircleIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  marginRight: theme.spacing(1),
}));

const ErrorIcon = styled(_ErrorIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  marginRight: theme.spacing(1),
}));

const InfoIcon = styled(_InfoIcon)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  marginRight: theme.spacing(1),
}));

const NewPoolMessage = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: '600',
  fontSize: '.875rem',
  padding: '.4rem 1rem 0 1rem',
}));

const PoolStakePanel: React.FC<Props> = ({ onStake, pool }) => {
  const theme = useTheme();
  const [amount, setAmount] = React.useState<string>(MIN_STAKE_AMOUNT.toString())
  const [stake, setStake] = React.useState(
    secondsToDays(pool.pool.minStakePeriod)
  )
  const [isOptingIn, setOptingIn] = React.useState<boolean>(false)
  const [peraDialogOpen, setPeraDialogState] = React.useState<boolean>(false)
  const [reward, setReward] = React.useState(0)
  const [apy, setApy] = React.useState(0)
  const [optedIn, setOptedIn] = React.useState(false)
  const [optInStatus, setOptInStatus] = React.useState<'success'|'error'|null>(null);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [showWalletModal, setShowWalletModal] = React.useState(false)
  const [shouldShowOptinLoadingDialog, setShouldShowOptinLoadingDialog] = React.useState<boolean>(false);
  const [accountInformation, setAccountInformation] =
    React.useState<IAccountInformationResponse | null>(null)
  const {
    state: { wallet, walletType, connector },
  } = React.useContext(WalletContext)

  React.useEffect(() => {
    if (wallet && pool.walletHasOptIn !== null) {
      setOptedIn(pool.walletHasOptIn)
    }

    if (!wallet) return

    myAlgoService
      .getAccountInformation(wallet as string)
      .then(setAccountInformation)
  }, [pool, wallet])

  const handleAmountChange = (n: string) => setAmount(n)

  const handleStakePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value

    if (isNaN(Number(val))) {
      return
    }

    setStake(Number(val.replace(/,/g, '') || ''))
  }

  const amountNumberBase = (): bigNumber.BigNumber => {
    return new bigNumber.BigNumber(amount.replace(/,/g, ''))
  }

  const amountNumberBaseOpul = () => {
    return amountNumberBase().multipliedBy(1e10)
  }

  const amountNumber = (): number => {
    return amountNumberBase().toNumber()
  }

  const amountStakePeriod = (): number => {
    return Number(stake)
  }

  React.useEffect(() => {
    if (isNaN(Number(amount))) {
      setAmount('')
    } else {
      setReward(
        calculateReward(
          amountNumberBaseOpul().toNumber(),
          stake,
          pool.pool.minAnnualPercentageYield,
          pool.pool.maxAnnualPercentageYield,
          pool.pool.minStakePeriod,
          pool.pool.maxStakePeriod
        )
      )
    }
  }, [stake, amount])

  React.useEffect(() => {
    setApy(
      calculateApy(
        stake,
        pool.pool.minAnnualPercentageYield,
        pool.pool.maxAnnualPercentageYield,
        pool.pool.minStakePeriod,
        pool.pool.maxStakePeriod
      ) / 100
    )
  }, [stake])

  const onClickStake = () => {
    setOptInStatus(null);
    onStake &&
      onStake({
        amount: amountNumberBaseOpul(),
        stake,
        apy,
        dailyProfits: formatNumber(reward / stake),
      })
  }

  const handleOptin = async () => {
    try {
      setOptingIn(true)
      setShouldShowOptinLoadingDialog(true);
      if (walletType === WalletTypeEnum.peraWallet) {
        Sentry.addBreadcrumb({
          category: '<PoolDetails /> : handleOptin',
          message: 'Displaying Pera mobile dialog',
          type: 'info',
          data: {
            wallet,
            walletType,
            connector,
          },
        })
        setPeraDialogState(true)
      }

      const optInTransaction = await makePoolOptInTransaction({
        from: wallet,
        applicationId: pool.pool.appId,
      });
      const txn = await signTransaction({
        walletType,
        connector,
        transaction: optInTransaction,
      })
      await sendSignedTransactions([txn]);
      await waitForTransactions([txn.txID]);
      setOptedIn(true)
      setOptInStatus('success');
    } catch (ex) {
      Sentry.captureException(ex)
      setOptInStatus('error');
      setErrorMessage(algorandErrorString(ex) || 'Failed to opt in');
    } finally {
      setOptingIn(false)
      setShouldShowOptinLoadingDialog(false);
      setPeraDialogState(false)
    }
  }

  const tokenId = pool.pool.tokenId

  const ownedTokenAmount =
    accountInformation?.assets.find((asset) => asset['asset-id'] === tokenId)
      ?.amount || 0
  const numberOfTokensInWallet = Math.max(ownedTokenAmount - 1, 0)
  const maxStakeInOpul = (wallet ? numberOfTokensInWallet : Number(pool.pool.maxShare)) / 1e10;
  const maxStake = formatNumberWithFixedDecimalPlaces(maxStakeInOpul, 2, false);

  const minLockupDays = secondsToDays(pool.pool.minStakePeriod)

  const validMaxLockup = getValidMaxLockup(
    pool.pool.maxStakePeriod,
    pool.pool.endTimestamp
  )

  const validMinLockup = minLockupDays

  const isStakePeriodValid = !!amountStakePeriod() &&
    amountStakePeriod() >= validMinLockup &&
    amountStakePeriod() <= validMaxLockup;

  const isPoolClosed = BigInt(pool.pool.maxShare) === BigInt(0);
  const isAvailableForStaking = validMinLockup <= validMaxLockup;
  const successorPoolId = config.env.LATEST_POOL_ID;

  const stakePeriodErrorMessage = (() => {
    if (isStakePeriodValid) {
      return null;
    }

    if (!isAvailableForStaking && !!successorPoolId) {
      return (<>
        <span>
          This pool is no longer allowing new stakes. Join our
        </span>
        &nbsp;
        <Link to={`/pools/${encodeURIComponent(successorPoolId)}`}>
          updated pool
        </Link>
        &nbsp;
        <span>to add new stakes!</span>
      </>);
    }

    if (successorPoolId) {
      return (<>
        <span>
          This pool is closing on {formatDate(new Date(Number(pool.pool.endTimestamp) * 1000))}. Join our
        </span>
        &nbsp;
        <Link to={`/pools/${encodeURIComponent(successorPoolId)}`}>
          updated pool
        </Link>
        &nbsp;
        <span>to add new stakes!</span>
      </>);
    }

    return (
      <span>
        This pool is closing on {formatDate(new Date(Number(pool.pool.endTimestamp) * 1000))}.
      </span>
    );
  })();

  const newPoolMessage = (isPoolClosed && !!successorPoolId) && (
    <NewPoolMessage>
      <div>
        New stakes are closed for this pool, please join the {
          <Link to={`/pools/${encodeURIComponent(successorPoolId)}`}>
            new one
          </Link>
        }.
      </div>
      <div>Previous stakes continue to yield rewards.</div>
    </NewPoolMessage>
  );

  return (
    <Container>
      <StyledBox flexGrow={1} p={3}>
        <Widget>
          <Box display="flex" justifyContent="space-between">
            <Label color="textSecondary">Amount to stake</Label>
            <Chip
              dark={stake.toString() === maxStake}
              onClick={() => setAmount(maxStake)}
            >
              Max
            </Chip>
          </Box>
          <form>
            <Value gap={theme.spacing(1)}>
              <OpulInput
                value={amount}
                onChange={handleAmountChange}
                displayTextStyle={{ padding: `${theme.spacing(.5)} 0` }}
                inputProps={{ 'aria-label': 'amount-input' }}
                error={
                  isNaN(amountNumber()) ||
                  amountNumber() < MIN_STAKE_AMOUNT ||
                  Number(amount) > Number(maxStake)
                }
                helperText={
                  ((isNaN(amountNumber()) || amountNumber() < MIN_STAKE_AMOUNT) &&
                    `The minimum stake amount is ${MIN_STAKE_AMOUNT} OPUL.`) ||
                  (Number(amount) > Number(maxStake) && maxStakeInOpul >= MIN_STAKE_AMOUNT &&
                    `You can stake a maximum of ${maxStake} OPUL.`)
                }
              />
              <SubText variant="h6" color="textSecondary">
                OPUL
              </SubText>
            </Value>
          </form>
        </Widget>
        <Widget mt={1.5}>
          <Box display="flex" justifyContent="space-between">
            <Label color="textSecondary">Stake Period</Label>
            <Box display="flex">
              <Chip
                dark={stake === secondsToDays(pool.pool.minStakePeriod)}
                mr={1}
                onClick={() =>
                  setStake(secondsToDays(pool.pool.minStakePeriod))
                }
              >
                Min
              </Chip>
              <Chip
                dark={stake === validMaxLockup}
                onClick={() => setStake(validMaxLockup)}
                data-testid="Pool_PoolStakePanel_Max-button"
              >
                Max
              </Chip>
            </Box>
          </Box>
          <form>
            <Value>
              <StyledInput
                value={formatNumber(Number(stake))}
                onChange={handleStakePeriodChange}
                inputProps={{ 'aria-label': 'amount-input' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SubText variant="h6" color="textSecondary">
                        days
                      </SubText>
                    </InputAdornment>
                  ),
                }}
                error={!isStakePeriodValid}
                helperText={stakePeriodErrorMessage}
              />
            </Value>
          </form>
        </Widget>
        <BorderedInfo>
          <InfoItem>
            <Label color="textSecondary">Min. Stake Period</Label>
            <Label>{validMinLockup} days</Label>
          </InfoItem>
          <InfoItem>
            <Label color="textSecondary">Max. Stake Period</Label>
            <Label>{validMaxLockup} days</Label>
          </InfoItem>
        </BorderedInfo>
        <Info>
          <InfoItem>
            <Label color="textSecondary">APY</Label>
            <Label>{formatNumberWithFixedDecimalPlaces(apy, 2)}%</Label>
          </InfoItem>
          <InfoItem>
            <Label color="textSecondary">Est. Daily Profits</Label>
            <Label>
              {formatNumberWithFixedDecimalPlaces(reward / stake, 2)} OPUL
            </Label>
          </InfoItem>
        </Info>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={0.5}
          mb={0.5}
        >
          <IconButton size="small">
            <ArrowDownward color="primary" />
          </IconButton>
        </Box>
        <Box pl={1.5} pr={1.5}>
          <AfterStakingLabel color="textSecondary">
            Total balance after staking
          </AfterStakingLabel>
        </Box>
        <Value mb={1.5} pl={1.5} pr={1.5}>
          <TotalTooltip
            title={formatOpulAmount((amountNumber() + reward) * 1e10)}
            placement="top"
          >
            <Total color="primary" data-testid="Pool_PoolStakePanel_total">
              {formatNumberWithFixedDecimalPlaces(amountNumber() + reward, 2)}
            </Total>
          </TotalTooltip>
          <Typography variant="h6" color="textSecondary">
            OPUL
          </Typography>
        </Value>
      </StyledBox>
      {optedIn ? (
        <Action>
          {optedIn && wallet && optInStatus === 'success' && (
            <Popup type="success">
              <CheckCircleIcon />
              <span>The opt-in was successful.</span>
            </Popup>
          )}

          <Button
            color="primary"
            size="small"
            fullWidth
            disabled={
              !amount ||
              isPoolClosed ||
              parseFloat(amount) < MIN_STAKE_AMOUNT ||
              !isStakePeriodValid ||
              Number(amount) > Number(maxStake)
            }
            onClick={onClickStake}
            data-testid="Pool_PoolStakePanel_Stake-button"
          >
            Stake
          </Button>

          {newPoolMessage}
        </Action>
      ) : (
        <Action>
          {!optedIn && wallet && (
            optInStatus === 'error' ? (
              <Popup type="error">
                <ErrorIcon />
                <span>{errorMessage}</span>
              </Popup>
            ) : (
              <Popup type="info">
                <InfoIcon />
                <span>You must opt in to the pool before staking.</span>
              </Popup>
            )
          )}

          <OptInLoadingDialog
            open={shouldShowOptinLoadingDialog}
            onClose={() => setShouldShowOptinLoadingDialog(false)}
          />

          <StyledLoadingButton
            color="primary"
            size="small"
            fullWidth
            loading={isOptingIn}
            onClick={() => {
              !wallet ? setShowWalletModal(true) : handleOptin()
            }}
            data-testid="Pool_PoolStakePanel_OptMeIn-button"
            variant="contained"
            borderRadius="32px"
            textTransform="capitalize"
            padding="12px 40px"
            disabled={isPoolClosed}
          >
            {!wallet ? 'Connect wallet' : 'Opt me in!'}
          </StyledLoadingButton>
          <WalletModal
            showWalletModal={showWalletModal}
            onClose={() => setShowWalletModal(false)}
          />

          {newPoolMessage}
        </Action>
      )}
      <PeraInfoModal
        open={peraDialogOpen}
        onClose={() => setPeraDialogState(false)}
      />
    </Container>
  )
}

export default PoolStakePanel
