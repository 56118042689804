import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import {
  formatNumber,
  formatCurrency,
  calculateIndividualRewardsShare,
  CalculatorProps,
  dispatchInputEvent,
} from '@opulous/web/src/utils';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import {
  Calculator,
  StyledArrowDownward,
  StyledInput,
} from '@opulous/web/src/components/Sales/RewardsShareCalculator/styled-components';

const MaxInvest = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1.65),
  right: theme.spacing(1.55),
  '&.MuiButton-text': {
    border: `1px solid ${theme.palette.grey[700]}`,
    background: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.2, 1.4),
    fontSize:  theme.spacing(1.5),
    fontWeight: 'normal',
  }
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1, 2),
  },
}));

type Props = {
  autoFocus?: boolean;
  calculatorProps: CalculatorProps;
  value?: string;
  onInputChange?: (event: CustomEvent) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;
let violationTooltipTimeoutId: any;
export default function SmartCalculatorForm({
  autoFocus,
  calculatorProps,
  value,
  name,
  onChange,
  onInputChange,
}: Props): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const [moneyInvested, setMoneyInvested] = useState<string | undefined>(value);
  const [yourShare, setYourShare] = useState<number>(0);
  const [showViolationTooltip, setShowViolationTooltip] = useState(false);
  const [moneyInvestedViolation, setMoneyInvestedViolation] = useState<
    string | undefined
  >();

  const setViolationMessage = (violationMessage?: string) => {
    clearTimeout(violationTooltipTimeoutId);
    const newShowViolationTootip = !!violationMessage;
    setMoneyInvestedViolation(violationMessage);
    setShowViolationTooltip(newShowViolationTootip);
    if (newShowViolationTootip) {
      violationTooltipTimeoutId = setTimeout(() => {
        setShowViolationTooltip(!newShowViolationTootip);
      }, 10000);
    }
  };

  const debouncedRounding = React.useRef(
    debounce((moneyInvestedValue: number, restOfDivision: number) => {
      if (moneyInvestedValue > 0 && inputRef.current && restOfDivision > 0) {
        const newValue =
          moneyInvestedValue +
          (calculatorProps.incrementAmount - restOfDivision);
        dispatchInputEvent(inputRef.current, `${newValue}`);
      }
    }, 2000),
  ).current;

  const calculateYourShare = useCallback((newMoneyInvested?: number) => {
    const newMoneyInvestedFormatted = newMoneyInvested
      ? formatNumber(newMoneyInvested)
      : '';
    setMoneyInvested(newMoneyInvestedFormatted);

    let customEvent: CustomEvent = new CustomEvent('custom:change', {
      detail: {
        your: 0,
        investors: calculatorProps.rewardsShare,
        moneyInvested: newMoneyInvested,
      },
    });

    if (
      newMoneyInvested &&
      [
        !Number.isNaN(newMoneyInvested),
        calculatorProps.rewardsShare > 0,
        newMoneyInvested >= calculatorProps.minInvest,
        newMoneyInvested <= calculatorProps.maxInvest,
      ].every(it => !!it)
    ) {
      const newYourShare = calculateIndividualRewardsShare({
        amount: newMoneyInvested,
        targetAmount: calculatorProps.fundsGoal,
        rewardsShare: calculatorProps.rewardsShare,
      });
      setYourShare(newYourShare);

      let newInvestorsShare = calculatorProps.rewardsShare - newYourShare;
      newInvestorsShare =
        newInvestorsShare > 0
          ? Math.round((newInvestorsShare + Number.EPSILON) * 100) / 100
          : 0;

      customEvent = new CustomEvent('custom:change', {
        detail: {
          your: newYourShare,
          investors: newInvestorsShare,
          moneyInvested: newMoneyInvested,
        },
      });
    } else {
      setYourShare(0);
    }

    if (onInputChange) {
      onInputChange(customEvent);
    }
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newMoneyInvested = event.target.value
        ? Number(event.target.value.replace(',', ''))
        : undefined;

      if (newMoneyInvested !== undefined) {
        if ((newMoneyInvested || 0) < calculatorProps.minInvest) {
          setViolationMessage(calculatorProps.minMoneyInvestedViolation);
        } else if ((newMoneyInvested || 0) > calculatorProps.allowedMaxInvest) {
          setViolationMessage(calculatorProps.maxMoneyInvestedViolation);
        } else {
          setViolationMessage(undefined);
        }
      }

      const moneyInvestedValue = newMoneyInvested || 0;
      debouncedRounding(
        moneyInvestedValue,
        moneyInvestedValue % calculatorProps.incrementAmount,
      );
      calculateYourShare(newMoneyInvested);
      if (onChange) {
        onChange(event);
      }
    },
    [],
  );

  const fillWithMax = () => {
    if (inputRef.current) {
      const inputElement = inputRef.current;
      const newValue = Number(calculatorProps.allowedMaxInvest);
      if (newValue > 0) {
        dispatchInputEvent(inputElement, `${newValue}`);
      }
    }
  };

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
    calculateYourShare(value ? Number(value) : undefined);
  }, []);

  useEffect(() => {
    return () => {
      debouncedRounding.cancel();
    };
  }, [debouncedRounding]);

  return (
    <Calculator data-testid="rewards-share-calculator__form">
      <StyledTooltip
        title={<React.Fragment>{moneyInvestedViolation}</React.Fragment>}
        placement="top"
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => {
          return;
        }}
        open={!!moneyInvestedViolation && showViolationTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <StyledInput>
          <label data-testid="rewards-share-calculator__form__invested__label">
            Purchase Amount
          </label>
          <div data-haserror={!!moneyInvestedViolation}>
            <p>{moneyInvested && '$'}</p>
            <input
              autoComplete="off"
              ref={inputRef}
              data-testid="rewards-share-calculator__form__invested__input"
              name={name || 'invested_input'}
              type="text"
              onChange={handleChange}
              placeholder={`min $${calculatorProps.minInvest}`}
              value={moneyInvested || ''}
            />
          </div>
          <MaxInvest
            data-testid="rewards-share-calculator__form__invested__max-button"
            variant='text'
            onClick={fillWithMax}
          >
            Max {formatCurrency(calculatorProps.allowedMaxInvest, '$', 0)}
          </MaxInvest>
        </StyledInput>
      </StyledTooltip>
      <StyledArrowDownward />
      <StyledInput>
        <label data-testid="rewards-share-calculator__form__your-share__label">
          Your share
        </label>
        <span data-testid="rewards-share-calculator__form__your-share__value">
          {yourShare || Number(0).toFixed(2)} %
        </span>
      </StyledInput>
    </Calculator>
  );
}
