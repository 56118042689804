import { ReactElement } from 'react'

type Props = {
  'data-testid'?: string
  className?: string
  fill: string
  stroke: string
}
export default function AcceptIcon(props: Props): ReactElement | null {
  const {
    className,
    fill,
    stroke,
  } = props
  return (
    <svg
      data-testid={props['data-testid'] || 'accept-icon'}
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" fill={fill} stroke={stroke} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7071 11.2929C24.0976 11.6834 24.0976 12.3166 23.7071 12.7071L14.7071 21.7071C14.3166 22.0976 13.6834 22.0976 13.2929 21.7071L8.29289 16.7071C7.90237 16.3166 7.90237 15.6834 8.29289 15.2929C8.68342 14.9024 9.31658 14.9024 9.70711 15.2929L14 19.5858L22.2929 11.2929C22.6834 10.9024 23.3166 10.9024 23.7071 11.2929Z"
        fill={stroke}
      />
    </svg>
  )
}
