import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, Pagination, Skeleton, useTheme } from '@mui/material';
import PoolService from 'src/services/pools';
import { PoolList } from 'src/shared/types';
import AdminPoolCard from 'src/components/Pools/PoolGridCard/AdminPoolCard';

const PAGE_SIZE = 12;

const Container = styled('div')(() => ({
  width: '100%',
  maxWidth: '80rem',
}));

const PoolGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(24rem, 1fr))',
  gap: '1rem',

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))',
  },
}));

export function PoolSkeleton() {
  const theme = useTheme();
  return (
    <Card>
      <Box display="flex" padding={2}>
        <Skeleton variant="circular" width="4rem" height="4rem" />
        <Box flex="1" display="flex" flexDirection="column" justifyContent="center" paddingX={2}>
          <Skeleton width="100%" height="1.6rem" />
          <Skeleton width="60%" height="1.6rem" />
        </Box>
      </Box>
      <Box padding={2}>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: theme.spacing(3, 2),
        }}>
          {new Array(4).fill(null).map((_, i) => (
            <Box key={`skeleton-${i}`}>
              <Box display="flex" gap={1} width="100%">
                <Skeleton variant="circular" width="1rem" height="1rem" />
                <Box flex="1"><Skeleton width="60%" /></Box>
              </Box>
              <Skeleton height="2rem" width="60%" />
            </Box>
          ))}
        </div>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        gap={1}
        padding={2}
        boxSizing="border-box"
        borderTop={`1px ${theme.palette.grey[100]} solid`}
      >
        <Skeleton variant="circular" width="2rem" height="2rem" />
      </Box>
    </Card>
  );
}

export default function PoolPage(): React.ReactElement {
  const navigate = useNavigate();
  const [pools, setPools] = useState<PoolList[]>([]);
  const [numPools, setNumPools] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchPools() {
    setIsLoading(true);
    const { data, count } = await PoolService.adminGetAllPools(page, PAGE_SIZE);
    setPools(data);
    setNumPools(count);
    setIsLoading(false);
  }

  const handlePoolCreate = () => navigate('/admin/pools/create');

  useEffect(() => {
    fetchPools();
  }, [page]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={3}>
      <Container>
        <div>
          <h1>Pools</h1>
        </div>
        <Box display="flex" justifyContent="flex-end" paddingY={2}>
          <Button onClick={handlePoolCreate}>Create</Button>
        </Box>
        <div>
          <PoolGrid>
            {isLoading && (new Array(3).fill(null).map((_, i) => (
              <PoolSkeleton key={`skeleton-${i}`} />
            )))}
            {!isLoading && pools.map(pool => (
              <AdminPoolCard key={pool.id} pool={pool} onPoolRefresh={fetchPools} />
            ))}
          </PoolGrid>
        </div>
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Pagination
            count={Math.ceil(numPools / PAGE_SIZE)}
            color="primary"
            onChange={(_e, p) => setPage(p)}
            page={page}
          />
        </Box>
      </Container>
    </Box>
  );
}
