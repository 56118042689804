import React from 'react'
import { styled } from '@mui/system'
import Icon from '@mui/material/Icon'
import Logo from '@opulous/web/src/assets/usdc.svg'

interface UsdcIconProps {
  fontSize?: number
}

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  padding: 10
})

const OpulIcon: React.FC<UsdcIconProps> = ({ fontSize = 16 }) => (
  <Icon sx={{ fontSize }}>
    <StyledImage alt="Usdc icon" src={Logo} />
  </Icon>
)

export default OpulIcon
