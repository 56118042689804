import { useEffect, useState } from 'react';
import { Amplify, Hub, Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import * as Sentry from '@sentry/react'
import * as adminService from '../services/admin';

export default function() {
  const [user, setUser] = useState<CognitoUser>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    (async () => {
      const { data: { data: adminAmplifyConfig } } = await adminService.getAmplifyConfig();

      Amplify.configure(adminAmplifyConfig);

      Hub.listen('auth', async ({ payload }) => {
        if (payload.data instanceof Error) {
          Sentry.captureException(payload.data);
        }

        switch (payload.event) {
          case 'signIn_failure': {
            setUser(undefined);
            setIsAdmin(false);
            break;
          }

          case 'signIn': {
            const session = await Auth.currentSession();
            setUser(payload.data);
            setIsAdmin(
              !!session.getAccessToken().decodePayload()['cognito:groups']
                ?.includes(adminService.CognitoGroup.ADMIN)
            );
            break;
          }

          case 'signOut': {
            setUser(undefined);
            setIsAdmin(false);
            break;
          }
        }
      });

      try {
        const currentUser = await Auth.currentUserPoolUser();
        const session = await Auth.currentSession();
        setUser(currentUser);
        setIsAdmin(
          !!session.getAccessToken().decodePayload()['cognito:groups']
            ?.includes(adminService.CognitoGroup.ADMIN)
        );
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        setIsInitializing(false);
      }
    })();
  }, []);

  return {
    user,
    isAdmin,
    isInitializing,
  };
}
