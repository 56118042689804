import { Dispatch } from 'react';
import algosdk from 'algosdk';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import * as SalesService from '@opulous/web/src/services/sales';
import {
  AlgoAsset,
  InvestmentDetails,
  InvestmentPurchaseStatus,
  PaymentMethodOptions,
  WalletTypeEnum,
} from '@opulous/web/src/shared/types';
import { PeraWalletConnect } from '@perawallet/connect';
import { isSignTransactionCancelledError } from '@opulous/web/src/utils';
import { delay } from '@opulous/web/src/shared/helpers';
import * as MyAlgoService from '@opulous/web/src/services/my-algo';
import { algorandErrorString } from '@opulous/web/src/services/my-algo/errors';
import config from '@opulous/web/src/config';

export type Action<T> = {
  type: T;
  payload?: any; // eslint-disable-line
};

export enum InvestmentActionType {
  SetState,
  SetLoading,
  SetLoadingAvailability,
  SetProcessingPayment,
  SetShowAlert,
  SetPaymentIntent,
  LoadInvestment,
  LoadInvestmentAvailability,
  RenderEmailConfirmationStep,
  RenderPaymentMethodStep,
  RenderPurchaseConfirmationStep,
  SubscriveInvestor,
  SendConfirmationCode,
  VerifyConfirmationCode,
  RenderCongratulationStep,
}

export type EmailConfirmationInput = {
  purchaseAmount: number;
  email: string;
};
export type PaymentMethodInput = {
  paymentMethod: PaymentMethodOptions;
};

const PAYMENT_CONFIRMATION_ATTEMPTS = 20;
const CreatePaymentIntentErrorTypesMap = {
  IS_NOT_YET_PRE_SALE: 'Sorry, but this MFT is not for pre-sale yet.\n',
  IS_NOT_YET_SALE: 'Sorry, but this MFT is not for sale yet.\n',
  SALE_FINISHED: 'Sorry, but this MFT is no longer available for sale.\n',
  AMOUNT_LESS_THAN_MIN: 'Sorry, but the amount chosen for investment is less than the minimum allowed.\n',
  AMOUNT_GREATER_THAN_MAX: 'Sorry, but the amount chosen for investment is greater than the maximum allowed.\n',
  AMOUNT_GREATER_THAT_AVAILABLE: 'Sorry, but the amount chosen for investment is greater than the available.\n',
};

export type InvestmentActions = {
  setLoading: (status: boolean) => void;
  setLoadingAvailability: (status: boolean) => void;
  setProcessingPayment: (input: { processingPayment: boolean, openCryptoPaymentIframe?: boolean }) => void;
  expirePaymentIntent: () => void;
  setShowAlertError: (
    status: boolean,
    message?: string,
    showSupportEmailMessage?: boolean,
  ) => void;
  loadInvestment: (investmentId: number, wallet?: string) => Promise<void>;
  loadInvestmentAvailability: (investmentId: number, wallet?: string) => Promise<void>;
  goToGetEarlyAccess: () => void;
  renderEmailConfirmationStep: () => void;
  renderPaymentMethodStep: (input: EmailConfirmationInput) => void;
  renderPurchaseConfirmationStep: (input: PaymentMethodInput) => void;
  subscriveInvestor: (
    investment: InvestmentDetails,
    email: string,
  ) => Promise<void>;
  sendConfirmationCode: (input: { email: string }) => Promise<void>;
  verifyConfirmationCode: (input: {
    email: string;
    purchaseAmount: number;
    code: string;
  }) => Promise<void>;
  createPaymentIntent: (input: {
    investmentId: number;
    walletAddress: string;
    email: string;
    purchaseAmount: number;
    paymentMethod: PaymentMethodOptions;
  }) => Promise<void>;
  completeOpulPaymentTransaction: (input: {
    externalId: string;
    walletType: WalletTypeEnum;
    connector?: PeraWalletConnect;
    transactionEncoded: number[];
    reloadWalletCallback: () => void;
  }) => Promise<void>;
  startPaymentWithCrypto: () => void;
  confirmPaymentWithCrypto: () => void;
  cancelProcessingPayment: () => void;
};
export function useActions(
  dispatch: Dispatch<Action<InvestmentActionType>>,
): InvestmentActions {
  const actions = {
    setLoading: (status: boolean) => {
      dispatch({ type: InvestmentActionType.SetLoading, payload: status });
    },
    setLoadingAvailability: (status: boolean) => {
      dispatch({
        type: InvestmentActionType.SetLoadingAvailability,
        payload: status,
      });
    },
    setProcessingPayment: (input: { processingPayment: boolean, openCryptoPaymentIframe?: boolean }) => {
      dispatch({
        type: InvestmentActionType.SetProcessingPayment,
        payload: {
          ...input,
        },
      });
    },
    expirePaymentIntent: () => {
      dispatch({
        type: InvestmentActionType.SetState,
        payload: { 
          paymentIntentExpired: true,
          processingPayment: false,
          openCryptoPaymentIframe: false,
        },
      });
    },
    setShowAlertError: (
      status: boolean,
      message?: string,
      showSupportEmailMessage = true,
    ) => {
      dispatch({
        type: InvestmentActionType.SetShowAlert,
        payload: status
          ? { error: true, message, showSupportEmailMessage }
          : undefined,
      });
    },
    goToGetEarlyAccess: () => {
      window.open(`${config.env.WEB_SITE_ADDRESS}/earlyaccess`);
    },
    loadInvestment: async (investmentId: number, wallet?: string) => {
      try {
        actions.setLoading(true);
        const investmentDetails = await SalesService.fetchInvestmentById({
          investmentId,
          walletAddress: wallet,
        });

        let ownedAsset: AlgoAsset | undefined;
        if (investmentDetails && wallet) {
          ownedAsset = await MyAlgoService.getAssetDetailsOwnedByWallet({
            assetId: investmentDetails?.asset.id,
            unitName: investmentDetails?.asset.unitName,
            walletAddress: wallet,
          });
        }

        dispatch({
          type: InvestmentActionType.LoadInvestment,
          payload: {
            investment: investmentDetails,
            walletAddress: wallet,
            ownedAsset,
          },
        });
      } catch (error) {
        Sentry.captureException(error);
        actions.setShowAlertError(true);
      } finally {
        actions.setLoading(false);
      }
    },
    loadInvestmentAvailability: async (investmentId: number, wallet?: string) => {
      try {
        actions.setLoadingAvailability(true);
        const investmentAvailability = await SalesService.fetchInvestmentAvailability({
          investmentId,
          walletAddress: wallet,
        });

        dispatch({
          type: InvestmentActionType.LoadInvestmentAvailability,
          payload: {
            investmentAvailability,
          },
        });
      } catch (error) {
        Sentry.captureException(error);
        actions.setShowAlertError(true);
      } finally {
        actions.setLoadingAvailability(false);
      }
    },
    renderEmailConfirmationStep: () => {
      dispatch({
        type: InvestmentActionType.RenderEmailConfirmationStep,
      });
    },
    renderPaymentMethodStep: (input: EmailConfirmationInput) => {
      dispatch({
        type: InvestmentActionType.RenderPaymentMethodStep,
        payload: {
          purchaseAmount: input.purchaseAmount,
          email: input.email,
        },
      });
    },
    renderPurchaseConfirmationStep: (input: PaymentMethodInput) => {
      dispatch({
        type: InvestmentActionType.RenderPurchaseConfirmationStep,
        payload: {
          paymentMethod: input.paymentMethod,
        },
      });
    },
    subscriveInvestor: async (investment: InvestmentDetails, email: string) => {
      await SalesService.subscribeInvestor({
        email,
        crmTagId: investment?.crmTagId,
      });
    },
    sendConfirmationCode: async (input: { email: string }) => {
      try {
        await SalesService.sendConfirmationCode({ ...input });
      } catch (error) {
        actions.setShowAlertError(
          true,
          'An error has occurred while sending confirmation code',
        );
      }
    },
    verifyConfirmationCode: async (input: {
      email: string;
      purchaseAmount: number;
      code: string;
    }) => {
      await SalesService.verifyConfirmationCode({ ...input });
      actions.renderPaymentMethodStep({
        ...input,
      });
    },
    createPaymentIntent: async (input: {
      investmentId: number;
      walletAddress: string;
      email: string;
      purchaseAmount: number;
      paymentMethod: PaymentMethodOptions;
    }) => {
      try {
        dispatch({
          type: InvestmentActionType.SetState,
          payload: {
            paymentIntent: undefined,
            paymentIntentExpired: false,
          },
        });
        const paymentIntent = await SalesService.createPaymentIntent({
          ...input,
        });
        dispatch({
          type: InvestmentActionType.SetPaymentIntent,
          payload: {
            paymentIntent,
          },
        });
      } catch (error: any) {
        dispatch({
          type: InvestmentActionType.SetState,
          payload: {
            paymentIntent: undefined,
            processingPayment: false,
          },
        });
        Sentry.captureException(error);
        let message;
        if (axios.isAxiosError(error)) {
          const { errors } = (error.response?.data || { errors: [] });
          const type = errors[0]?.type as keyof typeof CreatePaymentIntentErrorTypesMap;
          message = CreatePaymentIntentErrorTypesMap[type];
        }
        actions.setShowAlertError(true, message);
      }
    },
    completeOpulPaymentTransaction: async (input: {
      externalId: string;
      walletType: WalletTypeEnum;
      connector?: PeraWalletConnect;
      transactionEncoded: number[];
      reloadWalletCallback: () => void;
    }) => {
      try {
        const transaction = algosdk.decodeUnsignedTransaction(
          new Uint8Array(input.transactionEncoded),
        );
        const signedTxn = await SalesService.signPurchasePaymentTransaction({
          transaction,
          walletType: input.walletType,
          connector: input.connector,
        });
        actions.setProcessingPayment({
          processingPayment: true,
        });
        await SalesService.sendPurchasePaymentTransaction({ signedTxn });

        for (let i = 0; i < PAYMENT_CONFIRMATION_ATTEMPTS; i++) {
          const paymentTransactionState =
            await SalesService.loadPurchasePaymentTransactionState({
              externalId: input.externalId,
            });
          if (
            InvestmentPurchaseStatus.PAID === paymentTransactionState.status
          ) {
            input.reloadWalletCallback();
            dispatch({
              type: InvestmentActionType.RenderCongratulationStep,
            });
            break;
          }
          await delay(3000);
        }
      } catch (error) {
        if (isSignTransactionCancelledError(error)) {
          return;
        }
        Sentry.captureException(error);
        const algorandErrorMessage = algorandErrorString(error);
        const isAlgorandError = Boolean(algorandErrorMessage);
        actions.setShowAlertError(
          true,
          algorandErrorMessage || undefined,
          !isAlgorandError,
        );
      } finally {
        actions.setProcessingPayment({
          processingPayment: false,
        });
      }
    },
    startPaymentWithCrypto: () => {
      actions.setProcessingPayment({
        processingPayment: true,
        openCryptoPaymentIframe: true,
      });
    },
    confirmPaymentWithCrypto: () => {
      actions.setProcessingPayment({
        processingPayment: false,
      });
      dispatch({
        type: InvestmentActionType.RenderCongratulationStep,
      });
    },
    cancelProcessingPayment: () => {
      actions.setProcessingPayment({
        processingPayment: false,
      });
    },
  };
  return actions;
}
