import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { StyledContent, StyledDialog } from '../InvestModal'

export default function OptInLoadingDialog({
  onClose,
  open,
}: {
  onClose: () => void
  open: boolean
}): React.ReactElement {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
    >
      <StyledContent>
        <Box display="flex" justifyContent="flex-end" height="1rem">
          <Box position="absolute">
            <IconButton
              size="small"
              onClick={onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
            <CircularProgress color="primary" />
          </Box>
          <Typography variant="h6">
            Opting in is in progress
          </Typography>
        </Box>
      </StyledContent>
    </StyledDialog>
  );
}
