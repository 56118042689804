import React, { ReactElement } from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { styled } from '@mui/system'
import sanitizeHtml from 'sanitize-html'
import WinPlacesIcon from '../icons/WinPlacesIcon'
import EndDateIcon from '../icons/EndDateIcon'
import { formatDateTimeUtc, formatNumber } from '@opulous/web/src/utils'
import { DesktopOnly, MobileOnly } from '@opulous/web/src/components/Common/Responsive'
import { IAdminRaffle } from 'src/shared/types'

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(3),
  overflow: 'hidden',
  position: 'relative',
}))

const DraftTag = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: '600',
  position: 'absolute',
  top: theme.spacing(2),
  right: 0,
  height: theme.spacing(4),
  width: theme.spacing(8),
  fontSize: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(2, 0, 0, 2),
  backgroundColor: theme.palette.grey[400],

  '&:after': {
    content: '"Draft"',
  },
}));

const RaffleImg = styled(
  (props: React.HTMLAttributes<HTMLDivElement> & { src: string }) => (
    <div {...props} />
  )
)(({ theme, src }) => ({
  width: '100%',
  height: theme.spacing(36),
  backgroundImage: `url("${src}")`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',

  [theme.breakpoints.up('xl')]: {
    height: '100%',
  },

  [theme.breakpoints.down('sm')]: {
    paddingBottom: '100%',
    height: 'unset',
  },
}))

const ActionContainer = styled('div')(({ theme }) => ({
  borderLeft: '1px solid #e6ebef',
  width: '100%',
  height: '16rem',
  paddingLeft: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'stretch',
  marginTop: theme.spacing(2),

  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0,
    borderLeft: 'none',
    marginTop: 0,
  },
}))

const Tile = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
}))

const TileTitleText = styled(Typography)(({ theme }) => ({
  fontSize: '.75rem',
  color: theme.palette.grey[600],
  fontWeight: '400',
}))

const TileText = styled(Typography)(() => ({
  fontSize: '.875rem',
  fontWeight: '400',
}))

const TileIconContainer = styled('div')(({ theme }) => ({
  width: '1.2rem',
  height: '1.2rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),

  '& > svg': {
    width: '100%',
    height: '100%',
  },
}))

const TileGrid = styled('div')(({ theme }) => ({
  marginTop: 'auto',
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: 'repeat(auto-fit, minmax(6.8rem, 1fr))',
}))

const NumWinnerBadge = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  borderRadius: '.8rem',
  height: '1.6rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 2),
  fontSize: '.875rem',
  fontWeight: '500',
  whiteSpace: 'nowrap',
}))

const DescriptionContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': '4',
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'normal',
  height: '6rem',
}))

const TitleContainer = styled('div')<{ hasTag?: boolean }>(({ theme, hasTag }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': '1',
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'normal',
  fontWeight: '700',
  fontSize: '1.375rem',
  paddingRight: hasTag ? theme.spacing(5) : 0,
}))

const sanitizeSettings = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'li', 'ol', 'div','p','br'],
  allowedAttributes: {
    '*': ['href', 'target', 'style'],
  },
}

export default function AdminRaffleCard({
  raffle,
  children,
}: {
  raffle: IAdminRaffle
  children?: React.ReactNode
}): ReactElement {
  return (
    <Wrapper data-testid="raffle-card">
      <Grid container height="100%">
        <Grid item xl={12} lg={12} sm={12} xs={12}>
          <MobileOnly>
            <Box padding={3}>
              <TitleContainer hasTag={raffle.hidden}>{raffle.title}</TitleContainer>
            </Box>
          </MobileOnly>
        </Grid>
        <Grid item xl={3} lg={12} sm={12} xs={12} display="flex">
          <DesktopOnly width="100%" padding={3}>
            <RaffleImg src={raffle.image} />
          </DesktopOnly>
          <MobileOnly width="100%" paddingX={3}>
            <RaffleImg src={raffle.image} />
          </MobileOnly>
        </Grid>
        <Grid item xl={6} lg={8} sm={12} xs={12} padding={3} display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <DesktopOnly>
              <TitleContainer>{raffle.title}</TitleContainer>
            </DesktopOnly>
            <DesktopOnly>
              <NumWinnerBadge>
                {formatNumber(raffle.numberOfWinners)} winner(s)
              </NumWinnerBadge>
            </DesktopOnly>
          </Box>
          <DescriptionContainer
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(raffle.description, sanitizeSettings),
            }}
          />
          <TileGrid>
            <Tile>
              <TileIconContainer>
                <EndDateIcon />
              </TileIconContainer>
              <TileTitleText>Start date (UTC time)</TileTitleText>
              <TileText>{formatDateTimeUtc(new Date(raffle.startTimestamp))}</TileText>
            </Tile>
            <Tile>
              <TileIconContainer>
                <WinPlacesIcon />
              </TileIconContainer>
              <TileTitleText>End date (UTC time)</TileTitleText>
              <TileText>{formatDateTimeUtc(new Date(raffle.endTimestamp))}</TileText>
            </Tile>
          </TileGrid>

          <MobileOnly paddingTop={2}>
            <NumWinnerBadge>
              {formatNumber(raffle.numberOfWinners)} winner(s)
            </NumWinnerBadge>
          </MobileOnly>
        </Grid>
        <Grid
          item
          xl={3}
          lg={4}
          sm={12}
          xs={12}
          padding={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ActionContainer>
            {children}
          </ActionContainer>
        </Grid>
      </Grid>
      {raffle.hidden && <DraftTag />}
    </Wrapper>
  )
}
