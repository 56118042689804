import React, { CSSProperties, ReactElement, ReactNode, useEffect, useMemo, useRef } from 'react';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  zIndex: 999,
  backgroundColor: theme.palette.background.paper,
  maxWidth: `calc(100% - 2 * ${theme.spacing(2)})`,
  maxHeight: `calc(100% - 2 * ${theme.spacing(2)})`,
}));

export const DEFAULT_AUTO_HIDE_DURATION = 8000;

export default function Snackbar({
  children,
  open,
  onClose,
  autoHideDuration,
  position,
  style,
  className,
}: {
  children?: ReactNode
  open: boolean
  onClose?: () => void
  autoHideDuration?: number
  position?: Partial<{
    left: number|string
    right: number|string
    top: number|string
    bottom: number|string
  }>
  style?: CSSProperties
  className?: string
}): ReactElement|null {
  const theme = useTheme();
  const timerIdRef = useRef<number>();
  const containerPosition = useMemo(() => {
    if (!position) return { right: theme.spacing(2), top: theme.spacing(2) };
    const newPosition = { ...position };
    if (
      position?.top === undefined &&
      position?.bottom === undefined &&
      style?.top === undefined &&
      style?.bottom === undefined
    ) {
      newPosition.top = theme.spacing(2);
    }
    if (
      position?.left === undefined &&
      position?.right === undefined &&
      style?.left === undefined &&
      style?.right === undefined
    ) {
      newPosition.right = theme.spacing(2);
    }
    return newPosition;
  }, [position]);

  useEffect(() => {
      clearTimeout(timerIdRef.current);
      if (open && Number.isInteger(autoHideDuration)) {
        Object.assign(timerIdRef, {
          current: setTimeout(() => onClose?.(), autoHideDuration),
        });
      }
  }, [open]);

  if (!open) return null;

  return (
    <Container className={className} style={{ ...containerPosition, ...style }}>
      {children}
    </Container>
  );
}
