import React from 'react';
import { styled } from '@mui/system';
import { OpulIcon } from '@opulous/web/src/components/Claims/AssetClaimCard/components';
import OPULLabel from '@opulous/web/src/components/shared/OPULLabel';
import MinorOPUL from '@opulous/web/src/shared/valueObjects/MinorOPUL';

interface OpulInfoProps {
  amount?: number;
}

const OpulInfoWrapper = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
  marginRight: 10,
  display: 'flex',
  alignItems: 'center',
}));

const StyledOPULLabel = styled(OPULLabel)({
  fontWeight: 'bold',
  marginRight: 10,
});

const OpulInfo: React.FC<OpulInfoProps> = ({ amount = 0 }) => {
  return (
    <OpulInfoWrapper>
      <StyledOPULLabel value={MinorOPUL.create(amount)}></StyledOPULLabel>
      <OpulIcon />
    </OpulInfoWrapper>
  );
};

export default OpulInfo;
