import React, { useEffect, useState, useContext } from 'react'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'

import * as Sentry from '@sentry/react'

import PageHeader from '@opulous/web/src/components/PageHeader'
import { PoolList } from '@opulous/web/src/shared/types'
import { pools as PoolsService } from '@opulous/web/src/services'
import PoolsList from '@opulous/web/src/components/Pools/PoolsList'
// import Select from '@opulous/web/src/components/Pools/Select'
import PoolError from './PoolError'
import WalletContext from '@opulous/web/src/context/context'

const StyledBadge = styled('div')(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  borderRadius: '100%',
  background: theme.palette.grey['A100'],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: theme.spacing(3),
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(2),
  fontWeight: 600,
  color: theme.palette.grey[700],
}))

const ErrorContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

const ErrorText = styled('span')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '150%',
  color: theme.palette.grey[700],
  marginBottom: '20px',
}))

const ReloadButton = styled('button')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '150%',
  color: theme.palette.common.white,
  padding: '8px 24px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '40px',
  border: 'none',
  cursor: 'pointer',
}))

const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(2),
  },
}))

const PoolsPage: React.FC = () => {
  const [pools, setPools] = useState<PoolList[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // const [sortBy, setSortBy] = useState('')
  const [error, setError] = useState<string>('')

  const {
    state: { wallet },
  } = useContext(WalletContext)

  const fetchPools = async () => {
    setIsLoading(true)
    setError('')
    try {
      const {
        data: { data },
      } = await PoolsService.getAllPools(wallet)
      if (data.length > 0) {
        const poolsWithMyStakeData: PoolList[] = await Promise.all(
          data.map(async (pool) => {
            const {
              data: { data: poolDetail },
            } = await PoolsService.getPool(Number(pool.id), wallet)
            return {
              ...pool,
              myStaked: poolDetail?.activeStakes || [],
            }
          })
        )
        setPools(poolsWithMyStakeData)
      }
      // Blockchain data is still mocked
    } catch (_error: unknown) {
      Sentry.captureException(_error)
      setError('An error ocurred while fetching pools')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchPools()
  }, [])

  return (
    <div>
      <Container
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          marginBottom: '18px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PageHeader title="Pools" />
          {!isLoading && pools.length > 0 && (
            <StyledBadge data-testid="PoolsPage_pool-total">
              {pools.length}
            </StyledBadge>
          )}
        </Box>
        {/* {!isLoading && pools.length > 0 && (
          <Select label="Sort By" value={sortBy} onChange={setSortBy} />
        )} */}
      </Container>
      {(isLoading || (!isLoading && pools.length > 0)) && (
        <PoolsList isLoading={isLoading} pools={pools} />
      )}

      {((!isLoading && pools.length < 1) || error) && (
        <ErrorContainer>
          <PoolError />
          <ErrorText data-testid="pool-text-error">
            {error || 'No pools available at the moment'}
          </ErrorText>
          {error && (
            <ReloadButton onClick={fetchPools} data-testid="pool-reload-button">
              Reload page
            </ReloadButton>
          )}
        </ErrorContainer>
      )}
    </div>
  )
}

export default PoolsPage
