import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const sentryDsn = process.env.REACT_APP_SENTRY_DSN

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    initialScope: {
      tags: { service: 'web' },
    },
    tracesSampleRate: 1.0,
  })
}
