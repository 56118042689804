import BigNumber from 'bignumber.js';

export default class MicroUSDC extends BigNumber {
  static ZERO = new this('0');
  static MAX_SAFE_MICRO_USDC_AMOUNT = MicroUSDC.create(BigInt('18446744073709551615'));
  static readonly MICRO_DECIMAL = 6;

  private constructor(amount: string | BigNumber) {
    super(amount);
    if (super.isNaN()) {
      throw Error('A micro usdc value must be a big int valid.');
    }
  }

  toBigInt(): bigint {
    return BigInt(this.toString());
  }

  static fromUSDC(amountUSDC: number): MicroUSDC {
    return new this(
      new BigNumber(amountUSDC).decimalPlaces(MicroUSDC.MICRO_DECIMAL).multipliedBy(
        Math.pow(10, MicroUSDC.MICRO_DECIMAL),
      ),
    );
  }

  static create(amount: bigint | number | string | BigNumber): MicroUSDC {
    if (amount.toString().split('.').length > 1) {
      throw Error(`A micro usdc value must have an integer or bigint.`);
    }
    return new this(amount.toString());
  }

  plus(amount: number | string | BigNumber): MicroUSDC {
    return new MicroUSDC(super.plus(amount));
  }

  minus(amount: number | string | BigNumber): MicroUSDC {
    return new MicroUSDC(super.minus(amount));
  }

  div(amount: number | string | BigNumber): MicroUSDC {
    return new MicroUSDC(super.div(amount));
  }

  dividedBy(amount: number | string | BigNumber): MicroUSDC {
    return new MicroUSDC(super.dividedBy(amount));
  }

  times(amount: number | string | BigNumber): MicroUSDC {
    return new MicroUSDC(super.times(amount));
  }

  multipliedBy(amount: number | string | BigNumber): MicroUSDC {
    return new MicroUSDC(super.multipliedBy(amount));
  }
}
