import { ReactElement } from 'react'
import Grid from '@mui/material/Grid'
import TotalEarning from '@opulous/web/src/components/Royalties/TotalEarning'
import {
  BestPerformingCountries,
  BestPerformingStores,
  BestStreamedReleases,
} from '@opulous/web/src/components/Royalties/BestCards'
import FullOverview from '@opulous/web/src/components/Royalties/FullOverview'
import FilterBar from '@opulous/web/src/components/Royalties/FilterBar'

export default function SalesTab(): ReactElement {
  return (
    <Grid container spacing={3} data-testid="royalties-sales-tab">
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <FilterBar />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TotalEarning />
      </Grid>
      <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
        <BestPerformingCountries />
      </Grid>
      <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
        <BestPerformingStores />
      </Grid>
      <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
        <BestStreamedReleases />
      </Grid>
      <Grid item xs={12}>
        <FullOverview />
      </Grid>
    </Grid>
  )
}
