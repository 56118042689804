import React, { ReactElement, useCallback, useState } from 'react';
import * as Sentry from '@sentry/react';
import { styled } from '@mui/system';
import { Popover, Typography, TextField } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Snackbar from '@opulous/web/src/components/Snackbar';
import { isAValidEmail } from '@opulous/web/src/utils';

const StyledPopover = styled(Popover)(() => ({
  '& > div.MuiPopover-paper': {
    boxShadow: '0px 2px 13px -3px #0003',
  },
}));
const BellIconButton = styled('button')(() => ({
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  '&:focus-visible': {
    border: 'none',
    outline: 'none',
  },
}));
const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(2),
  minWidth: theme.spacing(35),
}));
const PopoverTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2.2),
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));
const IconClose = styled('span')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2.6),
  fontSize: theme.spacing(3.6),
  cursor: 'pointer',
}));
const FormContainer = styled('form')(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
}));
const StyledOutlinedInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  input: {
    padding: theme.spacing(1.5, 2),
  },
  fieldset: {
    borderRadius: theme.spacing(4),
  },
}));
const StyledButton = styled('button')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  borderRadius: theme.spacing(4),
  width: theme.spacing(6),
  height: theme.spacing(6),
  border: 'none',
  outline: 'none',
  padding: theme.spacing(0),
  background: theme.palette.grey[900],
  color: theme.palette.common.white,
  cursor: 'pointer',
}));

const SnackbarContent = styled('div')(({ theme }) => ({
  border: `2px solid ${theme.palette.success.main}`,
  backgroundColor: theme.palette.background.paper,
  fontSize: theme.spacing(2),
  fontWeight: '500',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2.2, 3),
  maxWidth: theme.spacing(60),
}));

type Props = {
  handleSubmit: (email: string) => Promise<void>;
  anchor?: ReactElement;
};
export default function GetNotified({
  handleSubmit,
  anchor,
}: Props): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState<string>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEmail('');
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.target.value) {
        setEmail(event.target.value);
      }
    },
    [],
  );

  const onSubmit = useCallback(
    (event?: React.FormEvent<HTMLFormElement>) => {
      if (event) {
        event.preventDefault();
        if (!isAValidEmail(email)) {
          setHasError(true);
          return;
        }

        (async () => {
          try {
            setProcessing(true);
            await handleSubmit(email);
            setSnackbarMessage('Thank you for subscribing!');
            setTimeout(() => {
              setSnackbarMessage('');
            }, 4000);
          } catch (error) {
            Sentry.captureException(error);
            setSnackbarMessage('an error occurred when subscribing the email');
          } finally {
            setProcessing(false);
            handleClose();
          }
        })();
      }
    },
    [email],
  );

  const handleKeyDown = useCallback(() => {
    setHasError(false);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'get-notified-popover' : undefined;
  return (
    <>
      {anchor ? (
        React.cloneElement(anchor, {
          'data-testid': anchor.props['data-testid'] || 'popover-get-notified__button',
          'aria-describedby': id,
          onClick: handleClick,
        })
      ) : (
        <BellIconButton
          data-testid="popover-get-notified__button"
          aria-describedby={id}
          onClick={handleClick}
        >
          <svg width="17" height="20" viewBox="0 0 17 20" fill="none">
            <path
              d="M16 16.69L3.84 4.14L1.27 1.49L0 2.76L2.8 5.56V5.57C2.28 6.56 2 7.73 2 8.99V13.99L0 15.99V16.99H13.73L15.73 18.99L17 17.72L16 16.69V16.69ZM8 20C9.11 20 10 19.11 10 18H6C6 19.11 6.89 20 8 20ZM14 12.68V9C14 5.92 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C6.35 2.71 6.21 2.76 6.08 2.8C5.98 2.83 5.88 2.87 5.78 2.91H5.77C5.76 2.91 5.76 2.91 5.75 2.92C5.52 3.01 5.29 3.12 5.07 3.23C5.07 3.23 5.06 3.23 5.06 3.24L14 12.68Z"
              fill={open ? 'rgba(35, 38, 47, 1)' : '#AAB0C0'}
            />
          </svg>
        </BellIconButton>
      )}
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          ...(anchor ? {
            vertical: 'top',
            horizontal: 'center',
          } : {
            vertical: 'bottom',
            horizontal: 'center',
          })
        }}
        transformOrigin={{
          ...(anchor ? {
            vertical: 'bottom',
            horizontal: 'center',
          } : {
            vertical: 'top',
            horizontal: 'right',
          })
        }}
      >
        <Wrapper data-testid="popover-get-notified__container">
          <PopoverTitle data-testid="popover-get-notified__container__title">
            Get notified
          </PopoverTitle>
          <IconClose
            data-testid="popover-get-notified__container__close"
            onClick={() => handleClose()}
          >
            &times;
          </IconClose>
          <FormContainer onSubmit={onSubmit} noValidate>
            <StyledOutlinedInput
              autoFocus
              data-testid="popover-get-notified__container__email-input"
              placeholder="Type your email..."
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              disabled={processing}
              error={hasError}
              helperText={hasError ? 'Fill a valid e-mail.' : ''}
            />
            <StyledButton data-testid="popover-get-notified__container__submit-button">
              <ArrowForwardOutlinedIcon />
            </StyledButton>
          </FormContainer>
        </Wrapper>
      </StyledPopover>
      <Snackbar
        data-testid="popover-get-notified__container__snackbar"
        open={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
      >
        <SnackbarContent>
          <span>{snackbarMessage}</span>
        </SnackbarContent>
      </Snackbar>
    </>
  );
}
