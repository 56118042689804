import React, { ReactElement, useContext, useMemo, useState } from 'react'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { styled } from '@mui/system'
import sanitizeHtml from 'sanitize-html'
import TotalTicketsIcon from '../icons/TotalTicketsIcon'
import YourTicketsIcon from '../icons/YourTicketsIcon'
import WinPlacesIcon from '../icons/WinPlacesIcon'
import EndDateIcon from '../icons/EndDateIcon'
import AddTicketModal from '../AddTicketModal'
import ActiveRaffleActions from './ActiveRaffleActions'
import RaffleWinIcon from '../icons/RaffleWinIcon'
import RaffleLoseIcon from '../icons/RaffleLoseIcon'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClaimModal from '../ClaimModal'
import WinnerListModal from '../WinnerListModal'
import { raffles as RaffleService } from '../../../services'
import WalletContext from '@opulous/web/src/context/context'
import { formatDate, formatNumber } from '@opulous/web/src/utils'
import { DesktopOnly, MobileOnly } from '@opulous/web/src/components/Common/Responsive'
import { IRaffle } from 'src/shared/types'
import { Link } from 'react-router-dom'

const MockedWinners = 5
const MockedTicketsSpentAmount = 0

const MftLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  gap: theme.spacing(.5),
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  fontWeight: '500',

  '& > svg': {
    width: '1rem',
    height: '1rem',
  },
}));

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(3),
  overflow: 'hidden',
}))

const RaffleImg = styled(
  (props: React.HTMLAttributes<HTMLDivElement> & { src: string }) => (
    <div {...props} />
  )
)(({ theme, src }) => ({
  width: '100%',
  height: theme.spacing(36),
  backgroundImage: `url("${src}")`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',

  [theme.breakpoints.up('xl')]: {
    height: '100%',
  },

  [theme.breakpoints.down('sm')]: {
    paddingBottom: '100%',
    height: 'unset',
  },
}))

const ActionContainer = styled('div')(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.grey[100]}`,
  width: '100%',
  height: '18rem',
  paddingLeft: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0,
    borderLeft: 'none',
  },
}))

const Tile = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
}))

const TileTitleText = styled(Typography)(({ theme }) => ({
  fontSize: '.75rem',
  color: theme.palette.grey[600],
  fontWeight: '400',
}))

const TileText = styled(Typography)(() => ({
  fontSize: '.875rem',
  fontWeight: '400',
}))

const TileIconContainer = styled('div')(({ theme }) => ({
  width: '1.2rem',
  height: '1.2rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),

  '& > svg': {
    width: '100%',
    height: '100%',
  },
}))

const TileGrid = styled('div')(({ theme }) => ({
  marginTop: 'auto',
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: 'repeat(auto-fit, minmax(6.8rem, 1fr))',
}))

const RaffleResultContainer = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
}))

const RaffleResultIconContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  width: '3rem',
  height: '3rem',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& > svg': {
    width: '60%',
    height: '60%',
  },
}))

const CaptionText = styled('div')(({ theme }) => ({
  fontSize: '.6rem',
  color: theme.palette.grey[500],
  fontWeight: '500',
}))

const NumWinnerBadge = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  borderRadius: '.8rem',
  height: '1.6rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 2),
  fontSize: '.875rem',
  fontWeight: '500',
  whiteSpace: 'nowrap',
}))

const DescriptionContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': '4',
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'normal',
  height: '6rem',
}))

const TitleContainer = styled('div')(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': '1',
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'normal',
  fontWeight: '700',
  fontSize: '1.375rem',
}))

function calculateWinningChance(
  stake: number,
  _total: number,
  places: number
): number {
  if (!stake) return 0;
  if (!places) return 0;
  if (_total - stake < places) return 1 // User must win

  let total = _total
  let p = 1
  for (let i = 0; i < places; i++) {
    p *= 1 - stake / total
    total--
  }
  return 1 - p
}

const sanitizeSettings = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'li', 'ol', 'div','p','br'],
  allowedAttributes: {
    '*': ['href', 'target', 'style'],
  },
}

export default function RaffleCard({
  numAvailableTickets,
  raffle,
  onTicketsAdd,
  onRewardClaimed,
}: {
  raffle: IRaffle
  numAvailableTickets: number
  onTicketsAdd?: () => Promise<void>
  onRewardClaimed?: () => Promise<void>
}): ReactElement {
  const {
    state: { wallet },
  } = useContext(WalletContext)
  const [shouldShowAddTicketModal, setShouldShowAddTicketModal] =
    useState(false)
  const [shouldShowClaimModal, setShouldShowClaimModal] = useState(false)
  const [shouldShowWinnerListModal, setShouldShowWinnerListModal] =
    useState(false)

  const winningChance =
    calculateWinningChance(
      raffle.walletRaffleEntry?.ticketsSpentAmount ?? 0,
      raffle.totalTicketsSpentAmount || MockedTicketsSpentAmount,
      raffle.numberOfWinners || MockedWinners
    ) * 100
  const maxSpendableTickets = Math.min(raffle.maxTicketAmount, numAvailableTickets);
  const maxWinningChance =
    calculateWinningChance(
      (raffle.walletRaffleEntry?.ticketsSpentAmount ?? 0) + maxSpendableTickets,
      (raffle.totalTicketsSpentAmount || MockedTicketsSpentAmount) + maxSpendableTickets,
      raffle.numberOfWinners || MockedWinners
    ) * 100

  const [isActive, isUpcoming, isWinner] = useMemo(() => {
    const _isActive =
      Date.now() >= new Date(raffle.startTimestamp).getTime() &&
      Date.now() <= new Date(raffle.endTimestamp).getTime()
    const _isUpcoming = Date.now() < new Date(raffle.startTimestamp).getTime()
    return [
      _isActive,
      _isUpcoming,
      !_isActive && !!raffle.walletRaffleEntry?.hasWon,
    ]
  }, [raffle]);

  const hasUserEnteredRaffle = raffle.walletRaffleEntry?.ticketsSpentAmount > 0;

  const handleTicketAdd = async (numTickets: number) => {
    await RaffleService.addTickets(wallet, raffle.id, numTickets);
    await onTicketsAdd?.();
  }

  const handleClaim = async (email: string, verificationCode: string) => {
    await RaffleService.claimRaffleRewards(wallet, raffle.id, email, verificationCode)
  }

  const handleClaimed = async () => {
    await onRewardClaimed?.();
  }

  const handleVerificationCodeSend = async (email: string) => {
    await RaffleService.sendVerificationCode(wallet, raffle.id, email);
  }

  return (
    <Wrapper data-testid="raffle-card">
      <Grid container height="100%">
        <Grid item xl={12} lg={12} sm={12} xs={12}>
          <MobileOnly>
            <Box padding={3}>
              <TitleContainer>{raffle.title}</TitleContainer>
            </Box>
          </MobileOnly>
        </Grid>
        <Grid item xl={3} lg={12} sm={12} xs={12} display="flex">
          <DesktopOnly width="100%" padding={3}>
            <RaffleImg src={raffle.image} />
          </DesktopOnly>
          <MobileOnly width="100%" paddingX={3}>
            <RaffleImg src={raffle.image} />
          </MobileOnly>
        </Grid>
        <Grid item xl={6} lg={8} sm={12} xs={12} padding={3} display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <DesktopOnly>
              <TitleContainer>{raffle.title}</TitleContainer>
            </DesktopOnly>
            <DesktopOnly>
              <NumWinnerBadge>
                {formatNumber(raffle.numberOfWinners || MockedWinners)} winner(s)
              </NumWinnerBadge>
            </DesktopOnly>
          </Box>
          <DescriptionContainer
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(raffle.description, sanitizeSettings),
            }}
          />
          {!!raffle.investmentId && (
            <div>
              <MftLink to={`/mfts/${encodeURIComponent(raffle.investmentId)}`}>
                MFT Page
                <ArrowForwardIcon />
              </MftLink>
            </div>
          )}
          <TileGrid>
            <Tile>
              <TileIconContainer>
                <EndDateIcon />
              </TileIconContainer>
              <TileTitleText>Start date</TileTitleText>
              <TileText>{formatDate(new Date(raffle.startTimestamp))}</TileText>
            </Tile>
            <Tile>
              <TileIconContainer>
                <WinPlacesIcon />
              </TileIconContainer>
              <TileTitleText>End date</TileTitleText>
              <TileText>{formatDate(new Date(raffle.endTimestamp))}</TileText>
            </Tile>
            <Tile>
              <TileIconContainer>
                <YourTicketsIcon />
              </TileIconContainer>
              <TileTitleText>Your tickets</TileTitleText>
              <TileText>
                {formatNumber(
                  raffle.walletRaffleEntry?.ticketsSpentAmount ?? 0
                )}
              </TileText>
            </Tile>
            <Tile>
              <TileIconContainer>
                <TotalTicketsIcon />
              </TileIconContainer>
              <TileTitleText>Total tickets</TileTitleText>
              <TileText>
                {formatNumber(raffle.totalTicketsSpentAmount || 0)}
              </TileText>
            </Tile>
          </TileGrid>

          <MobileOnly paddingTop={2}>
            <NumWinnerBadge>
              {formatNumber(raffle.numberOfWinners || MockedWinners)} winner(s)
            </NumWinnerBadge>
          </MobileOnly>
        </Grid>
        <Grid
          item
          xl={3}
          lg={4}
          sm={12}
          xs={12}
          padding={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ActionContainer>
            {!wallet || isUpcoming ? (
              <ActiveRaffleActions
                winningChance={0}
                maxWinningChance={0}
                maxTicketAllowed={raffle.maxTicketAmount}
                disabled
              />
            ) : isActive ? (
              <ActiveRaffleActions
                winningChance={winningChance}
                maxWinningChance={maxWinningChance}
                onTicketAdd={() => setShouldShowAddTicketModal(true)}
                maxTicketAllowed={raffle.maxTicketAmount}
                disabled={!numAvailableTickets}
              />
            ) : !raffle.totalTicketsSpentAmount ? (
              <RaffleResultContainer>
                <RaffleResultIconContainer>
                  <RaffleWinIcon />
                </RaffleResultIconContainer>
                <Box paddingY={2} textAlign="center">
                  <Typography variant="h5">No one participated</Typography>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled
                >
                  No winners
                </Button>
              </RaffleResultContainer>
            ) : isWinner ? (
              <RaffleResultContainer>
                <RaffleResultIconContainer>
                  <RaffleWinIcon />
                </RaffleResultIconContainer>
                <Box paddingY={2} textAlign="center">
                  {raffle.walletRaffleEntry?.hasClaimed ? (
                    <Typography variant="h5">This raffle is over</Typography>
                  ) : (<>
                    <Typography variant="h5">You won!</Typography>
                    <CaptionText>
                      Leave your email to claim your reward
                    </CaptionText>
                  </>)}
                </Box>

                {raffle.walletRaffleEntry?.hasClaimed ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => setShouldShowWinnerListModal(true)}
                  >
                    View winners
                  </Button>
                ): (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => setShouldShowClaimModal(true)}
                  >
                    Claim reward
                  </Button>
                )}
              </RaffleResultContainer>
            ) : (
              <RaffleResultContainer>
                <RaffleResultIconContainer>
                  <RaffleLoseIcon />
                </RaffleResultIconContainer>
                <Box paddingY={2} textAlign="center">
                  {hasUserEnteredRaffle ? (<>
                    <Typography variant="h5">You lost...</Typography>
                    <CaptionText>Better luck next time!</CaptionText>
                  </>) : (
                    <Typography variant="h5">You missed...</Typography>
                  )}
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setShouldShowWinnerListModal(true)}
                >
                  View winners
                </Button>
              </RaffleResultContainer>
            )}
          </ActionContainer>
        </Grid>
      </Grid>

      {shouldShowAddTicketModal && (
        <AddTicketModal
          numAddedTickets={raffle.walletRaffleEntry?.ticketsSpentAmount ?? 0}
          maxTickets={raffle.maxTicketAmount}
          numAvailableTickets={numAvailableTickets}
          onClose={() => setShouldShowAddTicketModal(false)}
          onSubmit={handleTicketAdd}
        />
      )}

      {shouldShowClaimModal && (
        <ClaimModal
          onClose={() => setShouldShowClaimModal(false)}
          onClaimed={handleClaimed}
          onSubmit={handleClaim}
          onVerificationCodeSend={handleVerificationCodeSend}
        />
      )}

      {shouldShowWinnerListModal && (
        <WinnerListModal
          winners={raffle.winners}
          onClose={() => setShouldShowWinnerListModal(false)}
        />
      )}
    </Wrapper>
  )
}
