import { ReactElement } from 'react';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { IContentLabelListItem } from '@opulous/web/src/shared/types';

interface ContentLabelListProps {
  items: IContentLabelListItem[];
}

const LabelWrapper = styled('div')(() => ({
  marginBottom: 16,
}));

const Label = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '21px',
}));

const Value = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  color: theme.palette.primary.main,
  fontSize: 20,
  lineHeight: '24px',
}));

const LabelListContainer = styled('div')(() => ({
  marginBottom: 24,
}));

const ContentLabelList = ({ items }: ContentLabelListProps): ReactElement => {
  return (
    <LabelListContainer>
      {items.map(({ name, value }) => {
        return (
          <LabelWrapper>
            <Label>{name}</Label>
            <Value variant="h6">{value}</Value>
          </LabelWrapper>
        );
      })}
    </LabelListContainer>
  );
};

export default ContentLabelList;
