import React from 'react';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import Divider from '@mui/material/Divider'
import { styled } from '@mui/system'
import { Theme } from '@mui/material'
import { useTheme } from '@mui/styles'
import { Option } from '@opulous/web/src/shared/types'

const Wrapper = styled(TabList)(({ theme }) => ({
  position: 'relative',
  '& .MuiTabs-scrollButtons': {
    display: 'flex !important',
  },
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    display: 'none !important',
  },
  width: '100%',
  [theme.breakpoints.down('md')]: {
    maxWidth: 'calc(100vw - 30px)',
  },
  '& .MuiTabs-flexContainer button': {
    borderBottom: `1.5px solid ${theme.palette.grey[300]}`,
  }
}));

interface TabProps {
  theme: Theme
  width: string
}

const StyledTab = styled(Tab)(({ theme, width }: TabProps) => ({
  textTransform: 'capitalize',
  fontSize: theme.spacing(1.875),
  fontWeight: 600,
  padding: theme.spacing(1.5, 3),
  width: width,
  '&.Mui-selected': {
    color: theme.palette.text.primary,
  }
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  top: '97%',
  borderBottom: `1.5px solid ${theme.palette.grey[300]}`,
}));

interface TabBarProps {
  tabs: Option[],
  onChangTab: (value: string) => void,
  tabWidth?: string,
}

const TabBar: React.FC<TabBarProps> = ({
  tabs,
  onChangTab,
  tabWidth = 'auto',
}) => {
  const theme = useTheme() as Theme

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    onChangTab(newValue);
  };

  return (
    <Wrapper
      data-testid="tab-bar"
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
    >
      <StyledDivider />
      {
        tabs.map((tab) => (
          <StyledTab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            theme={theme}
            width={tabWidth}
            data-testid={`TabBar_tab-${tab.value}`}
            disableRipple
          />
        ))
      }
    </Wrapper>
  )
};

export default TabBar;
