import { Box, Button, styled } from '@mui/material';
import React from 'react';
import { Link as _Link } from 'react-router-dom';
import CasinoIcon from '@mui/icons-material/Casino';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InterestsIcon from '@mui/icons-material/Interests';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const Link = styled(_Link)(() => ({
  textDecoration: 'none',

  '& > button.MuiButton-root': {
    width: '100%',
  },
}));

export default function Dashboard(): React.ReactElement {
  return (
    <div>
      <h1>Welcome to Admin dashboard!</h1>
      <Box paddingTop={2}>
        <Box maxWidth="60rem" display="flex" flexDirection="column" gap={4}>
          {[
            { Icon: CasinoIcon, path: '/admin/raffles', text: 'Raffles', view: true, create: true },
            { Icon: InterestsIcon, path: '/admin/assets', text: 'Assets', view: true, create: true },
            { Icon: WorkspacesIcon, path: '/admin/pools', text: 'Pools', view: true, create: true },
            { Icon: MonetizationOnIcon, path: '/admin/sales', text: 'Sales', view: true, create: true },
            { Icon: DeveloperBoardIcon, path: '/admin/issuances', text: 'Issuances', view: true, create: false },
            { Icon: TrendingUpIcon, path: '/admin/rewards', text: 'Rewards', view: true, create: false },
          ].map(({ Icon, path, text, view, create }) => (
            <Box key={path} display="flex" flexDirection="column" gap={1}>
              <Box display="flex" gap={1} alignItems="center" fontWeight="500">
                <Icon /><span>{text}</span>
              </Box>
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(12rem, 1fr))"
                gap={2}
              >
                {view && (
                  <Link to={`${path}`} state={{ back: '/admin/dashboard' }}>
                    <Button size="small">View</Button>
                  </Link>
                )}

                {create && (
                  <Link to={`${path}/create`} state={{ back: '/admin/dashboard' }}>
                    <Button size="small">Create</Button>
                  </Link>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
}
